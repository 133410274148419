import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import log from 'loglevel'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { mixins } from '../../styles/mixins'
import { useApi } from '../ApiContext'
import { KRPanoCanvas } from './KRPanoCanvas'
import { KRPanoProvider, useKRPanoStore } from './KRPanoProvider'
import { PhotosphereKRPanoXML } from './PhotosphereKRPanoXML'
import { tourJs2Xml } from './tourJs2Xml'

export const PhotospherePublicPage = () => {
  const api = useApi()

  const { panoId } = useParams()

  const { value } = useAsync(async () => {
    if (panoId) {
      const resp = await api.photospheresApi.publicPhotospheresRead({
        publicId: panoId,
      })
      const xmlUrl = await tourJs2Xml(
        <PhotosphereKRPanoXML project={undefined} photospheres={[resp.data]} />
      )
      return { xmlUrl, data: resp.data }
    }
  }, [panoId])

  if (!value) return null

  return (
    <KRPanoProvider xmlUrl={value.xmlUrl}>
      <InitKRPano />
      <div
        style={{
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <KRPanoCanvas />
        <div
          style={{
            color: '#fff',
            fontSize: 20,
            fontWeight: 'bold',
            textShadow: '0 0 4px #000',
            position: 'absolute',
            bottom: 20,
            left: 20,
          }}
        >
          {value.data.title}
        </div>
      </div>
    </KRPanoProvider>
  )
}

function InitKRPano() {
  const krpanoStore = useKRPanoStore()

  const panoElement = krpanoStore((state) => state.krPanoElement)

  const [searchParams] = useSearchParams()

  const hlookat = searchParams.get('hlookat')
  const vlookat = searchParams.get('vlookat')
  const fov = searchParams.get('fov')

  useEffect(() => {
    if (panoElement) {
      log.debug('Aim view', { hlookat, vlookat, fov })
      if (hlookat && typeof parseFloat(hlookat) === 'number') {
        panoElement.set('view.hlookat', parseFloat(hlookat))
      }
      if (vlookat && typeof parseFloat(vlookat) === 'number') {
        panoElement.set('view.vlookat', parseFloat(vlookat))
      }
      if (fov && typeof parseFloat(fov) === 'number') {
        panoElement.set('view.fov', parseFloat(fov))
      }
    }
  }, [panoElement, hlookat, vlookat, fov])

  return null
}
