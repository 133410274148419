import React, { useState, useRef } from 'react'
import { Rect, Layer, Line, Group } from 'react-konva'
import Konva from 'konva'
import {
  ImageLabelInfo,
  ImageAnnotationBox,
  ImageSublabel,
} from '../../api/codegen/typescript-axios'
import { useStageContext } from './VideoCanvas'

export const DrawNewBox = ({
  selectedLabel,
  selectedSublabel,
  setBoxes,
}: {
  selectedLabel: ImageLabelInfo
  selectedSublabel?: ImageSublabel
  setBoxes: React.Dispatch<React.SetStateAction<ImageAnnotationBox[]>>
}) => {
  const { absoluteTransform, clamp, width, height } = useStageContext()
  const layerRef = useRef<Konva.Layer>(null)
  const [mouse, setMouse] = useState<Konva.Vector2d>({ x: 0, y: 0 })
  const [origin, setOrigin] = useState<Konva.Vector2d | undefined>()

  const onMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    setOrigin({ x: e.evt.offsetX, y: e.evt.offsetY })
  }

  const onMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    setMouse({ x: e.evt.offsetX, y: e.evt.offsetY })
  }

  const onMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
    setBoxes((oldBoxes: ImageAnnotationBox[]) => {
      // this shouldnt ever happen. origin always set during mouseup
      if (!origin) return oldBoxes

      const transform = absoluteTransform.copy().invert()
      const topLeft = clamp(
        transform.point({
          x: Math.min(origin.x, mouse.x),
          y: Math.min(origin.y, mouse.y),
        })
      )
      const bottomRight = clamp(
        transform.point({
          x: Math.max(origin.x, mouse.x),
          y: Math.max(origin.y, mouse.y),
        })
      )
      const _newBox: ImageAnnotationBox = {
        x: topLeft.x,
        y: topLeft.y,
        w: bottomRight.x - topLeft.x,
        h: bottomRight.y - topLeft.y,
        label: selectedLabel,
        sublabel: selectedSublabel,
        category_id: selectedLabel.category_id,
        comments: [],
        id: new Date().getTime(),
        is_currently_valid: true,
      }
      if (_newBox.w < 4 || _newBox.h < 4) {
        return oldBoxes
      }
      return [...oldBoxes, _newBox]
    })
    setOrigin(undefined)
  }

  const w = layerRef.current ? layerRef.current.canvas.width : 0
  const h = layerRef.current ? layerRef.current.canvas.height : 0

  return (
    <Layer ref={layerRef}>
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      />
      {[
        // horizontal
        [0, mouse.y, w, mouse.y],
        // vertical
        [mouse.x, 0, mouse.x, h],
      ].map((points, i) => (
        <Group key={i}>
          <Line
            points={points}
            stroke={'white'}
            strokeWidth={1}
            dash={[-4, 4]}
          />
          <Line
            points={points}
            stroke={'black'}
            strokeWidth={1}
            dash={[4, 4]}
          />
        </Group>
      ))}
      {origin && (
        <Rect
          listening={false}
          x={origin.x}
          y={origin.y}
          width={mouse.x - origin.x}
          height={mouse.y - origin.y}
          stroke={selectedLabel.color || '#0f0'}
          strokeWidth={2}
        />
      )}
    </Layer>
  )
}
