import {
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { PageWrapper } from './PageWrapper'
import { useProject } from './ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  })
)

const email = 'mitch@topdeck.com'
const tel = '(323) 555-1212'

export const SupportPage = (props: {}) => {
  const classes = useStyles(props)
  const project = useProject()

  return (
    <PageWrapper>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Contact support for {project.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div>Contact: Mitch Mersek</div>
            <div>
              Phone:{' '}
              <Link
                href={`tel:${tel}`}
                onClick={(e: any) => e.preventDefault()}
                color="secondary"
              >
                {tel}
              </Link>
            </div>
            <div>
              Email:{' '}
              <Link
                href={`mailto:${email}`}
                onClick={(e: any) => e.preventDefault()}
                color="secondary"
              >
                {email}
              </Link>
            </div>
            <h4 style={{ marginTop: 40 }}>Project Info</h4>
            <p>
              Here would be information about the setup at the site. Maybe
              instructions on how to plug the power back in. Stuff like that.
              Lorem ipsum dolor sit ament.
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            <RouterLink to={`/${project.slug}/video-request`}>
              <Link color="secondary">Request custom video</Link>
            </RouterLink>
          </Typography>
        </Grid>
      </div>
    </PageWrapper>
  )
}
