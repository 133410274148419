import { createStyles, makeStyles } from '@material-ui/core'
import { ScaleTime } from 'd3-scale'
import { useCraneStore } from './CranePicksController'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      pickTimeRange: {
        position: 'absolute',
        borderRadius: 6,
        border: '2px solid',
        padding: '2px 4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        '&:hover div': {
          opacity: 0.7,
        },
      },
      pickTimeRangeBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0.3,
      },
    }),
  {
    name: 'VODPicks',
  }
)

export const CranePicksAsPlanned = ({
  scale,
  showAsBuilt,
}: {
  scale: ScaleTime<number, number, never>
  showAsBuilt: boolean
}) => {
  const classes = useStyles()
  const craneStore = useCraneStore()

  const pick = craneStore((state) => state.pick)
  const calendarEntries = craneStore((state) => state.calendarEntries)

  return (
    <div
      className="CranePicksAsPlanned"
      style={{ position: 'relative', zIndex: 1 }}
    >
      {calendarEntries.map((entry) => {
        const start = scale(entry.systemStartDateTime.toJSDate())

        const end = scale(entry.systemEndDateTime.toJSDate())

        const height = end - start

        return (
          <div
            // button
            key={entry.id}
            className={classes.pickTimeRange}
            style={{
              left: 0,
              width: showAsBuilt ? 'calc(40% - 1px)' : '100%',
              top: start + 1,
              height: height - 2,
              borderColor: entry.subcontractor_attrs?.color || '#999',
            }}
          >
            <div
              className={classes.pickTimeRangeBackground}
              style={{
                opacity: entry.id === pick?.id ? 1 : undefined,
                backgroundColor: entry.subcontractor_attrs?.color || '#999',
              }}
            />
            <div style={{ position: 'relative' }}>
              <span>{entry.subcontractor_attrs?.name || ''}</span>
              <span
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  opacity: 0.7,
                }}
              >
                {' - '}
                {entry.description}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
