import { Button, ButtonGroup } from '@material-ui/core'
import { useLocalStorage } from 'react-use'
import { useMe } from './useMe'

interface Windows {
  Timeline: boolean
  Drawings?: boolean
  Clips?: boolean
  Analytics?: boolean
  Thumbnails: boolean
  Multiplayer?: boolean
}

export const usePanels = (
  storageKey: string,
  config: Record<string, boolean>
) => {
  const { data: me } = useMe()

  const isAnnotator =
    me?.videoannotation_role === 'admin' ||
    me?.videoannotation_role === 'annotator'

  const [state, setState] = useLocalStorage<Record<string, boolean>>(
    storageKey,
    config,
    {
      raw: false,
      serializer: JSON.stringify,
      deserializer: (value: string) => {
        // remove any deprecated windows
        const storageValue = JSON.parse(value)
        for (const key in storageValue) {
          if (!(key in config)) {
            delete storageValue[key]
          }
        }
        return { ...config, ...storageValue }
      },
    }
  )

  return {
    panelState: state,
    setPanelState: setState,
    PanelControl: () => (
      <ButtonGroup
        style={{
          display: 'block',
        }}
      >
        {Object.keys(config)
          .filter(
            (panel) =>
              (panel !== 'Analytics' &&
                panel !== 'Clips' &&
                panel !== 'Drawings') ||
              isAnnotator
          )
          .map((panel) => (
            <Button
              key={panel}
              variant="contained"
              size="small"
              color={state && state[panel] ? 'primary' : 'default'}
              onClick={() => {
                if (!state) return
                setState({
                  ...state,
                  [panel]: !state[panel],
                })
              }}
            >
              {panel.replace('_', ' ')}
            </Button>
          ))}
      </ButtonGroup>
    ),
  }
}
