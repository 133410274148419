import { vec2 } from 'gl-matrix'
import { clamp } from 'lodash'
import { DateTime } from 'luxon'
import React, { memo, useEffect } from 'react'
import { animated, to, useSpring } from 'react-spring'
import shallow from 'zustand/shallow'
import { useProject } from '../../ProjectWrapper'
import { UseTimelineStore } from './Timeline'

const width = 144

export const TimelineMarker = memo(
  ({
    useTimelineStore,
    mousePositionRef,
    storeKey,
    color,
    containerWidth,
  }: {
    useTimelineStore: UseTimelineStore
    mousePositionRef: React.MutableRefObject<vec2>
    storeKey: 'videoDateTime' | 'mouseDateTime'
    color: string
    containerWidth: number
  }) => {
    const project = useProject()
    const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)
    const [{ time, x }, spring] = useSpring(() => ({
      time: 0,
      x: 0,
      immediate: true,
    }))

    useEffect(
      () =>
        useTimelineStore.subscribe<[DateTime, DateTime | undefined]>(
          () => {
            let time = undefined
            let x = 0
            if (storeKey === 'mouseDateTime') {
              // mouseDateTime for mouse line
              time = useTimelineStore.getState().mouseDateTime
              if (
                mousePositionRef.current[0] >
                useTimelineStore.getState().leftColumnWidth
              ) {
                x = mousePositionRef.current[0]
              }
            } else {
              // videoDateTime for playhead
              const {
                currentScale,
                videoDateTime,
              } = useTimelineStore.getState()
              time = videoDateTime
              x = currentScale(videoDateTime)
            }
            spring.set({ time: time?.toMillis() || 0, x: x })
          },
          (state) => [state.videoDateTime, state.mouseDateTime],
          shallow
        ),
      [useTimelineStore, storeKey, mousePositionRef, spring]
    )

    return (
      <>
        {/* Rounded rectangle and text */}
        <animated.g
          transform={to(
            [x],
            (x) =>
              `translate(${
                x
                  ? clamp(
                      x - width / 2,
                      leftColumnWidth + 3.5,
                      containerWidth - width - 3.5
                    ) // clamp within viewport when visible
                  : -width - 2 // hide offscreen
              } 28)`
          )}
          cursor="pointer"
        >
          <rect
            stroke={color}
            fill={'#252525'}
            x={0}
            y={0.5}
            width={width}
            height={20}
            rx={4}
          ></rect>
          <animated.text
            fill="#f7f7f7"
            fontSize={Math.floor(width / 12)}
            textAnchor="middle"
            x={width / 2}
            y={15}
            fontFamily="'Roboto Mono', monospace"
          >
            {to([time], (t) =>
              DateTime.fromMillis(t).toFormat('LLL dd yy HH:mm:ss')
            )}
          </animated.text>
        </animated.g>

        {/* Bottom arrow */}
        <animated.path
          fill={color}
          d={`M${width / 2 - 3} 20 l 6 0 l -3 6 Z`}
          transform={to(
            [x],
            (x) =>
              `translate(${x > leftColumnWidth ? x - width / 2 : -width} 28)`
          )}
        ></animated.path>
      </>
    )
  }
)

TimelineMarker.displayName = 'TimelineMarker'
