import { DateTime } from 'luxon'
import React, { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import zustand, { UseStore } from 'zustand'
import { ClipExtended, extendClip } from '../../api/ClipExtended'
import { createCtx } from '../../helpers/createCtx'
import { validateXYWH } from '../../helpers/validateXYWH'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'
import { useAnalysesStore } from './AnalysesController'
import { getUseVODStore } from './VODController'

export type ClipsState = {
  clipId?: number
  clip?: ClipExtended
  clips?: ClipExtended[]
  fetchClips: () => void
  setClip: (id: number) => void
}

export type UseClipsStore = UseStore<ClipsState>

export const [useClipsStore, ClipsContext] = createCtx<UseClipsStore>()

// Must be descendant of AnalysesController
export const VODClipsController: FC<{}> = ({ children }) => {
  const api = useApi()
  const project = useProject()
  const analysesStore = useAnalysesStore()
  const vodStore = getUseVODStore()

  const analysisId = analysesStore((state) => state.analysisId)

  const [searchParams, setSearchParams] = useSearchParams()
  const clipParam = parseInt(searchParams.get('clip') || '0') || undefined

  // init store
  const [useStore] = useState<UseClipsStore>(() =>
    zustand<ClipsState>((set, get) => ({
      clipId: clipParam,
      clip: undefined,
      clips: undefined,
      fetchClips: async () => {
        const analysisId = analysesStore.getState().analysisId
        if (!analysisId) return
        const resp = await api.videoAnnotationsApi.videoannotationAnalysesClipsList(
          {
            analysisId: analysisId.toString(),
          }
        )
        const clipsExtended = resp.data.map((c) => extendClip(c, project))

        set({ clips: clipsExtended })
        if (get().clipId) {
          const clip = clipsExtended.find((c) => c.id === get().clipId)
          if (clip) {
            set({ clip: clip })
          }
        }
      },
      setClip: (id: number) => {
        const params = new URLSearchParams()
        params.set('clip', id.toString())
        setSearchParams(params, { replace: true })
      },
    }))
  )

  const clipId = useStore((state) => state.clipId)

  // fetch clips based on analysis
  React.useEffect(() => {
    useStore.getState().fetchClips()
  }, [analysisId])

  // set clipId based on search params
  React.useEffect(() => {
    if (clipParam) {
      useStore.setState({ clipId: clipParam })
    }
  }, [clipParam])

  // set clip object based on clipId
  useAsync(async () => {
    if (clipId) {
      let clip = useStore.getState().clips?.find((clip) => clip.id === clipId)
      if (clip) {
        useStore.setState({ clip: clip })
      } else {
        const resp = await api.clipsApi.projectsClipsRead({
          id: clipId.toString(),
          projectId: project.id.toString(),
        })
        clip = extendClip(resp.data, project)

        analysesStore.setState({ analysisId: clip.analysis })
        useStore.setState({ clip: clip })
      }

      vodStore.getState().gotoVideo({
        streamId: clip.stream,
        dateTime: DateTime.fromISO(clip.start_datetime, {
          zone: project.timezone,
        }),
        speed: clip.speed,
        endDateTime: clip.end_datetime
          ? DateTime.fromISO(clip.end_datetime, { zone: project.timezone })
          : undefined,
        play: true,
        crop: validateXYWH(clip),
      })
    }
  }, [clipId])

  return (
    <ClipsContext.Provider value={useStore}>{children}</ClipsContext.Provider>
  )
}
