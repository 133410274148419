import MaterialTable, { Action, Column, Options } from '@material-table/core'
import { TablePagination } from '@material-ui/core'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { AsyncFnReturn } from 'react-use/lib/useAsync'
import { queryKeys } from '../helpers/constants'

const defaultOptions = {
  paging: true,
  sorting: false,
  emptyRowsWhenPaging: false,
}

export function RemotelyPaginatedTable<T extends object>({
  title,
  options,
  columns,
  onSelectionChange,
  actions,
  asyncFnReturn,
  defaultPer = '10',
}: {
  title: string
  options: Options<T>
  columns: Column<T>[]
  onSelectionChange?: (data: T[]) => void
  actions?: Action<T>[]
  asyncFnReturn: AsyncFnReturn
  defaultPer?: string
}) {
  const [fetchState, fetch] = asyncFnReturn
  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.PER]: defaultPer,
    [queryKeys.PAGE]: '1',
  })
  const perParam = parseInt(searchParams.get(queryKeys.PER)!)
  const pageParam = parseInt(searchParams.get(queryKeys.PAGE)!)

  return (
    <MaterialTable
      title={title}
      data={fetchState.value?.results || []}
      onSelectionChange={onSelectionChange}
      options={{ ...defaultOptions, ...options, pageSize: perParam }}
      columns={columns}
      isLoading={fetchState.loading}
      actions={actions}
      components={{
        Pagination: (props) => (
          <TablePagination
            page={pageParam - 1}
            onPageChange={(e, page) => {
              fetch({ page: page + 1 })
              searchParams.set(queryKeys.PAGE, (page + 1).toString())
              setSearchParams(searchParams, { replace: true })
            }}
            rowsPerPage={perParam}
            onRowsPerPageChange={(e) => {
              // TODO: check if this bug is resolved https://github.com/mbrn/material-table/issues/1805
              const per = e.target.value
              fetch({ per: per })
              searchParams.set(queryKeys.PER, per.toString())
              setSearchParams(searchParams, { replace: true })
            }}
            count={fetchState.value?.count || 0}
          />
        ),
      }}
    />
  )
}
