import { useTheme } from '@material-ui/core'
import Konva from 'konva'
import React from 'react'
import { Group, Rect, Text } from 'react-konva'
import { useCallbackRef } from 'use-callback-ref'
import { useUseTimelineStore } from './Timeline'

export const TimelineRowContent: React.FC<{
  labelText: string
  labelColor?: string
  onClickLabel?: () => void
  onClickContent?: () => void
}> = ({ labelText, labelColor, onClickLabel, onClickContent, children }) => {
  const theme = useTheme()
  const useTimelineStore = useUseTimelineStore()

  const rowHeight = useTimelineStore((state) => state.rowHeight)
  const width = useTimelineStore((state) => state.width)
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)

  const textRef = useCallbackRef<Konva.Text>(null, (text) => {
    if (text) {
      text.cache()
    }
  })

  return (
    <Group>
      <Group
        clip={{
          x: 0,
          y: 0,
          width: leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={0}
          y={0}
          width={leftColumnWidth}
          height={rowHeight}
          onClick={onClickLabel}
        />
        <Text
          ref={textRef}
          text={labelText}
          fill={labelColor || theme.palette.text.primary}
          x={3}
          y={4}
          fontSize={12}
          listening={false}
        />
      </Group>
      <Group
        clip={{
          x: leftColumnWidth,
          y: 0,
          width: width - leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={leftColumnWidth}
          y={0}
          width={width - leftColumnWidth}
          height={rowHeight}
          onClick={onClickContent}
        />
        {children}
      </Group>
    </Group>
  )
}
