import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import { Layer } from 'react-konva'
import { ImageAnnotationBox } from '../../api/codegen/typescript-axios'
import { AnnotatorContext, LabelState } from './Annotator'
import { AnnotatorCanvasBoxes } from './AnnotatorCanvasBoxes'
import { LabelStateControl } from './LabelStateControl'
import { VideoCanvas } from './VideoCanvas'

export const AnnotatorCanvasPreview = ({
  imageUrl,
  boxes,
  showToolbar = true,
  style,
}: {
  imageUrl: string
  boxes: ImageAnnotationBox[]
  showToolbar?: boolean
  style?: React.CSSProperties
}) => {
  const [selectedBox, setSelectedBox] = useState<ImageAnnotationBox>()
  const [hiddenBoxes, setHiddenBoxes] = useState<ImageAnnotationBox[]>([])
  const [labelState, setLabelState] = useState<LabelState>('all')

  return (
    <Box height="100%" display="flex" flexDirection="column" style={style}>
      <Box position="relative" flexGrow="1">
        <VideoCanvas
          imageUrl={imageUrl}
          showVideo={false}
          listening={true}
          setSelectedBox={setSelectedBox}
          mode={'select'}
          scaleExtent={[0.94, 10]}
        >
          <AnnotatorContext.Provider
            value={{
              selectedBox,
              setSelectedBox,
              hiddenBoxes,
              setHiddenBoxes,
            }}
          >
            <Layer>
              <AnnotatorCanvasBoxes
                boxes={boxes}
                selectedBox={selectedBox}
                setSelectedBox={setSelectedBox}
                labelState={labelState}
              />
            </Layer>
          </AnnotatorContext.Provider>
        </VideoCanvas>
      </Box>
      {showToolbar && (
        <Box p={1}>
          <LabelStateControl
            labelState={labelState}
            setLabelState={setLabelState}
          />
        </Box>
      )}
    </Box>
  )
}
