import fileDownload from 'js-file-download'
import log from 'loglevel'
import { FC, useEffect, useState } from 'react'
import useScript from 'react-script-hook'
import slugify from 'slugify'
import { v4 as uuidv4 } from 'uuid'
import zustand, { UseStore } from 'zustand'
import logoImg from '../../../images/logo-white.png'
import { createCtx } from '../../helpers/createCtx'

export type StoreState = {
  id: string
  scriptReady: boolean
  xmlUrl?: string
  krPanoElement?: PanoDivElement
  viewState: {
    hlookat: number
    vlookat: number
    fov: number
  }
  globalCallbacks: Record<string, Function>
  downloadScreenshot: (label?: string) => void
}

interface Window {
  _krpanoGlobalCallbacks: Record<string, Record<string, Function>>
}

export interface PanoDivElement extends HTMLDivElement {
  call: (a: any) => void
  get: (a: any) => any
  set: (a: any, value: any) => void
  unload: () => void
  screentosphere: (x: number, y: number) => any
  spheretoscreen: (h: number, v: number) => any
}

export type UseKRPanoStore = UseStore<StoreState>

export const [useKRPanoStore, KRPanoContext] = createCtx<UseKRPanoStore>()

export const KRPanoProvider: FC<{
  xmlUrl?: string
  handlers?: {
    onViewChange?: (viewState: {
      hlookat: number
      vlookat: number
      fov: number
    }) => void
  }
}> = ({ xmlUrl, handlers, children }) => {
  // Can't use useProject in this component because
  // it's not a child of ProjectWrapper on the aerial tour share page

  // init global callbacks dict
  if (!('_krpanoGlobalCallbacks' in window)) {
    window._krpanoGlobalCallbacks = {}
  }

  // init store
  const [store] = useState<UseStore<StoreState>>(() => {
    const id = 'krpano_' + uuidv4()

    return zustand<StoreState>((set, get) => ({
      id: id,
      scriptReady: false,
      xmlUrl: xmlUrl,
      krPanoElement: undefined,
      viewState: {
        hlookat: 0,
        vlookat: 0,
        fov: 0,
      },
      globalCallbacks: {
        onviewchanged: () => {
          const krpano = get().krPanoElement
          if (krpano) {
            const previousViewState = get().viewState
            const hlookat = Number(krpano.get('view.hlookat'))
            const vlookat = Number(krpano.get('view.vlookat'))
            const fov = Number(krpano.get('view.fov'))
            if (
              previousViewState.hlookat !== hlookat ||
              previousViewState.vlookat !== vlookat ||
              previousViewState.fov !== fov
            ) {
              const viewState = { hlookat: hlookat, vlookat: vlookat, fov }
              log.debug('KRPano view state: ', viewState)
              set({ viewState })
              handlers?.onViewChange &&
                handlers?.onViewChange({ hlookat, vlookat, fov })
            }
          }
        },
      },
      downloadScreenshot: (label) => {
        const krpanoCanvas = get().krPanoElement?.querySelector('canvas')
        const outputCanvas = document.createElement('canvas')
        const outputCtx = outputCanvas.getContext('2d')
        if (krpanoCanvas && outputCtx) {
          outputCanvas.width = krpanoCanvas.width
          outputCanvas.height = krpanoCanvas.height
          outputCtx.drawImage(krpanoCanvas, 0, 0)

          let fileName = 'screenshot'

          // write label
          if (label) {
            outputCtx.font = 'bold 40px Arial'
            outputCtx.fillStyle = 'white'
            outputCtx.strokeStyle = 'black'
            outputCtx.lineWidth = 8
            outputCtx.miterLimit = 1
            outputCtx.strokeText(label, 10, 40)
            outputCtx.fillText(label, 10, 40)
            fileName = slugify(label.replace('/', '-'))
          }

          const watermarkImage = new Image()

          watermarkImage.onload = () => {
            outputCtx.globalAlpha = 0.5

            const width = 400
            const scale = width / watermarkImage.width
            const height = watermarkImage.height * scale

            outputCtx.drawImage(
              watermarkImage,
              outputCanvas.width - width - 10,
              outputCanvas.height - height - 10,
              width,
              height
            )
            outputCanvas.toBlob((blob) => {
              if (!blob) throw new Error('Failed to convert to blob')
              fileDownload(blob, fileName + '.jpg')
            }, 'image/jpg')
          }

          watermarkImage.src = logoImg
        }
      },
    }))
  })

  window._krpanoGlobalCallbacks = window._krpanoGlobalCallbacks || {}

  window._krpanoGlobalCallbacks[
    store.getState().id
  ] = store.getState().globalCallbacks

  const [scriptLoading, scriptError] = useScript({
    src: process.env.PUBLIC_URL + '/krpano/krpano.js',
    checkForExisting: true,
  })

  useEffect(() => {
    if (xmlUrl && !scriptLoading && !scriptError)
      store.setState({ xmlUrl, scriptReady: true })
  }, [xmlUrl, scriptLoading, scriptError])

  // register event listeners
  useEffect(() => {
    store.subscribe(
      () => {
        const { krPanoElement, id, globalCallbacks } = store.getState()
        if (krPanoElement) {
          Object.keys(globalCallbacks).forEach((key) => {
            krPanoElement.set(
              `events.${key}`,
              `jscall(window._krpanoGlobalCallbacks['${id}'].${key}())`
            )
          })
        }
      },
      (state) => state.krPanoElement
    )
  }, [])

  return (
    <KRPanoContext.Provider value={store}>{children}</KRPanoContext.Provider>
  )
}
