import { DateTime, Duration } from 'luxon'
import { Idle } from '../components/VODPlayer/CranePicks/CranePicksController'
import { darkTheme } from '../styles/theme'
import { CraneCalendarEntryExtended } from './CraneCalendarEntryExtended'
import { CranePickExtended } from './CranePickExtended'
import { ProjectConfig, Subcontractor } from './codegen/typescript-axios'

export interface CranePicksCollection {
  startDateTime?: DateTime
  endDateTime?: DateTime
  picks: CranePickExtended[]
  idles: Idle[]
  idleDuration: Duration
  activeDuration: Duration
  valueAddedDuration: Duration
  nonValueAddedDuration: Duration
  totalDuration: Duration
  shiftDuration: Duration
  breakDuration: Duration
  emptyDuration: Duration
  emptyIdleDuration: Duration
  nonEmptyDuration: Duration
  nonEmptyIdleDuration: Duration
  utilization: number
  utilizationColor: string
  utilizationValueAdded: number
  utilizationNonValueAdded: number
  minutesPerPick: number
  picksPerHour: number
  dates: ({ date: string } & CranePicksCollection)[]
  subcontractors: ({ subcontractor: Subcontractor } & CranePicksCollection)[]
  calendarEntries?: CraneCalendarEntryExtended[]
}

export function createSumPicksFunction(projectConfig?: ProjectConfig) {
  return function sumPicks<
    T extends {
      picks: CranePickExtended[]
      isoDates?: string[]
      calendarEntries?: CraneCalendarEntryExtended[]
      subcontractors?: Map<number | null, Subcontractor>
    }
  >(args: T): T & CranePicksCollection {
    // create idles array
    const idles: Idle[] = []

    args.picks.forEach((pick) => {
      if (pick.inBetweenDuration.toMillis() > 0) {
        idles.push({
          startDateTime: pick.segments[0].start,
          systemStartDateTime: pick.segments[0].start.setZone('system', {
            keepLocalTime: true,
          }),
          endDateTime: pick.segments[0].end,
          systemEndDateTime: pick.segments[0].end.setZone('system', {
            keepLocalTime: true,
          }),
        })
      }
    })

    // compute sums of picks
    const sums = args.picks.reduce(
      (sums, pick) => {
        if (projectConfig?.count_intra_pick_idles_toward_utilization) {
          sums.idleDuration = sums.idleDuration.plus(pick.inBetweenDuration)

          sums.activeDuration = sums.activeDuration
            .plus(pick.activeDuration)
            .plus(pick.idleDuration)

          if (pick.action !== 'move' && pick.rework !== true) {
            sums.valueAddedDuration = sums.valueAddedDuration
              .plus(pick.activeDuration)
              .plus(pick.idleDuration)
          } else {
            sums.nonValueAddedDuration = sums.nonValueAddedDuration
              .plus(pick.activeDuration)
              .plus(pick.idleDuration)
          }
        } else {
          sums.idleDuration = sums.idleDuration
            .plus(pick.idleDuration)
            .plus(pick.inBetweenDuration)

          sums.activeDuration = sums.activeDuration.plus(pick.activeDuration)

          if (pick.action !== 'move' && pick.rework !== true) {
            sums.valueAddedDuration = sums.valueAddedDuration.plus(
              pick.activeDuration
            )
          } else {
            sums.nonValueAddedDuration = sums.nonValueAddedDuration.plus(
              pick.activeDuration
            )
          }
        }

        sums.emptyDuration = sums.emptyDuration.plus(pick.emptyDuration)
        sums.emptyIdleDuration = sums.emptyIdleDuration.plus(
          pick.emptyIdleDuration
        )
        sums.nonEmptyDuration = sums.nonEmptyDuration.plus(
          pick.nonEmptyDuration
        )
        sums.nonEmptyIdleDuration = sums.nonEmptyIdleDuration.plus(
          pick.nonEmptyIdleDuration
        )

        return sums
      },
      {
        idleDuration: Duration.fromMillis(0),
        activeDuration: Duration.fromMillis(0),
        valueAddedDuration: Duration.fromMillis(0),
        nonValueAddedDuration: Duration.fromMillis(0),
        emptyDuration: Duration.fromMillis(0),
        emptyIdleDuration: Duration.fromMillis(0),
        nonEmptyDuration: Duration.fromMillis(0),
        nonEmptyIdleDuration: Duration.fromMillis(0),
      }
    )

    const totalDuration = sums.idleDuration.plus(sums.activeDuration)
    const utilization =
      sums.activeDuration.toMillis() / totalDuration.toMillis() || 0

    const utilizationValueAdded =
      sums.valueAddedDuration.toMillis() / totalDuration.toMillis() || 0

    let sumsByDate: ({ date: string } & CranePicksCollection)[] = []
    let sumsBySubcontractor: ({
      subcontractor: Subcontractor
    } & CranePicksCollection)[] = []

    if (args.isoDates) {
      sumsByDate = args.isoDates.map((isoDate) => {
        return sumPicks({
          date: isoDate,
          picks: args.picks.filter((pick) => pick.isoDate === isoDate),
          calendarEntries: args.calendarEntries?.filter(
            (entry) => entry.isoDate === isoDate
          ),
          subcontractors: args.subcontractors,
        })
      })
    }

    if (args.subcontractors) {
      sumsBySubcontractor = Array.from(args.subcontractors.values()).map(
        (subcontractor) => {
          return sumPicks({
            subcontractor: subcontractor,
            picks: args.picks.filter(
              (pick) => pick.subcontractor_id === subcontractor.id
            ),
            isoDates: args.isoDates,
            calendarEntries: args.calendarEntries?.filter(
              (entry) => entry.subcontractor_link === subcontractor.id
            ),
          })
        }
      )
    }

    const getShiftDuration = (_picks: CranePickExtended[]) => {
      return args.picks.length > 0
        ? args.picks[args.picks.length - 1].endDateTime.diff(
            args.picks[0].segments[0].start
          )
        : Duration.fromMillis(0)
    }

    const shiftDuration = args.isoDates
      ? sumsByDate.reduce((sum, date) => {
          return sum.plus(date.shiftDuration)
        }, Duration.fromMillis(0))
      : getShiftDuration(args.picks)

    const getBreakDuration = (_picks: CranePickExtended[]) => {
      return args.picks.length > 0
        ? getShiftDuration(args.picks).minus(totalDuration)
        : Duration.fromMillis(0)
    }

    const breakDuration =
      args.isoDates && args.isoDates.length > 1
        ? sumsByDate.reduce(
            (sum, date) => sum.plus(getBreakDuration(date.picks)),
            Duration.fromMillis(0)
          )
        : getBreakDuration(args.picks)

    return {
      ...args,
      idles,
      ...sums,
      totalDuration,
      shiftDuration,
      breakDuration,
      utilization,
      utilizationColor:
        utilization > 0.9
          ? darkTheme.palette.success.main
          : utilization > 0.5
          ? darkTheme.palette.warning.main
          : darkTheme.palette.error.main,
      utilizationValueAdded,
      utilizationNonValueAdded: utilization - utilizationValueAdded,
      minutesPerPick:
        sums.activeDuration.toMillis() / args.picks.length / 1000 / 60,
      picksPerHour:
        (args.picks.length / totalDuration.toMillis()) * 1000 * 60 * 60,
      startDateTime: args.picks[0]?.startDateTime,
      endDateTime: args.picks[args.picks.length - 1]?.endDateTime,
      dates: sumsByDate,
      subcontractors: sumsBySubcontractor,
      calendarEntries: args.calendarEntries,
    }
  }
}
