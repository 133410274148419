import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import log from 'loglevel'
import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useCallbackRef } from 'use-callback-ref'
import { mixins } from '../../styles/mixins'
import { PanoDivElement, useKRPanoStore } from './KRPanoProvider'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...mixins.absoluteFill,
    },
  })
)

declare global {
  interface Window {
    krpanoJS: any
  }
}

export const KRPanoCanvas = ({
  elementProps,
}: {
  elementProps?: React.HTMLProps<HTMLDivElement>
}) => {
  const classes = useStyles()

  const krpanoStore = useKRPanoStore()
  const id = krpanoStore((state) => state.id)
  const panoElement = krpanoStore((state) => state.krPanoElement)
  const xmlUrl = krpanoStore((state) => state.xmlUrl)
  const scriptReady = krpanoStore((state) => state.scriptReady)

  const [, forceUpdate] = useState()

  const ref = useCallbackRef(null, (v) => forceUpdate(undefined))

  useAsync(async () => {
    log.debug('KRPanoCanvas useEffect', {
      scriptReady,
      ref: !!ref.current,
      xmlUrl: !!xmlUrl,
    })

    if (!scriptReady || !ref.current || !xmlUrl) return

    if (panoElement) {
      panoElement.unload()
      krpanoStore.setState({ krPanoElement: undefined })
    }

    window.krpanoJS.embedpano({
      xml: xmlUrl,
      target: id,
      id: id + '_id',
      html5: 'only',
      mobilescale: 1.0,
      passQueryParameters: false,
      webglsettings: { preserveDrawingBuffer: true },
      basepath: process.env.PUBLIC_URL + '/krpano/',
      onready: (krpano: PanoDivElement) => {
        krpanoStore.setState({ krPanoElement: krpano })
      },
    })

    log.debug('KRPano canvas initialized')
  }, [scriptReady, ref.current, xmlUrl])

  return (
    <div
      ref={ref}
      id={id}
      className={clsx(classes.root)}
      {...elementProps}
    ></div>
  )
}
