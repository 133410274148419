import React from 'react'
import { Navigate } from 'react-router-dom'
import { useApi } from './ApiContext'

export const LogoutPage = () => {
  const api = useApi()

  api.logout()

  return <Navigate replace to={`/login`} />
}
