import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import { DateTime } from 'luxon'
import React from 'react'
import { useAsyncFn } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import { ArmingScheduleItem } from '../../api/codegen/typescript-axios'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { useProject } from '../ProjectWrapper'
import { SecuritySwitch } from './SecuritySwitch'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      maxWidth: 700,
      padding: 10,
    },
  })
)

const getDateTime = (
  event: ArmingScheduleItem & { error: string; id: string }
) => {
  try {
    const day = DateTime.fromFormat(`${event.day + 1}`, 'E')
    const time = DateTime.fromFormat(`${event.event_time}`, 'H:mm:ss')
    const dateTime = DateTime.fromFormat(
      `${event.day + 1}:${event.event_time}`,
      'E:H:mm:ss'
    )
    return dateTime
  } catch (err) {
    if (err instanceof Error) {
      event.error = err.message
    }
    throw err
  }
}

export const SecurityScheduleEditor = ({
  data,
  onClose,
}: {
  data: ArmingScheduleItem[]
  onClose: () => void
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const api = useApi()
  const project = useProject()

  const [tempData, setTempData] = React.useState(
    data.map((d) => ({ ...d, id: uuidv4(), error: '' }))
  )
  const [dirty, setDirty] = React.useState(false)

  const [error, setError] = React.useState('')

  const [fetchState, refetch] = useAsyncFn(async () => {
    try {
      // check that first and last are different
      if (tempData[0].armed === tempData[tempData.length - 1].armed) {
        throw new Error(
          'The first and last events must have different "armed" values'
        )
      }

      let previous:
        | undefined
        | { armed: boolean; dateTime: DateTime } = undefined
      const dateTimes = tempData.map((event, i) => {
        // validate dates and times
        const dateTime = getDateTime(event)

        // check that arms and disarms alternate
        if (previous) {
          if (event.armed === previous.armed) {
            throw new Error('Events must alternate armed and disarmed')
          }

          if (dateTime <= previous.dateTime) {
            throw new Error(
              'Events must be in chronological order. Click the sort button to order them.'
            )
          }
        }

        previous = {
          armed: event.armed,
          dateTime: dateTime,
        }
      })

      setError('')

      const resp = await api.securityApi.projectsSecurityScheduleUpdateAll({
        projectId: project.id.toString(),
        data: tempData,
      })

      onClose()
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }, [tempData])

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Armed</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tempData.map((event, i) => (
              <React.Fragment key={event.id}>
                <TableRow>
                  <TableCell>
                    <Select
                      native
                      value={event.day}
                      onChange={(e) => {
                        setDirty(true)
                        tempData[i] = {
                          ...event,
                          day: parseInt(e.target.value as string),
                        }
                        setTempData([...tempData])
                      }}
                    >
                      {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                        <option value={day} key={day}>
                          {DateTime.fromObject({
                            weekday: day + 1,
                          }).toLocaleString({
                            weekday: 'long',
                          })}
                        </option>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      error={!!event.error}
                      value={event.event_time}
                      onChange={(e) => {
                        setDirty(true)
                        tempData[i] = {
                          ...event,
                          event_time: e.target.value as string,
                          error: '',
                        }
                        setTempData([...tempData])
                      }}
                      onBlur={() => {
                        try {
                          getDateTime(event)
                        } catch (err) {
                          if (err instanceof Error) {
                            tempData[i] = {
                              ...event,
                              error: err.message,
                            }
                            setTempData([...tempData])
                          }
                        }
                      }}
                    >
                      {event.event_time}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <SecuritySwitch
                          checked={event.armed}
                          onChange={(e) => {
                            setDirty(true)
                            tempData[i] = {
                              ...event,
                              armed: e.target.checked,
                            }
                            setTempData([...tempData])
                          }}
                          name="armed"
                          color="primary"
                        />
                      }
                      label={event.armed ? 'Armed' : 'Disarmed'}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setDirty(true)
                        console.log(tempData)
                        setTempData([
                          ...tempData.filter((e, j) => {
                            return j !== i
                          }),
                        ])
                      }}
                    >
                      <DeleteIcon></DeleteIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
                {event.error && (
                  <TableRow>
                    <TableCell colSpan={4} style={{ padding: 0 }}>
                      <Alert severity="error">{event.error}</Alert>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
          style={{
            marginRight: 10,
            whiteSpace: 'nowrap',
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setTempData([
              ...tempData,
              {
                day: 0,
                event_time: '00:00:00',
                armed: true,
                id: uuidv4(),
                error: '',
              },
              {
                day: 0,
                event_time: '01:00:00',
                armed: false,
                id: uuidv4(),
                error: '',
              },
            ])
          }}
          style={{
            marginRight: 10,
            whiteSpace: 'nowrap',
          }}
        >
          Add
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            try {
              setTempData([
                ...tempData.sort((a, b) => {
                  return getDateTime(a).toMillis() - getDateTime(b).toMillis()
                }),
              ])
            } catch (err) {}
          }}
          style={{
            marginRight: 10,
            whiteSpace: 'nowrap',
          }}
        >
          Sort
        </Button>
        <ProgressButton
          disabled={!dirty}
          fetchState={fetchState}
          onClick={refetch}
        >
          Save
        </ProgressButton>
      </Grid>
    </Grid>
  )
}
