import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import ZoomIn from '@material-ui/icons/ZoomIn'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import distance from '@turf/distance'
import { RiCursorFill } from 'react-icons/ri'
import { useDeckVerificationStore } from './DeckVerificationController'
import SaveIcon from '@material-ui/icons/Save'

export const DeckVerificationControlPanel = () => {
  const theme = useTheme()
  const store = useDeckVerificationStore()

  const deckFeatures = store((state) => state.deckFeatures)
  const selectionModel = store((state) => state.selectionModel)
  const drawMode = store((state) => state.drawMode)

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.properties.name,
      width: 150,
      editable: true,
    },
    {
      field: 'Offset',
      headerName: 'Offset',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${distance(
          params.row.geometry.coordinates[0],
          params.row.geometry.coordinates[1],
          { units: 'inches' }
        ).toFixed(1)} in`,
    },
    {
      field: ' ',
      headerName: ' ',
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              store.getState().zoomToFeature(params.id as string)
            }}
          >
            <ZoomIn />
          </IconButton>
          <IconButton
            onClick={() => {
              store.getState().deleteFeature(params.id as string)
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ]

  return (
    <div
      style={{
        padding: theme.spacing(1),
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* Draw mode button group */}
          <ButtonGroup fullWidth variant="contained">
            <Button
              color={
                drawMode === 'simple_select' || drawMode === 'direct_select'
                  ? 'primary'
                  : 'default'
              }
              onClick={() => {
                store.getState().changeMode('simple_select')
              }}
            >
              <RiCursorFill />
            </Button>
            <Button
              color={drawMode === 'draw_line_string' ? 'primary' : 'default'}
              onClick={() => {
                store.getState().changeMode('draw_line_string')
              }}
            >
              <AddIcon />
            </Button>
            <Button
              color={'default'}
              onClick={() => {
                store.getState().saveToStorage()
              }}
            >
              <SaveIcon />
            </Button>
          </ButtonGroup>
        </Grid>
        <DataGrid
          rows={Array.from(deckFeatures.values())}
          columns={columns}
          autoHeight={true}
          selectionModel={selectionModel}
          getRowId={(row) => row.id}
          onSelectionModelChange={(newSelectionModel) => {
            store.getState().setSelectionModel(newSelectionModel)
          }}
          onCellEditCommit={({ id, field, value }) => {
            store.getState().setFeatureName(id as string, value as string)
          }}
        />
      </Grid>
    </div>
  )
}
