import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DateTime } from 'luxon'
import React from 'react'
import { VODDateTimeTextInput } from '../VODDateTimeTextInput'
import { useCraneStore } from './CranePicksController'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { useProject } from '../../ProjectWrapper'
import { Subcontractor } from '../../../api/codegen/typescript-axios'
import { actions } from './options'
import { t } from 'i18next'

export const CranePickEventForm = () => {
  const craneStore = useCraneStore()
  const project = useProject()

  const selectedCrane = craneStore((state) => state.selectedCrane)
  const subcontractors = craneStore((state) => state.subcontractors)
  const loadOptions = craneStore((state) => state.loadOptions)
  const craneEventStates = craneStore((state) => state.craneEventStates)
  const formState = craneStore((state) => state.eventFormState)
  const prevSubcontractor = craneStore((state) => state.prevSubcontractor)
  const prevLoad = craneStore((state) => state.prevLoad)
  const prevAction = craneStore((state) => state.prevAction)

  const stateSlug = formState.state
    ? craneEventStates.get(formState.state)?.slug
    : undefined

  const isLoadState =
    stateSlug === 'loading' ||
    stateSlug === 'unloading' ||
    stateSlug === 'install'

  if (!selectedCrane) return null

  return (
    <Card
      style={{
        position: 'absolute',
        top: 1,
        left: 0,
        width: '100%',
        padding: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>State</InputLabel>
            <Select
              native
              value={formState.state}
              onChange={(e) =>
                craneStore.getState().editEventForm({
                  state: parseInt(e.target.value as string),
                })
              }
            >
              {Array.from(craneEventStates.values()).map((eventState) => (
                <option key={eventState.id} value={eventState.id}>
                  {eventState.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <VODDateTimeTextInput
            label="DateTime"
            value={
              formState.event_datetime
                ? DateTime.fromISO(formState.event_datetime, {
                    zone: project.timezone,
                  })
                : DateTime.now()
            }
            onChange={(d) => {
              craneStore.getState().editEventForm({
                event_datetime: d?.toISO(),
              })
            }}
            nullable={false}
          />
        </Grid>

        {isLoadState && (
          <>
            <Grid item xs={12} container spacing={1} alignItems="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  disabled={!prevSubcontractor}
                  title="Copy previous value"
                  onClick={() => {
                    craneStore.getState().editEventForm({
                      subcontractor_link: prevSubcontractor,
                    })
                  }}
                >
                  <DoubleArrowIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Select
                  fullWidth
                  native
                  value={formState.subcontractor_link}
                  onChange={(e) => {
                    craneStore.getState().editEventForm({
                      subcontractor_link: parseInt(e.target.value as string),
                    })
                  }}
                >
                  <option value={0}>Select subcontractor</option>
                  {Array.from(subcontractors.values()).map((subcontractor) => (
                    <option key={subcontractor.id} value={subcontractor.id}>
                      {subcontractor.name}
                    </option>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={1} alignItems="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  disabled={!prevLoad}
                  title="Copy previous value"
                  onClick={() => {
                    craneStore.getState().editEventForm({
                      subcontractor_link: prevSubcontractor,
                      load_data: prevLoad,
                    })
                  }}
                >
                  <DoubleArrowIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Autocomplete
                  size="small"
                  freeSolo
                  selectOnFocus
                  autoComplete
                  autoSelect
                  blurOnSelect
                  options={Array.from(loadOptions.keys())}
                  value={formState.load_data || ''}
                  onChange={(e, value) => {
                    console.log('onChange', value)
                    craneStore.getState().editEventForm({
                      load_data: value,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Load data"
                      fullWidth
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        fullWidth: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems="flex-end">
              <Grid item>
                <IconButton
                  size="small"
                  disabled={!prevAction}
                  title="Copy previous value"
                  onClick={() => {
                    craneStore.getState().editEventForm({
                      subcontractor_link: prevSubcontractor,
                      load_data: prevLoad,
                      action: prevAction,
                    })
                  }}
                >
                  <DoubleArrowIcon />
                </IconButton>
              </Grid>

              <Grid item style={{ flexGrow: 1 }}>
                <Autocomplete
                  size="small"
                  selectOnFocus
                  autoComplete
                  autoSelect
                  blurOnSelect
                  options={actions}
                  getOptionLabel={(option) =>
                    t('actions.' + option, { defaultValue: option })
                  }
                  value={formState.action || ''}
                  onChange={(e, value) => {
                    console.log('onChange', value)
                    craneStore.getState().editEventForm({
                      action: value,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Action"
                      fullWidth
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        fullWidth: true,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                {/* material-ui checkbox to set rework attribute*/}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.rework}
                      onChange={(e) => {
                        craneStore.getState().editEventForm({
                          rework: e.target.checked,
                        })
                      }}
                    />
                  }
                  label="Rework"
                />
              </Grid>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {formState.created_at && (
            <Typography variant="body2">
              Created :{' '}
              {DateTime.fromISO(formState.created_at).toLocaleString(
                DateTime.DATETIME_SHORT
              )}{' '}
              {formState.created_by_attrs?.email}
            </Typography>
          )}
          {formState.updated_at && (
            <Typography variant="body2">
              Updated:{' '}
              {DateTime.fromISO(formState.updated_at).toLocaleString(
                DateTime.DATETIME_SHORT
              )}{' '}
              {formState.updated_by_attrs?.email}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="default"
              fullWidth
              onClick={() => {
                craneStore.setState({
                  eventFormState: {},
                })
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="default"
              fullWidth
              onClick={() => {
                craneStore.getState().deleteEvent({
                  craneId: selectedCrane.id,
                  eventId: formState.id!,
                })
              }}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                craneStore.getState().saveEvent(formState)
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
