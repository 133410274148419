import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { Fragment, useMemo } from 'react'
import { extendStream, StreamExtended } from '../../api/StreamExtended'
import { useProject } from '../ProjectWrapper'
import { VideoPlayerHLS } from '../Video/VideoPlayerHLS'
import { UseDashboardStore } from './DashboardController'
import { LiveAICountChartController } from './LiveAICountController'
import { WeatherWindy } from './WeatherWindy'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      width: '100%',
      '& video': {
        display: 'block',
      },
    },
    alert: {
      borderRadius: 0,
      border: '1px solid #000',
      borderTopWidth: 0,
      fontWeight: 'bold',
      boxSizing: 'border-box',
    },
    bottomAlert: {
      position: 'fixed',
      right: 0,
      left: 0,
      bottom: 0,
    },
    bottomAlertSpacer: {
      position: 'static',
      visibility: 'hidden',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        left: 0,
      },
    },
    videoContainer: {
      border: '1px solid #000',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    player: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
)

export const DashboardLayout = ({
  useDashboardStore,
}: {
  useDashboardStore: UseDashboardStore
}) => {
  const classes = useStyles()
  const project = useProject()

  const streams: StreamExtended[] = useMemo(() => {
    return project.streams
      .filter((s) => s.live && s.has_ai_data)
      .map((s) => extendStream(s, project))
  }, [project])

  return (
    <div className={classes.root}>
      <Grid container>
        {streams.map((stream) => (
          <Fragment key={stream.id}>
            <Grid
              item
              key={stream.url}
              style={{ width: '60%' }}
              className={classes.videoContainer}
            >
              <VideoPlayerHLS
                stream={stream}
                className={classes.player}
                disableIntersectionObserver={false}
                extraControls={{
                  ptz: false,
                  play: true,
                  fullScreen: true,
                  enlarge: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={{ width: '40%' }}
              className={classes.videoContainer}
            >
              <LiveAICountChartController
                useDashboardStore={useDashboardStore}
                stream={stream}
              />
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12}>
          <WeatherWindy height={400} width={400} singleDay />
        </Grid>
      </Grid>
    </div>
  )
}
