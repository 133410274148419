import * as Sentry from '@sentry/react'
import { enableMapSet } from 'immer'
import log from 'loglevel'
import { Settings } from 'luxon'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { AppWrapper } from './js/components/AppWrapper'
import reportWebVitals from './reportWebVitals'

enableMapSet()

Settings.throwOnInvalid = true

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://23c9e184ccd34738851191ab277250c3@sentry.io/1886526',
    release: process.env.REACT_APP_VERSION,
  })
}

log.setDefaultLevel(
  process.env.NODE_ENV === 'production' ? log.levels.INFO : log.levels.DEBUG
)

log.info(`Topdeck v${process.env.REACT_APP_VERSION}`)

ReactDOM.render(
  // TODO: reenable when Material Table fixes
  // <React.StrictMode>
  <AppWrapper />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
