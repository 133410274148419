import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Portal,
  Select,
  TextField,
  useTheme,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { ScaleTime } from 'd3-scale'
import { DateTime } from 'luxon'
import React from 'react'
import { useGetSet } from 'react-use'
import { CraneEvent } from '../../../api/codegen/typescript-axios'
import { useMe } from '../../../hooks/useMe'
import { KeyedButton } from '../../Video/KeyedButton'
import { getUseVODStore } from '../VODController'
import { CranePickEventForm } from './CranePickEventForm'
import { useCraneStore } from './CranePicksController'

export const CranePickEvents = ({
  scale,
  portalRef,
}: {
  scale: ScaleTime<number, number, never>
  portalRef: React.RefObject<HTMLDivElement>
}) => {
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()
  const { data: me } = useMe()
  const theme = useTheme()

  const gotoVideo = useVODStore((state) => state.gotoVideo)

  const selectedCrane = craneStore((state) => state.selectedCrane)
  const craneEventStates = craneStore((state) => state.craneEventStates)
  const events = craneStore((state) => state.events)
  const formState = craneStore((state) => state.eventFormState)
  const craneDay = craneStore((state) => state.craneDay)

  const isLoadState = (slug: string) =>
    slug === 'loading' || slug === 'unloading' || slug === 'install'

  const filters = new Map<string, (event: CraneEvent) => boolean>([
    ['No filter', (event) => true],
    [
      'Loading events',
      (event) => craneEventStates.get(event.state)?.slug === 'loading',
    ],
    [
      'Incomplete loading events',
      (event) =>
        craneEventStates.get(event.state)?.slug === 'loading' &&
        (!event.load_data || !event.subcontractor_link),
    ],
    [
      'Unloading events',
      (event) => craneEventStates.get(event.state)?.slug === 'unloading',
    ],
    [
      'Install events',
      (event) => craneEventStates.get(event.state)?.slug === 'install',
    ],
    [
      'Last updated',
      (event) => {
        if (!event.updated_at) {
          return false
        }
        return (
          DateTime.now().diff(DateTime.fromISO(event.updated_at)).as('hours') <
          getHoursAgo()
        )
      },
    ],
  ])

  const [filter, setFilter] = React.useState({
    label:
      me?.videoannotation_role === 'annotator' ? 'Loading events' : 'No filter',
    fn:
      me?.videoannotation_role === 'annotator'
        ? filters.get('Loading events')!
        : filters.get('No filter')!,
  })

  const [getHoursAgo, setHoursAgo] = useGetSet(24)

  if (!selectedCrane) return null

  const filteredEvents = events.filter(filter.fn)

  return (
    <div
      className="CranePickEvents"
      style={{ position: 'relative', zIndex: 3 }}
    >
      {me?.isAnnotator && (
        <Portal container={portalRef.current}>
          <>
            <div style={{ padding: theme.spacing(1) }}>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl>
                    <InputLabel>Filter</InputLabel>
                    <Select
                      value={filter.label}
                      onChange={(event) => {
                        const filterFn = filters.get(
                          event.target.value as string
                        )
                        if (!filterFn) {
                          return
                        }
                        setFilter({
                          label: event.target.value as string,
                          fn: filterFn,
                        })
                      }}
                      label="Filter"
                      native
                    >
                      {Array.from(filters.keys())
                        .filter((label) => {
                          if (me.videoannotation_role === 'annotator') {
                            return (
                              label === 'Loading events' ||
                              label === 'Incomplete loading events'
                            )
                          } else {
                            return true
                          }
                        })
                        .map((label) => (
                          <option key={label} value={label}>
                            {label}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  {filter.label === 'Last updated' && (
                    <TextField
                      type={'number'}
                      label={'Hours ago'}
                      value={getHoursAgo()}
                      onChange={(event) =>
                        setHoursAgo(Number(event.target.value))
                      }
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (craneDay) {
                        craneStore
                          .getState()
                          .editCraneDay({ complete: !craneDay.complete })
                      } else {
                        craneStore.getState().createCraneDay()
                      }
                    }}
                    style={{
                      color: craneDay?.complete
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                  >
                    {!craneDay
                      ? 'Create Annotation Task'
                      : craneDay.complete
                      ? 'Annotation complete'
                      : 'Annotation incomplete'}
                  </Button>
                </Grid>
              </Grid>
            </div>

            <Divider />

            <div style={{ padding: theme.spacing(1) }}>
              <Grid container spacing={1}>
                {Array.from(craneEventStates.values()).map((eventState, i) => (
                  <Grid item key={eventState.id}>
                    <KeyedButton
                      size="small"
                      text={`${i}. ${eventState.name}`}
                      keyCode={i.toString()}
                      dictionaryEntry={[
                        i.toString(),
                        `Create ${eventState.name} event`,
                      ]}
                      onMouseDown={(e) => {
                        if (e) {
                          e.preventDefault()
                        }
                      }}
                      onClick={() => {
                        craneStore.getState().createEvent(eventState)
                      }}
                    ></KeyedButton>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        </Portal>
      )}
      {filteredEvents.map((event) => {
        const yPos = scale(event.systemEventDateTime.toJSDate())

        const stateSlug = craneEventStates.get(event.state)?.slug || ''

        return (
          <div
            // button
            key={event.id}
            onClick={() => {
              craneStore.setState({ showVideo: true })
              gotoVideo({
                dateTime: event.eventDateTime,
                streamId: selectedCrane.jib_stream_original,
              })
            }}
            style={{
              position: 'absolute',
              top: yPos,
              left: 0,
              width: '100%',
              height: 1,
              backgroundColor: stateSlug === 'loading' ? '#0f0' : '#ff0',
              zIndex: formState.id === event.id ? 1 : 0,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                opacity: 0.3,
                backgroundColor: stateSlug === 'loading' ? '#0f0' : '#ff0',
                position: 'absolute',
                top: -16,
                left: 0,
                width: '100%',
                height: 16,
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: -16,
                left: 0,
                width: '100%',
                color: theme.palette.text.primary,
                fontSize: 12,
              }}
              onClick={(e) => {
                craneStore.setState({
                  showVideo: true,
                })
                gotoVideo({
                  dateTime: event.eventDateTime,
                  streamId: selectedCrane.jib_stream_original,
                })
              }}
            >
              {craneEventStates.get(event.state)?.name}
              {isLoadState(stateSlug) && (
                <>
                  {' - '}

                  {event.load_data || '?'}
                  {' - '}
                  {event.subcontractor_attrs?.name || '?'}
                </>
              )}
              {'   '}
              <EditIcon
                style={{
                  height: 12,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  craneStore.setState({ eventFormState: event })
                }}
              />
            </div>
            {formState.id === event.id && <CranePickEventForm />}
          </div>
        )
      })}
    </div>
  )
}
