// import { useContextBridge } from '@react-three/drei'
import { MuiThemeProvider, useTheme } from '@material-ui/core'
import Konva from 'konva'
import React from 'react'
import { Layer, Stage } from 'react-konva'
import { useContextBridge } from '../../../hooks/useContextBridge'
import { ThemeControlContext } from '../../Context/ThemeController'
import { ProjectContext } from '../../ProjectWrapper'
import { TimelineStoreContext, useUseTimelineStore } from './Timeline'
import { TimelineBackground } from './TimelineBackground'

export const TimelineCanvas = React.memo(({ children }) => {
  const theme = useTheme()
  const useTimelineStore = useUseTimelineStore()
  const width = useTimelineStore((state) => state.width)
  const height = useTimelineStore((state) => state.height)

  const layerRef = React.useRef<Konva.Layer>(null)

  React.useEffect(
    () =>
      useTimelineStore.subscribe(
        () => {
          layerRef.current?.batchDraw()
        },
        (state) => state.currentScale
      ),
    [useTimelineStore, layerRef]
  )

  const ContextBridge = useContextBridge(TimelineStoreContext, ProjectContext)

  return (
    <Stage width={width} height={height} style={{ position: 'absolute' }}>
      <MuiThemeProvider theme={theme}>
        <ContextBridge>
          <Layer ref={layerRef}>
            {children}
            <TimelineBackground />
          </Layer>
        </ContextBridge>
      </MuiThemeProvider>
    </Stage>
  )
})
