export const actions = [
  'set',
  'strip',
  'strip_set',
  'move_fab',
  'move',
  'move_bundle',
  'delivery',
  'bundle_delivery',
  'delivery_set',
  'pour',
]
