import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import React, { useMemo } from 'react'
import { useMeasure } from 'react-use'
import {
  extendPublicStream,
  PublicStreamExtended,
} from '../../api/StreamExtended'
import { usePublicProject } from '../PublicProjectWrapper'
import { VideoPlayerPublicHLS } from './VideoPlayerPublicHLS'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      width: '100%',
      '& video': {
        display: 'block',
      },
    },
    alert: {
      borderRadius: 0,
      border: '1px solid #000',
      borderTopWidth: 0,
      fontWeight: 'bold',
      boxSizing: 'border-box',
    },
    videoContainer: {
      border: '1px solid #000',
      display: 'flex',
      flexDirection: 'column',
    },
    player: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
)

export const LiveVideosPageMobilePublic = () => {
  const classes = useStyles()
  const project = usePublicProject()

  const [gridContainerRef, containerDims] = useMeasure<HTMLDivElement>()

  const streams: PublicStreamExtended[] = useMemo(() => {
    return project.streams
      .filter((s) => s.url && s.live === true)
      .map((s) => extendPublicStream(s, project))
  }, [project])

  return (
    <div className={classes.root}>
      {project && project.live === false ? (
        <Alert severity="warning" className={classes.alert}>
          Live streams not available at the moment.
        </Alert>
      ) : (
        <>
          <Grid container ref={gridContainerRef}>
            {streams.map((stream) => (
              <Grid
                item
                key={stream.url}
                style={{ width: '100%' }}
                className={classes.videoContainer}
              >
                <VideoPlayerPublicHLS
                  stream={stream}
                  className={classes.player}
                  parentWidth={containerDims.width}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  )
}
