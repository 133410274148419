import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core'
import { AxisTop } from '@visx/axis'
import { scaleTime, ScaleTime } from 'd3-scale'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { useMergeRefs } from 'use-callback-ref'
import zustand, { UseStore } from 'zustand'
import { persist } from 'zustand/middleware'
import { createCtx } from '../../../helpers/createCtx'

export type UseTimelineStore = UseStore<StaticTimelineState>

export const [
  useStaticTimelineStore,
  StaticTimelineStoreContext,
] = createCtx<UseTimelineStore>()

export type StaticTimelineState = {
  scale: ScaleTime<number, number>
}

export const StaticTimeline: React.FC<{
  domain: [DateTime, DateTime]
}> = ({ domain, children }) => {
  const theme = useTheme()
  const [useTimelineStore] = useState(() =>
    zustand<StaticTimelineState>(
      persist(
        (set, get) => {
          const originalScale = scaleTime().domain([
            domain[0].setZone('system', { keepLocalTime: true }).toJSDate(),
            domain[1].setZone('system', { keepLocalTime: true }).toJSDate(),
          ])

          return {
            scale: originalScale,
          }
        },
        {
          name: 'static-timeline-store',
        }
      )
    )
  )

  React.useEffect(() => {
    const oldScale = useTimelineStore.getState().scale
    const newScale = oldScale
      .copy()
      .domain([
        domain[0].setZone('system', { keepLocalTime: true }).toJSDate(),
        domain[1].setZone('system', { keepLocalTime: true }).toJSDate(),
      ])
    useTimelineStore.setState({ scale: newScale })
  }, [domain])

  // Keep tabs on container size
  const [measurementRef, measurements] = useMeasure<HTMLDivElement>()

  // adjust scale as total width is changed
  useEffect(() => {
    const oldScale = useTimelineStore.getState().scale
    const newScale = oldScale.copy().range([0, measurements.width])
    useTimelineStore.setState({ scale: newScale })
  }, [measurements])

  const scale = useTimelineStore((state) => state.scale)

  return (
    <StaticTimelineStoreContext.Provider value={useTimelineStore}>
      <Table
        style={{
          WebkitPrintColorAdjust: 'exact',
          border: '1px solid',
          borderColor: theme.palette.divider,
        }}
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                position: 'relative',
              }}
            >
              {/* <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: 1,
                    backgroundColor: '#000',
                  }}
                ></div> */}
            </TableCell>
            <TableCell
              ref={useMergeRefs([
                measurementRef as React.Ref<HTMLTableDataCellElement>,
              ])}
              style={{ width: '100%', padding: 0 }}
            >
              <svg style={{ display: 'block', width: '100%', height: 30 }}>
                <g>
                  <AxisTop
                    scale={scale}
                    top={30}
                    hideAxisLine
                    tickLabelProps={() => ({
                      fill: theme.palette.text.primary,
                      fontSize: 11,
                      textAnchor: 'middle',
                      y: -10,
                    })}
                    hideTicks
                    tickLength={0}
                    tickFormat={(d) => {
                      const date = d instanceof Date ? d : new Date(d.valueOf())
                      return date.toLocaleString(undefined, {
                        hour: 'numeric',
                      })
                    }}
                  />
                </g>
              </svg>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </StaticTimelineStoreContext.Provider>
  )
}
