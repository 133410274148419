import { createStyles, makeStyles } from '@material-ui/core'
import ControlCameraIcon from '@material-ui/icons/ControlCamera'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import { useDrag, useGesture } from '@use-gesture/react'
import clamp from 'lodash/clamp'
import debounce from 'lodash/debounce'
import { animated, useSpring } from 'react-spring'
import { useInterval } from 'react-use'
import { StreamExtended } from '../api/StreamExtended'
import { mixins } from '../styles/mixins'
import { useApi } from './ApiContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      ...mixins.absoluteFill,
    },
    '@keyframes fadeIn': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    svg: {
      ...mixins.absoluteFill,
    },
    buttons: {
      transition: 'opacity 0.2s',
      // animationName: '$fadeIn',
      // animationDuration: '1s',
      // animationDelay: '2s',
      // animationIterationCount: '1',
      // animationFillMode: 'backwards',
      opacity: 0,
      // transitionDelay: '0.5s',
      '&:hover': {
        opacity: 1,
        transitionDelay: '0s',
      },
    },
    button: {
      cursor: 'grab',
      touchAction: 'none',
      opacity: 0.4,
      transition: 'opacity 0.2s',
      '&:hover': {
        opacity: 0.7,
      },
      '&:active': {
        transition: 'opacity 0s',
        opacity: 1,
      },
    },
    shape: {
      stroke: 'rgba(255, 255, 255, 0.7)',
      strokeWidth: '4',
      fill: 'rgba(0, 0, 0, 0.4)',
    },
    zoomRing: {
      cursor: 'grab',
    },
    zoomIcon: {
      color: '#fff',
      width: 30,
    },
  })
)

const cos = Math.cos
const sin = Math.sin

const innerRadius = 40
const middleRadius = 100
const outerRadius = 200

export const PTZDial = ({
  width,
  height,
  stream,
}: {
  width: number
  height: number
  stream: StreamExtended
}) => {
  const classes = useStyles()
  const api = useApi()

  // Center pan tilt button
  const [{ transformPT, pan, tilt }, ptSpring] = useSpring(() => ({
    transformPT: 'translate(0 0)',
    pan: 0,
    tilt: 0,
  }))
  const bindPT = useDrag(({ down, movement: [mx, my] }) => {
    ptSpring.start({
      transformPT: down ? `translate(${mx} ${my})` : 'translate(0 0)',
      pan: down ? mx : 0,
      tilt: down ? -my : 0,
    })
  })

  // Invisible draggable rect
  const bindPTDrag = useGesture({
    onDrag: debounce(({ movement: [mx, my] }) => {
      api.ptzApiStreamsMovementCreate({
        stream,
        data: {
          pan: Math.round(clamp(-mx / 5, -100, 100)),
          tilt: Math.round(clamp(my / 3, -100, 100)),
          duration: 500,
        },
      })
    }, 100),
    onWheel: debounce(({ movement: [, my] }) => {
      api.ptzApiStreamsMovementCreate({
        stream,
        data: {
          zoom: Math.round(clamp(my / 3, -100, 100)),
          duration: 500,
        },
      })
    }, 100),
  })

  // const bind = useDrag((state) => doSomethingWith(state), config)

  // Center zoom buttons
  const [{ transformZoom, z }, zoomSpring] = useSpring(() => ({
    transformZoom: 'translate(0 0)',
    z: 0,
  }))
  const bindZoom = useDrag(({ down, movement: [mx, my] }) => {
    zoomSpring.start({
      transformZoom: down ? `translate(0 ${my})` : 'translate(0 0)',
      z: down ? -my : 0,
    })
  })

  useInterval(() => {
    const _pan = Math.round(clamp(pan.get() / 2, -100, 100))
    const _tilt = Math.round(clamp(tilt.get() / 2, -100, 100))
    const _zoom = Math.round(clamp(z.get(), -100, 100))
    if (_pan || _tilt || _zoom) {
      // console.log(_pan, _tilt, _zoom)
      api.ptzApiStreamsMovementCreate({
        stream,
        data: {
          pan: _pan,
          tilt: _tilt,
          zoom: _zoom,
          duration: 500,
        },
      })
    }
  }, 1000)

  return (
    <div className={classes.root}>
      <svg viewBox={`0 0 ${width} ${height}`} className={classes.svg}>
        <g transform={`translate(${width / 2} ${height / 2})`}>
          <g {...bindPTDrag()} className={classes.button}>
            <rect
              x={-width / 2}
              y={-height / 2}
              width={width}
              height={height}
              fill={'rgba(0, 0, 0, 0)'}
            ></rect>
          </g>
          <g className={classes.buttons}>
            <animated.g
              {...bindPT()}
              transform={transformPT}
              className={classes.button}
            >
              <circle
                className={classes.shape}
                cx={0}
                cy={0}
                r={innerRadius}
              ></circle>
              <ControlCameraIcon
                className={classes.zoomIcon}
                width={60}
                height={60}
                x={-30}
                y={-30}
              />
            </animated.g>
            {/* {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
            const startAngle = (i * 2 * Math.PI) / 8 - (2 * Math.PI) / 16
            const endAngle = ((i + 1) * 2 * Math.PI) / 8 - (2 * Math.PI) / 16
            return (
              <g
                key={i}
                className={classes.button}
                onClick={() => {
                  api.movePTZ(streamId, {
                    pan: Math.round(cos((startAngle + endAngle) / 2)) * 20,
                    tilt: Math.round(sin((startAngle + endAngle) / 2)) * -20,
                    zoom: 0,
                    duration: 500,
                  })
                }}
              >
                <path
                  className={classes.shape}
                  d={`
                  M ${cos(startAngle) * middleRadius} ${
                    sin(startAngle) * middleRadius
                  } 
                  L ${cos(startAngle) * outerRadius} ${
                    sin(startAngle) * outerRadius
                  }
                  A ${outerRadius} ${outerRadius} 0 0 1 ${
                    cos(endAngle) * outerRadius
                  } ${sin(endAngle) * outerRadius}
                  L ${cos(endAngle) * middleRadius} ${
                    sin(endAngle) * middleRadius
                  }
                  A ${middleRadius} ${middleRadius} 0 0 0 ${
                    cos(startAngle) * middleRadius
                  } ${sin(startAngle) * middleRadius}
                `}
                />
                <path
                  fill="rgba(255, 255, 255, 1)"
                  d="M-3 5l5-5-5-5v10z"
                  transform={`rotate(${i * 45}) translate(${
                    (middleRadius + outerRadius) / 2
                  } 0) scale(3, 3) `}
                ></path>
              </g>
            )
          })} */}
            <animated.g
              {...bindZoom()}
              transform={transformPT}
              className={classes.zoomRing}
            >
              <g
                className={classes.button}
                onClick={() => {
                  api.ptzApiStreamsMovementCreate({
                    stream,
                    data: {
                      pan: 0,
                      tilt: 0,
                      zoom: 20,
                      duration: 500,
                    },
                  })
                }}
              >
                <path
                  className={classes.shape}
                  d={`
                  M ${innerRadius} 0
                  L ${middleRadius} 0
                  A ${middleRadius} ${middleRadius} 0 1 0 ${-middleRadius} 0
                  L ${-innerRadius} 0
                  A ${innerRadius} ${innerRadius} 0 1 1 ${innerRadius} 0
                `}
                ></path>
                <ZoomInIcon
                  className={classes.zoomIcon}
                  width="50"
                  height="50"
                  x="-25"
                  // y="-75"
                  y={-innerRadius - (middleRadius - innerRadius) / 2 - 25}
                />
              </g>
              <g
                className={classes.button}
                onClick={() => {
                  api.ptzApiStreamsMovementCreate({
                    stream,
                    data: {
                      pan: 0,
                      tilt: 0,
                      zoom: -20,
                      duration: 500,
                    },
                  })
                }}
              >
                <path
                  className={classes.shape}
                  d={`
                  M ${innerRadius} 0
                  L ${middleRadius} 0
                  A ${middleRadius} ${middleRadius} 0 1 1 ${-middleRadius} 0
                  L ${-innerRadius} 0
                  A ${innerRadius} ${innerRadius} 0 1 0 ${innerRadius} 0
                `}
                ></path>
                <ZoomOutIcon
                  className={classes.zoomIcon}
                  width="50"
                  height="50"
                  x="-25"
                  y={45}
                />
              </g>
            </animated.g>
          </g>
        </g>
      </svg>
    </div>
  )
}
