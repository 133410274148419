import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  Slider,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import {
  ImageCandidate,
  ImageCategoryDetails,
  ProjectSummary,
} from '../../api/codegen/typescript-axios'
import { extendProject } from '../../api/ProjectDetailsExtended'
import { dateFormat, queryKeys } from '../../helpers/constants'
import { mixins } from '../../styles/mixins'
import { AnnotatorCanvasPreview } from '../Annotator/AnnotationCanvasPreview'
import { getDateRangeParams } from '../Annotator/utils'
import { useApi } from '../ApiContext'
import { DateRangeInput } from '../FormInputs/DateRangeInput'
import { MultiSelect } from '../FormInputs/MultiSelect'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { RemotelyPaginatedTable } from '../RemotelyPaginatedTable'
import { orderOptions, OrderSelect } from './OrderSelect'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 8,
      flexGrow: 1,
    },
    link: {
      textDecoration: 'none',
    },
    thumbnail: {
      display: 'block',
      width: 200,
    },
    categoryList: {
      margin: 0,
    },
    zoomImage: {
      display: 'block',
      width: '100%',
    },
    backdrop: {
      pointerEvents: 'none',
    },
    submitBar: {
      top: 'auto',
      bottom: 0,
    },
  })
)

const tableOptions = {
  rowStyle: (rowData: ImageCandidate) => ({
    verticalAlign: 'top',
  }),
  selection: true,
}

export const ListImages = ({
  projects,
  categories,
}: {
  projects: ProjectSummary[]
  categories: ImageCategoryDetails[]
}) => {
  const api = useApi()
  const classes = useStyles()

  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.PER]: '100',
    [queryKeys.PAGE]: '1',
    [queryKeys.SAMPLE_RATE]: '1',
    [queryKeys.CATEGORIES]: categories.map((c) => c.id!).join(','),
  })
  const perParam = parseInt(searchParams.get(queryKeys.PER)!)
  const pageParam = parseInt(searchParams.get(queryKeys.PAGE)!)
  const sampleRate = parseInt(searchParams.get(queryKeys.SAMPLE_RATE) || '1')
  const [initialSampleRate] = useState(sampleRate)

  const projectParam = searchParams.get(queryKeys.PROJECTS) || ''

  // project autocomplete state
  const [projectInput, setProjectInput] = useState('')

  // fetch project
  const { value: project } = useAsync(async () => {
    if (!projectParam) {
      return
    }
    const projectId = await api.projectIdFromKey(projectParam)
    if (!projectId) return null
    const resp = await api.projectsApi.projectsRead({
      id: projectId.toString(),
    })
    return extendProject(resp.data)
  }, [projectParam])

  // array of available dates
  const datesArray = project
    ? project.videoArchiveDates.map((d) => d.dateString)
    : []

  // Fetch Images
  const [fetchImagesState, fetchImages] = useAsyncFn(
    async ({ per = perParam, page = pageParam }) => {
      // couldn't spread the date range arrays inline because of TS bug?
      const imageStartEnd = getDateRangeParams(
        searchParams,
        queryKeys.IMAGE_STARTTIME,
        queryKeys.IMAGE_ENDTIME,
        project?.timezone
      )

      const resp = await api.imageAnnotationsApi.imageannotationCandidatesList({
        categoryIds: searchParams.get(queryKeys.CATEGORIES)!,
        streamIds: searchParams.get(queryKeys.STREAMS) || undefined,
        imageStarttime: imageStartEnd[0],
        imageEndtime: imageStartEnd[1],
        ordering: searchParams.get(queryKeys.ORDER) || undefined,
        page: page,
        per: per * sampleRate,
      })

      resp.data.results = resp.data.results.filter(
        (v: ImageCandidate, i: number) => i % sampleRate === 0
      )
      resp.data.count = Math.ceil(resp.data.count / sampleRate)

      return resp.data
    },
    [searchParams]
  )

  // images that you've checked in the table
  const [selectedImages, setSelectedImages] = useState<ImageCandidate[]>([])

  // Submit HITs
  const [submitImagesState, submitImages] = useAsyncFn(async () => {
    const categories = searchParams.get(queryKeys.CATEGORIES)
    if (!categories) {
      alert('you must select some categories to submit')
      return
    }
    const response = await api.imageAnnotationsApi.imageannotationSubmissionsMassSubmit(
      {
        data: {
          category_ids: categories.split(',').map((c) => parseInt(c)),
          candidates: selectedImages.map((i) => ({ frame_id: i.frame.id })),
        },
      }
    )
    // fetchImages({})
    return response.data
  }, [searchParams, selectedImages])

  // sets image of zoom modal
  const [zoomImage, setZoomImage] = useState<ImageCandidate | null>(null)

  // When false, modal has no pointer events and reacts to mouse hover on thumbnails
  // when false, modal behaves as normal
  const [modalLocked, setModalLocked] = useState(false)

  // memoized table props
  const onSelectionChange = useCallback((rows) => setSelectedImages(rows), [])
  const columns = useMemo(
    () => [
      {
        title: 'Thumbnail',
        render: (i: ImageCandidate) => (
          <img
            alt={i.frame.thumbnail_url}
            src={i.frame.thumbnail_url}
            className={classes.thumbnail}
            onClick={() => setModalLocked(true)}
            onMouseEnter={() => setZoomImage(i)}
            onMouseLeave={() => {
              if (!modalLocked) {
                setZoomImage(null)
              }
            }}
          />
        ),
        cellStyle: {
          padding: 0,
        },
      },
      {
        title: 'Project',
        field: 'project_name',
      },
      {
        title: 'Stream',
        field: 'stream_name',
      },
      {
        title: 'Datetime',
        field: 'frame.local_image_datetime',
        render: (i: ImageCandidate) =>
          format(
            new Date(i.frame.local_image_datetime || i.frame.image_datetime),
            `${dateFormat} HH:mm:ss`
          ),
      },
      {
        title: 'Frame type',
        field: 'frame.frame_type',
      },
      {
        title: 'Available Categories',
        render: (i: ImageCandidate) => (
          <ul className={classes.categoryList}>
            {i.available_categories?.map((c) => (
              <li key={c.id}>{c.name}</li>
            ))}
          </ul>
        ),
      },
    ],
    [classes, modalLocked]
  )

  return (
    <>
      <Paper>
        <Box p={2} m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={projects}
                getOptionLabel={(p) => p.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Project"
                    variant="outlined"
                  />
                )}
                value={projects.find((p) => p.slug === projectParam)}
                onChange={(event: any, p: ProjectSummary | null) => {
                  if (p) {
                    searchParams.set(queryKeys.PROJECTS, p.slug)
                    searchParams.delete(queryKeys.STREAMS)
                    setSearchParams(searchParams, { replace: true })
                  }
                }}
                inputValue={projectInput}
                onInputChange={(event, newInputValue) => {
                  setProjectInput(newInputValue)
                }}
              />
            </Grid>

            {project && (
              <>
                <Grid item xs={12}>
                  <MultiSelect
                    groupLabel="Streams"
                    options={project.streams.map((s) => ({
                      label: s.name,
                      value: s.id.toString(),
                    }))}
                    queryKey={queryKeys.STREAMS}
                    inputType="checkbox"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateRangeInput
                    label="Date range"
                    timezone={project.timezone}
                    startQueryKey={queryKeys.IMAGE_STARTTIME}
                    endQueryKey={queryKeys.IMAGE_ENDTIME}
                    datesArray={datesArray}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <MultiSelect
                groupLabel="Categories"
                options={categories.map((c) => ({
                  label: c.name,
                  value: c.id!.toString(),
                }))}
                queryKey={'categories'}
                inputType="checkbox"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>Sample Rate</Typography>
              <Slider
                defaultValue={initialSampleRate}
                valueLabelDisplay="auto"
                step={1}
                marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({
                  value: i,
                  label: `1/${i}`,
                }))}
                min={1}
                max={10}
                onChangeCommitted={(e, value) => {
                  searchParams.set(queryKeys.SAMPLE_RATE, value.toString())
                  setSearchParams(searchParams, { replace: true })
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <OrderSelect
                options={[
                  orderOptions.IMAGE_DATETIME,
                  orderOptions.STREAM_NAME,
                ]}
                defaultOption={orderOptions.IMAGE_DATETIME}
                defaultDirection=""
              />
            </Grid>

            <Grid item xs={12}>
              <ProgressButton
                variant="contained"
                color="primary"
                onClick={fetchImages}
                fetchState={fetchImagesState}
              >
                Fetch Images
              </ProgressButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <RemotelyPaginatedTable<ImageCandidate>
        title="Images"
        onSelectionChange={onSelectionChange}
        options={tableOptions}
        columns={columns}
        asyncFnReturn={[fetchImagesState, fetchImages]}
        defaultPer="100"
      />

      <AppBar position="sticky" color="default" className={classes.submitBar}>
        <Toolbar>
          <ProgressButton
            variant="contained"
            color="primary"
            onClick={submitImages}
            disabled={!selectedImages.length}
            fetchState={submitImagesState}
          >
            Submit HITs
          </ProgressButton>
        </Toolbar>
      </AppBar>

      <Dialog
        open={!!zoomImage}
        onClose={() => {
          setZoomImage(null)
          setModalLocked(false)
        }}
        maxWidth="lg"
        disableScrollLock
        className={modalLocked ? '' : classes.backdrop}
      >
        {zoomImage && (
          <>
            <img
              src={zoomImage.frame.thumbnail_url}
              className={classes.zoomImage}
              alt=""
            />
            {modalLocked && (
              <AnnotatorCanvasPreview
                imageUrl={zoomImage.frame.thumbnail_url!}
                boxes={[]}
                showToolbar={false}
                style={{
                  ...(mixins.absoluteFill as React.CSSProperties),
                }}
              />
            )}
          </>
        )}
      </Dialog>
    </>
  )
}
