import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { AxisBottom } from '@visx/axis'
import { Group } from '@visx/group'
import { Legend, LegendItem, LegendLabel } from '@visx/legend'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Bar } from '@visx/shape'
import { Text } from '@visx/text'
import { DateTime, Duration } from 'luxon'
import React, { useMemo } from 'react'
import { useAsync, useMeasure } from 'react-use'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: 200,
      margin: '20px 0',
    },
    svg: {
      display: 'block',
      width: '100%',
      height: 200,
    },
  })
)

const margin = { top: 40, right: 0, bottom: 40, left: 0 }

export default function TruckWeightsChart() {
  const classes = useStyles()
  const theme = useTheme()
  const api = useApi()
  const project = useProject()
  const useVODStore = getUseVODStore()

  const videoHour = useVODStore((state) => state.videoHour)
  const videoDate = useVODStore((state) => state.videoDate)

  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  // bounds
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const { value: data } = useAsync(async () => {
    const resp = await api.vehiclesApi.vehiclesProjectsScaletripreportingList({
      projectId: project.id.toString(),
      startdate: videoHour.startOf('day').minus({ days: 7 }).toISODate(),
      enddate: videoHour.endOf('day').toISODate(),
    })

    const extended = resp.data.map((d) => ({
      ...d,
      dateTime: DateTime.fromISO(d.day),
      dateString: DateTime.fromISO(d.day).toLocaleString({
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      }),
      duration: Duration.fromISO((d.avg_trip_duration as unknown) as string),
      durationMinutes: Duration.fromISO(
        (d.avg_trip_duration as unknown) as string
      ).as('minutes'),
    }))

    return extended
  }, [videoDate.toISO()])

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        domain: data?.map((d) => d.dateString),
        range: [0, xMax],
        round: true,
        padding: 0.75,
      }),
    [xMax, data]
  )

  const yScale0 = useMemo(
    () =>
      scaleLinear<number>({
        domain: data ? [0, Math.max(...data.map((d) => d.num_loads))] : [0, 0],
        range: [0, yMax],
        round: true,
      }),
    [yMax, data]
  )

  const yScale1 = useMemo(
    () =>
      scaleLinear<number>({
        domain: data
          ? [0, Math.max(...data.map((d) => d.num_loads_with_extra))]
          : [0, 0],
        range: [0, yMax],
        round: true,
      }),
    [yMax, data]
  )

  const yScale2 = useMemo(
    () =>
      scaleLinear<number>({
        domain: data
          ? [0, Math.max(...data.map((d) => d.durationMinutes))]
          : [0, 0],
        range: [0, yMax],
        round: true,
      }),
    [yMax, data]
  )

  return (
    <div className={classes.root} ref={ref}>
      <Legend scale={xScale} labelFormat={(label) => `${label.toUpperCase()}`}>
        {() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {[
              'Loads',
              'Loads with extra trips',
              'Avg trip duration (minutes)',
            ].map((label, i) => (
              <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                <svg width={20} height={20}>
                  <rect
                    fill={theme.custom.chartColorsCategorical[i]}
                    width={20}
                    height={20}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 4px">
                  {label}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </Legend>
      <svg width={width} height={height} className={classes.svg}>
        {data && (
          <Group top={margin.top} left={margin.left}>
            {data.map((d) => {
              const height0 = yScale0(d.num_loads) ?? 0
              const height1 = yScale1(d.num_loads_with_extra) ?? 0
              const height2 = yScale2(d.durationMinutes) ?? 0

              const colWidth = xScale.bandwidth()

              return (
                <Group left={xScale(d.dateString)} key={d.day}>
                  <Group left={-colWidth - 3} top={yMax - height0}>
                    <Bar
                      width={colWidth}
                      height={height0}
                      fill={theme.custom.chartColorsCategorical[0]}
                    />
                    <Text
                      fill={theme.palette.text.primary}
                      x={colWidth / 2}
                      y={-8}
                      textAnchor="middle"
                      fontSize={14}
                      fontWeight="bold"
                    >
                      {Math.floor(d.num_loads)}
                    </Text>
                  </Group>

                  <Group left={0} top={yMax - height1}>
                    <Bar
                      width={colWidth}
                      height={height1}
                      fill={theme.custom.chartColorsCategorical[1]}
                    />
                    <Text
                      fill={theme.palette.text.primary}
                      x={colWidth / 2}
                      y={-8}
                      textAnchor="middle"
                      fontSize={14}
                      fontWeight="bold"
                    >
                      {Math.floor(d.num_loads_with_extra)}
                    </Text>
                  </Group>

                  <Group left={colWidth + 3} top={yMax - height2}>
                    <Bar
                      width={colWidth}
                      height={height2}
                      fill={theme.custom.chartColorsCategorical[2]}
                    />
                    <Text
                      fill={theme.palette.text.primary}
                      x={colWidth / 2}
                      y={-8}
                      textAnchor="middle"
                      fontSize={14}
                      fontWeight="bold"
                    >
                      {Math.floor(d.durationMinutes)}
                    </Text>
                  </Group>
                </Group>
              )
            })}
          </Group>
        )}
        <AxisBottom
          top={yMax + margin.top}
          scale={xScale}
          stroke={theme.palette.text.primary}
          tickStroke={theme.palette.text.primary}
          hideAxisLine
          hideTicks
          tickLabelProps={() => ({
            fill: theme.palette.text.primary,
            fontSize: 14,
            textAnchor: 'middle',
          })}
        />
      </svg>
    </div>
  )
}
