import { Box, Paper, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { UserSummary } from '../../api/codegen/typescript-axios'
import { useMe } from '../../hooks/useMe'
import { useApi } from '../ApiContext'
import { Loading } from '../Loading'
import { PageWrapper } from '../PageWrapper'
import { AnnotationReports } from './AnnotationReports'
import { Issues } from './Issues'
import { ListHits } from './ListHits'
import { ListImages } from './ListImages'
import { UserReports } from './UserReports'

export const AnnotationsPageGuard = () => {
  const { data: me } = useMe()

  if (me === undefined) {
    return null
  } else if (me.annotation_role === null) {
    return <Navigate to={`/`} />
  } else {
    return <AnnotationsPage me={me} />
  }
}

const AnnotationsPage = ({ me }: { me: UserSummary }) => {
  const api = useApi()

  let params = useParams()
  let navigate = useNavigate()

  const { data: projects } = useQuery('api.getProjects', api.getProjects)

  const { value: categories } = useAsync(async () => {
    const resp = await api.imageAnnotationsApi.imageannotationCategoriesList()
    return resp.data
  }, [])

  const { value: users } = useAsync(async () => {
    let users: UserSummary[] = []
    if (me.annotation_role === 'tagger') {
      users = [me as UserSummary]
    } else {
      const resp = await api.imageAnnotationsApi.imageannotationUsersList()
      users = resp.data
    }
    const taggers: UserSummary[] = []
    const reviewers: UserSummary[] = []
    users.forEach((user) => {
      if (user.annotation_role === 'tagger') {
        taggers.push(user)
      } else {
        reviewers.push(user)
      }
    })
    return {
      me: me as UserSummary,
      taggers: taggers,
      reviewers: reviewers,
    }
  }, [])

  if (!projects || !categories || !users) {
    return <Loading text="Loading"></Loading>
  }

  return (
    <PageWrapper>
      <Box mb={2}>
        <Paper square>
          <Tabs
            value={params.tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => {
              navigate(`/annotations/${value}`)
            }}
          >
            {users.me.annotation_role === 'admin' && (
              <Tab label="Images" value="list-images" />
            )}
            <Tab label="Jobs" value="list-jobs" />
            <Tab label="User Reports" value="user-reports" />
            <Tab label="Annotation Reports" value="annotation-reports" />
            <Tab label="Issues" value="issues" />
          </Tabs>
        </Paper>
      </Box>
      {params.tab === 'list-images' ? (
        <ListImages projects={projects} categories={categories}></ListImages>
      ) : params.tab === 'list-jobs' ? (
        <ListHits
          projects={projects}
          categories={categories}
          users={users}
        ></ListHits>
      ) : params.tab === 'user-reports' ? (
        <UserReports categories={categories}></UserReports>
      ) : params.tab === 'annotation-reports' ? (
        <AnnotationReports
          projects={projects}
          categories={categories}
          users={users}
        ></AnnotationReports>
      ) : params.tab === 'issues' ? (
        <Issues projects={projects}></Issues>
      ) : null}
    </PageWrapper>
  )
}
