import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@material-ui/core'
import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import useImage from 'use-image'
import { useMe } from '../../hooks/useMe'
import { mixins } from '../../styles/mixins'
import { AnnotatorCanvasPreview } from '../Annotator/AnnotationCanvasPreview'
import { useApi } from '../ApiContext'
import { useThemeControl } from '../Context/ThemeController'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { Loading } from '../Loading'

export const FrameDetailPage = () => {
  const { frameId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const api = useApi()
  const { data: me } = useMe()
  const { themeType } = useThemeControl()

  // Fetch Images
  const { value: frame } = useAsync(async () => {
    if (frameId) {
      const resp = await api.imageAnnotationsApi.imageannotationFramesRead({
        id: frameId,
      })
      return resp.data
    }
  }, [frameId])

  const [image] = useImage(frame?.frame?.image_url || '')

  console.log(image)
  const aspectRatio = image ? image.naturalWidth / image.naturalHeight : 16 / 9

  const [selectedSubmissions, setSelectedSubmissions] = useState<{
    [key: number]: boolean
  }>({})

  // Begin Session
  const [startSessionState, startSession] = useAsyncFn(async () => {
    const {
      data: session,
    } = await api.imageAnnotationsApi.imageannotationSessionsCreate({
      data: {
        submission_ids: Object.keys(selectedSubmissions).map((k) =>
          parseInt(k, 10)
        ),
      },
    })
    navigate(`/annotations/editor/${session.id}`, {
      state: location.state,
    })
    return session
  }, [selectedSubmissions])

  if (!frame) return <Loading text={'Loading Frame...'}></Loading>

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <FormControl component="fieldset">
                <FormLabel component="legend">Submission Categories</FormLabel>
                <FormGroup>
                  {frame.submissions?.map((s) => (
                    <div key={s.id}>
                      <FormControlLabel
                        key={s.id}
                        control={
                          <Checkbox
                            disabled={
                              s.state === 'labeling' ||
                              (me?.annotation_role === 'tagger' &&
                                s.state === 'labeled' &&
                                s.annotations[0].session.user?.id !== me.id)
                            }
                            checked={selectedSubmissions.hasOwnProperty(s.id)}
                            onChange={(e, v: boolean) => {
                              setSelectedSubmissions((prev) => {
                                const clone = { ...prev }
                                if (v) {
                                  clone[s.id] = true
                                } else {
                                  delete clone[s.id]
                                }
                                return clone
                              })
                            }}
                          />
                        }
                        label={s.category?.name}
                      />
                      {s.state === 'unlabeled' && <span>Unlabeled</span>}
                      {s.state === 'ai_labeled' && <span>AI labeled</span>}
                      {s.state === 'labeling' && (
                        <span>
                          {s.annotations[0].session.user?.first_name} is
                          currently labeling.
                        </span>
                      )}
                      {s.state === 'labeled' && (
                        <span>
                          Labeled by:{' '}
                          {s.annotations[0].session.user?.first_name}
                        </span>
                      )}
                      {s.state === 'labeling' &&
                        s.annotations[0].session.user?.id === me?.id && (
                          <Link
                            style={{
                              display: 'block',
                              margin: '0 0 10px 0',
                            }}
                            to={`/annotations/editor/${s.annotations[0].session.id}`}
                          >
                            {' '}
                            This is your ongoing session. Click to rejoin.
                          </Link>
                        )}
                      <ReactJson
                        src={s}
                        collapsed
                        theme={
                          themeType === 'light' ? 'bright:inverted' : 'bright'
                        }
                      />
                      <Divider></Divider>
                    </div>
                  ))}
                </FormGroup>
              </FormControl>

              <ProgressButton
                variant="contained"
                color="primary"
                vertical
                onClick={startSession}
                fetchState={startSessionState}
                style={{ marginTop: 20 }}
              >
                Start Session
              </ProgressButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  flexGrow: 1,
                  paddingTop: `calc(${100 / aspectRatio}% + 54px)`,
                }}
              >
                <AnnotatorCanvasPreview
                  imageUrl={frame.frame!.image_url}
                  boxes={frame.latest_boxes!}
                  style={{
                    ...(mixins.absoluteFill as React.CSSProperties),
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
