import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useKey } from 'react-use'
import { useFramesStore } from './FramesController'

export const FrameDisplay = () => {
  const frameStore = useFramesStore()

  const frame = frameStore((state) => state.selectedFrame)

  useKey('ArrowLeft', () => {
    frameStore.getState().gotoPrev()
  })

  useKey('ArrowRight', () => {
    frameStore.getState().gotoNext()
  })

  return (
    <div
      style={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        position: 'relative',
      }}
    >
      {frame ? (
        <>
          <img
            src={frame.thumbnail_url}
            alt={frame.local_image_datetime}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
          <img
            key={frame.image_url}
            src={frame.image_url}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </>
      ) : (
        <Typography
          variant="h6"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          No image at this time
        </Typography>
      )}
      <IconButton
        onClick={() => frameStore.getState().gotoPrev()}
        style={{
          position: 'absolute',
          top: '50%',
          left: 20,
          transform: 'translateY(-50%)',
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <IconButton
        onClick={() => frameStore.getState().gotoNext()}
        style={{
          position: 'absolute',
          top: '50%',
          right: 20,
          transform: 'translateY(-50%)',
        }}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  )
}
