import {
  createStyles,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  useTheme,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { DateTime } from 'luxon'
import QueryString from 'qs'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import { CamEvent } from '../../api/codegen/typescript-axios'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { useProject } from '../ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    tableContainer: {
      flexGrow: 1,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    weighs: {
      backgroundColor: theme.palette.background.default,
    },
    images: {
      display: 'flex',
    },
    video: {
      display: 'block',
      width: '100%',
      maxHeight: 'calc(100vh - 100px)',
      margin: '0 auto',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#fff',
    },
  })
)

export const SecurityEventsList = (props: {
  alarmModalClosed: number
  setAlarmModalClosed: (eventId: number) => void
}) => {
  const api = useApi()
  const project = useProject()
  const classes = useStyles()

  const [modalEvent, setModalEvent] = React.useState<CamEvent>()

  const { value: eventsList } = useAsync(async () => {
    const resp = await api.securityApi.projectsSecurityEventsList({
      projectId: project.id.toString(),
    })

    const mostRecentEvent = resp.data[0]
    if (
      mostRecentEvent?.alarm_active &&
      props.alarmModalClosed !== mostRecentEvent?.id
    ) {
      setModalEvent(mostRecentEvent)
    }

    return resp.data
  }, [project])

  const closeModal = () => {
    if (modalEvent?.id) {
      props.setAlarmModalClosed(modalEvent.id)
    }
    setModalEvent(undefined)
  }

  return (
    <>
      <Table>
        <TableBody>
          {eventsList?.map((event, i) => (
            <Row key={event.id} camEvent={event} index={i} />
          ))}
        </TableBody>
      </Table>
      {modalEvent && (
        <Dialog
          open={!!modalEvent}
          onClose={closeModal}
          maxWidth="lg"
          fullWidth
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <Row camEvent={modalEvent} index={0} />
        </Dialog>
      )}
    </>
  )
}

const Row = memo(
  ({ camEvent, index }: { camEvent: CamEvent; index: number }) => {
    const classes = useStyles()
    const theme = useTheme()
    const project = useProject()
    const api = useApi()
    const { confirm } = useAlertConfirmPrompt()

    const [open, setOpen] = React.useState(index === 0)

    const [cancelState, cancel] = useAsyncFn(async () => {
      try {
        await confirm({
          title: 'Cancel Alarm?',
          description: 'This will call off any police response.',
          yesText: 'Yes',
          noText: 'No',
        })
        const resp = await api.securityApi.projectsSecurityEventsCancelAlarm({
          projectId: project.id.toString(),
          id: camEvent.id!.toString(),
        })
        return resp.data
      } catch (err) {
        // do nothing
      }
    }, [api, project])

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width={30}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          {/* Datetime */}
          <TableCell width={200}>
            {camEvent.event_time &&
              DateTime.fromISO(camEvent.event_time, {
                zone: project.timezone,
              }).toLocaleString(DateTime.DATETIME_SHORT)}
          </TableCell>

          {/* Description */}
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {camEvent.alarm_active && (
                <ProgressButton
                  fetchState={cancelState}
                  onClick={cancel}
                  style={{ whiteSpace: 'nowrap', marginLeft: 10 }}
                >
                  Cancel Alarm
                </ProgressButton>
              )}
            </div>
          </TableCell>
        </TableRow>
        <TableRow className={classes.weighs}>
          <TableCell style={{ padding: 0 }} colSpan={4}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              mountOnEnter
              style={{
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
              }}
            >
              <Grid container spacing={1} className={classes.images}>
                <Table size="small" style={{ width: 'auto' }}>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        <Link
                          to={`../archive?${QueryString.stringify({
                            date: camEvent.event_time,
                            stream: camEvent.stream,
                            speed: 1,
                          })}`}
                          style={{
                            whiteSpace: 'nowrap',
                            textDecoration: 'none',
                          }}
                        >
                          View in Archive
                        </Link>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Event type: </StyledTableCell>
                      <StyledTableCell>{camEvent.event_type}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Canceled at:</StyledTableCell>
                      <StyledTableCell>
                        {camEvent.alarm_canceled_at &&
                          DateTime.fromISO(camEvent.alarm_canceled_at, {
                            zone: project.timezone,
                          }).toLocaleString(DateTime.TIME_SIMPLE)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Canceled by:</StyledTableCell>
                      <StyledTableCell>
                        {camEvent.alarm_canceled_by?.first_name}{' '}
                        {camEvent.alarm_canceled_by?.last_name}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {camEvent.video_url && (
                  <Grid key={camEvent.id} item xs={12}>
                    <video
                      src={camEvent.video_url}
                      className={classes.video}
                      controls
                    />
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
)

Row.displayName = 'Row'

const StyledTableCell: React.FC<TableCellProps> = ({ children, ...props }) => (
  <TableCell {...props} style={{ border: 'none' }}>
    {children}
  </TableCell>
)
