import React from 'react'
import { FormControlLabel, Tooltip, RadioGroup, Radio } from '@material-ui/core'
import { LabelState } from './Annotator'

export const LabelStateControl = ({
  labelState,
  setLabelState,
}: {
  labelState: LabelState
  setLabelState: (state: LabelState) => void
}) => {
  return (
    <Tooltip title="Show label tags for:">
      <RadioGroup
        aria-label="Label tags"
        name="Label tags"
        value={labelState}
        row
        onChange={(e, v) => setLabelState(v as typeof labelState)}
        style={{ alignItems: 'center' }}
      >
        <FormControlLabel
          value="selected"
          control={<Radio size="small" />}
          label="Selected"
        />
        <FormControlLabel
          value="all"
          control={<Radio size="small" />}
          label="All"
        />
      </RadioGroup>
    </Tooltip>
  )
}
