import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import { raf } from 'rafz'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSpring } from 'react-spring'
import { useAsync, useInterval, useMeasure } from 'react-use'
import zustand, { UseStore } from 'zustand'
import { ClassLabel, LiveAiData } from '../../api/codegen/typescript-axios'
import { StreamExtended } from '../../api/StreamExtended'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'
import { UseDashboardStore } from './DashboardController'
import { LiveAICountChartLegend } from './LiveAICountChartLegend'
import { LiveAICountChartVX } from './LiveAICountChartVX'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#111',
    color: '#aaa',
  },
  legend: {},
  chart: {
    flexGrow: 1,
  },
})
export interface AICountPoint {
  dateTime: Date
  count: number
}

export interface LiveAiDataExtended extends LiveAiData {
  dateTime: Date
}

export type LiveAICountState = {
  liveaiClassesList: ClassLabel[]
  liveAiData: LiveAiDataExtended[]
  dates: Date[]
  xDomain: [Date, Date]
  yDomain: [number, number]
  width: number
  height: number
}

export type UseLiveAICountStore = UseStore<LiveAICountState>

export const LiveAICountChartController = ({
  stream,
}: {
  useDashboardStore: UseDashboardStore
  stream: StreamExtended
}) => {
  const classes = useStyles()
  const api = useApi()
  const project = useProject()

  // init store
  const [useLiveAICountStore] = useState(() =>
    zustand<LiveAICountState>((set) => ({
      liveaiClassesList: [],
      liveAiData: [],
      dates: [],
      xDomain: [
        DateTime.local()
          .startOf('second')
          .setZone(project.timezone)
          .setZone('local', { keepLocalTime: true })
          .minus({ seconds: 100 })
          .toJSDate(),
        DateTime.local()
          .startOf('second')
          .setZone(project.timezone)
          .setZone('local', { keepLocalTime: true })
          .minus({ seconds: 5 })
          .toJSDate(),
      ],
      yDomain: [0, 0],
      width: 1,
      height: 1,
    }))
  )

  // update scale every frame
  useEffect(() => {
    const update = () => {
      const now = DateTime.local()
        .setZone(project.timezone)
        .setZone('local', { keepLocalTime: true })

      useLiveAICountStore.setState({
        xDomain: [
          now.minus({ seconds: 100 }).toJSDate(),
          now.minus({ seconds: 5 }).toJSDate(),
        ],
      })
      return true
    }
    raf(update)
    return () => raf.cancel(update)
  }, [])

  // animate Y scale
  const [, spring] = useSpring(() => ({
    to: { yDomainMax: 1 },
    onFrame: ({ yDomainMax }: { yDomainMax: number }) => {
      const prevState = useLiveAICountStore.getState()
      useLiveAICountStore.setState({
        yDomain:
          yDomainMax !== prevState.yDomain[1]
            ? [prevState.yDomain[0], yDomainMax]
            : prevState.yDomain,
      })
    },
  }))

  // fetch classes
  useQuery('aiApi.liveaiClassesList', async () => {
    const resp = await api.aiApi.liveaiClassesList()
    useLiveAICountStore.setState({
      liveaiClassesList: resp.data,
    })
    return resp.data
  })

  // initial fetch to get last 100 entries
  useAsync(async () => {
    const { data } = await api.aiApi.streamsLiveaiList({
      streamId: stream.id.toString(),
    })
    const extended = data.map((d) => ({
      ...d,
      dateTime: DateTime.fromISO(d.timestamp)
        .setZone(project.timezone)
        .setZone('local', { keepLocalTime: true })
        .startOf('second')
        .toJSDate(),
    }))
    useLiveAICountStore.setState({
      liveAiData: extended,
    })
    const yMax = getYMax(extended)
    if (yMax > 0) {
      spring.start({ to: { yDomainMax: yMax } })
    }
  }, [])

  // fetch latest count every second
  const updateLiveAIData = async () => {
    const { data: datum } = await api.aiApi.streamsLiveaiLatest({
      streamId: stream.id.toString(),
    })

    const extended = {
      ...datum,
      dateTime: DateTime.fromISO(datum.timestamp)
        .setZone(project.timezone)
        .setZone('local', { keepLocalTime: true })
        .startOf('second')
        .toJSDate(),
    }

    const newData = [
      ...useLiveAICountStore.getState().liveAiData,
      extended,
    ].slice(-100)

    useLiveAICountStore.setState({
      liveAiData: newData,
    })

    const yMax = getYMax(newData)
    if (yMax > 0) {
      spring.start({ to: { yDomainMax: yMax } })
    }
  }

  // // update data from api
  useInterval(updateLiveAIData, 1000)

  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    useLiveAICountStore.setState({
      width,
      height,
    })
  }, [width, height, useLiveAICountStore])

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.legend}>
        <LiveAICountChartLegend useLiveAICountStore={useLiveAICountStore} />
      </div>
      <div className={classes.chart}>
        <LiveAICountChartVX useLiveAICountStore={useLiveAICountStore} />
      </div>
    </div>
  )
}

function getYMax(data: LiveAiDataExtended[]) {
  return Math.ceil(
    data.reduce((accumulator, currentValue) => {
      return Math.max(
        accumulator,
        ...Object.keys(currentValue.box_count_avg_by_class).map(
          (k) => currentValue.box_count_avg_by_class[k]
        )
      )
    }, 0)
  )
}
