import { omit } from 'lodash'
import { DateTime } from 'luxon'
import {
  ShoringBay,
  StreamCoordinates,
  UploadCoordinates,
} from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'

export interface ShoringBayExtended
  extends Omit<
    ShoringBay,
    'start_time' | 'end_time' | 'stream_coordinates' | 'upload_coordinates'
  > {
  startDateTime?: DateTime | null
  inferredStartDateTime?: DateTime
  endDateTime?: DateTime | null
  inferredEndDateTime?: DateTime
  systemStartDateTime?: DateTime
  inferredSystemStartDateTime?: DateTime
  systemEndDateTime?: DateTime
  inferredSystemEndDateTime?: DateTime
  streamCoordinates: Map<number, StreamCoordinates>
  uploadCoordinates: Map<number, UploadCoordinates>
  predecessors: Set<number>
  successors: Set<number>
  teamIndex?: number
}

export function extendBay(
  bay: ShoringBay,
  project: ProjectDetailsExtended
): ShoringBayExtended {
  const startDateTime = bay.start_time
    ? DateTime.fromISO(bay.start_time, { zone: project.timezone })
    : undefined
  const endDateTime = bay.end_time
    ? DateTime.fromISO(bay.end_time, { zone: project.timezone })
    : undefined

  return {
    ...omit(bay, [
      'start_time',
      'end_time',
      'stream_coordinates',
      'upload_coordinates',
    ]),

    node_type: bay.node_type || undefined,

    startDateTime,
    endDateTime,

    systemStartDateTime: startDateTime?.setZone('system', {
      keepLocalTime: true,
    }),
    systemEndDateTime: endDateTime?.setZone('system', { keepLocalTime: true }),

    streamCoordinates: (bay.stream_coordinates || []).reduce(function (
      map,
      obj
    ) {
      map.set(obj.stream_id, obj)
      return map
    },
    new Map<number, StreamCoordinates>()),

    uploadCoordinates: (bay.upload_coordinates || []).reduce(function (
      map,
      obj
    ) {
      map.set(obj.upload_id, obj)
      return map
    },
    new Map<number, UploadCoordinates>()),

    predecessors: new Set<number>(),
    successors: new Set<number>(),
  }
}

export function serializeBay(bay: ShoringBayExtended): ShoringBay {
  const serialized: ShoringBay = omit(bay, [
    'startDateTime',
    'inferredStartDateTime',
    'systemStartDateTime',
    'inferredSystemStartDateTime',
    'systemEndDateTime',
    'endDateTime',
    'inferredEndDateTime',
    'inferredSystemEndDateTime',
    'streamCoordinates',
    'uploadCoordinates',
  ])
  serialized.start_time =
    bay.startDateTime?.toISO({
      suppressMilliseconds: true,
    }) || null

  serialized.end_time =
    bay.endDateTime?.toISO({ suppressMilliseconds: true }) || null

  serialized.stream_coordinates = Array.from(bay.streamCoordinates.values())
  serialized.upload_coordinates = Array.from(bay.uploadCoordinates.values())

  return serialized
}
