import { Paper } from '@material-ui/core'
import { colord } from 'colord'
import { scaleSequential } from 'd3-scale'
import {
  interpolateCubehelixDefault,
  interpolateRdYlGn,
  interpolateTurbo,
} from 'd3-scale-chromatic'
import Konva from 'konva'
import { DateTime } from 'luxon'
import React from 'react'
import { Group, Image, Rect, Shape } from 'react-konva'
import { Html } from 'react-konva-utils'
import { useAsync } from 'react-use'
import { darkTheme } from '../../../styles/theme'
import { useUploadCanvasStore } from '../Uploads/UploadDisplay'
import { useUploadsStore } from '../Uploads/UploadsController'
import { getUseVODStore } from '../VODController'
import { DeckAICurrentSegment } from './CurrentSegment'
import {
  colorizeImage,
  combineImageBitmap,
  DeckAIGroup,
  loadImageBitmap,
  useDeckAIStore,
} from './DeckAIController'

export const DeckAIDrawingOverlay = () => {
  const deckAIStore = useDeckAIStore()
  const uploadsStore = useUploadsStore()
  const uploadCanvasStore = useUploadCanvasStore()
  const useVODStore = getUseVODStore()

  const canvasTransform = uploadCanvasStore((state) => state.transform)

  const labels = deckAIStore((state) => state.labels)
  const labelId = deckAIStore((state) => state.labelId)
  const segments = deckAIStore((state) => state.segmentationData)
  const segmentationDrawings = deckAIStore(
    (state) => state.segmentationDrawings
  )
  const overlayOptions = deckAIStore((state) => state.overlayOptions)

  const selectedUpload = uploadsStore((state) => state.selectedUpload)
  const selectedUploadPageNumber = uploadsStore(
    (state) => state.selectedUploadPageNumber
  )

  // onscreen display canvas
  const displayRef = React.useRef<Konva.Shape>(null)

  // offscreen canvases
  const [displayBuffer] = React.useState(() => document.createElement('canvas'))
  const [hitBuffer] = React.useState(() => document.createElement('canvas'))

  // transform for segments
  const [transform, setTransform] = React.useState<Konva.Transform>(
    new Konva.Transform()
  )

  // for hit detection
  const [hitColorHash] = React.useState<Record<string, DeckAIGroup>>({})

  // hovered segment
  const [highlightGroup, setHighlightGroup] = React.useState<DeckAIGroup>()

  // mouse position for positioning hover metadata
  const [mousePos, setMousePos] = React.useState({ x: -1, y: -1 })

  // load highlighted segment bitmap
  const { value: highlightBitmap } = useAsync(async () => {
    if (!highlightGroup || !highlightGroup.segments) {
      return undefined
    }
    const bitmap = await combineImageBitmap(
      highlightGroup.segments.map((s) => s.smallestDeltaMask)
    )

    return bitmap
  }, [highlightGroup])

  // size buffers
  React.useEffect(() => {
    hitBuffer.width = 200
    hitBuffer.height = 200
    displayBuffer.width = 200
    displayBuffer.height = 200
  }, [displayBuffer, hitBuffer])

  // get transform
  React.useEffect(() => {
    if (!selectedUpload?.file_url) return
    const drawing = deckAIStore
      .getState()
      .segmentationDrawings.find((drawing) => {
        return (
          drawing.upload === selectedUpload.id &&
          (drawing.page_number === selectedUploadPageNumber ||
            drawing.page_number === null)
        )
      })

    deckAIStore.setState({ segmentationDrawing: drawing })

    if (drawing?.master_to_drawing) {
      const _0 = drawing.master_to_drawing[0]
      const _1 = drawing.master_to_drawing[1]
      const transform = new Konva.Transform([
        _0[0],
        _1[0],
        _0[1],
        _1[1],
        _0[2],
        _1[2],
      ])
      transform.scale(36, 36)
      setTransform(transform)
    } else {
    }
  }, [selectedUpload, selectedUploadPageNumber, segmentationDrawings])

  // render segments
  React.useEffect(() => {
    if (!labelId) return

    displayBuffer.getContext('2d')?.clearRect(0, 0, 200, 200)
    hitBuffer.getContext('2d')?.clearRect(0, 0, 200, 200)

    const labelSegments = segments.get(labelId)?.groupTypes[
      overlayOptions.groupBy
    ]

    if (!labelSegments) {
      console.error('no segments for this label')
      return
    }

    const nonNullGroups = labelSegments.groups.filter(
      (group) => group.areaChanged > 0
    )

    const colorScale =
      overlayOptions.colorBy === 'Work rate'
        ? scaleSequential(labelSegments.domain, interpolateRdYlGn)
        : scaleSequential([0, nonNullGroups.length], interpolateTurbo)

    const hitScale = scaleSequential(
      [0, nonNullGroups.length],
      interpolateCubehelixDefault
    )

    nonNullGroups.forEach(async (group, i) => {
      if (group.areaChanged) {
        const color =
          overlayOptions.colorBy === 'Work rate'
            ? colord(colorScale(group.areaChanged)).toRgbString()
            : colord(colorScale(i)).toRgbString()

        const hitColor = colord(hitScale(i + 1)).toHex()

        hitColorHash[hitColor] = group

        group.segments.forEach(async (segment) => {
          if (!segment.smallestDeltaMask) return
          const imageBitmap = await loadImageBitmap(segment.smallestDeltaMask)
          const colorizedImageBitmap = await colorizeImage(imageBitmap, color)
          const hitImageBitmap = await colorizeImage(imageBitmap, hitColor)

          displayBuffer.getContext('2d')?.drawImage(colorizedImageBitmap, 0, 0)
          hitBuffer.getContext('2d')?.drawImage(hitImageBitmap, 0, 0)
          displayRef.current?.getLayer()?.batchDraw()
        })
      }
    })
  }, [segments, labelId, overlayOptions])

  return (
    <Group listening>
      <Group {...transform.decompose()} listening>
        <Rect
          listening
          x={0}
          y={0}
          width={200}
          height={200}
          onMouseMove={(event) => {
            const stagePosition = event.target.getStage()?.getPointerPosition()

            if (!stagePosition) {
              throw new Error('no pointerPosition')
            }

            const overlayPosition = canvasTransform
              .copy()
              .multiply(transform)
              .invert()
              .point(stagePosition)

            const hitColorArray = hitBuffer
              .getContext('2d')
              ?.getImageData(
                Math.floor(overlayPosition.x),
                Math.floor(overlayPosition.y),
                1,
                1
              ).data

            if (!hitColorArray) {
              setHighlightGroup(undefined)
              return
            }

            const hitColorHex = colord({
              r: hitColorArray[0],
              g: hitColorArray[1],
              b: hitColorArray[2],
            }).toHex()

            const segment = hitColorHash[hitColorHex]

            if (!segment) {
              setHighlightGroup(undefined)
              return
            }

            setMousePos({ x: stagePosition.x, y: stagePosition.y })

            if (!highlightGroup || highlightGroup.id !== segment.id) {
              setHighlightGroup(segment)
            }
          }}
          onDblClick={() => {
            if (highlightGroup) {
              useVODStore.getState().gotoVideo({
                dateTime: highlightGroup.startDateTime,
              })
            }
          }}
        />
        <Shape
          listening={false}
          key={labelId}
          ref={displayRef}
          x={0}
          y={0}
          sceneFunc={(context, shape) => {
            context.drawImage(displayBuffer, 0, 0)
          }}
          opacity={0.8}
        />

        <DeckAICurrentSegment />

        {highlightBitmap && (
          <Image listening={false} image={highlightBitmap} opacity={0.5} />
        )}

        {highlightGroup && (
          <Html transform={false}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <Paper
                style={{
                  backgroundColor: darkTheme.palette.background.paper,
                  padding: 10,
                  minWidth: 230,
                  color: '#fff',
                  transform: `translate(${mousePos.x + 10}px, ${
                    mousePos.y + 10
                  }px)`,
                  pointerEvents: 'none',
                }}
              >
                <div>{labels.find((l) => l.id === labelId)?.name}</div>

                <div>
                  Start time:{' '}
                  {highlightGroup.startDateTime.toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </div>
                <div>
                  End time:{' '}
                  {highlightGroup.endDateTime.toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </div>

                <div>
                  Area: {Math.round(highlightGroup.areaChanged / 144)} sf
                </div>

                {highlightGroup.valueAdd.totalSegments > 1 && (
                  <div>
                    Value add:{' '}
                    {Math.round(
                      (100 * highlightGroup.valueAdd.activeSegments) /
                        highlightGroup.valueAdd.totalSegments
                    )}
                    % ({highlightGroup.valueAdd.activeSegments}/
                    {highlightGroup.valueAdd.totalSegments})
                  </div>
                )}
              </Paper>
            </div>
          </Html>
        )}
      </Group>
    </Group>
  )
}
