import MaterialTable, { Column } from '@material-table/core'
import { Box, Grid, Paper } from '@material-ui/core'
import { format, getDay, subDays } from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import {
  ImageAnnotationUserReport,
  ImageCategoryDetails,
} from '../../api/codegen/typescript-axios'
import { dateFormat, queryKeys, tz } from '../../helpers/constants'
import { getDateRangeParams } from '../Annotator/utils'
import { useApi } from '../ApiContext'
import { DateRangeInput } from '../FormInputs/DateRangeInput'
import { ProgressButton } from '../FormInputs/ProgressButton'

export const UserReports = ({
  categories,
}: {
  categories: ImageCategoryDetails[]
}) => {
  const api = useApi()

  const today = new Date()
  const dayOfWeek = getDay(today)
  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.ANNOTATION_STARTTIME]: format(
      subDays(today, dayOfWeek === 0 ? 6 : dayOfWeek - 1),
      dateFormat
    ),
    [queryKeys.ANNOTATION_ENDTIME]: format(endOfDay(today), dateFormat),
  })

  useEffect(() => {
    setSearchParams(searchParams)
  }, [])

  const [fetchReportsState, fetchReports] = useAsyncFn(async () => {
    const dateRange = getDateRangeParams(
      searchParams,
      queryKeys.ANNOTATION_STARTTIME,
      queryKeys.ANNOTATION_ENDTIME
    )
    if (!dateRange[0] || !dateRange[1]) return
    const users = await api.imageAnnotationsApi.imageannotationUserreportsList({
      starttime: dateRange[0],
      endtime: dateRange[1],
    })
    return users.data
  }, [searchParams])

  useEffect(() => {
    fetchReports()
  }, [])

  const reports = fetchReportsState.value || []

  // memoized table props
  const tableOptions = {
    paging: true,
    pageSize: reports.length,
    rowStyle: () => ({
      verticalAlign: 'top',
    }),
  }

  // const f = reports[0].
  const columns = useMemo(() => {
    const columns: Column<ImageAnnotationUserReport>[] = [
      {
        title: 'Name',
        render: (report) => report.user.first_name || report.user.email,
      },
      {
        title: 'Avg Score',
        render: (report) =>
          report.avg_score ? Math.round(report.avg_score * 100) + '%' : '--',
      },
      {
        title: 'Number of Boxes',
        render: (report) =>
          Math.max(
            report.num_boxes_annotated || 0,
            report.num_boxes_reviewed || 0
          ),
      },
      {
        title: 'Time',
        render: (report) => report.time,
      },
      {
        title: 'Bonus',
        render: (report) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format((report.bonus || 0) / 100),
      },
      {
        title: 'Total',
        render: (report) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format((report.total || 0) / 100),
      },
    ]
    return columns
  }, [])

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          {/* Categories filter doesnt work on BE, hence commented out */}
          {/* <Grid item xs={12}>
            <MultiSelect
              groupLabel="Categories"
              options={categories.map((c) => ({
                label: c.name,
                value: c.id!.toString(),
              }))}
              queryKey={queryKeys.CATEGORIES}
              inputType="checkbox"
            />
          </Grid> */}

          <Grid item xs={12}>
            <DateRangeInput
              label="Date range"
              timezone={tz}
              startQueryKey={queryKeys.ANNOTATION_STARTTIME}
              endQueryKey={queryKeys.ANNOTATION_ENDTIME}
            />
          </Grid>
          <Grid item xs={12}>
            <ProgressButton
              fetchState={fetchReportsState}
              onClick={fetchReports}
            >
              Fetch Reports
            </ProgressButton>
          </Grid>
          <Grid item xs={12}>
            {fetchReportsState.value && (
              <MaterialTable
                title="Users"
                data={reports}
                options={tableOptions}
                columns={columns}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
