import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CloseIcon from '@material-ui/icons/Close'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import SaveIcon from '@material-ui/icons/Save'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import { DateTime, Duration } from 'luxon'
import React, { useEffect } from 'react'
import { TbWiper } from 'react-icons/tb'
import { useAsyncFn } from 'react-use'
import { StreamExtended } from '../../api/StreamExtended'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { KeyedButton } from './KeyedButton'

export const PtzControls: React.FC<{
  stream?: StreamExtended
  ptzState?: boolean
  setPtzState?: (b: boolean) => void
  setRtcState?: (b: boolean) => void
}> = ({ stream, ptzState, setPtzState, setRtcState }) => {
  const api = useApi()

  const [presetId, setPresetId] = React.useState('')
  const [, setPtzLocked] = React.useState(stream?.ptz_locked)

  const [, setPtz] = useAsyncFn(async (pan, tilt, zoom) => {
    if (stream) {
      setPresetId('')

      api.ptzApiStreamsMovementCreate({
        stream,
        data: {
          pan: pan,
          tilt: tilt,
          zoom: zoom,
          duration: 500,
        },
      })
    }
  })

  const [{ value: presets = [] }, fetchPresets] = useAsyncFn(async () => {
    if (!stream || !setPtzState || !ptzState) return []

    const { data: _presets } = await api.ptzApi.streamsPtzPresetsList({
      streamId: stream.id.toString(),
    })

    const sorted = _presets.sort((a, b) => {
      if (a.name.toLowerCase() === 'home') {
        return -1
      } else {
        return 0
      }
    })

    return sorted
  }, [ptzState, setPtzState])

  useEffect(() => {
    fetchPresets()
  }, [fetchPresets])

  const [presetFormOpen, setPresetFormOpen] = React.useState(false)
  const [presetFormValue, setPresetFormValue] = React.useState('')
  const [savePresetState, savePreset] = useAsyncFn(async () => {
    if (!stream) return
    await api.ptzApi.streamsPtzPresetsSavePreset({
      id: presetId,
      streamId: stream.id.toString(),
      data: {
        name: presetFormValue,
      },
    })
    fetchPresets()
    setPresetFormOpen(false)
  }, [presetId, presetFormValue])

  const [helpModalOpen, setHelpModalOpen] = React.useState(false)

  return (
    <>
      {/* Preset editor */}
      <Dialog
        open={presetFormOpen}
        onClose={() => setPresetFormOpen(false)}
        aria-labelledby="form-dialog-title"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a new name for the preset if you wish.
          </DialogContentText>
          <Select
            value={presetId}
            onChange={(e) => {
              setPresetId(e.target.value as string)
            }}
            required
            displayEmpty
            variant="standard"
            fullWidth
          >
            <MenuItem value={''}>Select Preset</MenuItem>
            {presets.map((p, i) => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label="Preset name"
            type="text"
            fullWidth
            value={presetFormValue}
            onChange={(e) => {
              setPresetFormValue(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPresetFormOpen(false)} color="primary">
            Cancel
          </Button>
          <ProgressButton
            disabled={!presetId || !presetFormValue}
            onClick={savePreset}
            fetchState={savePresetState}
          >
            Save Preset
          </ProgressButton>
        </DialogActions>
      </Dialog>
      {/* End Preset editor */}

      {/* Help dialog */}
      <Dialog
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DialogTitle id="form-dialog-title">PTZ help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <List>
              <ListItem>
                <ListItemText primary="Use the arrows and magnifying lens buttons to pan, tilt or zoom the camera." />
              </ListItem>
              <ListItem>
                <ListItemText primary="To save the current view for later recall, click the disk icon and provide a name for the preset." />
              </ListItem>
              <ListItem>
                <ListItemText primary="The camera will automatically reset back to the HOME Preset after 12 minutes of inactivity as a security feature. To retain a new view for longer, save the desired camera position to HOME." />
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setHelpModalOpen(false)}
            color="primary"
            variant="contained"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Help dialog */}

      {/* PTZ controls */}
      {stream && ptzState && (
        <Card
          style={{
            position: 'absolute',
            left: '50%',
            top: -60,
            transform: 'translate(-50%)',
            whiteSpace: 'nowrap',
          }}
        >
          {[
            {
              pan: -50,
              tilt: 0,
              tooltip: 'Pan left',
              keyCode: 'ArrowLeft',
              rotate: 180,
            },
            {
              pan: 50,
              tilt: 0,
              tooltip: 'Pan right',
              keyCode: 'ArrowRight',
              rotate: 0,
            },
            {
              pan: 0,
              tilt: 50,
              tooltip: 'Tilt up',
              keyCode: 'ArrowUp',
              rotate: 270,
            },
            {
              pan: 0,
              tilt: -50,
              tooltip: 'Tilt down',
              keyCode: 'ArrowDown',
              rotate: 90,
            },
          ].map((c, i) => (
            <KeyedButton
              key={i}
              keyCode={c.keyCode}
              tooltip={c.tooltip}
              disabled={stream.ptz_locked}
              icon={
                <ArrowForwardIcon
                  fontSize="inherit"
                  style={{ transform: `rotate(${c.rotate}deg)` }}
                />
              }
              onClick={() => setPtz(c.pan, c.tilt, 0)}
            />
          ))}
          <KeyedButton
            tooltip={'Zoom in'}
            keyCode="i"
            disabled={stream.ptz_locked}
            icon={<ZoomInIcon />}
            onClick={() => setPtz(0, 0, 50)}
          />
          <KeyedButton
            tooltip={'Zoom out'}
            keyCode="o"
            disabled={stream.ptz_locked}
            icon={<ZoomOutIcon />}
            onClick={() => setPtz(0, 0, -50)}
          />
          <Select
            native
            disabled={stream.ptz_locked}
            value={presetId}
            onChange={(e) => {
              setPresetId(e.target.value as string)

              stream.ptzResetDateTime = DateTime.now()
                .setZone(stream.timezone)
                .plus(Duration.fromObject({ seconds: 720 }))

              api.ptzApi.streamsPtzPresetsMove({
                id: e.target.value as string,
                streamId: stream.id.toString(),
              })
            }}
            displayEmpty
            variant="standard"
          >
            <option key="Select Preset" value={''}>
              Select Preset
            </option>
            {presets.map((p, i) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </Select>
          {stream.project.user_attrs.ptz_level >= 20 && (
            <KeyedButton
              tooltip={'Save preset'}
              icon={<SaveIcon />}
              onClick={() => {
                setPresetFormValue(
                  presets.find((p) => p.id === presetId)?.name || ''
                )
                setPresetFormOpen(true)
              }}
            />
          )}
          {stream.project.user_attrs.ptz_level === 30 && (
            <KeyedButton
              tooltip={stream.ptz_locked ? 'Unlock PTZ' : 'Lock PTZ'}
              disabled={stream.project.user_attrs.ptz_level < 30}
              icon={stream.ptz_locked ? <LockIcon /> : <LockOpenIcon />}
              onClick={async () => {
                stream.ptz_locked = !stream.ptz_locked
                api.ptzApi.streamsPtzLockstatusPartialUpdate({
                  streamId: stream.id.toString(),
                  data: {
                    ptz_locked: stream.ptz_locked,
                  },
                })
                setPtzLocked(stream.ptz_locked)
              }}
            />
          )}
          {stream.ptz_wiper && (
            <KeyedButton
              tooltip={'Trigger lens wiper'}
              icon={<TbWiper />}
              onClick={() => {
                api.ptzApi.streamsPtzLenswipeCreate({
                  streamId: stream.id.toString(),
                  data: {},
                })
              }}
            />
          )}
          <KeyedButton
            tooltip={'PTZ help'}
            icon={<HelpOutlineIcon />}
            onClick={() => {
              setHelpModalOpen(true)
            }}
          />
          {(setPtzState || setRtcState) && (
            <KeyedButton
              tooltip={'Exit PTZ Mode'}
              keyCode="p"
              icon={<CloseIcon />}
              onClick={() => {
                setPtzState && setPtzState(false)
                setRtcState && setRtcState(false)
              }}
            />
          )}
        </Card>
      )}
      {/* End PTZ controls */}
    </>
  )
}
