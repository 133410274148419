import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { debounce } from 'lodash'
import React from 'react'
import { useAlertConfirmPrompt } from '../../AlertConfirmPrompt'
import { DebouncedTextField } from '../../FormInputs/DebouncedTextField'
import { useProject } from '../../ProjectWrapper'
import { useUploadsStore } from './../Uploads/UploadsController'
import { getUseVODStore } from './../VODController'
import { VODDateTimeTextInput } from './../VODDateTimeTextInput'
import { useShoringStore } from './VODShoringController'

export const ShoringBayEditor = () => {
  const project = useProject()
  const { alert } = useAlertConfirmPrompt()
  const shoringStore = useShoringStore()
  const useVODStore = getUseVODStore()
  const uploadsStore = useUploadsStore()

  const uploads = uploadsStore((state) => state.uploads)

  const bays = shoringStore((state) => state.bays)
  const selectedBayId = shoringStore((state) => state.selectedBayId)
  const linkMode = shoringStore((state) => state.linkMode)
  const nodeTypes = shoringStore((state) => state.nodeTypes)
  const teams = shoringStore((state) => state.teams)

  const [editPredecessors, setEditPredecessors] = React.useState(false)
  const [editSuccessors, setEditSuccessors] = React.useState(false)

  const selectedBay = selectedBayId && bays.get(selectedBayId)

  if (!selectedBay) return null

  return (
    <>
      <Grid item xs={12}>
        Node ID: {selectedBay.id.toString().slice(-4)}
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          options={Array.from(nodeTypes.keys()).filter((nt) => !!nt)}
          value={selectedBay.node_type}
          onChange={debounce((event, newValue) => {
            shoringStore.getState().saveSelectedBay({
              node_type: newValue,
            })
          }, 1000)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Node Type"
              margin="normal"
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <DebouncedTextField
          fullWidth
          variant="outlined"
          label="Title"
          size="small"
          value={selectedBay.title || ''}
          onDebouncedChange={(value) => {
            shoringStore.getState().saveSelectedBay({
              title: value,
            })
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <DebouncedTextField
          fullWidth
          variant="outlined"
          label="Description"
          size="small"
          value={selectedBay.description || ''}
          onDebouncedChange={(value) => {
            shoringStore.getState().saveSelectedBay({
              description: value,
            })
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <DebouncedTextField
          fullWidth
          variant="outlined"
          label="Team"
          size="small"
          value={selectedBay.team || ''}
          onDebouncedChange={(value) => {
            shoringStore.setState({ defaultTeam: value })
            shoringStore.getState().saveSelectedBay({
              team: value,
            })
          }}
          style={{
            backgroundColor: selectedBay.team
              ? teams.get(selectedBay.team)
              : undefined,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DebouncedTextField
          fullWidth
          variant="outlined"
          label="Workers"
          size="small"
          type="number"
          value={selectedBay.worker_count?.toString() || ''}
          onDebouncedChange={(value) => {
            const count = value ? parseInt(value) : undefined
            shoringStore.setState({
              defaultWorkerCount: count,
            })
            shoringStore.getState().saveSelectedBay({
              worker_count: count,
            })
          }}
        />
      </Grid>

      <VODDateTimeTextInput
        label="Start time"
        value={selectedBay.startDateTime}
        onChange={(d) => {
          if (selectedBay.endDateTime && d && d >= selectedBay.endDateTime) {
            alert({
              title: "You can't set the start time after the end time",
            })
          } else {
            shoringStore.getState().saveSelectedBay({
              startDateTime: d,
            })
          }
        }}
        nullable
      />
      <VODDateTimeTextInput
        label="End time"
        value={selectedBay.endDateTime}
        onChange={(d) => {
          if (
            selectedBay.startDateTime &&
            d &&
            d <= selectedBay.startDateTime
          ) {
            alert({
              title: "You can't set the end time before the start time",
            })
          } else {
            shoringStore.getState().saveSelectedBay({
              endDateTime: d,
            })
          }
        }}
        nullable
      />

      <Divider />
      {Array.from(selectedBay.streamCoordinates.values()).map((sc) => (
        <Grid key={sc.stream_id} item xs={12} container spacing={1}>
          <Grid
            item
            xs={12}
            onClick={() =>
              useVODStore.getState().gotoVideo({
                streamId: sc.stream_id,
              })
            }
            style={{ cursor: 'pointer' }}
          >
            {project.streams.find((s) => s.id === sc.stream_id)?.name}
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              label="x"
              value={Math.floor(sc.x)}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              label="y"
              size="small"
              value={Math.floor(sc.y)}
            />
          </Grid>
        </Grid>
      ))}
      <Divider />

      {Array.from(selectedBay.uploadCoordinates.values()).map((coord) => (
        <Grid key={coord.upload_id} item xs={12} container spacing={1}>
          <Grid
            item
            xs={12}
            onClick={() => {
              const upload = uploads?.find((u) => u.id === coord.upload_id)
              if (upload) {
                uploadsStore.setState({ selectedUpload: upload })
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            {uploads?.find((u) => u.id === coord.upload_id)?.basename}
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              label="x"
              value={Math.floor(coord.x)}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              label="y"
              size="small"
              value={Math.floor(coord.y)}
            />
          </Grid>
        </Grid>
      ))}

      {/* <Grid item xs={12}>
        <Typography>
          Predecessors{' '}
          <IconButton
            size="small"
            title="Add predecessors"
            color={linkMode === 'link predecessors' ? 'primary' : 'default'}
            onClick={() =>
              shoringStore.setState({
                linkMode:
                  linkMode === 'link predecessors'
                    ? 'none'
                    : 'link predecessors',
              })
            }
          >
            <AddIcon />
          </IconButton>
          <IconButton
            size="small"
            title="Edit predecessors"
            color={editPredecessors ? 'primary' : 'default'}
            onClick={() => setEditPredecessors(!editPredecessors)}
          >
            <EditIcon />
          </IconButton>
        </Typography>
        {Array.from(selectedBay.predecessors.values()).map((id, i) => (
          <a
            key={id}
            onMouseEnter={() => shoringStore.setState({ hoveredBayId: id })}
            onMouseLeave={() =>
              shoringStore.setState({ hoveredBayId: undefined })
            }
            onClick={() => shoringStore.setState({ selectedBayId: id })}
            style={{
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: 2,
            }}
          >
            {id}
            {editPredecessors && (
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation()
                  shoringStore.getState().deleteRelationship({
                    predecessorId: id,
                    successorId: selectedBay.id,
                  })
                }}
              />
            )}
            {i < selectedBay.predecessors.size - 1 && ', '}
          </a>
        ))}
      </Grid> */}

      {/* <Grid item xs={12}>
        <Typography>
          Successors{' '}
          <IconButton
            size="small"
            title="Add successors"
            color={linkMode === 'link successors' ? 'primary' : 'default'}
            onClick={() =>
              shoringStore.setState({
                linkMode:
                  linkMode === 'link successors' ? 'none' : 'link successors',
              })
            }
          >
            <AddIcon />
          </IconButton>
          <IconButton
            size="small"
            title="Edit successors"
            color={editSuccessors ? 'primary' : 'default'}
            onClick={() => setEditSuccessors(!editSuccessors)}
          >
            <EditIcon />
          </IconButton>
        </Typography>
        {Array.from(selectedBay.successors.values()).map((id, i) => (
          <a
            key={id}
            onMouseEnter={() => shoringStore.setState({ hoveredBayId: id })}
            onMouseLeave={() =>
              shoringStore.setState({ hoveredBayId: undefined })
            }
            onClick={() => shoringStore.setState({ selectedBayId: id })}
            style={{
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: 2,
            }}
          >
            {id}
            {editSuccessors && (
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation()
                  shoringStore.getState().deleteRelationship({
                    predecessorId: selectedBay.id,
                    successorId: id,
                  })
                }}
              />
            )}
            {i < selectedBay.successors.size - 1 && ', '}
          </a>
        ))}
      </Grid> */}

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          onClick={() => shoringStore.getState().deleteBay()}
        >
          Delete
        </Button>
      </Grid>
    </>
  )
}
