import { Chip, createStyles, Grid, makeStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { Photosphere as PhotosphereType } from '../../api/codegen/typescript-axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    dateChip: {
      marginRight: 4,
      marginBottom: 4,
    },
    title: {
      whiteSpace: 'nowrap',
    },
  })
)

export const PhotosphereDates = ({
  spheres,
  sphereId,
  currentSphere,
  setSphereId,
}: {
  spheres?: PhotosphereType[]
  sphereId?: number
  currentSphere?: PhotosphereType
  setSphereId: (id: number) => void
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        {spheres
          ?.filter((s) => s.template === currentSphere?.template)
          .reverse()
          .map((sphere) => (
            <Chip
              key={sphere.id}
              color={sphere.id === sphereId ? 'primary' : 'default'}
              className={classes.dateChip}
              label={DateTime.fromISO(sphere.image_datetime).toFormat('M/d')}
              onClick={() => setSphereId(sphere.id)}
            />
          ))}
      </Grid>
    </>
  )
}
