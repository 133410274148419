import { Button, ButtonGroup, useTheme } from '@material-ui/core'
import React from 'react'
import { useLocalStorage, useWindowSize } from 'react-use'
import { useContextBridge } from '../../../hooks/useContextBridge'
import { useMe } from '../../../hooks/useMe'
import { useMobile } from '../../../hooks/useMobile'
import { useTouchDevice } from '../../../hooks/useTouchDevice'
import { ApiContext } from '../../ApiContext'
import { DragResizeBox } from '../../DragResizeBox'
import { Timeline } from '../Timeline/Timeline'
import { TimelineStreams } from '../Timeline/TimelineStreams'
import { UploadsContext, UploadsController } from '../Uploads/UploadsController'
import { UploadsPanel } from '../Uploads/UploadsPanel'
import { VODClock } from '../VODClock'
import { VODContext, VODController } from '../VODController'
import { VODControls } from '../VODControls'
import { VODPlayer } from '../VODPlayer'
import { VODSelectMobile } from '../VODSelectMobile'
import { VODThumbnails } from '../VODThumbnails'
import { DeckAIContext, DeckAIController } from './DeckAIController'
import { DeckAIControls } from './DeckAIControls'
import { DeckAIDrawingOverlay } from './DeckAIDrawingOverlay'
import { TimelineDeckAI } from './TimelineDeckAI'

interface Windows {
  Timeline: boolean
  Drawings?: boolean
  Thumbnails: boolean
}

const defaultWindows = {
  Timeline: false,
  Drawings: false,
  Thumbnails: true,
}

export const DeckAIPage = () => {
  return (
    <VODController>
      <UploadsController>
        <DeckAIController>
          <DeckAILayout />
        </DeckAIController>
      </UploadsController>
    </VODController>
  )
}

export const DeckAILayout = () => {
  const theme = useTheme()
  const { data: me } = useMe()

  const [windows, setWindows] = useLocalStorage<Windows>(
    'DeckAIWindows',
    defaultWindows,
    {
      raw: false,
      serializer: JSON.stringify,
      deserializer: (value: string) => {
        // remove any deprecated windows
        const storageValue = JSON.parse(value)
        for (const key in storageValue) {
          if (!(key in defaultWindows)) {
            delete storageValue[key]
          }
        }
        return { ...defaultWindows, ...storageValue }
      },
    }
  )

  const touchDevice = useTouchDevice()
  const isMobile = useMobile()
  const { height } = useWindowSize()

  const ContextBridge = useContextBridge(
    ApiContext,
    VODContext,
    UploadsContext,
    DeckAIContext
  )

  if (!windows) return null

  return (
    <div
      style={{
        height: height - theme.custom.projectNavHeight,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {isMobile && <VODSelectMobile />}
      <div
        style={{
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 6,
          height: 44,
          minHeight: 44,
          borderBottom: '2px solid #000',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <VODClock />
        </div>
        {!touchDevice && (
          <ButtonGroup style={{ display: 'block' }}>
            {(Object.keys(windows) as Array<keyof Windows>).map((w) => (
              <Button
                key={w}
                variant="contained"
                size="small"
                color={windows[w] ? 'primary' : 'default'}
                onClick={() =>
                  setWindows({
                    ...windows,
                    [w]: !windows[w],
                  })
                }
              >
                {w.replace('_', ' ')}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </div>

      <div style={{ display: 'flex', height: 0, flexGrow: 1 }}>
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Player */}
          <VODPlayer>
            <ContextBridge>
              <></>
            </ContextBridge>
          </VODPlayer>

          {/* New Controls */}
          {!touchDevice && <VODControls />}
        </div>

        {!isMobile && windows.Drawings && me?.isAnnotator && (
          <DragResizeBox
            handleSide="left"
            defaultSize={600}
            minSize={400}
            localStorageKey="deckai-drawings-col"
            contentStyle={{ display: 'flex', flexDirection: 'column' }}
          >
            <div style={{ padding: 8 }}>
              <DeckAIControls />
            </div>
            <div style={{ flexGrow: 1 }}>
              <UploadsPanel>
                <ContextBridge>
                  <DeckAIDrawingOverlay />
                </ContextBridge>
              </UploadsPanel>
            </div>
          </DragResizeBox>
        )}

        {!isMobile && windows.Thumbnails && (
          <DragResizeBox
            handleSide="left"
            defaultSize={190}
            minSize={190}
            localStorageKey="deckai-thumbs-col"
          >
            <VODThumbnails />
          </DragResizeBox>
        )}
      </div>

      {!touchDevice && windows.Timeline && (
        <DragResizeBox
          handleSide="top"
          defaultSize={200}
          minSize={110}
          localStorageKey="deckai-timeline"
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Timeline>
            <ContextBridge>
              <TimelineStreams rowGroupIndex={0} />
              {me?.isAnnotator && <TimelineDeckAI rowGroupIndex={1} />}
            </ContextBridge>
          </Timeline>
        </DragResizeBox>
      )}
    </div>
  )
}
