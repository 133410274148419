import { assertId, assertIsDefined, HasID } from './assertRequired'
import { Timelapse } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'
import { StreamExtended } from './StreamExtended'

export interface TimelapseExtended extends HasID<Timelapse> {
  isAutoTimelapse: boolean
  streamExtended: StreamExtended
}

export function extendTimelapse(
  timelapse: Timelapse,
  project: ProjectDetailsExtended
): TimelapseExtended {
  assertId(timelapse)

  const stream = project.streams.find((s) => s.id === timelapse.stream)
  assertIsDefined(stream)

  return {
    ...timelapse,
    streamExtended: stream,
    isAutoTimelapse:
      timelapse.video !== null &&
      stream.current_timelapse_url === timelapse.video,
  }
}
