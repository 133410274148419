import { DateTime } from 'luxon'
import { ProjectSummary } from '../../../api/codegen/typescript-axios'
import { ProjectDetailsExtended } from '../../../api/ProjectDetailsExtended'
import { ShoringBayExtended } from '../../../api/ShoringBayExtended'

export function getLatestPredecesssorEndDateTime(
  bayId: number,
  bays: Map<number, ShoringBayExtended>,
  project: ProjectDetailsExtended
) {
  const bay = bays.get(bayId)
  if (!bay) {
    // shouldnt happen
    throw 'no bay found'
  }

  // if this property is already set, we don't need to calculate it again
  if (bay.inferredEndDateTime) {
    return bay.inferredEndDateTime
  }

  // default zero
  let inferredStartDateTime = DateTime.fromMillis(0)

  if (bay.startDateTime) {
    // if it has a real start time just set it to that
    inferredStartDateTime = bay.startDateTime
  } else if (bay.predecessors.size) {
    // if not, set it to end of latest predecessor
    const latestPredecessor = Math.max(
      ...Array.from(bay.predecessors.values()).map((predecessor) =>
        getLatestPredecesssorEndDateTime(predecessor, bays, project).toMillis()
      )
    )
    inferredStartDateTime = DateTime.fromMillis(latestPredecessor, {
      zone: project.timezone,
    })
  } else if (bay.endDateTime) {
    inferredStartDateTime = bay.endDateTime
  }

  // actually set it on the bay
  bay.inferredStartDateTime = inferredStartDateTime
  bay.inferredSystemStartDateTime = inferredStartDateTime.setZone('system', {
    keepLocalTime: true,
  })

  // if it has no end time, set it to the same as start time, its timerange is now a moment
  bay.inferredEndDateTime = bay.endDateTime || inferredStartDateTime
  bay.inferredSystemEndDateTime = bay.inferredEndDateTime.setZone('system', {
    keepLocalTime: true,
  })

  // we return the inferred endtime because that's what the recursion actually needs to know
  return bay.inferredEndDateTime
}
