import { axisTop } from 'd3-axis'
import { ScaleTime } from 'd3-scale'
import { select as d3select, Selection } from 'd3-selection'
import { raf } from 'rafz'
import React, { memo, useEffect, useState } from 'react'
import { useCallbackRef } from 'use-callback-ref'
import { UseTimelineStore } from './Timeline'

export const TimelineAxis = memo(
  ({ useTimelineStore }: { useTimelineStore: UseTimelineStore }) => {
    // store the d3 selection
    const [selection, setSelection] = useState<
      Selection<SVGGElement, unknown, null, undefined>
    >()

    // store d3 axis
    const [axis] = useState(() =>
      axisTop<Date>(useTimelineStore.getState().originalScale).tickSizeOuter(0)
    )

    // svg g element callback
    const gRef = useCallbackRef<SVGGElement>(null, (gElement) => {
      if (gElement) {
        const selection = d3select(gElement)
        selection.call(axis)
        setSelection(selection)
      }
    })

    // subscribe to scale to rerender axis on scale change
    useEffect(
      () =>
        useTimelineStore.subscribe<ScaleTime<number, number>>(
          function rescaleAxis(currentScale) {
            raf.write(function writeAxis() {
              if (selection && currentScale) {
                axis.scale(currentScale)
                selection.call(axis)
              }
            })
          },
          (state) => state.currentScale
        ),
      [selection, axis]
    )

    return (
      <g
        ref={gRef}
        transform="translate(0 22)"
        style={{
          // fontFamily: "'Roboto Mono', monospace",
          fontSize: 12,
        }}
      ></g>
    )
  }
)

TimelineAxis.displayName = 'TimelineAxis'
