import { Paper } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  ThemeProvider,
  useTheme,
} from '@material-ui/core/styles'
import clsx from 'clsx'
import Hls from 'hls.js'
import React, { useCallback, useEffect, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { useNavigate } from 'react-router'
import { useIntersection, useUnmount } from 'react-use'
import { useCallbackRef } from 'use-callback-ref'
import { StreamExtended } from '../../api/StreamExtended'
import { useIOS } from '../../hooks/useIOS'
import useStreamLiveStatus from '../../hooks/useStreamLiveStatus'
import { mixins } from '../../styles/mixins'
import { darkTheme } from '../../styles/theme'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { Loading } from '../Loading'
import { useProject } from '../ProjectWrapper'
import { LivePlayerErrorMessages } from './LivePlayerErrorMessages'
import { VideoControls } from './VideoControls'
import { useMuxBaseConfig } from '../../hooks/useMuxBaseConfig'
// @ts-ignore
import mux from 'mux-embed'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      [theme.breakpoints.up('sm')]: {},
    },
    videoContainer: {
      backgroundColor: '#000',
      position: 'relative',
    },
    ptzContainer: {
      ...mixins.absoluteFill,
    },
    loadingSpinner: {
      color: '#fff',
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    poster: {
      background: 'center center no-repeat',
      backgroundSize: 'contain',
    },
    layer: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px',
      animation: '$fadeIn',
      animationDuration: '0.3s',
      animationDelay: '2s',
      animationFillMode: 'both',
    },
    errorMessages: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: '100%',
      padding: '6px',
      pointerEvents: 'none',
    },
    overlay: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      ...mixins.absoluteFill,
      display: 'block',
    },
    dialogPaper: {
      height: '100%',
    },
  })
)

export const VideoPlayerHLSMobile = ({
  stream,
  className,
  parentWidth = window.innerWidth,
}: {
  stream: StreamExtended
  className?: string
  parentWidth?: number
}) => {
  const classes = useStyles()
  const { alert } = useAlertConfirmPrompt()
  const navigate = useNavigate()
  const project = useProject()
  const muxBaseConfig = useMuxBaseConfig()

  const log = useCallback(
    (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(stream.name, ...args)
      }
    },
    [stream.name]
  )

  // state
  const videoContainerRef = useCallbackRef<HTMLDivElement>(null, () => {})

  const videoRef = useCallbackRef<HTMLVideoElement>(null, () => {})

  const videoEl = videoRef.current

  const [hlsPlayer, setHlsPlayer] = React.useState<Hls>()

  const iOS = useIOS()

  const intersection = useIntersection(videoContainerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })

  const isVisible = usePageVisibility()

  // Pause video when it's no longer visible
  useEffect(() => {
    if (
      videoEl &&
      (isVisible === false || intersection?.isIntersecting === false)
    ) {
      videoEl.pause()
    }
  }, [videoEl, isVisible, intersection?.isIntersecting])

  const [playState, setPlayState] = React.useState(false)
  useEffect(() => {
    if (!videoEl) return
    if (playState) {
      try {
        videoEl.play()
      } catch (err) {
        console.warn(err)
      }
    } else {
      videoEl.pause()
    }
  }, [videoEl, playState])

  const [buffering, setBuffering] = useState(false)

  const liveStatus = useStreamLiveStatus(stream)

  // setup player and error handler
  useEffect(() => {
    if (!videoEl || !stream.url) return

    const muxData = {
      ...muxBaseConfig,

      player_name: 'VideoPlayerHLSMobile', // any arbitrary string you want to use to identify this player

      // Video Metadata
      video_id: stream.id.toString(), // ex: 'abcd123'
      video_title: `${project.slug} - ${stream.name} - LIVE`, // ex: 'My Great Video'
      // video_series: '', // ex: 'Weekly Great Videos'
      video_stream_type: 'live', // 'live' or 'on-demand',
      video_content_type: 'live video',
    }

    if (videoEl.canPlayType('application/vnd.apple.mpegurl') && iOS) {
      console.log('videoEl.canPlayType(application/vnd.apple.mpegurl')

      mux.monitor(videoEl, {
        debug: false,
        data: muxData,
      })
    } else {
      const hls = new Hls({
        autoStartLoad: false,
        liveDurationInfinity: true,
      })
      hls.loadSource(stream.url)
      hls.attachMedia(videoEl)

      hls.on(Hls.Events.ERROR, (event, data) => {
        log(data)
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              // console.error('fatal network error encountered, try to recover')
              hls.startLoad()
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              // console.error('fatal media error encountered, try to recover')
              hls.recoverMediaError()
              break
            default:
              // cannot recover
              // console.error(data)
              log('unrecoverable error')
              hls.destroy()
              break
          }
        }
      })

      mux.monitor(videoEl, {
        debug: false,
        hlsjs: hls,
        Hls: Hls,
        data: muxData,
      })

      setHlsPlayer(hls)
    }
  }, [videoEl, stream.url, log])

  // dispose player on unmount
  useUnmount(() => {
    if (hlsPlayer) {
      hlsPlayer.destroy()
    }
  })

  // subtract 40 for control bar height, projectNavHeight for nav
  const parentAspectRatio =
    parentWidth / (window.innerHeight - 40 - useTheme().custom.projectNavHeight)

  const videoAspectRatio = stream.aspectRatioNumber

  const containerAspectRatio =
    stream.width === 1
      ? Math.max(parentAspectRatio, videoAspectRatio)
      : videoAspectRatio

  const containerStyle = {
    paddingTop: (1 / containerAspectRatio) * 100 + '%',
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper className={clsx(className, classes.root)}>
        <div
          ref={videoContainerRef}
          className={classes.videoContainer}
          style={containerStyle}
        >
          <video
            ref={videoRef}
            className={classes.video}
            style={{ width: '100%', height: '100%', top: 0, left: 0 }}
            poster={stream.current_thumbnail_url}
            controls={!hlsPlayer}
            muted
            src={stream.url || ''}
            onWaiting={() => {
              if (hlsPlayer) {
                setBuffering(true)
              }
            }}
            onPlaying={() => {
              if (hlsPlayer) {
                setBuffering(false)
              }
            }}
            onPlay={() => {
              hlsPlayer?.startLoad(-1)
              setPlayState(true)
            }}
            onPause={() => {
              hlsPlayer?.stopLoad()
              setPlayState(false)
              setBuffering(false)
            }}
          />

          {!stream.show_still_image && buffering && (
            <div className={classes.layer}>
              <Loading className={classes.loadingSpinner}></Loading>
            </div>
          )}
          <LivePlayerErrorMessages stream={stream} liveStatus={liveStatus} />
        </div>
        <VideoControls
          stream={stream}
          playState={hlsPlayer ? playState : undefined}
          setPlayState={hlsPlayer ? setPlayState : undefined}
          setFullScreenState={
            hlsPlayer
              ? () => {
                  if (videoEl) {
                    if (videoEl.requestFullscreen) {
                      videoEl.requestFullscreen()
                    } else if (videoEl.webkitEnterFullscreen) {
                      videoEl.webkitEnterFullscreen()
                    }
                    videoEl.play()
                  }
                }
              : undefined
          }
          setPtzState={
            (stream.ptz && project.user_attrs.ptz_level === 30) ||
            (stream.ptz &&
              project.user_attrs.ptz_level > 0 &&
              !stream.ptz_locked)
              ? () => {
                  navigate(`../ptz/${stream.id}`)
                }
              : undefined
          }
        />
      </Paper>
    </ThemeProvider>
  )
}
