import React from 'react'
import { Circle, Group, Rect, Text } from 'react-konva'
import { ShoringBayExtended } from '../../../api/ShoringBayExtended'
import { useUploadCanvasStore } from '../Uploads/UploadDisplay'
import { useUploadsStore } from '../Uploads/UploadsController'
import { useShoringStore } from './VODShoringController'

export const ShoringDrawingOverlay = () => {
  const shoringStore = useShoringStore()
  const uploadsStore = useUploadsStore()
  const uploadCanvasStore = useUploadCanvasStore()

  const bays = shoringStore((state) => state.bays)
  const addBay = shoringStore((state) => state.addBay)
  const overlayMouseMode = shoringStore((state) => state.overlayMouseMode)
  const overlaySettings = shoringStore((state) => state.overlaySettings)

  const selectedUpload = uploadsStore((state) => state.selectedUpload)
  const selectedUploadPageNumber = uploadsStore(
    (state) => state.selectedUploadPageNumber
  )

  const drawingWidth = uploadCanvasStore((state) => state.drawingWidth)
  const drawingHeight = uploadCanvasStore((state) => state.drawingHeight)
  const transform = uploadCanvasStore((state) => state.transform)

  if (!selectedUpload) return null

  return (
    <Group listening>
      <Rect
        x={0}
        y={0}
        width={drawingWidth}
        height={drawingHeight}
        fill={overlaySettings.background}
      />
      {overlayMouseMode === 'create' && (
        <Rect
          x={0}
          y={0}
          width={drawingWidth}
          height={drawingHeight}
          onMouseDown={(e) => {
            const stagePosition = e.target.getStage()?.getPointerPosition()
            const uploadId = uploadsStore.getState().selectedUpload?.id
            const selectedUploadPageNumber = uploadsStore.getState()
              .selectedUploadPageNumber
            if (!stagePosition || !uploadId || !selectedUploadPageNumber) {
              throw new Error('no pointerPosition')
            }
            const drawingPosition = transform
              .copy()
              .invert()
              .point(stagePosition)

            addBay({
              uploadCoord: {
                upload_id: uploadId,
                page_number: selectedUploadPageNumber,
                x: Math.floor(drawingPosition.x),
                y: Math.floor(drawingPosition.y),
              },
            })
          }}
        ></Rect>
      )}
      {Array.from(bays.values())
        .filter((bay) => {
          return (
            bay.uploadCoordinates.get(selectedUpload.id!)?.page_number ===
            selectedUploadPageNumber
          )
        })
        .map((bay, i) => {
          return (
            <BayCircle
              key={i}
              bay={bay}
              uploadId={selectedUpload.id!}
              uploadPageNumber={selectedUploadPageNumber}
            />
          )
        })}
    </Group>
  )
}

function BayCircle({
  bay,
  uploadId,
  uploadPageNumber,
}: {
  bay: ShoringBayExtended
  uploadId: number
  uploadPageNumber: number
}) {
  const shoringStore = useShoringStore()
  const uploadCanvasStore = useUploadCanvasStore()

  const selectedBayId = shoringStore((state) => state.selectedBayId)
  const hoveredBayId = shoringStore((state) => state.hoveredBayId)
  const overlayMouseMode = shoringStore((state) => state.overlayMouseMode)
  const linkMode = shoringStore((state) => state.linkMode)
  const overlaySettings = shoringStore((state) => state.overlaySettings)
  const teams = shoringStore((state) => state.teams)

  const uploadCoordinates = bay.uploadCoordinates.get(uploadId)

  if (!uploadCoordinates) {
    return null
  }

  const label = `${bay.team}${bay.teamIndex}`

  return (
    <Group
      x={uploadCoordinates.x}
      y={uploadCoordinates.y}
      listening={
        overlayMouseMode === 'select' ||
        overlayMouseMode === 'create' ||
        linkMode === 'link predecessors' ||
        linkMode === 'link successors'
      }
      draggable={overlayMouseMode === 'select'}
      onMouseEnter={() => shoringStore.setState({ hoveredBayId: bay.id })}
      onMouseLeave={() => shoringStore.setState({ hoveredBayId: undefined })}
      onPointerDown={(evt: any) => {
        // This is to prevent @use-gesture drag pan of the stage
        evt.evt.stopPropagation()

        shoringStore.getState().onClickNode(bay.id)
      }}
      onDragEnd={(e) => {
        const stagePosition = e.target.getStage()?.getPointerPosition()
        if (!stagePosition) {
          throw new Error('no pointerPosition')
        }
        const drawingPosition = uploadCanvasStore
          .getState()
          .transform.copy()
          .invert()
          .point(stagePosition)

        shoringStore.getState().saveSelectedBay({
          uploadCoordinate: {
            upload_id: uploadId,
            page_number: uploadPageNumber,
            x: Math.round(drawingPosition.x),
            y: Math.round(drawingPosition.y),
          },
        })
      }}
    >
      <Circle
        radius={overlaySettings.radius}
        x={0}
        y={0}
        fill={bay.team ? teams.get(bay.team) : '#f00'}
        stroke={
          selectedBayId === bay.id || hoveredBayId === bay.id
            ? overlaySettings.selected
            : selectedBayId &&
              (bay.predecessors.has(selectedBayId) ||
                bay.successors.has(selectedBayId))
            ? overlaySettings.related
            : 'rgba(0, 0, 0, 0)'
        }
        strokeWidth={2}
        strokeScaleEnabled={false}
      />
      {typeof bay.teamIndex === 'number' && (
        <Text
          text={label}
          align="center"
          verticalAlign="middle"
          fontStyle="bold"
          fontSize={(overlaySettings.radius / label.length) * 2.5}
          fill="#fff"
          stroke={'#000'}
          strokeWidth={1}
          fillAfterStrokeEnabled
          width={overlaySettings.radius * 2}
          height={overlaySettings.radius * 2}
          x={-overlaySettings.radius}
          y={-overlaySettings.radius * 0.95}
          listening={false}
        ></Text>
      )}
    </Group>
  )
}
