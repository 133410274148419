import React, { useMemo } from 'react'
import { scaleTime, scaleLinear } from '@visx/scale'
import { Group } from '@visx/group'
import { LinePath } from '@visx/shape'
import { RectClipPath } from '@visx/clip-path'
import { GridRows } from '@visx/grid'
import { curveNatural } from '@visx/curve'
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip'
import { UseLiveAICountStore, AICountPoint } from './LiveAICountController'
import shallow from 'zustand/shallow'
import { FastAxis } from '../Chart/FastAxis'
import { useMeasure } from 'react-use'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core'

const margin = { top: 20, right: 40, bottom: 60, left: 52 }

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#2e2c2c',
    color: '#fff',
  },
  date: {
    lineHeight: 1.3,
  },
  count: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 1.3,
  },
})

export const LiveAICountChartVX = ({
  useLiveAICountStore,
}: {
  useLiveAICountStore: UseLiveAICountStore
}) => {
  const classes = useStyles()

  const [liveaiClassesList, liveAiData, xDomain, yDomain] = useLiveAICountStore(
    (state) => [
      state.liveaiClassesList,
      state.liveAiData,
      state.xDomain,
      state.yDomain,
    ],
    shallow
  )

  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  const xMax = Math.max(width - margin.left - margin.right, 0)
  const yMax = Math.max(height - margin.top - margin.bottom, 0)

  const xScale = useMemo(
    () =>
      scaleTime<number>({
        domain: xDomain,
        range: [0, xMax],
      }),
    [width, xDomain]
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: yDomain,
        range: [yMax, 0],
      }),
    [width, yDomain]
  )

  const yTicks = Math.min(yDomain[1], 10)

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    date: Date
    count: number
    label: string
  }>()

  return (
    <div ref={ref} style={{ height: '100%', position: 'relative' }}>
      <svg width={width} height={'100%'} style={{ display: 'block' }}>
        <Group left={margin.left} top={margin.top}>
          <RectClipPath
            id="clip-path"
            width={xMax}
            height={yMax + 100}
            y={-50}
          />
          {/* <rect x={0} y={0} width={xMax} height={yMax} fill={'#111'} /> */}
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            numTicks={yTicks}
            stroke={'#333'}
          />
          <g clipPath="url(#clip-path)">
            {liveaiClassesList.map((klass, i) => (
              <LinePath<AICountPoint>
                key={klass.name}
                data={liveAiData.map((d) => ({
                  dateTime: d.dateTime,
                  count: d.box_count_avg_by_class[klass.name],
                }))}
                x={(d) => {
                  return xScale(d.dateTime) ?? 0
                }}
                y={(d) => yScale(d.count) ?? 0}
                stroke={klass.color}
                strokeWidth={2}
                // curve={curveNatural}
                onMouseMove={(e) => {
                  showTooltip({
                    tooltipLeft: e.nativeEvent.offsetX,
                    tooltipTop: e.nativeEvent.offsetY,
                    tooltipData: {
                      date: new Date(
                        xScale.invert(e.nativeEvent.offsetX - margin.left)
                      ),
                      count: Math.round(
                        yScale.invert(e.nativeEvent.offsetY - margin.top)
                      ),
                      label: klass.name,
                    },
                  })
                }}
                onMouseOut={hideTooltip}
              />
            ))}
          </g>
          <FastAxis scale={yScale} orientation="left" ticks={yTicks}></FastAxis>
          <FastAxis
            scale={xScale}
            orientation="bottom"
            top={yMax}
            ticks={3}
          ></FastAxis>
        </Group>
      </svg>

      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          className={classes.tooltip}
          style={{
            ...defaultStyles,
            backgroundColor: '#2e2c2c',
            color: '#fff',
            padding: 10,
          }}
        >
          <div className={classes.date}>
            {DateTime.fromJSDate(tooltipData.date).toFormat('MM/dd hh:mm:ss')}
          </div>
          <div className={classes.count}>
            {tooltipData?.count.toString()} {tooltipData?.label}s
          </div>
        </TooltipWithBounds>
      )}
    </div>
  )
}
