export const dateFormat = 'yyyy-MM-dd'
export const dateTimeFormat = `yyyy-MM-dd HH:mm`

export const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

export const annotationStates = [
  'unlabeled',
  'ai_labeled',
  'labeling',
  'labeled',
]

export const queryKeys = {
  PROJECTS: 'projects',
  STREAMS: 'streams',
  CATEGORIES: 'categories',
  IMAGE_STARTTIME: 'image-starttime',
  IMAGE_ENDTIME: 'image-endtime',
  ANNOTATION_STARTTIME: 'annotation-starttime',
  ANNOTATION_ENDTIME: 'annotation-endtime',
  REVIEW_STARTTIME: 'review-starttime',
  REVIEW_ENDTIME: 'review-endtime',
  USERS: 'users',
  TYPES: 'types',
  VERSION: 'version',
  STATES: 'states',
  MIN_SCORE: 'min-score',
  MAX_SCORE: 'max-score',
  REVIEWED: 'reviewed',
  PER: 'per',
  PAGE: 'page',
  ORDER: 'order',
  SAMPLE_RATE: 'sample_rate',
  ISSUE_RESOLVED: 'issue_resolved',
} as const
