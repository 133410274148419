import {
  Box,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from '@material-ui/core'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import produce from 'immer'
import React, { FC, useState } from 'react'
import zustand, { UseStore } from 'zustand'
import { createCtx } from '../helpers/createCtx'

export type KeyboardShortcutsState = {
  shortcuts: Map<React.ReactNode, string>
  get: (key: string) => string | undefined
  set: (key: string, value: string) => void
  delete: (key: string) => void
}

export const [useKeyboardShortcutsStore, KeyboardShortcutsContext] = createCtx<
  UseStore<KeyboardShortcutsState>
>()

export const KeyboardShortcutsController: FC<{}> = ({ children }) => {
  const [keyboardShortcutsStore] = useState(() =>
    zustand<KeyboardShortcutsState>((set, get) => ({
      shortcuts: new Map<string, string>(),
      get: (key: string) => get().shortcuts.get(key),
      set: (key: string, value: string) => {
        set(
          produce((draft: KeyboardShortcutsState) => {
            draft.shortcuts.set(key, value)
          })
        )
      },
      delete: (key: string) => {
        set(
          produce((draft: KeyboardShortcutsState) => {
            draft.shortcuts.delete(key)
          })
        )
      },
    }))
  )

  return (
    <KeyboardShortcutsContext.Provider value={keyboardShortcutsStore}>
      {children}
    </KeyboardShortcutsContext.Provider>
  )
}

export const KeyboardShortcutsMenu: FC<{}> = () => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const keyboardShortcutsStore = useKeyboardShortcutsStore()

  const shortcuts = keyboardShortcutsStore((state) => state.shortcuts)

  return (
    <Box displayPrint="none">
      {shortcuts.size > 0 && (
        <IconButton
          size="small"
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
          }}
        >
          <KeyboardIcon />
        </IconButton>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <Table size="small">
          <TableBody>
            {Array.from(shortcuts.entries()).map((shortcut) => (
              <TableRow key={shortcut[1]}>
                <TableCell>{shortcut[1]}</TableCell>
                <TableCell
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      background: theme.palette.background.default,
                      height: 30,
                      minWidth: 30,
                      padding: '0px 8px',
                      borderRadius: 2,
                      textAlign: 'center',
                      display: 'flex',
                      placeContent: 'center',
                      lineHeight: '30px',
                    }}
                  >
                    <span>{shortcut[0]}</span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Menu>
    </Box>
  )
}
