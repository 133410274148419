import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { vec2 } from 'gl-matrix'
import React, { memo } from 'react'
import { useMeasure } from 'react-use'
import { UseTimelineStore } from './Timeline'
import { TimelineAxis } from './TimelineAxis'
import { TimelineMarker } from './TimelineMarker'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      svg: {
        display: 'block',
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
      },
    }),
  {
    name: 'TimelineSVG',
  }
)

export const TimelineSVG = memo(
  ({
    useTimelineStore,
    mousePositionRef,
  }: {
    useTimelineStore: UseTimelineStore
    mousePositionRef: React.MutableRefObject<vec2>
  }) => {
    const classes = useStyles()
    const theme = useTheme()

    // rerender component on root element size change
    const [ref, { width }] = useMeasure<HTMLDivElement>()

    const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)

    return (
      <div ref={ref}>
        <svg className={classes.svg} height={54}>
          <TimelineAxis useTimelineStore={useTimelineStore} />
          <TimelineMarker
            useTimelineStore={useTimelineStore}
            storeKey="videoDateTime"
            mousePositionRef={mousePositionRef}
            color="#ffffff"
            containerWidth={width}
          />
          <TimelineMarker
            useTimelineStore={useTimelineStore}
            storeKey="mouseDateTime"
            mousePositionRef={mousePositionRef}
            color="#00ffff"
            containerWidth={width}
          />
          <rect
            x={0}
            y={14}
            width={leftColumnWidth}
            height={40}
            fill={theme.palette.background.default}
          ></rect>
          <rect
            x={leftColumnWidth}
            y={21}
            width={1}
            height={33}
            fill={theme.palette.divider}
          ></rect>
        </svg>
      </div>
    )
  }
)

TimelineSVG.displayName = 'TimelineSVG'
