import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      body: {
        'scrollbar-width': 'thin',
        scrollbarColor: `rgba(0,0,0,0.5) ${theme.palette.background.default}`,
      },
      '*::-webkit-scrollbar': {
        width: 8,
        height: 0,
      },
      '*::-webkit-scrollbar-track': {
        background: theme.palette.background.default,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: 0,
        border: `2px solid ${theme.palette.background.default}`,
      },
      'input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      'input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      a: {
        color: theme.palette.secondary.main,
      },
    },
  })
)

export const GlobalStyles = () => {
  const classes = useStyles()
  return null
}
