import { Grid, LinearProgress } from '@material-ui/core'
import React, { memo } from 'react'
import { UploadDisplay } from './UploadDisplay'
import { useUploadsStore } from './UploadsController'
import { UploadsLibrary } from './UploadsLibrary'

export const UploadsPanel: React.FC<{ className?: string }> = memo(
  ({ className, children }) => {
    const uploadsStore = useUploadsStore()
    const selectedUploadLoading = uploadsStore(
      (state) => state.selectedUploadLoading
    )

    return (
      <div
        style={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', width: '100%', left: 0, top: 0 }}>
          {selectedUploadLoading && <LinearProgress />}
        </div>
        <div
          className={className}
          style={{
            height: '100%',
            padding: '12px 6px',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            spacing={1}
            style={{ flexGrow: 1 }}
            direction="column"
          >
            <Grid item>
              <UploadsLibrary />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <UploadDisplay>{children}</UploadDisplay>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
)

UploadsPanel.displayName = 'UploadsPanel'
