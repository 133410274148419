import { createStyles, makeStyles } from '@material-ui/core'
import { useDrag } from '@use-gesture/react'
import clsx from 'clsx'
import React, { FC, useMemo, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import { mixins } from '../styles/mixins'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      vertical: {
        display: 'flex',
      },
      horizontal: {
        display: 'flex',
        flexDirection: 'column',
      },
      verticalHandle: {
        minWidth: 1,
        minHeight: '100%',
        cursor: 'col-resize',
        border: '0px solid',
        borderColor: theme.palette.divider,
        borderLeftWidth: 3,
        position: 'relative',
        '&:after': {
          content: '""',
          ...mixins.absoluteFill,
          width: 6,
          left: -3,
        },
      },
      horizontalHandle: {
        minWidth: '100%',
        minHeight: 1,
        cursor: 'row-resize',
        border: '0px solid',
        borderColor: theme.palette.divider,
        borderTopWidth: 3,
        position: 'relative',
        '&:after': {
          content: '""',
          ...mixins.absoluteFill,
          height: 6,
          top: -3,
        },
      },
      content: {
        flexGrow: 1,
        overflow: 'auto',
      },
    }),
  {
    name: 'DragResizeBox',
  }
)

export const DragResizeBox: FC<{
  handleSide: 'left' | 'right' | 'top' | 'bottom'
  localStorageKey: string
  defaultSize: number
  minSize?: number
  contentStyle?: React.CSSProperties
}> = ({
  handleSide,
  localStorageKey,
  defaultSize,
  minSize = 0,
  contentStyle = {},
  children,
}) => {
  const classes = useStyles()

  const vertical = handleSide === 'left' || handleSide === 'right'

  const initialLength = useMemo(() => {
    const stored = localStorage.getItem(localStorageKey)
    if (stored) {
      const parsed = parseInt(stored)
      if (!isNaN(parsed)) {
        return parsed
      } else {
        return defaultSize
      }
    } else {
      return defaultSize
    }
  }, [defaultSize, localStorageKey])

  const previousLength = useRef(initialLength)

  const [{ length }, spring] = useSpring(() => ({
    length: initialLength,
    immediate: true,
  }))
  const bind = useDrag((state) => {
    const {
      movement: [mx, my],
    } = state

    const addends = {
      left: -mx,
      right: mx,
      top: -my,
      bottom: my,
    }

    const value = Math.floor(
      Math.max(previousLength.current + addends[handleSide], minSize)
    )

    localStorage.setItem(localStorageKey, value.toString())
    spring.set({ length: value })

    if (state.last) {
      previousLength.current = value
    }
  })

  return (
    <animated.div
      style={
        vertical
          ? {
              minWidth: length,
              maxWidth: length,
              userSelect: 'none',
            }
          : {
              minHeight: length,
              maxHeight: length,
              userSelect: 'none',
            }
      }
      className={vertical ? classes.vertical : classes.horizontal}
    >
      {handleSide === 'left' && (
        <div className={classes.verticalHandle} {...bind()}></div>
      )}
      {handleSide === 'top' && (
        <div className={classes.horizontalHandle} {...bind()}></div>
      )}

      <div className={clsx(classes.content)} style={contentStyle}>
        {children}
      </div>

      {handleSide === 'right' && (
        <div className={classes.verticalHandle} {...bind()}></div>
      )}
      {handleSide === 'bottom' && (
        <div className={classes.horizontalHandle} {...bind()}></div>
      )}
    </animated.div>
  )
}
