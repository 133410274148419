import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import { StaticDateRangePicker } from '@material-ui/pickers'
import { DataGrid, GridColumns, GridValueGetterParams } from '@mui/x-data-grid'
import { union, without } from 'lodash'
import { DateTime, Info } from 'luxon'
import React from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import {
  Timelapse,
  TimelapseStateEnum,
} from '../../api/codegen/typescript-axios'
import { extendTimelapse, TimelapseExtended } from '../../api/TimelapseExtended'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'
import { GenericTimelapseVideo, timelapseQueryKeys } from './TimelapsePage'

const colorMap: Record<TimelapseStateEnum, { color: string; title: string }> = {
  initial: { color: '#f60', title: 'Processing' },
  processing: { color: '#f60', title: 'Processing' },
  failed: { color: '#f00', title: 'Processing failed. Contact support.' },
  completed: { color: '#0f0', title: 'Processing complete' },
}

const columns: GridColumns = [
  {
    field: 'Status',
    headerName: '',
    flex: 0,
    width: 10,
    align: 'center',
    hideSortIcons: true,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <>
          {(params.row as TimelapseExtended).isAutoTimelapse ? (
            <Tooltip title={'Auto-updating timelapse'} placement="right">
              <StarIcon
                style={{
                  color: '#fd0',
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={colorMap[params.row.state as TimelapseStateEnum].title}
              placement="right"
            >
              <span
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 5,
                  backgroundColor:
                    colorMap[params.row.state as TimelapseStateEnum].color,
                  display: 'inline-block',
                }}
              ></span>
            </Tooltip>
          )}
        </>
      )
    },
  },
  {
    field: 'Stream',
    valueGetter: (params: GridValueGetterParams) =>
      (params.row as Timelapse).stream_attrs?.name,
    flex: 1,
  },
  {
    field: 'Dates',
    valueGetter: (params: GridValueGetterParams) =>
      `${DateTime.fromISO(params.row.start_date as string).toLocaleString(
        DateTime.DATE_SHORT
      )} - ${DateTime.fromISO(params.row.end_date as string).toLocaleString(
        DateTime.DATE_SHORT
      )}`,
    flex: 1,
  },
  {
    field: 'Time',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.start_hour} - ${params.row.end_hour}`,
    flex: 1,
  },
]

export const CustomTimelapsePanel = ({
  video,
  setVideo,
}: {
  video?: GenericTimelapseVideo
  setVideo: (v: GenericTimelapseVideo) => void
}) => {
  const api = useApi()
  const project = useProject()
  const { alert } = useAlertConfirmPrompt()

  const [searchParams, setSearchParams] = useSearchParams()
  const customParam = searchParams.get(timelapseQueryKeys.custom)

  const [modalOpen, setModalOpen] = React.useState(false)

  const [formState, setFormState] = React.useState<Timelapse>({
    stream: project.streams[0].id,
    start_date: DateTime.now().minus({ weeks: 1 }).toISO(),
    end_date: DateTime.now().toISO(),
    start_hour: '10:00',
    end_hour: '14:00',
    days_of_week: [0, 1, 2, 3, 4],
  })

  const { data: timelapses, refetch } = useQuery(
    ['projectsTimelapsesList', project.id],
    async () => {
      const resp = await api.timelapsesApi.projectsTimelapsesList({
        projectId: project.id.toString(),
      })

      return resp.data.map((tl) => extendTimelapse(tl, project))
    },
    {
      refetchInterval: 1000 * 60,
    }
  )

  const [{ error }, postTimelapse] = useAsyncFn(async () => {
    const resp = await api.timelapsesApi.projectsTimelapsesCreate({
      projectId: project.id.toString(),
      data: formState,
    })
    refetch()
    return resp.data
  }, [project, formState, refetch])

  React.useEffect(() => {
    if (!customParam) return
    const tl = timelapses?.find((t) => t.id === parseInt(customParam))
    if (!tl || !tl.video) return

    setVideo({
      url: tl.video,
      title: `Custom timelapse: ${tl.stream_attrs?.name} ${DateTime.fromISO(
        tl.start_date
      ).toLocaleString(DateTime.DATE_SHORT)} - ${DateTime.fromISO(
        tl.end_date
      ).toLocaleString(DateTime.DATE_SHORT)}`,
    })
  }, [timelapses, customParam])

  const selectedTimelapse = timelapses?.find((t) => t.video === video?.url)

  return (
    <div style={{ height: '100%', padding: 10 }}>
      <Grid container spacing={1} direction="column" style={{ height: '100%' }}>
        <Grid item>
          <Button onClick={() => setModalOpen(true)} variant="contained">
            Create Custom Timelapse
          </Button>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          {timelapses && (
            <DataGrid
              rows={timelapses}
              columns={columns}
              density="compact"
              onSelectionModelChange={(newSelectionModel) => {
                const tl = timelapses.find((t) => t.id === newSelectionModel[0])

                if (tl?.video && tl?.id) {
                  searchParams.set(timelapseQueryKeys.custom, tl.id.toString())
                  searchParams.delete(timelapseQueryKeys.stream)
                  setSearchParams(searchParams)
                } else {
                  alert({
                    title: 'Video not available',
                    description:
                      tl?.state === 'failed'
                        ? 'Something went wrong with the timelapse creation. Please contact support.'
                        : tl?.state === 'processing'
                        ? 'The timelapse is still processing. Please check back later'
                        : '',
                  })
                }
              }}
              selectionModel={selectedTimelapse ? [selectedTimelapse.id!] : []}
            />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={modalOpen}
        onKeyDown={(event) => event.stopPropagation()}
        maxWidth="md"
      >
        <DialogTitle>Create custom timelapse</DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <InputLabel variant="outlined">Stream</InputLabel>
                  <Select
                    fullWidth
                    native
                    label="Stream"
                    variant="outlined"
                    onChange={(e) => {
                      setFormState({
                        ...formState,
                        stream: parseInt(e.target.value as string),
                      })
                    }}
                  >
                    {project.streams.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  value={[
                    DateTime.fromISO(formState.start_date),
                    DateTime.fromISO(formState.end_date),
                  ]}
                  onChange={(d) => {
                    if (d[0]) {
                      setFormState({
                        ...formState,
                        start_date: d[0].toISO(),
                      })
                    }
                    if (d[1]) {
                      setFormState({
                        ...formState,
                        end_date: d[1].toISO(),
                      })
                    }
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TimePicker
                  value={formState.start_hour}
                  onChange={(hour) => {
                    setFormState({
                      ...formState,
                      start_hour: hour,
                    })
                  }}
                  label="Start hour"
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  value={formState.end_hour}
                  onChange={(hour) => {
                    setFormState({
                      ...formState,
                      end_hour: hour,
                    })
                  }}
                  label="End hour"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Days of week</FormLabel>
                  <FormGroup row>
                    {Info.weekdays().map((day, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              formState.days_of_week &&
                              formState.days_of_week.indexOf(i) > -1
                            }
                            onChange={(e) => {
                              setFormState({
                                ...formState,
                                days_of_week: e.target.checked
                                  ? union(formState.days_of_week, [i]).sort()
                                  : without(formState.days_of_week, i).sort(),
                              })
                            }}
                            name={day}
                          />
                        }
                        label={day}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          {error?.message}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                await postTimelapse()
                await alert({
                  title: 'Your request was received',
                  description:
                    "Your timelapse will take some time to process. You'll receive an email when it's ready.",
                })
                setModalOpen(false)
              } catch (error) {
                await alert({
                  title: 'Something went wrong with your request',
                  description: 'Please contact support for help.',
                })
              }
            }}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const TimePicker = ({
  value,
  onChange,
  label,
}: {
  value: string
  onChange: (value: string) => void
  label: string
}) => (
  <FormControl fullWidth>
    <InputLabel id="demo-simple-select-helper-label" variant="outlined">
      {label}
    </InputLabel>
    <Select
      fullWidth
      native
      label={label}
      labelId="demo-simple-select-helper-label"
      variant="outlined"
      value={value}
      onChange={(e) => {
        onChange(e.target.value as string)
      }}
    >
      {[
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
        '23:59',
      ].map((t) => (
        <option key={t} value={t}>
          {t}
        </option>
      ))}
    </Select>
  </FormControl>
)
