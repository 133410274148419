import { ButtonGroup, IconButton } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import { useKRPanoStore } from './KRPanoProvider'

export const PhotospherePTZ = ({}: {}) => {
  const krpanoStore = useKRPanoStore()

  const krPano = krpanoStore((state) => state.krPanoElement)

  return (
    <ButtonGroup>
      {[
        {
          key: 'hlookat_moveforce',
          value: -1,
          tooltip: 'Pan left',
          keyCode: 'ArrowLeft',
          rotate: 180,
        },
        {
          key: 'hlookat_moveforce',
          value: 1,
          tooltip: 'Pan right',
          keyCode: 'ArrowRight',
          rotate: 0,
        },
        {
          key: 'vlookat_moveforce',
          value: -1,
          tooltip: 'Tilt up',
          keyCode: 'ArrowUp',
          rotate: 270,
        },
        {
          key: 'vlookat_moveforce',
          value: 1,
          tooltip: 'Tilt down',
          keyCode: 'ArrowDown',
          rotate: 90,
        },
      ].map((c, i) => (
        <IconButton
          key={i}
          size="small"
          onMouseDown={() => krPano?.set(c.key, c.value)}
          onMouseUp={() => krPano?.set(c.key, 0)}
        >
          <ArrowForwardIcon
            fontSize="inherit"
            style={{ transform: `rotate(${c.rotate}deg)` }}
          />
        </IconButton>
      ))}
      <IconButton
        size="small"
        onMouseDown={() => krPano?.set('fov_moveforce', -1)}
        onMouseUp={() => krPano?.set('fov_moveforce', 0)}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        size="small"
        onMouseDown={() => krPano?.set('fov_moveforce', 1)}
        onMouseUp={() => krPano?.set('fov_moveforce', 0)}
      >
        <ZoomOutIcon />
      </IconButton>
    </ButtonGroup>
  )
}
