import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import React, { useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import logoColor from '../../images/logo-color.png'
import logoWhite from '../../images/logo-white.png'
import { useApi } from './ApiContext'
import { useThemeControl } from './Context/ThemeController'
import { ProgressButton } from './FormInputs/ProgressButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 8,
      margin: '0',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      width: '100%',
      maxWidth: 420,
    },
    logo: {
      width: 196,
      display: 'block',
      margin: '30px auto 0',
    },
    loginButtonWrapper: {
      flexDirection: 'column',
    },
    loginButton: {
      marginBottom: 10,
    },
    link: {
      color: '#39aee6',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    forgotPasswordButtons: {
      display: 'flex',
    },
    resetPassword: {
      marginLeft: 10,
      flexGrow: 1,
    },
  })
)

export const LoginPage = (props: {}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const api = useApi()
  const classes = useStyles()
  const { themeType } = useThemeControl()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [forgotPassword, setForgotPassword] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const [loginState, doLogin] = useAsyncFn(async () => {
    try {
      const me = await api.login(email, password)
      const locationState = location.state as { destination?: string }
      if (locationState && locationState.destination) {
        navigate(locationState.destination, { replace: true })
      }
      return me
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        throw new Error('Invalid email or password')
      }
      throw error
    }
  }, [email, password])

  const [resetState, doReset] = useAsyncFn(async () => {
    try {
      const value = await api.resetPassword(email)
      setEmailSent(true)
      return value
    } catch (error) {
      throw error
    }
  }, [email])

  if (loginState.value) {
    return <Navigate replace to={`/projects`} />
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                className={classes.logo}
                src={themeType === 'light' ? logoColor : logoWhite}
                alt="Topdeck logo"
              />
            </Grid>
            <Grid item xs={12}>
              {!forgotPassword ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    doLogin()
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        type="password"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProgressButton
                        className={classes.loginButtonWrapper}
                        buttonClassname={classes.loginButton}
                        type="submit"
                        fullWidth
                        disabled={false}
                        fetchState={loginState}
                      >
                        Sign in
                      </ProgressButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span
                          className={classes.link}
                          onClick={() => setForgotPassword(true)}
                        >
                          Forgot Password?
                        </span>
                      </Typography>
                      <Typography gutterBottom>
                        <a
                          className={classes.link}
                          href="mailto:support@topdeck.ai"
                        >
                          Need help? Contact us
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              ) : emailSent ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    A reset password email has been sent to {email}. Check your
                    spam folder if you don't see it.
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        setEmailSent(false)
                        setForgotPassword(false)
                      }}
                    >
                      Okay
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    doReset()
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.forgotPasswordButtons}
                    >
                      <Button
                        variant="contained"
                        onClick={() => setForgotPassword(false)}
                      >
                        Cancel
                      </Button>
                      <ProgressButton
                        type="submit"
                        className={classes.resetPassword}
                        fullWidth
                        disabled={false}
                        fetchState={resetState}
                      >
                        Request Password Reset
                      </ProgressButton>
                    </Grid>
                  </Grid>
                </form>
              )}{' '}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
