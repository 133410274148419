import { DateTime } from 'luxon'
import { SegmentationProgress } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'

export interface SegmentationProgressExtended extends SegmentationProgress {
  id: number
  startDateTime: DateTime
  endDateTime: DateTime
  // system times are the real world times, shifted to the browser system's timezone,
  // because the timeline D3 scale can only be in that timezone, or in UTC
  systemStartDateTime: DateTime
  systemEndDateTime: DateTime
  areaChanged: number
  smallestDeltaMask: string
  smallestDeltaMaskScale: number
}

export function extendSegmentationProgress(
  progress: SegmentationProgress,
  project: ProjectDetailsExtended
): SegmentationProgressExtended {
  const endDateTime = DateTime.fromISO(progress.end_datetime, {
    zone: project.timezone,
  })

  const startDateTime = progress.start_datetime
    ? DateTime.fromISO(progress.start_datetime, { zone: project.timezone })
    : endDateTime.minus({ minutes: 10 })

  const systemStartDateTime = startDateTime.setZone('system', {
    keepLocalTime: true,
  })

  const systemEndDateTime = endDateTime.setZone('system', {
    keepLocalTime: true,
  })

  return {
    ...progress,
    id: progress.id || -1,
    startDateTime,
    endDateTime,
    systemStartDateTime,
    systemEndDateTime,
    areaChanged: progress.area_changed || 0,
    smallestDeltaMask: progress.resized_delta_mask || progress.delta_mask || '',
    smallestDeltaMaskScale: progress.resized_delta_mask ? progress.scale : 1,
  }
}
