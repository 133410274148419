import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { memo, useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import { useMergeRefs } from 'use-callback-ref'
import cursor from '../../../images/cursor.png'
import { StreamExtended } from '../../api/StreamExtended'
import { VideoSummaryExtended } from '../../api/VideoSummaryExtended'
import useRefCurrent from '../../hooks/useRefCurrent'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      cursor: `url(${cursor}) 22 22, auto`,
      marginBottom: 8,
      borderRadius: 0,
    },
    video: {
      width: '100%',
      display: 'block',
      outline: 'none',
      borderBottom: 'solid transparent',
      borderBottomWidth: theme.spacing(0.5),
    },
    label: {
      padding: '2px 6px',
    },
  }),
  {
    name: 'VODPlayer',
  }
)

export const MultiPlayerVideo = memo(
  ({ stream }: { stream: StreamExtended }) => {
    const classes = useStyles()
    const project = useProject()
    const api = useApi()
    const [videoRef, videoEl] = useRefCurrent<HTMLVideoElement>(null)
    const useVODStore = getUseVODStore()
    const videoPlaying = useVODStore((state) => state.videoPlaying)

    const [video, setVideo] = useState<VideoSummaryExtended>()

    useEffect(
      () =>
        useVODStore.subscribe<boolean>(
          (videoPlaying) => {
            if (videoEl) {
              if (videoPlaying) {
                videoEl.play()
              } else {
                videoEl.pause()
              }
            }
          },
          (state) => state.videoPlaying
        ),
      [useVODStore, videoEl]
    )

    useInterval(async () => {
      const _video = await api.getVideo({
        project,
        streamId: stream.id,
        dateTime: useVODStore.getState().videoDateTime,
        speed: useVODStore.getState().speed,
        goToNearestTime: false,
        preferStabilized: useVODStore.getState().preferStabilized,
      })
      if (_video) {
        setVideo(_video)

        if (videoEl) {
          const thisVideoDateTime = _video.localStartDateTime.plus({
            seconds: videoEl.currentTime * _video.speed,
          })
          const masterVideoDateTime = useVODStore.getState().videoDateTime

          if (
            Math.abs(thisVideoDateTime.diff(masterVideoDateTime).toMillis()) >
            3000
          ) {
            const currentTime =
              (masterVideoDateTime.toMillis() -
                _video.localStartDateTime.toMillis()) /
              1000 /
              _video.speed

            const masterClockSpeed = useVODStore.getState().speed

            const playbackRate = Math.max(
              0.1,
              Math.min(16, masterClockSpeed / _video.speed)
            )

            console.warn(_video, currentTime, playbackRate)

            videoEl.currentTime = currentTime
            videoEl.playbackRate = playbackRate
          }
        }
      }
    }, 1000)

    return (
      <Card className={clsx(classes.root, {})}>
        <video
          ref={useMergeRefs([videoRef])}
          src={video?.url}
          className={classes.video}
          playsInline
          autoPlay={videoPlaying}
          muted
          preload="auto"
        />
        <div className={classes.label}>
          <div className="streamName">{stream.name}</div>
        </div>
      </Card>
    )
  }
)

MultiPlayerVideo.displayName = 'MultiPlayerVideo'
