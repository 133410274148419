import React, { useState } from 'react'
import { Circle, Group, Line } from 'react-konva'
import { useStageContext } from '../VideoCanvas'

export const MaskPolygon = ({
  polygon,
  editable,
  editing,
  onClick,
  onEdit,
}: {
  polygon: number[]
  editable: boolean
  editing: boolean
  onClick: () => void
  onEdit: (polygon: number[]) => void
}) => {
  const { absoluteTransform, width, height } = useStageContext()

  const [hover, setHover] = useState<boolean>(false)
  const [hoveredPoint, setHoveredPoint] = useState<number>(-1)

  const inverseTransform = absoluteTransform.copy().invert()

  console.log('polygon', polygon)

  if (!width || !height) return null

  return (
    <>
      <Line
        points={polygon}
        fill={editing || hover ? '#000000' : '#000000'}
        stroke={editing || hover ? '#00ffff' : '#ff0000'}
        strokeWidth={2}
        strokeScaleEnabled={false}
        opacity={0.7}
        closed
        listening={editable}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
      />
      {editing &&
        polygon
          .filter((n, i) => i % 2 === 0)
          .map((n, i) => {
            const [x, y] = [polygon[i * 2], polygon[i * 2 + 1]]
            // const [nextX, nextY] =
            //   i === polygon.length / 2 - 1
            //     ? [polygon[0], polygon[1]]
            //     : [polygon[i * 2 + 2], polygon[i * 2 + 3]]
            // const [interpolateX, interpolateY] = [
            //   (x + nextX) / 2,
            //   (y + nextY) / 2,
            // ]
            return (
              <Group key={i}>
                {/* <Line
                  points={[x, y, nextX, nextY]}
                  stroke="#00ff00"
                  strokeHitEnabled={true}
                  hitStrokeWidth={30}
                  onMouseEnter={() => setHoveredPoint(i + 0.5)}
                  onMouseLeave={() => setHoveredPoint(-1)}
                  onMouseDown={() =>
                    polygon.splice(i * 2 + 2, 0, interpolateX, interpolateY)
                  }
                  onDragMove={(e) => {
                    const point = inverseTransform.point({
                      x: e.evt.offsetX,
                      y: e.evt.offsetY,
                    })
                    polygon[i * 2 + 2] = point.x
                    polygon[i * 2 + 3] = point.y
                    onEdit(polygon)
                  }}
                /> */}

                {/* existing points */}
                <Circle
                  radius={hoveredPoint === i ? 30 : 20}
                  x={x}
                  y={y}
                  fill="#0099ff"
                  stroke="#00ffff"
                  strokeWidth={2}
                  strokeScaleEnabled={false}
                  opacity={hoveredPoint === i ? 0.8 : 0.4}
                  onMouseEnter={() => setHoveredPoint(i)}
                  onMouseLeave={() => setHoveredPoint(-1)}
                  draggable
                  onDragMove={(e) => {
                    const point = inverseTransform.point({
                      x: e.evt.offsetX,
                      y: e.evt.offsetY,
                    })
                    polygon[i * 2] = point.x
                    polygon[i * 2 + 1] = point.y
                    onEdit(polygon)
                  }}
                />

                {/* add new points */}
                {/* <Circle
                  radius={hoveredPoint === i + 0.5 ? 30 : 20}
                  x={interpolateX}
                  y={interpolateY}
                  fill="#00ffff"
                  stroke="#00ffff"
                  opacity={hoveredPoint === i + 0.5 ? 1 : 0}
                  onMouseEnter={() => setHoveredPoint(i + 0.5)}
                  onMouseLeave={() => setHoveredPoint(-1)}
                  draggable
                  onDragStart={() =>
                    polygon.splice(i * 2 + 2, 0, interpolateX, interpolateY)
                  }
                  onDragMove={(e) => {
                    const point = inverseTransform.point({
                      x: e.evt.offsetX,
                      y: e.evt.offsetY,
                    })
                    polygon[i * 2 + 2] = point.x
                    polygon[i * 2 + 3] = point.y
                    onEdit(polygon)
                  }}
                /> */}
              </Group>
            )
          })}
    </>
  )
}
