import MaterialTable from '@material-table/core'
import {
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { ProjectUser } from '../api/codegen/typescript-axios'
import { dateFormat } from '../helpers/constants'
import { useMe } from '../hooks/useMe'
import { useApi } from './ApiContext'
import { PageWrapper } from './PageWrapper'
import { useProject } from './ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    bold: {
      fontWeight: 'bold',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    resetButtons: {
      display: 'flex',
    },
    addUserInputs: {
      height: '48px',
      display: 'flex',
      alignItems: 'flex-end',
    },
  })
)

const roles = ['viewer', 'manager', 'admin', 'superuser']

const ptzLevels = [
  { value: 0, name: 'None', description: 'No PTZ permissions.' },
  {
    value: 10,
    name: 'Use Presets',
    description: 'Ability to control the camera and select presets.',
  },
  {
    value: 20,
    name: 'Set Presets',
    description: 'Additional ability to save presets',
  },
  {
    value: 30,
    name: 'Full Access',
    description:
      'Additional ability to lock and unlock the ptz controls for other users.',
  },
]

const gteRole = (user1: ProjectUser, user2: ProjectUser) => {
  return roles.indexOf(user1.role) >= roles.indexOf(user2.role)
}

export const ManageProjectPage = (props: {}) => {
  const api = useApi()
  const classes = useStyles(props)
  const project = useProject()

  const { data: me } = useMe()

  const { value: users = [], retry: reloadUsers } = useAsyncRetry(async () => {
    if (!project) return
    const { data: users } = await api.projectUsersApi.projectsUsersList({
      projectId: project.id.toString(),
    })
    return users
  }, [project])

  const [error, setError] = useState<Error>()

  if (!project || !me) {
    return null
  }

  const meProject = users.find((u) => u.user!.id === me.id)
  const filteredUsers = users.filter((user) => gteRole(meProject!, user))

  return (
    <PageWrapper>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Manage Users: {project.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <table>
              <tbody>
                <tr>
                  <td>
                    {project.address_1} {project.address_2} {project.city}
                    {', '}
                    {project.state} {project.zip_code}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <MaterialTable
              title="Manage Roles"
              options={{
                actionsColumnIndex: -1,
                pageSize: 20,
                addRowPosition: 'first',
              }}
              data={filteredUsers}
              columns={[
                {
                  title: 'Email',
                  field: 'user.email',
                  editable: 'onAdd',
                  editComponent: (props) => (
                    <TextField
                      label="Email"
                      required
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      fullWidth
                      size="small"
                    />
                  ),
                },
                {
                  title: 'Start Date',
                  field: 'start_date',
                  editable: 'never',
                  render: (user) =>
                    user && user.start_date ? (
                      <>{format(new Date(user.start_date), dateFormat)}</>
                    ) : (
                      <></>
                    ),
                },
                {
                  title: 'Role',
                  field: 'role',
                  render: (user) => (
                    <span>
                      {user.role.slice(0, 1).toUpperCase() + user.role.slice(1)}
                    </span>
                  ),
                  editComponent: (props) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                          value={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.value)
                          }}
                        >
                          {roles
                            .filter(
                              (role) =>
                                roles.indexOf(role) <=
                                roles.indexOf(project.user_attrs.role)
                            )
                            .map((role) => (
                              <MenuItem key={role} value={role}>
                                {role.slice(0, 1).toUpperCase() + role.slice(1)}
                              </MenuItem>
                            ))}
                        </Select>
                        <Tooltip
                          title={
                            <>
                              Page access permissions:
                              <ul style={{ paddingLeft: '2em' }}>
                                {roles
                                  .filter(
                                    (role) =>
                                      roles.indexOf(role) <=
                                      roles.indexOf(project.user_attrs.role)
                                  )
                                  .reverse()
                                  .map((role) => (
                                    <li key={role} value={role}>
                                      {`${
                                        role.slice(0, 1).toUpperCase() +
                                        role.slice(1)
                                      }:`}
                                      <ul style={{ paddingLeft: '1em' }}>
                                        {project.roles[role].map((page) => (
                                          <li key={page}>{page}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          }
                        >
                          <HelpIcon fontSize="small" color="action" />
                        </Tooltip>
                      </div>
                    )
                  },
                },
                {
                  title: 'PTZ Level',
                  field: 'ptz_level',
                  render: (user) => (
                    <span>
                      {ptzLevels.find((l) => l.value === user.ptz_level)?.name}
                    </span>
                  ),
                  editComponent: (props) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                          value={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.value)
                          }}
                        >
                          {ptzLevels
                            .filter(
                              (level) =>
                                level.value <= project.user_attrs.ptz_level
                            )
                            .map((level) => (
                              <MenuItem key={level.value} value={level.value}>
                                {level.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <Tooltip
                          title={
                            <>
                              PTZ Permissions:
                              <ul style={{ paddingLeft: '2em' }}>
                                {ptzLevels
                                  .filter(
                                    (level) =>
                                      level.value <=
                                      project.user_attrs.ptz_level
                                  )
                                  .map((level) => (
                                    <li key={level.value}>
                                      {`${level.name}: ${level.description}`}
                                    </li>
                                  ))}
                              </ul>
                            </>
                          }
                        >
                          <HelpIcon fontSize="small" color="action" />
                        </Tooltip>
                      </div>
                    )
                  },
                },
              ]}
              editable={{
                onRowAdd: (user) => {
                  if (!user.user || !user.user.email || !user.role) {
                    return Promise.reject()
                  }
                  return api.projectUsersApi
                    .projectsUsersAddUsersByEmail({
                      projectId: project.id.toString(),
                      data: {
                        emails: [user.user.email],
                        role: user.role,
                        ptz_level: user.ptz_level,
                      },
                    })
                    .then(reloadUsers)
                    .catch(setError)
                },
                onRowUpdate: (newUser, oldUser) =>
                  api.projectUsersApi
                    .projectsUsersUpdate({
                      projectId: project.id.toString(),
                      userId: newUser.user!.id!.toString(),
                      data: newUser,
                    })
                    .then(reloadUsers)
                    .catch((error) => {
                      console.log(error)
                      setError(error)
                    }),

                onRowDelete: (user) =>
                  api.projectUsersApi
                    .projectsUsersDelete({
                      projectId: project.id.toString(),
                      userId: user.user!.id!.toString(),
                    })
                    .then(reloadUsers)
                    .catch(setError),
              }}
              localization={{
                body: {
                  editRow: {
                    deleteText: 'Are you sure you want to remove this user?',
                  },
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
          <AddUserForm
            project={project}
            reloadUsers={reloadUsers}
          ></AddUserForm>
        </Grid> */}
        </Grid>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(undefined)}
      >
        <Alert onClose={() => setError(undefined)} severity="error">
          <AlertTitle>Error</AlertTitle>
          {error && error.message}
        </Alert>
      </Snackbar>
    </PageWrapper>
  )
}
