import { Button, useTheme } from '@material-ui/core'
import MapIcon from '@material-ui/icons/Map'
import VideocamIcon from '@material-ui/icons/Videocam'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import React from 'react'
import { ShoringBayExtended } from '../../../api/ShoringBayExtended'
import { useShoringStore } from './VODShoringController'

const columns: GridColDef[] = [
  {
    field: 'uploadCoordinates',
    headerName: '+',
    width: 30,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => {
      const bay = params.row as ShoringBayExtended
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {bay.streamCoordinates.size ? <VideocamIcon></VideocamIcon> : null}
          {bay.uploadCoordinates.size ? <MapIcon></MapIcon> : null}
        </div>
      )
    },
  },
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    hideSortIcons: true,
    hide: true,
  },
  {
    field: 'teamIndex',
    headerName: '#',
    flex: 1,
  },
  {
    field: 'start_time',
    headerName: 'Date',
    // width: 100,
    flex: 1,
    disableColumnMenu: true,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams) => {
      return (
        ((params.row.startDateTime || params.row.endDateTime) as
          | DateTime
          | undefined)?.toLocaleString({
          weekday: 'narrow',
          month: '2-digit',
          day: '2-digit',
        }) || ''
      )
    },
  },
  {
    field: 'startDateTime',
    headerName: 'Start',
    // width: 100,
    flex: 1,
    disableColumnMenu: true,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return (
        (params.value as DateTime | undefined)?.toLocaleString(
          DateTime.TIME_24_SIMPLE
        ) || ''
      )
    },
  },
  {
    field: 'endDateTime',
    headerName: 'End',
    // width: 100,
    flex: 1,
    disableColumnMenu: true,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return (
        (params.value as DateTime | undefined)?.toLocaleString(
          DateTime.TIME_24_SIMPLE
        ) || ''
      )
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    hideSortIcons: true,
  },
  {
    field: 'team',
    headerName: 'Team',
    flex: 1,
  },
  {
    field: 'worker_count',
    headerName: 'Workers',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
]

export const ShoringTable = () => {
  const shoringStore = useShoringStore()
  const theme = useTheme()

  const bays = shoringStore((state) => state.bays)
  const selectedBayId = shoringStore((state) => state.selectedBayId)
  const hoveredBayId = shoringStore((state) => state.hoveredBayId)

  const selectionModels: number[] = [selectedBayId, hoveredBayId].filter(
    (id) => !!id
  ) as number[]

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        fontFamily: "'Roboto Mono', monospace",
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ padding: theme.spacing(1) }}>
        <Button
          variant="contained"
          onClick={() => shoringStore.getState().exportCSV('csv')}
        >
          Export CSV
        </Button>
      </div>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={Array.from(bays.values())}
          onRowEnter={(params) =>
            shoringStore.setState({
              hoveredBayId: (params.row as ShoringBayExtended).id,
            })
          }
          onRowLeave={() => shoringStore.setState({ hoveredBayId: undefined })}
          density={'compact'}
          selectionModel={selectionModels}
          onSelectionModelChange={(newSelectionModel) => {
            shoringStore.setState({
              selectedBayId: newSelectionModel[0] as number,
            })
          }}
          style={{
            fontFamily: "'Roboto Mono', monospace",
            border: 'none',
          }}
          columns={columns}
        />
      </div>
    </div>
  )
}
