import {
  CircularProgress,
  ClickAwayListener,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Axios from 'axios'
import { omit } from 'lodash'
import log from 'loglevel'
import React, { useState } from 'react'
import { useAsyncFn } from 'react-use'
import { PhotosphereDrawing } from '../../api/codegen/typescript-axios'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { KRPanoHotspot } from './KRPanoHotspot'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    overlayHeader: {
      display: 'flex',
    },
    overlayName: {
      flexGrow: 1,
      width: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameInput: {
      '& input': {
        padding: 0,
        height: '1.5em',
        lineHeight: 1.5,
      },
    },
    gridItem: {
      display: 'flex',
    },
    label: {
      width: 50,
      textAlign: 'right',
    },
    number: {
      width: 50,
      margin: '0 3px',
    },
    range: {
      flexGrow: 1,
      width: 'calc(100% - 100px)',
    },
  })
)

export const defaultDrawing: PhotosphereDrawing = {
  alpha: 0.5,
  scale: 1,
  ath: 0,
  atv: 90,
  tx: 0,
  ty: 0,
  tz: 0,
  rx: 0,
  ry: 0,
  rz: 0,
  ox: 0,
  oy: 0,
  sequence: 0,
  name: 'untitled',
}

const sliderConfig: Array<{
  key: keyof Pick<
    PhotosphereDrawing,
    | 'alpha'
    | 'scale'
    | 'ath'
    | 'atv'
    | 'tx'
    | 'ty'
    | 'tz'
    | 'rx'
    | 'ry'
    | 'rz'
    | 'ox'
    | 'oy'
  >
  range: [number, number]
}> = [
  { key: 'alpha', range: [0, 1] },
  { key: 'scale', range: [0, 4] },
  { key: 'ath', range: [0, 360] },
  { key: 'atv', range: [-90, 90] },
  { key: 'tx', range: [-1000, 1000] },
  { key: 'ty', range: [-1000, 1000] },
  { key: 'tz', range: [-1000, 1000] },
  { key: 'rx', range: [-10, 10] },
  { key: 'ry', range: [-10, 10] },
  { key: 'rz', range: [-180, 180] },
  { key: 'ox', range: [-2000, 2000] },
  { key: 'oy', range: [-2000, 2000] },
]

export const PhotosphereOverlay = ({
  drawing,
  file,
  onDelete,
  refreshDrawings,
}: {
  drawing: PhotosphereDrawing
  file?: File
  onDelete?: () => void
  refreshDrawings: () => void
}) => {
  const classes = useStyles()
  const api = useApi()
  const { confirm } = useAlertConfirmPrompt()

  const [visible, setVisible] = useState(!!file)
  const [editMode, setEditMode] = useState(!!file)

  const [drawingState, setDrawingState] = useState<PhotosphereDrawing>({
    ...drawing,
  })

  const [saveState, save] = useAsyncFn(
    async (state: PhotosphereDrawing) => {
      const templateId = drawing.template
      if (!templateId) return

      if (file) {
        // create drawing entry in api
        const resp = await api.photospheresApi.photosphereTemplatesDrawingsCreate(
          {
            templateId: templateId.toString(),
            data: {
              drawing: {
                ...omit(state, 'url'),
              },
              upload_request: { basename: file.name, content_type: file.type },
            },
          }
        )
        log.debug(resp.data)

        // upload drawing file to S3
        const formData = new FormData()
        Object.entries(resp.data.presigned_post.fields).forEach(([k, v]) =>
          formData.append(k, v)
        )
        formData.append('file', file)

        const resp2 = await Axios({
          method: 'post',
          url: resp.data.presigned_post.url,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        log.debug(resp2.data)

        const resp3 = await api.photospheresApi.photosphereTemplatesDrawingsConfirm(
          {
            id: resp.data.drawing.id!.toString(),
            templateId: templateId.toString(),
          }
        )
        refreshDrawings()
        log.debug(resp3.data)
        return resp3.data
      } else {
        const resp = await api.photospheresApi.photosphereTemplatesDrawingsUpdate(
          {
            id: state.id!.toString(),
            templateId: templateId.toString(),
            data: {
              ...omit(state, 'id', 'template', 'url'),
            },
          }
        )
        log.debug(resp.data)
        refreshDrawings()
      }
    },
    [file]
  )

  // loading spinner for image
  const imageUrl = visible ? drawingState.url || '' : ''
  const [loaded, setLoaded] = useState(false)
  const imageLoading = imageUrl && !loaded

  const [editNameMode, setEditNameMode] = useState(false)
  const [editingName, setEditingName] = useState(drawing.name)
  const onSaveName = () => {
    const newState = { ...drawingState, name: editingName }
    setDrawingState(newState)
    setEditNameMode(false)
    save(newState)
  }

  return (
    <>
      {visible && (
        <KRPanoHotspot
          {...omit(drawingState, 'name', 'sequence', 'alpha')}
          alpha={visible ? drawingState.alpha : 0}
          enabled={false}
          distorted={true}
          zoom={true}
          torigin={'image'}
          onloaded={() => setLoaded(true)}
          // preserves the hotspot when switching days
          keep={true}
        />
      )}
      <Grid item xs={12} className={classes.overlayHeader}>
        <div className={classes.overlayName}>
          {editNameMode ? (
            <ClickAwayListener onClickAway={() => setEditNameMode(false)}>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  onSaveName()
                }}
                onBlur={() => setEditNameMode(false)}
              >
                <TextField
                  className={classes.nameInput}
                  variant="outlined"
                  size="small"
                  autoFocus
                  value={editingName}
                  onChange={(e) => setEditingName(e.target.value)}
                  onBlur={() => setEditNameMode(false)}
                />
              </form>
            </ClickAwayListener>
          ) : (
            <Typography
              variant="body1"
              onDoubleClick={() => setEditNameMode(true)}
            >
              {drawingState.name}
            </Typography>
          )}
        </div>
        <IconButton
          size="small"
          onClick={(e) => {
            setVisible(!visible)
          }}
        >
          {imageLoading ? (
            <CircularProgress size={18} color="inherit" />
          ) : visible ? (
            <VisibilityIcon fontSize="inherit" />
          ) : (
            <VisibilityOffIcon fontSize="inherit" />
          )}
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            if (!editMode) {
              setVisible(true)
            }
            setEditMode(!editMode)
          }}
          color={editMode ? 'secondary' : 'default'}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          size="small"
          onClick={async (e) => {
            if (file && onDelete) {
              onDelete()
            } else {
              await confirm({
                title: 'Are you sure you want to delete this drawing?',
                description: 'This operation cannot be undone',
              })
              api.photospheresApi.photosphereTemplatesDrawingsDelete({
                id: drawing.id!.toString(),
                templateId: drawing.template!.toString(),
              })
            }
            refreshDrawings()
            log.debug(`Deleted drawing ${drawing.id}`)
          }}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      {editMode && (
        <>
          {sliderConfig.map((input) => (
            <OverlaySlider
              key={input.key}
              keyName={input.key}
              range={input.range}
              value={drawingState[input.key]}
              onChange={(value) =>
                setDrawingState({ ...drawingState, ...{ [input.key]: value } })
              }
            />
          ))}
          <Grid item xs={12}>
            <ProgressButton
              fetchState={saveState}
              onClick={() => save(drawingState)}
              fullWidth
            >
              Save
            </ProgressButton>
          </Grid>
        </>
      )}
      <Divider />
    </>
  )
}

const OverlaySlider = ({
  keyName,
  range,
  value,
  onChange,
}: {
  keyName: string
  range: [number, number]
  value?: number
  onChange: (value: number) => void
}) => {
  const classes = useStyles()

  return (
    <Grid
      item
      onDrag={(e) => console.log(e)}
      xs={12}
      className={classes.gridItem}
    >
      <label className={classes.label}>{keyName}</label>
      <input
        className={classes.number}
        type="number"
        value={value}
        min={range[0]}
        max={range[1]}
        onChange={(e) => {
          onChange(Number(e.target.value))
        }}
      />
      <input
        className={classes.range}
        type="range"
        min={range[0]}
        max={range[1]}
        step="0.01"
        value={value}
        onChange={(e) => {
          onChange(Number(e.target.value))
        }}
      />
    </Grid>
  )
}
