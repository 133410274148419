import { Grid } from '@material-ui/core'
import log from 'loglevel'
import React, { useEffect, useState } from 'react'
import { useAsyncRetry, useDrop } from 'react-use'
import {
  Photosphere,
  PhotosphereDrawing,
} from '../../api/codegen/typescript-axios'
import { useApi } from '../ApiContext'
import { defaultDrawing, PhotosphereOverlay } from './PhotosphereOverlay'

export const PhotosphereOverlays = ({
  currentSphere,
}: {
  currentSphere?: Photosphere
}) => {
  const api = useApi()

  const [newDrawingFile, setNewDrawingFile] = useState<File>()
  const [newDrawing, setNewDrawing] = useState<PhotosphereDrawing>()

  useEffect(() => {
    setNewDrawing(undefined)
    setNewDrawingFile(undefined)
  }, [currentSphere?.id])

  const {
    value: drawings,
    retry: refreshDrawings,
  } = useAsyncRetry(async () => {
    if (!currentSphere?.template) return
    const resp = await api.photospheresApi.photosphereTemplatesDrawingsList({
      templateId: currentSphere?.template.toString(),
    })
    setNewDrawing(undefined)
    setNewDrawingFile(undefined)
    log.debug('PhotosphereOverlays fetched: ', resp.data)
    return resp.data
  }, [currentSphere?.template])

  useDrop({
    onFiles: (files) => {
      const file = files[0]
      const url = window.URL.createObjectURL(file)
      setNewDrawingFile(file)
      setNewDrawing({
        ...defaultDrawing,
        name: file.name,
        url: url,
        template: currentSphere?.template,
      })
    },
    onUri: (uri) => console.log('uri', uri),
    onText: (text) => console.log('text', text),
  })

  return (
    <Grid container spacing={1}>
      {drawings &&
        drawings.map((drawing) => (
          <PhotosphereOverlay
            key={drawing.id}
            drawing={drawing}
            refreshDrawings={refreshDrawings}
          />
        ))}
      {!newDrawingFile && (
        <Grid item xs={12}>
          Drag and drop a jpg or png anywhere on the screen to add a drawing
          overlay
        </Grid>
      )}
      {newDrawing && (
        <PhotosphereOverlay
          key={newDrawingFile?.lastModified}
          drawing={newDrawing}
          file={newDrawingFile}
          onDelete={() => {
            setNewDrawing(undefined)
            setNewDrawingFile(undefined)
          }}
          refreshDrawings={refreshDrawings}
        />
      )}
    </Grid>
  )
}
