import MaterialTable from '@material-table/core'
import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { useMe } from '../hooks/useMe'
import { useProjects } from '../hooks/useProjects'
import { useApi } from './ApiContext'
import { ProgressButton } from './FormInputs/ProgressButton'
import { PageWrapper } from './PageWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    bold: {
      fontWeight: 'bold',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    resetButtons: {
      display: 'flex',
    },
  })
)

export const AccountPage = (props: {}) => {
  const api = useApi()
  const navigate = useNavigate()
  const classes = useStyles(props)

  const { data: me, refetch: refetchMe } = useMe()
  const { data: projects } = useProjects()

  const [changePassword, setChangePassword] = useState(false)
  const [passwordEmail, setPasswordEmail] = useState('')

  const [editMode, setEditMode] = useState(false)
  const [editInfo, setEditInfo] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
  })

  useEffect(() => {
    if (me && me.email) {
      setPasswordEmail(me.email)
      setEditInfo({
        first_name: me.first_name || '',
        last_name: me.last_name || '',
        phone_number: me.phone_number?.slice(2) || '',
      })
    }
  }, [me])

  const [fetchEditState, fetchEdit] = useAsyncFn(async () => {
    const resp = await api.usersApi.usersMeUpdate({
      data: { ...editInfo, phone_number: '+1' + editInfo.phone_number },
    })
    await refetchMe()
    setEditMode(false)
    return resp.data
  }, [editInfo])

  const [fetchResetState, fetchReset] = useAsyncFn(async () => {
    const response = await api.resetPassword(passwordEmail)
    if (response.status === 204) {
      setChangePassword(false)
    }
    return response
  }, [passwordEmail])

  if (!me) {
    return null
  }

  return (
    <PageWrapper>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Account Management</Typography>
          </Grid>
          <Grid item xs={12}>
            <table>
              <tbody>
                {me.username ? (
                  <tr>
                    <td className={classes.bold}>Username</td>
                    <td>{me.username}</td>
                  </tr>
                ) : null}
                <tr>
                  <td className={classes.bold}>Email</td>
                  <td>{me.email}</td>
                </tr>
                <tr>
                  <td className={classes.bold}>First name</td>
                  <td>{me.first_name}</td>
                </tr>
                <tr>
                  <td className={classes.bold}>Last name</td>
                  <td>{me.last_name}</td>
                </tr>
                <tr>
                  <td className={classes.bold}>Phone number</td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      value={me.phone_number?.slice(2) || ''}
                      format="+1 (###) ###-####"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12}>
            {editMode ? (
              <Card>
                <CardContent>
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="First Name"
                          value={editInfo.first_name}
                          onChange={(e) =>
                            setEditInfo({
                              ...editInfo,
                              first_name: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Last Name"
                          value={editInfo.last_name}
                          onChange={(e) =>
                            setEditInfo({
                              ...editInfo,
                              last_name: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Phone number"
                          value={editInfo.phone_number}
                          onChange={(e) =>
                            setEditInfo({
                              ...editInfo,
                              phone_number: e.target.value,
                            })
                          }
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.resetButtons}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => setEditMode(false)}
                        >
                          Cancel
                        </Button>
                        <ProgressButton
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={fetchEdit}
                          fetchState={fetchEditState}
                        >
                          Update info
                        </ProgressButton>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            ) : (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit info
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {changePassword ? (
              <Card>
                <CardContent>
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Email"
                          value={passwordEmail}
                          onChange={(e) => setPasswordEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.resetButtons}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => setChangePassword(false)}
                        >
                          Cancel
                        </Button>
                        <ProgressButton
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={fetchReset}
                          disabled={!passwordEmail}
                          fetchState={fetchResetState}
                        >
                          Confirm Reset
                        </ProgressButton>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            ) : fetchResetState.value ? (
              <Card>
                <CardContent>
                  Thank you. A password reset email has been sent to{' '}
                  {passwordEmail}. Check your spam folder if you don't receive
                  it within ten minutes.
                </CardContent>
              </Card>
            ) : (
              <Button
                variant="contained"
                onClick={() => setChangePassword(true)}
              >
                Change Password
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {projects && (
              <MaterialTable
                title="Your roles"
                options={{
                  pageSize: Math.min(projects.length, 20),
                }}
                columns={[
                  { title: 'Project', field: 'name' },
                  {
                    title: 'Role',
                    field: 'user_attrs.role',
                    cellStyle: { textTransform: 'capitalize' },
                  },
                ]}
                data={projects}
                actions={[
                  (rowData) => ({
                    icon: 'settings',
                    tooltip: 'Manage Project',
                    onClick: () => navigate(`/${rowData.slug}/manageusers`),
                    disabled: rowData.user_attrs.role === 'viewer',
                  }),
                ]}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </PageWrapper>
  )
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format="+1 (###) ###-####"
      mask="_"
    />
  )
}
