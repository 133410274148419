import {
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { MdPrint } from 'react-icons/md'
import { useMeasure } from 'react-use'
import { Api } from '../../../api/Api'
import { useMediaClasses } from '../../../styles/mediaClasses'
import { Loading } from '../../Loading'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { CraneHistoryPanel } from './CraneHistoryPanel'
import { useCraneStore } from './CranePicksController'
import { CraneReportsPanelEmptiness } from './CraneReportsPanelEmptiness'
import { CraneReportsPanelSubcontractors } from './CraneReportsPanelSubcontractors'
import { CraneReportsPanelUtilization } from './CraneReportsPanelUtilization'
import { CraneReportsSubcontractorsPie } from './CraneReportsSubcontractorsPie'
import { CraneReportsUtilizationPie } from './CraneReportsUtilizationPie'
import { useSubcontractorStats } from './useSubcontractorStats'
import { t } from 'i18next'

export const CraneReportsPanel = () => {
  const theme = useTheme()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()
  const project = useProject()
  const mediaClasses = useMediaClasses()

  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const twoColumn = width > 750

  const date = useVODStore((state) => state.videoDate)

  const selectedCrane = craneStore((state) => state.selectedCrane)
  const days = craneStore((state) => state.days)
  const picks = craneStore((state) => state.picks)
  const subcontractors = craneStore((state) => state.subcontractors)
  const loading = craneStore((state) => state.loading)
  const projectConfig = craneStore((state) => state.projectConfig)
  const sumPicks = craneStore((state) => state.sumPicks)

  const currentDayData = days.get(date.toISODate())

  const subcontractorStats = useSubcontractorStats(
    picks,
    Array.from(subcontractors.values()),
    sumPicks
  )

  return (
    <div
      style={{
        padding: theme.spacing(1),
        overflowX: 'hidden',
      }}
      onKeyDown={(e) => e.stopPropagation()}
      ref={ref}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} alignItems="baseline" container spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Daily Crane Report{' '}
              {project.cranes.length > 1 && selectedCrane && (
                <span> - {selectedCrane.name}</span>
              )}
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
              {`${date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`}{' '}
              {!currentDayData || !picks ? null : (
                <span>
                  {picks.length &&
                    `, ${picks[0].startDateTime.toLocaleString(
                      DateTime.TIME_SIMPLE
                    )} -
              ${picks[picks.length - 1].endDateTime.toLocaleString(
                DateTime.TIME_SIMPLE
              )}`}{' '}
                  <Button
                    variant="contained"
                    size="small"
                    className={mediaClasses.hideForPrint}
                    startIcon={<MdPrint />}
                    onClick={() => {
                      const printWindow = window.open(
                        `${window.location.origin}${process.env.REACT_APP_BASE_HREF}${project.slug}/crane-report-daily-print${window.location.search}&theme=light&print=1`,
                        'topdeck-print-window'
                        // `width=800,height=800,`
                      )
                      // if (printWindow) {
                      //   const printListener = () => {
                      //     printWindow.removeEventListener(
                      //       Api.stabilityEventType,
                      //       printListener
                      //     )
                      //     printWindow.addEventListener('afterprint', () =>
                      //       printWindow.close()
                      //     )
                      //     printWindow?.print()
                      //   }
                      //   printWindow.addEventListener(
                      //     Api.stabilityEventType,
                      //     printListener
                      //   )
                      // }
                    }}
                  >
                    {t('daily')}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    className={mediaClasses.hideForPrint}
                    style={{ marginLeft: theme.spacing(1) }}
                    startIcon={<MdPrint />}
                    onClick={() => {
                      const printWindow = window.open(
                        `${window.location.origin}${process.env.REACT_APP_BASE_HREF}${project.slug}/crane-report-weekly-print${window.location.search}&theme=light&print=1`,
                        'topdeck-print-window'
                      )
                    }}
                  >
                    {t('weekly')}
                  </Button>
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>

        {loading ? (
          <Loading text={'Loading data'} />
        ) : !currentDayData || !picks ? (
          <Grid item xs={12}>
            <Typography variant="body1">
              No crane activity recorded on this day.
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <CraneReportsUtilizationPie />
            </Grid>
            <Grid item xs={6}>
              <CraneReportsSubcontractorsPie
                subcontractorStats={subcontractorStats}
              />
            </Grid>
            <Grid item xs={twoColumn ? 6 : 12}>
              <CraneReportsPanelUtilization />
            </Grid>

            {projectConfig?.show_empty_stats && (
              <Grid item xs={twoColumn ? 6 : 12}>
                <CraneReportsPanelEmptiness />
              </Grid>
            )}

            <Grid item xs={twoColumn ? 6 : 12}>
              <CraneReportsPanelSubcontractors
                key={`subcontractors-${date.toISODate()}`}
                subcontractorStats={subcontractorStats}
              />
            </Grid>

            <Grid item xs={12} className={mediaClasses.hideForPrint}>
              <CraneHistoryPanel key={`history-${date.toISODate()}`} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}
