import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { getUseVODStore } from './VODController'

export const VODDateTimeTextInput = ({
  label,
  value,
  onChange,
  nullable,
}: {
  label: string
  value?: DateTime | null
  onChange: (d: DateTime | null) => void
  nullable?: boolean
}) => {
  const useVODStore = getUseVODStore()

  const [textValue, setTextValue] = useState<string>('')
  const [valid, setValidity] = useState<boolean>(false)
  const [valueInFuture, setValueFuture] = useState(false)

  useEffect(() => {
    if (value) {
      setTextValue(
        value.startOf('second').toISO({ suppressMilliseconds: true })
      )
    } else {
      setTextValue('')
    }
  }, [value])

  const changeLocal = useCallback((text: string) => {
    try {
      const dateTime = DateTime.fromISO(text)
      onChange(dateTime)
      setValidity(true)
    } catch {
      setTextValue(text)
      setValidity(false)
    }
  }, [])

  useEffect(
    () =>
      useVODStore.subscribe<DateTime>(
        (videoDateTime) => {
          if (!videoDateTime || !value) return
          if (valueInFuture && value < videoDateTime) {
            setValueFuture(false)
          } else if (!valueInFuture && value > videoDateTime) {
            setValueFuture(true)
          }
        },
        (state) => state.videoDateTime
      ),
    [value, valueInFuture]
  )

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel variant="outlined" margin="dense">
          {label}
        </InputLabel>
        <OutlinedInput
          type={'text'}
          label={label}
          value={textValue}
          onChange={(e) => {
            changeLocal(e.target.value)
          }}
          style={{ borderColor: valid ? '#0f0' : '#f00' }}
          margin="dense"
          fullWidth
          disabled
          endAdornment={
            <>
              {value && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    title="Seek video to time"
                    aria-label="Seek video to time"
                    onClick={() => {
                      useVODStore.getState().gotoVideo({
                        dateTime: value,
                      })
                    }}
                    edge="end"
                  >
                    {valueInFuture ? <SkipNextIcon /> : <SkipPreviousIcon />}
                  </IconButton>
                </InputAdornment>
              )}

              <InputAdornment position="end">
                <IconButton
                  size="small"
                  title="Copy clock time to input"
                  aria-label="Copy clock time to input"
                  onClick={() => {
                    onChange(
                      useVODStore.getState().videoDateTime.startOf('second')
                    )
                  }}
                  edge="end"
                >
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>

              {nullable && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    title="Delete time"
                    aria-label="Delete time"
                    onClick={() => {
                      onChange(null)
                    }}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          }
          labelWidth={70}
        />
      </FormControl>
    </Grid>
  )
}
