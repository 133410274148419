import {
  DrawCreateEvent,
  DrawMode,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw'
import { GridInputSelectionModel, GridSelectionModel } from '@mui/x-data-grid'
import bbox from '@turf/bbox'
import { Feature, LineString } from '@turf/helpers'
import produce from 'immer'
import { LngLatBounds } from 'mapbox-gl'
import React, { FC, useState } from 'react'
import zustand, { UseStore } from 'zustand'
import { createCtx } from '../../../helpers/createCtx'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'

const STORAGE_KEY = 'deck-verification-prototype-data-v1'

export interface DeckFeature extends Feature<LineString, { name: string }> {}

export type StoreState = {
  deckFeatures: Map<string, DeckFeature>
  selectionModel: GridInputSelectionModel
  setSelectionModel: (model: GridSelectionModel) => void
  setFeatureName: (id: string, name: string) => void
  zoomToFeature: (id: string) => void
  deleteFeature: (id: string) => void
  draw: MapboxDraw | undefined
  drawMode: DrawMode
  changeMode: (mode: DrawMode) => void
  drawUpdate: (e: DrawCreateEvent | DrawUpdateEvent) => void
  bounds: LngLatBounds | undefined
  saveToStorage: () => void
}

export type UseDeckVerificationStore = UseStore<StoreState>

export const [
  useDeckVerificationStore,
  DeckVerificationContext,
] = createCtx<UseDeckVerificationStore>()

export const DeckVerificationController: FC = ({ children }) => {
  const api = useApi()
  const project = useProject()

  const [useStore] = useState(() => {
    return zustand<StoreState>((set, get) => ({
      deckFeatures: new Map(),
      selectionModel: [],
      // handle data grid onSelectionModelChange
      setSelectionModel: (model) => {
        set({ selectionModel: model })
        get().draw?.changeMode('simple_select', {
          featureIds: model as string[],
        })
      },
      // handle data grid name change
      setFeatureName: (id: string, name: string) => {
        get().draw?.setFeatureProperty(id, 'name', name)
        set(
          produce((state) => {
            state.deckFeatures.get(id).properties.name = name
          })
        )
      },
      deleteFeature: (id: string) => {
        get().draw?.delete(id)
        set(
          produce((state) => {
            state.deckFeatures.delete(id)
          })
        )
      },
      zoomToFeature: (id: string) => {
        const feature = get().deckFeatures.get(id)
        if (feature) {
          const targetBbox = bbox(feature)
          const bounds = new LngLatBounds(
            [targetBbox[0], targetBbox[1]],
            [targetBbox[2], targetBbox[3]]
          )
          set({ bounds })
        }
      },
      draw: undefined,
      drawMode: 'simple_select',
      // handle control panel change mode buttons
      changeMode: (mode) => {
        switch (mode) {
          case 'simple_select':
            set({ drawMode: mode })
            get().draw?.changeMode(mode)
            break
          case 'direct_select':
            set({ drawMode: mode })
            get().draw?.changeMode(mode, { featureId: 'test' })
            break
          case 'draw_line_string':
            set({ drawMode: mode })
            get().draw?.changeMode(mode)
            break
        }
      },
      drawUpdate: (e: DrawCreateEvent | DrawUpdateEvent) => {
        set(
          produce((state) => {
            console.log('map onUpdate', e)
            const feature = e.features[0]
            if (feature && feature.geometry.type === 'LineString') {
              state.deckFeatures.set(feature.id, {
                ...feature,
                properties: {
                  ...feature.properties,
                  name: feature.properties?.name || feature.id,
                },
              })
            }
          })
        )
      },
      bounds: undefined,
      loadFromStorage: () => {
        const data = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
        if (data) {
          get().draw?.add(data)
        }
      },
      saveToStorage: () => {
        const data = get().draw?.getAll()
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
      },
    }))
  })

  const draw = useStore((state) => state.draw)

  React.useEffect(() => {
    if (draw) {
      const dataStr = localStorage.getItem(STORAGE_KEY)
      if (dataStr) {
        try {
          const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = JSON.parse(
            dataStr
          )
          draw.add(data)
          useStore.setState(
            produce((state) => {
              state.deckFeatures = new Map(data.features.map((f) => [f.id, f]))
            })
          )
        } catch {
          throw new Error('Could not parse data from local storage')
        }
      }
    }
  }, [draw])

  return (
    <DeckVerificationContext.Provider value={useStore}>
      {children}
    </DeckVerificationContext.Provider>
  )
}
