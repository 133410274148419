import mapboxgl from 'mapbox-gl'
import React from 'react'
import { Layer, Source } from 'react-map-gl'

const heatmapStyle: mapboxgl.HeatmapPaint = {
  // Increase the heatmap weight based on frequency and property magnitude
  'heatmap-weight': 1,
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(0, 0, 255, 0)',
    0.1,
    'royalblue',
    0.3,
    'cyan',
    0.5,
    'lime',
    0.7,
    'yellow',
    1,
    'red',
  ],
  'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 15, 1, 20, 50],
}

export const CranePickHeatmap = React.memo(
  ({
    data,
    pickPhase,
  }: {
    data: GeoJSON.FeatureCollection<GeoJSON.Geometry>
    pickPhase: 'load' | 'unload'
  }) => {
    return (
      <Source type="geojson" data={data} id={'crane-picks-heatmap-source'}>
        <Layer
          id="heatmap"
          type="heatmap"
          paint={heatmapStyle}
          filter={['match', ['get', 'type'], [pickPhase], true, false]}
        />
      </Source>
    )
  }
)
