import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Settings } from 'luxon'
import { FC } from 'react'
import { initReactI18next } from 'react-i18next'
import en from '../../translations/en.json'
import fr from '../../translations/fr.json'

const resources = {
  en,
  fr,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    // lng: 'en',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const LanguageController: FC = ({ children }) => {
  Settings.defaultLocale = i18n.language
  return <>{children}</>
}
