import React from 'react'
import { useMobile } from '../../hooks/useMobile'
import { LiveVideosPageDesktop } from './LiveVideosPageDesktop'
import { LiveVideosPageMobile } from './LiveVideosPageMobile'

export const LiveVideosPage = () => {
  if (useMobile()) {
    return <LiveVideosPageMobile />
  } else {
    return <LiveVideosPageDesktop />
  }
}
