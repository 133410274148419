import {
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { union, without } from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import zustand from 'zustand'
import { PageWrapper } from './PageWrapper'
import { useProject } from './ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '400px',
      margin: '0 auto',
      [theme.breakpoints.up('lg')]: {
        maxWidth: 'none',
      },
    },
  })
)

interface Service {
  name: string
  description: string
}

const services: Service[] = [
  { name: 'Aerial Photography', description: 'description' },
  { name: 'Aerial Videography', description: 'description' },
  { name: 'Aerial Mapping/Orthomosaic', description: 'description' },
  { name: '4K HD Hyperlapse', description: 'description' },
  {
    name: '4K HD Timelapse',
    description:
      'description description description description description description description description description',
  },
  {
    name: 'Video Production Services (Editing, Sound, Color)',
    description: '',
  },
]

const useCases = [
  'Productivity and Inspection',
  'Internal Promotional Material',
  'Marketing and Social Media',
] as const
type UseCases = typeof useCases
type UseCase = UseCases[number]

const flightConditions = [
  'No preference, fly in clear, overcast, or cloudy skies',
  'Fly in optimal lighting conditions, clear to partly cloudy skies',
] as const
type FlightConditions = typeof flightConditions
type FlightCondition = FlightConditions[number]

const schedules = ['As soon as possible', 'Specify date and time'] as const
type Schedules = typeof schedules
type Schedule = Schedules[number]

export const VideoRequestPage = () => {
  const classes = useStyles()
  const project = useProject()

  const [useStore] = useState(() =>
    zustand<{
      activeStep: number
      selectedServices: Service[]
      useCase?: UseCase
      flightCondition?: FlightCondition
      schedule?: Schedule
      flightDateTime?: DateTime
      stream?: string
      timelapseStartDateTime?: DateTime
      timelapseEndDateTime?: DateTime
      contactName?: string
      contactPhone?: string
      specialInstructions?: string
    }>((set) => ({
      activeStep: 0,
      selectedServices: [],
    }))
  )

  const {
    activeStep,
    selectedServices,
    useCase,
    schedule,
    flightCondition,
    flightDateTime,
    stream,
    timelapseStartDateTime,
    timelapseEndDateTime,
    specialInstructions,
    contactName,
    contactPhone,
  } = useStore()

  const hasDroneService = !!selectedServices?.find(
    (s) => s === services[0] || services[1] || services[2] || services[3]
  )
  const hasTimelapseService = !!selectedServices?.find((s) => s === services[4])

  const steps = ['Services', 'Scheduling', 'Confirmation']

  return (
    <PageWrapper style={{ paddingBottom: 60 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ marginBottom: 20 }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step 0 */}
      {activeStep === 0 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <Typography variant="h5" gutterBottom>
                Services
              </Typography>

              {services.map((o) => (
                <FormControlLabel
                  key={o.name}
                  style={{ alignItems: 'flex-start', marginBottom: 20 }}
                  control={
                    <Checkbox
                      name={o.name}
                      checked={selectedServices.indexOf(o) > -1}
                      onChange={(e, v) => {
                        if (e.target.checked) {
                          useStore.setState({
                            selectedServices: union(selectedServices, [o]),
                          })
                        } else {
                          useStore.setState({
                            selectedServices: without(selectedServices, o),
                          })
                        }
                      }}
                      style={{ paddingTop: 0 }}
                    />
                  }
                  label={
                    <label>
                      <Typography variant="body1">{o.name}</Typography>
                      <Typography variant="body2">{o.description}</Typography>
                    </label>
                  }
                />
              ))}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <Typography variant="h5" gutterBottom>
                Use Case
              </Typography>
              <RadioGroup
                aria-label="use case"
                name="use case"
                value={useCase || ''}
                onChange={(e, v) =>
                  useStore.setState({ useCase: v as UseCase })
                }
              >
                {useCases.map((o) => (
                  <FormControlLabel
                    key={o}
                    value={o}
                    control={<Radio />}
                    label={o}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => useStore.setState({ activeStep: 1 })}
              disabled={selectedServices.length === 0 || !useCase}
            >
              Scheduling
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Step 1 */}
      {activeStep === 1 && (
        <Grid container spacing={2} justifyContent="center">
          {hasDroneService && (
            <>
              <Grid item xs={12} md={7}>
                <Typography variant="h5">Flight scheduling</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Flight Conditions</FormLabel>
                  <RadioGroup
                    aria-label="Flight Conditions"
                    name="Flight Conditions"
                    value={flightCondition || ''}
                    onChange={(e, v) =>
                      useStore.setState({
                        flightCondition: v as FlightCondition,
                      })
                    }
                  >
                    {flightConditions.map((o) => (
                      <FormControlLabel
                        key={o}
                        value={o}
                        control={<Radio />}
                        label={o}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={7}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Schedule</FormLabel>
                  <RadioGroup
                    aria-label="Schedule"
                    name="Schedule"
                    value={schedule || ''}
                    onChange={(e, v) =>
                      useStore.setState({ schedule: v as Schedule })
                    }
                  >
                    {schedules.map((o) => (
                      <FormControlLabel
                        key={o}
                        value={o}
                        control={<Radio />}
                        label={o}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              {schedule === 'Specify date and time' && (
                <Grid item xs={12} md={7}>
                  <DateTimePicker
                    value={flightDateTime?.toJSDate()}
                    onChange={(d) =>
                      useStore.setState({
                        flightDateTime: DateTime.fromJSDate(d as Date),
                      })
                    }
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant={'outlined'}
                        helperText=""
                      />
                    )}
                  />
                </Grid>
              )}
              <Divider />
            </>
          )}

          {hasTimelapseService && (
            <>
              <Grid item xs={12} md={7} style={{ marginTop: 40 }}>
                <Typography variant="h5">Timelapse scheduling</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Stream</FormLabel>
                  <RadioGroup
                    aria-label="Stream"
                    name="Stream"
                    value={stream || ''}
                    onChange={(e, v) => useStore.setState({ stream: v })}
                  >
                    {project.streams.map((o) => (
                      <FormControlLabel
                        key={o.id}
                        value={o.name}
                        control={<Radio />}
                        label={o.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={7}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Start Date</FormLabel>
                  <DatePicker
                    value={timelapseStartDateTime?.toJSDate()}
                    onChange={(d) =>
                      useStore.setState({
                        timelapseStartDateTime: DateTime.fromJSDate(d as Date),
                      })
                    }
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant={'outlined'}
                        helperText=""
                        style={{ marginRight: 20 }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">End Date</FormLabel>
                  <DatePicker
                    value={timelapseEndDateTime?.toJSDate()}
                    onChange={(d) =>
                      useStore.setState({
                        timelapseEndDateTime: DateTime.fromJSDate(d as Date),
                      })
                    }
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant={'outlined'}
                        helperText=""
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={7} style={{ marginTop: 40 }}>
            <Typography variant="h5">Contact Info</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Contact name</FormLabel>
              <TextField
                variant="filled"
                value={contactName}
                onChange={(e) =>
                  useStore.setState({
                    contactName: e.target.value,
                  })
                }
                style={{ minWidth: 320 }}
                rows={10}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Contact phone</FormLabel>
              <TextField
                variant="filled"
                value={contactPhone}
                onChange={(e) =>
                  useStore.setState({
                    contactPhone: e.target.value,
                  })
                }
                style={{ minWidth: 320 }}
                rows={10}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Special instructions</FormLabel>
              <textarea
                value={specialInstructions}
                onChange={(e) =>
                  useStore.setState({
                    specialInstructions: e.target.value,
                  })
                }
                style={{ minWidth: 320 }}
                rows={10}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <Button
              variant="contained"
              color="default"
              onClick={() => useStore.setState({ activeStep: 0 })}
              style={{ marginRight: 20 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => useStore.setState({ activeStep: 2 })}
            >
              Complete
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Step 2 */}
      {activeStep === 2 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10} md={6}>
            <Typography variant="h5" gutterBottom>
              Order completed
            </Typography>

            <Typography variant="body1" gutterBottom>
              Your services requested has been submitted and a confirmation
              email with the name of your Pilot and flight summary will be sent
              shortly.
            </Typography>

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="right" style={{ verticalAlign: 'top' }}>
                    Services:
                  </TableCell>
                  <TableCell>
                    {selectedServices.map((s) => (
                      <div>{s.name}</div>
                    ))}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="right">Use Case:</TableCell>
                  <TableCell>{useCase}</TableCell>
                </TableRow>

                {schedule === 'As soon as possible' && (
                  <TableRow>
                    <TableCell align="right">Schedule:</TableCell>
                    <TableCell>{schedule}</TableCell>
                  </TableRow>
                )}

                {hasDroneService && flightDateTime && (
                  <TableRow>
                    <TableCell align="right">Flight date:</TableCell>
                    <TableCell>{flightDateTime.toFormat('fff')}</TableCell>
                  </TableRow>
                )}

                {hasTimelapseService && stream && (
                  <TableRow>
                    <TableCell align="right">Stream:</TableCell>
                    <TableCell>{stream}</TableCell>
                  </TableRow>
                )}

                {hasTimelapseService && timelapseStartDateTime && (
                  <TableRow>
                    <TableCell align="right">Start date:</TableCell>
                    <TableCell>
                      {timelapseStartDateTime.toFormat('fff')}
                    </TableCell>
                  </TableRow>
                )}

                {hasTimelapseService && timelapseEndDateTime && (
                  <TableRow>
                    <TableCell align="right">End date:</TableCell>
                    <TableCell>
                      {timelapseEndDateTime.toFormat('fff')}
                    </TableCell>
                  </TableRow>
                )}

                {contactName && (
                  <TableRow>
                    <TableCell align="right">Contact name:</TableCell>
                    <TableCell>{contactName}</TableCell>
                  </TableRow>
                )}

                {contactPhone && (
                  <TableRow>
                    <TableCell align="right">Contact phone:</TableCell>
                    <TableCell>{contactPhone}</TableCell>
                  </TableRow>
                )}

                {specialInstructions && (
                  <TableRow>
                    <TableCell align="right" style={{ verticalAlign: 'top' }}>
                      Special instructions:
                    </TableCell>
                    <TableCell>{specialInstructions}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <Typography variant="h6" gutterBottom style={{ marginTop: '2em' }}>
              Thank you!
            </Typography>
          </Grid>
        </Grid>
      )}
    </PageWrapper>
  )
}
