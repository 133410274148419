import { DateTime } from 'luxon'
import { ProjectDetails } from '../api/codegen/typescript-axios'

export function checkTimeZone(
  project: ProjectDetails,
  dateTime?: DateTime | null
) {
  if (dateTime && dateTime.zoneName !== project.timezone) {
    console.error('checkTimeZone failed')
    console.log(dateTime.zoneName, project.timezone)
  }
}
