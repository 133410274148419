import React from 'react'
import { Layer, Source } from 'react-map-gl'
import { pickLinesSourceId } from './CranePicksMap'

export const CranePickLines = React.memo(
  ({ data }: { data: GeoJSON.FeatureCollection<GeoJSON.Geometry> }) => {
    return (
      <Source type="geojson" data={data} id={pickLinesSourceId}>
        <Layer
          {...{
            layout: {},
            filter: ['match', ['get', 'type'], ['travel'], true, false],
            type: 'line',
            id: 'travel',
            paint: {
              'line-width': 4,
              'line-color': ['get', 'color'],
              'line-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.3,
              ],
            },
          }}
        />
        <Layer
          {...{
            layout: {},
            filter: ['match', ['get', 'type'], ['load'], true, false],
            type: 'circle',
            id: 'load',
            paint: {
              'circle-radius': ['get', 'duration'],
              'circle-color': 'transparent',
              'circle-stroke-color': ['get', 'color'],
              'circle-stroke-width': 2,
              'circle-stroke-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.3,
              ],
            },
          }}
        />
        <Layer
          {...{
            layout: {},
            filter: ['match', ['get', 'type'], ['unload'], true, false],
            type: 'circle',
            id: 'unload',
            paint: {
              'circle-radius': ['get', 'duration'],
              'circle-color': ['get', 'color'],
              'circle-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.3,
              ],
              'circle-stroke-color': ['get', 'color'],
              'circle-stroke-width': 2,
              'circle-stroke-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.3,
              ],
            },
          }}
        />
      </Source>
    )
  }
)
