import { ButtonGroup, createStyles, makeStyles } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import { animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import clsx from 'clsx'
import { scaleTime, ScaleTime } from 'd3-scale'
import { zoomIdentity } from 'd3-zoom'
import { throttle } from 'lodash'
import log from 'loglevel'
import { DateTime, DurationUnit } from 'luxon'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMeasure, usePrevious } from 'react-use'
import { useMergeRefs } from 'use-callback-ref'
import zustand, { UseStore } from 'zustand'
import { persist } from 'zustand/middleware'
import shallow from 'zustand/shallow'
import { dateFormat } from '../../../helpers/constants'
import { createCtx } from '../../../helpers/createCtx'
import { getContainTransformMat2d } from '../../../helpers/getContainTransform'
import { useProject } from '../../ProjectWrapper'
import { KeyedButton } from '../../Video/KeyedButton'
import { transformers, useZoomPan } from '../useZoomPan'
import { getUseVODStore } from '../VODController'
import { TimelineCanvas } from './TimelineCanvas'
import { TimelinePlayheads } from './TimelinePlayheads'
import { TimelineSVG } from './TimelineSVG'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      },
      controls: {
        padding: '6px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
          marginLeft: 10,
        },
      },
      button: {
        color: '#fff',
        border: '1px solid',
        borderRadius: 3,
        outline: 'none',
        lineHeight: 1.5,
        cursor: 'pointer',
        marginLeft: 6,
      },
      canvases: {
        width: '100%',
        height: '0',
        flexGrow: 1,
        position: 'relative',
        overflow: 'hidden',
        touchAction: 'none',
      },
      leftColumnResizeHandle: {
        position: 'absolute',
        top: 0,
        left: -4,
        width: 9,
        height: '100%',
        cursor: 'col-resize',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 4,
          width: 1,
          height: '100%',
          backgroundColor: theme.palette.divider,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 9,
          height: '100%',
          backgroundColor: theme.palette.secondary.main,
          transition: 'opacity 0.3s',
          opacity: 0,
        },
        '&:hover': {
          '&:after': {
            opacity: 0.7,
          },
        },
      },
    }),
  {
    name: 'Timeline',
  }
)

export type UseTimelineStore = UseStore<TimelineState>

export const [
  useUseTimelineStore,
  TimelineStoreContext,
] = createCtx<UseTimelineStore>()

const durations: DurationUnit[] = ['week', 'day', 'hour', 'minute']

const rowHeight = 18

export type TimelineState = {
  originalScale: ScaleTime<number, number>
  currentScale: ScaleTime<number, number>
  width: number
  height: number
  leftColumnWidth: number
  rowHeight: number
  sectionHeights: number[]
  getRowGroupY: (getRowGroupIndex: number) => number
  videoDateTime: DateTime
  mouseDateTime: DateTime | undefined
  followPlayhead: boolean
  toggleFollowPlayhead: () => void
  filterByTime: boolean
  scrollY: number
}

export const Timeline: React.FC<{
  className?: string
  initialLeftColumnWidth?: number
  initialZoom?: DurationUnit
  showFollowPlayheadButton?: boolean
  showFilterButton?: boolean
}> = ({
  className,
  initialLeftColumnWidth = 250,
  initialZoom = 'week',
  showFollowPlayheadButton = true,
  showFilterButton = false,
  children,
}) => {
  const classes = useStyles()
  const project = useProject()
  const useVODStore = getUseVODStore()

  function getDomainMax() {
    return DateTime.now()
      .setZone(project.timezone)
      .setZone('system', { keepLocalTime: true })
      .endOf('minute')
      .toJSDate()
  }

  const [useTimelineStore] = useState(() =>
    zustand<TimelineState>(
      persist(
        (set, get) => {
          const firstDate = project.videoArchiveDates[0]
          const originalScale = scaleTime().domain([
            DateTime.fromFormat(
              firstDate?.dateString ||
                DateTime.now().setZone(project.timezone).toISODate(),
              dateFormat
            ).toJSDate(),
            getDomainMax(),
          ])

          return {
            originalScale: originalScale,
            currentScale: originalScale,
            width: 1,
            height: 1,
            leftColumnWidth: initialLeftColumnWidth,
            rowHeight: 18,
            sectionHeights: [],
            getRowGroupY: (rowGroupIndex: number) => {
              const sectionHeights = get().sectionHeights
              let rowGroupY = 0
              for (
                let index = 0;
                index < Math.min(sectionHeights.length, rowGroupIndex);
                index++
              ) {
                rowGroupY = rowGroupY + sectionHeights[index]
              }
              return rowGroupY
            },
            videoDateTime: useVODStore
              .getState()
              .videoDateTime.setZone('local', { keepLocalTime: true }),
            mouseDateTime: undefined,
            followPlayhead: false,
            toggleFollowPlayhead: () => {
              set((state) => ({ followPlayhead: !state.followPlayhead }))
            },
            filterByTime: false,
            scrollY: 0,
          }
        },
        {
          name: 'timeline-store',
          whitelist: ['followPlayhead', 'filterByTime'],
          // partialize: (state) => ({ followPlayhead: state.followPlayhead, filterByTime: state.filterByTime })
        }
      )
    )
  )

  React.useEffect(() => {
    if (!showFollowPlayheadButton) {
      useTimelineStore.setState({ followPlayhead: false })
    }
    if (!showFilterButton) {
      useTimelineStore.setState({ filterByTime: false })
    }
  }, [showFollowPlayheadButton, showFilterButton])

  const throttledUpdateScale = throttle(function timelineUpdateScale() {
    // Advance leading edge of scale domain on hour change
    const originalScale = useTimelineStore.getState().originalScale

    const newDomainMax = getDomainMax()

    const scaleEnd = originalScale.domain()[1]
    if (newDomainMax > scaleEnd) {
      log.debug('update scale leading edge')
      originalScale.domain([originalScale.domain()[0], newDomainMax])
    }
  }, 1000 * 60)

  // Subscribe to master clock
  useEffect(
    () =>
      useVODStore.subscribe<DateTime>(
        function timelineUpdateFromVideoClock() {
          throttledUpdateScale()

          // update local clock, adjusted to local/system timezone,
          // Because D3 time scale can only be in local timezone
          useTimelineStore.setState({
            videoDateTime: useVODStore
              .getState()
              .videoDateTime.setZone('local', { keepLocalTime: true }),
          })
        },
        (state) => state.videoDateTime
      ),
    [useTimelineStore, useVODStore, throttledUpdateScale]
  )

  // drag handler for left column width
  const bind = useDrag(
    ({ event, offset }) => {
      event.stopPropagation()
      useTimelineStore.setState({
        leftColumnWidth: Math.max(initialLeftColumnWidth + offset[0], 90),
      })
    },
    {
      eventOptions: { capture: true },
    }
  )

  // for use in jsx
  const [followPlayhead, toggleFollowPlayhead, filterByTime] = useTimelineStore(
    (state) => [
      state.followPlayhead,
      state.toggleFollowPlayhead,
      state.filterByTime,
    ],
    shallow
  )

  const ref = React.useRef<HTMLDivElement>(null)

  // Keep tabs on container size
  const [measurementRef, measurements] = useMeasure<HTMLDivElement>()

  // this is a zoom/pan thing. check D3 zoom docs for explainer
  const translateExtentRef = useRef<[[number, number], [number, number]]>([
    [0, 0],
    [measurements.width, Infinity],
  ])

  const sectionHeights = useTimelineStore((state) => state.sectionHeights)
  React.useEffect(() => {
    translateExtentRef.current[1][0] = measurements.width

    const totalHeight = sectionHeights.reduce((previous, current) => {
      return previous + current
    }, 0)

    translateExtentRef.current[1][1] = Math.max(
      totalHeight,
      measurements.height
    )
  }, [sectionHeights, measurements])

  // adjust scale as left column width is changed and total width is changed
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)
  useEffect(() => {
    useTimelineStore
      .getState()
      .originalScale.range([leftColumnWidth, measurements.width])
    useTimelineStore
      .getState()
      .currentScale.range([leftColumnWidth, measurements.width])

    useTimelineStore.setState({
      width: measurements.width,
      height: measurements.height,
    })
  }, [measurements, leftColumnWidth])

  const { transformRef, mousePositionRef, setTransform } = useZoomPan({
    target: ref,
    scaleExtent: useRef([1, Infinity]),
    translateExtent: translateExtentRef,
    gestureConfig: {
      wheelX: transformers.panX,
      wheelY: transformers.zoomX,
      altWheelX: transformers.zoomX,
      altWheelY: transformers.panY,
      dragX: transformers.panX,
      dragY: transformers.panY,
    },
    handlers: {
      onTransform: (transformRef, mousePositionRef) => {
        const matrix = transformRef.current

        const newScale = zoomIdentity
          .translate(matrix[4], matrix[5])
          .scale(matrix[0])
          .rescaleX(useTimelineStore.getState().originalScale)

        if (mousePositionRef.current[0]) {
          useTimelineStore.setState({
            currentScale: newScale,
            mouseDateTime: DateTime.fromJSDate(
              useTimelineStore
                .getState()
                .currentScale.invert(mousePositionRef.current[0])
            ),
            scrollY: matrix[5],
          })
        } else {
          useTimelineStore.setState({
            currentScale: newScale,
            mouseDateTime: undefined,
            scrollY: matrix[5],
          })
        }
      },
      onMove: (transformRef, mousePositionRef) => {
        if (mousePositionRef.current[0]) {
          useTimelineStore.setState({
            mouseDateTime: DateTime.fromJSDate(
              useTimelineStore
                .getState()
                .currentScale.invert(mousePositionRef.current[0])
            ),
          })
        } else {
          useTimelineStore.setState({ mouseDateTime: undefined })
        }
      },
    },
  })

  const zoomToTimeRange = useCallback(
    // these dates must already be converted to system timezone before calling
    (startDateTime: Date, endDateTime: Date) => {
      const { originalScale, leftColumnWidth } = useTimelineStore.getState()

      const startX = originalScale(startDateTime)
      const endX = originalScale(endDateTime)

      const transform = getContainTransformMat2d({
        parent: [measurements.width - leftColumnWidth, 1],
        child: [measurements.width - leftColumnWidth, 0],
        crop: [
          [startX, 0],
          [endX - startX, 0],
        ],
      })

      setTransform([
        transform[0],
        0,
        0,
        1,
        transform[4] + leftColumnWidth,
        transformRef.current[5],
      ])
    },
    [
      useTimelineStore,
      getContainTransformMat2d,
      transformRef,
      setTransform,
      measurements,
    ]
  )

  const zoomToTime = useCallback(
    (durationUnit: DurationUnit) => {
      // this is already in system timezone, no need to convert before calling zoomToTimeRange
      const videoDateTime = useTimelineStore.getState().videoDateTime
      zoomToTimeRange(
        videoDateTime.startOf(durationUnit).toJSDate(),
        videoDateTime.endOf(durationUnit).toJSDate()
      )
    },
    [useTimelineStore, zoomToTimeRange]
  )

  const zoomIncrementally = useCallback(
    // 1 = same zoom, 2 = zoom in 2x, 0.5 = zoom out, etc
    (scale: number) => {
      const { currentScale, leftColumnWidth } = useTimelineStore.getState()
      const currentStartDateTime = currentScale
        .invert(leftColumnWidth)
        .valueOf()
      const currentEndDateTime = currentScale
        .invert(measurements.width)
        .valueOf()
      const currentDuration = currentEndDateTime - currentStartDateTime
      const middle = currentStartDateTime + currentDuration / 2
      const targetDuration = currentDuration / scale
      const targetStart = middle - targetDuration / 2
      const targetEnd = middle + targetDuration / 2

      zoomToTimeRange(new Date(targetStart), new Date(targetEnd))
    },
    [useTimelineStore, zoomToTimeRange, measurements]
  )

  // zoom to week on initial mount
  const [zoomInit, setZoomInit] = useState(false)
  React.useEffect(() => {
    if (measurements.width && !zoomInit) {
      setZoomInit(true)
      zoomToTime(initialZoom)
    }
  }, [measurements])

  const dateTimeRange = useVODStore((state) => state.dateTimeRange)
  const previousDateTimeRange = usePrevious(dateTimeRange)
  React.useEffect(() => {
    if (dateTimeRange && dateTimeRange !== previousDateTimeRange) {
      const dateRangeConverted = dateTimeRange.map((dateTime) =>
        dateTime.setZone('system', { keepLocalTime: true }).toJSDate()
      )
      zoomToTimeRange(dateRangeConverted[0], dateRangeConverted[1])
    }
  }, [dateTimeRange, previousDateTimeRange, zoomToTimeRange])

  return (
    <TimelineStoreContext.Provider value={useTimelineStore}>
      <div className={clsx(classes.root)}>
        <div className={classes.controls}>
          {showFollowPlayheadButton && (
            <KeyedButton
              tooltip={'Follow Playhead'}
              text="Follow Playhead"
              onClick={toggleFollowPlayhead}
              color={followPlayhead ? 'primary' : 'default'}
            />
          )}

          <ButtonGroup>
            <KeyedButton
              tooltip={'Zoom out'}
              text={<ZoomOut />}
              onClick={() => zoomIncrementally(0.5)}
            />
            {durations.map((duration) => (
              <KeyedButton
                key={duration}
                tooltip={duration}
                text={duration}
                onClick={() => zoomToTime(duration)}
              />
            ))}
            <KeyedButton
              tooltip={'Zoom in'}
              text={<ZoomIn />}
              onClick={() => zoomIncrementally(2)}
            />
          </ButtonGroup>

          {showFilterButton && (
            <KeyedButton
              tooltip={'Filter rows by current zoom'}
              text={<FilterListIcon />}
              onClick={() => {
                useTimelineStore.setState({ filterByTime: !filterByTime })
              }}
              // icon={<FilterListIcon />}
              color={filterByTime ? 'primary' : 'default'}
            >
              {/* <FilterListIcon /> */}
            </KeyedButton>
          )}
        </div>
        <TimelineSVG
          useTimelineStore={useTimelineStore}
          mousePositionRef={mousePositionRef}
        />
        <div
          className={classes.canvases}
          ref={useMergeRefs([ref, measurementRef as React.Ref<HTMLDivElement>])}
        >
          <TimelineCanvas>{children}</TimelineCanvas>
          <TimelinePlayheads
            useTimelineStore={useTimelineStore}
            transformRef={transformRef}
            mousePositionRef={mousePositionRef}
            setTransform={setTransform}
            width={measurements.width}
            height={measurements.height}
          />
          <animated.div
            {...bind()}
            style={{ x: leftColumnWidth, touchAction: 'none' }}
            className={classes.leftColumnResizeHandle}
          />
        </div>
      </div>
    </TimelineStoreContext.Provider>
  )
}

Timeline.displayName = 'Timeline'
