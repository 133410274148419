import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@material-ui/core'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import React from 'react'
import { Loading } from '../../Loading'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'
import { t } from 'i18next'

export const CranePickListView = ({}: {}) => {
  const craneStore = useCraneStore()
  const theme = useTheme()

  const picks = craneStore((state) => state.picks)
  const pick = craneStore((state) => state.pick)
  const loading = craneStore((state) => state.loading)

  const [filterOutliers, setFilterOutliers] = React.useState(false)

  return (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterOutliers}
              onChange={() => {
                setFilterOutliers(!filterOutliers)
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Show outliers only"
        />
      </Grid>
      <Divider />
      <Grid
        item
        style={{
          flexGrow: 1,
          height: 1,
          overflow: 'auto',
          marginTop: 0,
        }}
      >
        {loading ? (
          <Loading text={'Loading data'} />
        ) : (
          <List dense>
            {picks.map((p) => {
              if (filterOutliers && !p.isOutlier) {
                return null
              }

              return (
                <ListItem
                  button
                  key={p.id}
                  onClick={() => {
                    craneStore.getState().setPick(p)
                  }}
                  selected={p.id === pick?.id}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                      backgroundColor: p.isOutlier
                        ? 'rgba(255, 0, 0, 0.2)'
                        : undefined,
                    }}
                  />
                  <ListItemText
                    primary={
                      <>
                        <span>
                          {p.load_data ? p.load_data : 'Unspecified load'}
                        </span>
                        <span>
                          {p.action && (
                            <span>: {t(`actions.${p.action}`)}</span>
                          )}
                        </span>
                        {p.rework ? (
                          <span
                            style={{
                              fontSize: '0.8em',
                              fontWeight: 'bold',
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.error.contrastText,
                              padding: '0px 4px',
                              borderRadius: 4,
                              marginLeft: 8,
                            }}
                          >
                            Rework
                          </span>
                        ) : null}
                        <span>
                          <span> - </span>
                          {p.totalDuration.toFormat(
                            p.totalDuration.as('hours') > 1
                              ? "h':'mm':'ss"
                              : "m':'ss"
                          )}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span>{p.subcontractor_name}</span>
                      </>
                    }
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      craneStore.setState({
                        showVideo: true,
                      })
                    }}
                  >
                    <PlayCircleFilled />
                  </IconButton>
                </ListItem>
              )
            })}
          </List>
        )}
      </Grid>
    </>
  )
}
