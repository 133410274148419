import { DateTime } from 'luxon'
import React, { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import zustand, { UseStore } from 'zustand'
import { Analysis } from '../../api/codegen/typescript-axios'
import { createCtx } from '../../helpers/createCtx'
import { useMe } from '../../hooks/useMe'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'

export type AnalysesState = {
  analyses: Analysis[]
  analysisId?: number
  fetchAnalyses: () => void
  createAnalysis: (name: string, dateTime: DateTime) => void
}

export type UseAnalysesStore = UseStore<AnalysesState>

export const [useAnalysesStore, AnalysesContext] = createCtx<UseAnalysesStore>()

export const AnalysesController: FC = ({ children }) => {
  const api = useApi()
  const { data: me } = useMe()
  const project = useProject()

  const [searchParams, setSearcParams] = useSearchParams()
  const analysisParam = searchParams.get('analysis')
  const searchAnalysis = analysisParam ? parseInt(analysisParam) : undefined

  const [useStore] = useState(() =>
    zustand<AnalysesState>((set, get) => ({
      analyses: [],
      analysisId: searchAnalysis,
      fetchAnalyses: async () => {
        const resp = await api.videoAnnotationsApi.projectsVideoannotationAnalysesList(
          {
            projectId: project.id.toString(),
            per: 1000,
          }
        )

        set({ analyses: resp.data.results.reverse() })
      },
      createAnalysis: async (name: string, dateTime: DateTime) => {
        const newAnalysis = await api.videoAnnotationsApi.videoannotationAnalysesCreate(
          {
            data: {
              name: name,
              start_datetime: dateTime.toISO(),
              project: project.id,
            },
          }
        )

        await get().fetchAnalyses()

        set({
          analysisId: newAnalysis.data.id,
        })
      },
    }))
  )

  React.useEffect(() => {
    useStore.setState({ analysisId: searchAnalysis })
  }, [searchAnalysis])

  useStore.subscribe(
    () => {
      const storeAnalysis = useStore.getState().analysisId
      if (storeAnalysis) {
        searchParams.set('analysis', storeAnalysis.toString())
      } else {
        searchParams.delete('analysis')
      }
      setSearcParams(searchParams)
    },
    (state) => state.analysisId
  )

  React.useEffect(() => {
    if (
      me &&
      (me.videoannotation_role === 'admin' ||
        me.videoannotation_role === 'annotator')
    ) {
      useStore.getState().fetchAnalyses()
    }

    return () => {
      useStore.destroy()
    }
  }, [useStore, me])

  return (
    <AnalysesContext.Provider value={useStore}>
      {children}
    </AnalysesContext.Provider>
  )
}
