import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import { ArmingScheduleItem } from '../../api/codegen/typescript-axios'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'
import { SecurityRenderSchedule } from './SecurityRenderSchedule'
import { SecurityScheduleEditor } from './SecurityScheduleEditor'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      // maxWidth: 700,
      padding: 10,
    },
    inner: {},
    day: {
      position: 'relative',
      marginTop: 20,
      marginBottom: 20,
    },
    dayLabel: { textAlign: 'left' },
    dayBlock: {
      position: 'relative',
      height: 20,
      overflow: 'hidden',
      backgroundColor: theme.palette.success.main,
    },
    armBlock: {
      position: 'absolute',
      top: 0,
      height: '100%',
      backgroundColor: theme.palette.error.main,
      borderWidth: '0 3px 0',
      borderColor: theme.palette.background.default,
      borderStyle: 'solid',
    },
    svg: {
      display: 'block',
      position: 'relative',
    },
  })
)

export const SecurityScheduleTab = () => {
  const api = useApi()
  const project = useProject()
  const theme = useTheme()
  const classes = useStyles()

  const [editMode, setEditMode] = React.useState(false)
  const [schedule, setSchedule] = React.useState<ArmingScheduleItem[]>([])

  const { data, refetch } = useQuery(
    ['projectsSecurityScheduleList', project.id],
    async () => {
      const resp = await api.securityApi.projectsSecurityScheduleList({
        projectId: project.id.toString(),
      })
      setSchedule(resp.data)
    },
    {
      refetchInterval: 10000,
    }
  )

  return (
    <div className={classes.root}>
      {editMode ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={6}>
            <SecurityScheduleEditor
              data={schedule}
              onClose={() => {
                setEditMode(false)
                refetch()
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={6}>
            <SecurityRenderSchedule data={schedule} />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMode(true)
                setSchedule([...schedule])
              }}
            >
              Edit Schedule
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
