import { DateTime } from 'luxon'
import { assertRequired } from './assertRequired'
import { VideoSummary } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'
import { StreamExtended } from './StreamExtended'

export const videoTypeSpeeds = {
  '1x': 1,
  '20x': 20,
  '200x': 200,
  '4x-stab': 4,
  '20x-stab': 20,
  '200x-stab': 200,
  daily: 200,
}

export interface VideoSummaryExtended extends VideoSummary {
  stream: StreamExtended
  project: ProjectDetailsExtended
  localStartDateTime: DateTime
  localEndDateTime: DateTime
  // system times are the real world times, shifted to the browser system's timezone,
  // because the timeline D3 scale can only be in that timezone, or in UTC
  systemStartDateTime: DateTime
  systemEndDateTime: DateTime
  speed: number
}

export function extendVideoSummary(
  videoSummary: VideoSummary,
  stream: StreamExtended,
  project: ProjectDetailsExtended
): VideoSummaryExtended {
  assertRequired(videoSummary, ['local_start_time'])

  const startTime = DateTime.fromISO(videoSummary.local_start_time, {
    zone: project.timezone,
  })
  const endTime = startTime.plus({
    seconds:
      (videoSummary.length || 3600) * videoTypeSpeeds[videoSummary.video_type],
  })
  return {
    ...videoSummary,
    project,
    stream,
    localStartDateTime: startTime,
    localEndDateTime: endTime,
    systemStartDateTime: startTime.setZone('system', { keepLocalTime: true }),
    systemEndDateTime: endTime.setZone('system', { keepLocalTime: true }),
    speed: videoTypeSpeeds[videoSummary.video_type],
  }
}
