import { Button, ButtonGroup, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import clsx from 'clsx'
import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useCallbackRef } from 'use-callback-ref'
import { mixins } from '../styles/mixins'
import { GenericTimelapseVideo } from './Timelapses/TimelapsePage'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {},
    },
    videoContainer: {
      position: 'relative',
      backgroundColor: '#000',
    },
    video: {
      ...mixins.absoluteFill,
      outline: 'none',
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: '4px 0',
      color: '#fff',
      // minheight: 38,
      flexWrap: 'wrap',
    },
    controlGroup: {
      margin: 4,
      '& > *': {
        margin: 4,
      },
    },
  })
)

export const VideoPlayerNative = ({
  video,
  className,
  extraControls,
  nativeAttrs,
  setVideoEl,
}: {
  video?: GenericTimelapseVideo
  className?: string
  extraControls?: {
    download?: boolean
    playbackRates?: number[]
  }
  disableIntersectionObserver?: boolean
  nativeAttrs?: HTMLAttributes<HTMLVideoElement>
  setVideoEl?: (video: HTMLVideoElement) => void
}) => {
  const classes = useStyles()

  const [playbackRate, setPlaybackRate] = useState(1)
  const videoRef = useCallbackRef<HTMLVideoElement>(null, (el) => {
    if (setVideoEl && el) {
      setVideoEl(el)
    }
  })

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate
    }
  }, [video, videoRef, playbackRate])

  // console.log(video)

  return (
    <div className={clsx(className, classes.root)}>
      <div
        className={classes.videoContainer}
        style={{
          paddingTop: (9 / 16) * 100 + '%',
        }}
      >
        <video
          ref={videoRef}
          src={video?.url}
          className={classes.video}
          playsInline
          controls
          autoPlay
          muted
          {...nativeAttrs}
        />
      </div>
      {extraControls && video && (
        <div className={classes.info}>
          <div className={classes.controlGroup}>
            <Typography>{video.title}</Typography>
          </div>
          <div className={classes.controlGroup}>
            {extraControls && extraControls.playbackRates && (
              <ButtonGroup>
                {extraControls.playbackRates.map((r) => (
                  <Button
                    key={r}
                    variant="contained"
                    color={playbackRate === r ? 'primary' : 'default'}
                    size="small"
                    onClick={() => setPlaybackRate(r)}
                  >
                    {r}x
                  </Button>
                ))}
              </ButtonGroup>
            )}
            {extraControls && extraControls.download && (
              <a
                href={video.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<CloudDownloadIcon />}
                >
                  Download Video
                </Button>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
