import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import React, { FC, useState } from 'react'
import JoyRide, { CallBackProps, Step } from 'react-joyride'
import { useAsync } from 'react-use'
import { createCtx } from '../../helpers/createCtx'
import { useMe } from '../../hooks/useMe'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      featuresList: {
        paddingLeft: '1em',
      },
    }),
  {
    name: 'AppTour',
  }
)

const locale = {
  back: 'Back',
  close: 'Close',
  last: 'Done',
  next: 'Next',
  skip: 'Skip',
} as const

const tourVersion = '2.17.0'

const tourSteps: Array<{ className: string } & Step> = [
  {
    className: 'projects',
    target: '.projects img',
    disableBeacon: true,
    isFixed: true,
    placement: 'bottom-start',
    content: 'The Projects page link is now at the top of the left nav.',
    styles: {
      tooltip: {
        left: 18,
        top: -1,
      },
    },
  },
  {
    className: 'account-menu',
    target: '.account-menu',
    disableBeacon: true,
    isFixed: true,
    placement: 'bottom-end',
    content:
      'The links to Your Account, Support, and Log-out are now in this dropdown menu in the top right.',
    styles: {
      tooltip: {
        right: 18,
        top: -1,
      },
    },
  },
]

export const [useAppTour, AppTourContext] = createCtx<CallBackProps>()

export const AppTour: FC<{}> = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()

  const styles = {
    options: {
      // arrowColor: '#e3ffeb',
      // backgroundColor: '#e3ffeb',
      // overlayColor: 'rgba(79, 26, 0, 0.4)',
      primaryColor: theme.palette.primary.main,
      // textColor: '#004a14',
      // width: 900,
      zIndex: 1300,
    },
    tooltipContent: {
      textAlign: 'left',
      fontFamily: 'Roboto',
    },
    buttonBack: {
      outline: 'none',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
    },
    buttonClose: {
      outline: 'none',
      strokeWidth: 1,
      stroke: '#000',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
    },
    buttonNext: {
      outline: 'none',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
    },
    buttonSkip: {
      outline: 'none',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
    },
  } as const

  const api = useApi()
  const { data: me } = useMe()
  const { confirm } = useAlertConfirmPrompt()

  const [tourOpen, setTourOpen] = useState(false)

  const [state, setState] = useState<CallBackProps>({
    action: '',
    controlled: false,
    index: -1,
    lifecycle: '',
    size: -1,
    status: 'idle',
    step: {
      target: '',
      content: '',
    },
    type: '',
  })

  // console.log(state)

  useAsync(async () => {
    // uncomment to reset for development
    // if (me) {
    //   api.usersApi.usersMeUpdate({
    //     data: {
    //       ...me,
    //       latest_app_tour: '1.0.0',
    //     },
    //   })
    // }
    if (me && (!me.latest_app_tour || tourVersion > me.latest_app_tour)) {
      try {
        await confirm({
          title: `Version ${tourVersion}`,
          description: (
            <>
              <Typography variant={'body1'}>What's in this release?</Typography>
              <ul className={classes.featuresList}>
                {['Navigation design update', 'Archive player bugfixes'].map(
                  (n, i) => (
                    <li key={i}>{n}</li>
                  )
                )}
              </ul>
              <Typography variant="body1">
                Would you like to take a quick tour of new features?
              </Typography>
            </>
          ),
          yesText: 'Okay',
          noText: 'No thanks',
        })
        setTourOpen(true)
      } catch (err) {
        setTourOpen(false)
      } finally {
        api.usersApi.usersMeUpdate({
          data: {
            ...me,
            latest_app_tour: tourVersion,
          },
        })
      }
    }
  }, [me])

  return (
    <AppTourContext.Provider value={state}>
      {children}
      <JoyRide
        steps={tourSteps}
        run={tourOpen}
        continuous
        disableScrollParentFix
        callback={(data) => {
          setState(data)
        }}
        styles={styles}
        locale={locale}
      />
    </AppTourContext.Provider>
  )
}
