import log from 'loglevel'
import { FC, useCallback, useEffect } from 'react'
import * as serviceWorker from '../../serviceWorker'
import { useAlertConfirmPrompt } from './AlertConfirmPrompt'
import { useApi } from './ApiContext'

export const ServiceWorkerUpdate: FC = () => {
  const api = useApi()
  const { confirm } = useAlertConfirmPrompt()

  const onSWUpdate = useCallback(
    async (registration: ServiceWorkerRegistration) => {
      log.debug('serviceWorker onUpdate')
      if (registration.waiting) {
        log.debug('serviceWorker registration.waiting')
        try {
          const me = await api.getMe()

          if (me?.isAnnotator) {
            await confirm({
              title: 'A new update is available.',
              description:
                "Would you like to reload to install the update? This will reset any forms you haven't yet saved.",
              yesText: 'Reload',
              noText: 'Not now',
            })
          }

          if (registration.waiting) {
            registration.waiting.addEventListener('statechange', (event) => {
              // @ts-ignore
              if (event.target?.state === 'activated') {
                log.debug('serviceWorker registration activated. Reloading.')
                window.location.reload()
              }
            })
            log.debug(
              "serviceWorker registration.waiting.postMessage({ type: 'SKIP_WAITING' })"
            )
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          } else {
            log.debug('serviceWorker registration not waiting. Reloading')
            window.location.reload()
          }
        } catch (err) {}
      }
    },
    [confirm]
  )

  useEffect(() => {
    log.debug('serviceWorker.register')
    serviceWorker.register({ onUpdate: onSWUpdate })
    return () => {
      log.debug('serviceWorker.unregister')
      serviceWorker.unregister()
    }
  }, [onSWUpdate])

  return null
}
