import { createStyles, makeStyles } from '@material-ui/core'
import React, { memo } from 'react'
import { VODDateTimeSelect } from './VODDateTimeSelect'
import { VODStreamThumbnails } from './VODStreamThumbnails'

const useStyles = makeStyles(
  () =>
    createStyles({
      pickers: {
        height: '100%',
        userSelect: 'none',
        overflow: 'hidden',
        padding: '6px 6px 0',
        color: '#fff',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  {
    name: 'VODPickers',
  }
)

export const VODThumbnails = memo(() => {
  const classes = useStyles()

  return (
    <div className={classes.pickers}>
      <VODDateTimeSelect />
      <VODStreamThumbnails />
    </div>
  )
})

VODThumbnails.displayName = 'VODThumbnails'
