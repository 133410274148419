import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import {
  ImageAnnotationBox,
  ProjectSummary,
} from '../../api/codegen/typescript-axios'
import { extendProject } from '../../api/ProjectDetailsExtended'
import { queryKeys } from '../../helpers/constants'
import { AnnotationIssue } from '../Annotator/AnnotationIssue'
import { useApi } from '../ApiContext'
import { MultiSelect } from '../FormInputs/MultiSelect'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { RemotelyPaginatedTable } from '../RemotelyPaginatedTable'
import { orderOptions, OrderSelect } from './OrderSelect'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 8,
      flexGrow: 1,
    },
    link: {
      textDecoration: 'none',
    },
    thumbnail: {
      display: 'block',
      width: 200,
    },
    categoryList: {
      margin: 0,
    },
    zoomImage: {
      display: 'block',
      width: '100%',
    },
    backdrop: {
      pointerEvents: 'none',
    },
    submitBar: {
      top: 'auto',
      bottom: 0,
    },
  })
)

const tableOptions = {}

export const Issues = ({ projects }: { projects: ProjectSummary[] }) => {
  const api = useApi()
  const classes = useStyles()

  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.PER]: '100',
    [queryKeys.PAGE]: '1',
  })
  const perParam = parseInt(searchParams.get(queryKeys.PER)!)
  const pageParam = parseInt(searchParams.get(queryKeys.PAGE)!)

  const projectParam = searchParams.get(queryKeys.PROJECTS) || ''
  // project autocomplete state
  const [projectInput, setProjectInput] = useState('')
  // fetch project
  const { value: project } = useAsync(async () => {
    if (!projectParam) {
      return
    }
    const projectId = await api.projectIdFromKey(projectParam)
    if (!projectId) return null
    const resp = await api.projectsApi.projectsRead({
      id: projectId.toString(),
    })
    return extendProject(resp.data)
  }, [projectParam])

  // Fetch Images
  const [fetchIssuesState, fetchIssues] = useAsyncFn(
    async ({ per = perParam, page = pageParam }) => {
      const resp = await api.imageAnnotationsApi.imageannotationFlaggedboxesList(
        {
          streamIds: searchParams.get(queryKeys.STREAMS) || undefined,
          issueResolved:
            searchParams.get(queryKeys.ISSUE_RESOLVED) || undefined,
          ordering: searchParams.get(queryKeys.ORDER) || undefined,
          page,
          per,
        }
      )

      return resp.data
    },
    [searchParams]
  )
  useEffect(() => {
    fetchIssues({})
  }, [])

  const issueId = searchParams.get('issue')

  const columns = useMemo(
    () => [
      {
        title: 'Label',
        field: 'label.name',
      },
      {
        title: 'Sublabel',
        field: 'sublabel.name',
      },
      {
        title: 'Flagged',
        render: (i: ImageAnnotationBox) =>
          i.issue_flagged ? (
            <CheckIcon style={{ color: '#090' }} />
          ) : (
            <CloseIcon style={{ color: '#900' }} />
          ),
      },
      {
        title: 'Resolved',
        render: (i: ImageAnnotationBox) =>
          i.issue_resolved ? (
            <CheckIcon style={{ color: '#090' }} />
          ) : (
            <CloseIcon style={{ color: '#900' }} />
          ),
      },
    ],
    [classes]
  )

  return (
    <>
      <Paper>
        <Box p={2} m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={projects}
                getOptionLabel={(p) => p.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Project"
                    variant="outlined"
                  />
                )}
                value={projects.find((p) => p.slug === projectParam)}
                onChange={(event: any, p: ProjectSummary | null) => {
                  if (p) {
                    searchParams.set(queryKeys.PROJECTS, p.slug)
                    searchParams.delete(queryKeys.STREAMS)
                    setSearchParams(searchParams, { replace: true })
                  }
                }}
                inputValue={projectInput}
                onInputChange={(event, newInputValue) => {
                  setProjectInput(newInputValue)
                }}
              />
            </Grid>

            {project && project.streams && (
              <>
                <Grid item xs={12}>
                  <MultiSelect
                    groupLabel="Streams"
                    options={project.streams.map((s) => ({
                      label: s.name,
                      value: s.id.toString(),
                    }))}
                    queryKey={queryKeys.STREAMS}
                    inputType="checkbox"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <OrderSelect
                options={[orderOptions.ANNOTATION_DATE]}
                defaultOption={orderOptions.ANNOTATION_DATE}
                defaultDirection=""
              />
            </Grid>

            <Grid item xs={12}>
              <ProgressButton
                variant="contained"
                color="primary"
                onClick={fetchIssues}
                fetchState={fetchIssuesState}
              >
                Fetch Issues
              </ProgressButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <RemotelyPaginatedTable<ImageAnnotationBox>
        title="Issues"
        options={tableOptions}
        columns={columns}
        asyncFnReturn={[fetchIssuesState, fetchIssues]}
        defaultPer="100"
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: 'Inspect Box',
            onClick: (event, rowData) => {
              searchParams.set(
                'issue',
                (rowData as ImageAnnotationBox).id!.toString()
              )
              setSearchParams(searchParams, { replace: true })
            },
          },
        ]}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={!!issueId}
        onClose={() => {
          searchParams.delete('report')
          setSearchParams(searchParams, { replace: true })
        }}
      >
        <DialogContent style={{ height: '95vh', padding: 0 }}>
          {issueId && <AnnotationIssue issueId={issueId} />}
        </DialogContent>
      </Dialog>
    </>
  )
}
