import { chunk, flatten } from 'lodash'
import log from 'loglevel'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import { AsyncFnReturn } from 'react-use/lib/useAsync'
import zustand, { UseStore } from 'zustand'
import {
  ImageAnnotationBox,
  ImageSessionDetails,
} from '../../api/codegen/typescript-axios'
import { createCtx } from '../../helpers/createCtx'
import { useApi } from '../ApiContext'
import { Loading } from '../Loading'
import { Annotator, ImageAnnotationBoxEdit } from './Annotator'

type AFR<T, U> = AsyncFnReturn<(arg: T) => Promise<U | undefined>>

export const [useAnnotatorApi, AnnotatorApi] = createCtx<{
  pause: AFR<ImageAnnotationBox[], ImageSessionDetails>
  resume: AFR<undefined, ImageSessionDetails>
  save: AFR<ImageAnnotationBox[], ImageSessionDetails>
  complete: AFR<ImageAnnotationBox[], ImageSessionDetails>
  useStore: UseStore<AnnotatorState>
}>()

export type AnnotatorState = {
  maskPolygons?: number[][]
}

export const AnnotatorFetcher = () => {
  const { sessionId } = useParams()
  const api = useApi()
  const navigate = useNavigate()

  const [session, setSession] = useState<ImageSessionDetails>()

  const [useStore] = useState(() =>
    zustand<AnnotatorState>((set) => ({
      maskPolygons: undefined,
    }))
  )

  const save = useAsyncFn(
    async (boxes: ImageAnnotationBoxEdit[]) => {
      if (!session) return

      const polygons = useStore.getState().maskPolygons?.map((polygon) =>
        chunk(
          polygon.map((n) => Math.round(n)),
          2
        )
      )

      if (!polygons) {
        log.error('No polygons?')
        return
      }

      const maskResp = await api.imageAnnotationsApi.imageannotationFramesSetMask(
        {
          id: session?.frame.id!.toString(),
          data: {
            mask_polygons: polygons,
          },
        }
      )
      console.log(maskResp.data)

      const resp = await api.imageAnnotationsApi.imageannotationSessionsSave({
        id: session.id.toString(),
        data: {
          boxes: boxes.map((box) => ({
            x: Math.round(box.x),
            y: Math.round(box.y),
            w: Math.round(box.w),
            h: Math.round(box.h),
            label_id: box.label.id,
            sublabel_id: box.sublabel?.id,
            comment: box.newComment,
          })),
        },
      })
      console.log(resp.data)
      setSession(resp.data)

      return resp.data
    },
    [session]
  )

  const pause = useAsyncFn(
    async (boxes: ImageAnnotationBoxEdit[]) => {
      if (!session) return
      const resp = await api.imageAnnotationsApi.imageannotationSessionsPause({
        id: session.id.toString(),
      })
      console.log(resp.data)
      await save[1](boxes)
      return resp.data
    },
    [session]
  )

  // Resume session
  const resume = useAsyncFn(async () => {
    if (!session) return
    const resp = await api.imageAnnotationsApi.imageannotationSessionsResume({
      id: session.id.toString(),
    })
    console.log(resp.data)
    setSession(resp.data)
    return resp.data
  }, [session])

  // Complete Session
  const complete = useAsyncFn(
    async (boxes: ImageAnnotationBoxEdit[]) => {
      if (!session) return
      if (session.paused_at) {
        await resume[1]()
      }
      const resp = await api.imageAnnotationsApi.imageannotationSessionsComplete(
        {
          id: session.id.toString(),
          data: {
            boxes: boxes.map((box) => ({
              x: Math.round(box.x),
              y: Math.round(box.y),
              w: Math.round(box.w),
              h: Math.round(box.h),
              label_id: box.label.id,
              sublabel_id: box.sublabel?.id,
              comment: box.newComment,
            })),
          },
        }
      )
      console.log(resp.data)
      setSession(resp.data)
      // @ts-ignore
      if (window.opener) {
        window.close()
      } else {
        navigate(`/annotations/list-jobs`)
      }
      return resp.data
    },
    [session]
  )

  // initial fetch session
  useAsync(async () => {
    if (sessionId) {
      const resp = await api.imageAnnotationsApi.imageannotationSessionsRead({
        id: sessionId,
      })
      setSession(resp.data)
      return resp.data
    }
  }, [sessionId])

  const { value: sublabels = [] } = useAsync(async () => {
    const resp = await api.imageAnnotationsApi.imageannotationSublabelsList()
    return resp.data
  }, [])

  useEffect(() => {
    if (session) {
      if (session.frame.mask_polygons) {
        useStore.setState({
          maskPolygons: session?.frame.mask_polygons.map((p) => flatten(p)),
        })
      } else {
        useStore.setState({ maskPolygons: [] })
      }
    }
  }, [session])

  if (!session || !sublabels) return <Loading text={'Loading...'}></Loading>

  return (
    <AnnotatorApi.Provider
      value={{
        pause,
        resume,
        save,
        complete,
        useStore,
      }}
    >
      <Annotator session={session} sublabels={sublabels} useStore={useStore} />
    </AnnotatorApi.Provider>
  )
}
