import { useState, useEffect } from 'react'

export default function useVideoMetadata(
  video?: HTMLVideoElement | null
): {
  videoWidth?: number
  videoHeight?: number
  duration?: number
} {
  const [metadata, setMetaData] = useState({
    videoWidth: video?.videoWidth,
    videoHeight: video?.videoHeight,
    duration: video?.duration,
  })

  const onLoadedMetadata = () => {
    if (video) {
      setMetaData({
        videoWidth: video.videoWidth,
        videoHeight: video.videoHeight,
        duration: video.duration,
      })
    }
  }

  useEffect(() => {
    if (!video) return
    if (video.paused || video.ended) {
      onLoadedMetadata()
    }

    video.addEventListener('loadedmetadata', onLoadedMetadata)
    return () => {
      video.removeEventListener('loadedmetadata', onLoadedMetadata)
    }
  }, [video])

  return metadata
}
