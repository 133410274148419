import { createStyles, withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

export const SecuritySwitch = withStyles((theme) =>
  createStyles({
    switchBase: {
      color: theme.palette.success.main,
      '&$checked': {
        color: theme.palette.error.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    checked: {},
    track: {
      backgroundColor: theme.palette.success.dark,
    },
  })
)(Switch)
