import { SomeRequired } from '../../types'
import { assertRequired } from './assertRequired'
import { ProjectSummary } from './codegen/typescript-axios'

export type ProjectSummaryExtended = SomeRequired<
  ProjectSummary,
  'id' | 'user_attrs' | 'streams'
>

export function extendProjectSummary(
  project: ProjectSummary
): ProjectSummaryExtended {
  project.streams = project.streams || []

  assertRequired(project, ['id', 'user_attrs', 'streams'])

  return project
}
