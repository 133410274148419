import { useEffect, useRef, useState } from 'react'
import useScript from 'react-script-hook'
import { useUnmount } from 'react-use'
import 'webrtc-adapter'
import { Stream } from '../api/codegen/typescript-axios'
import { useMuxBaseConfig } from '../hooks/useMuxBaseConfig'
import { Loading } from './Loading'
// @ts-ignore
import mux from 'mux-embed'
import { useProject } from './ProjectWrapper'

export const AntMediaWebRTCPlayer = ({
  stream,
  setError,
}: {
  stream: Stream
  setError?: (e: string) => void
}) => {
  // const [loadingAdapter, adapterError] = useScript({
  //   src: 'https://webrtc.github.io/adapter/adapter-latest.js',
  // })

  const muxBaseConfig = useMuxBaseConfig()
  const [loadingAdaptor] = useScript({
    src: 'https://webrtc.topdeck.ai:5443/WebRTCAppEE/js/webrtc_adaptor.js',
  })

  const project = useProject()

  const rtcRef = useRef(null)

  const [adaptor, setAdaptor] = useState<WebRTCAdaptor>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!adaptor && rtcRef.current && stream.ant_id && !loadingAdaptor) {
      var pc_config = {
        iceServers: [
          {
            urls: 'turn:stun.topdeck.ai:80?transport=tcp', // A TURN server
            username: 'topdeck',
            credential: 'bad_password',
          },
          {
            urls: 'turn:stun.topdeck.ai:443?transport=tcp', // A TURN server
            username: 'topdeck',
            credential: 'bad_password',
          },
        ],
      }

      var sdpConstraints = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      }
      var mediaConstraints = {
        video: false,
        audio: false,
      }

      const webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: 'wss://webrtc.topdeck.ai/WebRTCAppEE/websocket',
        mediaConstraints: mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        remoteVideoId: 'webrtcvideo',
        isPlayMode: true,
        debug: true,
        callback: (info: any, description: any) => {
          switch (info) {
            case 'initialized':
              webRTCAdaptor.getStreamInfo(stream.ant_id!)
              break
            case 'streamInformation':
              webRTCAdaptor.play(stream.ant_id!, 'null')
              break
            case 'play_started':
              setLoading(false)
              console.log('play started')
              break
            case 'play_finished':
              console.log('play finished')
              break
            case 'closed': // (!)
              console.log('Connecton closed: ' + JSON.stringify(description))
              break
          }
        },
        callbackError: (error: any) => {
          console.error(error)
          setError &&
            setError(
              'There was a problem loading the low-latency feed: ' +
                JSON.stringify(error)
            )
          //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
          // console.log("error callback: " + JSON.stringify(error));
        },
      })

      mux.monitor(rtcRef.current, {
        debug: false,
        data: {
          ...muxBaseConfig,

          player_name: 'AntMediaWebRTCPlayer', // any arbitrary string you want to use to identify this player

          // Video Metadata
          video_id: stream.ant_id, // ex: 'abcd123'
          video_title: `${project.slug} - ${stream.name} - ANT`, // ex: 'My Great Video'
          video_stream_type: 'live low-latency', // 'live' or 'on-demand',
          video_content_type: 'live video',
        },
      })

      setAdaptor(webRTCAdaptor)
    }
  }, [adaptor, stream.ant_id, loadingAdaptor])

  useUnmount(() => {
    if (adaptor) {
      adaptor.stop(stream.ant_id!)
    }
  })

  return (
    <>
      <video
        ref={rtcRef}
        id="webrtcvideo"
        autoPlay
        playsInline
        muted
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
        }}
      ></video>
      {loading && (
        <Loading
          text="Connecting directly to camera…"
          style={{ color: '#fff' }}
        ></Loading>
      )}
    </>
  )
}
