import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { degreesToRadians } from '@turf/helpers'
import clsx from 'clsx'
import { uniqBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Marker } from 'react-map-gl'
import { animated, useSpring } from 'react-spring'
import { Photosphere } from '../../api/codegen/typescript-axios'
import { mixins } from '../../styles/mixins'
import { ProjectMap } from '../ProjectMap'
import { useKRPanoStore } from './KRPanoProvider'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        ...mixins.absoluteFill,
      },
      markerOuter: {
        pointerEvents: 'none',
      },
      radarMarker: {
        pointerEvents: 'none',
      },
      radarSVG: {
        position: 'relative',
      },
      radarPath: {
        pointerEvents: 'all',
        cursor: 'pointer',
        transition: 'transform 0.2s',
      },
      radarLinkPath: {
        transition: 'opacity 0.2s',
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
      },
    }),
  {
    name: 'PhotosphereMap',
  }
)

const radarRadius = 30

const { sin, cos } = Math

export function getRadarPath(hLookAt: number, vLooktAt: number, fov: number) {
  const hLootAtRad = degreesToRadians(hLookAt)
  const fovRad = degreesToRadians(fov / 2)
  const fov0 = hLootAtRad + fovRad
  const fov1 = hLootAtRad - fovRad
  return `M 0 0 L ${cos(fov0) * radarRadius} ${
    sin(fov0) * radarRadius
  } A ${radarRadius} ${radarRadius} 0 0 0 ${cos(fov1) * radarRadius} ${
    sin(fov1) * radarRadius
  } Z`
}

export const PhotosphereMap = ({
  spheres,
  currentSphere,
  onClickSphere,
}: {
  spheres?: Photosphere[]
  currentSphere?: Photosphere
  onClickSphere: (id: number) => void
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const store = useKRPanoStore()

  const [radar, spring] = useSpring(() => ({
    path: 'M 0 0 L 0 0 A 0 0 0 0 0 0 0 Z',
    immediate: true,
  }))

  const setRadar = React.useCallback(() => {
    console.log('useKRPanoStoreApi viewState change')
    const { hlookat, vlookat, fov } = store.getState().viewState
    const path = getRadarPath(
      hlookat + (currentSphere?.bearing ?? 0) - 90,
      vlookat,
      fov
    )
    spring.set({ path })
  }, [store, currentSphere, spring])

  useEffect(() => store.subscribe(setRadar, (state) => state.viewState), [
    store,
    setRadar,
  ])

  useEffect(setRadar, [currentSphere, setRadar])

  const dedupedSpheres = useMemo(
    () => uniqBy(spheres, (sphere) => sphere.template),
    [spheres]
  )

  return (
    <div className={clsx(classes.root)}>
      <ProjectMap>
        <Marker
          key="radar"
          latitude={currentSphere?.latitude || 0}
          longitude={currentSphere?.longitude || 0}
          //  className={classes.radarMarker}
        >
          <svg
            className={classes.radarSVG}
            width={radarRadius * 2 + 2}
            height={radarRadius * 2 + 2}
            viewBox={`${-radarRadius - 1} ${-radarRadius - 1} ${
              radarRadius * 2 + 2
            } ${radarRadius * 2 + 2}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <animated.path
              className={classes.radarPath}
              d={radar.path}
              fill={'#00cc00'}
              fillOpacity="0.3"
              stroke={'#00cc00'}
            />
            <circle cx={0} cy={0} r={4} fill={'#00cc00'} />
          </svg>
        </Marker>

        {dedupedSpheres &&
          dedupedSpheres
            .filter((sphere) => currentSphere?.template !== sphere.template)
            .map((sphere) => {
              const color = theme.palette.secondary.dark
              return (
                <Marker
                  key={sphere.id}
                  latitude={sphere.latitude || 0}
                  longitude={sphere.longitude || 0}
                  // className={classes.radarMarker}
                >
                  <svg
                    className={classes.radarSVG}
                    onClick={() => onClickSphere(sphere.id)}
                    width={radarRadius * 2 + 2}
                    height={radarRadius * 2 + 2}
                    viewBox={`${-radarRadius - 1} ${-radarRadius - 1} ${
                      radarRadius * 2 + 2
                    } ${radarRadius * 2 + 2}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={clsx(classes.radarPath, classes.radarLinkPath)}
                      d={getRadarPath(
                        (sphere.template_panoattrs?.hlookat ?? 0) +
                          (sphere?.bearing ?? 0) -
                          90,
                        0,
                        120
                      )}
                      fill={color}
                      fillOpacity="0.3"
                      stroke={color}
                    />
                    <circle cx={0} cy={0} r={4} fill={color} />
                  </svg>
                </Marker>
              )
            })}
      </ProjectMap>
    </div>
  )
}
