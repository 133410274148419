import { Box, Divider, Grid, useTheme } from '@material-ui/core'
import { useWindowSize } from 'react-use'
import { useMobile } from '../../../hooks/useMobile'
import { usePanels } from '../../../hooks/usePanels'
import { StaticTimeline } from '../Timeline/StaticTimeline'
import { VODClock } from '../VODClock'
import { getUseVODStore, VODController } from '../VODController'
import { VODDateTimeSelect } from '../VODDateTimeSelect'
import { VODSteamSelect } from '../VODStreamSelect'
import { FrameDisplay } from './FrameDisplay'
import { FramesController, useFramesStore } from './FramesController'
import { FramesTimeline } from './FramesTimeline'

export const FramesPage = () => {
  return (
    <VODController>
      <FramesController>
        <FramesLayout />
      </FramesController>
    </VODController>
  )
}

const FramesLayout = () => {
  const theme = useTheme()
  const vodStore = getUseVODStore()
  const frameStore = useFramesStore()

  const isMobile = useMobile()

  const videoDate = vodStore((state) => state.videoDate)
  const timeOptions = frameStore((state) => state.timeOptions)

  const { panelState, PanelControl } = usePanels('FramesPagePanels', {
    Timeline: true,
  })

  const { height } = useWindowSize()

  if (!panelState) return null

  return (
    <div
      style={{
        height: height - theme.custom.projectNavHeight,
        maxHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box padding={1}>
        <Grid container spacing={1}>
          <Grid item>
            <VODClock />
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <VODDateTimeSelect time={true} timeOptions={timeOptions} />
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <VODSteamSelect />
          </Grid>
          {/* <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <PanelControl />
          </Grid> */}
        </Grid>
      </Box>
      <Divider />

      {!isMobile && (
        <StaticTimeline
          domain={[
            videoDate.startOf('day').minus({ minutes: 59 }),
            videoDate.endOf('day').plus({ minutes: 59 }),
          ]}
        >
          <FramesTimeline />
        </StaticTimeline>
      )}

      <FrameDisplay />
    </div>
  )
}
