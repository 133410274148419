import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { DateRangeDelimiter, StaticDateRangePicker } from '@material-ui/pickers'
import { DateTime, Duration } from 'luxon'
import React from 'react'
import * as XLSX from 'xlsx'
import { assertIsDefined } from '../../../api/assertRequired'
import { extendCranePick } from '../../../api/CranePickExtended'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'
import {
  columns,
  cranePicksCollectionToAOA,
  dateCellObject,
  durationCellObject,
  timeCellObject,
} from './CranePicksExportConfigs'

export const CranePicksExportButton = () => {
  const api = useApi()
  const project = useProject()
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()

  const videoDate = vodStore((state) => state.videoDate)

  const selectedCrane = craneStore((state) => state.selectedCrane)
  const sumPicks = craneStore((state) => state.sumPicks)

  const [exportDialogOpen, setExportDialogOpen] = React.useState(false)
  const [exportTimeRange, setExportTimeRange] = React.useState<
    [DateTime | null, DateTime | null]
  >([DateTime.now(), DateTime.now()])
  const [exportFormat, setExportFormat] = React.useState<'xlsx' | 'csv'>('xlsx')

  const timeRangeTooLong =
    !!exportTimeRange[1] &&
    !!exportTimeRange[0] &&
    exportTimeRange[1].diff(exportTimeRange[0]).as('days') > 31

  const [exportVersion, setExportVersion] = React.useState<'raw' | 'extended'>(
    'extended'
  )

  React.useEffect(() => {
    if (exportVersion === 'extended') {
      setExportFormat('xlsx')
    }
  }, [exportVersion])

  const exportPicks = async () => {
    if (
      !selectedCrane ||
      exportTimeRange[0] === null ||
      exportTimeRange[1] === null
    ) {
      console.error('Timerange member is null!')
      return
    }

    const filename = `crane-picks--${selectedCrane.name.replaceAll(
      ' ',
      '-'
    )}--${exportTimeRange[0].toISODate()}`

    const resp = await api.craneApi.cranesPicksList({
      craneId: selectedCrane.id.toString(),
      startTime: exportTimeRange[0].startOf('day').toISO(),
      endTime: exportTimeRange[1].endOf('day').toISO(),
    })

    const summedPicks = sumPicks({
      picks: resp.data.map((p) => extendCranePick(p, project)),
    })

    let filteredColumns = columns

    if (exportFormat === 'csv' || exportVersion === 'raw') {
      filteredColumns = columns.filter(
        (col) => col.sourceKey && !col.getFormula
      )
    }

    const aoa: XLSX.CellObject[][] = summedPicks.picks.map((pick, i) => {
      return filteredColumns.map((column) => {
        if (column.sourceKey) {
          let v: string | number | boolean | Date | undefined =
            pick[column.sourceKey] || undefined

          if (typeof v === 'string' && column.cellObject === dateCellObject) {
            v = DateTime.fromISO(v, {
              zone: project.timezone,
            })
              .setZone('UTC', { keepLocalTime: true })
              .set({ millisecond: 0 })
              .toJSDate()
          } else if (
            typeof v === 'string' &&
            column.cellObject === timeCellObject
          ) {
            v = DateTime.fromISO(v, {
              zone: project.timezone,
            })
              .setZone('UTC', { keepLocalTime: true })
              .set({ millisecond: 0 })
              .toJSDate()
          } else if (
            typeof v === 'string' &&
            column.cellObject === durationCellObject
          ) {
            v = Duration.fromISO(v)
              .set({ milliseconds: 0 })
              .toISOTime({ suppressMilliseconds: true })
          } else {
            v = v
          }

          return {
            ...column.cellObject,
            v,
          }
        } else if (column.getFormula) {
          return {
            ...column.cellObject,
            f: column.getFormula(i),
          }
        }
        return column.cellObject
      })
    })

    const headerRow: XLSX.CellObject[] = filteredColumns.map((column) => ({
      t: 's',
      v: column.header,
    }))

    aoa.unshift(headerRow)

    // array of arrays of sumpicks sums
    const aoa2: XLSX.CellObject[][] = cranePicksCollectionToAOA(summedPicks)

    var workbook = XLSX.utils.book_new()
    var worksheet = XLSX.utils.aoa_to_sheet(aoa, {})
    var worksheet2 = XLSX.utils.aoa_to_sheet(aoa2, {})

    worksheet2['!cols'] = [{ wch: 25 }, { wch: 18 }]

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Picks')

    if (exportFormat === 'xlsx' && exportVersion === 'extended') {
      XLSX.utils.book_append_sheet(workbook, worksheet2, 'Aggregates')
    }

    XLSX.writeFile(workbook, filename + '.' + exportFormat, {
      cellStyles: true,
    })
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setExportTimeRange([videoDate, videoDate])
          setExportDialogOpen(true)
        }}
      >
        Export
      </Button>

      {selectedCrane && (
        <Dialog
          open={exportDialogOpen}
          onClose={() => setExportDialogOpen(false)}
          maxWidth="lg"
        >
          <DialogTitle>Export Excel</DialogTitle>
          <DialogContent>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              startText="Start"
              endText="End"
              value={exportTimeRange}
              onChange={(d) => {
                setExportTimeRange(d)
              }}
              disableFuture
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField {...endProps} />
                </>
              )}
            />
            {timeRangeTooLong && (
              <Alert severity="error">
                1-month maximum time range exceeded
              </Alert>
            )}

            <FormControl fullWidth>
              <FormLabel style={{ marginRight: 12 }}>Version: </FormLabel>
              <RadioGroup
                row
                aria-label="Version"
                name="fileFormat"
                value={exportVersion}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  value
                ) => {
                  setExportVersion(value as 'raw' | 'extended')
                }}
              >
                <FormControlLabel
                  value="raw"
                  control={<Radio />}
                  label="Raw data"
                />
                <FormControlLabel
                  value="extended"
                  control={<Radio />}
                  label="Extended (includes formula columns and daily totals sheet)"
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel style={{ marginRight: 12 }}>File format: </FormLabel>
              <RadioGroup
                row
                aria-label="File format"
                name="fileFormat"
                value={exportFormat}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  value
                ) => {
                  setExportFormat(value as 'xlsx' | 'csv')
                }}
              >
                <FormControlLabel
                  value="xlsx"
                  control={<Radio />}
                  label="XLSX"
                />
                {exportVersion === 'raw' && (
                  <FormControlLabel
                    value="csv"
                    control={<Radio />}
                    label="CSV"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setExportDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                const timeStart = exportTimeRange[0]
                const timeEnd = exportTimeRange[1]

                assertIsDefined(timeStart)
                assertIsDefined(timeEnd)

                exportPicks()
              }}
              disabled={timeRangeTooLong}
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
