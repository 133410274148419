import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import Forward10Icon from '@material-ui/icons/Forward10'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ReplayIcon from '@material-ui/icons/Replay'
import Replay10Icon from '@material-ui/icons/Replay10'
import React, { useState } from 'react'
import { useUnmount } from 'react-use'
import {
  ImageAnnotationBox,
  ImageSessionDetails,
} from '../../api/codegen/typescript-axios'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { KeyedButton } from '../Video/KeyedButton'
import { LabelState, Mode } from './Annotator'
import { useAnnotatorApi } from './AnnotatorFetcher'
import { LabelStateControl } from './LabelStateControl'

const useStyles = makeStyles({
  '@keyframes blink': {
    '0%': { opacity: 0.2 },
    '49%': { opacity: 0.2 },
    '50%': { opacity: 1 },
  },
  blink: { animation: '$blink 1s infinite' },
})

export const AnnotatorBottomControls = ({
  session,
  boxes,
  video,
  showVideo,
  setShowVideo,
  mode,
  setMode,
  labelState,
  setLabelState,
}: {
  session: ImageSessionDetails
  boxes: ImageAnnotationBox[]
  video: HTMLVideoElement
  showVideo: boolean
  setShowVideo: (b: boolean) => void
  mode: Mode
  setMode: (mode: Mode) => void
  labelState: LabelState
  setLabelState: (s: LabelState) => void
}) => {
  const classes = useStyles()
  const {
    pause: [pauseSessionState, pauseSession],
    resume: [resumeSessionState, resumeSession],
    save: [saveSessionState, saveSession],
    complete: [completeSessionState, completeSession],
  } = useAnnotatorApi()

  useUnmount(() => {
    if (session!.paused_at === null) {
      pauseSession(boxes)
    }
  })

  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <Box
        p={1}
        display="flex"
        justifyContent="space-between"
        // border="1px solid #eee"
      >
        <ButtonGroup variant="contained">
          <Button
            onClick={() => {
              video.currentTime = video.currentTime - 10
              setShowVideo(true)
            }}
            startIcon={<Replay10Icon />}
          ></Button>
          {showVideo ? (
            <Button
              onClick={() => {
                video.currentTime = session.frame.offset_seconds
                video.pause()
                setShowVideo(false)
              }}
              startIcon={<ReplayIcon />}
            >
              Reset Video
            </Button>
          ) : (
            <Button
              onClick={() => {
                video.currentTime = session.frame.offset_seconds
                video.play()
                setShowVideo(true)
              }}
              startIcon={<PlayArrowIcon />}
            >
              Play Video
            </Button>
          )}
          <Button
            onClick={() => {
              video.currentTime = video.currentTime + 10
              setShowVideo(true)
            }}
            startIcon={<Forward10Icon />}
          ></Button>
        </ButtonGroup>

        <LabelStateControl
          labelState={labelState}
          setLabelState={setLabelState}
        />

        <ButtonGroup variant="contained">
          <KeyedButton
            color={mode === 'selectMask' ? 'primary' : 'default'}
            onClick={() => setMode('selectMask')}
            text="Edit Mask"
            tooltip="Mask"
          />
          <KeyedButton
            color={mode === 'drawMask' ? 'primary' : 'default'}
            onClick={() => setMode('drawMask')}
            text="Draw Mask"
            tooltip="Mask"
          />
          <KeyedButton
            color={mode === 'select' ? 'primary' : 'default'}
            onClick={() => setMode('select')}
            keyCode="s"
            text="Select"
            tooltip="Select (s)"
          />
          <KeyedButton
            color={mode === 'draw' ? 'primary' : 'default'}
            onClick={() => setMode('draw')}
            keyCode="s"
            text="Draw"
            tooltip="Draw (d)"
          />
        </ButtonGroup>
      </Box>

      <Box p={1} display="flex" justifyContent="flex-end">
        <ProgressButton
          fetchState={saveSessionState}
          onClick={() => saveSession(boxes)}
          style={{ marginLeft: 10 }}
        >
          Save
        </ProgressButton>

        {session.paused_at ? (
          <ProgressButton
            fetchState={resumeSessionState}
            onClick={() => resumeSession(undefined)}
            className={classes.blink}
            style={{ marginLeft: 10 }}
          >
            Resume
          </ProgressButton>
        ) : (
          <ProgressButton
            fetchState={pauseSessionState}
            onClick={() => pauseSession(boxes)}
            style={{ marginLeft: 10 }}
          >
            Pause
          </ProgressButton>
        )}

        <ProgressButton
          fetchState={completeSessionState}
          onClick={() => setDialogOpen(true)}
          style={{ marginLeft: 10 }}
        >
          Complete
        </ProgressButton>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{'Complete your session?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will complete and close the session. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => completeSession(boxes)}
            color="primary"
            autoFocus
          >
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
