import { CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { mixins } from '../styles/mixins'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    pointerEvents: 'none',
    ...mixins.absoluteFill,
  },
  text: {
    marginTop: 20,
  },
})
export const Loading = ({
  text,
  className,
  style,
}: {
  text?: string
  className?: string
  style?: any
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} style={style}>
      <CircularProgress />
      {text && <div className={classes.text}>{text}</div>}
    </div>
  )
}
