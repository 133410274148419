import axios from 'axios'
import React, { FC, useState } from 'react'
import { useQuery } from 'react-query'
import zustand, { UseStore } from 'zustand'
import { Upload } from '../../../api/codegen/typescript-axios'
import { createCtx } from '../../../helpers/createCtx'
import { useAlertConfirmPrompt } from '../../AlertConfirmPrompt'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'

export type UploadsState = {
  uploads?: Upload[]
  selectedUpload?: Upload
  selectedUploadLoading: boolean
  selectedUploadLoadProgress: number
  selectedUploadError?: string
  selectedUploadPageCount?: number
  // This is 1-based because a PDF's first page is page 1
  selectedUploadPageNumber: number
  fetchUploads: () => void
  createUpload: (file: File) => void
  deleteUpload: (upload: Upload) => void
}

export type UseUploadsStore = UseStore<UploadsState>

export const [useUploadsStore, UploadsContext] = createCtx<UseUploadsStore>()

// Must be descendant of AnalysesController
export const UploadsController: FC<{}> = ({ children }) => {
  const api = useApi()
  const project = useProject()
  const { confirm } = useAlertConfirmPrompt()

  // init store
  const [useStore] = useState<UseUploadsStore>(() =>
    zustand<UploadsState>((set, get) => ({
      uploads: undefined,
      selectedUpload: undefined,
      selectedUploadLoading: false,
      selectedUploadLoadProgress: 0,
      selectedUploadError: undefined,
      selectedUploadPageCount: undefined,
      selectedUploadPageNumber: 1,
      fetchUploads: () => {},
      createUpload: async (file) => {
        await confirm({
          title: `Upload ${file.name}?`,
          description: 'This may take a few minutes for large files',
        })

        const { data } = await api.uploadsApi.projectsUploadsInit({
          projectId: project.id.toString(),
          data: {
            basename: file.name,
            content_type: file.type,
          },
        })

        const formData = new FormData()

        for (let k in data.presigned_post.fields) {
          formData.append(k, data.presigned_post.fields[k])
        }

        formData.append('file', file)

        await axios.post(data.presigned_post.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        await api.uploadsApi.projectsUploadsConfirm({
          id: data.upload.id!.toString(),
          projectId: project.id.toString(),
        })

        get().fetchUploads()

        return
      },
      deleteUpload: async (upload) => {
        await confirm({
          title: `Are you sure you want to delete ${upload.basename}?`,
        })

        await api.uploadsApi.projectsUploadsDelete({
          id: upload.id!.toString(),
          projectId: project.id.toString(),
        })
      },
    }))
  )

  const { data, refetch } = useQuery(
    ['uploadsApi.projectsUploadsList', project.name],
    async () => {
      const resp = await api.uploadsApi.projectsUploadsList({
        projectId: project.id.toString(),
      })
      return resp.data
    },
    {
      refetchInterval: 1000 * 60 * 60,
    }
  )

  React.useEffect(() => {
    useStore.setState({ uploads: data })
  }, [data])

  React.useEffect(() => {
    useStore.setState({ fetchUploads: refetch })
  }, [refetch])

  React.useEffect(() =>
    useStore.subscribe(
      (selectedUpload) => {
        useStore.setState({
          selectedUploadLoading: true,
          selectedUploadPageCount: undefined,
          selectedUploadPageNumber: 1,
        })
      },
      (state) => state.selectedUpload
    )
  )

  React.useEffect(() =>
    useStore.subscribe(
      () => {
        useStore.setState({
          selectedUploadPageNumber: 1,
        })
      },
      (state) => state.selectedUploadPageCount
    )
  )

  return (
    <UploadsContext.Provider value={useStore}>
      {children}
    </UploadsContext.Provider>
  )
}
