import Axios from 'axios'
import React from 'react'
import { useInterval } from 'react-use'
import { Stream } from '../api/codegen/typescript-axios'
import { PublicStreamExtended } from '../api/StreamExtended'

export default function useStreamLiveStatus(
  stream: Stream | PublicStreamExtended
): boolean {
  const [liveStatus, setLiveStatus] = React.useState(true)

  const checkStreamStatus = React.useCallback(() => {
    if (stream.url) {
      Axios.get(
        stream.url.split('/').slice(0, -1).concat(['status.txt']).join('/')
      )
        .then((resp) => {
          if (resp.data === 1) {
            setLiveStatus(true)
          } else {
            setLiveStatus(false)
          }
        })
        .catch(() => {
          setLiveStatus(false)
        })
    }
  }, [stream.url])

  // only run once on mount
  React.useEffect(() => {
    checkStreamStatus()
  }, [stream.url])

  // Check live status endpoint
  useInterval(checkStreamStatus, 10000)

  return liveStatus
}
