import { useTheme } from '@material-ui/core'
import produce from 'immer'
import { DateTime } from 'luxon'
import React, { FC, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import zustand, { UseStore } from 'zustand'
import {
  ShoringBay,
  ShoringBayRelation,
  StreamCoordinates,
  UploadCoordinates,
} from '../../../api/codegen/typescript-axios'
import {
  extendBay,
  serializeBay,
  ShoringBayExtended,
} from '../../../api/ShoringBayExtended'
import { createCtx } from '../../../helpers/createCtx'
import { useAlertConfirmPrompt } from '../../AlertConfirmPrompt'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { useAnalysesStore } from '../AnalysesController'
import { useUploadsStore } from '../Uploads/UploadsController'
import { getUseVODStore } from '../VODController'
import { getLatestPredecesssorEndDateTime } from './getLatestPredecesssorEndDateTime'

export type ShoringState = {
  bays: Map<number, ShoringBayExtended>
  relationships: Map<string, ShoringBayRelation>
  selectedBayId?: number
  hoveredBayId?: number
  nodeTypes: Map<string | undefined | null, string>
  defaultTeam?: string
  defaultWorkerCount?: number
  showOverlay: boolean
  overlayMouseMode: 'pan' | 'create' | 'select'
  linkMode: 'none' | 'link predecessors' | 'link successors'
  createWithTimes: 'none' | 'start' | 'end' | 'series'
  setSeriesLocationWhen: 'start' | 'end'
  startedSeriesBayId: number | undefined
  teams: Map<string, string>
  overlaySettings: {
    radius: number
    background: string
    noTimes: string
    partialTimes: string
    bothTimes: string
    selected: string
    related: string
  }
  setOverlaySettings: (
    settings: Partial<ShoringState['overlaySettings']>
  ) => void
  fetchBays: () => void
  onClickNode: (bayId: number) => boolean
  sortBays: () => void
  addBay: (args: {
    streamCoord?: {
      stream_id: number
      x: number
      y: number
    }
    uploadCoord?: {
      upload_id: number
      page_number: number
      x: number
      y: number
    }
    startDateTime?: DateTime
  }) => void
  saveSelectedBay: (
    attributes: Partial<
      ShoringBayExtended & {
        uploadCoordinate: UploadCoordinates
        streamCoordinate: StreamCoordinates
      }
    >
  ) => void
  deleteBay: () => void
  addRelationship: ({
    predecessorId,
    successorId,
  }: {
    predecessorId: number
    successorId: number
  }) => void
  deleteRelationship: ({
    predecessorId,
    successorId,
  }: {
    predecessorId: number
    successorId: number
  }) => void
  // setTempRelationship: () => void
  // saveTempRelationship: (
  //   attributes: Partial<
  //     ShoringBayExtended & {
  //       uploadCoordinate: UploadCoordinates
  //       streamCoordinate: StreamCoordinates
  //     }
  //   >
  // ) => void
  exportCSV: (format: string) => void
}

export type UseShoringStore = UseStore<ShoringState>

export const [useShoringStore, ShoringContext] = createCtx<UseShoringStore>()

function getDefaultNodeTypes() {
  return new Map([
    [undefined, '#aaa'],
    [null, '#aaa'],
    ['', '#aaa'],
  ])
}

// Must be a descendant of AnalysesController
export const VODShoringController: FC = ({ children }) => {
  const api = useApi()
  const theme = useTheme()
  const project = useProject()
  const useVODStore = getUseVODStore()
  const analysesStore = useAnalysesStore()
  const uploadsStore = useUploadsStore()

  const analysisId = analysesStore((state) => state.analysisId)

  const { alert } = useAlertConfirmPrompt()

  const [useStore] = useState(() =>
    zustand<ShoringState>((set, get) => ({
      bays: new Map<number, ShoringBayExtended>(),
      relationships: new Map<string, ShoringBayRelation>(),
      selectedBayId: undefined,
      hoveredBayId: undefined,
      nodeTypes: getDefaultNodeTypes(),
      showOverlay: false,
      overlayMouseMode: 'pan',
      linkMode: 'none',
      createWithTimes: 'end',
      setSeriesLocationWhen: 'start',
      startedSeriesBayId: undefined,
      teams: new Map<string, string>(),
      overlaySettings: {
        radius: 10,
        background: 'rgba(0, 0, 0, 0.5)',
        noTimes: 'rgba(255, 0, 0, 0.7)',
        partialTimes: 'rgba(255, 255, 0, 0.7)',
        bothTimes: 'rgba(0, 255, 0, 0.7)',
        selected: 'rgba(0, 255, 255, 1)',
        related: '#f8f',
      },
      setOverlaySettings: (settings) => {
        set(
          produce((draft: ShoringState) => {
            draft.overlaySettings = { ...get().overlaySettings, ...settings }
          })
        )
      },
      fetchBays: async () => {
        const analysisId = analysesStore.getState().analysisId
        if (!analysisId) return

        const fetchBays = api.shoringApi.videoannotationAnalysesShoringbaysList(
          {
            analysisId: analysisId.toString(),
          }
        )

        const fetchRelationships = api.shoringApi.videoannotationAnalysesShoringbayrelationsList(
          {
            analysisId: analysisId.toString(),
          }
        )

        const baysResp = await fetchBays

        const bays = new Map<number, ShoringBayExtended>()
        const oldTeams = get().teams
        const teams = new Map<string, string>()
        const nodeTypes = getDefaultNodeTypes()
        const colors = new Set(theme.custom.chartColorsCategorical)

        oldTeams.forEach((oldTeam) => colors.delete(oldTeam))

        const allBays = baysResp.data
          .map((bay) => {
            return extendBay(bay, project)
          })
          .reverse()

        allBays.forEach((bay) => {
          nodeTypes.set(bay.node_type, '')

          bays.set(bay.id, bay)

          if (bay.team && !teams.get(bay.team)) {
            let newTeamColor
            const oldTeamColor = oldTeams.get(bay.team)
            if (oldTeamColor) {
              newTeamColor = oldTeamColor
            } else {
              newTeamColor = colors.values().next().value
            }
            teams.set(bay.team, newTeamColor)
            colors.delete(newTeamColor)
          }
        })

        Array.from(nodeTypes.keys()).forEach((nodeType, i) => {
          if (nodeType) {
            nodeTypes.set(nodeType, theme.custom.chartColorsCategorical[i])
          }
        })

        const relationsResp = await fetchRelationships

        relationsResp.data.forEach((rel) => {
          const predecessor = bays.get(rel.predecessor)
          const successor = bays.get(rel.successor)
          if (!predecessor || !successor) {
            throw 'ShoringRelationship connected to phantom node'
          }
          predecessor.successors.add(successor.id)
          successor.predecessors.add(predecessor.id)
        })

        set(
          produce((draft: ShoringState) => {
            draft.bays = bays
            draft.nodeTypes = nodeTypes
            draft.teams = teams
            relationsResp.data.forEach((rel) => {
              draft.relationships.set(
                `${rel.predecessor},${rel.successor}`,
                rel
              )
            })
          })
        )

        get().sortBays()
      },
      onClickNode: (bayId: number) => {
        // The return value of this function serves as a sort of
        // stopPropagation/preventDefault for the remaining lines of code in the on-node click handler
        // For example returning false prevents timeline node clicks from calling gotoVideo
        const {
          bays,
          selectedBayId,
          overlayMouseMode,
          linkMode,
          saveSelectedBay,
          addRelationship,
        } = get()
        const selectedBay = selectedBayId ? bays.get(selectedBayId) : undefined
        const bay = bays.get(bayId)

        if (!bay) {
          // shouldn't happen
          throw 'bay not found'
        }

        if (
          overlayMouseMode === 'create' &&
          bay.startDateTime &&
          !bay.endDateTime
        ) {
          saveSelectedBay({
            endDateTime: useVODStore.getState().videoDateTime,
          })
          set(
            produce((draft) => {
              draft.selectedBayId = bay.id
            })
          )
          return false
        } else if (overlayMouseMode === 'select') {
          if (linkMode === 'none') {
            set(
              produce((draft) => {
                draft.selectedBayId = bay.id
              })
            )
          } else if (linkMode === 'link predecessors' && selectedBay) {
            addRelationship({
              predecessorId: bay.id,
              successorId: selectedBay.id,
            })
          } else if (linkMode === 'link successors' && selectedBay) {
            addRelationship({
              predecessorId: selectedBay.id,
              successorId: bay.id,
            })
          }
          return false
        }
        return true
      },
      sortBays: () => {
        let showIndexChangeAlert = false
        const oldBays = get().bays

        set(
          produce((draft: ShoringState) => {
            const baysArray = Array.from(draft.bays.values())
            const teamTallies: Record<string, number> = {}

            baysArray.forEach((b) =>
              getLatestPredecesssorEndDateTime(b.id, draft.bays, project)
            )

            baysArray.sort((b1, b2) => {
              if (!b1.inferredEndDateTime || !b2.inferredEndDateTime) {
                // shouldn't happen
                throw 'missing endDateTime or inferredEndDateTime'
              }

              const b1Millis = b1.inferredEndDateTime.valueOf()
              const b2Millis = b2.inferredEndDateTime.valueOf()

              if (b1Millis === b2Millis) {
                return b1.id - b2.id
                // if (!b1.startDateTime) {
                //   return 1
                // } else if (!b2.startDateTime) {
                //   return -1
                // }
              }

              return b1Millis - b2Millis
            })

            baysArray.forEach((bay) => {
              if (bay.team) {
                if (!teamTallies.hasOwnProperty(bay.team)) {
                  teamTallies[bay.team] = 0
                }
                teamTallies[bay.team] = teamTallies[bay.team] + 1
                bay.teamIndex = teamTallies[bay.team]

                const oldBay = oldBays.get(bay.id)

                if (
                  oldBay &&
                  oldBay.teamIndex !== undefined &&
                  oldBay.teamIndex !== bay.teamIndex
                ) {
                  showIndexChangeAlert = true
                }
              }
            })
            draft.bays = new Map(baysArray.map((bay) => [bay.id, bay]))
          })
        )

        if (showIndexChangeAlert) {
          alert({
            title:
              'The bay numbers have changed! Be sure to update the spreadsheet with new numbers',
          })
        }
      },
      addBay: async ({ streamCoord, uploadCoord }) => {
        const analysisId = analysesStore.getState().analysisId
        const currentTime = useVODStore.getState().videoDateTime
        const {
          createWithTimes,
          startedSeriesBayId,
          setSeriesLocationWhen,
        } = get()

        if (!analysisId) {
          throw 'missing analysisId, bays array or videoStreamId'
        }

        const currentTimeString = currentTime.toISO({
          suppressMilliseconds: true,
        })

        const currentCoordinates = {
          stream_coordinates: streamCoord ? [streamCoord] : [],
          upload_coordinates: uploadCoord ? [uploadCoord] : [],
        }

        let previousBayParams: ShoringBay | undefined =
          createWithTimes === 'series' && startedSeriesBayId
            ? {
                id: startedSeriesBayId,
                end_time: currentTimeString,
              }
            : undefined

        let newBayParams: ShoringBay = {
          // TS appeasement, not actually used
          id: 0,
          team: get().defaultTeam,
          worker_count: get().defaultWorkerCount,
        }

        if (createWithTimes === 'none') {
          // create new bay with no times
          newBayParams = {
            ...newBayParams,
            ...currentCoordinates,
          }
        } else if (createWithTimes === 'start') {
          // create new bay with start time
          newBayParams = {
            ...newBayParams,
            ...currentCoordinates,
            start_time: currentTimeString,
          }
        } else if (createWithTimes === 'end') {
          // create new bay with end time
          newBayParams = {
            ...newBayParams,
            ...currentCoordinates,
            end_time: currentTimeString,
          }
        } else if (createWithTimes === 'series') {
          if (previousBayParams) {
            // complete existing bay...
            if (setSeriesLocationWhen === 'start') {
              // ...with end time only
              previousBayParams = {
                ...previousBayParams,
              }
            } else if (setSeriesLocationWhen === 'end') {
              // ...with end time and coordinates
              previousBayParams = {
                ...previousBayParams,
                ...currentCoordinates,
              }
            }
          }

          // start new bay...
          if (setSeriesLocationWhen === 'start') {
            // ...with start time and coordinates
            newBayParams = {
              ...newBayParams,
              ...currentCoordinates,
              start_time: currentTimeString,
            }
          } else if (setSeriesLocationWhen === 'end') {
            // ...with only start time
            newBayParams = {
              ...newBayParams,
              start_time: currentTimeString,
            }
          }
        }

        if (previousBayParams) {
          await api.shoringApi.videoannotationAnalysesShoringbaysPartialUpdate({
            analysisId: analysisId.toString(),
            id: previousBayParams.id.toString(),
            data: previousBayParams,
          })
        }

        const newBayResp = await api.shoringApi.videoannotationAnalysesShoringbaysCreate(
          {
            analysisId: analysisId.toString(),
            data: newBayParams,
          }
        )

        if (!newBayResp) return

        const newBay = newBayResp.data

        set(
          produce((draft: ShoringState) => {
            draft.bays.set(newBay.id, extendBay(newBay, project))

            if (createWithTimes === 'series') {
              draft.startedSeriesBayId = newBay.id
            }

            if (draft.linkMode === 'none') {
              // Only select this bay if we arent in link mode
              draft.selectedBayId = newBay.id
            } else if (
              draft.linkMode === 'link predecessors' &&
              draft.selectedBayId
            ) {
              draft.addRelationship({
                predecessorId: newBay.id,
                successorId: draft.selectedBayId,
              })
              return false
            } else if (
              draft.linkMode === 'link successors' &&
              draft.selectedBayId
            ) {
              draft.addRelationship({
                predecessorId: draft.selectedBayId,
                successorId: newBay.id,
              })
              return false
            }
          })
        )

        get().fetchBays()
      },
      saveSelectedBay: async (newAttributes) => {
        // newAttributes is an object with any fields from ShoringBayExtended
        // it can also specify individual stream or upload coordinates objects to add/replace in their respective arrays

        set(
          produce((draft: ShoringState) => {
            const bay =
              draft.selectedBayId && draft.bays.get(draft.selectedBayId)

            if (!bay) {
              // shouldnt happen
              throw 'No bay with that ID?'
            }

            if (newAttributes.uploadCoordinate) {
              bay.uploadCoordinates.set(
                newAttributes.uploadCoordinate.upload_id,
                newAttributes.uploadCoordinate
              )
            }

            if (newAttributes.streamCoordinate) {
              bay.streamCoordinates.set(
                newAttributes.streamCoordinate.stream_id,
                newAttributes.streamCoordinate
              )
            }
          })
        )

        delete newAttributes.streamCoordinate
        delete newAttributes.uploadCoordinate

        const { bays, selectedBayId } = get()

        const bay = selectedBayId && bays.get(selectedBayId)

        if (!bay) {
          // shouldnt happen
          throw 'No bay with that ID?'
        }

        const serializedBay: ShoringBay = serializeBay(
          Object.assign({}, bay, newAttributes)
        )

        const analysisId = analysesStore.getState().analysisId

        if (!analysisId) {
          // shouldnt happen
          throw 'no analysis id'
        }

        const resp = await api.shoringApi.videoannotationAnalysesShoringbaysUpdate(
          {
            analysisId: analysisId.toString(),
            id: bay.id.toString(),
            data: serializedBay,
          }
        )

        set(
          produce((draft: ShoringState) => {
            draft.bays.set(bay.id, extendBay(resp.data, project))
          })
        )

        get().fetchBays()
      },
      deleteBay: async () => {
        const { selectedBayId } = get()

        if (!selectedBayId) {
          // shouldnt happen
          throw 'No bayId'
        }

        set(
          produce((draft: ShoringState) => {
            draft.selectedBayId = undefined
            draft.bays.delete(selectedBayId)
          })
        )

        const { analysisId } = analysesStore.getState()

        if (!analysisId) {
          // shouldnt happen
          throw 'No analysisId'
        }

        await api.shoringApi.videoannotationAnalysesShoringbaysDelete({
          analysisId: analysisId.toString(),
          id: selectedBayId.toString(),
        })

        get().fetchBays()
      },
      addRelationship: async ({ predecessorId, successorId }) => {
        if (predecessorId === successorId) {
          throw "You can't link a node to itself"
        }

        const relationshipKey = `${predecessorId},${successorId}`

        if (get().relationships.get(relationshipKey)) {
          throw 'Relationship already exists'
        }

        set(
          produce((draft: ShoringState) => {
            draft.bays.get(predecessorId)?.successors.add(successorId)
            draft.bays.get(successorId)?.predecessors.add(predecessorId)
          })
        )

        const analysisId = analysesStore.getState().analysisId

        if (!analysisId) {
          // Shouldnt happen
          throw 'No analysisId'
        }

        await api.shoringApi.videoannotationAnalysesShoringbayrelationsCreate({
          analysisId: analysisId.toString(),
          data: {
            id: 0, // TS appeasement, not actually used
            predecessor: predecessorId,
            successor: successorId,
          },
        })

        get().fetchBays()
      },
      deleteRelationship: async ({ predecessorId, successorId }) => {
        const relationshipKey = `${predecessorId},${successorId}`

        const analysisId = analysesStore.getState().analysisId
        const relationship = get().relationships.get(relationshipKey)

        if (!analysisId || !relationship) {
          // shouldnt happen
          throw 'No analysisId or relationship found'
        }

        set(
          produce((draft: ShoringState) => {
            draft.relationships.delete(relationshipKey)
            draft.bays.get(predecessorId)?.successors.delete(successorId)
            draft.bays.get(successorId)?.predecessors.delete(predecessorId)
          })
        )

        await api.shoringApi.videoannotationAnalysesShoringbayrelationsDelete({
          analysisId: analysisId.toString(),
          id: relationship.id!.toString(),
        })

        get().fetchBays()
      },
      exportCSV: (format: string) => {
        const currentUpload = uploadsStore.getState().selectedUpload
        const analysisName = analysesStore
          .getState()
          .analyses.find((a) => a.id === analysesStore.getState().analysisId)
          ?.name

        if (!currentUpload) return
        const serialized = Array.from(get().bays.values())
          .map((bay) => {
            const uploadCoordinates = bay.uploadCoordinates.get(
              currentUpload.id!
            )
            if (
              !uploadCoordinates ||
              bay.teamIndex === undefined ||
              !bay.team
            ) {
              return null
            }
            return {
              X: uploadCoordinates.x,
              Y: uploadCoordinates.y,
              'Bay Number': bay.teamIndex.toString(),
              'Bay Crew': bay.team,
            }
          })
          .filter((bay) => bay !== null)
        const workbook = XLSX.utils.book_new()
        const sheet = XLSX.utils.json_to_sheet(serialized)
        XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1')
        XLSX.writeFile(workbook, analysisName + '.' + format)
      },
    }))
  )

  useEffect(() => {
    useStore.subscribe(
      (overlaySettings) => {
        if (overlaySettings !== 'none') {
          useVODStore.setState({ zoomMode: true })
        }
      },
      (state) => state.overlaySettings
    )

    useStore.subscribe(
      () => {
        useStore.setState({ linkMode: 'none' })
      },
      (state) => state.selectedBayId
    )

    // when we switch out of series mode, we want to clear startedSeriesBayId
    // so that it doesnt try to complete it when we switch back into series mode
    useStore.subscribe(
      () => {
        if (useStore.getState().createWithTimes !== 'series') {
          useStore.setState({ startedSeriesBayId: undefined })
        }
      },
      (state) => state.createWithTimes
    )

    return () => {
      useStore.destroy()
    }
  }, [useStore])

  useEffect(() => {
    useStore.getState().fetchBays()
  }, [analysisId])

  return (
    <ShoringContext.Provider value={useStore}>
      {children}
    </ShoringContext.Provider>
  )
}
