import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import union from 'lodash/union'
import without from 'lodash/without'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'
import { parseSearchList } from '../../helpers/parseSearchList'

interface Props {
  groupLabel: string
  options: Array<{ label: string; value: string }>
  queryKey: string
  inputType: 'checkbox' | 'autocomplete'
  showLabel?: boolean
  defaultSelectAll?: boolean
}

export const MultiSelect = ({
  groupLabel,
  options,
  queryKey,
  inputType,
  showLabel = true,
  defaultSelectAll = false,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const param = parseSearchList(searchParams, queryKey)

  const [autocompleteInput, setAutocompleteInput] = useState('')

  useMount(() => {
    if (defaultSelectAll && param.length === 0) {
      searchParams.set(queryKey, options.map((o) => o.value).join(','))
      setSearchParams(searchParams, { replace: true })
    }
  })

  if (inputType === 'checkbox') {
    return (
      <FormControl fullWidth variant="outlined" margin="dense">
        {showLabel && (
          <SelectAll
            groupLabel={groupLabel}
            options={options}
            queryKey={queryKey}
          ></SelectAll>
        )}
        <FormGroup row>
          {options.map((o) => (
            <FormControlLabel
              key={o.value}
              control={
                <Checkbox
                  checked={param.indexOf(o.value) > -1}
                  onChange={(e, v: boolean) => {
                    if (v) {
                      searchParams.set(queryKey, union(param, [o.value]).join())
                    } else {
                      searchParams.set(queryKey, without(param, o.value).join())
                    }
                    setSearchParams(searchParams, { replace: true })
                  }}
                  name={o.label}
                />
              }
              label={o.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    )
  } else {
    return (
      <FormControl fullWidth variant="outlined" margin="dense">
        {showLabel && (
          <SelectAll
            groupLabel={groupLabel}
            options={options}
            queryKey={queryKey}
          ></SelectAll>
        )}
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(o) => o.label}
          renderInput={(params) => (
            <TextField {...params} label="Select " variant="outlined" />
          )}
          value={options.filter((p) => param.indexOf(p.value) > -1)}
          onChange={(event: any, value) => {
            if (value) {
              searchParams.set(queryKey, value.map((o) => o.value).join(','))
              setSearchParams(searchParams, { replace: true })
            }
          }}
          inputValue={autocompleteInput}
          onInputChange={(event, newInputValue) => {
            setAutocompleteInput(newInputValue)
          }}
        />
      </FormControl>
    )
  }
}

const SelectAll = ({
  groupLabel,
  options,
  queryKey,
}: Omit<Props, 'inputType'>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const optionValues = options.map((o) => o.value)
  const param = parseSearchList(searchParams, queryKey)
  const filteredParam = parseSearchList(searchParams, queryKey).filter(
    (value) => optionValues.indexOf(value) > -1
  )
  return (
    <FormGroup row style={{ alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              filteredParam.length === options.length && options.length !== 0
            }
            onChange={(e, v: boolean) => {
              if (v) {
                searchParams.set(
                  queryKey,
                  union(
                    param,
                    options.map((o) => o.value)
                  ).join()
                )
              } else {
                searchParams.set(
                  queryKey,
                  without(param, ...options.map((o) => o.value)).join()
                )
              }
              setSearchParams(searchParams, { replace: true })
            }}
            name={'select all'}
          />
        }
        label={groupLabel}
      />
    </FormGroup>
  )
}
