import React from 'react'
import {
  ListItem,
  List,
  FormControlLabel,
  Checkbox,
  ListSubheader,
  ListItemText,
} from '@material-ui/core'
import { ImageSublabel } from '../../api/codegen/typescript-axios'

export const AnnotatorSublabelList = React.memo(
  ({
    sublabels,
    selectedSublabel,
    setSelectedSublabel,
  }: {
    sublabels: ImageSublabel[]
    selectedSublabel?: ImageSublabel
    setSelectedSublabel: (s?: ImageSublabel) => void
  }) => {
    return (
      <List
        component="div"
        disablePadding
        dense
        subheader={<ListSubheader>Sublabels</ListSubheader>}
      >
        {sublabels.map((sublabel) => {
          return (
            <ListItem
              key={sublabel.id}
              dense
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={selectedSublabel === sublabel}
                    onChange={(e) =>
                      setSelectedSublabel(
                        e.target.checked ? sublabel : undefined
                      )
                    }
                    name="checkedA"
                  />
                }
                label={<ListItemText>{sublabel.name}</ListItemText>}
              />
            </ListItem>
          )
        })}
      </List>
    )
  }
)
