import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Select,
  TextField,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useAsyncFn } from 'react-use'
import { useAlertConfirmPrompt } from '../../AlertConfirmPrompt'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'

export const VideoStabilizationRequester = () => {
  const project = useProject()
  const api = useApi()
  const useVODStore = getUseVODStore()
  const { alert } = useAlertConfirmPrompt()

  const [showStabilizationRequest, setShowStabilizationRequest] = useState(
    false
  )

  const [formState, setFormState] = React.useState<{
    streamId: number
    startTime: string
    endTime: string
  }>(getDefaultState())

  function getDefaultState() {
    return {
      streamId: useVODStore.getState().videoStreamId || project.streams[0].id,
      startTime: useVODStore
        .getState()
        .videoDateTime.setZone('system', { keepLocalTime: true })
        .set({ millisecond: 0 })
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
      endTime: useVODStore
        .getState()
        .videoDateTime.setZone('system', { keepLocalTime: true })
        .set({ millisecond: 0 })
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
    }
  }

  React.useEffect(() => {
    setFormState(getDefaultState())
  }, [showStabilizationRequest])

  const [state, doFetch] = useAsyncFn(async () => {
    const startTime = DateTime.fromISO(
      formState.startTime
    ).setZone(project.timezone, { keepLocalTime: true })
    const endTime = DateTime.fromISO(
      formState.endTime
    ).setZone(project.timezone, { keepLocalTime: true })

    if (endTime.toMillis() === startTime.toMillis()) {
      alert({
        title: 'Did you forget to set an end time?',
        description: 'Start and end time cannot be the same.',
      })
      throw 'Start and end time cannot be the same'
    }

    if (endTime.toMillis() < startTime.toMillis()) {
      alert({
        title: 'End time cannot be before start time!',
      })
      throw 'End time cannot be before start time.'
    }

    await api.videoProcessingApi.streamsVideostabilizationjobsCreate({
      streamId: formState.streamId.toString(),
      data: {
        project: project.id,
        start_time: startTime.toISO(),
        end_time: endTime.toISO(),
      },
    })

    setShowStabilizationRequest(false)

    alert({
      title: 'Request submitted',
      description: 'Please allow some time for the stabilization to process',
    })
  }, [formState])

  return (
    <>
      <Link color="secondary" onClick={() => setShowStabilizationRequest(true)}>
        Request Video Stabilization
      </Link>

      {showStabilizationRequest && (
        <Dialog open={showStabilizationRequest} maxWidth="xs">
          <DialogTitle>Request Video Stabilization</DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  label="Stream"
                  native
                  value={formState.streamId}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      streamId: parseInt(e.target.value as string),
                    })
                  }
                >
                  {project.streams.map((s) => (
                    <option key={s.id} value={s.id.toString()}>
                      {s.name}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Start time"
                  type="datetime-local"
                  defaultValue={formState.startTime}
                  onChange={(e) => {
                    setFormState({ ...formState, startTime: e.target.value })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="End time"
                  type="datetime-local"
                  defaultValue={formState.endTime}
                  onChange={(e) => {
                    setFormState({ ...formState, endTime: e.target.value })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => setShowStabilizationRequest(false)}
              variant="contained"
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                doFetch()
              }}
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
