import {
  createStyles,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { useWindowSize } from 'react-use'
import { useMobile } from '../../hooks/useMobile'
import { DragResizeBox } from '../DragResizeBox'
import { VideoPlayerNative } from '../VideoPlayerNative'
import { CustomTimelapsePanel } from './CustomTimelapsePanel'
import { DailyTimelapsePanel } from './DailyTimelapsePanel'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      maxHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    topControl: {
      padding: theme.spacing(1),
      border: '1px solid',
      borderColor: theme.palette.divider,
    },
    moduleRow: {
      flexGrow: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      overflowY: 'hidden',
      overflowX: 'auto',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    pickers: {
      height: '100%',
      userSelect: 'none',
      overflow: 'hidden',
      padding: '6px 6px 0',
      color: '#fff',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    thumbnails: {
      display: 'flex',
      margin: '10px -5px 0',
      padding: 0,
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        overflow: 'auto',
      },
    },
    thumbnail: {
      listStyle: 'none',
      display: 'block',
      width: '100%',
      cursor: 'pointer',
      padding: '5px',
      transition: 'opacity 0.1s',
    },
    thumbnailInner: {
      paddingTop: '56.25%',
      backgroundSize: 'cover',
      position: 'relative',
      '&:hover': {
        outline: `1px solid ${theme.palette.primary.main}`,
      },
    },
    selectedThumbnail: {
      outline: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
    streamName: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      color: '#fff',
      width: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0,
      padding: 2,
    },
  })
)

export const timelapseQueryKeys = {
  date: 'date',
  stream: 'stream',
  custom: 'custom',
}

export interface GenericTimelapseVideo {
  url: string
  title: string
}

export const TimelapsePage = (props: {}) => {
  const classes = useStyles(props)
  const theme = useTheme()
  const isMobile = useMobile()

  const { height } = useWindowSize()

  const [video, setVideo] = React.useState<GenericTimelapseVideo>()

  return (
    <div
      className={classes.root}
      style={{ height: height - theme.custom.projectNavHeight }}
    >
      <div className={classes.moduleRow}>
        <div className={classes.main}>
          {isMobile ? (
            <DailyTimelapsePanel video={video} setVideo={setVideo} />
          ) : (
            <div style={{ padding: theme.spacing(1) }}>
              <Typography
                variant="h4"
                style={{ display: 'inline', marginRight: 10 }}
              >
                Timelapse Studio
              </Typography>
            </div>
          )}

          {video && (
            <VideoPlayerNative
              video={video}
              extraControls={{
                download: true,
                playbackRates: [1, 2, 4, 8, 12],
              }}
            ></VideoPlayerNative>
          )}
        </div>

        {isMobile ? (
          <></>
        ) : (
          <>
            <DragResizeBox
              handleSide="left"
              defaultSize={200}
              minSize={150}
              localStorageKey="timelapses-streams-panel"
            >
              <DailyTimelapsePanel video={video} setVideo={setVideo} />
            </DragResizeBox>

            <DragResizeBox
              handleSide="left"
              defaultSize={600}
              minSize={500}
              localStorageKey="timelapses-multiday-panel"
            >
              <CustomTimelapsePanel video={video} setVideo={setVideo} />
            </DragResizeBox>
          </>
        )}
      </div>
    </div>
  )
}
