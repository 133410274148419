import { PublicStreamExtended, StreamExtended } from '../../api/StreamExtended'
import { Message, VideoErrorMessages } from './VideoErrorMessages'

export const LivePlayerErrorMessages = ({
  stream,
  liveStatus,
}: {
  stream: StreamExtended | PublicStreamExtended
  liveStatus: boolean
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '100%',
        padding: '6px',
        pointerEvents: 'none',
      }}
    >
      <VideoErrorMessages
        messages={([] as Message[]).concat(
          stream.computed_error_messages?.map((msg) => ({
            text: msg,
            color: 'red',
          })) || [],
          !liveStatus
            ? [
                {
                  text: 'The stream is currently offline',
                  color: 'yellow',
                },
              ]
            : [],
          stream.computed_warning_messages?.map((msg) => ({
            text: msg,
            color: 'blue',
          })) || []
        )}
      />
    </div>
  )
}
