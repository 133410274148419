import { useEffect } from 'react'
import useRefCurrent from './useRefCurrent'
import { useDevicePixelRatio } from './useDevicePixelRatio'

export function useSizeCanvas(width: number, height: number) {
  const [ref, el] = useRefCurrent<HTMLCanvasElement>(null)

  const devicePixelRatio = useDevicePixelRatio()

  useEffect(() => {
    if (!el) return

    el.width = width * devicePixelRatio
    el.height = height * devicePixelRatio

    const ctx = el.getContext('2d')

    if (ctx) {
      ctx.transform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0)
    }
  }, [el, width, height])
  return ref
}
