import { useState, useEffect } from 'react'

export default function useVideoState(
  video: HTMLVideoElement | null
): [boolean, () => void, () => void] {
  const [playState, setPlayState] = useState(
    !!video && !video.paused && !video.ended
  )

  const onPlay = () => setPlayState(true)
  const onPause = () => setPlayState(false)

  useEffect(() => {
    if (!video) return
    if (video.paused || video.ended) {
      onPause()
    } else {
      onPlay()
    }

    video.addEventListener('play', onPlay)
    video.addEventListener('pause', onPause)
    video.addEventListener('ended', onPause)
    return () => {
      video.removeEventListener('play', onPlay)
      video.removeEventListener('pause', onPause)
      video.removeEventListener('ended', onPause)
    }
  }, [video])
  return [playState, () => video && video.play(), () => video && video.pause()]
}
