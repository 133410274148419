import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsyncFn, useAsyncRetry } from 'react-use'
import { extendClip } from '../../api/ClipExtended'
import { Clip } from '../../api/codegen/typescript-axios'
import { validateXYWH } from '../../helpers/validateXYWH'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { useProject } from '../ProjectWrapper'
import { useAnalysesStore } from './AnalysesController'
import { AnalysisSelect } from './AnalysisSelect'
import { VODClipEditor } from './VODClipEditor'
import { useClipsStore } from './VODClipsController'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        padding: '12px 6px',
        overflowX: 'hidden',
        color: '#fff',
      },
    }),
  {
    name: 'VODClips',
  }
)

export const VODClips = () => {
  const classes = useStyles()
  const api = useApi()
  const project = useProject()
  const analysesStore = useAnalysesStore()
  const useVODStore = getUseVODStore()
  const clipsStore = useClipsStore()

  const analysisId = analysesStore((state) => state.analysisId)
  const clips = clipsStore((state) => state.clips)
  const clipId = clipsStore((state) => state.clipId)
  const clip = clipsStore((state) => state.clip)

  const [minDate, setMinDate] = useState<DateTime>(
    DateTime.local().minus({ weeks: 1 }).startOf('week')
  )

  useEffect(() => {
    if (clip) {
      console.log(clip)
      if (clip.analysis) {
        console.log('clip effect setAnalysisId', clip.analysis)
        analysesStore.setState({ analysisId: clip.analysis })
      }
      setMinDate((minDate) =>
        DateTime.fromMillis(
          Math.min(
            minDate.valueOf(),
            DateTime.fromISO(clip.start_datetime).valueOf()
          )
        )
      )
    }
  }, [clip])

  interface ClipGroup {
    label: string
    clips: Clip[]
  }

  const clipGroups: ClipGroup[] | undefined = useMemo(() => {
    if (!clips) return

    const clipGroupsMap: Record<string, ClipGroup> = {}
    const clipGroupsArray: ClipGroup[] = []
    const minDateProject = minDate
      .setZone(project.timezone, { keepLocalTime: true })
      .startOf('day')
      .valueOf()
    let filteredClips = clips
    if (minDate) {
      filteredClips = clips.filter(
        (c) => DateTime.fromISO(c.start_datetime).valueOf() > minDateProject
      )
    }

    filteredClips.forEach((c, i) => {
      if (c.grouping) {
        if (!clipGroupsMap[c.grouping]) {
          clipGroupsMap[c.grouping] = {
            label: c.grouping,
            clips: [c],
          }
          clipGroupsArray.push(clipGroupsMap[c.grouping])
        } else {
          clipGroupsMap[c.grouping].clips.push(c)
        }
      } else {
        const key = '_' + c.id!.toString()
        clipGroupsMap[key] = {
          label: key,
          clips: [c],
        }
        clipGroupsArray.push(clipGroupsMap[key])
      }
    })
    return clipGroupsArray
  }, [clips, minDate, project.timezone])

  const [createClipState, createClip] = useAsyncFn(async () => {
    const { video, speed } = useVODStore.getState()
    if (!video || !analysisId || !clips) return
    const resp = await api.videoAnnotationsApi.videoannotationAnalysesClipsCreate(
      {
        analysisId: analysisId.toString(),
        data: {
          stream: video.stream.id,
          title: 'untitled',
          start_datetime: useVODStore.getState().videoDateTime.toISO(),
          speed: speed,
          ...validateXYWH(useVODStore.getState().videoViewport),
        },
      }
    )
    clips.push(extendClip(resp.data, project))
    clipsStore.getState().setClip(resp.data.id!)
    clipsStore.getState().fetchClips()
  }, [analysisId, clips])

  return (
    <div className={classes.root} onKeyDown={(e) => e.stopPropagation()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          Clips
        </Grid>

        <Grid item xs={12}>
          <AnalysisSelect />
        </Grid>

        {/* Date filter */}
        {analysisId && (
          <Grid item xs={12}>
            <DatePicker
              value={minDate}
              onChange={(date) => {
                if (date) {
                  setMinDate(date)
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  helperText=""
                  fullWidth
                />
              )}
              disableFuture
            />
          </Grid>
        )}

        {clips && (
          <>
            {/* Clip Select */}
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Clip</InputLabel>
                <Select
                  native
                  value={clipId || 0}
                  onChange={(e) => {
                    clipsStore
                      .getState()
                      .setClip(parseInt(e.target.value as string))
                  }}
                  label="Clip"
                >
                  <option key={'undefined'} value={0}>
                    Select clip
                  </option>
                  {clipGroups &&
                    clipGroups.map((cg) =>
                      cg.label.indexOf('_') !== 0 ? (
                        <optgroup label={cg.label} key={cg.label}>
                          {cg.clips.map((c) => (
                            <option key={c.id} value={c.id}>
                              {`
                              ${DateTime.fromISO(c.start_datetime)
                                .setZone(project.timezone)
                                .toFormat('HH:mm')}
                              ${c.featured ? '*' : ''}
                              ${c.title}`}
                            </option>
                          ))}
                        </optgroup>
                      ) : (
                        <option key={cg.clips[0].id} value={cg.clips[0].id}>
                          {`
                          ${DateTime.fromISO(cg.clips[0].start_datetime)
                            .setZone(project.timezone)
                            .toFormat('HH:mm')}
                          ${cg.clips[0].featured ? '*' : ''}
                          ${cg.clips[0].title}
                          `}
                        </option>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>

            {/* Create New Button */}
            <Grid item xs={12}>
              <ProgressButton
                variant="contained"
                fullWidth
                onClick={createClip}
                fetchState={createClipState}
              >
                Create New
              </ProgressButton>
            </Grid>
          </>
        )}
        {analysisId && <VODClipEditor />}
      </Grid>
    </div>
  )
}
