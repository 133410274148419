import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { useQuery } from 'react-query'
import { useAsyncFn } from 'react-use'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { useProject } from '../ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '10px auto',
      maxWidth: 700,
    },
    button: { margin: '10px auto' },
    showSchedule: { cursor: 'pointer' },
    day: {
      position: 'relative',
      marginTop: 20,
    },
    dayLabel: { textAlign: 'left' },
    dayBlock: {
      position: 'relative',
      height: 20,
      overflow: 'hidden',
      backgroundColor: theme.palette.success.main,
    },
    armBlock: {
      position: 'absolute',
      top: 0,
      height: '100%',
      backgroundColor: theme.palette.error.main,
      borderWidth: '0 3px 0',
      borderColor: theme.palette.background.default,
      borderStyle: 'solid',
    },
    svg: {
      display: 'block',
      position: 'relative',
    },
  })
)

export const SecuritySchedule = (props: {}) => {
  const classes = useStyles(props)
  const api = useApi()
  const project = useProject()

  const { data: surroundingSchedule, refetch } = useQuery(
    ['projectsSecurityScheduleSurroundingScheduleRead', project.id],
    async () => {
      const resp = await api.securityApi.projectsSecurityScheduleSurroundingSchedule(
        {
          projectId: project.id.toString(),
        }
      )
      return resp.data
    },
    {
      refetchInterval: 10000,
    }
  )

  const [disableNextEventState, disableNextEvent] = useAsyncFn(async () => {
    const resp = await api.securityApi.projectsSecurityScheduleDisableNextEvent(
      {
        projectId: project.id.toString(),
      }
    )
    await refetch()
    return resp.data
  }, [])

  const [enableNextEventState, enableNextEvent] = useAsyncFn(async () => {
    const resp = await api.securityApi.projectsSecurityScheduleEnableNextEvent({
      projectId: project.id.toString(),
    })
    await refetch()
    return resp.data
  }, [])

  if (!surroundingSchedule) {
    return null
  }

  return (
    <div className={classes.root}>
      {surroundingSchedule.disable_next_event ? (
        <div>
          <Typography variant="body1">
            The upcoming scheduled{' '}
            {surroundingSchedule.next_event.armed ? 'arming' : 'disarming'} at{' '}
            <strong>
              {DateTime.fromISO(surroundingSchedule.next_event.event_datetime, {
                zone: project.timezone,
              }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
            </strong>{' '}
            has been disabled.
          </Typography>
          <Typography variant="body1">
            You will need to manually{' '}
            {surroundingSchedule.next_event.armed ? 'arm' : 'disarm'} the system
            using the above toggle when{' '}
            {surroundingSchedule.next_event.armed ? 'leaving' : 'entering'} the
            job site.
          </Typography>
          <ProgressButton
            buttonClassname={classes.button}
            variant="contained"
            color="primary"
            onClick={enableNextEvent}
            fetchState={enableNextEventState}
          >
            Reenable upcoming change
          </ProgressButton>
        </div>
      ) : (
        <div>
          <Typography variant="body1">
            The security system is scheduled to{' '}
            {surroundingSchedule.next_event.armed ? 'arm' : 'disarm'} itself at{' '}
            <strong>
              {DateTime.fromISO(surroundingSchedule.next_event.event_datetime, {
                zone: project.timezone,
              }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
            </strong>{' '}
          </Typography>
          <Typography variant="body1">
            Click below to disable this upcoming change.
          </Typography>
          <ProgressButton
            buttonClassname={classes.button}
            variant="contained"
            color="primary"
            onClick={disableNextEvent}
            fetchState={disableNextEventState}
          >
            Disable upcoming change
          </ProgressButton>
        </div>
      )}
    </div>
  )
}
