import { createStyles, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import Konva from 'konva'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Image, Layer, Stage } from 'react-konva'
import { useMeasure } from 'react-use'
import { useCallbackRef } from 'use-callback-ref'
import { StreamExtended } from '../../api/StreamExtended'
import { getContainTransform } from '../../helpers/getContainTransform'
import { mixins } from '../../styles/mixins'
import { AntMediaWebRTCPlayer } from '../AntMediaWebRTCPlayer'
import { PTZDial } from '../PTZDial'
import { VideoControls } from './VideoControls'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
    },
    videoContainer: {
      flexGrow: 1,
      position: 'relative',
    },
    ptzContainer: {
      ...mixins.absoluteFill,
    },
  })
)

export const VideoModal: FunctionComponent<{
  video: HTMLVideoElement
  stream: StreamExtended
  ptz?: boolean
  openWithPtz?: boolean
  slideshowState?: boolean
  setSlideshowState?: (b: boolean) => void
  slideshowInterval?: number
  setSlideshowInterval?: (n: number) => void
  nextVideo?: () => void
  prevVideo?: () => void
}> = React.memo(
  ({
    children,
    video,
    stream,
    ptz = false,
    openWithPtz = false,
    slideshowState,
    setSlideshowState,
    slideshowInterval,
    setSlideshowInterval,
    nextVideo,
    prevVideo,
  }) => {
    const classes = useStyles()

    const [measuringRef, containerDims] = useMeasure<HTMLDivElement>()
    const [ptzState, setPtzState] = useState(openWithPtz)
    const [rtcState, setRtcState] = useState(openWithPtz && !!stream.ant_id)
    const [error, setError] = useState('')
    const animationRef = useRef<Konva.Animation>()

    useEffect(() => {
      return () => {
        if (animationRef.current) {
          animationRef.current.stop()
        }
      }
    }, [])

    const imageRef = useCallbackRef<Konva.Image>(null, () => {
      if (imageRef.current) {
        const layer = imageRef.current.getLayer()
        animationRef.current = new Konva.Animation(() => {}, layer)
        animationRef.current.start()
      }
    })

    // const videoAspectRatio = video.videoWidth / video.videoHeight
    // const containerAspectratio = containerDims.width / containerDims.height
    const decomposedBaseTransform = getContainTransform({
      parent: { w: containerDims.width, h: containerDims.height },
      child: { w: video.videoWidth, h: video.videoHeight },
    }).decompose()

    return (
      <div className={clsx(classes.root)}>
        <div ref={measuringRef} className={classes.videoContainer}>
          <Stage width={containerDims.width} height={containerDims.height}>
            <Layer {...decomposedBaseTransform}>
              <Image
                image={video}
                ref={imageRef}
                x={0}
                y={0}
                width={video?.videoWidth}
                height={video?.videoHeight}
              />
            </Layer>
            {children}
          </Stage>
          <div className={classes.ptzContainer}>
            {rtcState && (
              <AntMediaWebRTCPlayer
                stream={stream}
                setError={(error: string) => {
                  setError(error)
                  setRtcState(false)
                }}
              />
            )}
            {ptzState && (
              <PTZDial
                width={containerDims.width}
                height={containerDims.height}
                stream={stream}
              />
            )}
            {error && (
              <Alert
                severity="error"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                }}
              >
                {error}
              </Alert>
            )}
          </div>
        </div>
        <VideoControls
          stream={stream}
          ptzState={ptzState}
          setPtzState={
            ptz
              ? () => {
                  setPtzState(!ptzState)
                  setSlideshowState && setSlideshowState(false)
                }
              : undefined
          }
          rtcState={rtcState}
          setRtcState={
            stream.ant_id && stream.ptz
              ? (state) => {
                  setRtcState(state)
                }
              : undefined
          }
          slideshowState={slideshowState}
          setSlideshowState={setSlideshowState}
          slideshowInterval={slideshowInterval}
          setSlideshowInterval={setSlideshowInterval}
          nextVideo={nextVideo}
          prevVideo={prevVideo}
        />
      </div>
    )
  }
)
