import { useTheme } from '@material-ui/core'
import { Group, Rect, Text } from 'react-konva'
import { CraneCalendarEntryExtended } from '../../../api/CraneCalendarEntryExtended'
import { CranePickExtended } from '../../../api/CranePickExtended'
import { useUseTimelineStore } from '../Timeline/Timeline'
import { TimelineCollapsibleSection } from '../Timeline/TimelineCollapsibleSection'
import { TimelineRow } from '../Timeline/TimelineRow'
import { TimelineRowContent } from '../Timeline/TimelineRowContent'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'

export const CraneTimeline = ({ rowGroupIndex }: { rowGroupIndex: number }) => {
  const craneStore = useCraneStore()
  const picks = craneStore((state) => state.picks)
  const calendarEntries = craneStore((state) => state.calendarEntries)
  const subcontractors = craneStore((state) => state.subcontractors)

  const useTimelineStore = useUseTimelineStore()
  const rowHeight = useTimelineStore((state) => state.rowHeight)

  const filteredSubcontractors = Array.from(subcontractors.values())
    .map((subcontractor, i) => {
      return {
        name: subcontractor.name,
        calendarEntries: calendarEntries.filter(
          (entry) => entry.subcontractor_link === subcontractor.id
        ),
        picks: picks.filter(
          (pick) => pick.subcontractor_id === subcontractor.id
        ),
      }
    })
    .filter((sub) => sub.calendarEntries.length > 0 || sub.picks.length > 0)

  return (
    <TimelineCollapsibleSection
      expandedTitle={'Crane Picks'}
      collapsedTitle={'Crane Picks'}
      rowGroupIndex={rowGroupIndex}
      childRowCount={picks.length}
      initialCollpased={false}
    >
      {(collapsed) =>
        collapsed ? (
          <Group>
            {/* <TimelineRow key={'crane-picks'} y={0} i={0}> */}
            {picks.map((pick) => (
              <TimelinePick key={pick.id} pick={pick} collapsed={true} />
            ))}
            {/* </TimelineRow> */}
          </Group>
        ) : (
          <Group>
            {filteredSubcontractors.map((subcontractor, i) => {
              return calendarEntries.length > 0 ? (
                <Group key={subcontractor.name}>
                  <TimelineRow
                    key={`${subcontractor.name}-as-planned`}
                    y={rowHeight * i * 2}
                    i={i * 2}
                  >
                    <TimelineRowContent
                      labelText={`${
                        subcontractor.name || 'Unspecified'
                      } AS-PLANNED`}
                    >
                      {subcontractor.calendarEntries.map((entry) => (
                        <CalendarEntry entry={entry} />
                      ))}
                    </TimelineRowContent>
                  </TimelineRow>
                  <TimelineRow
                    key={`${subcontractor.name}-as-build`}
                    y={rowHeight * (i * 2 + 1)}
                    i={i * 2 + 1}
                  >
                    <TimelineRowContent
                      labelText={`${
                        subcontractor.name || 'Unspecified'
                      } AS-BUILT`}
                    >
                      {subcontractor.picks.map((pick) => (
                        <TimelinePick
                          key={pick.id}
                          pick={pick}
                          collapsed={true}
                        />
                      ))}
                    </TimelineRowContent>
                  </TimelineRow>
                </Group>
              ) : (
                <TimelineRow key={subcontractor.name} y={rowHeight * i} i={i}>
                  <TimelineRowContent
                    labelText={`${
                      subcontractor.name || 'Unspecified'
                    } AS-BUILT`}
                  >
                    {subcontractor.picks.map((pick) => (
                      <TimelinePick
                        key={pick.id}
                        pick={pick}
                        collapsed={true}
                      />
                    ))}
                  </TimelineRowContent>
                </TimelineRow>
              )
            })}
          </Group>
        )
      }
    </TimelineCollapsibleSection>
  )
}

const CalendarEntry = ({ entry }: { entry: CraneCalendarEntryExtended }) => {
  const useTimelineStore = useUseTimelineStore()
  const vodStore = getUseVODStore()
  const craneStore = useCraneStore()

  const rowHeight = useTimelineStore((state) => state.rowHeight)
  const currentScale = useTimelineStore((state) => state.currentScale)

  const x = Math.floor(currentScale(entry.systemStartDateTime))
  const x1 = Math.floor(currentScale(entry.systemEndDateTime))
  const width = x1 - x

  return (
    <Group x={x} y={0}>
      <Rect
        fill={entry.subcontractor_attrs?.color}
        x={1}
        y={0}
        width={width - 1}
        height={rowHeight - 1}
        onClick={() => {
          vodStore.getState().gotoVideo({
            dateTime: entry.startDateTime.plus({
              milliseconds: Math.random() * 1000,
            }),
          })
        }}
      />
    </Group>
  )
}

const TimelinePick = ({
  pick,
  collapsed,
}: {
  pick: CranePickExtended
  collapsed: boolean
}) => {
  const theme = useTheme()
  const useTimelineStore = useUseTimelineStore()
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()

  const rowHeight = useTimelineStore((state) => state.rowHeight)
  const currentScale = useTimelineStore((state) => state.currentScale)

  const x = Math.floor(currentScale(pick.systemStartDateTime))
  const x1 = Math.floor(currentScale(pick.systemEndDateTime))
  const width = collapsed ? x1 - x : Math.max(x1 - x, 4)

  return (
    <Group x={x} y={0}>
      <Rect
        fill={pick.subcontractor_color}
        x={1}
        y={0}
        width={width - 1}
        height={rowHeight - 1}
        onClick={() => {
          if (pick.type === 'pick') {
            craneStore.getState().setPick(pick)
          } else {
            craneStore.setState({
              pick: undefined,
            })
            vodStore.getState().gotoVideo({
              dateTime: pick.startDateTime.plus({
                milliseconds: Math.random() * 1000,
              }),
              streamId: pick.stream,
            })
          }
        }}
      />
      {pick.segments
        .filter(
          (segment) =>
            segment.name !== 'in_between' && segment.idleDuration.toMillis() > 0
        )
        .map((segment) => {
          const sx = Math.floor(currentScale(segment.systemStartDateTime))
          const sx1 = Math.floor(
            currentScale(segment.systemStartDateTime.plus(segment.idleDuration))
          )
          const w = sx1 - sx
          return (
            <Rect
              key={segment.name}
              fill={theme.palette.error.main}
              x={sx - x + 1}
              y={rowHeight / 2 - 1}
              width={w}
              height={rowHeight / 2}
            />
          )
        })}
      {!collapsed && (
        <Text
          fill="#fff"
          text={`${pick.load_data} - ${pick.subcontractor_name}`}
          x={width + 6}
          y={rowHeight / 2 - 5}
          fontSize={12}
        />
      )}
    </Group>
  )
}
