import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  Select,
} from '@material-ui/core'
import React from 'react'
import { useProject } from '../ProjectWrapper'
import { getUseVODStore } from './VODController'
import { VODDateTimeSelect } from './VODDateTimeSelect'

const useStyles = makeStyles({
  root: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
})

export const VODSelectMobile = () => {
  // console.log('render VODSelectMobile')
  const classes = useStyles()
  const project = useProject()
  const useVODStore = getUseVODStore()

  const gotoVideo = useVODStore((state) => state.gotoVideo)
  const speed = useVODStore((state) => state.speed)

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VODDateTimeSelect />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            native
            onChange={(e) => {
              if (!e.target.value) return
              gotoVideo({ streamId: parseInt(e.target.value as string) })
            }}
          >
            {project.streams.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          Speed:{' '}
          <ButtonGroup style={{ flexGrow: 1, marginLeft: 10 }}>
            {[1, 20, 200].map((r) => (
              <Button
                key={r}
                variant="contained"
                color={speed === r ? 'primary' : 'default'}
                size="small"
                onClick={() => gotoVideo({ speed: r })}
                style={{ paddingLeft: 0, paddingRight: 0, flexGrow: 1 }}
              >
                {r}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  )
}
