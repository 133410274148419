import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawModeChangeEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { useControl } from 'react-map-gl'

import type { ControlPosition } from 'react-map-gl'
import { useDeckVerificationStore } from './DeckVerificationController'

const ACTIVE_COLOR = '#0f0'
const INACTIVE_COLOR = '#0F0'

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition

  onCreate?: (evt: DrawCreateEvent) => void
  onUpdate?: (evt: DrawUpdateEvent) => void
  onDelete?: (evt: DrawDeleteEvent) => void
}

export default function DrawControl(props: DrawControlProps) {
  const store = useDeckVerificationStore()

  useControl<MapboxDraw>(
    ({ map }) => {
      // @ts-ignore
      map.on('draw.create', props.onCreate)
      // @ts-ignore
      map.on('draw.update', props.onUpdate)
      // @ts-ignore
      map.on('draw.delete', props.onDelete)

      map.on('draw.modechange', (e: DrawModeChangeEvent) => {
        store.setState({
          drawMode: e.mode,
        })
      })

      map.on('draw.selectionchange', (e: DrawSelectionChangeEvent) => {
        store.setState({
          selectionModel: e.features.map((f) => f.id as string),
        })
      })

      const draw = new MapboxDraw({
        ...props,
        styles: [
          {
            id: 'gl-draw-line-inactive',
            type: 'line',
            filter: [
              'all',
              ['==', 'active', 'false'],
              ['==', '$type', 'LineString'],
              ['!=', 'mode', 'static'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': INACTIVE_COLOR,
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-line-active',
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', 'active', 'true'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': ACTIVE_COLOR,
              'line-dasharray': [0.2, 2],
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
            type: 'circle',
            filter: [
              'all',
              ['==', 'meta', 'vertex'],
              ['==', '$type', 'Point'],
              ['!=', 'mode', 'static'],
            ],
            paint: {
              'circle-radius': 5,
              'circle-color': '#fff',
            },
          },
          {
            id: 'gl-draw-polygon-and-line-vertex-inactive',
            type: 'circle',
            filter: [
              'all',
              ['==', 'meta', 'vertex'],
              ['==', '$type', 'Point'],
              ['!=', 'mode', 'static'],
            ],
            paint: {
              'circle-radius': 3,
              'circle-color': ACTIVE_COLOR,
            },
          },
          {
            id: 'gl-draw-point-point-stroke-inactive',
            type: 'circle',
            filter: [
              'all',
              ['==', 'active', 'false'],
              ['==', '$type', 'Point'],
              ['==', 'meta', 'feature'],
              ['!=', 'mode', 'static'],
            ],
            paint: {
              'circle-radius': 5,
              'circle-opacity': 1,
              'circle-color': '#fff',
            },
          },
          {
            id: 'gl-draw-point-inactive',
            type: 'circle',
            filter: [
              'all',
              ['==', 'active', 'false'],
              ['==', '$type', 'Point'],
              ['==', 'meta', 'feature'],
              ['!=', 'mode', 'static'],
            ],
            paint: {
              'circle-radius': 3,
              'circle-color': INACTIVE_COLOR,
            },
          },
          {
            id: 'gl-draw-point-stroke-active',
            type: 'circle',
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['==', 'active', 'true'],
              ['!=', 'meta', 'midpoint'],
            ],
            paint: {
              'circle-radius': 7,
              'circle-color': '#fff',
            },
          },
          {
            id: 'gl-draw-point-active',
            type: 'circle',
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['!=', 'meta', 'midpoint'],
              ['==', 'active', 'true'],
            ],
            paint: {
              'circle-radius': 5,
              'circle-color': ACTIVE_COLOR,
            },
          },
          {
            id: 'gl-draw-polygon-fill-static',
            type: 'fill',
            filter: [
              'all',
              ['==', 'mode', 'static'],
              ['==', '$type', 'Polygon'],
            ],
            paint: {
              'fill-color': '#404040',
              'fill-outline-color': '#404040',
              'fill-opacity': 0.1,
            },
          },
          {
            id: 'gl-draw-polygon-stroke-static',
            type: 'line',
            filter: [
              'all',
              ['==', 'mode', 'static'],
              ['==', '$type', 'Polygon'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#404040',
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-line-static',
            type: 'line',
            filter: [
              'all',
              ['==', 'mode', 'static'],
              ['==', '$type', 'LineString'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#404040',
              'line-width': 2,
            },
          },
          {
            id: 'gl-draw-point-static',
            type: 'circle',
            filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
            paint: {
              'circle-radius': 5,
              'circle-color': '#404040',
            },
          },
        ],
      })

      store.setState({
        draw,
      })

      return draw
    },
    ({ map }) => {
      // @ts-ignore
      map.on('draw.create', props.onCreate)
      // @ts-ignore
      map.on('draw.update', props.onUpdate)
      // @ts-ignore
      map.on('draw.delete', props.onDelete)
    },
    ({ map }) => {
      // @ts-ignore
      map.off('draw.create', props.onCreate)
      // @ts-ignore
      map.off('draw.update', props.onUpdate)
      // @ts-ignore
      map.off('draw.delete', props.onDelete)
    },
    {
      position: props.position,
    }
  )

  return null
}
