import Alert from '@material-ui/lab/Alert'
import React, { memo } from 'react'
import { useMergeRefs } from 'use-callback-ref'
// import cursor from '../../../images/cursor.png'
import useRefCurrent from '../../hooks/useRefCurrent'
import { getUseVODStore } from './VODController'
import { VODVideoCanvas } from './VODVideoCanvas'

// import 'mux-embed/dist/types/mux-embed.d.ts'
// @ts-ignore
import mux from 'mux-embed'
import { useProject } from '../ProjectWrapper'
import { useMuxBaseConfig } from '../../hooks/useMuxBaseConfig'

export const VODPlayer: React.FC<{}> = memo(({ children }) => {
  const [videoRef, videoEl] = useRefCurrent<HTMLVideoElement>(null)
  const useVODStore = getUseVODStore()

  const project = useProject()
  const muxBaseConfig = useMuxBaseConfig()

  const parentVideoRef = useVODStore((state) => state.videoRef)
  const zoomMode = useVODStore((state) => state.zoomMode)
  const gotoNextVideo = useVODStore((state) => state.gotoNextVideo)
  const alert = useVODStore((state) => state.alert)
  const video = useVODStore((state) => state.video)

  React.useEffect(() => {
    if (!video || !videoRef.current) return

    mux.monitor(videoRef.current, {
      debug: false,
      data: {
        ...muxBaseConfig,

        player_name: 'VODPlayer', // any arbitrary string you want to use to identify this player

        // Video Metadata
        video_id: video?.id.toString(), // ex: 'abcd123'
        video_title: `${project.slug} - ${
          video?.stream.name
        } - ${video?.localStartDateTime.toISO({
          suppressSeconds: true,
          suppressMilliseconds: true,
        })} @${video?.speed}x`, // ex: 'My Great Video'
        video_variant_name: `${video?.speed}x`,
        // video_series: '', // ex: 'Weekly Great Videos'
        video_stream_type: 'on-demand', // 'live' or 'on-demand'
        video_content_type: 'archive video',
      },
    })
  }, [video])

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#000',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <video
        ref={useMergeRefs([parentVideoRef, videoRef])}
        playsInline
        autoPlay
        muted
        controls
        onEnded={gotoNextVideo}
        preload="auto"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          outline: 'none',
          flexGrow: 1,
          paddingTop: '0 !important',
        }}
      />
      {zoomMode && (
        <VODVideoCanvas
          videoEl={videoEl}
          showVideo={true}
          mode={'select'}
          onClick={() => {
            if (
              !videoEl
              // || shoringCreateMode
            )
              return
            if (videoEl.paused || videoEl.ended) {
              videoEl.play()
            } else {
              videoEl.pause()
            }
          }}
          scaleExtent={[1, 10]}
        >
          {children}
        </VODVideoCanvas>
      )}

      {alert && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert
            onClose={() => {
              useVODStore.setState({ alert: undefined })
            }}
            severity={alert?.severity}
          >
            {alert?.message}
          </Alert>
        </div>
      )}
    </div>
  )
})

VODPlayer.displayName = 'VODPlayer'
