import { FullGestureState, useGesture } from '@use-gesture/react'
import { glMatrix, mat2d, ReadonlyMat2d, ReadonlyVec2, vec2 } from 'gl-matrix'
import clamp from 'lodash/clamp'
import React, { useCallback, useRef } from 'react'
import { ValueOf } from '../../../types'
import { getContainTransformMat2d } from '../../helpers/getContainTransform'

// deault precision is 32 bit (Float32Array).
// Need 64 bit to avoid jitter at extreme zooms
glMatrix.setMatrixArrayType(Array)

type GestureHandler<
  T extends 'move' | 'drag' | 'wheel' | 'scroll' | 'pinch'
> = (
  transformRef: React.MutableRefObject<mat2d>,
  mousePositionRef: React.MutableRefObject<vec2>,
  state?: FullGestureState<T>
) => void

type Middleware = (
  transformRef: React.MutableRefObject<mat2d>,
  mousePositionRef: React.MutableRefObject<vec2>
) => void

export const useZoomPan = ({
  target,
  scaleExtent,
  translateExtent,
  handlers,
  gestureConfig: options = {
    wheelX: transformers.panX,
    wheelY: transformers.panY,
    altWheelX: transformers.zoomX,
    altWheelY: transformers.zoomY,
    dragX: transformers.panX,
    dragY: transformers.panY,
  },
}: {
  target: React.MutableRefObject<HTMLElement | null>
  scaleExtent?: React.MutableRefObject<ReadonlyVec2>
  translateExtent?: React.MutableRefObject<[ReadonlyVec2, ReadonlyVec2]>
  gestureConfig: {
    wheelX: ValueOf<typeof transformers>
    wheelY: ValueOf<typeof transformers>
    altWheelX: ValueOf<typeof transformers>
    altWheelY: ValueOf<typeof transformers>
    dragX: ValueOf<typeof transformers>
    dragY: ValueOf<typeof transformers>
  }
  handlers?: {
    onTransform?: GestureHandler<'move'>
    onClick?: GestureHandler<'drag'>
    onMove?: GestureHandler<'move'>
    onBeforeClamp?: Middleware
  }
}) => {
  const mat2dRef = useRef<mat2d>(mat2d.create())
  const mousePositionRef = useRef<vec2>([0, 0])
  const defaultScaleExtent = useRef<[number, number]>([-Infinity, -Infinity])
  const defaultTranslateExtent = useRef<[[number, number], [number, number]]>([
    [-Infinity, -Infinity],
    [Infinity, Infinity],
  ])

  const clampTransform = useCallback(
    function _clampTransform() {
      if (handlers?.onBeforeClamp) {
        handlers.onBeforeClamp(mat2dRef, mousePositionRef)
      }
      clampMat2d(
        mat2dRef.current,
        mat2dRef.current,
        [target.current?.offsetWidth || 1, target.current?.offsetHeight || 1],
        scaleExtent?.current || defaultScaleExtent.current,
        translateExtent?.current || defaultTranslateExtent.current
      )
      if (handlers?.onTransform) {
        handlers.onTransform(mat2dRef, mousePositionRef)
      }
    },
    [
      clampMat2d,
      mat2dRef,
      target,
      scaleExtent,
      defaultScaleExtent,
      translateExtent,
      defaultTranslateExtent,
      handlers,
    ]
  )

  const setTransform = useCallback(
    (t: mat2d) => {
      mat2dRef.current = t
      clampTransform()
    },
    [mat2dRef, clampTransform]
  )

  const zoomTo = useCallback(
    (zoomBox: [vec2, vec2]) => {
      if (!target.current) return
      const transform = getContainTransformMat2d({
        parent: [target.current.offsetWidth, target.current.offsetHeight],
        child: [target.current.offsetWidth, target.current.offsetHeight],
        crop: zoomBox,
      })
      setTransform(transform)
    },
    [target, setTransform]
  )

  const bind = useGesture(
    {
      onMove: function useZoomPanOnMove(state) {
        if (state.event instanceof MouseEvent) {
          if (state.hovering) {
            mousePositionRef.current[0] = state.event.offsetX
            mousePositionRef.current[1] = state.event.offsetY
          } else {
            mousePositionRef.current[0] = 0
            mousePositionRef.current[1] = 0
          }
          if (handlers?.onMove) {
            handlers.onMove(mat2dRef, mousePositionRef, state)
          }
        }
      },
      onWheel: function useZoomPanOnWheel(state) {
        if (state.event instanceof MouseEvent) {
          if (state.down) return

          state.event.preventDefault()

          const mouseOffset = vec2.transformMat2d(
            [0, 0],
            [state.event.offsetX, state.event.offsetY],
            mat2d.invert(mat2d.create(), mat2dRef.current)
          )

          let xTransformer: Transformer | undefined
          let yTransformer: Transformer | undefined

          if (state.altKey || state.ctrlKey || state.metaKey) {
            xTransformer = options.altWheelX
            yTransformer = options.altWheelY
          } else {
            xTransformer = options.wheelX
            yTransformer = options.wheelY
          }

          // Test if there is a combo of one zoom transformer and one pan transformer
          // If such is the case, we want to only trigger the transformer that is along the main axis of motion
          if (
            (isZoomTransformer(xTransformer) &&
              !isZoomTransformer(yTransformer)) ||
            (!isZoomTransformer(xTransformer) &&
              isZoomTransformer(yTransformer))
          ) {
            if (Math.abs(state.delta[1]) - Math.abs(state.delta[0]) > 0) {
              // y-axis dominant gesture, so ignore x
              xTransformer = undefined
            } else {
              // x-axis dominant gesture, so ignore y
              yTransformer = undefined
            }
          }

          if (xTransformer) {
            xTransformer(mat2dRef.current, state.delta[0], mouseOffset)
          }

          if (yTransformer) {
            yTransformer(mat2dRef.current, state.delta[1], mouseOffset)
          }

          clampTransform()
        }
      },
      onDrag: function useZoomPanOnDrag(state) {
        const mouseOffset = vec2.transformMat2d(
          [0, 0],
          mousePositionRef.current,
          mat2d.invert(mat2d.create(), mat2dRef.current)
        )

        options.dragX(mat2dRef.current, -state.delta[0], mouseOffset)
        options.dragY(mat2dRef.current, -state.delta[1], mouseOffset)
        clampTransform()
      },
      onDragEnd: function useZoomPanOnDragEnd(state) {
        if (state.tap && handlers?.onClick) {
          handlers.onClick(mat2dRef, mousePositionRef, state)
        }
      },
      onHover: function useZoomPanOnHover(state) {
        if (!state.hovering) {
          mousePositionRef.current = [0, 0]
        }
      },
    },
    {
      target: target,
      eventOptions: { passive: false },
    }
  )

  // call this asynchronously so any onTransform handlers don't setState from within render
  // https://github.com/facebook/react/issues/18178#issuecomment-595846312
  React.useEffect(() => {
    setTimeout(clampTransform, 0)
  }, [])

  return {
    transformRef: mat2dRef,
    mousePositionRef,
    setTransform,
    zoomTo,
  }
}

type Transformer = (
  matrix: mat2d,
  delta: number,
  mouseOffset: ReadonlyVec2
) => void

const tempVec2: [number, number] = [0, 0]

function zoom(matrix: mat2d, delta: ReadonlyVec2, mouseOffset: ReadonlyVec2) {
  mat2d.translate(matrix, matrix, mouseOffset)
  mat2d.scale(matrix, matrix, [1 - delta[0] / 500, 1 - delta[1] / 500])
  mat2d.translate(matrix, matrix, vec2.scale(tempVec2, mouseOffset, -1))
}

function zoomX(matrix: mat2d, delta: number, mouseOffset: ReadonlyVec2) {
  zoom(matrix, [delta, 0], mouseOffset)
}

function zoomY(matrix: mat2d, delta: number, mouseOffset: ReadonlyVec2) {
  zoom(matrix, [0, delta], mouseOffset)
}

function zoomXY(matrix: mat2d, delta: number, mouseOffset: ReadonlyVec2) {
  zoom(matrix, [delta, delta], mouseOffset)
}

function panXY(matrix: mat2d, delta: ReadonlyVec2) {
  const initialScaleX = matrix[0]
  const initialScaleY = matrix[3]
  mat2d.translate(matrix, matrix, [
    -delta[0] / initialScaleX,
    -delta[1] / initialScaleY,
  ])
}

function panX(matrix: mat2d, delta: number, mouseOffset: ReadonlyVec2) {
  panXY(matrix, [delta, 0])
}

function panY(matrix: mat2d, delta: number, mouseOffset: ReadonlyVec2) {
  panXY(matrix, [0, delta])
}

export const transformers: Record<string, Transformer> = {
  zoomX,
  zoomY,
  zoomXY,
  panX,
  panY,
} as const

const isZoomTransformer = (transformer: Transformer) => {
  return (
    transformer === transformers.zoomX ||
    transformer === transformers.zoomY ||
    transformer === transformers.zoomXY
  )
}

export function clampMat2d(
  out: mat2d,
  a: ReadonlyMat2d,
  containerExtent: ReadonlyVec2,
  scaleExtent: ReadonlyVec2,
  translateExtent: [ReadonlyVec2, ReadonlyVec2]
) {
  out[0] = clamp(a[0], scaleExtent[0], scaleExtent[1])
  out[3] = clamp(a[3], scaleExtent[0], scaleExtent[1])
  out[4] = clamp(
    a[4],
    -(translateExtent[1][0] * out[0] - containerExtent[0]),
    -translateExtent[0][0] * out[0]
  )
  out[5] = clamp(
    a[5],
    -(translateExtent[1][1] * out[3] - containerExtent[1]),
    -translateExtent[0][1] * out[3]
  )
  return out
}
