import { TableCell, TableRow, useTheme } from '@material-ui/core'
import { DateTime } from 'luxon'
import { FrameExtended } from '../../../api/FrameExtended'
import { useProject } from '../../ProjectWrapper'
import { useStaticTimelineStore } from '../Timeline/StaticTimeline'
import { getUseVODStore } from '../VODController'
import { useFramesStore } from './FramesController'

export const FramesTimeline = ({}: {}) => {
  const project = useProject()
  const frameStore = useFramesStore()
  const vodStore = getUseVODStore()

  const videoDate = vodStore((state) => state.videoDate)
  const streamFrames = frameStore((state) => state.streamFrames)

  return (
    <>
      {Array.from(streamFrames.entries()).map(([streamId, frames], i) => {
        const stream = project.streams.find((s) => s.id === streamId)
        if (!stream) return null
        return (
          <TableRow key={streamId} style={{}}>
            <TableCell
              style={{
                whiteSpace: 'nowrap',
                paddingLeft: 8,
                paddingRight: 20,
              }}
            >
              {stream.name}
            </TableCell>
            <TableCell style={{ position: 'relative', overflow: 'hidden' }}>
              {Array.from(Array(24).keys()).map((hour) => {
                const frame = frames.find((f) => {
                  return f.roundedDateTime.hour === hour
                })

                return (
                  <TimelineFrame
                    key={hour}
                    frame={frame}
                    hour={videoDate.set({ hour: hour })}
                  />
                )
              })}
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

const TimelineFrame = ({
  frame,
  hour,
}: {
  frame?: FrameExtended
  hour: DateTime
}) => {
  const theme = useTheme()
  const staticTimelineStore = useStaticTimelineStore()
  const vodStore = getUseVODStore()
  const videoHour = vodStore((state) => state.videoHour)

  const scale = staticTimelineStore((state) => state.scale)

  const x = Math.floor(scale(hour.setZone('system', { keepLocalTime: true })))
  const x1 = Math.floor(
    scale(hour.setZone('system', { keepLocalTime: true }).plus({ hours: 1 }))
  )
  const width = x1 - x

  const selected =
    hour.toLocaleString(DateTime.TIME_24_SIMPLE) ===
    videoHour.toLocaleString(DateTime.TIME_24_SIMPLE)

  return (
    <div
      style={{
        position: 'absolute',
        width: width,
        left: x,
        height: '100%',
        top: 0,
        backgroundImage: frame ? `url(${frame.thumbnail_url})` : undefined,
        backgroundSize: 'cover',
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: selected ? theme.palette.primary.light : '#000',
      }}
      onClick={() => {
        if (frame) {
          vodStore.getState().gotoVideo({
            dateTime: frame.dateTime,
            streamId: frame.stream.id,
          })
        }
      }}
    />
  )
}
