import { createStyles, Divider, makeStyles } from '@material-ui/core'
import without from 'lodash/without'
import React, { useEffect, useMemo, useState } from 'react'
import { Layer } from 'react-konva'
import { useKey, useKeyPressEvent } from 'react-use'
import { UseStore } from 'zustand'
import {
  ImageAnnotationBox,
  ImageLabelInfo,
  ImageSessionDetails,
  ImageSublabel,
} from '../../api/codegen/typescript-axios'
import { createCtx } from '../../helpers/createCtx'
import { Loading } from '../Loading'
import { AnnotatorBottomControls } from './AnnotatorBottomControls'
import { AnnotatorBoxList } from './AnnotatorBoxList'
import { AnnotatorCanvasBoxes } from './AnnotatorCanvasBoxes'
import { AnnotatorState } from './AnnotatorFetcher'
import { AnnotatorLabelList } from './AnnotatorLabelList'
import { AnnotatorSublabelList } from './AnnotatorSublabelList'
import { DrawNewBox } from './DrawNewBox'
import { MaskCanvas } from './Mask/MaskCanvas'
import { VideoCanvas } from './VideoCanvas'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      display: 'flex',
    },
    leftCol: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    boxList: {},
    centerCol: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      flexGrow: 1,
      position: 'relative',
    },
    img: {
      display: 'block',
      width: '100%',
    },
    playControls: {},
    rightCol: {
      width: 240,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    categoryControls: {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sessionControls: {},
  })
)

export type Mode = 'select' | 'draw' | 'selectMask' | 'drawMask'
export type LabelState = 'selected' | 'all' | 'none'
export interface ImageAnnotationBoxEdit extends ImageAnnotationBox {
  newComment?: string
}

export const [useAnnotatorContext, AnnotatorContext] = createCtx<{
  selectedBox?: ImageAnnotationBoxEdit
  setSelectedBox: (box: ImageAnnotationBoxEdit | undefined) => void
  hiddenBoxes: ImageAnnotationBoxEdit[]
  setHiddenBoxes: (h: ImageAnnotationBoxEdit[]) => void
}>()

export const Annotator = ({
  session,
  sublabels,
  useStore,
}: {
  session: ImageSessionDetails
  sublabels: ImageSublabel[]
  useStore: UseStore<AnnotatorState>
}) => {
  const classes = useStyles()

  const [mode, setMode] = useState<Mode>('select')
  const [tempSelect, setTempSelect] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState<ImageLabelInfo>()
  const [selectedSublabel, setSelectedSublabel] = useState<ImageSublabel>()
  const [selectedBox, setSelectedBox] = React.useState<ImageAnnotationBoxEdit>()
  const [boxes, setBoxes] = useState<ImageAnnotationBoxEdit[]>([])
  const [hiddenBoxes, setHiddenBoxes] = useState<ImageAnnotationBoxEdit[]>([])
  const video = useMemo(() => document.createElement('video'), [])
  const [showVideo, setShowVideo] = useState(false)
  const [labelState, setLabelState] = useState<LabelState>('selected')

  useEffect(() => {
    if (selectedBox) {
      setSelectedSublabel(selectedBox?.sublabel)
      setSelectedLabel(
        session.labels.find((l) => selectedBox?.label.id === l.id)
      )
    }
  }, [selectedBox])

  useEffect(() => {
    if (selectedBox) {
      selectedBox.sublabel = selectedSublabel
      setBoxes(boxes.slice())
    }
  }, [selectedSublabel])

  useEffect(() => {
    if (selectedBox && selectedLabel) {
      selectedBox.label = selectedLabel
      setBoxes(boxes.slice())
    }
  }, [selectedLabel])

  useEffect(() => {
    if (mode === 'draw') {
      setSelectedSublabel(undefined)
      setSelectedBox(undefined)
    }
  }, [mode])

  // modes
  useKey('s', () => setMode('select'))
  useKey('d', () => setMode('draw'))
  useKeyPressEvent(
    'Shift', // shift key
    () => {
      if (mode === 'draw') {
        setTempSelect(true)
        setMode('select')
      }
    },
    () => {
      if (tempSelect) {
        setTempSelect(false)
        setMode('draw')
      }
    }
  )
  // delete selectedBox
  useKey(
    (event: KeyboardEvent) => {
      return event.key === 'Backspace' || event.key === 'Delete'
    },
    () => {
      if (selectedBox) {
        const _box = selectedBox
        setSelectedBox(undefined)
        setBoxes(without(boxes, _box))
      }
    }
  )

  useEffect(() => {
    if (session.video) {
      video.src = session.video.url
      // video.currentTime = _session.frame.offset_seconds
    }
    setBoxes(
      session.boxes && session.boxes.length > 0
        ? session.boxes
        : session.previous_boxes || []
    )
  }, [session])

  if (!session) return <Loading text={'Loading...'}></Loading>

  return (
    <AnnotatorContext.Provider
      value={{
        selectedBox,
        setSelectedBox,
        hiddenBoxes,
        setHiddenBoxes,
      }}
    >
      <div className={classes.root}>
        <div className={classes.leftCol}>
          <AnnotatorBoxList
            boxes={boxes}
            categories={session.categories}
            selectedBox={selectedBox}
            setSelectedBox={setSelectedBox}
            // hiddenBoxes={hiddenBoxes}
            // setHiddenBoxes={setHiddenBoxes}
          />
        </div>
        <div className={classes.centerCol}>
          <div className={classes.image}>
            <VideoCanvas
              imageUrl={session.frame.image_url}
              videoEl={video}
              showVideo={showVideo}
              listening={!session.paused_at}
              setSelectedBox={setSelectedBox}
              mode={mode}
              scaleExtent={[0.94, 10]}
            >
              <AnnotatorContext.Provider
                value={{
                  selectedBox,
                  setSelectedBox,
                  hiddenBoxes,
                  setHiddenBoxes,
                }}
              >
                <Layer>
                  <AnnotatorCanvasBoxes
                    boxes={boxes}
                    setBoxes={setBoxes}
                    selectedBox={selectedBox}
                    setSelectedBox={setSelectedBox}
                    labelState={showVideo ? 'none' : labelState}
                  />
                </Layer>
                {setBoxes && selectedLabel && mode === 'draw' && (
                  // This is on a separate canvas that isnt transformed
                  <DrawNewBox
                    selectedLabel={selectedLabel}
                    selectedSublabel={selectedSublabel}
                    setBoxes={setBoxes}
                  />
                )}
                <MaskCanvas session={session} mode={mode} useStore={useStore} />
              </AnnotatorContext.Provider>
            </VideoCanvas>
          </div>
          <div className={classes.playControls}>
            <AnnotatorBottomControls
              session={session}
              boxes={boxes}
              video={video}
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              mode={mode}
              setMode={setMode}
              labelState={labelState}
              setLabelState={setLabelState}
            />
          </div>
        </div>
        <div className={classes.rightCol}>
          <div className={classes.categoryControls}>
            <AnnotatorLabelList
              categories={session.categories}
              labels={session.labels}
              selectedLabel={selectedLabel}
              setSelectedLabel={setSelectedLabel}
            />
            <Divider />
            <AnnotatorSublabelList
              sublabels={sublabels}
              selectedSublabel={selectedSublabel}
              setSelectedSublabel={setSelectedSublabel}
            />
          </div>
        </div>
      </div>
    </AnnotatorContext.Provider>
  )
}
