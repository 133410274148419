import {
  Accordion,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'

export const CraneReportsPanelEmptiness = () => {
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()

  const date = useVODStore((state) => state.videoDate)
  const days = craneStore((state) => state.days)

  const currentDayData = days.get(date.toISODate())

  if (!currentDayData) return null

  return (
    <>
      <Accordion>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Empty</TableCell>
              <TableCell>Non-empty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {currentDayData.emptyDuration.toFormat("h'h' m'm'")}
              </TableCell>
              <TableCell>
                {currentDayData.nonEmptyDuration.toFormat("h'h' m'm'")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {(
                  (currentDayData.emptyIdleDuration.toMillis() /
                    currentDayData.emptyDuration.toMillis()) *
                  100
                ).toFixed(0) + '% idle'}
              </TableCell>
              <TableCell>
                {(
                  (currentDayData.nonEmptyIdleDuration.toMillis() /
                    currentDayData.nonEmptyDuration.toMillis()) *
                  100
                ).toFixed(0) + '% idle'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Accordion>
    </>
  )
}
