import React from 'react'
import { Navigate } from 'react-router-dom'
import { allProjectNavItems } from './Nav'
import { useProject } from './ProjectWrapper'

export const ProjectDefaultPage = (props: {}) => {
  const project = useProject()
  const firstPage = allProjectNavItems.find(
    (i) => i.apiName === project.pages[0]
  )
  const page = project.pages.indexOf('live') > -1 ? 'live' : firstPage?.path

  return <Navigate replace to={`/${project.slug}/${page}`} />
}
