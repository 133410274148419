import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import clsx from 'clsx'
import log from 'loglevel'
import React, { ReactElement } from 'react'
import { useKey, useKeyPressEvent } from 'react-use'
import { KeyFilter } from 'react-use/lib/useKey'
import { useKeyboardShortcutsStore } from '../KeyboardShortcutsController'

const useStyles = makeStyles(
  {
    buttonRoot: {
      minWidth: 0,
    },
  },
  {
    name: 'KeyedButton',
  }
)

export const KeyedButton = ({
  icon,
  text,
  tooltip,
  keyCode,
  dictionaryEntry,
  margin = false,
  onClick,
  onMouseDown,
  onMouseUp,

  // inherited props below
  size = 'small',
  disabled = false,
  style,
  className,
  color = 'default',
}: {
  icon?: ReactElement
  text?: string | React.ReactNode
  tooltip?: string
  keyCode?: KeyFilter
  dictionaryEntry?: [string, string]
  onClick?: () => void
  onMouseDown?: (e?: React.MouseEvent) => void
  onMouseUp?: () => void
  margin?: boolean
} & ButtonProps &
  IconButtonProps) => {
  const classes = useStyles()

  const keyboardShortcutsStore = useKeyboardShortcutsStore()

  useKey(
    keyCode,
    (e) => {
      if (!onClick) return
      log.debug('useKey: ', keyCode)
      e.preventDefault()
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
      onClick()
    },
    undefined,
    [onClick]
  )

  useKeyPressEvent(
    keyCode,
    (e) => {
      if (!onMouseDown) return
      log.debug('useKey: ', keyCode)
      e.preventDefault()
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
      onMouseDown()
    },
    (e) => {
      if (!onMouseUp) return
      log.debug('useKey: ', keyCode)
      e.preventDefault()
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
      onMouseUp()
    }
  )

  React.useEffect(() => {
    if (typeof keyCode === 'string') {
      keyboardShortcutsStore
        .getState()
        .set(
          dictionaryEntry ? dictionaryEntry[0] : keyCode,
          dictionaryEntry ? dictionaryEntry[1] : tooltip || 'NO DESCRIPTION'
        )

      return () => {
        keyboardShortcutsStore
          .getState()
          .delete(dictionaryEntry ? dictionaryEntry[0] : keyCode)
      }
    }
  }, [keyCode, dictionaryEntry])

  if (margin) {
    style = {
      ...style,
      margin: '0 10px',
    }
  }

  return (
    <Tooltip
      title={tooltip + (keyCode ? ` ( ${keyCode} )` : '')}
      open={tooltip ? undefined : false}
    >
      {typeof text !== 'undefined' ? (
        <Button
          className={clsx(classes.buttonRoot, className)}
          disabled={disabled}
          variant="contained"
          color={color}
          size={size}
          endIcon={icon}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          style={style}
        >
          {text}
        </Button>
      ) : (
        <IconButton
          className={className}
          disabled={disabled}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          style={style}
          size={size}
        >
          {icon}
        </IconButton>
      )}
    </Tooltip>
  )
}
