import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { useMobile } from '../../hooks/useMobile'
import { useApi } from '../ApiContext'
import { DatePicker } from '../FormInputs/DatePicker'
import { useProject } from '../ProjectWrapper'
import { GenericTimelapseVideo, timelapseQueryKeys } from './TimelapsePage'

const useStyles = makeStyles((theme) =>
  createStyles({
    pickers: {
      // height: '100%',
      userSelect: 'none',
      overflow: 'hidden',
      padding: '8px',
      color: '#fff',
      // flexGrow: 1,
      display: 'flex',
      // flexDirection: 'column',
    },
    thumbnails: {
      display: 'flex',
      margin: '10px -5px 0',
      padding: 0,
      flexWrap: 'wrap',
      // [theme.breakpoints.up('sm')]: {
      //   display: 'block',
      //   overflow: 'auto',
      // },
    },
    thumbnail: {
      listStyle: 'none',
      display: 'block',
      width: '100%',
      cursor: 'pointer',
      padding: '5px',
      transition: 'opacity 0.1s',
    },
    thumbnailInner: {
      paddingTop: '56.25%',
      backgroundSize: 'cover',
      position: 'relative',
      '&:hover': {
        outline: `1px solid ${theme.palette.primary.main}`,
      },
    },
    selectedThumbnail: {
      outline: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
    streamName: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      color: '#fff',
      width: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0,
      padding: 2,
    },
  })
)

export const DailyTimelapsePanel = ({
  video,
  setVideo,
}: {
  video?: GenericTimelapseVideo
  setVideo: (v: GenericTimelapseVideo) => void
}) => {
  const api = useApi()
  const classes = useStyles()
  const project = useProject()
  const isMobile = useMobile()

  const [searchParams, setSearchParams] = useSearchParams()
  const dateParam = searchParams.get(timelapseQueryKeys.date)
  const streamParam = searchParams.get(timelapseQueryKeys.stream)

  const { value: videos = [], loading } = useAsync(async () => {
    const _date = dateParam || DateTime.now().toISODate()
    try {
      const _dateTime = DateTime.fromISO(_date).setZone(project.timezone, {
        keepLocalTime: true,
      })

      const streamVideosMap = await api.getProjectVideos(project, {
        video_type: 'daily',
        start_time: _dateTime.startOf('day'),
        end_time: _dateTime.startOf('day').plus({ days: 1 }),
      })

      const dailyVideos = Array.from(streamVideosMap.values()).map(
        (typeMap) => {
          const dailyVids = typeMap.get('daily')
          return dailyVids ? dailyVids[0] : undefined
        }
      )

      return dailyVideos
    } catch (error) {
      throw error
    }
  }, [project, dateParam])

  React.useEffect(() => {
    const streamParam = searchParams.get(timelapseQueryKeys.stream)
    const streamId = streamParam ? parseInt(streamParam) : project.streams[0].id
    const customParam = searchParams.get(timelapseQueryKeys.custom)

    const video = videos.find((v) => v?.stream.id === streamId)

    if (!customParam && video) {
      setVideo({
        url: video.url,
        title: `Daily Timelapse: ${
          video.stream.name
        } ${video.localStartDateTime.toLocaleString(DateTime.DATE_SHORT)}`,
      })
    }
  }, [searchParams, project, videos])

  return (
    <Grid container spacing={2} className={classes.pickers}>
      <Grid item xs={12}>
        <Typography variant="h6">Daily Timelapses</Typography>
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          value={dateParam}
          onChange={(d) => {
            searchParams.set('date', d)
            searchParams.delete('custom')
            setSearchParams(searchParams, { replace: true })
          }}
          dates={project.videoArchiveDates.map((d) => d.dateString)}
        />
      </Grid>
      <Grid item xs={12}>
        {isMobile ? (
          <Select
            fullWidth
            native
            label="Stream"
            value={streamParam}
            onChange={(e) => {
              searchParams.set('stream', e.target.value as string)
              searchParams.delete('custom')
              setSearchParams(searchParams, { replace: true })
            }}
          >
            {videos.map((_video) => {
              if (!_video) return null

              return (
                <option
                  key={_video.stream.id}
                  value={_video.stream.id.toString()}
                >
                  {_video.stream.name}
                </option>
              )
            })}
          </Select>
        ) : (
          <ul className={classes.thumbnails}>
            {videos.map((_video) => {
              if (!_video) return null

              return (
                <li
                  key={_video.stream.id}
                  className={clsx(classes.thumbnail)}
                  onClick={() => {
                    searchParams.set('stream', _video.stream.id.toString())
                    searchParams.delete('custom')
                    setSearchParams(searchParams, { replace: true })
                  }}
                >
                  <Paper elevation={2}>
                    <div
                      className={clsx(classes.thumbnailInner, {
                        [classes.selectedThumbnail]: video?.url === _video.url,
                      })}
                      style={{
                        backgroundImage: `url(${_video.stream.current_thumbnail_url})`,
                      }}
                    >
                      <div className={classes.streamName}>
                        {_video.stream.name}
                      </div>
                    </div>
                  </Paper>
                </li>
              )
            })}
          </ul>
        )}
      </Grid>
    </Grid>
  )
}
