import { useEffect, useState } from 'react'

export function useDevicePixelRatio() {
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  )

  const listener = () => {
    setDevicePixelRatio(window.devicePixelRatio)
  }

  useEffect(() => {
    const mqString = `(resolution: ${window.devicePixelRatio}dppx)`
    const mm = matchMedia(mqString)

    mm.addListener(listener)

    return mm.removeListener(listener)
  }, [listener])

  return devicePixelRatio
}
