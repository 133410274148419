import { Grid, Typography, useTheme } from '@material-ui/core'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { Axis } from '@visx/axis'
import { scaleTime } from '@visx/scale'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useMeasure } from 'react-use'
import { assertIsDefined } from '../../../api/assertRequired'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'

export const CranePickDetailPanel = () => {
  const project = useProject()
  const theme = useTheme()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()

  const gotoVideo = useVODStore((state) => state.gotoVideo)

  const pick = craneStore((state) => state.pick)

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const scale = scaleTime({
    range: [0, width],
    round: false,
    domain: [
      pick?.segments[1].start
        .setZone('local', { keepLocalTime: true })
        .toJSDate() || 0,
      pick?.segments[4].end
        .setZone('local', { keepLocalTime: true })
        .toJSDate() || 0,
    ],
    nice: false,
    clamp: false,
  })

  const [{ time, x }, spring] = useSpring(() => ({
    time: 0,
    x: 0,
    immediate: true,
  }))

  useEffect(
    () =>
      useVODStore.subscribe<DateTime | undefined>(
        (dateTime: DateTime | null | undefined) => {
          if (dateTime) {
            const localTime = dateTime
              .setZone('local', {
                keepLocalTime: true,
              })
              .valueOf()

            const x = scale(localTime)
            spring.set({ time: localTime, x: x })
          } else {
            spring.set({ time: 0, x: 0 })
          }
        },
        (state) => state.videoDateTime
      ),
    [useVODStore, scale, spring]
  )

  return (
    <div
      style={{ padding: '12px 6px 0', position: 'relative' }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {/* <IconButton
        onClick={() => craneStore.setState({ showVideo: false })}
        style={{ position: 'absolute', top: 10, right: 10 }}
      >
        <CloseIcon />
      </IconButton> */}

      <Grid container spacing={1} direction={'column'}>
        {/* <Grid item>
          <Typography variant="h6">Crane Pick Detail</Typography>
        </Grid> */}
        {pick && (
          <Grid item ref={ref}>
            <Typography variant="h5">
              {pick.load_data}{' '}
              <span
                style={{ fontSize: '0.5em', opacity: 0.7, fontWeight: 'bold' }}
              >
                {pick.subcontractor_name}
              </span>
            </Typography>
            <svg style={{ width: '100%', height: 90, marginTop: 10 }}>
              {pick.segments.slice(1).map((s, i) => {
                const start =
                  Math.floor(
                    scale(s.start.setZone('local', { keepLocalTime: true }))
                  ) + 0.5
                const width =
                  scale(s.end.setZone('local', { keepLocalTime: true })) - start

                if (width < 1) {
                  return null
                }

                return (
                  <g
                    key={s.name}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      const time = scale.invert(e.nativeEvent.offsetX)
                      gotoVideo({
                        dateTime: DateTime.fromJSDate(
                          scale.invert(e.nativeEvent.offsetX)
                        ).setZone(project.timezone, { keepLocalTime: true }),
                      })
                    }}
                  >
                    <rect
                      x={start}
                      y={40.5}
                      width={i === 3 ? width : width - 4}
                      height={20}
                      fill={pick.subcontractor_color}
                      strokeWidth={0}
                    />
                    <rect
                      x={start}
                      y={width > 90 || i === 3 ? 21 : 1}
                      width={4}
                      height={width > 90 || i === 3 ? 40 : 60}
                      fill={pick.subcontractor_color}
                      strokeWidth={0}
                    />
                    <text
                      x={start + 6}
                      y={width > 90 || i === 3 ? 21 : 1}
                      fill={theme.palette.text.primary}
                      strokeWidth={0}
                      fontWeight="bold"
                      textAnchor={
                        i === pick.segments.length - 1 ? 'right' : 'left'
                      }
                      alignmentBaseline="hanging"
                      style={{ cursor: 'pointer' }}
                      onClick={() => gotoVideo({ dateTime: s.start })}
                    >
                      {`${s.label} ${s.totalDuration.toFormat("m'm' s's'")}`}
                    </text>
                  </g>
                )
              })}
              <Axis
                scale={scale}
                top={60.5}
                left={0}
                stroke={theme.palette.text.primary}
                tickStroke={theme.palette.text.primary}
                tickLabelProps={() => ({
                  fill: theme.palette.text.primary,
                  fontSize: 12,
                  fontWeight: 'bold',
                  textAnchor: 'middle',
                })}
              />
              <animated.rect x={x} y={40.5} width={3} height={20} fill="#fff" />
            </svg>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
