import { Grid, useMediaQuery } from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'
import { extendStream, StreamExtended } from '../../api/StreamExtended'
import { useProject } from '../ProjectWrapper'
import { VideoControls } from './VideoControls'
import { VideoPlayerHLS } from './VideoPlayerHLS'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      width: '100%',
      '& video': {
        display: 'block',
      },
    },
    alert: {
      borderRadius: 0,
      border: '1px solid #000',
      borderTopWidth: 0,
      fontWeight: 'bold',
      boxSizing: 'border-box',
    },
    videoContainer: {
      border: '1px solid #000',
      display: 'flex',
      flexDirection: 'column',
    },
    player: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
)

export const LiveVideosPageDesktop = () => {
  const theme = useTheme()
  const classes = useStyles()
  const project = useProject()

  const [gridContainerRef, containerDims] = useMeasure<HTMLDivElement>()

  const twoColumn = useMediaQuery(theme.breakpoints.up('lg'))
  const [modalStreamID, setModalStreamID] = useState<number>()

  const streams: StreamExtended[] = useMemo(() => {
    return project.streams
      .filter((s) => s.url && s.live === true)
      .map((s) => extendStream(s, project))
  }, [project])

  for (let i = 0; i < streams.length; i += 2) {
    if (!streams[i + 1]) {
      // if it's the last stream and a widow, make it full width
      streams[i].width = 1
      break
    }
    if (streams[i].full_width === true) {
      // if it's full length, we only increment +1 on the next loop iteration
      // do this by decrementing one here and incrementing two in the loop definition
      streams[i].width = 1
      i--
      continue
    }
    const leftVidAspect = streams[i].rotatedAspectRatioNumber
    const rightVidAspect = streams[i + 1].rotatedAspectRatioNumber
    streams[i].width = twoColumn
      ? leftVidAspect / (leftVidAspect + rightVidAspect)
      : 1
    streams[i + 1].width = twoColumn
      ? rightVidAspect / (leftVidAspect + rightVidAspect)
      : 1
  }

  const [slideshowState, setSlideshowState] = useState(false)
  const [slideshowInterval, setSlideshowInterval] = useState(15)
  useEffect(() => {
    if (!slideshowState) return
    const timeout = window.setTimeout(() => {
      const currentIndex = modalStreamID
        ? streams.findIndex((s) => s.id === modalStreamID)
        : -1
      const nextIndex =
        currentIndex === streams.length - 1 ? 0 : currentIndex + 1
      setModalStreamID(streams[nextIndex].id)
    }, slideshowInterval * 1000)
    return () => clearTimeout(timeout)
  }, [slideshowState, slideshowInterval, modalStreamID])

  return (
    <div className={classes.root}>
      {project && project.live === false ? (
        <Alert severity="warning" className={classes.alert}>
          Live streams not available at the moment.
        </Alert>
      ) : (
        <>
          {project.streams.length > 1 && (
            <VideoControls
              slideshowState={slideshowState}
              setSlideshowState={(state: boolean) => {
                setModalStreamID(streams[0].id)
                setSlideshowState(state)
              }}
              slideshowInterval={slideshowInterval}
              setSlideshowInterval={setSlideshowInterval}
            />
          )}
          <Grid container ref={gridContainerRef}>
            {streams.map((stream) => (
              <Grid
                item
                key={stream.url}
                style={{ width: stream.width * 100 + '%' }}
                className={classes.videoContainer}
              >
                <VideoPlayerHLS
                  stream={stream}
                  className={classes.player}
                  parentWidth={containerDims.width}
                  extraControls={{
                    ptz:
                      stream.ptz && project.user_attrs.ptz_level === 30
                        ? true
                        : stream.ptz &&
                          project.user_attrs.ptz_level > 0 &&
                          !stream.ptz_locked,
                    play: true,
                    fullScreen: true,
                    enlarge: true,
                  }}
                  showModal={modalStreamID === stream.id}
                  setModalStreamID={setModalStreamID}
                  slideshowState={slideshowState}
                  setSlideshowState={
                    project.streams.length > 1 ? setSlideshowState : undefined
                  }
                  slideshowInterval={slideshowInterval}
                  setSlideshowInterval={setSlideshowInterval}
                  shouldPlay={!modalStreamID || modalStreamID === stream.id}
                  disableIntersectionObserver={modalStreamID === stream.id}
                  nextVideo={
                    project.streams.length > 1
                      ? () => {
                          const currentIndex = modalStreamID
                            ? streams.findIndex((s) => s.id === modalStreamID)
                            : -1
                          const nextIndex =
                            currentIndex === streams.length - 1
                              ? 0
                              : currentIndex + 1
                          setModalStreamID(streams[nextIndex].id)
                        }
                      : undefined
                  }
                  prevVideo={
                    project.streams.length > 1
                      ? () => {
                          const currentIndex = modalStreamID
                            ? streams.findIndex((s) => s.id === modalStreamID)
                            : -1
                          const prevIndex =
                            currentIndex === 0
                              ? streams.length - 1
                              : currentIndex - 1
                          setModalStreamID(streams[prevIndex].id)
                        }
                      : undefined
                  }
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  )
}
