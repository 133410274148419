import Konva from 'konva'
import React, { useEffect, useRef } from 'react'
import { Label, Rect, Tag, Text, Transformer } from 'react-konva'
import { ImageAnnotationBox } from '../../api/codegen/typescript-axios'
import { useAnnotatorContext } from './Annotator'

export const Box = ({
  box,
  isSelected,
  onSelect,
  onChange,
  absoluteTransform,
  showLabel,
  clamp,
  color,
}: {
  box: ImageAnnotationBox
  isSelected: boolean
  onSelect: () => void
  // onChange truthiness is a proxy for editability
  onChange?: (box: Partial<ImageAnnotationBox>) => void
  absoluteTransform: Konva.Transform
  showLabel: boolean
  clamp: (p: Konva.Vector2d, dims?: Konva.Vector2d) => Konva.Vector2d
  color?: string
}) => {
  const boxRef = useRef<Konva.Rect>(null)
  const transformerRef = useRef<Konva.Transformer>(null)

  const { hiddenBoxes } = useAnnotatorContext()

  useEffect(() => {
    if (isSelected) {
      if (transformerRef.current) {
        // we need to attach transformer manually
        transformerRef.current.setNode(boxRef.current)
        transformerRef.current.getLayer()?.batchDraw()
      }
    }
  }, [isSelected])

  const scale = absoluteTransform.decompose().scaleX
  const invertAbsoluteTransform = absoluteTransform.copy().invert()

  if (hiddenBoxes.includes(box)) {
    return null
  }

  return (
    <>
      {showLabel && (
        <>
          {box.label.name && (
            <Label x={box.x + box.w} y={box.y}>
              <Tag pointerWidth={100} fill="#111111" />
              <Text
                fontSize={14 / scale}
                text={`${box.label.name} `}
                fill="white"
                padding={2 / scale}
              />
            </Label>
          )}
          {box.sublabel && (
            <Label x={box.x + box.w} y={box.y + 18 / scale}>
              <Tag pointerWidth={10} fill="white" />
              <Text
                fontSize={12 / scale}
                text={`${box.sublabel.name} `}
                fill="#111111"
                padding={2 / scale}
              />
            </Label>
          )}
        </>
      )}
      <Rect
        x={box.x}
        y={box.y}
        width={box.w}
        height={box.h}
        scaleX={1}
        scaleY={1}
        onClick={onSelect}
        onTap={onSelect}
        ref={boxRef}
        stroke={color || box.label?.color}
        strokeWidth={2}
        strokeScaleEnabled={false}
        draggable={!!onChange && isSelected}
        dragBoundFunc={(point) => {
          return absoluteTransform.point(
            clamp(invertAbsoluteTransform.point(point), { x: box.w, y: box.h })
          )
        }}
        onDragEnd={
          onChange
            ? (e) => {
                onChange({
                  x: Math.max(0, e.target.x()),
                  y: Math.max(0, e.target.y()),
                })
              }
            : undefined
        }
        onTransformEnd={
          onChange
            ? (e) => {
                // transformer is changing scale of the node
                // and NOT its width or height.
                // But in the store we have only width and height.
                // To match the data better, we will reset scale on transform end
                // debugger
                const node = boxRef.current
                if (!node) return

                const width = node.width()
                const height = node.height()
                const scaleX = node.scaleX()
                const scaleY = node.scaleY()

                const newWidth = width * scaleX
                const newHeight = height * scaleY

                const newBox = {
                  x: node.x(),
                  y: node.y(),
                  // set minimal value
                  w: newWidth,
                  h: newHeight,
                }

                // we will reset it back
                node.scaleX(1)
                node.scaleY(1)
                onChange(newBox)
              }
            : undefined
        }
      />
      {onChange && isSelected && (
        <Transformer
          ref={transformerRef}
          rotateEnabled={false}
          strokeScaleEnabled={true}
          keepRatio={false}
          ignoreStroke={true}
          boundBoxFunc={(oldBox, newBox) => {
            // the clamp code breaks if width or height are negative
            // this will happen momentarily when resizing past zero
            // in which case we ignore and return old box
            // (kind of a bug in konva IMO)
            if (newBox.width < 0 || newBox.height < 0) {
              console.log(newBox)
              return oldBox
            }

            const newTopLeft = { x: newBox.x, y: newBox.y }
            const newBottomRight = {
              x: newBox.x + newBox.width,
              y: newBox.y + newBox.height,
            }
            const topLeft = absoluteTransform.point(
              clamp(invertAbsoluteTransform.point(newTopLeft))
            )
            const bottomRight = absoluteTransform.point(
              clamp(invertAbsoluteTransform.point(newBottomRight))
            )

            return {
              ...topLeft,
              width: bottomRight.x - topLeft.x,
              height: bottomRight.y - topLeft.y,
              rotation: 0,
            }
          }}
        />
      )}
    </>
  )
}
