import { DateTime } from 'luxon'
import { Clip } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'

export interface ClipExtended extends Clip {
  startDateTime: DateTime
  endDateTime: DateTime
  // system times are the real world times, shifted to the browser system's timezone,
  // because the timeline D3 scale can only be in that timezone, or in UTC
  systemStartDateTime: DateTime
  systemEndDateTime: DateTime
}

export function extendClip(
  clip: Clip,
  project: ProjectDetailsExtended
): ClipExtended {
  const startDateTime = DateTime.fromISO(clip.start_datetime, {
    zone: project.timezone,
  })

  const endDateTime = clip.end_datetime
    ? DateTime.fromISO(clip.end_datetime, { zone: project.timezone })
    : startDateTime.plus({ minutes: 5 })

  const systemStartDateTime = startDateTime.setZone('system', {
    keepLocalTime: true,
  })

  const systemEndDateTime = endDateTime.setZone('system', {
    keepLocalTime: true,
  })

  return {
    ...clip,
    startDateTime,
    endDateTime,
    systemStartDateTime,
    systemEndDateTime,
  }
}
