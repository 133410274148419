import { TableCell, TableRow, useTheme } from '@material-ui/core'
import React from 'react'
import { CraneCalendarEntryExtended } from '../../../api/CraneCalendarEntryExtended'
import { CranePickExtended } from '../../../api/CranePickExtended'
import { CranePicksCollection } from '../../../api/CranePicksCollection'
import { useStaticTimelineStore } from '../Timeline/StaticTimeline'
import { getUseVODStore } from '../VODController'
import { Idle, useCraneStore } from './CranePicksController'

type SubcontractorStats = {
  name: string
  color: string
  calendarEntries: CraneCalendarEntryExtended[]
  picks: CranePickExtended[]
} & CranePicksCollection

export const StaticCraneTimeline = () => {
  const theme = useTheme()
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()
  const staticTimelineStore = useStaticTimelineStore()

  const videoDate = vodStore((state) => state.videoDate).setZone('system', {
    keepLocalTime: true,
  })

  const picks = craneStore((state) => state.picks)
  const idles = craneStore((state) => state.idles)
  const calendarEntries = craneStore((state) => state.calendarEntries)
  const subcontractors = craneStore((state) => state.subcontractors)
  const sumPicks = craneStore((state) => state.sumPicks)

  const scale = staticTimelineStore((state) => state.scale)

  const filteredSubcontractors: SubcontractorStats[] = Array.from(
    subcontractors.values()
  )
    .map((subcontractor, i) => {
      return sumPicks({
        name: subcontractor.name,
        color: subcontractor.color || '#999',
        calendarEntries: calendarEntries.filter(
          (entry) => entry.subcontractor_link === subcontractor.id
        ),
        picks: picks.filter(
          (pick) => pick.subcontractor_id === subcontractor.id
        ),
      })
    })
    .filter((sub) => sub.calendarEntries.length > 0 || sub.picks.length > 0)
    .sort((a, b) => b.totalDuration.toMillis() - a.totalDuration.toMillis())

  const hasCalendarEntries = calendarEntries.length > 0

  return (
    <>
      {filteredSubcontractors.map((subcontractor, i) => {
        return (
          <React.Fragment key={subcontractor.name}>
            {hasCalendarEntries && (
              <TableRow
                key={`${subcontractor.name}-as-planned`}
                style={{
                  backgroundColor: theme.palette.action.hover,
                  WebkitPrintColorAdjust: 'exact',
                }}
              >
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    paddingLeft: 8,
                    paddingRight: 20,
                  }}
                >{`${
                  subcontractor.name || 'Unspecified'
                } AS-PLANNED`}</TableCell>
                <TableCell style={{ position: 'relative', overflow: 'hidden' }}>
                  {subcontractor.calendarEntries.map((entry) => (
                    <CalendarEntry
                      key={entry.id}
                      entry={entry}
                      subcontractor={subcontractor}
                    />
                  ))}
                  <HourGrid />
                </TableCell>
              </TableRow>
            )}
            <TableRow
              key={`${subcontractor.name}-as-build`}
              style={{
                backgroundColor:
                  !hasCalendarEntries && i % 2
                    ? theme.palette.action.hover
                    : theme.palette.background.default,
                WebkitPrintColorAdjust: 'exact',
              }}
            >
              <TableCell
                style={{
                  whiteSpace: 'nowrap',
                  paddingLeft: 8,
                  paddingRight: 20,
                }}
              >{`${subcontractor.name || 'Unspecified'} AS-BUILT`}</TableCell>
              <TableCell style={{ position: 'relative', overflow: 'hidden' }}>
                {subcontractor.picks.map((pick) => (
                  <TimelinePick
                    key={pick.id}
                    pick={pick}
                    collapsed={true}
                    subcontractor={subcontractor}
                  />
                ))}
                <HourGrid />
              </TableCell>
            </TableRow>
          </React.Fragment>
        )
      })}
      <TableRow
        key={`idle`}
        style={{
          backgroundColor:
            hasCalendarEntries || filteredSubcontractors.length % 2
              ? theme.palette.action.hover
              : theme.palette.background.default,
          WebkitPrintColorAdjust: 'exact',
        }}
      >
        <TableCell
          style={{
            whiteSpace: 'nowrap',
            paddingLeft: 8,
            paddingRight: 20,
          }}
        >{`IDLE`}</TableCell>
        <TableCell style={{ position: 'relative', overflow: 'hidden' }}>
          {idles.map((idle, i) => (
            <TimelineIdle key={i} idle={idle} />
          ))}
          <HourGrid />
        </TableCell>
      </TableRow>
    </>
  )
}

const CalendarEntry = ({
  entry,
  subcontractor,
}: {
  entry: CraneCalendarEntryExtended
  subcontractor: SubcontractorStats
}) => {
  const staticTimelineStore = useStaticTimelineStore()
  const vodStore = getUseVODStore()

  const scale = staticTimelineStore((state) => state.scale)

  const x = Math.floor(scale(entry.systemStartDateTime))
  const x1 = Math.floor(scale(entry.systemEndDateTime))
  const width = x1 - x

  return (
    <div
      style={{
        backgroundColor: subcontractor.color,
        position: 'absolute',
        width: width - 1,
        left: x,
        top: 0,
        height: '100%',
        colorAdjust: 'exact',
      }}
      onClick={() => {
        vodStore.getState().gotoVideo({
          dateTime: entry.startDateTime.plus({
            milliseconds: Math.random() * 1000,
          }),
        })
      }}
    />
  )
}

const TimelinePick = ({
  pick,
  subcontractor,
  collapsed,
}: {
  pick: CranePickExtended
  subcontractor: SubcontractorStats
  collapsed: boolean
}) => {
  const staticTimelineStore = useStaticTimelineStore()
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()

  const scale = staticTimelineStore((state) => state.scale)

  const x = Math.floor(scale(pick.systemStartDateTime))
  const x1 = Math.floor(scale(pick.systemEndDateTime))
  const idleX1 =
    pick.type === 'pick'
      ? Math.floor(scale(pick.systemStartDateTime.plus(pick.idleDuration)))
      : x
  const width = collapsed ? x1 - x : Math.max(x1 - x, 4)
  const idleWidth = idleX1 - x

  return (
    <div
      style={{
        backgroundColor: subcontractor.color,
        position: 'absolute',
        width: width - 1,
        left: x,
        height: '100%',
        top: 0,
        WebkitPrintColorAdjust: 'exact',
      }}
      onClick={() => {
        if (pick.type === 'pick') {
          craneStore.getState().setPick(pick)
        } else {
          craneStore.setState({
            pick: undefined,
          })
          vodStore.getState().gotoVideo({
            dateTime: pick.startDateTime.plus({
              milliseconds: Math.random() * 1000,
            }),
            streamId: pick.stream,
          })
        }
      }}
    />
  )
}

const TimelineIdle = ({ idle }: { idle: Idle }) => {
  const theme = useTheme()
  const staticTimelineStore = useStaticTimelineStore()
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()

  const scale = staticTimelineStore((state) => state.scale)

  const x = Math.floor(scale(idle.systemStartDateTime))
  const x1 = Math.floor(scale(idle.systemEndDateTime))
  const width = Math.max(x1 - x, 4)

  return (
    <div
      style={{
        backgroundColor: theme.palette.error.main,
        position: 'absolute',
        width: width - 1,
        left: x,
        height: '100%',
        top: 0,
        WebkitPrintColorAdjust: 'exact',
      }}
      onClick={() => {
        craneStore.setState({
          pick: undefined,
        })
        vodStore.getState().gotoVideo({
          dateTime: idle.startDateTime.plus({
            milliseconds: Math.random() * 1000,
          }),
        })
      }}
    />
  )
}

const HourGrid = () => {
  const theme = useTheme()
  const staticTimelineStore = useStaticTimelineStore()
  const vodStore = getUseVODStore()

  const scale = staticTimelineStore((state) => state.scale)

  const videoDate = vodStore((state) => state.videoDate).setZone('system', {
    keepLocalTime: true,
  })

  const hoursArray: number[] = []
  for (let i = 0; i < 24; i++) {
    hoursArray.push(i)
  }
  return (
    <>
      {hoursArray.map((i) => (
        <div
          key={i}
          style={{
            position: 'absolute',
            top: 0,
            height: '100%',
            width: 1,
            backgroundColor: theme.palette.divider,
            left: scale(videoDate.plus({ hours: i }).toJSDate()),
          }}
        ></div>
      ))}
    </>
  )
}
