import {
  Box,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  Select,
  Typography,
} from '@material-ui/core'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import React, { memo, useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { dateFormat } from '../../helpers/constants'
import { useApi } from '../ApiContext'
import { DatePicker } from '../FormInputs/DatePicker'
import { useProject } from '../ProjectWrapper'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles(
  () =>
    createStyles({
      gridItem: {
        flexGrow: 1,
        '&:first-child': {
          width: 150,
        },
        '& > *': {
          width: '100%',
        },
      },
    }),
  {
    name: 'VODDateTimeSelect',
  }
)

export const VODDateTimeSelect = memo(
  ({
    time = true,
    timeOptions: timeOptionsProp,
  }: {
    time?: boolean
    timeOptions?: {
      string: string
      dateTime: DateTime
    }[]
  }) => {
    // console.log('render VODDateTimeSelect')
    const classes = useStyles()
    const project = useProject()
    const api = useApi()
    const useVODStore = getUseVODStore()

    const gotoVideo = useVODStore((state) => state.gotoVideo)
    const videoStreamId = useVODStore((state) => state.videoStreamId)
    const videoDate = useVODStore((state) => state.videoDate)
    const videoHour = useVODStore((state) => state.videoHour)

    const { value: timeOptions } = useAsync(async () => {
      if (timeOptionsProp) return timeOptionsProp

      const videos = await api.getvideosByDate(project, videoDate)

      if (!videoStreamId) {
        // shouldn't happen
        throw 'no streamId'
      }

      const times = uniq(
        videos
          .get(videoStreamId)!
          .get('1x')!
          .map((v) => v.localStartDateTime)
          .map((time) =>
            time.minute < 59
              ? time.startOf('hour')
              : time.startOf('hour').plus({ hours: 1 })
          )
          .map((time) => time.toMillis())
      )

      const timeOptions = times.map((s) => {
        const dateTime = DateTime.fromMillis(s).setZone(project.timezone)
        return {
          string: dateTime.toFormat('h a'),
          dateTime: dateTime,
        }
      })
      return timeOptions
    }, [timeOptionsProp, videoDate, videoStreamId])

    return (
      <>
        <Box displayPrint="none">
          <Grid container spacing={2}>
            <Grid item className={classes.gridItem}>
              <DatePicker
                value={videoDate.toFormat(dateFormat)}
                onChange={(d) => {
                  gotoVideo({
                    dateTime: DateTime.fromFormat(d, dateFormat, {
                      zone: project.timezone,
                    }).set({ hour: 7 }),
                    skipUnarchiveModal: true,
                    goToNearestTime: true,
                  })
                }}
                dates={project.videoArchiveDates.map((d) => d.dateString)}
                autoSelect={false}
              />
            </Grid>
            {time && (
              <Grid item className={classes.gridItem}>
                <FormControl fullWidth>
                  <Select
                    native
                    id="time-select"
                    value={videoHour.toFormat('h a') || ''}
                    onChange={(e) => {
                      if (timeOptions) {
                        gotoVideo({
                          dateTime: timeOptions
                            .find((t) => t.string === e.target.value)
                            ?.dateTime!.plus({ seconds: 1 }),
                          goToNearestTime: true,
                        })
                      }
                    }}
                  >
                    {timeOptions &&
                      timeOptions.map((time) => (
                        <option key={time.string} value={time.string}>
                          {time.string}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box display="none" displayPrint="block">
          <Typography variant="body1">
            {time
              ? videoHour.toLocaleString(DateTime.DATETIME_MED)
              : videoHour.toLocaleString(DateTime.DATE_FULL)}
          </Typography>
        </Box>
      </>
    )
  }
)

VODDateTimeSelect.displayName = 'VODDateTimeSelect'
