import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Layer } from 'react-konva'
import { Link, useSearchParams } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import {
  ImageAnnotationBox,
  ImageAnnotationBoxDetails,
} from '../../api/codegen/typescript-axios'
import { dateTimeFormat } from '../../helpers/constants'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { Loading } from '../Loading'
import { AnnotatorContext } from './Annotator'
import { AnnotatorCanvasBoxes } from './AnnotatorCanvasBoxes'
import { VideoCanvas } from './VideoCanvas'

export const AnnotationIssue = ({ issueId }: { issueId: string }) => {
  const api = useApi()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedBox, setSelectedBox] = useState<ImageAnnotationBox>()
  const [hiddenBoxes, setHiddenBoxes] = useState<ImageAnnotationBox[]>([])
  const [issue, setIssue] = useState<ImageAnnotationBoxDetails>()
  const [commentText, setCommentText] = useState('')
  const [issueResolved, setIssueResolved] = useState(false)

  const { loading } = useAsync(async () => {
    if (issueId) {
      const resp = await api.imageAnnotationsApi.imageannotationFlaggedboxesRead(
        { id: issueId }
      )
      setIssue(resp.data)
      setIssueResolved(resp.data.issue_resolved || false)
      return resp.data
    } else {
      return undefined
    }
  }, [issueId])

  const [addCommentState, addComment] = useAsyncFn(async () => {
    if (!issue) return
    const resp = await api.imageAnnotationsApi.imageannotationFlaggedboxesAddComment(
      {
        id: issue.id!.toString(),
        data: {
          comment: commentText,
          issue_resolved: issueResolved,
        },
      }
    )
    setIssue(resp.data)
    setCommentText('')
    return resp.data
  }, [commentText, issueResolved])

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          padding: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {issue && (
            <Link
              to={`/annotations/frame/${issue.frame!.id}`}
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Button color="primary">Go to frame</Button>
            </Link>
          )}
        </div>
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            searchParams.delete('issue')
            setSearchParams(searchParams, { replace: true })
          }}
        />
      </div>
      <div style={{ position: 'relative', flexGrow: 1, display: 'flex' }}>
        {loading ? (
          <Loading />
        ) : issue ? (
          <>
            <div
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
            >
              <form onSubmit={() => {}} style={{ padding: 10 }}>
                <TextField
                  label="Comment"
                  multiline
                  rows={4}
                  value={commentText}
                  onChange={(e) => {
                    setCommentText(e.target.value)
                  }}
                  variant="outlined"
                  fullWidth
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={issueResolved}
                      onChange={(e) => setIssueResolved(e.target.checked)}
                      name="issue resolved"
                    />
                  }
                  label="Issue Resolved"
                />
                <ProgressButton
                  fetchState={addCommentState}
                  onClick={addComment}
                  fullWidth
                >
                  Add Comment
                </ProgressButton>
              </form>
              {issue.comments.length > 0 && (
                <List>
                  {issue.comments.map((c) => (
                    <ListItem key={c.comment_datetime}>
                      <ListItemText
                        primary={
                          <>
                            <strong>{c.user.first_name}:</strong> {c.comment}
                          </>
                        }
                        secondary={format(
                          new Date(c.comment_datetime),
                          dateTimeFormat
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
            <div style={{ position: 'relative', flexGrow: 1 }}>
              <VideoCanvas
                imageUrl={issue.frame!.image_url}
                showVideo={false}
                listening={true}
                setSelectedBox={setSelectedBox}
                mode={'select'}
                scaleExtent={[0.94, 10]}
              >
                <AnnotatorContext.Provider
                  value={{
                    selectedBox,
                    setSelectedBox,
                    hiddenBoxes,
                    setHiddenBoxes,
                  }}
                >
                  <Layer>
                    <AnnotatorCanvasBoxes
                      boxes={[issue]}
                      selectedBox={selectedBox}
                      setSelectedBox={setSelectedBox}
                      labelState={'all'}
                      boxColor="#00f"
                    />
                  </Layer>
                </AnnotatorContext.Provider>
              </VideoCanvas>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}
