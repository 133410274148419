import React, { useState } from 'react'
import { useAsync, useLocalStorage } from 'react-use'
import { useAlertConfirmPrompt } from './AlertConfirmPrompt'
import iosActionIcon from '../../images/icon/ios_navigation_action_2x.png'

export const PWAInstall = ({}: {}) => {
  const { alert, confirm } = useAlertConfirmPrompt()
  const [deferredPrompt, setDeferredPrompt] = useState<Event>()
  const [pwaDismissed, setPwaDismissed] = useLocalStorage('pwaDismissed', false)
  const inPWA = matchMedia('(display-mode: standalone)').matches

  window.addEventListener('beforeinstallprompt', (e: Event) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault()
    // Stash the event so it can be triggered later.
    setDeferredPrompt(e)
  })

  useAsync(async () => {
    if (pwaDismissed || inPWA) return

    if (
      // @ts-ignore
      !window.BeforeInstallPromptEvent &&
      /iphone|ipod|ipad/i.test(navigator.userAgent)
    ) {
      try {
        await alert({
          title: 'NEW: You can now install Topdeck Dashboard as a mobile app!',
          description: (
            <span>
              Tap{' '}
              <img
                src={iosActionIcon}
                style={{
                  width: '24px',
                  verticalAlign: 'sub',
                  margin: '0px 1px 0px 0px',
                }}
              />{' '}
              below, then scroll down and tap "Add to Home Screen"
            </span>
          ),
        })
      } catch {
      } finally {
        setPwaDismissed(true)
      }
    } else if (deferredPrompt) {
      try {
        await confirm({
          title: 'NEW: Would you like to install this as an app?',
        })
        // @ts-ignore
        deferredPrompt.prompt()
      } catch {
      } finally {
        setPwaDismissed(true)
      }
    }
  }, [deferredPrompt])

  return null
}
