import { Checkbox, FormControlLabel } from '@material-ui/core'
import { omit, uniq } from 'lodash'
import { DateTime } from 'luxon'
import React, { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync, useInterval } from 'react-use'
import zustand, { UseStore } from 'zustand'
import {
  CraneCalendarEntryExtended,
  extendCraneCalendarEntry,
} from '../../../api/CraneCalendarEntryExtended'
import {
  CraneEventExtended,
  extendCraneEvent,
} from '../../../api/CraneEventExtended'
import {
  CranePickExtended,
  extendCranePick,
} from '../../../api/CranePickExtended'
import {
  CranePicksCollection,
  createSumPicksFunction,
} from '../../../api/CranePicksCollection'
import { assertId, HasID } from '../../../api/assertRequired'
import {
  Crane,
  CraneDay,
  CraneEvent,
  CraneEventState,
  ProjectConfig,
  Subcontractor,
} from '../../../api/codegen/typescript-axios'
import { createCtx } from '../../../helpers/createCtx'
import { useAlertConfirmPrompt } from '../../AlertConfirmPrompt'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore, vodQueryKeys } from '../VODController'
import i18n from 'i18next'

export interface ExtendedCraneEvent extends CraneEvent {
  eventDateTime: DateTime
  systemEventDateTime: DateTime
}

export interface CraneDayPicksCollection extends CranePicksCollection {
  date: DateTime
  picks: CranePickExtended[]
}

export interface Idle {
  startDateTime: DateTime
  systemStartDateTime: DateTime
  endDateTime: DateTime
  systemEndDateTime: DateTime
}

export interface CraneWeek extends CranePicksCollection {
  startDateTime: DateTime // Monday 00:00
  systemStartDateTime: DateTime
  endDateTime: DateTime // Sunday 23:59 or current day, whichever is sooner
  systemEndDateTime: DateTime
}

export type StoreState = {
  projectConfig?: ProjectConfig
  selectedCrane?: HasID<Crane>
  setSelectedCraneId: (id: number) => void
  craneDay?: CraneDay
  days: Map<string, CraneDayPicksCollection>
  weeks: Map<string, CraneWeek>
  picks: CranePickExtended[]
  idles: Idle[]
  pick?: CranePickExtended
  calendarEntries: CraneCalendarEntryExtended[]
  events: ExtendedCraneEvent[]
  craneEventStates: Map<number, CraneEventState>
  eventFormState: Partial<CraneEvent>
  pageLoading: boolean
  loading: boolean
  subcontractors: Map<number | null, Subcontractor>
  supercategories: Set<string>
  prevSubcontractor: number | null
  prevLoad: string
  prevAction: string
  loadOptions: Set<string>
  showVideo: boolean
  ignoreStreamWarning: boolean
  sumPicks: ReturnType<typeof createSumPicksFunction>
  setPick: (pick: CranePickExtended) => void
  fetchPicks: (previousDays: number) => void
  fetchProjectConfig: () => void
  fetchCraneDay: () => void
  createCraneDay: () => void
  editCraneDay: (partialCraneDay: Partial<CraneDay>) => void
  fetchCalendar: () => void
  fetchEventStates: () => void
  fetchEvents: () => void
  createEvent: (
    eventState: CraneEventState
  ) => Promise<CraneEventExtended | undefined>
  saveEvent: (
    event: Partial<CraneEvent>
  ) => Promise<CraneEventExtended | undefined>
  deleteEvent: ({
    eventId,
    craneId,
  }: {
    eventId: number
    craneId: number
  }) => void
  editEventForm: (event: Partial<CraneEvent>) => void
}

export type UseCraneStore = UseStore<StoreState>

export const [useCraneStore, CraneContext] = createCtx<UseCraneStore>()

export const CranePicksController: FC = ({ children }) => {
  const api = useApi()
  const project = useProject()

  const useVODStore = getUseVODStore()
  const { alert, confirm } = useAlertConfirmPrompt()

  const videoDate = useVODStore((state) => state.videoDate)

  const [searchParams, setSearchParams] = useSearchParams()
  const craneParam = searchParams.get('crane')
  const dateTimeParam = searchParams.get('dateTime')

  // init store
  const [useStore] = useState(() =>
    zustand<StoreState>((set, get) => ({
      projectConfig: undefined,
      selectedCrane: project.cranes.find((c) => c.id.toString() === craneParam),
      setSelectedCraneId: (id) => {
        const crane = project.cranes.find((c) => c.id === id)

        if (!crane) return

        const newSearchParams = new URLSearchParams(window.location.search)
        newSearchParams.set('crane', id.toString())
        if (crane.jib_stream_original) {
          newSearchParams.set('streamId', crane.jib_stream_original.toString())
        }
        setSearchParams(newSearchParams, { replace: true })
      },
      craneDay: undefined,
      days: new Map(),
      weeks: new Map(),
      picks: [],
      idles: [],
      pick: undefined,
      calendarEntries: [],
      events: [],
      craneEventStates: new Map(),
      eventFormState: {},
      subcontractors: new Map([[null, { name: '', color: '#999' }]]),
      prevSubcontractor: null,
      supercategories: new Set(),
      prevLoad: '',
      prevAction: '',
      loadOptions: new Set(),
      showVideo: false,
      pageLoading: true,
      loading: false,
      ignoreStreamWarning: false,
      sumPicks: createSumPicksFunction(),
      setPick: (pick: CranePickExtended) => {
        set({ pick: pick })
        useVODStore.getState().gotoVideo({
          dateTime: pick.startDateTime.plus({
            // TODO: fix this hack
            milliseconds: Math.random() * 1000,
          }),
          streamId: pick.stream,
        })
      },
      fetchPicks: async (previousDays) => {
        const crane = get().selectedCrane

        if (!crane) return

        const videoDate = useVODStore.getState().videoDate

        const resp = await api.craneApi.cranesPicksList({
          craneId: crane.id.toString(),
          startTime: videoDate.minus({ days: previousDays }).toISO(),
          endTime: videoDate.endOf('day').toISO(),
        })

        const picks = resp.data.map((p) => extendCranePick(p, project))

        const days = new Map<string, CraneDayPicksCollection>(
          uniq(picks.map((p) => p.startDateTime.toISODate()))
            .map((date) => {
              const datePicks = picks.filter(
                (p) => p.startDateTime.toISODate() === date
              )
              return get().sumPicks({
                date: DateTime.fromISO(date),
                picks: datePicks,
              })
            })
            .map((day) => [day.date.toISODate(), day])
        )

        if (
          // this is a fresh page load
          get().pageLoading &&
          // there is no explicit date set in the url
          !dateTimeParam &&
          // there are no picks for the current day
          !days.has(videoDate.toISODate())
        ) {
          set({ pageLoading: false })
          // load the most recent day with picks
          console.log(
            `no picks for current day, ${videoDate.toISODate()} loading most recent day with picks`
          )
          const mostRecentDay = Array.from(days.keys())
            .map((d) => DateTime.fromISO(d))
            .sort((a, b) => b.diff(a).milliseconds)
            .map((d) => d.toISODate())[0]

          if (mostRecentDay) {
            useVODStore.getState().gotoVideo({
              dateTime: DateTime.fromISO(mostRecentDay).startOf('day'),
            })
          }
        }

        set({ pageLoading: false })

        const mergedDays = new Map([...get().days, ...days])
        const currentDayPicks =
          mergedDays.get(videoDate.toISODate())?.picks || []

        const currentDayIdles =
          mergedDays.get(videoDate.toISODate())?.idles || []

        const allPicks = Array.from(mergedDays.values()).reduce<
          CranePickExtended[]
        >((allPicks, day) => {
          return allPicks.concat(day.picks)
        }, [])

        const weeks = new Map<string, CraneWeek>(
          uniq(allPicks.map((p) => p.startDateTime.weekNumber))
            .map((weekNumber) => {
              const weekPicks = allPicks.filter(
                (p) => p.startDateTime.weekNumber === weekNumber
              )
              const startDateTime = weekPicks[0].startDateTime.startOf('week')
              const endDateTime = weekPicks[0].startDateTime.endOf('week')
              return get().sumPicks({
                startDateTime,
                systemStartDateTime: startDateTime.setZone('system', {
                  keepLocalTime: true,
                }),
                endDateTime: endDateTime,
                systemEndDateTime: endDateTime.setZone('system', {
                  keepLocalTime: true,
                }),
                picks: weekPicks,
              })
            })
            .map((week) => [week.startDateTime.toISODate(), week])
        )

        set({
          weeks,
          days: mergedDays,
          picks: currentDayPicks,
          idles: currentDayIdles,
        })
      },
      fetchProjectConfig: async () => {
        const resp = await api.craneApi.projectsCraneProjectConfigsList({
          projectId: project.id.toString(),
        })
        if (resp.data.length > 0) {
          const config = resp.data[0]

          set({
            projectConfig: config,
            sumPicks: createSumPicksFunction(config),
            subcontractors: new Map(
              config.subcontractors.map((s) => {
                assertId(s)
                return [s.id, s]
              })
            ),
            supercategories: new Set<string>(
              config.subcontractors
                .map((s) => s.supercategory)
                .filter((s) => s !== null && s !== undefined)
                .sort((a, b) =>
                  a.localeCompare(b, i18n.language, { numeric: true })
                )
                // this is a hack to make sure "Prefa" is first for GA customer.
                // Fix if this needs to be more intelligent in the future
                .reverse()
            ),
          })
        }
      },
      fetchCraneDay: async () => {
        const craneId = get().selectedCrane?.id.toString()
        const day = useVODStore.getState().videoDate

        if (!craneId) {
          return
        }

        const resp = await api.craneApi.cranesDaysList({
          craneId,
          startDate: day.toISODate(),
          endDate: day.endOf('day').toISODate(),
        })

        set({
          craneDay: resp.data[0],
        })
      },
      createCraneDay: async () => {
        const craneId = get().selectedCrane?.id.toString()
        const day = useVODStore.getState().videoDate

        if (!craneId) {
          return
        }

        const resp = await api.craneApi.cranesDaysCreate({
          craneId,
          data: {
            day: day.toISODate(),
          },
        })

        set({
          craneDay: resp.data,
        })
      },
      editCraneDay: async (partialCraneDay: Partial<CraneDay>) => {
        const craneId = get().selectedCrane?.id.toString()
        const day = useVODStore.getState().videoDate
        const { craneDay, events, craneEventStates } = get()

        if (!craneId || !craneDay) {
          return
        }

        assertId(craneDay)

        if (
          partialCraneDay.complete === true &&
          craneEventStates.get(events[0].state)?.slug !== 'start'
        ) {
          try {
            await alert({
              title: "It appears you haven't created a start event yet.",
              description:
                'Each day must begin with a start event. Also remember to create a start event when activity resumes after lunch breaks.',
            })
          } finally {
            return undefined
          }
        }

        const resp = await api.craneApi.cranesDaysPartialUpdate({
          craneId,
          id: craneDay.id.toString(),
          data: {
            ...partialCraneDay,
            day: day.toISODate(),
          },
        })

        set({
          craneDay: resp.data,
        })
      },
      fetchCalendar: async () => {
        const crane = get().selectedCrane
        if (!crane) return

        const day = useVODStore.getState().videoDate

        const resp = await api.craneApi.cranesCalendarList({
          craneId: crane.id.toString(),
          startTime: day.startOf('day').toISO(),
          endTime: day.endOf('day').toISO(),
        })

        set({
          calendarEntries: resp.data
            .map((entry) => {
              return extendCraneCalendarEntry(entry, project)
            })
            .sort(
              (a, b) => a.startDateTime.toMillis() - b.startDateTime.toMillis()
            ),
        })
      },
      fetchEventStates: async () => {
        const resp = await api.craneApi.craneEventStatesList()
        set({
          craneEventStates: new Map(
            resp.data.map((state) => [state.id || -1, state])
          ),
        })
      },
      fetchEvents: async () => {
        const crane = get().selectedCrane
        if (!crane) return

        const day = useVODStore.getState().videoDate

        const resp = await api.craneApi.cranesEventsList({
          craneId: crane.id.toString(),
          startTime: day.toISO(),
          endTime: day.endOf('day').toISO(),
        })

        const loadOptions = new Set<string>()

        set({
          events: resp.data
            .map((event) => {
              if (event.load_data) {
                loadOptions.add(event.load_data)
              }
              return extendCraneEvent(event, project)
            })
            .sort(
              (a, b) => a.eventDateTime.toMillis() - b.eventDateTime.toMillis()
            ),
          loadOptions: loadOptions,
        })
      },
      createEvent: async (eventState: CraneEventState) => {
        const crane = get().selectedCrane
        if (!crane) return

        const streamId = useVODStore.getState().videoStreamId

        if (
          !get().ignoreStreamWarning &&
          crane.jib_stream_original !== streamId
        ) {
          try {
            await confirm({
              title: "Are you sure you're watching the right stream?",
              description: (
                <div>
                  You're trying to save an event while watch a stream that isn't
                  the crane's primary jib stream. Make you're watch the right
                  stream before continuing.
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={get().ignoreStreamWarning}
                        onChange={(e) =>
                          set({ ignoreStreamWarning: e.target.checked })
                        }
                      />
                    }
                    label="Don't warn me again"
                  />
                </div>
              ),
            })
          } catch {
            return undefined
          }
        }

        // create new event
        const resp = await api.craneApi.cranesEventsCreate({
          craneId: crane.id.toString(),
          data: {
            crane: crane.id,
            state: eventState.id!,
            event_datetime: useVODStore.getState().videoDateTime.toISO(),
          },
        })

        get().fetchEvents()
        get().fetchPicks(0)

        // if (eventState.slug === 'loading') {
        //   set({ eventFormState: resp.data })
        // }

        return extendCraneEvent(resp.data, project)
      },
      saveEvent: async (event) => {
        const crane = get().selectedCrane
        if (!crane) return

        const streamId = useVODStore.getState().videoStreamId

        if (
          !get().ignoreStreamWarning &&
          crane.jib_stream_original !== streamId
        ) {
          try {
            await confirm({
              title: "Are you sure you're watching the right stream?",
              description: (
                <div>
                  You're trying to save an event while watch a stream that isn't
                  the crane's primary jib stream. Make you're watch the right
                  stream before continuing.
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={get().ignoreStreamWarning}
                        onChange={(e) =>
                          set({ ignoreStreamWarning: e.target.checked })
                        }
                      />
                    }
                    label="Don't warn me again"
                  />
                </div>
              ),
            })
          } catch {
            return undefined
          }
        }
        const state = event.state

        if (!state) {
          //  shouldnt happen. Both are non-nullable select inputs
          throw '!state'
        }

        const resp = await api.craneApi.cranesEventsPartialUpdate({
          id: event.id!.toString(),
          craneId: crane.id.toString(),
          data: {
            crane: crane.id,
            state: state,
            event_datetime:
              event.event_datetime || DateTime.fromMillis(0).toISO(),
            ...omit(
              event,
              'created_at',
              'created_by',
              'created_by_attrs',
              'updated_at',
              'updated_by',
              'updated_by_attrs',
              'subcontractor',
              'subcontractor_attrs',
              'eventDateTime',
              'systemEventDateTime'
            ),
          },
        })

        get().fetchEvents()
        get().fetchPicks(0)

        set({
          eventFormState: {},
          prevSubcontractor: event.subcontractor_link || null,
          prevLoad: event.load_data || '',
          prevAction: event.action || '',
        })

        return extendCraneEvent(resp.data, project)
      },
      deleteEvent: async ({ eventId, craneId }) => {
        await confirm({
          title: 'Are you sure you want to delete this event?',
        })

        await api.craneApi.cranesEventsDelete({
          craneId: craneId.toString(),
          id: eventId.toString(),
        })

        set({ eventFormState: {} })

        get().fetchEvents()
        get().fetchPicks(0)

        set({ eventFormState: {} })
      },
      editEventForm: (partialEvent) => {
        set({
          eventFormState: {
            ...get().eventFormState,
            ...partialEvent,
          },
        })
      },
    }))
  )

  // fetch event states on mount
  React.useEffect(() => {
    useStore.getState().fetchEventStates()
    useStore.getState().fetchProjectConfig()
  }, [])

  // update selectedCrane if a new one is received from API
  React.useEffect(() => {
    const currentCrane = useStore.getState().selectedCrane
    if (!currentCrane) return

    const newCrane = project.cranes.find(
      (crane) => crane.id === currentCrane.id
    )

    if (currentCrane !== newCrane) {
      useStore.setState({ selectedCrane: newCrane })
    }
  }, [project])

  const projectConfig = useStore((state) => state.projectConfig)

  // fetch new data when date and crane search params change, or new project config is received
  useAsync(async () => {
    const crane = project.cranes.find(
      (crane) => crane.id.toString() === craneParam
    )

    const projectConfig = useStore.getState().projectConfig

    if (!projectConfig) {
      return
    }

    if (!crane) {
      useStore.setState({ loading: false })
      useStore.getState().setSelectedCraneId(project.cranes[0]?.id)
      return
    }

    useStore.setState({
      selectedCrane: crane,
      loading: true,
    })

    useVODStore.setState({
      dateTimeRange: [videoDate.startOf('day'), videoDate.endOf('day')],
    })

    useVODStore.getState().gotoVideo({
      streamId: crane.jib_stream_original,
    })

    await Promise.all([
      useStore.getState().fetchPicks(9),
      useStore.getState().fetchCalendar(),
      useStore.getState().fetchEvents(),
      useStore.getState().fetchCraneDay(),
    ])

    const searchParams = new URLSearchParams(window.location.search)
    if (!searchParams.get(vodQueryKeys.dateTime)) {
      const firstEvent = useStore.getState().events[0]

      if (firstEvent) {
        useVODStore.getState().gotoVideo({
          dateTime: firstEvent.eventDateTime,
        })
      } else {
        console.warn('no crane events on this day')
      }
    }

    useStore.setState({ loading: false })
  }, [videoDate.toISODate(), craneParam, projectConfig])

  // update selected pick as video plays into it
  useInterval(() => {
    const videoTime = useVODStore.getState().videoDateTime
    const currentPick = useStore.getState().picks.find((p) => {
      return p.startDateTime <= videoTime && p.endDateTime > videoTime
    })
    if (currentPick && useStore.getState().pick !== currentPick) {
      useStore.setState({ pick: currentPick })
    }
  }, 1000)

  // automatically pause/video video when form is shown/hidden
  const eventFormState = useStore((state) => state.eventFormState)
  React.useEffect(() => {
    const video = useVODStore.getState().videoRef.current
    if (!video) return

    if (eventFormState.id) {
      video.pause()
    } else {
      video.play()
    }
  }, [eventFormState.id])

  return (
    <CraneContext.Provider value={useStore}>{children}</CraneContext.Provider>
  )
}
