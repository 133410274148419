import { DateTime } from 'luxon'
import { assertId, HasID } from './assertRequired'
import {
  ProjectDetails,
  PublicProject,
  PublicStream,
  Stream,
} from './codegen/typescript-axios'
import {
  ProjectDetailsExtended,
  PublicProjectExtended,
} from './ProjectDetailsExtended'

export interface StreamExtended extends HasID<Stream> {
  rotation: number
  width: number
  aspectRatioNumber: number
  rotatedAspectRatioNumber: number
  project: ProjectDetails
  ptzResetDateTime: DateTime | undefined
}

export interface PublicStreamExtended extends PublicStream {
  rotation: number
  width: number
  aspectRatioNumber: number
  rotatedAspectRatioNumber: number
  project: PublicProject
}

export function extendStream(
  stream: Stream,
  project: ProjectDetailsExtended
): StreamExtended {
  assertId(stream)

  let aspectRatio = stream.aspect_ratio || '16:9'
  const [x, y] = aspectRatio.split(':')
  const rotation = stream.rotation || 0
  const aspectRatioNumber = parseFloat(x) / parseFloat(y)
  const rotatedAspectRatioNumber =
    rotation % 180 === 90 ? 1 / aspectRatioNumber : aspectRatioNumber

  return {
    ...stream,
    rotation,
    width: 1,
    aspectRatioNumber,
    rotatedAspectRatioNumber,
    project,
    ptzResetDateTime: undefined,
  }
}

export function extendPublicStream(
  stream: PublicStream,
  project: PublicProjectExtended
): PublicStreamExtended {
  let aspectRatio = stream.aspect_ratio || '16:9'
  const [x, y] = aspectRatio.split(':')
  const rotation = stream.rotation || 0
  const aspectRatioNumber = parseFloat(x) / parseFloat(y)
  const rotatedAspectRatioNumber =
    rotation % 180 === 90 ? 1 / aspectRatioNumber : aspectRatioNumber
  return {
    ...stream,
    rotation,
    width: 1,
    aspectRatioNumber,
    rotatedAspectRatioNumber,
    project,
  }
}
