import { Grid, IconButton, Slider } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import { VideoStabilizationRequester } from './VideoStabilizationRequester'
import { useShoringStore } from './VODShoringController'

export const ShoringOverlaySettings = () => {
  const shoringStore = useShoringStore()

  const overlaySettings = shoringStore((state) => state.overlaySettings)
  const setOverlaySettings = shoringStore((state) => state.setOverlaySettings)

  const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {
    shoringStore.setState({ showOverlay: true })
    return () =>
      shoringStore.setState({
        showOverlay: false,
      })
  }, [shoringStore])

  return (
    <>
      <Grid item container spacing={1} justifyContent="space-between">
        <Grid item>
          Analytics | <VideoStabilizationRequester />
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            onClick={() => setShowSettings(!showSettings)}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
      </Grid>

      {showSettings && (
        <Grid item xs={12}>
          <Grid item>Circle radius: {overlaySettings.radius}</Grid>
          <Grid item xs>
            <Slider
              value={overlaySettings.radius}
              onChange={(e, value) =>
                setOverlaySettings({ radius: value as number })
              }
              aria-labelledby="continuous-slider"
            />
          </Grid>
          {(['noTimes', 'partialTimes', 'bothTimes'] as const).map((time) => (
            <ColorSetting key={time} time={time} />
          ))}
          <ColorSetting time={'selected'} />
          <ColorSetting time={'background'} />
          {/* <FormControlLabel
              label="Sequential Mode"
              control={
                <Checkbox
                  checked={overlaySettings.sequentialMode}
                  onChange={(event) =>
                    setOverlaySettings({ sequentialMode: event.target.checked })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
            /> */}
        </Grid>
      )}
    </>
  )
}

function ColorSetting({
  time,
}: {
  time: 'bothTimes' | 'partialTimes' | 'noTimes' | 'selected' | 'background'
}) {
  const shoringStore = useShoringStore()
  const overlaySettings = shoringStore((state) => state.overlaySettings)
  const setOverlaySettings = shoringStore((state) => state.setOverlaySettings)
  const [open, setOpen] = useState(false)
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid
        item
        xs={12}
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
      >
        <span
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            display: 'inline-block',
            marginRight: 6,
            backgroundColor: overlaySettings[time],
          }}
        ></span>
        {time}
      </Grid>
      <Grid item>
        {open && (
          <ChromePicker
            color={overlaySettings[time]}
            onChange={({ rgb }) => {
              setOverlaySettings({
                [time]: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
              })
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}
