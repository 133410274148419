import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import bbox from '@turf/bbox'
import { colord } from 'colord'
import mapboxgl from 'mapbox-gl'
import React from 'react'
import { MapRef } from 'react-map-gl'
import { ProjectMap } from '../../ProjectMap'
import { CraneLocations } from './CraneLocations'
import { CranePickHeatmap } from './CranePickHeatmap'
import { CranePickLines } from './CranePickLines'
import { useCraneStore } from './CranePicksController'

export const pickLinesSourceId = 'crane-picks-lines-source'

export const CranePicksMap = () => {
  const craneStore = useCraneStore()

  const mapRef = React.useRef<MapRef>(null)

  const [heatMapState, setHeatMapState] = React.useState<
    'off' | 'load' | 'unload'
  >('off')

  const [cursor, setCursor] = React.useState<string>('auto')

  const picks = craneStore((state) => state.picks)
  const pick = craneStore((state) => state.pick)

  const picksGeoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = React.useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: picks
        ? picks
            .filter(
              (p) =>
                p.start_longitude &&
                p.start_latitude &&
                p.end_longitude &&
                p.end_latitude
            )
            .reduce<Array<GeoJSON.Feature<GeoJSON.Geometry>>>(
              (previous, pick, i) => {
                const color = pick.subcontractor_color || 'rgb(0,0,0)'

                const duration = Math.sqrt(
                  pick.totalDuration.as('minutes') * 10
                )

                previous.push(
                  {
                    type: 'Feature',
                    id: i * 10 + 0,
                    properties: {
                      type: 'load',
                      pickId: pick.id,
                      color: color,
                      duration,
                    },
                    geometry: {
                      type: 'Point',
                      coordinates: [
                        pick.start_longitude || 0,
                        pick.start_latitude || 0,
                        0.0,
                      ],
                    },
                  },
                  {
                    type: 'Feature',
                    id: i * 10 + 1,
                    properties: {
                      type: 'travel',
                      pickId: pick.id,
                      color: color,
                      duration,
                    },
                    geometry: {
                      type: 'LineString',
                      coordinates: [
                        [pick.start_longitude || 0, pick.start_latitude || 0],
                        [pick.end_longitude || 0, pick.end_latitude || 0],
                      ],
                    },
                  },
                  {
                    type: 'Feature',
                    id: i * 10 + 2,
                    properties: {
                      type: 'unload',
                      pickId: pick.id,
                      color: color,
                      duration,
                    },
                    geometry: {
                      type: 'Point',
                      coordinates: [
                        pick.end_longitude || 0,
                        pick.end_latitude || 0,
                        0.0,
                      ],
                    },
                  }
                )
                return previous
              },
              []
            )
        : [],
    }
  }, [picks])

  const bounds = React.useMemo(() => {
    if (picksGeoJson && picksGeoJson.features.length > 0) {
      const [minLng, minLat, maxLng, maxLat] = bbox(picksGeoJson)
      return new mapboxgl.LngLatBounds([
        [minLng, minLat],
        [maxLng, maxLat],
      ])
    }
  }, [picksGeoJson])

  React.useEffect(() => {
    if (pick && mapRef.current) {
      const mapApi = mapRef.current
      picksGeoJson.features.forEach((feature) => {
        if (feature.properties?.pickId === pick.id) {
          mapApi.setFeatureState(
            {
              source: pickLinesSourceId,
              id: feature.id,
            },
            {
              hover: true,
            }
          )
        } else {
          mapApi.setFeatureState(
            {
              source: pickLinesSourceId,
              id: feature.id,
            },
            {
              hover: false,
            }
          )
        }
      })
    }
  }, [pick, mapRef.current, picksGeoJson])

  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ padding: 8 }}>
        <span style={{ marginRight: 8 }}>Heatmap:</span>

        <ToggleButtonGroup
          value={heatMapState}
          exclusive
          size="small"
          onChange={(event, value) => {
            if (value) {
              setHeatMapState(value)
            }
          }}
          aria-label="text alignment"
        >
          <ToggleButton value="off" style={{ padding: '4px 10px' }}>
            Off
          </ToggleButton>
          <ToggleButton value="load" style={{ padding: '4px 10px' }}>
            Load
          </ToggleButton>
          <ToggleButton value="unload" style={{ padding: '4px 10px' }}>
            Unload
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <ProjectMap
          mapRef={mapRef}
          bounds={bounds}
          initialScrollZoom={true}
          interactiveLayerIds={
            heatMapState === 'off' ? ['load', 'unload', 'travel'] : undefined
          }
          cursor={cursor}
          onMouseMove={(evt) => {
            const hoveredFeature = evt.features && evt.features[0]
            const selectedPickId = craneStore.getState().pick?.id

            if (hoveredFeature) {
              setCursor('pointer')
              picksGeoJson.features.forEach((feature) => {
                if (
                  feature.properties?.pickId ===
                    hoveredFeature.properties?.pickId ||
                  feature.properties?.pickId === selectedPickId
                ) {
                  evt.target.setFeatureState(
                    {
                      source: pickLinesSourceId,
                      id: feature.id,
                    },
                    {
                      hover: true,
                    }
                  )
                } else {
                  evt.target.setFeatureState(
                    {
                      source: pickLinesSourceId,
                      id: feature.id,
                    },
                    {
                      hover: false,
                    }
                  )
                }
              })
            }
          }}
          onMouseLeave={(evt) => {
            const selectedPickId = craneStore.getState().pick?.id

            setCursor('auto')
            picksGeoJson.features.forEach((feature) => {
              if (feature.properties?.pickId !== selectedPickId)
                evt.target.setFeatureState(
                  {
                    source: pickLinesSourceId,
                    id: feature.id,
                  },
                  {
                    hover: false,
                  }
                )
            })
          }}
          onClick={(evt) => {
            const hoveredFeature = evt.features && evt.features[0]
            if (hoveredFeature) {
              const pick = picks.find(
                (p) => p.id === hoveredFeature.properties?.pickId
              )
              if (pick) {
                craneStore.getState().setPick(pick)

                evt.target.setFeatureState(
                  {
                    source: pickLinesSourceId,
                    id: pick.id,
                  },
                  {
                    hover: true,
                  }
                )
              }
            }
          }}
        >
          {heatMapState !== 'off' && (
            <CranePickHeatmap data={picksGeoJson} pickPhase={heatMapState} />
          )}

          {heatMapState === 'off' && <CranePickLines data={picksGeoJson} />}

          {/* <CraneLocations /> */}
        </ProjectMap>
      </div>
    </div>
  )
}
