import log from 'loglevel'
import { useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import { useKRPanoStore } from './KRPanoProvider'

export interface KRPanoHotspotProps {
  url?: string
  enabled?: boolean
  distorted?: boolean
  zoom?: boolean
  renderer?: 'webgl' | 'css3d'
  chromakey?: string
  alpha?: number
  scale?: number
  ath?: number
  atv?: number
  torigin?: 'world' | 'image' | 'view'
  tx?: number
  ty?: number
  tz?: number
  rx?: number
  ry?: number
  rz?: number
  ox?: number
  oy?: number
  keep?: boolean
  onloaded?: () => void
  sprite?: HTMLElement
}

export const KRPanoHotspot: React.FC<KRPanoHotspotProps> = (props) => {
  const [uuid] = useState(() => 'hotspot-' + uuidv4())

  const krpanoStore = useKRPanoStore()

  const pano = krpanoStore((state) => state.krPanoElement)
  const prevProps = usePrevious(props)

  const [, setInstance] = useState<KRPanoHotspotProps>()

  useEffect(() => {
    if (pano) {
      pano.call(`addhotspot(${uuid});`)
      const _instance = pano.get(`hotspot[${uuid}]`) as KRPanoHotspotProps
      log.debug('Hotspot created: ', _instance)
      setInstance(_instance)
    }
    return () => {
      if (pano) {
        pano.call(`removehotspot("${uuid}");`)
        log.debug('Hotspot removed: ', uuid)
      }
    }
  }, [pano, uuid, setInstance])

  useEffect(() => {
    if (pano) {
      Object.entries(props).forEach(([key, value]) => {
        if (
          !prevProps ||
          prevProps[key as keyof KRPanoHotspotProps] !== value
        ) {
          pano.set(`hotspot[${uuid}].${key}`, value)
          log.debug('Hotspot updated: ', key, value)
        }
      })
    }
  }, [pano, props])

  return null
}
