import {
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Switch,
  Tooltip,
  useTheme,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FolderIcon from '@material-ui/icons/Folder'
import React, { useState } from 'react'
import {
  ImageCategorySummary,
  ImageLabelInfo,
} from '../../api/codegen/typescript-axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    // arrow: {
    //   color: theme.palette.common.white,
    // },
    // tooltip: {
    //   backgroundColor: theme.palette.common.white,
    // },
    // selectedCategory: {
    //   color: '#fff',
    // },
  })
)

export const AnnotatorLabelList = React.memo(
  ({
    labels,
    categories,
    selectedLabel,
    setSelectedLabel,
  }: {
    labels: ImageLabelInfo[]
    categories: ImageCategorySummary[]
    selectedLabel?: ImageLabelInfo
    setSelectedLabel: (label: ImageLabelInfo | undefined) => void
  }) => {
    const [showAllLabels, setShowAllLabels] = useState(false)

    return (
      <List
        component="div"
        disablePadding
        dense
        subheader={<ListSubheader>Labels</ListSubheader>}
        style={{ overflow: 'auto' }}
      >
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                checked={showAllLabels}
                onChange={(e, v) => setShowAllLabels(v)}
                color="primary"
              />
            }
            label="Show all labels"
          />
        </ListItem>
        {categories.map((category) => (
          <Category
            key={category.id}
            category={category}
            labels={labels.filter((label) => label.category_id === category.id)}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            showAllLabels={showAllLabels}
          />
        ))}
      </List>
    )
  }
)

const Category = ({
  category,
  labels,
  selectedLabel,
  setSelectedLabel,
  showAllLabels,
}: {
  category: ImageCategorySummary
  labels: ImageLabelInfo[]
  selectedLabel?: ImageLabelInfo
  setSelectedLabel: (label: ImageLabelInfo | undefined) => void
  showAllLabels: boolean
}) => {
  const [expanded, setExpanded] = useState(true)
  const selected =
    selectedLabel && !!labels.find((label) => selectedLabel.id === label.id)

  return (
    <>
      <ListItem
        button
        onClick={() => setExpanded(!expanded)}
        selected={selected}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={category.name} />
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding>
          {labels.map(
            (label) =>
              (label.show_by_default || showAllLabels) && (
                <Label
                  key={label.id}
                  label={label}
                  selected={selectedLabel === label}
                  setSelectedLabel={setSelectedLabel}
                />
              )
          )}
        </List>
      </Collapse>
    </>
  )
}

const Label = React.memo(
  ({
    label,
    selected,
    setSelectedLabel,
  }: {
    label: ImageLabelInfo
    selected: boolean
    setSelectedLabel: (label: ImageLabelInfo | undefined) => void
  }) => {
    const theme = useTheme()
    return (
      <Tooltip
        placement="left"
        enterDelay={1000}
        classes={useStyles()}
        title={
          <img
            alt={label.thumbnail_url}
            src={label.thumbnail_url}
            style={{ height: 200, width: 200, objectFit: 'contain' }}
          />
        }
        arrow
      >
        <ListItem
          dense
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: selected
              ? 'rgba(128, 128, 128, 0.1)'
              : 'transparent',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={selected}
                onChange={(e) => setSelectedLabel(label)}
                name="checkedA"
                style={{ color: label.color }}
              />
            }
            label={<ListItemText>{label.name}</ListItemText>}
          />
        </ListItem>
      </Tooltip>
    )
  }
)
