import {
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FlagIcon from '@material-ui/icons/Flag'
import FolderIcon from '@material-ui/icons/Folder'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { ImageCategorySummary } from '../../api/codegen/typescript-axios'
import { dateTimeFormat } from '../../helpers/constants'
import { ImageAnnotationBoxEdit, useAnnotatorContext } from './Annotator'

export const AnnotatorBoxList = React.memo(
  ({
    boxes,
    categories,
    selectedBox,
    setSelectedBox,
  }: {
    boxes: ImageAnnotationBoxEdit[]
    categories: ImageCategorySummary[]
    selectedBox?: ImageAnnotationBoxEdit
    setSelectedBox: (box: ImageAnnotationBoxEdit | undefined) => void
  }) => {
    const [flaggingBox, setFlaggingBox] = useState<ImageAnnotationBoxEdit>()
    return (
      <List
        component="div"
        disablePadding
        dense
        style={{ minWidth: 236, overflow: 'auto' }}
      >
        {categories.map((category) => (
          <Category
            key={category.id}
            category={category}
            boxes={boxes.filter((box) => box.category_id === category.id)}
            selectedBox={selectedBox}
            setSelectedBox={setSelectedBox}
            flaggingBox={flaggingBox}
            setFlaggingBox={setFlaggingBox}
          />
        ))}
      </List>
    )
  }
)

const Category = ({
  category,
  boxes,
  selectedBox,
  setSelectedBox,
  flaggingBox,
  setFlaggingBox,
}: {
  category: ImageCategorySummary
  boxes: ImageAnnotationBoxEdit[]
  selectedBox?: ImageAnnotationBoxEdit
  setSelectedBox: (box: ImageAnnotationBoxEdit | undefined) => void
  flaggingBox?: ImageAnnotationBoxEdit
  setFlaggingBox: (box: ImageAnnotationBoxEdit | undefined) => void
}) => {
  const [expanded, setExpanded] = useState(true)

  return (
    <>
      <ListItem
        button
        onClick={() => setExpanded(!expanded)}
        selected={selectedBox && boxes.indexOf(selectedBox) > -1}
      >
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={category.name} />
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {boxes.map((box) => (
            <Box
              key={box.id}
              box={box}
              selected={selectedBox === box}
              setSelectedBox={setSelectedBox}
              flagging={flaggingBox === box}
              setFlaggingBox={setFlaggingBox}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

const Box = React.memo(
  ({
    box,
    selected,
    setSelectedBox,
    flagging,
    setFlaggingBox,
  }: {
    box: ImageAnnotationBoxEdit
    selected: boolean
    setSelectedBox: (box: ImageAnnotationBoxEdit | undefined) => void
    flagging: boolean
    setFlaggingBox: (box: ImageAnnotationBoxEdit | undefined) => void
  }) => {
    const { hiddenBoxes, setHiddenBoxes } = useAnnotatorContext()
    const [commentText, setCommentText] = useState('')

    return (
      <ListItem
        dense
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <>
          <FormControlLabel
            style={{ whiteSpace: 'nowrap', marginRight: 11 }}
            control={
              <Checkbox
                size="small"
                checked={selected}
                onChange={(e) =>
                  setSelectedBox(e.target.checked ? box : undefined)
                }
                name="checkedA"
                style={{ color: box.label.color }}
              />
            }
            label={
              <>
                {box.label.name}{' '}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    if (hiddenBoxes.includes(box)) {
                      setHiddenBoxes(hiddenBoxes.filter((b) => b !== box))
                    } else {
                      setHiddenBoxes([...hiddenBoxes, box])
                      setSelectedBox(undefined)
                    }
                    e.preventDefault()
                  }}
                >
                  {hiddenBoxes.includes(box) ? (
                    <VisibilityOffIcon fontSize="inherit" />
                  ) : (
                    <VisibilityIcon fontSize="inherit" />
                  )}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    setFlaggingBox(flagging ? undefined : box)
                    setSelectedBox(box)
                    e.preventDefault()
                  }}
                >
                  <FlagIcon />
                </IconButton>
              </>
            }
          />
          {(flagging || box.newComment) && (
            <div
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                width: '100%',
              }}
            >
              <TextField
                label="Comment"
                multiline
                rows={4}
                value={commentText}
                onChange={(e) => {
                  setCommentText(e.target.value)
                  box.newComment = e.target.value
                }}
                variant="outlined"
                fullWidth
              />
            </div>
          )}
          {box.comments.length > 0 && (
            <List>
              {box.comments.map((c) => (
                <ListItem key={c.comment_datetime}>
                  <ListItemText
                    primary={
                      <>
                        <strong>{c.user.first_name}:</strong> {c.comment}
                      </>
                    }
                    secondary={format(
                      new Date(c.comment_datetime),
                      dateTimeFormat
                    )}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Divider style={{ width: '100%' }} />
        </>
      </ListItem>
    )
  }
)
