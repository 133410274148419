import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useAnalysesStore } from './../AnalysesController'
import { AnalysisSelect } from './../AnalysisSelect'
import { ShoringBayEditor } from './ShoringBayEditor'
import { ShoringOverlaySettings } from './ShoringOverlaySettings'
import { ShoringTools } from './ShoringTools'
import { useShoringStore } from './VODShoringController'

export const VODShoringDetailPanel = () => {
  const analysesStore = useAnalysesStore()
  const shoringStore = useShoringStore()

  const analysisId = analysesStore((state) => state.analysisId)

  useEffect(() => {
    shoringStore.setState({ showOverlay: true })
    return () =>
      shoringStore.setState({
        showOverlay: false,
      })
  }, [shoringStore])

  return (
    <div
      style={{
        padding: '12px 6px',
        overflowX: 'hidden',
      }}
      // To prevent any keydowns (such as form input) from triggering VOD shortcuts
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Grid container spacing={1}>
        <ShoringOverlaySettings />

        <Grid item xs={12}>
          <AnalysisSelect />
        </Grid>

        {analysisId && (
          <>
            <ShoringTools />

            <ShoringBayEditor />
          </>
        )}
      </Grid>
    </div>
  )
}
