import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'

export const CraneReportsPanelUtilization = ({
  expanded,
}: {
  expanded?: boolean
}) => {
  const { t } = useTranslation()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()

  const date = useVODStore((state) => state.videoDate)
  const days = craneStore((state) => state.days)
  const picks = craneStore((state) => state.picks)
  const projectConfig = craneStore((state) => state.projectConfig)

  const currentDayData = days.get(date.toISODate())

  if (!currentDayData) return null

  return (
    <>
      <Typography variant="h6">{t('total')}</Typography>
      <Accordion square expanded={expanded}>
        {/* Summary header */}
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <BoldStat>{picks.length}</BoldStat>{' '}
            {t('picks', { count: picks.length }).toLowerCase()},{' '}
            <BoldStat>{currentDayData.totalDuration.toFormat('h:mm')}</BoldStat>{' '}
            {t('duration').toLowerCase()},{' '}
            {projectConfig?.pick_rate_format === 'picks_per_hour' ? (
              <span>
                <BoldStat>{(currentDayData?.picksPerHour).toFixed(1)}</BoldStat>{' '}
                {t('picks', { count: picks.length })}/{t('hour').toLowerCase()}
              </span>
            ) : projectConfig?.pick_rate_format === 'minutes_per_pick' ? (
              <span>
                <BoldStat>
                  {(currentDayData?.minutesPerPick).toFixed(1)}
                </BoldStat>{' '}
                {t('minutes/pick').toLowerCase()}
              </span>
            ) : null}
          </Typography>
        </AccordionSummary>

        {/* Detail table */}
        <AccordionDetails style={{ padding: 0 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ width: 1 }} align="right">
                  {t('duration')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {t('total')}{' '}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  <b>{currentDayData.totalDuration.toFormat('h:mm')}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Active</TableCell>
                <TableCell align="right">
                  {currentDayData.activeDuration.toFormat('h:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Idle</TableCell>
                <TableCell align="right">
                  {currentDayData.idleDuration.toFormat('h:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Break</TableCell>
                <TableCell align="right">
                  {currentDayData.breakDuration.toFormat('h:mm')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

const BoldStat: React.FC = ({ children }) => (
  <b style={{ fontSize: '1.2em' }}>{children}</b>
)
