import { deepOrange, lightBlue, amber } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import { RGBA } from 'konva/lib/filters/RGBA'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    custom: {
      appBarHeight: number
      projectNameHeight: number
      projectNavHeight: number
      chartColorsCategorical: string[]
      chartColorsDegree: string[]
    }
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    custom: {
      appBarHeight: number
      projectNameHeight: number
      projectNavHeight: number
      chartColorsCategorical: string[]
      chartColorsDegree: string[]
    }
  }
}

// const adobeCategoricalPalett = [
//   '#00C0C7', // blue-green
//   '#5144D3', // dark blue
//   '#E8871A', // orange
//   '#DA3490', // pink
//   '#9089FA', // light purple
//   '#47E26F', // light green
//   '#2780EB', // blue
//   '#5C2E93', // purple
//   '#DFBF03', // yellow
//   '#CB6F10', // brown
//   '#268D6C', // dark green
//   '#9BEC54', // yellow-green
// ]

export const appBarHeight = 48
export const projectNameHeight = 47
export const projectNavHeight = appBarHeight + projectNameHeight
const custom = {
  appBarHeight,
  projectNameHeight,
  projectNavHeight: appBarHeight + projectNameHeight,
  chartColorsCategorical: [
    '#6929c4',
    '#1192e8',
    '#005d5d',
    '#9f1953',
    // '#fa4d56',
    // '#520608',
    // '#198039',
    '#002e9c',
    '#ee5396',
    '#b28600',
    '#029d9a',
    '#012749',
    '#8a3800',
    '#a56eff',
  ],
  chartColorsDegree: [
    '#ffffd9',
    '#edf8b1',
    '#c7e9b4',
    '#7fcdbb',
    '#41b6c4',
    '#1d91c0',
    '#225ea8',
    '#0c2c84',
  ],
}

const customDark = {
  ...custom,
  chartColorsCategorical: [
    '#8a3ffc',
    '#33b1ff',
    '#027d79',
    '#ff7eb6',
    // '#fa4d56',
    // '#fff1f1',
    // '#6fdc8d',
    '#4589ff',
    '#d12771',
    '#d2a107',
    '#09bdba',
    '#bae6ff',
    '#ba4e00',
    '#d4bbff',
  ],
}

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: { main: '#b52123' },
    secondary: lightBlue,
    error: deepOrange,
    warning: amber,
    text: {
      secondary: lightBlue[900],
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#000',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000',
        fontSize: 12,
      },
      arrow: {
        color: '#000',
      },
    },
    MuiAccordion: {
      root: {
        '@media print': {
          boxShadow: 'none',
          border: '1px solid',
          borderColor: 'rgba(128,128,128,0.5)',
        },
      },
    },
    MuiAccordionSummary: {
      expandIcon: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '6px 16px 6px 16px',
      },
    },
  },
  custom,
})

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: { main: '#b52123' },
    secondary: lightBlue,
    error: deepOrange,
    warning: amber,
    background: { default: '#222222', paper: '#333333' },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: '#303030',
        color: '#fff',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000',
        fontSize: 12,
      },
      arrow: {
        color: '#000',
      },
    },
    MuiAccordionSummary: {
      expandIcon: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '6px 16px 6px 16px',
      },
    },
  },
  custom: customDark,
})
