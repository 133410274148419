import React from 'react'
import ReactDOMServer from 'react-dom/server'

export async function tourJs2Xml(element: React.ReactElement) {
  try {
    const xml = ReactDOMServer.renderToStaticMarkup(element)
    const replacedXml = xml.replace(/kr-/g, '')
    const xmlUrl = 'data:text/xml,' + replacedXml
    return xmlUrl
  } catch (err) {
    console.error(err)
  }
}
