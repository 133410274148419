import {
  AppBar,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { PageWrapper } from '../PageWrapper'
import { useProject } from '../ProjectWrapper'
import { SecurityArmLog } from './SecurityArmLog'
import { SecurityContactsList } from './SecurityContactsList'
import { SecurityEventsList } from './SecurityEventsList'
import { SecuritySchedule } from './SecuritySchedule'
import { SecurityScheduleTab } from './SecurityScheduleTab'
import { SecuritySwitch } from './SecuritySwitch'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    tab: {
      position: 'relative',
      zIndex: 1,
    },
    tabHidden: {
      zIndex: 0,
      display: 'none',
    },
  })
)

const defaultManifest = {
  // short_name: 'Topdeck',
  name: 'Topdeck Dashboard',
  icons: [
    // 48,
    // 72,
    // 96,
    // 128,
    // 192,
    // 384,
    512,
  ].map((size) => ({
    src: `${window.location.origin}${process.env.PUBLIC_URL}/security_icon_x${size}.png`,
    sizes: `${size}x${size}`,
    type: 'image/png',
    purpose: 'any maskable',
  })),
  start_url: '.',
  display: 'standalone',
  background_color: '#333333',
}

export const SecurityPage = (props: {}) => {
  const classes = useStyles(props)
  const api = useApi()
  const theme = useTheme()
  const project = useProject()
  const { alert } = useAlertConfirmPrompt()

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get('pwa') === 'security') {
      sessionStorage.setItem('pwa', 'security')
    }
  }, [])

  const [alarmModalClosed, setAlarmModalClosed] = React.useState(0)

  const [{ value, loading, error }, fetch] = useAsyncFn(
    async (checked: boolean) => {
      const resp = await api.projectsApi.projectsUpdate({
        id: project.id.toString(),
        data: {
          camevent_all_enabled: checked,
        },
      })
      return resp.data.camevent_all_enabled
    },
    [project]
  )

  const manifest = {
    ...defaultManifest,
    name: `Security - ${project.name}`,
    start_url: `${window.location.origin}${process.env.REACT_APP_BASE_HREF}${project.slug}/security?pwa=security`,
  }

  const armed =
    typeof value === 'boolean' ? value : project.camevent_all_enabled || false

  const [currentTab, setCurrentTab] = React.useState(0)

  return (
    <PageWrapper style={{ maxWidth: 'initial' }}>
      <Helmet>
        <link
          rel="manifest"
          href={`data:application/manifest+json,${encodeURIComponent(
            JSON.stringify(manifest)
          )}`}
        />
        <link rel="apple-touch-icon" href={manifest.icons[0].src} />
      </Helmet>

      <div className={classes.root}>
        <Grid container spacing={2} style={{ textAlign: 'center' }}>
          <Grid item xs={12} style={{ maxWidth: 500, margin: '0 auto' }}>
            <Typography variant="h4">Security</Typography>
          </Grid>
          <Grid item xs={12}>
            {armed ? (
              <LockIcon
                onClick={() => fetch(false)}
                color="primary"
                style={{
                  width: 200,
                  height: 'auto',
                  cursor: 'pointer',
                  fill: theme.palette.error.main,
                }}
              />
            ) : (
              <LockOpenIcon
                onClick={() => fetch(true)}
                style={{
                  width: 200,
                  height: 'auto',
                  cursor: 'pointer',
                  fill: theme.palette.success.main,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <Grid
                component="div"
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>Disarmed</Grid>
                <Grid item>
                  <SecuritySwitch
                    checked={armed}
                    onChange={(event) => fetch(event.target.checked)}
                    name="Armed"
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Armed{' '}
                  <InfoOutlinedIcon
                    color="secondary"
                    style={{
                      marginLeft: 6,
                    }}
                    onClick={() =>
                      alert({
                        title: 'Arm/Disarm switch',
                        description:
                          'Set to "Armed" when leaving the worksite to enable intrusion detection and alarm dispatch. Disarm when resuming work to disable intrusion detection and alarm dispatch.',
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SecuritySchedule />
          </Grid>
          <Grid item xs={12} style={{ maxWidth: 500, margin: '0 auto' }}>
            <Typography variant="body1">Dispatch Points of Contact:</Typography>
            <Typography variant="body1">
              {project.contacts?.map((contact) => (
                <span key={contact.user?.id}>
                  {contact.user?.first_name} {contact.user?.last_name},{' '}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs
                value={currentTab}
                onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                  setCurrentTab(newValue)
                }}
              >
                <Tab label="Events" />
                <Tab label="Arm Log" />
                <Tab label="Schedule" />
                <Tab label="Contacts" />
              </Tabs>
            </AppBar>
            <Paper
              className={currentTab == 0 ? classes.tab : classes.tabHidden}
            >
              <SecurityEventsList
                alarmModalClosed={alarmModalClosed}
                setAlarmModalClosed={setAlarmModalClosed}
              />
            </Paper>
            <Paper
              className={currentTab == 1 ? classes.tab : classes.tabHidden}
            >
              <SecurityArmLog />
            </Paper>
            <Paper
              className={currentTab == 2 ? classes.tab : classes.tabHidden}
            >
              <SecurityScheduleTab />
            </Paper>
            <Paper
              className={currentTab == 3 ? classes.tab : classes.tabHidden}
            >
              <SecurityContactsList />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </PageWrapper>
  )
}
