import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { select as d3select } from 'd3-selection'
import { zoom as d3zoom, ZoomBehavior, zoomIdentity } from 'd3-zoom'
import Konva from 'konva'
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Image, Layer, Stage } from 'react-konva'
import { useMeasure } from 'react-use'
import { useCallbackRef, useMergeRefs } from 'use-callback-ref'
import { ReactRef } from 'use-callback-ref/dist/es5/types'
import { createCtx } from '../../helpers/createCtx'
import { getContainTransform } from '../../helpers/getContainTransform'
import useRefCurrent from '../../hooks/useRefCurrent'
import useVideoMetadata from '../../hooks/useVideoMetadata'
import { mixins } from '../../styles/mixins'
import { Mode } from '../Annotator/Annotator'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        backgroundColor: '#000',
        ...mixins.absoluteFill,
      },
      drawMode: {
        cursor: 'crosshair',
      },
    }),
  {
    name: 'VideoCanvas',
  }
)

export const [useStageContext, StageContext] = createCtx<{
  absoluteTransform: Konva.Transform
  clamp: (
    point: Konva.Vector2d,
    dimensions?: Konva.Vector2d
  ) => {
    x: number
    y: number
  }
  width: number
  height: number
  videoWidth: number
  videoHeight: number
}>()

// This component handles image and video display,
// zoom/pan gestures,
// and other events attached to canvas root (Stage)
export const VODVideoCanvas: FunctionComponent<{
  videoEl?: HTMLVideoElement | null
  showVideo?: boolean
  mode: Mode
  onClick?: () => void
  scaleExtent: [number, number]
}> = React.memo(
  ({ children, videoEl, showVideo, mode, onClick, scaleExtent }) => {
    const classes = useStyles()
    const useVODStore = getUseVODStore()

    const crop = useVODStore((state) => state.crop)

    const videoMetadata = useVideoMetadata(videoEl)
    const videoWidth = videoMetadata.videoWidth || 1
    const videoHeight = videoMetadata.videoHeight || 1

    // @ts-ignore
    // Store zoom transform in state (possible to update transform without rerender?)
    const [zoomTransform, setZoomTransform] = useState(new Konva.Transform())

    // create zoom behavior
    const zoomRef = useRef<ZoomBehavior<Element, unknown>>(
      d3zoom()
        .scaleExtent(scaleExtent)
        .on('zoom', (e: any) => {
          const t = e.transform
          // @ts-ignore
          setZoomTransform(new Konva.Transform([t.k, 0, 0, t.k, t.x, t.y]))
        })
    )

    // attach zoom behavior to container
    const zoomingRef = useCallbackRef<HTMLDivElement>(null, (containerEl) => {
      if (containerEl) {
        const selection = d3select(containerEl as Element)
        selection.call(zoomRef.current)
      }
    })

    // set translate extent based on container size and on every resize
    const [measuringRef, containerDims] = useMeasure<HTMLDivElement>()
    useEffect(() => {
      const x = (1 - scaleExtent[0]) / 2
      zoomRef.current.translateExtent([
        [-containerDims.width * x, -containerDims.height * x],
        [containerDims.width * (1 + x), containerDims.height * (1 + x)],
      ])
    }, [containerDims])

    // base transform
    const baseTransform = getContainTransform({
      parent: { w: containerDims.width, h: containerDims.height },
      child: { w: videoWidth, h: videoHeight },
    })

    useEffect(() => {
      if (
        !crop ||
        !containerDims.width ||
        !containerDims.height ||
        !videoWidth ||
        !videoHeight
      ) {
        return
      }

      const t = getContainTransform({
        parent: { w: containerDims.width, h: containerDims.height },
        child: { w: videoWidth, h: videoHeight },
        crop: crop,
      })
        .multiply(baseTransform.copy().invert())
        .decompose()

      const d3transform = zoomIdentity.translate(t.x, t.y).scale(t.scaleX)

      const selection = d3select(zoomingRef.current as Element)
      selection.call(zoomRef.current.transform, d3transform)
    }, [crop])

    const absoluteTransform = new Konva.Transform()
      .multiply(zoomTransform)
      .multiply(baseTransform)

    if (videoEl) {
      const videoInvertTransform = absoluteTransform.copy().invert()

      let { x: x0, y: y0 } = videoInvertTransform.point({ x: 0, y: 0 })
      let { x: x1, y: y1 } = videoInvertTransform.point({
        x: containerDims.width,
        y: containerDims.height,
      })
      const x = Math.floor(Math.max(x0, 0))
      const y = Math.floor(Math.max(y0, 0))
      const w = Math.floor(Math.min(x1, videoWidth)) - x
      const h = Math.floor(Math.min(y1, videoHeight)) - y
      useVODStore.setState({
        videoViewport: {
          x,
          y,
          w,
          h,
        },
      })
    }

    // load image and get natural dims
    const [imageRef, imageRefCurrent] = useRefCurrent<Konva.Image>(null)
    useEffect(() => {
      let anim: Konva.Animation
      if (showVideo && imageRefCurrent) {
        const layer = imageRefCurrent.getLayer()
        anim = new Konva.Animation(() => {}, layer)
        anim.start()
      }
      return () => {
        if (anim) {
          anim.stop()
        }
      }
    }, [showVideo, imageRefCurrent])

    const clamp = useMemo(
      () => (
        point: Konva.Vector2d,
        dimensions: Konva.Vector2d = { x: 0, y: 0 }
      ) => ({
        x: Math.min(videoWidth - dimensions.x, Math.max(point.x, 0)),
        y: Math.min(videoHeight - dimensions.y, Math.max(point.y, 0)),
      }),
      [videoWidth, videoHeight]
    )

    return (
      <div
        className={clsx(classes.root, { [classes.drawMode]: mode === 'draw' })}
        ref={useMergeRefs<HTMLDivElement>([
          measuringRef as ReactRef<HTMLDivElement>,
          zoomingRef,
        ])}
        onClick={onClick}
      >
        <Stage
          width={containerDims.width}
          height={containerDims.height}
          strokeScaleEnabled={true}
          listening={true}
          onMouseDown={(e: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              e.target === e.target.getStage() ||
              e.target instanceof Konva.Image
            ) {
              console.log('onmousedown stage or image')
            } else {
              // If we mousedown on a rect, we must prevent the d3 zoom gesture from starting
              console.log('onmousedown object. Prevent zoom behavior')
              e.evt.stopPropagation()
            }
          }}
        >
          <StageContext.Provider
            value={{
              absoluteTransform,
              clamp,
              width: containerDims.width,
              height: containerDims.height,
              videoWidth,
              videoHeight,
            }}
          >
            <Layer {...absoluteTransform.decompose()}>
              {videoEl && (
                <Image
                  image={videoEl}
                  ref={imageRef}
                  x={0}
                  y={0}
                  width={videoWidth}
                  height={videoHeight}
                />
              )}
              {children}
            </Layer>
          </StageContext.Provider>
        </Stage>
      </div>
    )
  }
)
