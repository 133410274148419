import {
  createStyles,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { DateTime } from 'luxon'
import React, { memo, useState } from 'react'
import { useAsync } from 'react-use'
import {
  Trip,
  TripWeighin,
  VehicleStreamMap,
} from '../../../api/codegen/typescript-axios'
import { useApi } from '../../ApiContext'
import { Loading } from '../../Loading'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { VODDateTimeSelect } from '../VODDateTimeSelect'
import { VODLink } from '../VODLink'
import TruckWeightsChart from './TruckWeightsChart'

interface ExtendedTrip extends Trip {
  startTime?: DateTime
  endTime?: DateTime
  weighs: ExtendedWeighIn[]
}

interface ExtendedWeighIn extends TripWeighin {
  weighTime?: DateTime
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: '100%',
    },
    gridContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    tableContainer: {
      flexGrow: 1,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    weighs: {
      backgroundColor: theme.palette.background.default,
    },
  })
)

export const TruckWeightsPanel = (props: {}) => {
  const api = useApi()
  const classes = useStyles(props)
  const project = useProject()
  const useVODStore = getUseVODStore()

  const videoHour = useVODStore((state) => state.videoHour)

  const { value: streamMap } = useAsync(async () => {
    const resp = await api.vehiclesApi.vehiclesProjectsStreamconfigStreammap({
      projectId: project.id.toString(),
    })
    return resp.data
  })

  const { value: trips, loading } = useAsync(async () => {
    const resp = await api.vehiclesApi.vehiclesProjectsTripsList({
      projectId: project.id.toString(),
      startTime: videoHour.startOf('hour').toISO(),
      endTime: videoHour.endOf('hour').toISO(),
    })

    const extendedTrips: ExtendedTrip[] = resp.data.map((t) => ({
      ...t,
      startTime: t.start_time
        ? DateTime.fromISO(t.start_time, { zone: project.timezone })
        : undefined,
      endTime: t.end_time
        ? DateTime.fromISO(t.end_time, { zone: project.timezone })
        : undefined,
      weighs: t.trip_weighins.map((w) => ({
        ...w,
        weighTime: w.weigh_time
          ? DateTime.fromISO(w.weigh_time, { zone: project.timezone })
          : undefined,
      })),
    }))

    return extendedTrips
  }, [videoHour.toISO()])

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item>
          <Typography variant="h4">Truck Weights</Typography>
          <VODDateTimeSelect time={true} />
        </Grid>
        <Grid item>
          <TruckWeightsChart />
        </Grid>
        {!trips ? (
          loading ? (
            <Loading text="Loading truck weights" />
          ) : (
            <Grid item>No weigh-ins for this date.</Grid>
          )
        ) : !streamMap ? null : (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader aria-label="Truck trips table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Time in</TableCell>
                  <TableCell>Time out</TableCell>
                  <TableCell>License plate</TableCell>
                  <TableCell>Truck id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trips.map((trip, i) => (
                  <Row key={trip.id} trip={trip} streamMap={streamMap} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </div>
  )
}

const Row = memo(
  ({
    trip,
    streamMap,
  }: {
    trip: ExtendedTrip
    streamMap: VehicleStreamMap
  }) => {
    const [open, setOpen] = React.useState(false)
    const classes = useStyles()
    const useVODStore = getUseVODStore()

    const [dialogImage, setDialogImage] = useState<{
      title: string
      url: string
    }>()

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          {/* Truck entrance */}
          <TableCell component="th" scope="row">
            {trip.startTime && (
              <span className={classes.nowrap}>
                <VODLink
                  to={{
                    dateTime: trip.startTime.minus({
                      seconds: 2,
                    }),
                    streamId: streamMap.enter || undefined,
                    speed: 1,
                  }}
                >
                  {trip.startTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}
                </VODLink>
                {' |'}
                {trip.in_license_detection_image_url && (
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() =>
                      setDialogImage({
                        title: `Truck entrance: ${trip.startTime?.toLocaleString(
                          DateTime.TIME_WITH_SECONDS
                        )}`,
                        url: trip.in_license_detection_image_url || '',
                      })
                    }
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                )}
              </span>
            )}
          </TableCell>

          {/* Truck exit */}
          <TableCell>
            {trip.endTime && (
              <span className={classes.nowrap}>
                <VODLink
                  to={{
                    dateTime: trip.endTime.minus({
                      seconds: 2,
                    }),
                    streamId: streamMap.exit || undefined,
                    speed: 1,
                  }}
                >
                  {trip.endTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}
                </VODLink>
                {' |'}
                {trip.out_license_detection_image_url && (
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() =>
                      setDialogImage({
                        title: `Truck exit: ${trip.endTime?.toLocaleString(
                          DateTime.TIME_WITH_SECONDS
                        )}`,
                        url: trip.out_license_detection_image_url || '',
                      })
                    }
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                )}
              </span>
            )}
          </TableCell>
          <TableCell>{trip.license_plate}</TableCell>
          <TableCell>{trip.truck_number}</TableCell>
        </TableRow>
        <TableRow className={classes.weighs}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit mountOnEnter>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Weigh time</TableCell>
                      <TableCell align="right">Gross weight</TableCell>
                      <TableCell align="right">Reported weight</TableCell>
                      <TableCell align="right">Tare Weight</TableCell>
                      <TableCell align="right">Scale Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trip.weighs.map((weigh) => (
                      <TableRow key={weigh.weigh_time}>
                        <TableCell component="th" scope="row">
                          {weigh.weighTime && (
                            <span className={classes.nowrap}>
                              <VODLink
                                to={{
                                  dateTime: weigh.weighTime.minus({
                                    seconds: 10,
                                  }),
                                  streamId:
                                    streamMap.scales.find(
                                      (s) =>
                                        s.scale_number === weigh.scale_number
                                    )?.lpr || undefined,
                                  speed: 1,
                                }}
                              >
                                {weigh.weighTime.toLocaleString(
                                  DateTime.TIME_WITH_SECONDS
                                )}
                              </VODLink>
                              {' |'}
                              {weigh.license_detection_image_url && (
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() =>
                                    setDialogImage({
                                      title: `Truck weight: ${weigh.weighTime?.toLocaleString(
                                        DateTime.TIME_WITH_SECONDS
                                      )}`,
                                      url:
                                        weigh.license_detection_image_url || '',
                                    })
                                  }
                                >
                                  <PhotoCameraIcon />
                                </IconButton>
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {weigh.weighTime && weigh.gross_weight && (
                            <VODLink
                              to={{
                                dateTime: weigh.weighTime.minus({
                                  seconds: 2,
                                }),
                                streamId:
                                  streamMap.scales.find(
                                    (s) => s.scale_number === weigh.scale_number
                                  )?.display || undefined,
                                speed: 1,
                              }}
                            >
                              {weigh.gross_weight.toLocaleString()}
                            </VODLink>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {weigh.weighTime && weigh.reported_weight && (
                            <VODLink
                              to={{
                                dateTime: weigh.weighTime.minus({
                                  seconds: 2,
                                }),
                                streamId:
                                  streamMap.scales.find(
                                    (s) => s.scale_number === weigh.scale_number
                                  )?.display || undefined,
                                speed: 1,
                              }}
                            >
                              {weigh.reported_weight.toLocaleString()}
                            </VODLink>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {weigh.tare_weight?.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {weigh.scale_number}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>

        <Dialog
          onClose={() => setDialogImage(undefined)}
          onClick={() => setDialogImage(undefined)}
          open={!!dialogImage}
          maxWidth={false}
        >
          <img src={dialogImage?.url} />
        </Dialog>
      </React.Fragment>
    )
  }
)

Row.displayName = 'Row'
