import Konva from 'konva'
import React from 'react'
import { Group, Rect, Shape, Text } from 'react-konva'
import { useCallbackRef } from 'use-callback-ref'
import { ClipExtended } from '../../../api/ClipExtended'
import { darkTheme } from '../../../styles/theme'
import { useProject } from '../../ProjectWrapper'
import { useClipsStore } from '../VODClipsController'
import { getUseVODStore } from '../VODController'
import { useUseTimelineStore } from './Timeline'
import { TimelineCollapsibleSection } from './TimelineCollapsibleSection'
import { TimelineRow } from './TimelineRow'

export const TimelineClips = ({ rowGroupIndex }: { rowGroupIndex: number }) => {
  const clipsStore = useClipsStore()
  const useTimelineStore = useUseTimelineStore()

  const clip = clipsStore((state) => state.clip)
  const clips = clipsStore((state) => state.clips) || []

  const rowHeight = useTimelineStore((state) => state.rowHeight)

  return (
    <TimelineCollapsibleSection
      expandedTitle={'Clips'}
      collapsedTitle={clip?.title}
      rowGroupIndex={rowGroupIndex}
      childRowCount={clips?.length || 0}
    >
      {(collapsed) => (
        <Group>
          {clips
            .filter((c) => !collapsed || c.id === clip?.id)
            .map((c, i) => (
              <TimelineRow key={c.id} y={i * rowHeight} i={i}>
                <ClipRow clip={c} />
              </TimelineRow>
            ))}
        </Group>
      )}
    </TimelineCollapsibleSection>
  )
}

const ClipRow = ({ clip }: { clip: ClipExtended }) => {
  const project = useProject()
  const useVODStore = getUseVODStore()
  const clipsStore = useClipsStore()
  const useTimelineStore = useUseTimelineStore()

  const currentClip = clipsStore((state) => state.clip)

  const rowHeight = useTimelineStore((state) => state.rowHeight)
  const width = useTimelineStore((state) => state.width)
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)

  const [hover, setHover] = React.useState(false)

  const textRef = useCallbackRef<Konva.Text>(null, (text) => {
    if (text) {
      text.cache()
    }
  })

  return (
    <Group
      x={0}
      y={0}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Group
        clip={{
          x: 0,
          y: 0,
          width: leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={0}
          y={0}
          width={leftColumnWidth}
          height={rowHeight}
          onClick={() => {
            console.log('click')
            useVODStore.getState().gotoVideo({
              streamId: clip.stream,
              dateTime: clip.startDateTime,
              speed: clip.speed,
            })
            clipsStore.getState().setClip(clip.id!)
          }}
        />
        <Text
          ref={textRef}
          text={clip.title}
          fill="#fff"
          x={3}
          y={3}
          fontSize={13}
          listening={false}
        />
      </Group>
      <Group
        clip={{
          x: leftColumnWidth,
          y: 0,
          width: width - leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={leftColumnWidth}
          y={0}
          width={width - leftColumnWidth}
          height={rowHeight}
          onClick={(e) => {
            const { gotoVideo } = useVODStore.getState()
            gotoVideo({
              streamId: clip.stream,
              dateTime: useTimelineStore
                .getState()
                .mouseDateTime?.setZone(project.timezone, {
                  keepLocalTime: true,
                }),
              speed: clip.speed,
            })
            clipsStore.getState().setClip(clip.id!)
          }}
        />
        <ClipBlock clip={clip} />
      </Group>
      {(hover || currentClip?.id === clip.id) && (
        <Rect
          x={0}
          y={0}
          width={width}
          height={rowHeight}
          fill="rgba(255, 255, 255, 0.2)"
          listening={false}
        />
      )}
    </Group>
  )
}

const ClipBlock = React.memo(({ clip }: { clip: ClipExtended }) => {
  const useTimelineStore = useUseTimelineStore()

  return (
    <>
      <Shape
        perfectDrawEnabled={false}
        fill={darkTheme.custom.chartColorsCategorical[1]}
        listening={false}
        sceneFunc={function renderDayblock(context, shape) {
          // get Timeline state
          const {
            width,
            rowHeight,
            leftColumnWidth,
            currentScale,
          } = useTimelineStore.getState()

          // calculate day width
          const x = Math.floor(currentScale(clip.systemStartDateTime))
          const x1 = Math.floor(currentScale(clip.systemEndDateTime))

          // determine if shoud be visible in the viewport
          const visible = x < width && x1 > leftColumnWidth

          context.beginPath()

          // day rect
          if (visible) {
            context.rect(x, 0, Math.max(x1 - x, 4), rowHeight - 1)
          }

          context.closePath()

          // (!) Konva specific method, it is very important
          context.fillShape(shape)

          // context.fillText(clip.title, x1 + 6, rowHeight / 2 + 3)
        }}
      />
      {/* <Text
        perfectDrawEnabled={false}
        fill="#ffffff"
        fontSize={20}
        listening={false}
        sceneFunc={function renderDayblock(context, shape) {
          // get Timeline state
          const { rowHeight, currentScale } = useTimelineStore.getState()

          const x1 = Math.floor(currentScale(clip.systemEndDateTime))

          context.fillShape(shape)

          context.fillText(clip.title, x1 + 6, rowHeight / 2 + 3)
        }}
      /> */}
    </>
  )
})
