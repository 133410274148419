import React from 'react'
import { Rect, Group, Label, Tag, Text } from 'react-konva'
import { ImageAnnotationBoxComparison } from '../../api/codegen/typescript-axios'
import { useStageContext } from './VideoCanvas'

export const ChangedBoxes = ({
  changes,
}: {
  changes: ImageAnnotationBoxComparison[]
}) => {
  const { absoluteTransform } = useStageContext()
  const scale = absoluteTransform.decompose().scaleX

  return (
    <Group {...absoluteTransform.decompose()}>
      {changes.map((change, i) => {
        return (
          <Group key={i}>
            <Label
              x={change.editor_box.x + change.editor_box.w}
              y={change.editor_box.y}
            >
              <Tag pointerWidth={100} fill="#00f" />
              <Text
                fontSize={14 / scale}
                text={`${change.editor_box.label.name} `}
                fill="white"
                padding={2 / scale}
              />
            </Label>
            {change.relabeled && (
              <Label
                x={change.worker_box.x + change.worker_box.w}
                y={change.worker_box.y + 18 / scale}
              >
                <Tag pointerWidth={100} fill="#f00" />
                <Text
                  fontSize={14 / scale}
                  text={`${change.worker_box.label.name} `}
                  fill="white"
                  padding={2 / scale}
                />
              </Label>
            )}
            <Rect
              x={change.worker_box.x}
              y={change.worker_box.y}
              width={change.worker_box.w}
              height={change.worker_box.h}
              stroke={'#f00'}
              strokeWidth={4}
              strokeScaleEnabled={false}
            />
            <Rect
              x={change.editor_box.x}
              y={change.editor_box.y}
              width={change.editor_box.w}
              height={change.editor_box.h}
              stroke={'#00f'}
              strokeWidth={2}
              strokeScaleEnabled={false}
            />
          </Group>
        )
      })}
    </Group>
  )
}
