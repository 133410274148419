import { Link, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useProject } from '../../ProjectWrapper'
import { AnalysesController } from '../AnalysesController'
import { StaticTimeline } from '../Timeline/StaticTimeline'
import { getUseVODStore, VODController } from '../VODController'
import { CranePicksController, useCraneStore } from './CranePicksController'
import { CraneReportsPanelIntraPickIdles } from './CraneReportsPanelIntraPickIdles'
import { CraneReportsPanelSubcontractors } from './CraneReportsPanelSubcontractors'
import { CraneReportsPanelUtilization } from './CraneReportsPanelUtilization'
import { CraneReportsSubcontractorsPie } from './CraneReportsSubcontractorsPie'
import { CraneReportsUtilizationPie } from './CraneReportsUtilizationPie'
import { StaticCraneTimeline } from './StaticCraneTimeline'
import { useSubcontractorStats } from './useSubcontractorStats'

export const CraneReportDailyPrint = () => {
  return (
    <AnalysesController>
      <VODController>
        <CranePicksController>
          <CraneReportDailyPrintLayout />
        </CranePicksController>
      </VODController>
    </AnalysesController>
  )
}

const CraneReportDailyPrintLayout = () => {
  const { t } = useTranslation()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()
  const project = useProject()

  const date = useVODStore((state) => state.videoDate)

  const projectConfig = craneStore((state) => state.projectConfig)
  const selectedCrane = craneStore((state) => state.selectedCrane)
  const days = craneStore((state) => state.days)
  const picks = craneStore((state) => state.picks)
  const calendarEntries = craneStore((state) => state.calendarEntries)
  const subcontractors = craneStore((state) => state.subcontractors)
  const sumPicks = craneStore((state) => state.sumPicks)

  const currentDayData = days.get(date.toISODate())

  React.useEffect(() => {
    document.body.style.width = '8in'
    return () => {
      document.body.style.width = ''
    }
  }, [])

  const timeRange = picks.length
    ? `${picks[0].startDateTime.toLocaleString(DateTime.TIME_SIMPLE)} -
  ${picks[picks.length - 1].endDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
    : ''

  const then = DateTime.fromMillis(0)
  const now = DateTime.now().setZone(project.timezone)

  const startDateTime = DateTime.min(
    picks[0]?.startDateTime || now,
    calendarEntries[0]?.startDateTime || now
  ).set({ millisecond: 0 })

  const endDateTime = DateTime.max(
    picks[picks.length - 1]?.endDateTime || then,
    calendarEntries[calendarEntries.length - 1]?.endDateTime || then
  )

  const url = `https://topdeck.ai/app/${
    project.slug
  }/crane-reports/?dateTime=${encodeURIComponent(
    startDateTime.toISO({
      suppressMilliseconds: true,
    })
  )}&stream=${selectedCrane?.jib_stream_original}&crane=${selectedCrane?.id}`

  const subcontractorStats = useSubcontractorStats(
    picks,
    Array.from(subcontractors.values()),
    sumPicks
  )

  return (
    <div>
      {picks.length > 0 && (
        <div id="loading-complete" style={{ display: 'none' }}>
          LOADING COMPLETE
        </div>
      )}
      <Helmet>
        <title>
          {project.name} -{' '}
          {project.cranes.length > 1 ? selectedCrane?.name + ' - ' : ''}{' '}
          {t('daily_crane_report')} -{' '}
          {date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </title>
      </Helmet>

      <Typography variant="h5">
        {t('daily_crane_report')} <span>- {project.name}</span>
        {project.cranes.length > 1 && selectedCrane && (
          <span> - {selectedCrane.name}</span>
        )}
      </Typography>
      <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
        {date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{' '}
        {!currentDayData || !picks ? null : <span>{timeRange} </span>}
      </Typography>

      <Link color="textSecondary" href={url}>
        {'Report page'}
      </Link>

      {picks.length === 0 && (
        <Typography variant="h6" style={{ marginTop: '1em' }}>
          {t('no_crane_activity_on_this_day')}
        </Typography>
      )}

      <div style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
        <div
          style={{
            padding: '0 20px',
          }}
        >
          <CraneReportsUtilizationPie />
        </div>
        <div
          style={{
            padding: '0 20px',
            display: 'flex',
          }}
        >
          <CraneReportsSubcontractorsPie
            subcontractorStats={subcontractorStats}
          />
        </div>
      </div>
      <CraneReportsPanelUtilization expanded={true} />

      <div
        style={{
          position: 'relative',
          paddingBottom: 20,
          breakInside: 'avoid',
        }}
      >
        {picks.length > 0 && (
          <StaticTimeline
            domain={[
              startDateTime.startOf('hour').minus({ minutes: 30 }),
              endDateTime.endOf('hour').plus({ minutes: 30 }),
            ]}
          >
            <StaticCraneTimeline />
          </StaticTimeline>
        )}
      </div>

      {/* list all picks with idle time over one minute long */}
      {projectConfig?.show_intra_pick_idles_on_daily_report && (
        <CraneReportsPanelIntraPickIdles />
      )}

      <CraneReportsPanelSubcontractors
        expanded
        subcontractorStats={subcontractorStats}
      />
    </div>
  )
}
