import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  ThemeProvider,
  useTheme,
} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import clsx from 'clsx'
import Hls from 'hls.js'
import React, { useEffect, useRef, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import {
  useIntersection,
  useInterval,
  usePreviousDistinct,
  useUnmount,
} from 'react-use'
import { useCallbackRef } from 'use-callback-ref'
import { StreamExtended } from '../../api/StreamExtended'
import useStreamLiveStatus from '../../hooks/useStreamLiveStatus'
import { useTouchDevice } from '../../hooks/useTouchDevice'
import { mixins } from '../../styles/mixins'
import { darkTheme } from '../../styles/theme'
import { Loading } from '../Loading'
import { LivePlayerErrorMessages } from './LivePlayerErrorMessages'
import { VideoControls } from './VideoControls'
import { VideoModal } from './VideoModal'

// @ts-ignore
import mux from 'mux-embed'
import { useMuxBaseConfig } from '../../hooks/useMuxBaseConfig'
import { useProject } from '../ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      [theme.breakpoints.up('sm')]: {},
    },
    videoContainer: {
      backgroundColor: '#000',
      position: 'relative',
    },
    ptzContainer: {
      ...mixins.absoluteFill,
    },
    loadingSpinner: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    poster: {
      background: 'center center no-repeat',
      backgroundSize: 'contain',
    },
    layer: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px',
      animation: '$fadeIn',
      animationDuration: '0.3s',
      animationDelay: '2s',
      animationFillMode: 'both',
    },
    overlay: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      ...mixins.absoluteFill,
      display: 'block',
    },
    dialogPaper: {
      height: '100%',
    },
  })
)

export const VideoPlayerHLS = ({
  stream,
  className,
  parentWidth = window.innerWidth,
  extraControls = {},
  disableIntersectionObserver,
  disableRotation,
  showModal = false,
  setModalStreamID,
  slideshowState,
  setSlideshowState,
  slideshowInterval,
  setSlideshowInterval,
  shouldPlay: shouldPlayProp = true,
  nextVideo,
  prevVideo,
}: {
  stream: StreamExtended
  className?: string
  parentWidth?: number
  extraControls?: {
    download?: boolean
    ptz?: boolean
    play?: boolean
    fullScreen?: boolean
    enlarge?: boolean
  }
  disableIntersectionObserver: boolean
  disableRotation?: boolean
  showModal?: boolean
  setModalStreamID?: (stream?: number) => void
  slideshowState?: boolean
  setSlideshowState?: (b: boolean) => void
  slideshowInterval?: number
  setSlideshowInterval?: (n: number) => void
  shouldPlay?: boolean
  nextVideo?: () => void
  prevVideo?: () => void
}) => {
  const classes = useStyles()

  const log = React.useCallback(
    (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(stream.name, ...args)
      }
    },
    [stream.name]
  )

  const logTable = React.useCallback(
    (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        log('data: ')
        console.table(...args)
      }
    },
    [stream.name]
  )

  // state
  const [, forceUpdate] = useState<HTMLElement | null>()
  const videoContainerRef = useCallbackRef<HTMLDivElement>(null, forceUpdate)
  const videoRef = useCallbackRef<HTMLVideoElement>(null, forceUpdate)

  const project = useProject()
  const muxBaseConfig = useMuxBaseConfig()

  useInterval(() => {
    if (process.env.NODE_ENV !== 'production') {
      forceUpdate(document.createElement('div'))
    }
  }, 1000)

  const hlsRef = React.useRef<Hls>()

  const [buffering, setBuffering] = useState(true)

  const [enlargeWithPtzState, setEnlargeWithPtzState] = useState(false)

  const liveStatus = useStreamLiveStatus(stream)

  const intersection = useIntersection(videoContainerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  })

  const isTouchDevice = useTouchDevice()

  const isVisible = usePageVisibility()
  // end state

  const tryPlay = React.useCallback(
    (reasons?: {}) => {
      if (hlsRef.current && videoRef.current && videoRef.current.paused) {
        log('play()')
        logTable(reasons)

        hlsRef.current.config.maxBufferLength = 30
        hlsRef.current.config.maxMaxBufferLength = 60
        hlsRef.current.resumeBuffering()
        if (hlsRef.current.liveSyncPosition) {
          videoRef.current.currentTime = hlsRef.current.liveSyncPosition
        }

        const _playPromise = videoRef.current.play()

        if (_playPromise && _playPromise.catch) {
          _playPromise.catch((err) => {
            log(err)
          })
        }
      }
    },
    [hlsRef, videoRef]
  )

  const tryPause = React.useCallback(
    (reasons?: {}) => {
      if (hlsRef.current && videoRef.current && !videoRef.current.paused) {
        log('pause()')
        logTable(reasons)

        videoRef.current.pause()

        hlsRef.current.pauseBuffering()
      }
    },
    [hlsRef, videoRef]
  )

  // setup player and error handler
  const initHls = React.useCallback(() => {
    log('initHls()')
    if (hlsRef.current) {
      hlsRef.current.destroy()
    }
    if (
      Hls.isSupported() &&
      videoRef.current &&
      stream.url &&
      !stream.show_still_image
    ) {
      log('new Hls')

      var hls = new Hls({
        enableWorker: true,
        lowLatencyMode: true,
        highBufferWatchdogPeriod: 1,
        maxBufferLength: 1,
        maxMaxBufferLength: 1,
        liveSyncDurationCount: 2,
        maxLiveSyncPlaybackRate: 2,
        liveDurationInfinity: true,

        fragLoadingMaxRetry: Infinity,
        manifestLoadingMaxRetry: Infinity,
        levelLoadingMaxRetry: Infinity,
        nudgeMaxRetry: Infinity,
        // debug: true,
      })

      hlsRef.current = hls

      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        log('video and hls.js are now bound together !')
      })

      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        log('manifest loaded, found ' + data.levels.length + ' quality level')
      })

      hls.on(Hls.Events.ERROR, function (event, data) {
        log('Hls.Events.ERROR', data)
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('fatal media error encountered, try to recover')
              hls.recoverMediaError()
              break
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error('fatal network error encountered', data)
              // All retries and media options have been exhausted.
              // Immediately trying to restart loading could cause loop loading.
              // Consider modifying loading policies to best fit your asset and network
              // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
              // hls.startLoad()
              break
            default:
              // cannot recover
              console.error('unrecoverable error')
              hls.destroy()
              break
          }
        }
      })

      log(`hls.loadSource(${stream.url})`, videoRef.current)
      hls.loadSource(stream.url)
      hls.attachMedia(videoRef.current)

      mux.monitor(videoRef.current, {
        debug: false,
        hlsjs: hls,
        Hls: Hls,
        data: {
          ...muxBaseConfig,

          player_name: 'VideoPlayerHLS', // any arbitrary string you want to use to identify this player

          // Video Metadata
          video_id: stream.id.toString(), // ex: 'abcd123'
          video_title: `${project.slug} - ${stream.name} - LIVE`, // ex: 'My Great Video'
          // video_series: '', // ex: 'Weekly Great Videos'
          video_stream_type: 'live', // 'live' or 'on-demand',
          video_content_type: 'live video',
        },
      })
    } else if (videoRef.current && stream.url) {
      // MSE not supported on iOS but can run hls natively
      videoRef.current.src = stream.url
    }
  }, [videoRef.current, stream.url, log])

  // control all plays/pauses in here to minimize conflicts

  const shouldPlayReasons = React.useMemo(() => {
    return {
      shouldPlay:
        shouldPlayProp &&
        !!videoRef.current &&
        isVisible &&
        !isTouchDevice &&
        !stream.show_still_image &&
        (disableIntersectionObserver || intersection?.isIntersecting === true),
      shouldPlayProp,
      videoEl: !!videoRef.current,
      buffering,
      isVisible,
      isntTouchDevice: !isTouchDevice,
      isntStillImage: !stream.show_still_image,
      isIntersectingEnough:
        disableIntersectionObserver || intersection?.isIntersecting === true,
      disableIntersectionObserver,
      intersection: !!intersection,
      isIntersecting: intersection?.isIntersecting,
    }
  }, [
    shouldPlayProp,
    videoRef,
    videoRef.current,
    buffering,
    isVisible,
    isTouchDevice,
    disableIntersectionObserver,
    intersection,
  ])

  // for logging, create an object of changes variables which trigger play/pause
  const previousShouldPlayReasons = usePreviousDistinct(shouldPlayReasons)
  const differentShouldPlayReasons = React.useMemo(() => {
    if (!previousShouldPlayReasons) return shouldPlayReasons
    const keys: Array<keyof typeof shouldPlayReasons> = Object.keys(
      shouldPlayReasons
    ) as Array<keyof typeof shouldPlayReasons>
    return keys.reduce<Partial<typeof shouldPlayReasons>>((acc, key) => {
      if (shouldPlayReasons[key] !== previousShouldPlayReasons[key]) {
        acc[key] = shouldPlayReasons[key]
      }
      return acc
    }, {})
  }, [shouldPlayReasons, previousShouldPlayReasons])

  // play/pause side effects
  useEffect(() => {
    // if no hls player, init it
    if (!hlsRef.current) {
      log('no hls player, calling initHls()')
      initHls()
    }

    if (shouldPlayReasons.shouldPlay) {
      tryPlay(differentShouldPlayReasons)
    } else {
      tryPause(differentShouldPlayReasons)
    }
  }, [
    shouldPlayReasons.shouldPlay,
    differentShouldPlayReasons,
    tryPlay,
    tryPause,
    hlsRef,
    initHls,
    log,
  ])

  const previousTime = useRef(0)
  useInterval(() => {
    if (videoRef.current && hlsRef.current) {
      if (
        !videoRef.current.paused &&
        videoRef.current.currentTime === previousTime.current
      ) {
        log('stream frozen, calling initHls()')
        initHls()
      }
      previousTime.current = videoRef.current.currentTime
    }
  }, 5000)

  // dispose player on unmount
  useUnmount(() => {
    if (hlsRef.current) {
      hlsRef.current.destroy()
    }
  })

  useEffect(() => {
    if (!showModal) {
      setEnlargeWithPtzState(false)
    }
  }, [showModal])
  // end side effects

  // subtract 40 for control bar height, projectNavHeight for nav
  const parentAspectRatio =
    parentWidth / (window.innerHeight - 40 - useTheme().custom.projectNavHeight)

  const videoAspectRatio = disableRotation
    ? stream.aspectRatioNumber
    : stream.rotatedAspectRatioNumber

  const containerAspectRatio =
    stream.width === 1
      ? Math.max(parentAspectRatio, videoAspectRatio)
      : videoAspectRatio

  const containerStyle = {
    paddingTop: (1 / containerAspectRatio) * 100 + '%',
  }

  const videoStyle =
    stream.rotation % 180 === 90 && !disableRotation
      ? {
          width: `${stream.aspectRatioNumber * 100}%`,
          height: `${(1 / stream.aspectRatioNumber) * 100}%`,
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%) rotate(${stream.rotation}deg)`,
        }
      : { width: '100%', height: '100%', top: 0, left: 0 }

  const poster = () => (
    <div
      className={clsx(classes.layer, classes.poster)}
      style={{
        backgroundImage: `url(${stream.current_image_url})`,
      }}
    ></div>
  )

  // log buffering changes
  useEffect(() => {
    if (!hlsRef.current) return
    log(
      'buffering:',
      buffering,
      ', latency:',
      Math.round(hlsRef.current.latency * 10) / 10,
      ', targetLatency:',
      hlsRef.current.targetLatency
    )
  }, [shouldPlayReasons.shouldPlay, buffering, hlsRef, hlsRef.current])

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper className={clsx(className, classes.root)}>
        <div
          ref={videoContainerRef}
          className={classes.videoContainer}
          style={containerStyle}
        >
          {!liveStatus && poster()}
          <video
            ref={videoRef}
            className={classes.video}
            style={videoStyle}
            muted
            // controls
            onLoadStart={() => {
              log('onLoadStart')
              setBuffering(true)
            }}
            onWaiting={() => {
              log('onWaiting')
              setBuffering(false)
            }}
            onStalled={() => {
              log('onStalled')
              setBuffering(true)
            }}
            onCanPlay={() => {
              log('onCanPlay')
              setBuffering(false)
            }}
          />
          {stream.show_still_image && poster()}
          {!stream.show_still_image && buffering && !isTouchDevice && (
            <div className={classes.layer}>
              <Loading
                text={'Loading stream...'}
                className={classes.loadingSpinner}
              ></Loading>
            </div>
          )}
          {isTouchDevice &&
            (!stream.computed_error_messages ||
              stream.computed_error_messages?.length === 0) && (
              <div
                className={classes.overlay}
                onClick={() => {
                  if (videoRef.current && isTouchDevice) {
                    if (videoRef.current.requestFullscreen) {
                      videoRef.current.requestFullscreen()
                    } else if (videoRef.current.webkitEnterFullscreen) {
                      videoRef.current.webkitEnterFullscreen()
                    }
                  }
                }}
              >
                <PlayCircleFilledIcon
                  style={{ color: '#fff', width: 80, height: 80 }}
                />
              </div>
            )}
          <LivePlayerErrorMessages stream={stream} liveStatus={liveStatus} />
        </div>
        <VideoControls
          stream={stream}
          setPtzState={
            extraControls.ptz
              ? () => {
                  setModalStreamID && setModalStreamID(stream.id)
                  setEnlargeWithPtzState(true)
                }
              : undefined
          }
          setEnlargeState={
            extraControls.enlarge
              ? () => {
                  setModalStreamID && setModalStreamID(stream.id)
                }
              : undefined
          }
          setFullScreenState={() => {
            if (videoRef.current) {
              if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen()
              } else if (videoRef.current.webkitEnterFullscreen) {
                videoRef.current.webkitEnterFullscreen()
              }
            }
          }}
        >
          {/* if not in production env, log hls live stats */}
          {process.env.NODE_ENV !== 'production' && (
            <Table size="small">
              <TableRow>
                <TableCell>
                  targetLatency: {hlsRef.current?.targetLatency}
                </TableCell>
                <TableCell>
                  actual latency:{' '}
                  {hlsRef.current?.latency &&
                    Math.round(hlsRef.current.latency * 10) / 10}
                </TableCell>
              </TableRow>
            </Table>
          )}
        </VideoControls>
        {videoRef.current && (
          <Dialog
            open={showModal}
            onClose={() => {
              setEnlargeWithPtzState(false)
              setModalStreamID && setModalStreamID()
              setSlideshowState && setSlideshowState(false)
            }}
            fullWidth
            maxWidth="xl"
            style={{ height: '100%' }}
            classes={{
              paper: classes.dialogPaper,
            }}
            transitionDuration={0}
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={() => {
                setEnlargeWithPtzState(false)
                setModalStreamID && setModalStreamID()
                setSlideshowState && setSlideshowState(false)
              }}
              style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
            <VideoModal
              video={videoRef.current}
              stream={stream}
              ptz={extraControls?.ptz}
              openWithPtz={enlargeWithPtzState}
              slideshowState={slideshowState}
              setSlideshowState={setSlideshowState}
              slideshowInterval={slideshowInterval}
              setSlideshowInterval={setSlideshowInterval}
              nextVideo={nextVideo}
              prevVideo={prevVideo}
            />
          </Dialog>
        )}
      </Paper>
    </ThemeProvider>
  )
}
