import { useState, MutableRefObject } from 'react'
import { useCallbackRef } from 'use-callback-ref'

export default function useRefCurrent<T>(
  initialValue: T | null
): [MutableRefObject<T | null>, T | null] {
  const [current, setCurrent] = useState<T | null>(null)
  const ref = useCallbackRef<T>(initialValue, (current) => {
    if (current) {
      setCurrent(current)
    }
  })
  return [ref, current]
}
