import { memoize } from 'lodash'
import { DateTime } from 'luxon'
import React, { FC, useState } from 'react'
import zustand, { UseStore } from 'zustand'
import {
  Analysis,
  SegmentationDrawing,
  SegmentationLabel,
} from '../../../api/codegen/typescript-axios'
import {
  extendSegmentationProgress,
  SegmentationProgressExtended,
} from '../../../api/SegmentationProgressExtended'
import { createCtx } from '../../../helpers/createCtx'
import { useMe } from '../../../hooks/useMe'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'

export interface DeckAIGroup {
  id: number
  startDateTime: DateTime
  systemStartDateTime: DateTime
  endDateTime: DateTime
  systemEndDateTime: DateTime
  areaChanged: number
  valueAdd: {
    activeSegments: number
    totalSegments: number
  }
  segments: SegmentationProgressExtended[]
}

export interface LabelData {
  label: SegmentationLabel
  groupTypes: Record<
    DeckAIOverlayOptionGroupBy,
    {
      domain: [number, number]
      groups: DeckAIGroup[]
    }
  >
}

type SegmentationData = Map<number, LabelData>

export type DeckAIOverlayOptionColorBy = 'Work rate' | 'Completion time'
export type DeckAIOverlayOptionGroupBy = 'None' | 'Hour' | 'Day'

export type DeckAIState = {
  labels: SegmentationLabel[]
  labelId?: number
  analyses: Analysis[]
  analysisId?: number
  segmentationData: SegmentationData
  selectedLabelData?: LabelData
  currentGroup?: DeckAIGroup
  segmentationDrawings: SegmentationDrawing[]
  segmentationDrawing?: SegmentationDrawing
  overlayOptions: {
    show: 'full cycle' | 'live'
    colorBy: DeckAIOverlayOptionColorBy
    groupBy: DeckAIOverlayOptionGroupBy
  }
  mouseLocation: string
  fetchAnalyses: () => void
  fetchAnalysis: () => void
}

export type UseDeckAIStore = UseStore<DeckAIState>

export const [useDeckAIStore, DeckAIContext] = createCtx<UseDeckAIStore>()

// Must be descendant of AnalysesController
export const DeckAIController: FC<{}> = ({ children }) => {
  const api = useApi()
  const project = useProject()
  const { data: me } = useMe()
  const useVODStore = getUseVODStore()

  // init store
  const [useStore] = useState<UseDeckAIStore>(() =>
    zustand<DeckAIState>((set, get) => ({
      labels: [],
      labelId: undefined,
      analyses: [],
      analysisId: undefined,
      segmentationData: new Map(),
      selectedLabelData: undefined,
      currentGroup: undefined,
      segmentationDrawings: [],
      segmentationDrawing: undefined,
      mouseLocation: '',
      overlayOptions: {
        show: 'live',
        colorBy: 'Work rate',
        groupBy: 'None',
      },
      fetchAnalyses: async () => {
        const analysesResp = await api.segmentationReportingApi.segmentationAnalysesList(
          {
            projectId: project.id,
          }
        )
        const labelsResp = await api.segmentationReportingApi.segmentationLabelsList()

        set({ analyses: analysesResp.data, labels: labelsResp.data })
      },
      fetchAnalysis: async () => {
        const analysisId = get().analysisId

        if (!analysisId) return

        const progressResp = await api.segmentationReportingApi.segmentationProgressList(
          { analysisId }
        )
        const drawingsResp = await api.segmentationReportingApi.segmentationDrawingsList(
          { analysisId }
        )

        const segmentationData: SegmentationData = new Map()

        progressResp.data.forEach((segment) => {
          if (!segment.label) return
          if (!segmentationData.has(segment.label)) {
            segmentationData.set(segment.label, {
              label: get().labels.find((label) => label.id === segment.label)!,
              groupTypes: {
                None: {
                  domain: [0, 0],
                  groups: [],
                },
                Hour: {
                  domain: [0, 0],
                  groups: [],
                },
                Day: {
                  domain: [0, 0],
                  groups: [],
                },
              },
            })
          }
          const labelData = segmentationData.get(segment.label)!

          const segmentExtended = extendSegmentationProgress(segment, project)

          // None
          labelData.groupTypes.None.domain[1] = Math.max(
            labelData.groupTypes.None.domain[1],
            segmentExtended.areaChanged
          )

          labelData.groupTypes.None.groups.push({
            id: segmentExtended.id,
            startDateTime: segmentExtended.startDateTime,
            systemStartDateTime: segmentExtended.systemStartDateTime,
            endDateTime: segmentExtended.endDateTime,
            systemEndDateTime: segmentExtended.systemEndDateTime,
            areaChanged: segmentExtended.areaChanged,
            segments: [segmentExtended],
            valueAdd: {
              activeSegments: 1,
              totalSegments: segmentExtended.areaChanged ? 1 : 0,
            },
          })

          // Hour
          const hour = labelData.groupTypes.Hour
          const currentHourGroup = hour.groups[hour.groups.length - 1]

          if (
            !currentHourGroup ||
            !currentHourGroup.startDateTime.hasSame(
              segmentExtended.startDateTime,
              'hour'
            )
          ) {
            hour.groups.push({
              id: segmentExtended.id,
              startDateTime: segmentExtended.startDateTime,
              systemStartDateTime: segmentExtended.systemStartDateTime,
              endDateTime: segmentExtended.startDateTime.endOf('hour'),
              systemEndDateTime: segmentExtended.systemStartDateTime.endOf(
                'hour'
              ),
              areaChanged: segmentExtended.areaChanged,
              segments: [segmentExtended],
              valueAdd: {
                activeSegments: segmentExtended.areaChanged ? 1 : 0,
                totalSegments: 1,
              },
            })
          } else {
            currentHourGroup.areaChanged =
              currentHourGroup.areaChanged + segmentExtended.areaChanged
            currentHourGroup.segments.push(segmentExtended)
            currentHourGroup.valueAdd = incrementValueAdd(
              currentHourGroup.valueAdd,
              currentHourGroup.areaChanged
            )
          }

          const newCurrentHourGroup = hour.groups[hour.groups.length - 1]

          labelData.groupTypes.Hour.domain[1] = Math.max(
            labelData.groupTypes.Hour.domain[1],
            newCurrentHourGroup.areaChanged
          )

          // Day
          const day = labelData.groupTypes.Day
          const currentDayGroup = day.groups[day.groups.length - 1]

          if (
            !currentDayGroup ||
            !currentDayGroup.startDateTime.hasSame(
              segmentExtended.startDateTime,
              'day'
            )
          ) {
            day.groups.push({
              id: segmentExtended.id,
              startDateTime: segmentExtended.startDateTime.startOf('day'),
              systemStartDateTime: segmentExtended.systemStartDateTime.startOf(
                'day'
              ),
              endDateTime: segmentExtended.startDateTime.endOf('day'),
              systemEndDateTime: segmentExtended.systemStartDateTime.endOf(
                'day'
              ),
              areaChanged: segmentExtended.areaChanged,
              segments: [segmentExtended],
              valueAdd: {
                activeSegments: segmentExtended.areaChanged ? 1 : 0,
                totalSegments: 1,
              },
            })
          } else {
            currentDayGroup.areaChanged =
              currentDayGroup.areaChanged + segmentExtended.areaChanged
            currentDayGroup.segments.push(segmentExtended)
            currentDayGroup.valueAdd = incrementValueAdd(
              currentDayGroup.valueAdd,
              currentDayGroup.areaChanged
            )
          }

          const newCurrentDayGroup = day.groups[day.groups.length - 1]

          labelData.groupTypes.Day.domain[1] = Math.max(
            labelData.groupTypes.Day.domain[1],
            newCurrentDayGroup.areaChanged
          )
        })

        set({
          segmentationData: segmentationData,
          segmentationDrawings: drawingsResp.data,
        })
      },
    }))
  )

  const labelId = useStore((state) => state.labelId)
  const analysisId = useStore((state) => state.analysisId)
  const videoMinute = useVODStore((state) => state.videoMinute)

  React.useEffect(() => {
    if (me?.isAnnotator) {
      useStore.getState().fetchAnalyses()
    }

    return () => {
      useStore.destroy()
    }
  }, [useStore, me, project])

  React.useEffect(() => {
    return useStore.subscribe(
      () => {
        useStore.getState().fetchAnalysis()
      },
      (state) => state.analysisId
    )
  }, [useStore])

  React.useEffect(() => {
    if (!labelId) return

    const selectedLabelData = useStore.getState().segmentationData.get(labelId)

    if (!selectedLabelData) return

    const currentGroup = selectedLabelData.groupTypes[
      useStore.getState().overlayOptions.groupBy
    ].groups.find(
      (group) =>
        group.startDateTime <= videoMinute && group.endDateTime > videoMinute
    )

    useStore.setState({ selectedLabelData, currentGroup })
  }, [videoMinute, labelId, analysisId])

  return (
    <DeckAIContext.Provider value={useStore}>{children}</DeckAIContext.Provider>
  )
}

const buffer1 = document.createElement('canvas')

export const loadImageBitmap = memoize((src: string) => {
  return new Promise<ImageBitmap>((resolve, reject) => {
    const img = document.createElement('img')
    img.crossOrigin = 'Anonymous'

    img.onload = async () => {
      const width = img.naturalWidth
      const height = img.naturalHeight
      buffer1.width = width
      buffer1.height = height
      const context = buffer1.getContext('2d')!
      context.drawImage(img, 0, 0)
      const imageData = context.getImageData(0, 0, width, height)
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (imageData.data[i + 0] === 0) {
          // imageData.data[i + 1] = 0
          // imageData.data[i + 2] = 0
          imageData.data[i + 3] = 0
        }
      }
      const bitmap = await createImageBitmap(imageData, {
        // resizeWidth: Math.round(width / 24),
        // resizeHeight: Math.round(height / 24),
        // resizeQuality: 'pixelated',
      })
      resolve(bitmap)
    }

    img.onerror = () => {
      reject(img)
    }

    img.src = src
  })
})

export const combineImageBitmap = async (srcs: string[]) => {
  const bitmaps = await Promise.all(srcs.map((src) => loadImageBitmap(src)))

  buffer1.width = bitmaps.reduce(
    (prev, bitmap) => Math.max(prev, bitmap.width),
    0
  )
  buffer1.height = bitmaps.reduce(
    (prev, bitmap) => Math.max(prev, bitmap.width),
    0
  )

  buffer1.getContext('2d')?.clearRect(0, 0, buffer1.width, buffer1.height)

  bitmaps.forEach((bitmap) => {
    buffer1.getContext('2d')?.drawImage(bitmap, 0, 0)
  })

  return await createImageBitmap(buffer1)
}

export const cloneImageData = (imageData: ImageData) => {
  return new ImageData(
    new Uint8ClampedArray(imageData.data),
    imageData.width,
    imageData.height
  )
}

export const filterImageData = (
  imageData: ImageData,
  filterFn: (imageData: ImageData) => ImageData
) => {
  const clone = cloneImageData(imageData)
  return filterFn(clone)
}

export const colorizeImage = (imageBitmap: ImageBitmap, color: string) => {
  buffer1.width = imageBitmap.width
  buffer1.height = imageBitmap.height
  const context1 = buffer1.getContext('2d')!
  context1.fillStyle = color
  context1.fillRect(0, 0, imageBitmap.width, imageBitmap.height)
  context1.globalCompositeOperation = 'destination-in'
  context1.drawImage(imageBitmap, 0, 0)

  return createImageBitmap(buffer1)
}

const incrementValueAdd = (
  previous: { activeSegments: number; totalSegments: number },
  areaChanged: number
) => {
  return {
    activeSegments: previous.activeSegments + (areaChanged > 0 ? 1 : 0),
    totalSegments: previous.totalSegments + 1,
  }
}
