import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  makeStyles,
  Slider,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GetAppIcon from '@material-ui/icons/GetApp'
import fileDownload from 'js-file-download'
import React from 'react'
import { MapRef } from 'react-map-gl'
import { MappingOverlay } from '../api/codegen/typescript-axios'
import { useProject } from './ProjectWrapper'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: 5,
    top: 5,
    maxHeight: 'calc(100% - 10px)',
    width: 300,
    maxWidth: 'calc(100% - 10px)',
  },
  header: {
    // padding: '10px 15px',
  },
  control: {
    padding: 15,
    paddingBottom: 0,
  },
  controlLabel: {
    cursor: 'pointer',
  },
  navigationControl: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  epxansionPanelDetails: {
    padding: 0,
    display: 'block',
  },
})

export const MapLayerControls = ({
  mapRef,
  overlayArray,
  layerOpacities,
  setLayerOpacities,
  drawingStats,
  screenshotFilename,
}: {
  mapRef: React.RefObject<MapRef>
  overlayArray: MappingOverlay[]
  layerOpacities: number[]
  setLayerOpacities: (o: number[]) => void
  drawingStats?: string
  screenshotFilename?: string
}) => {
  const classes = useStyles()
  const project = useProject()

  const filename = screenshotFilename || project.name

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.header}>Project Overlays</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.epxansionPanelDetails}>
          {overlayArray.map((overlay, i) => (
            <div
              className={classes.control}
              key={overlay.display_name}
              style={{
                opacity: layerOpacities[i] === 0 ? 0.5 : 1,
              }}
            >
              <Typography
                className={classes.controlLabel}
                id="continuous-slider"
                gutterBottom
                variant="subtitle2"
                onClick={() => {
                  const newOpacities = layerOpacities.slice()
                  newOpacities[i] = newOpacities[i] === 0 ? 1 : 0
                  setLayerOpacities(newOpacities)
                }}
              >
                {overlay.display_name}
              </Typography>
              <Slider
                min={0}
                max={1}
                step={0.01}
                value={layerOpacities[i]}
                onChange={(e, value) => {
                  const newOpacities = layerOpacities.slice()
                  newOpacities[i] = value as number
                  setLayerOpacities(newOpacities)
                }}
                aria-labelledby="continuous-slider"
              />
            </div>
          ))}
          {/* <div className={classes.control} style={{ paddingBottom: 15 }}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<GetAppIcon />}
              onClick={() => {
                if (mapRef.current) {
                  // console.log(mapRef.current.getMap().getCanvas() as HTMLCanvasElement)
                  ;(mapRef.current
                    .getMap()
                    .getCanvas() as HTMLCanvasElement).toBlob((blob) => {
                    if (blob) {
                      console.log(blob)
                      fileDownload(blob, 'topdeck-ortho.png')
                    }
                  }, 'image/png')
                }
              }}
            >
              {'Export Image'}
            </Button>
          </div> */}
          {drawingStats && (
            <div className={classes.control} style={{ paddingBottom: 15 }}>
              {drawingStats}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
