import { isNull, omitBy } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { DateTime } from 'luxon'
import { assertId, assertRequired, HasID } from './assertRequired'
import {
  Crane,
  ProjectDetails,
  PublicProject,
} from './codegen/typescript-axios'
import {
  extendPublicStream,
  extendStream,
  PublicStreamExtended,
  StreamExtended,
} from './StreamExtended'

export interface VideoArchiveDate {
  dateString: string
  startDateTime: DateTime
  endDateTime: DateTime
  systemStartDateTime: DateTime
  systemEndDateTime: DateTime
  streamIds: number[]
}

export interface ProjectDetailsExtended extends HasID<ProjectDetails> {
  streams: StreamExtended[]
  videoArchiveDates: VideoArchiveDate[]
  cranes: Array<HasID<Crane>>
}

export interface PublicProjectExtended extends PublicProject {
  streams: PublicStreamExtended[]
}

const defaultMappingViewport = {
  bearing: 0,
  latitude: 0,
  longitude: 0,
  zoom: 0,
}

export function extendProject(project: ProjectDetails): ProjectDetailsExtended {
  assertRequired(project, ['id', 'videoarchive_dates'])

  const clone = cloneDeep(project)

  const projectExtended: ProjectDetailsExtended = {
    ...clone,
    cranes: clone.cranes
      ? clone.cranes
          .map((crane) => {
            assertId(crane)
            return crane
          })
          .sort((craneA, craneB) => {
            const nameA = craneA.name.toUpperCase() // ignore upper and lowercase
            const nameB = craneB.name.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }

            // names must be equal
            return 0
          })
      : [],
    videoArchiveDates: [],
    streams: [],
  }

  projectExtended.videoArchiveDates = Object.keys(
    project.videoarchive_dates
  ).map((dateString) => {
    const startDateTime = DateTime.fromISO(dateString, {
      zone: project.timezone,
    })
    const endDateTime = startDateTime.endOf('day')
    return {
      dateString,
      startDateTime,
      endDateTime,
      systemStartDateTime: startDateTime.setZone('system', {
        keepLocalTime: true,
      }),
      systemEndDateTime: endDateTime.setZone('system', { keepLocalTime: true }),
      streamIds: project.videoarchive_dates[dateString],
    }
  })

  projectExtended.streams =
    clone.streams?.map((s) => extendStream(s, projectExtended)) || []

  projectExtended.mapping_viewport = {
    ...omitBy(defaultMappingViewport, isNull),
    ...omitBy(projectExtended.mapping_viewport, isNull),
  }

  return projectExtended
}

export function extendPublicProject(
  project: PublicProject
): PublicProjectExtended {
  const clone = cloneDeep(project)
  const projectExtended: PublicProjectExtended = {
    ...clone,
    streams: [],
  }
  projectExtended.streams =
    clone.streams?.map((s) => extendPublicStream(s, projectExtended)) || []
  return projectExtended
}
