import {
  createStyles,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { AxisTop } from '@visx/axis'
import { scaleTime } from '@visx/scale'
import { DateTime } from 'luxon'
import React from 'react'
import { useMeasure } from 'react-use'
import { ArmingScheduleItem } from '../../api/codegen/typescript-axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      maxWidth: 700,
      padding: 10,
    },
    inner: {},
    day: {
      position: 'relative',
      marginTop: 20,
      marginBottom: 20,
    },
    dayLabel: { textAlign: 'left' },
    dayBlock: {
      position: 'relative',
      height: 20,
      overflow: 'hidden',
      backgroundColor: theme.palette.success.main,
    },
    armBlock: {
      position: 'absolute',
      top: 0,
      height: '100%',
      backgroundColor: theme.palette.error.main,
      borderWidth: '0 3px 0',
      borderColor: theme.palette.background.default,
      borderStyle: 'solid',
    },
    svg: {
      display: 'block',
      position: 'relative',
    },
  })
)

export const SecurityRenderSchedule = ({
  data,
}: {
  data: ArmingScheduleItem[]
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const { armedBlocks, scheduledEvents } = React.useMemo(
    () => processSchedule(data),
    [data]
  )

  return (
    <div className={classes.root}>
      <div ref={ref} className={classes.inner}>
        {[0, 1, 2, 3, 4, 5, 6].map((day) => {
          const scale = scaleTime({
            range: [0, width],
            round: true,
            domain: [
              DateTime.local()
                .startOf('week')
                .plus({ days: day, seconds: 1 })
                .toJSDate(),
              DateTime.local()
                .startOf('week')
                .plus({ days: day })
                .endOf('day')
                .toJSDate(),
            ],
            nice: false,
            clamp: false,
          })
          return (
            <div className={classes.day} key={day}>
              <Typography className={classes.dayLabel}>
                {DateTime.local()
                  .startOf('week')
                  .plus({ days: day })
                  .toLocaleString({ weekday: 'long' })}
              </Typography>
              <svg width={width} height={20} className={classes.svg}>
                <AxisTop
                  scale={scale}
                  stroke={'transparent'}
                  tickStroke={'transparent'}
                  top={20}
                  left={10}
                  hideZero
                  numTicks={23}
                  tickLength={4}
                  tickLabelProps={() => ({
                    fill: theme.palette.text.primary,
                    fontSize: 12,
                    textAnchor: 'middle',
                    dy: -3,
                  })}
                  tickFormat={(date) => {
                    if (date instanceof Date) {
                      return DateTime.fromJSDate(date).toLocaleString(
                        DateTime.TIME_SIMPLE
                      )
                    } else {
                      return '1'
                    }
                  }}
                  tickValues={scheduledEvents.map((e) => e.dateTime.toJSDate())}
                />
              </svg>
              <div key={day} className={classes.dayBlock}>
                {armedBlocks &&
                  armedBlocks.map((block) => {
                    const left = scale(block.start.dateTime.toJSDate())
                    const right = scale(block.end.dateTime.toJSDate())
                    const width = right - left
                    return (
                      <div
                        key={block.start.dateTime.toISO()}
                        className={classes.armBlock}
                        style={{
                          left: left,
                          width: width,
                        }}
                      />
                    )
                  })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function processSchedule(data: ArmingScheduleItem[]) {
  if (!data.length) return { armedBlocks: [], scheduledEvents: [] }

  const monday = DateTime.local().startOf('week')
  interface ScheduledEvent extends ArmingScheduleItem {
    dateTime: DateTime
  }

  const scheduledEvents = data.reduce<Array<ScheduledEvent>>((acc, curr) => {
    const time = curr.event_time.split(':').map((n) => parseInt(n))
    acc.push({
      ...curr,
      dateTime: monday.plus({
        days: curr.day,
        hours: time[0],
        minutes: time[1],
      }),
    })
    return acc
  }, [])

  const first = scheduledEvents[0]
  const last = scheduledEvents[scheduledEvents.length - 1]

  if (first.armed == false) {
    // add starting and ending times for blocks in other weeks
    scheduledEvents.unshift({
      ...last,
      dateTime: last.dateTime.minus({ weeks: 1 }),
    })
    scheduledEvents.push({
      ...first,
      dateTime: first.dateTime.plus({ weeks: 1 }),
    })
  }

  const armedBlocks = scheduledEvents.reduce<
    Array<{
      start: ScheduledEvent
      end: ScheduledEvent
    }>
  >((acc, curr, i, arr) => {
    if (i % 2 === 0) {
      acc.push({
        start: curr,
        end: arr[i + 1],
      })
    }
    return acc
  }, [])

  return { armedBlocks, scheduledEvents }
}
