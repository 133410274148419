import {
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { useWindowSize } from 'react-use'
import { useMe } from '../../../hooks/useMe'
import { useMobile } from '../../../hooks/useMobile'
import { useTouchDevice } from '../../../hooks/useTouchDevice'
import { DragResizeBox } from '../../DragResizeBox'
import { AnalysesController } from '../AnalysesController'
import { VODClock } from '../VODClock'
import { VODController } from '../VODController'
import { VODControls } from '../VODControls'
import { VODPlayer } from '../VODPlayer'
import { VODThumbnails } from '../VODThumbnails'
import { CranePickDetailPanel } from './CranePickDetailPanel'
import { CranePicksController, useCraneStore } from './CranePicksController'
import { CranePicksPanel } from './CranePicksPanel'
import { getUseVODStore } from '../VODController'
import { CraneSelect } from './CraneSelect'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        maxHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
      topControl: {
        padding: theme.spacing(1),
        border: '1px solid',
        borderColor: theme.palette.divider,
      },
      moduleRow: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'hidden',
        overflowX: 'auto',
      },
      right: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
      bottom: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      timeline: {
        flexGrow: 1,
      },
    }),
  {
    name: 'CraneLayout',
  }
)

export const CraneAnnotationPage = () => {
  return (
    <AnalysesController>
      <VODController>
        <CranePicksController>
          <CraneLayout />
        </CranePicksController>
      </VODController>
    </AnalysesController>
  )
}

const CraneLayout = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { data: me } = useMe()

  const touchDevice = useTouchDevice()
  const isMobile = useMobile()
  const { height } = useWindowSize()

  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()

  const craneEventStates = craneStore((state) => state.craneEventStates)

  const initialState = {
    mouseX: null,
    mouseY: null,
  }

  const [state, setState] = React.useState<{
    mouseX: null | number
    mouseY: null | number
  }>(initialState)

  return (
    <div
      className={classes.root}
      style={{ height: height - theme.custom.projectNavHeight }}
    >
      <CraneSelect />
      <div className={classes.moduleRow}>
        <div className={classes.right}>
          <CranePickDetailPanel />

          <div style={{ padding: theme.spacing(1) }}>
            <VODClock />
          </div>

          {/* Player */}
          <div
            style={{
              position: 'relative',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
            onContextMenu={(event) => {
              event.preventDefault()
              useVODStore.getState().videoRef.current?.pause()
              setState({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
              })
            }}
          >
            <Menu
              keepMounted
              open={state.mouseY !== null}
              onClose={() => {
                setState(initialState)
              }}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
            >
              {Array.from(craneEventStates.values()).map((eventState, i) => (
                <MenuItem
                  key={eventState.id}
                  onClick={() => {
                    useVODStore.getState().videoRef.current?.play()
                    craneStore.getState().createEvent(eventState)
                    setState(initialState)
                  }}
                >
                  {eventState.name}
                </MenuItem>
              ))}
            </Menu>

            <VODPlayer></VODPlayer>
          </div>

          {/* New Controls */}
          {!touchDevice && <VODControls />}
        </div>

        <DragResizeBox
          handleSide="left"
          defaultSize={300}
          minSize={270}
          localStorageKey="crane-picks-col"
        >
          <CranePicksPanel autoScroll={false} />
        </DragResizeBox>

        {!isMobile && me?.isAnnotator && (
          <DragResizeBox
            handleSide="left"
            defaultSize={190}
            minSize={190}
            localStorageKey="vod-thumbs-col"
          >
            <VODThumbnails />
          </DragResizeBox>
        )}
      </div>
    </div>
  )
}
