import { Button, createStyles, makeStyles } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import React, { FunctionComponent, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useMeasure } from 'react-use'
import { mixins } from '../../styles/mixins'
import { AntMediaWebRTCPlayer } from '../AntMediaWebRTCPlayer'
import { useProject } from '../ProjectWrapper'
import { VideoControls } from './VideoControls'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
    },
    backBar: {
      height: 48,
      padding: theme.spacing(1),
    },
    videoContainer: {
      flexGrow: 1,
      position: 'relative',
    },
    ptzContainer: {
      ...mixins.absoluteFill,
    },
  })
)

export const MobilePTZPage: FunctionComponent<{}> = () => {
  const classes = useStyles()
  const { streamId } = useParams()
  const project = useProject()
  const navigate = useNavigate()

  const stream = project.streams.find((s) => s.id.toString() === streamId)

  const [measuringRef, containerDims] = useMeasure<HTMLDivElement>()
  const [ptzState, setPtzState] = useState(true)
  const [rtcState, setRtcState] = useState(!!stream?.ant_id)
  const [error, setError] = useState('')

  if (!stream) return null

  return (
    <div className={clsx(classes.root)}>
      <div ref={measuringRef} className={classes.videoContainer}>
        <div className={classes.ptzContainer}>
          {rtcState && (
            <AntMediaWebRTCPlayer
              stream={stream}
              setError={(error: string) => {
                setError(error)
                setRtcState(false)
              }}
            />
          )}
          {/* {ptzState && (
            <PTZDial
              width={containerDims.width}
              height={containerDims.height}
              streamId={stream.id}
            />
          )} */}
          {error && <Alert severity="error">{error}</Alert>}
        </div>
      </div>
      <VideoControls stream={stream} ptzState={true} rtcState={rtcState}>
        <Button
          onClick={() => {
            navigate(-1)
          }}
          startIcon={<ArrowBack />}
        >
          Back to live page
        </Button>
      </VideoControls>
    </div>
  )
}
