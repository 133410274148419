import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Nav } from './js/components/Nav'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    paddingTop: 48,
  },
})

export const NavPages = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Nav />
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  )
}
