import { Box, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { PageWrapper } from './PageWrapper'

export const FourOhFour = () => {
  const theme = useTheme()
  return (
    <PageWrapper>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexDirection="column"
        height={`calc(100vh - ${theme.custom.projectNavHeight}px)`}
      >
        <Typography variant="h1">404</Typography>
        <Typography variant="h3">Page not found</Typography>
        <Link to={`/`} style={{ color: theme.palette.primary.main }}>
          Go to homepage
        </Link>
      </Box>
    </PageWrapper>
  )
}
