import {
  createStyles,
  makeStyles,
  Paper,
  ThemeProvider,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import RemoveIcon from '@material-ui/icons/Remove'
import clsx from 'clsx'
import React, { useState } from 'react'
import { darkTheme } from '../../styles/theme'
import { Photosphere } from './Photosphere'
import {
  PhotospherePageProvider,
  usePhotospherePageStore,
} from './PhotospherePageProvider'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: 0,
    },
    arrow: {
      color: 'white',
      cursor: 'pointer',
    },
    photospheres: {
      flexGrow: 1,
      height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'width 0.5s',
      position: 'relative',
    },
    photosphere: {
      position: 'relative',
      flexGrow: 1,
      height: '100%',
    },
    globalControl: {
      borderRadius: 0,
      // width: 35,
      // minWidth: 35,
      height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
)

declare global {
  interface Window {
    krpanoJS: any
  }
}

export const PhotospherePage = () => (
  <PhotospherePageProvider>
    <PhotosphereLayout />
  </PhotospherePageProvider>
)

export const PhotosphereLayout = (props: {}) => {
  const classes = useStyles(props)

  const photospherePageStore = usePhotospherePageStore()

  const syncViews = photospherePageStore((state) => state.syncViews)

  const [count, setCount] = useState(1)

  const desktop = useMediaQuery(useTheme().breakpoints.up('lg'))

  // only enable one at a time on mobile
  const limitedCount = desktop ? count : 1

  // const makeScreenshot = () => {
  //   if (!panoCanvas1.current) return
  //   const pano1Canvas = panoCanvas1.current
  //   const outputCanvas = document.createElement('canvas')
  //   const outputCtx = outputCanvas.getContext('2d')!
  //   outputCanvas.height = pano1Canvas.height
  //   let fileName = ''
  //   if (panoCanvas2.current) {
  //     const pano2Canvas = panoCanvas2.current
  //     outputCanvas.width = pano1Canvas.width + pano2Canvas.width
  //     outputCtx.drawImage(pano1Canvas, 0, 0)
  //     outputCtx.drawImage(pano2Canvas, pano1Canvas.width, 0)
  //     // fileName = `${project.name} - ${comparisonDateParam}`
  //   } else {
  //     outputCanvas.width = pano1Canvas.width
  //     outputCtx.drawImage(pano1Canvas, 0, 0)
  //     fileName = `${project.name}`
  //   }
  //   outputCanvas.toBlob((blob) => {
  //     if (!blob) throw new Error('Failed to convert to blob')
  //     fileDownload(blob, fileName)
  //   }, 'image/png')
  // }

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper className={classes.root}>
        <div className={clsx(classes.photospheres)}>
          {new Array(limitedCount).fill(0).map((c, i) => (
            <div className={classes.photosphere} key={i}>
              <Photosphere index={i} key={i} />
            </div>
          ))}
        </div>
        {desktop && (
          <div className={classes.globalControl}>
            <Tooltip
              title={'Add comparison view'}
              placement="left"
              enterDelay={500}
            >
              <AddIcon
                fontSize="large"
                className={classes.arrow}
                onClick={() => {
                  setCount(count + 1)
                }}
              />
            </Tooltip>
            {count > 1 && (
              <Tooltip
                title="Remove comparison view"
                placement="left"
                enterDelay={500}
              >
                <RemoveIcon
                  fontSize="large"
                  className={classes.arrow}
                  onClick={() => {
                    setCount(count - 1)
                  }}
                />
              </Tooltip>
            )}
            {count > 1 && (
              <Tooltip
                title={syncViews ? 'Unsync photospheres' : 'Sync photospheres'}
                placement="left"
                enterDelay={500}
              >
                {syncViews ? (
                  <LinkOffIcon
                    fontSize="large"
                    className={classes.arrow}
                    onClick={() => {
                      photospherePageStore.setState({ syncViews: false })
                    }}
                  />
                ) : (
                  <LinkIcon
                    fontSize="large"
                    className={classes.arrow}
                    onClick={() => {
                      photospherePageStore.setState({ syncViews: true })
                    }}
                  />
                )}
              </Tooltip>
            )}
          </div>
        )}
      </Paper>
    </ThemeProvider>
  )
}
