import React, { useState } from 'react'
import { useApi } from '../ApiContext'
import { useAsync } from 'react-use'
import { useSearchParams } from 'react-router-dom'
import { useProject } from '../ProjectWrapper'
import zustand, { UseStore } from 'zustand'
import { DashboardLayout } from './DashboardLayout'

export type DashboardState = {}

export type UseDashboardStore = UseStore<DashboardState>

export const DashboardController = (props: {}) => {
  const api = useApi()
  const project = useProject()
  const [searchParams] = useSearchParams()

  const [useDashboardStore] = useState(() =>
    zustand<DashboardState>((set) => ({}))
  )

  useAsync(async () => {}, [searchParams])

  return <DashboardLayout useDashboardStore={useDashboardStore} />
}
