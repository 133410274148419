import Konva from 'konva'
import React, { useState } from 'react'
import { Line, Rect } from 'react-konva'
import { useStageContext } from '../VideoCanvas'

export const NewPolygon = ({
  enabled,
  onCreatePolygon,
}: {
  enabled: boolean
  onCreatePolygon: (polygon: number[]) => void
}) => {
  const {
    absoluteTransform,
    imageNaturalWidth,
    imageNaturalHeight,
  } = useStageContext()

  const [newPolygon, setNewPolygon] = useState<number[]>()

  const onClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (enabled) {
      const point = absoluteTransform.copy().invert().point({
        x: e.evt.offsetX,
        y: e.evt.offsetY,
      })
      if (!newPolygon) {
        setNewPolygon([point.x, point.y, point.x, point.y])
      } else {
        newPolygon.push(point.x, point.y)
        setNewPolygon(newPolygon.slice())
      }
    }
  }

  const onMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (enabled) {
      if (newPolygon) {
        const point = absoluteTransform.copy().invert().point({
          x: e.evt.offsetX,
          y: e.evt.offsetY,
        })
        newPolygon[newPolygon.length - 2] = point.x
        newPolygon[newPolygon.length - 1] = point.y
        setNewPolygon(newPolygon.slice())
      }
    }
  }

  const onDblClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (enabled) {
      if (newPolygon) {
        onCreatePolygon(newPolygon.slice(0, -4))
        setNewPolygon(undefined)
      }
    }
  }

  return (
    <>
      <Rect
        x={0}
        y={0}
        width={imageNaturalWidth}
        height={imageNaturalHeight}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onDblClick={onDblClick}
      />
      {newPolygon && (
        <Line
          points={newPolygon}
          strokeWidth={10}
          stroke={'#00ffff'}
          listening={false}
        />
      )}
    </>
  )
}
