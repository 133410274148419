import {
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { uniqBy } from 'lodash'
import log from 'loglevel'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync, useLocalStorage, useMount, usePrevious } from 'react-use'
import shallow from 'zustand/shallow'
import { queryKeys } from '../../helpers/constants'
import { mixins } from '../../styles/mixins'
import { getDateRangeParams } from '../Annotator/utils'
import { useApi } from '../ApiContext'
import { DateRangeInput } from '../FormInputs/DateRangeInput'
import { useProject } from '../ProjectWrapper'
import { KRPanoCanvas } from './KRPanoCanvas'
import { KRPanoProvider, useKRPanoStore } from './KRPanoProvider'
import { PhotosphereAdvancedControls } from './PhotosphereAdvancedControls'
import { PhotosphereHotspots } from './PhotosphereHotspots'
import { PhotosphereKRPanoXML } from './PhotosphereKRPanoXML'
import { PhotosphereMap } from './PhotosphereMap'
import { usePhotospherePageStore } from './PhotospherePageProvider'
import { tourJs2Xml } from './tourJs2Xml'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      ...mixins.absoluteFill,
      overflow: 'hidden',
      display: 'flex',
    },
    panoContainer: {
      flexGrow: 1,
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
    minimalControls: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: '100%',
    },
    minimalDateRangeContainer: {
      position: 'absolute',
      left: 10,
      top: 10,
      display: 'flex',
    },
    menuIcon: {
      marginRight: 10,
      cursor: 'pointer',
      minWidth: 40,
    },
    closeIcon: {
      position: 'absolute',
      top: 10,
      left: 0,
      zIndex: 1,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    minimalDateInput: {
      width: 220,
      [theme.breakpoints.up('sm')]: {
        width: 440,
      },
    },
    minimalNameAndDates: {
      marginLeft: 10,
    },
    title: {
      textShadow: '0 0 2px #000000',
      whiteSpace: 'nowrap',
    },
    minimalMapContainer: {
      position: 'absolute',
      left: 0,
      width: 200,
      bottom: 0,
      height: 200,
    },
  })
)

export const Photosphere = ({ index }: { index: number }) => {
  const classes = useStyles()
  const project = useProject()
  const api = useApi()

  const photospherePageStore = usePhotospherePageStore()

  const startParamKey = `${queryKeys.IMAGE_STARTTIME}-${index}`
  const endParamKey = `${queryKeys.IMAGE_ENDTIME}-${index}`

  const [searchParams, setSearchParams] = useSearchParams()

  const startDateTime = searchParams.get(startParamKey)
  const endDateTime = searchParams.get(endParamKey)

  useMount(() => {
    const now = DateTime.fromJSDate(new Date(), {
      zone: project.timezone,
    })
    if (!startDateTime) {
      searchParams.set(startParamKey, now.minus({ weeks: 2 }).toISO())
    }
    if (!endDateTime) {
      searchParams.set(endParamKey, now.toISO())
    }
    setSearchParams(searchParams, { replace: true })
  })

  const [
    showAdvancedControls,
    setShowAdvancedControls,
  ] = useLocalStorage<boolean>('Photosphere-showAdvancedControls', false)

  const { value } = useAsync(async () => {
    const imageStartEnd = getDateRangeParams(
      searchParams,
      startParamKey,
      endParamKey,
      project?.timezone
    )

    const resp = await api.photospheresApi.projectsPhotospheresList({
      projectId: project.id.toString(),
      startTime: imageStartEnd[0],
      endTime: imageStartEnd[1],
      ordering: undefined,
      states: 'completed',
    })

    // uncomment to force showing all without coords
    resp.data.forEach((sphere, i) => {
      let missing = ''
      if (!sphere.longitude) {
        missing = missing + 'longitude, '
        // sphere.longitude = project.mapping_viewport.longitude || 0 + i * 0.0002
      }
      if (!sphere.latitude) {
        missing = missing + 'latitude, '
        // sphere.latitude = project.mapping_viewport.latitude || 0
      }
      if (!sphere.bearing) {
        missing = missing + 'bearing'
        // sphere.bearing = 0
      }
      if (missing.length > 0) {
        log.warn(`Photosphere ${sphere.title} is missing ${missing}`)
      }
    })

    const spheres = resp.data.reverse()

    const dedupedSpheres = uniqBy(spheres, (sphere) => sphere.template)

    return { spheres, dedupedSpheres }
  }, [startDateTime, endDateTime])

  const [sphereId, setSphereId] = useState<number>()

  const { spheres, dedupedSpheres } = value || {}

  const currentSphere = useMemo(() => spheres?.find((s) => s.id === sphereId), [
    spheres,
    sphereId,
  ])

  const { value: xmlUrl } = useAsync(async () => {
    if (!spheres?.length) return

    const xmlUrl = await tourJs2Xml(
      <PhotosphereKRPanoXML project={project} photospheres={spheres} />
    )

    setSphereId(spheres[0].id)

    log.debug('KRPano xmlUrl created')

    return xmlUrl
  }, [spheres])

  return (
    <KRPanoProvider
      xmlUrl={xmlUrl}
      handlers={{
        onViewChange: (viewState) => {
          if (
            photospherePageStore.getState().masterPhotosphereIndex === index
          ) {
            photospherePageStore.setState({
              masterView: { ...viewState },
            })
          }
        },
      }}
    >
      <InitKRPano
        sphereId={sphereId}
        templateId={currentSphere?.template}
        index={index}
      />
      <div className={clsx(classes.root)}>
        {showAdvancedControls && (
          <PhotosphereAdvancedControls
            startParamKey={startParamKey}
            endParamKey={endParamKey}
            spheres={spheres}
            sphereId={sphereId}
            currentSphere={currentSphere}
            setSphereId={setSphereId}
          />
        )}

        <div
          className={classes.panoContainer}
          onMouseDown={() => {
            photospherePageStore.setState({ masterPhotosphereIndex: index })
          }}
          onWheel={() => {
            photospherePageStore.setState({ masterPhotosphereIndex: index })
          }}
        >
          {showAdvancedControls && (
            <Tooltip
              title="Show advanced controls"
              placement="bottom"
              enterDelay={500}
            >
              <Fab
                color="default"
                size="small"
                onClick={() => setShowAdvancedControls(false)}
                className={classes.closeIcon}
              >
                <CloseIcon fontSize="medium" />
              </Fab>
            </Tooltip>
          )}

          <KRPanoCanvas />

          <PhotosphereHotspots
            spheres={spheres}
            currentSphere={currentSphere}
            onClickSphere={setSphereId}
          />

          {/* Minimal controls */}
          {!showAdvancedControls && (
            <div className={classes.minimalControls}>
              <div className={classes.minimalDateRangeContainer}>
                <Tooltip
                  title="Show advanced controls"
                  placement="bottom"
                  enterDelay={500}
                >
                  <Fab
                    color="default"
                    size="small"
                    className={classes.menuIcon}
                    onClick={() => setShowAdvancedControls(true)}
                  >
                    <MenuIcon fontSize="medium" />
                  </Fab>
                </Tooltip>

                <div className={classes.minimalDateInput}>
                  <DateRangeInput
                    label="Date range"
                    timezone={project.timezone}
                    startQueryKey={startParamKey}
                    endQueryKey={endParamKey}
                    minimal={true}
                  />
                </div>
                <Grid
                  container
                  spacing={0}
                  className={classes.minimalNameAndDates}
                >
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      {currentSphere?.title}
                    </Typography>
                  </Grid>
                </Grid>
              </div>

              {dedupedSpheres && dedupedSpheres.length > 1 && (
                <div className={classes.minimalMapContainer}>
                  <PhotosphereMap
                    spheres={dedupedSpheres}
                    currentSphere={currentSphere}
                    onClickSphere={setSphereId}
                  />
                </div>
              )}
            </div>
          )}
          {/* uncomment to see grid lines */}
          {/* <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          width: '100%',
          height: 2,
          backgroundColor: '#0f0',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '0',
          height: '100%',
          width: 2,
          backgroundColor: '#0f0',
        }}
      ></div> */}
        </div>
      </div>
    </KRPanoProvider>
  )
}

export const InitKRPano = ({
  sphereId,
  templateId,
  index,
}: {
  sphereId?: number
  templateId?: number
  index: number
}) => {
  const photospherePageStore = usePhotospherePageStore()
  const krpanoStore = useKRPanoStore()

  const panoElement = krpanoStore((state) => state.krPanoElement)

  const aimView = (force: boolean) => {
    const {
      masterView,
      syncViews,
      masterPhotosphereIndex,
    } = photospherePageStore.getState()
    if (
      // pano available to control
      panoElement &&
      // there are master view coords to sync to
      masterView &&
      // sync views toggle is one
      (force || syncViews) &&
      // this isnt the master photosphere (would create a loop)
      (force || index !== masterPhotosphereIndex)
    ) {
      panoElement.set('view.hlookat', masterView.hlookat)
      panoElement.set('view.vlookat', masterView.vlookat)
      panoElement.set('view.fov', masterView.fov)
    }
  }

  const previousTemplateId = usePrevious(templateId)

  useEffect(() => {
    if (panoElement && sphereId) {
      log.debug(`mapspot_loadscene(scene${sphereId});`)
      panoElement.call(`mapspot_loadscene(scene${sphereId});`)
      if (templateId === previousTemplateId) {
        aimView(true)
      }
    }
  }, [sphereId, panoElement])

  useEffect(
    () =>
      photospherePageStore.subscribe(
        () => aimView(false),
        (state) => [state.masterView, state.syncViews],
        shallow
      ),
    [photospherePageStore, aimView]
  )

  return null
}
