import { Box, Dialog, useTheme } from '@material-ui/core'
import { useWindowSize } from 'react-use'
import { useContextBridge } from '../../../hooks/useContextBridge'
import { usePanels } from '../../../hooks/usePanels'
import { useTouchDevice } from '../../../hooks/useTouchDevice'
import { ApiContext } from '../../ApiContext'
import { DragResizeBox } from '../../DragResizeBox'
import { AnalysesController } from '../AnalysesController'
import { Timeline } from '../Timeline/Timeline'
import { VODClock } from '../VODClock'
import { VODContext, VODController } from '../VODController'
import { VODControls } from '../VODControls'
import { VODPlayer } from '../VODPlayer'
import { CranePickDetailPanel } from './CranePickDetailPanel'
import { CraneContext, CranePicksController } from './CranePicksController'
import { CranePicksMap } from './CranePicksMap'
import { CranePicksPanel } from './CranePicksPanel'
import { CraneReportsPanel } from './CraneReportsPanel'
import { CraneSelect } from './CraneSelect'
import { CraneTimeline } from './CraneTimeline'
import { VODClipsController, useClipsStore } from '../VODClipsController'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMe } from '../../../hooks/useMe'
import { VODClips } from '../VODClips'

export const CraneReportsPage = () => {
  return (
    <AnalysesController>
      <VODController>
        <CranePicksController>
          <VODClipsController>
            <CraneLayout />
          </VODClipsController>
        </CranePicksController>
      </VODController>
    </AnalysesController>
  )
}

const CraneLayout = () => {
  const theme = useTheme()
  const { data: me } = useMe()
  const touchDevice = useTouchDevice()
  const [searchParams] = useSearchParams()
  const clipsStore = useClipsStore()

  const { panelState, setPanelState, PanelControl } = usePanels(
    'CraneReportPagePanels',
    {
      Metrics: true,
      // TODO: Toggleable video is buggy because when the video is turned back on it resets the time back to where it last was
      // Video: true,
      Map: false,
      Timeline: true,
      Schedule: false,
      Clips: false,
    }
  )

  React.useEffect(() => {
    if (panelState && !!searchParams.get('clip')) {
      setPanelState({
        ...panelState,
        Clips: true,
      })
    }
  }, [searchParams.get('clip')])

  const clipsPermission =
    me?.videoannotation_role === 'admin' ||
    me?.videoannotation_role === 'annotator'

  const clip = clipsStore((state) => state.clip)

  const ContextBridge = useContextBridge(ApiContext, VODContext, CraneContext)

  const { height } = useWindowSize()

  if (!panelState) return null

  return (
    <div
      style={{
        height: height - theme.custom.projectNavHeight,
        maxHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <CraneSelect>
        {clip && (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 18,
              width: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
              marginRight: 20,
            }}
          >
            {clip.title && <strong>{clip.title}</strong>}
            {clip.description && <span style={{ margin: '0 0.2em' }}> – </span>}
            {clip.description && <span>{clip.description}</span>}
          </span>
        )}
        <PanelControl />
      </CraneSelect>
      <div
        style={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'hidden',
          overflowX: 'auto',
        }}
      >
        {panelState.Metrics && (
          <DragResizeBox
            handleSide="right"
            defaultSize={500}
            minSize={500}
            localStorageKey="CraneReportsPage-Metrics"
          >
            <div
              style={{
                height: '100%',
                overflowY: 'scroll',
                position: 'relative',
              }}
            >
              <CraneReportsPanel />
            </div>
          </DragResizeBox>
        )}

        <div
          style={{
            flexGrow: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{ flexGrow: 1, display: 'flex', justifyContent: 'stretch' }}
          >
            <div
              style={{
                width: '50%',
                flexGrow: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box padding={1}>
                <VODClock />
              </Box>
              <CranePickDetailPanel />
              <VODPlayer />
              <VODControls />
            </div>

            {panelState.Map && (
              <div
                style={{
                  width: '50%',
                  flexGrow: 1,
                  position: 'relative',
                }}
              >
                <CranePicksMap />
              </div>
            )}
          </div>

          {panelState.Timeline && (
            <DragResizeBox
              handleSide="top"
              defaultSize={200}
              minSize={150}
              localStorageKey="CraneReportsPage-Timeline"
            >
              <Timeline
                initialLeftColumnWidth={250}
                initialZoom="day"
                showFollowPlayheadButton={false}
              >
                <ContextBridge>
                  <CraneTimeline rowGroupIndex={0} />
                </ContextBridge>
              </Timeline>
            </DragResizeBox>
          )}
        </div>

        {panelState.Schedule && (
          <DragResizeBox
            handleSide="left"
            defaultSize={300}
            minSize={270}
            localStorageKey="crane-picks-col"
          >
            <CranePicksPanel autoScroll={true} />
          </DragResizeBox>
        )}

        {!touchDevice && panelState.Clips && (
          <DragResizeBox
            handleSide="left"
            defaultSize={300}
            minSize={200}
            localStorageKey="vod-clips-col"
          >
            <VODClips />
          </DragResizeBox>
        )}
      </div>
    </div>
  )
}

// Not in use for now because multiple VODPlayers confuses the VODController
// The players steal the controller's videoRef from each other
const VideoDialog = () => {
  const theme = useTheme()
  const touchDevice = useTouchDevice()
  return (
    <Dialog open={false} fullWidth maxWidth="xl">
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CranePickDetailPanel />

        <div style={{ padding: theme.spacing(1) }}>
          <VODClock />
        </div>

        {/* Player */}
        <VODPlayer />

        {/* New Controls */}
        {!touchDevice && <VODControls />}
      </div>
    </Dialog>
  )
}
