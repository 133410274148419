import { useTheme } from '@material-ui/core'
import { useWindowSize } from 'react-use'
import { useMe } from '../../../hooks/useMe'
import { DeckVerificationController } from './DeckVerificationController'
import { DeckVerificationMap } from './DeckVerificationMap'
import { DeckVerificationControlPanel } from './DeckVerificationControlPanel'
import { DragResizeBox } from '../../DragResizeBox'

export const DeckVerificationPage = () => {
  return (
    <DeckVerificationController>
      <DeckVerificationLayout />
    </DeckVerificationController>
  )
}

const DeckVerificationLayout = () => {
  const theme = useTheme()
  const me = useMe()

  const { height } = useWindowSize()

  if (!me.data?.isAnnotator) {
    return null
  }

  return (
    <div
      style={{
        height: height - theme.custom.projectNavHeight,
        maxHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'hidden',
          overflowX: 'auto',
          position: 'relative',
        }}
      >
        <DeckVerificationMap />
      </div>
      <DragResizeBox
        handleSide="left"
        defaultSize={300}
        minSize={200}
        localStorageKey="deck-verification-control-panel-width"
      >
        <DeckVerificationControlPanel />
      </DragResizeBox>
    </div>
  )
}
