import {
  Box,
  createStyles,
  Dialog,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync, useAsyncFn } from 'react-use'
import {
  ImageCategoryDetails,
  ImageSubmissionIndex,
  UserSummary,
} from '../../api/codegen/typescript-axios'
import { ProjectSummaryExtended } from '../../api/ProjectSummaryExtended'
import {
  annotationStates,
  dateFormat,
  queryKeys,
} from '../../helpers/constants'
import { parseSearchList } from '../../helpers/parseSearchList'
import { mixins } from '../../styles/mixins'
import { AnnotatorCanvasPreview } from '../Annotator/AnnotationCanvasPreview'
import { getDateRangeParams } from '../Annotator/utils'
import { useApi } from '../ApiContext'
import { DateRangeInput } from '../FormInputs/DateRangeInput'
import { MultiSelect } from '../FormInputs/MultiSelect'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { Loading } from '../Loading'
import { RemotelyPaginatedTable } from '../RemotelyPaginatedTable'
import { orderOptions, OrderSelect } from './OrderSelect'
import { StreamsSelect } from './StreamsSelect'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 8,
      flexGrow: 1,
    },
    link: {
      textDecoration: 'none',
    },
    thumbnail: {
      display: 'block',
      width: 200,
    },
    categoryList: {
      margin: 0,
    },
    zoomImage: {
      display: 'block',
      width: '100%',
    },
    backdrop: {
      pointerEvents: 'none',
    },
    submitBar: {
      top: 'auto',
      bottom: 0,
    },
  })
)

const initialFieldsToShow = {
  [queryKeys.PROJECTS]: true,
  [queryKeys.STREAMS]: true,
  [queryKeys.CATEGORIES]: true,
  [queryKeys.IMAGE_STARTTIME]: true,
  [queryKeys.IMAGE_ENDTIME]: true,
  [queryKeys.STATES]: true,
  [queryKeys.ANNOTATION_STARTTIME]: false,
  [queryKeys.ANNOTATION_ENDTIME]: false,
  [queryKeys.USERS]: false,
  [queryKeys.TYPES]: false,
  [queryKeys.VERSION]: false,
}

const tableOptions = {
  rowStyle: (rowData: ImageSubmissionIndex) => ({
    verticalAlign: 'top',
  }),
}

export const ListHits = ({
  projects,
  categories,
  users,
}: {
  projects: ProjectSummaryExtended[]
  categories: ImageCategoryDetails[]
  users: {
    me: UserSummary
    taggers: UserSummary[]
    reviewers: UserSummary[]
  }
}) => {
  const api = useApi()
  const classes = useStyles()

  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.PER]: '10',
    [queryKeys.PAGE]: '1',
  })
  const perParam = parseInt(searchParams.get(queryKeys.PER)!)
  const pageParam = parseInt(searchParams.get(queryKeys.PAGE)!)

  const [fieldsToShow, setFieldsToShow] = useState(initialFieldsToShow)
  useEffect(() => {
    // This function will determine which fields to show based on selected state values
    // hidden fields will have their params deleted from the url search string
    // visible fields will be set to try in the fieldsToShow state variable, used during the jsx render
    // array of selected state values
    const states = parseSearchList(searchParams, queryKeys.STATES)
    // config specified here: https://app.clickup.com/t/8udw48
    const config: { [key: string]: Array<keyof typeof initialFieldsToShow> } =
      users.me.annotation_role === 'tagger'
        ? {
            unlabeled: [],
            ai_labeled: [
              queryKeys.ANNOTATION_STARTTIME,
              queryKeys.ANNOTATION_ENDTIME,
            ],
            labeling: [],
            labeled: [
              queryKeys.ANNOTATION_STARTTIME,
              queryKeys.ANNOTATION_ENDTIME,
            ],
          }
        : {
            unlabeled: [],
            ai_labeled: [
              queryKeys.ANNOTATION_STARTTIME,
              queryKeys.ANNOTATION_ENDTIME,
              queryKeys.VERSION,
            ],
            labeling: [queryKeys.TYPES, queryKeys.VERSION, queryKeys.USERS],
            labeled: [
              queryKeys.TYPES,
              queryKeys.VERSION,
              queryKeys.USERS,
              queryKeys.ANNOTATION_STARTTIME,
              queryKeys.ANNOTATION_ENDTIME,
            ],
          }
    const _fieldsToShow = { ...initialFieldsToShow }
    states.forEach((state) => {
      config[state].forEach((field) => (_fieldsToShow[field] = true))
    })
    ;(Object.keys(_fieldsToShow) as Array<keyof typeof _fieldsToShow>).forEach(
      (field: keyof typeof initialFieldsToShow) => {
        if (_fieldsToShow[field] === false) {
          searchParams.delete(field)
        }
      }
    )
    setSearchParams(searchParams)
    setFieldsToShow(_fieldsToShow)
  }, [searchParams, setSearchParams, users.me.annotation_role])

  // Fetch Hits
  const [fetchHitsState, fetchHits] = useAsyncFn(
    async ({ per = perParam, page = pageParam }) => {
      const states = parseSearchList(searchParams, queryKeys.STATES)

      const onlyOwnHits =
        users.me.annotation_role === 'tagger' &&
        (states.indexOf('labeling') > -1 || states.indexOf('labeled') > -1)

      // couldn't spread the date range arrays inline because of TS bug?
      const imageStartEnd = getDateRangeParams(
        searchParams,
        queryKeys.IMAGE_STARTTIME,
        queryKeys.IMAGE_ENDTIME
      )
      const annotationStartEnd = getDateRangeParams(
        searchParams,
        queryKeys.ANNOTATION_STARTTIME,
        queryKeys.ANNOTATION_ENDTIME
      )

      const resp = await api.imageAnnotationsApi.imageannotationSubmissionsList(
        {
          streamIds: searchParams.get(queryKeys.STREAMS) || undefined,
          categoryIds: searchParams.get(queryKeys.CATEGORIES) || undefined,
          imageStarttime: imageStartEnd[0],
          imageEndtime: imageStartEnd[1],
          latestAnnotationStarttime: annotationStartEnd[0],
          latestAnnotationEndtime: annotationStartEnd[1],
          latestAnnotationUserIds: onlyOwnHits
            ? users.me.id!.toString()
            : searchParams.get(queryKeys.USERS) || undefined,
          latestAnnotationTypes: searchParams.get(queryKeys.TYPES) || undefined,
          version: searchParams.get(queryKeys.VERSION) || undefined,
          ordering: searchParams.get(queryKeys.ORDER) || undefined,
          states: searchParams.get(queryKeys.STATES) || undefined,
          page,
          per,
        }
      )

      return resp.data
    },
    [searchParams]
  )

  // sets image of zoom modal
  const [zoomImage, setZoomImage] = useState<ImageSubmissionIndex | null>(null)

  // When false, modal has no pointer events and reacts to mouse hover on thumbnails
  // when false, modal behaves as normal
  const [modalLocked, setModalLocked] = useState(false)

  const {
    value: frameDetail,
    loading: frameDetailLoading,
  } = useAsync(async () => {
    if (zoomImage && modalLocked) {
      const resp = await api.imageAnnotationsApi.imageannotationFramesRead({
        id: zoomImage.frame.id.toString(),
      })
      return resp.data
    } else {
      return undefined
    }
  }, [zoomImage, modalLocked])

  // memoized table props
  const columns = useMemo(
    () => [
      {
        title: 'Thumbnail',
        render: (i: ImageSubmissionIndex) => (
          <img
            alt={i.frame.thumbnail_url}
            src={i.frame.thumbnail_url}
            className={classes.thumbnail}
            onClick={() => setModalLocked(true)}
            onMouseEnter={() => setZoomImage(i)}
            onMouseLeave={() => {
              if (!modalLocked) {
                setZoomImage(null)
              }
            }}
          />
        ),
        cellStyle: {
          padding: 0,
        },
      },
      {
        title: 'Project',
        field: 'project_name',
      },
      {
        title: 'Stream',
        field: 'stream_name',
      },
      {
        title: 'Image Date',
        field: 'frame.local_image_datetime',
        render: (i: ImageSubmissionIndex) =>
          format(
            new Date(i.frame.local_image_datetime || i.frame.image_datetime),
            `${dateFormat} HH:mm:ss`
          ),
      },
      {
        title: 'Category',
        field: 'category.name',
      },
      {
        title: 'Last Labeling',
        field: 'latest_annotation.annotation_datetime',
        render: (i: ImageSubmissionIndex) =>
          i.latest_annotation?.annotation_datetime
            ? format(
                new Date(i.latest_annotation.annotation_datetime),
                `${dateFormat} HH:mm:ss`
              )
            : null,
      },
      {
        title: 'Last Labeler',
        field: 'latest_annotation.session.user.first_name',
      },
      {
        title: 'State',
        field: 'state',
      },
    ],
    [classes, modalLocked]
  )

  return (
    <>
      <Paper>
        <Box p={2} m={2}>
          <Grid container spacing={2}>
            {/* Categories Checboxes */}
            <Grid item xs={12}>
              <MultiSelect
                groupLabel="Categories"
                options={categories.map((c) => ({
                  label: c.name,
                  value: c.id!.toString(),
                }))}
                queryKey={queryKeys.CATEGORIES}
                inputType="checkbox"
              />
            </Grid>

            <StreamsSelect projects={projects} />

            <Grid item xs={12}>
              <DateRangeInput
                label="Image capture time"
                startQueryKey={queryKeys.IMAGE_STARTTIME}
                endQueryKey={queryKeys.IMAGE_ENDTIME}
              />
            </Grid>

            {/* States Checboxes */}
            <Grid item xs={12}>
              <MultiSelect
                groupLabel={'States'}
                options={annotationStates.map((s) => ({
                  label: s,
                  value: s,
                }))}
                queryKey={queryKeys.STATES}
                inputType="checkbox"
              />
            </Grid>

            {/* Annotation Type */}
            {fieldsToShow[queryKeys.TYPES] && (
              <Grid item xs={12}>
                <MultiSelect
                  groupLabel={'Annotation Type'}
                  options={['tag', 'review'].map((s) => ({
                    label: s,
                    value: s,
                  }))}
                  queryKey={queryKeys.TYPES}
                  inputType="checkbox"
                />
              </Grid>
            )}

            {/* Taggers Autocomplete */}
            {fieldsToShow[queryKeys.USERS] && (
              <Grid item xs={12}>
                <MultiSelect
                  groupLabel="Taggers"
                  options={users.taggers.map((u) => ({
                    label:
                      u.first_name ||
                      u.email ||
                      u.username ||
                      u.last_name ||
                      u.id?.toString() ||
                      // unreachable because id will always be defined
                      'unknown user',
                    value: u.id!.toString(),
                  }))}
                  queryKey={queryKeys.USERS}
                  inputType="autocomplete"
                />
              </Grid>
            )}

            {/* Reviewers Autocomplete */}
            {fieldsToShow[queryKeys.USERS] && users.reviewers.length > 0 && (
              <Grid item xs={12}>
                <MultiSelect
                  groupLabel="Reviewers"
                  options={users.reviewers.map((u) => ({
                    label:
                      u.first_name ||
                      u.email ||
                      u.username ||
                      u.last_name ||
                      u.id?.toString() ||
                      // unreachable because id will always be defined
                      'unknown user',
                    value: u.id!.toString(),
                  }))}
                  queryKey={queryKeys.USERS}
                  inputType="autocomplete"
                />
              </Grid>
            )}

            {/* Image Annotation times */}
            {fieldsToShow[queryKeys.ANNOTATION_STARTTIME] &&
              fieldsToShow[queryKeys.ANNOTATION_ENDTIME] && (
                <Grid item xs={12}>
                  <DateRangeInput
                    label="Most recent annotation time"
                    startQueryKey={queryKeys.ANNOTATION_STARTTIME}
                    endQueryKey={queryKeys.ANNOTATION_ENDTIME}
                  />
                </Grid>
              )}

            {/* Version */}
            {fieldsToShow[queryKeys.VERSION] && (
              <Grid item xs={12}>
                <MultiSelect
                  groupLabel={'Version'}
                  options={['current', 'behind'].map((s) => ({
                    label: s,
                    value: s,
                  }))}
                  queryKey={queryKeys.VERSION}
                  inputType="checkbox"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <OrderSelect
                options={[
                  orderOptions.IMAGE_DATETIME,
                  orderOptions.PROJECT_NAME,
                  orderOptions.STREAM_NAME,
                  orderOptions.LATEST_ANNOTATION_DATETIME,
                ]}
                defaultOption={orderOptions.IMAGE_DATETIME}
                defaultDirection="-"
              />
            </Grid>

            <Grid item xs={12}>
              <ProgressButton
                variant="contained"
                color="primary"
                onClick={fetchHits}
                fetchState={fetchHitsState}
              >
                Fetch Hits
              </ProgressButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <RemotelyPaginatedTable<ImageSubmissionIndex>
        title="Hits"
        options={tableOptions}
        columns={columns}
        asyncFnReturn={[fetchHitsState, fetchHits]}
        actions={[
          {
            icon: 'info',
            tooltip: 'View Details',
            onClick: (event, rowData) => {
              window.open(
                `/annotations/frame/${
                  (rowData as ImageSubmissionIndex).frame.id
                }`
              )
            },
          },
        ]}
      />

      {/* Quick look thumbnail preview */}
      <Dialog
        open={!!zoomImage}
        onClose={() => {
          setZoomImage(null)
          setModalLocked(false)
        }}
        maxWidth="lg"
        disableScrollLock
        className={modalLocked ? '' : classes.backdrop}
      >
        {zoomImage && (
          <>
            <img
              src={zoomImage.frame?.thumbnail_url}
              className={classes.zoomImage}
              alt=""
            />
            {frameDetail && (
              <AnnotatorCanvasPreview
                imageUrl={frameDetail.frame?.image_url!}
                boxes={frameDetail.latest_boxes || []}
                showToolbar={false}
                style={{
                  ...(mixins.absoluteFill as React.CSSProperties),
                }}
              />
            )}
            {frameDetailLoading && <Loading />}
          </>
        )}
      </Dialog>
    </>
  )
}
