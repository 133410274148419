import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import { useSearchParams } from 'react-router-dom'
import { queryKeys } from '../../helpers/constants'

export enum orderOptions {
  ANNOTATION_DATE = 'annotation_date',
  SCORE = 'score',
  IMAGE_DATETIME = 'image_datetime',
  PROJECT_NAME = 'project_name',
  STREAM_NAME = 'stream_name',
  LATEST_ANNOTATION_DATETIME = 'latest_annotation_datetime',
}

export const OrderSelect = ({
  options,
  defaultOption,
  defaultDirection,
}: {
  options: Array<orderOptions>
  defaultOption: orderOptions
  defaultDirection: '-' | ''
}) => {
  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.ORDER]: defaultDirection + defaultOption,
  })
  const orderParam = searchParams.get(queryKeys.ORDER)

  return (
    <>
      <FormLabel component="legend">Sort Order</FormLabel>
      <RadioGroup
        aria-label="Sort order"
        name="Sort order"
        value={orderParam}
        row
        onChange={(e, v) => {
          searchParams.set(queryKeys.ORDER, v)
          setSearchParams(searchParams, { replace: true })
        }}
        style={{ alignItems: 'center' }}
      >
        {options.map((option) => (
          <Fragment key={option}>
            <FormControlLabel
              value={'-' + option}
              control={<Radio size="small" />}
              label={option + ' (desc)'}
            />
            <FormControlLabel
              value={option}
              control={<Radio size="small" />}
              label={option + ' (asc)'}
              color="primary"
            />
          </Fragment>
        ))}
      </RadioGroup>
    </>
  )
}
