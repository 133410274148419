import { useTheme } from '@material-ui/core'
import { FC } from 'react'
import { Group, Rect } from 'react-konva'
import { useUseTimelineStore } from './Timeline'

export const TimelineRow: FC<{
  y: number
  i: number
  rowHeight?: number
}> = ({ y, i, rowHeight, children }) => {
  const theme = useTheme()
  const useTimelineStore = useUseTimelineStore()
  const width = useTimelineStore((state) => state.width)
  let _rowHeight = useTimelineStore((state) => state.rowHeight)

  if (rowHeight) {
    _rowHeight = rowHeight
  }

  return (
    <Group x={0} y={y}>
      <Rect
        x={0}
        y={0}
        width={width}
        height={_rowHeight}
        fill={
          i % 2
            ? theme.palette.background.paper
            : theme.palette.background.default
        }
      />
      {children}
    </Group>
  )
}
