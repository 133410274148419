import { Column } from '@material-table/core'
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  Typography,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { format, getDay, subDays } from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import {
  ImageAnnotationReportSummary,
  ImageCategoryDetails,
  UserSummary,
} from '../../api/codegen/typescript-axios'
import { ProjectSummaryExtended } from '../../api/ProjectSummaryExtended'
import { dateFormat, queryKeys, tz } from '../../helpers/constants'
import { AnnotationReport } from '../Annotator/AnnotationReport'
import { getDateRangeParams } from '../Annotator/utils'
import { useApi } from '../ApiContext'
import { DateRangeInput } from '../FormInputs/DateRangeInput'
import { MultiSelect } from '../FormInputs/MultiSelect'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { RemotelyPaginatedTable } from '../RemotelyPaginatedTable'
import { orderOptions, OrderSelect } from './OrderSelect'
import { StreamsSelect } from './StreamsSelect'

export const AnnotationReports = ({
  projects,
  categories,
  users,
}: {
  projects: ProjectSummaryExtended[]
  categories: ImageCategoryDetails[]
  users: {
    me: UserSummary
    taggers: UserSummary[]
    reviewers: UserSummary[]
  }
}) => {
  const api = useApi()

  const today = new Date()
  const dayOfWeek = getDay(today)
  const [searchParams, setSearchParams] = useSearchParams({
    [queryKeys.MIN_SCORE]: '0',
    [queryKeys.MAX_SCORE]: '100',
    [queryKeys.ANNOTATION_STARTTIME]: format(
      subDays(today, dayOfWeek === 0 ? 6 : dayOfWeek - 1),
      dateFormat
    ),
    [queryKeys.ANNOTATION_ENDTIME]: format(endOfDay(today), dateFormat),
    [queryKeys.PER]: '10',
    [queryKeys.PAGE]: '1',
  })

  useEffect(() => {
    setSearchParams(searchParams)
  }, [])

  const [fetchReportsState, fetchReports] = useAsyncFn(
    async ({
      per = parseInt(searchParams.get(queryKeys.PER) || '10'),
      page = parseInt(searchParams.get(queryKeys.PAGE) || '1'),
    }) => {
      const annotationDateRange = getDateRangeParams(
        searchParams,
        queryKeys.ANNOTATION_STARTTIME,
        queryKeys.ANNOTATION_ENDTIME
      )
      const reviewDateRange = getDateRangeParams(
        searchParams,
        queryKeys.REVIEW_STARTTIME,
        queryKeys.REVIEW_ENDTIME
      )
      const resp = await api.imageAnnotationsApi.imageannotationAnnotationsList(
        {
          minScore: parseInt(searchParams.get(queryKeys.MIN_SCORE)!) / 100,
          maxScore: parseInt(searchParams.get(queryKeys.MAX_SCORE)!) / 100,
          userIds:
            users.me.annotation_role === 'tagger'
              ? users.me.id!.toString()
              : searchParams.get(queryKeys.USERS) || undefined,
          streamIds: undefined,
          categoryIds: searchParams.get(queryKeys.CATEGORIES) || undefined,
          annotationStarttime: annotationDateRange[0],
          annotationEndtime: annotationDateRange[1],
          payEligible: undefined,
          initialReviewStarttime: reviewDateRange[0],
          initialReviewEndtime: reviewDateRange[1],
          reviewed: searchParams.get(queryKeys.REVIEWED) || undefined,
          ordering: searchParams.get(queryKeys.ORDER) || undefined,
          page: page,
          per: per,
        }
      )

      return resp.data
    },
    [searchParams]
  )

  useEffect(() => {
    fetchReports({})
  }, [])

  const reports = fetchReportsState.value || []

  const reportId = searchParams.get('report')

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          {/* Taggers Autocomplete */}
          {users.me.annotation_role !== 'tagger' && (
            <>
              <Grid item xs={12}>
                <MultiSelect
                  groupLabel="Taggers"
                  options={users.taggers.map((u) => ({
                    label:
                      u.first_name ||
                      u.email ||
                      u.username ||
                      u.last_name ||
                      u.id?.toString() ||
                      // unreachable because id will always be defined
                      'unknown user',
                    value: u.id!.toString(),
                  }))}
                  queryKey={queryKeys.USERS}
                  inputType="autocomplete"
                />
              </Grid>

              <Grid item xs={12}>
                <MultiSelect
                  groupLabel="Reviewers"
                  options={users.reviewers.map((u) => ({
                    label:
                      u.first_name ||
                      u.email ||
                      u.username ||
                      u.last_name ||
                      u.id?.toString() ||
                      // unreachable because id will always be defined
                      'unknown user',
                    value: u.id!.toString(),
                  }))}
                  queryKey={queryKeys.USERS}
                  inputType="autocomplete"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <MultiSelect
              groupLabel="Categories"
              options={categories.map((c) => ({
                label: c.name,
                value: c.id!.toString(),
              }))}
              queryKey={queryKeys.CATEGORIES}
              inputType="checkbox"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography id="range-slider" gutterBottom>
              Score
            </Typography>
            <Slider
              min={0}
              max={100}
              defaultValue={[
                parseFloat(searchParams.get(queryKeys.MIN_SCORE) || '0'),
                parseFloat(searchParams.get(queryKeys.MAX_SCORE) || '100'),
              ]}
              onChangeCommitted={(e, v) => {
                const [min, max] = v as [number, number]
                searchParams.set(queryKeys.MIN_SCORE, min.toString())
                searchParams.set(queryKeys.MAX_SCORE, max.toString())
                setSearchParams(searchParams, { replace: true })
              }}
              valueLabelDisplay="auto"
            />
          </Grid>

          <StreamsSelect projects={projects} />

          <Grid item xs={12}>
            <DateRangeInput
              label="Annotation Date"
              timezone={tz}
              startQueryKey={queryKeys.ANNOTATION_STARTTIME}
              endQueryKey={queryKeys.ANNOTATION_ENDTIME}
            />
          </Grid>

          <Grid item xs={12}>
            <DateRangeInput
              label="Review Date"
              timezone={tz}
              startQueryKey={queryKeys.REVIEW_STARTTIME}
              endQueryKey={queryKeys.REVIEW_ENDTIME}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchParams.get(queryKeys.REVIEWED) === 'reviewed'}
                  onChange={(e, v) => {
                    searchParams.set(queryKeys.REVIEWED, v ? 'reviewed' : '')
                    setSearchParams(searchParams, { replace: true })
                  }}
                  name="checkedA"
                />
              }
              label="Reviewed"
            />
          </Grid>

          <Grid item xs={12}>
            <OrderSelect
              options={[orderOptions.ANNOTATION_DATE, orderOptions.SCORE]}
              defaultOption={orderOptions.ANNOTATION_DATE}
              defaultDirection="-"
            />
          </Grid>

          <Grid item xs={12}>
            <ProgressButton
              fetchState={fetchReportsState}
              onClick={fetchReports}
            >
              Fetch Reports
            </ProgressButton>
          </Grid>
          <Grid item xs={12}>
            {reports && (
              <RemotelyPaginatedTable
                title="Annotation Reports"
                options={tableOptions}
                columns={columns}
                actions={[
                  {
                    icon: VisibilityIcon,
                    tooltip: 'Inspect Annotation',
                    onClick: (event, rowData) => {
                      searchParams.set(
                        'report',
                        (rowData as ImageAnnotationReportSummary).id!.toString()
                      )
                      setSearchParams(searchParams, { replace: true })
                    },
                  },
                ]}
                asyncFnReturn={[fetchReportsState, fetchReports]}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={!!reportId}
        onClose={() => {
          searchParams.delete('report')
          setSearchParams(searchParams, { replace: true })
        }}
      >
        <DialogContent style={{ height: '95vh', padding: 0 }}>
          {reportId && <AnnotationReport reportId={reportId} />}
        </DialogContent>
      </Dialog>
    </Paper>
  )
}

const tableOptions = {
  rowStyle: () => ({
    verticalAlign: 'top',
  }),
}

const columns: Column<ImageAnnotationReportSummary>[] = [
  {
    title: 'Score',
    render: (report) =>
      report.reporting_score
        ? Math.round(report.reporting_score * 100) + '%'
        : '',
  },
  {
    title: 'Thumbnail',
    render: (i: ImageAnnotationReportSummary) => (
      <img
        alt={i.frame.thumbnail_url}
        src={i.frame.thumbnail_url}
        style={{
          display: 'block',
          width: 200,
        }}
      />
    ),
    cellStyle: {
      padding: 0,
    },
    sorting: false,
  },
  {
    title: 'Annotation Date',
    render: (report) =>
      report?.annotation_datetime
        ? format(new Date(report.annotation_datetime), `${dateFormat} HH:mm:ss`)
        : null,
  },
  {
    title: 'User',
    render: (report) =>
      report.session.user?.first_name || report.session.user?.email,
    sorting: false,
  },
  {
    title: 'Review Date',
    render: (report) =>
      report?.initial_review_at
        ? format(new Date(report.initial_review_at), `${dateFormat} HH:mm:ss`)
        : null,
    sorting: false,
  },
  {
    title: 'Reviewer',
    render: (report) =>
      report.review_session.user?.first_name ||
      report.review_session.user?.email,
    sorting: false,
  },
  {
    title: 'Category',
    render: (report) => report.category.name,
    sorting: false,
  },
]
