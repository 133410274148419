import { Select } from '@material-ui/core'
import { memo } from 'react'
import { useProject } from '../ProjectWrapper'
import { getUseVODStore } from './VODController'

export const VODSteamSelect = memo(() => {
  const useVODStore = getUseVODStore()
  const project = useProject()

  const videoStreamId = useVODStore((state) => state.videoStreamId)

  return (
    <Select
      native
      value={videoStreamId || ''}
      onChange={(event) => {
        console.log(event.target.value)
        useVODStore
          .getState()
          .gotoVideo({ streamId: parseInt(event.target.value as string) })
      }}
    >
      {project.streams.map((stream) => (
        <option key={stream.id} value={stream.id}>
          {stream.name}
        </option>
      ))}
    </Select>
  )
})
