export function validateXYWH({
  x,
  y,
  w,
  h,
}: {
  x?: number | null | undefined
  y?: number | null | undefined
  w?: number | null | undefined
  h?: number | null | undefined
}) {
  if (
    typeof x === 'number' &&
    typeof y === 'number' &&
    typeof w === 'number' &&
    typeof h === 'number' &&
    w > 0 &&
    h > 0
  ) {
    return { x, y, w, h }
  } else {
    return undefined
  }
}
