import { Card, createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { compact } from 'lodash'
import mapboxgl, { LngLatBounds, Map } from 'mapbox-gl'
import React, { useState } from 'react'
import ReactMapGL, {
  FullscreenControl,
  Layer,
  MapRef,
  NavigationControl,
  ScaleControl,
  Source,
} from 'react-map-gl'
import { useMeasure } from 'react-use'
import { useMergeRefs } from 'use-callback-ref'
import { mixins } from '../styles/mixins'
import { MapLayerControls } from './MapLayerControls'
import { useProject } from './ProjectWrapper'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        ...mixins.absoluteFill,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .mapboxgl-ctrl-logo': {
          display: 'none',
        },
      },
      scrollZoomPrompt: {
        transition: 'opacity 0.2s',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 8,
        pointerEvents: 'none',
      },
    }),
  {
    name: 'ProjectMap',
  }
)

export const ProjectMap: React.FC<{
  mapRef?: React.RefObject<MapRef>
  bounds?: LngLatBounds
  initialScrollZoom?: boolean
  mapStyle?:
    | 'mapbox://styles/mapbox/light-v9'
    | 'mapbox://styles/mapbox/satellite-v9'
    | string
  interactiveLayerIds?: string[]
  cursor?: string
  onClick?: (evt: mapboxgl.MapLayerMouseEvent) => void
  onMouseEnter?: (evt: mapboxgl.MapLayerMouseEvent) => void
  onMouseLeave?: (evt: mapboxgl.MapLayerMouseEvent) => void
  onMouseMove?: (evt: mapboxgl.MapLayerMouseEvent) => void
}> = ({
  mapRef: mapRefProp,
  bounds,
  initialScrollZoom = false,
  mapStyle = 'mapbox://styles/mapbox/light-v9',
  interactiveLayerIds,
  cursor,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  children,
}) => {
  const classes = useStyles()
  const project = useProject()

  const mapRef = React.useRef<MapRef>(null)
  const mergedRef = useMergeRefs(compact([mapRef, mapRefProp]))

  const [measureRef, measurements] = useMeasure<HTMLDivElement>()

  const [scrollZoom, setScrollZoom] = React.useState(initialScrollZoom)
  const [showScrollZoomPrompt, setshowScrollZoomPrompt] = React.useState(false)
  const [layerOpacities, setLayerOpacities] = useState<number[]>(
    project.mapping_overlays.map((overlay) => overlay.default_opacity || 0)
  )

  React.useEffect(() => {
    if (bounds && mapRef.current) {
      const mapApi = mapRef.current.getMap() as Map
      mapApi.fitBounds(bounds, { padding: 100, duration: 1000 })
    }
  }, [mapRef.current, bounds])

  React.useEffect(() => {
    mergedRef.current?.resize()
  }, [measurements])

  if (!project.mapping_viewport.latitude || !project.mapping_viewport.longitude)
    return null

  return (
    <div
      ref={measureRef}
      className={clsx(classes.root)}
      onWheel={() => {
        if (!scrollZoom) {
          setshowScrollZoomPrompt(true)
          setTimeout(() => {
            setshowScrollZoomPrompt(false)
          }, 2000)
        }
      }}
      onDoubleClick={() => {
        setScrollZoom(true)
      }}
    >
      <ReactMapGL
        ref={mergedRef}
        initialViewState={project.mapping_viewport}
        style={{
          width: '100%', // Math.round(measurements.width),
          height: '100%', // Math.round(measurements.height),
        }}
        minZoom={15}
        scrollZoom={scrollZoom}
        doubleClickZoom={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        attributionControl={false}
        cursor={cursor}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        mapStyle={mapStyle}
        interactiveLayerIds={interactiveLayerIds}
        maxZoom={26}
      >
        {project.mapping_overlays.map((overlay, i) => (
          <Source
            key={overlay.url}
            id={overlay.url + 'source'}
            url={overlay.url}
            type={overlay.overlay_type}
          >
            <Layer
              id={overlay.url + 'layer'}
              type="raster"
              paint={{
                'raster-opacity': layerOpacities[i],
              }}
            />
          </Source>
        ))}

        <React.Fragment key={'map children'}>{children}</React.Fragment>

        <NavigationControl />
        <ScaleControl />
        <FullscreenControl />
      </ReactMapGL>

      {project.mapping_overlays.length > 0 && (
        <MapLayerControls
          mapRef={mergedRef}
          overlayArray={project.mapping_overlays}
          layerOpacities={layerOpacities}
          setLayerOpacities={setLayerOpacities}
        />
      )}

      <Card
        className={classes.scrollZoomPrompt}
        style={{ opacity: showScrollZoomPrompt ? 1 : 0 }}
      >
        Double-click to enable mouse wheel zoom.
      </Card>
    </div>
  )
}
