import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { NavPages } from '../../NavPages'
import { AccountPage } from './AccountPage'
import { AnnotationsPageGuard } from './Annotations/Annotations'
import { FrameDetailPage } from './Annotations/FrameDetailPage'
import { AnnotatorFetcher } from './Annotator/AnnotatorFetcher'
import { FramesPage } from './VODPlayer/Frames/FramesPage'
import { DashboardController } from './Dashboard/DashboardController'
import { GrafanaDashboard } from './Dashboard/GrafanaDashboard'
import { FourOhFour } from './FourOhFourPage'
import { LocationListener } from './LocationListener'
import { LoginPage } from './LoginPage'
import { LogoutPage } from './LogoutPage'
import { ManageProjectPage } from './ManageProjectPage'
import { PhotospherePage } from './Photosphere/PhotospherePage'
import { PhotospherePublicPage } from './Photosphere/PhotospherePublicPage'
import { PricingPage } from './PricingPage'
import { ProjectDefaultPage } from './ProjectDefaultPage'
import { ProjectsListPage } from './ProjectsListPage'
import { ProjectWrapper } from './ProjectWrapper'
import { PublicProjectWrapper } from './PublicProjectWrapper'
import { ResetPasswordPage } from './ResetPasswordPage'
import { SecurityPage } from './Security/SecurityPage'
import { SupportPage } from './SupportPage'
import { TimelapsePage } from './Timelapses/TimelapsePage'
import { LiveVideosPage } from './Video/LiveVideosPage'
import { LiveVideosPagePublic } from './Video/LiveVideosPagePublic'
import { MobilePTZPage } from './Video/MobilePTZPage'
import { VideoRequestPage } from './VideoRequestPage'
import { DeckAIPage } from './VODPlayer/DeckAI/DeckAIPage'
import { CraneAnnotationPage } from './VODPlayer/CranePicks/CraneAnnotationPage'
import { CraneReportsPage } from './VODPlayer/CranePicks/CraneReportsPage'
import { TruckWeightsPage } from './VODPlayer/TruckWeights/TruckWeightsPage'
import { VODPage } from './VODPlayer/VODPage'
import { CraneReportDailyPrint } from './VODPlayer/CranePicks/CraneReportDailyPrint'
import { CraneReportWeeklyPrint } from './VODPlayer/CranePicks/CraneReportWeeklyPrint'
import { DeckVerificationPage } from './VODPlayer/DeckVerification/DeckVerificationPage'

function AppRouter() {
  return (
    <LocationListener>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="logout" element={<LogoutPage />} />
        <Route
          path="reset_password/:token/:uidb64"
          element={<ResetPasswordPage />}
        />
        <Route path={'public'}>
          <Route path={':projectSlug'} element={<PublicProjectWrapper />}>
            <Route path={'live'} element={<LiveVideosPagePublic />} />
          </Route>
        </Route>
        <Route
          path="annotations/frame/:frameId"
          element={<FrameDetailPage />}
        />
        <Route
          path="annotations/editor/:sessionId"
          element={<AnnotatorFetcher />}
        />
        <Route
          path="aerial-tour-share/:panoId"
          element={<PhotospherePublicPage />}
        />
        <Route path={'/*'} element={<NavPages />}>
          <Route path={''} element={<Navigate replace to={`/projects`} />} />
          <Route path="projects" element={<ProjectsListPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="annotations/:tab" element={<AnnotationsPageGuard />} />
          <Route
            path="annotations/"
            element={<Navigate to={`/annotations/list-jobs`} />}
          />
          <Route
            path="annotations/frame/:frameId"
            element={<FrameDetailPage />}
          />
          <Route path="pricing" element={<PricingPage />} />
          <Route path=":projectSlug" element={<ProjectWrapper />}>
            <Route path={'live'} element={<LiveVideosPage />} />
            <Route path={'ptz/:streamId'} element={<MobilePTZPage />} />
            <Route path={'dashboard'} element={<DashboardController />} />
            <Route path={'grafana'} element={<GrafanaDashboard />} />
            <Route path={'daily'} element={<TimelapsePage />} />
            <Route path={'archive'} element={<VODPage />} />
            <Route path={'hourly'} element={<VODPage />} />
            <Route path={'video'} element={<VODPage />} />
            <Route path={'crane-reports'} element={<CraneReportsPage />} />
            <Route
              path={'crane-report-daily-print'}
              element={<CraneReportDailyPrint />}
            />
            <Route
              path={'crane-report-weekly-print'}
              element={<CraneReportWeeklyPrint />}
            />
            <Route
              path={'crane-annotation'}
              element={<CraneAnnotationPage />}
            />
            <Route
              path={'DeckVerification'}
              element={<DeckVerificationPage />}
            />
            <Route path={'truck-weights'} element={<TruckWeightsPage />} />
            <Route path={'photos'} element={<FramesPage />} />
            <Route path={'aerialtour'} element={<PhotospherePage />} />
            <Route path={'manageusers'} element={<ManageProjectPage />} />
            <Route path={'security'} element={<SecurityPage />} />
            <Route path={'support'} element={<SupportPage />} />
            <Route path={'video-request'} element={<VideoRequestPage />} />
            <Route path={'deck-ai'} element={<DeckAIPage />} />
            <Route path={''} element={<ProjectDefaultPage />} />
            <Route path={'*'} element={<FourOhFour />} />
          </Route>
        </Route>
      </Routes>
    </LocationListener>
  )
}

export default AppRouter
