import React from 'react'
import { Image } from 'react-konva'
import { useAsync, useInterval } from 'react-use'
import { combineImageBitmap, useDeckAIStore } from './DeckAIController'

export const DeckAICurrentSegment = () => {
  const deckAIStore = useDeckAIStore()

  const currentGroup = deckAIStore((state) => state.currentGroup)

  const [opacity, setOpacity] = React.useState(0)

  useInterval(() => {
    setOpacity(opacity === 0.6 ? 0.2 : 0.6)
  }, 600)

  // load highlighted segment bitmap
  const { value: highlightBitmap } = useAsync(async () => {
    if (!currentGroup) {
      return undefined
    }
    const bitmap = await combineImageBitmap(
      currentGroup.segments.map((s) => s.smallestDeltaMask)
    )
    return bitmap
  }, [currentGroup])

  if (!highlightBitmap) return null

  return <Image listening={false} image={highlightBitmap} opacity={opacity} />
}
