import {
  Box,
  Divider,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import React from 'react'
import { useQuery } from 'react-query'
import {
  BrowserRouterProps,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useMeasure } from 'react-use'
import {
  extendProject,
  ProjectDetailsExtended,
} from '../api/ProjectDetailsExtended'
import { createCtx } from '../helpers/createCtx'
import { useApi } from './ApiContext'
import { useNavData } from './AppWrapper'
import { FourOhFour } from './FourOhFourPage'
import { KeyboardShortcutsMenu } from './KeyboardShortcutsController'
import { Loading } from './Loading'
import { allProjectNavItems } from './Nav'
import { VideoErrorMessages } from './Video/VideoErrorMessages'

const useStyles = makeStyles((theme) => ({
  input: {
    '&:before': {
      // content: 'unset',
      opacity: 0.2,
    },
    '&:after': {
      // content: 'unset',
    },
  },
  measure: {
    position: 'absolute',
    top: -10000,
    left: -10000,
    fontSize: 16,
    maxWidth: window.innerWidth - 16,
  },
  alertRoot: {
    marginTop: '0px',
    marginLeft: 6,
  },
}))

export const [useProject, ProjectContext] = createCtx<ProjectDetailsExtended>()

export const ProjectWrapper: React.FC<BrowserRouterProps> = (props) => {
  const classes = useStyles()
  const api = useApi()
  const location = useLocation()
  const navigate = useNavigate()
  const { projectSlug } = useParams()

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const desktop = useMediaQuery(useTheme().breakpoints.up('md'))

  const [showAlert, setShowAlert] = React.useState(false)

  const { data: projects } = useQuery('api.getProjects', api.getProjects)
  const liveProjects =
    projects?.filter((p) => p.live || p.slug === projectSlug) || []

  const { data: rawProject } = useQuery(
    ['projectsRead', projectSlug],
    async () => {
      if (!projectSlug) return

      const projectId = await api.projectIdFromKey(projectSlug)

      if (!projectId) return null

      const resp = await api.projectsApi.projectsRead({
        id: projectId.toString(),
      })

      return resp.data
    },
    {
      staleTime: 600000,
      refetchInterval: 10000,
    }
  )

  // need to do this outside of useQuery because it returns non-JSON
  const project = React.useMemo(
    () => (rawProject ? extendProject(rawProject) : undefined),
    [rawProject]
  )

  const [, setNavData] = useNavData()
  React.useEffect(() => {
    setNavData((navData) => ({ ...navData, project: project || undefined }))
    return () => setNavData((navData) => ({ ...navData, project: undefined }))
  }, [project, setNavData])

  // reshow mobile snackbar alert when error_message changes
  React.useEffect(() => {
    if (project?.error_message) {
      setShowAlert(true)
    }
  }, [project?.error_message])

  if (project === undefined) {
    return <Loading text="Loading project" />
  }

  if (project === null) {
    return <FourOhFour />
  }

  const path = location.pathname.split('/').slice(-1)[0]
  const apiPath = allProjectNavItems.find((item) => item.path === path)?.apiName
  const invalidPath = apiPath && project.pages.indexOf(apiPath) === -1

  if (invalidPath) {
    navigate(`/${project.slug}/`)
    return null
  }

  return (
    <ProjectContext.Provider value={project}>
      <Box
        padding={1}
        display="flex"
        alignItems="center"
        position="relative"
        displayPrint="none"
      >
        {liveProjects && (
          <Box onKeyDown={(e) => e.stopPropagation()}>
            <Autocomplete
              size="small"
              options={liveProjects}
              getOptionLabel={(option) => option.name}
              value={liveProjects.find((p) => p.id === project.id)}
              onChange={(e, v) => {
                navigate(`/${v.slug}/${path}`)
              }}
              // popupIcon={null}
              renderInput={(params) => {
                params.InputProps.className = clsx(
                  params.InputProps.className,
                  classes.input
                )
                return <TextField {...params} variant="standard" />
              }}
              blurOnSelect
              style={{ width: Math.min(width + 30, window.innerWidth - 16) }}
              disableClearable
            />
          </Box>
        )}
        <Box display="none">
          <Typography variant="h4">{project.name}</Typography>
        </Box>
        {project.error_message &&
          (desktop ? (
            <div>
              <VideoErrorMessages
                messages={[{ text: project.error_message, color: 'red' }]}
                classes={{
                  alertRoot: classes.alertRoot,
                }}
              />
            </div>
          ) : (
            <Snackbar open={showAlert} onClose={() => setShowAlert(false)}>
              <Alert
                variant="filled"
                onClose={() => setShowAlert(false)}
                severity="error"
              >
                {project.error_message}
              </Alert>
            </Snackbar>
          ))}

        <div style={{ flexGrow: 1 }}></div>

        <KeyboardShortcutsMenu />

        {/* this is just to measure the width to project names to set the width of the autocomplete above */}
        <div className={classes.measure} ref={ref}>
          {liveProjects.map((p) => (
            <div key={p.name}>{p.name}</div>
          ))}
        </div>
      </Box>
      <Divider />
      <Outlet key={project.id} />
    </ProjectContext.Provider>
  )
}
