import { TextField } from '@material-ui/core'
import { DatePicker as MUIPicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo } from 'react'

export const DatePicker = React.memo(
  ({
    value,
    onChange,
    dates,
    inputVariant = 'standard',
    autoSelect = false,
  }: {
    value: string | null | undefined
    onChange: (d: string) => void
    dates?: Array<string>
    inputVariant?: 'filled' | 'outlined' | 'standard'
    autoSelect?: boolean
  }) => {
    const { _dates, _minDate, _maxDate } = useMemo(() => {
      if (!dates || dates.length === 0)
        return {
          _maxDate: DateTime.now().toISODate(),
        }
      const _dates = dates.sort()
      const _minDate = _dates[0]
      const _maxDate = _dates[_dates.length - 1]
      return { _dates, _minDate, _maxDate }
    }, [dates])

    const _date = value || _maxDate

    useEffect(() => {
      if (autoSelect && !value && _maxDate) {
        onChange(_maxDate)
      }
    }, [autoSelect, value, _maxDate])

    if (!_date) return null

    return (
      <MUIPicker
        value={DateTime.fromISO(_date)}
        onChange={(date) => {
          if (date) {
            onChange(date.toISODate())
          }
        }}
        minDate={_minDate ? DateTime.fromISO(_minDate) : undefined}
        maxDate={DateTime.fromISO(_maxDate)}
        shouldDisableDate={(date) => {
          if (!_dates) return false
          if (!date) return true
          return _dates.indexOf((date as DateTime).toISODate()) === -1
        }}
        renderInput={(props) => {
          return <TextField {...props} variant={inputVariant} helperText="" />
        }}
      />
    )
  }
)
