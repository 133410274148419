import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  useTheme,
} from '@material-ui/core'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { AxisLeft } from '@visx/axis'
import { scaleTime } from '@visx/scale'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useMeasure } from 'react-use'
import { useMe } from '../../../hooks/useMe'
import { Loading } from '../../Loading'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { CranePickEvents } from './CranePickEvents'
import { CranePicksAsBuilt } from './CranePicksAsBuilt'
import { CranePicksAsPlanned } from './CranePicksAsPlanned'
import { useCraneStore } from './CranePicksController'

export const CranePicksScheduleView = ({
  autoScroll,
}: {
  autoScroll: boolean
}) => {
  const { data: me } = useMe()
  const theme = useTheme()
  const project = useProject()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()

  const annotationControlsPortalRef = React.useRef<HTMLDivElement>(null)

  const picks = craneStore((state) => state.picks)
  const pick = craneStore((state) => state.pick)
  const calendarEntries = craneStore((state) => state.calendarEntries)
  const events = craneStore((state) => state.events)
  const loading = craneStore((state) => state.loading)

  const [measureRef, { width }] = useMeasure<HTMLDivElement>()
  const scrollingRef = React.useRef<HTMLDivElement>(null)

  const [showAsPlanned, setShowAsPlanned] = useState(calendarEntries.length > 0)
  const [showAsBuilt, setShowAsBuilt] = useState(true)
  const [showEvents, setShowEvents] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(5000)

  const start = DateTime.min(
    ...[
      events[0]?.eventDateTime,
      picks[0]?.startDateTime,
      calendarEntries[0]?.startDateTime,
      useVODStore.getState().videoDate.plus({ hours: 7 }),
    ].filter((d) => d instanceof DateTime)
  )
    .minus({ hours: 0.5 })
    .setZone('local', { keepLocalTime: true })

  const end = useVODStore.getState().videoDate.endOf('day')
    .setZone('local', { keepLocalTime: true })

  const workdayLength = end.toMillis() - start.toMillis()

  const rangeMax = workdayLength / zoomLevel

  const scale = React.useMemo(
    () =>
      scaleTime({
        range: [0, rangeMax],
        round: false,
        domain: [start.toJSDate(), end.toJSDate()],
        nice: false,
        clamp: false,
      }),
    [rangeMax, start.toISO(), end.toISO()]
  )

  // spring for animating playhead
  const [{ playheadY }, spring] = useSpring(() => ({
    playheadY: 0,
    immediate: true,
  }))

  // move playhead
  const setPlayhead = React.useCallback(
    (dateTime?: DateTime | null | undefined) => {
      if (dateTime) {
        const localTime = dateTime
          .setZone('local', {
            keepLocalTime: true,
          })
          .valueOf()
        spring.set({ playheadY: scale(localTime) })
      } else {
        spring.set({ playheadY: 0 })
      }
    },
    [scale, spring]
  )

  // scroll to playhead
  const scrollToPlayhead = React.useCallback(
    (dateTime?: DateTime | null | undefined) => {
      const _dateTime = dateTime || useVODStore.getState().videoDateTime

      const targetScroll = scale(
        _dateTime
          .setZone('local', {
            keepLocalTime: true,
          })
          .toJSDate()
      )

      if (scrollingRef.current) {
        const currentScroll = scrollingRef.current.scrollTop
        if (
          currentScroll > targetScroll ||
          currentScroll + scrollingRef.current.clientHeight < targetScroll
        ) {
          scrollingRef.current.scrollTop = targetScroll - 40
        }
      }
    },
    [scale, useVODStore, scrollingRef.current]
  )

  React.useEffect(() => {
    // maintain scroll position while zooming
    if (!scrollingRef.current) return
    scrollingRef.current.style.scrollBehavior = 'auto'
    scrollToPlayhead()
    scrollingRef.current.style.scrollBehavior = 'smooth'
  }, [scale, scrollToPlayhead])

  // animate playhead while video plays
  React.useEffect(
    () =>
      useVODStore.subscribe<DateTime | undefined>(
        (dateTime: DateTime | null | undefined) => {
          setPlayhead(dateTime)
        },
        (state) => state.videoDateTime
      ),
    [useVODStore, scale, spring]
  )

  // move and scroll to playhead when selected pick changes
  React.useEffect(() => {
    if (autoScroll) {
      scrollToPlayhead(pick?.startDateTime)
    }
  }, [pick, scrollToPlayhead])

  return (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAsPlanned}
              onMouseDown={(e) => e.preventDefault()}
              onChange={(e) => setShowAsPlanned(e.target.checked)}
              color="primary"
            />
          }
          label="As planned"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showAsBuilt}
              onMouseDown={(e) => e.preventDefault()}
              onChange={(e) => setShowAsBuilt(e.target.checked)}
              color="primary"
            />
          }
          label="As built"
        />
        {me?.isAnnotator && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showEvents}
                onMouseDown={(e) => e.preventDefault()}
                onChange={(e) => setShowEvents(e.target.checked)}
                color="primary"
              />
            }
            label="Events"
          />
        )}
      </Grid>
      <Divider />
      <Grid item>
        <IconButton
          title="Zoome out timeline"
          size="small"
          onClick={() => setZoomLevel(zoomLevel * 2)}
          onMouseDown={(e) => e.preventDefault()}
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Zoome in timeline"
          size="small"
          onClick={() => setZoomLevel(zoomLevel / 2)}
          onMouseDown={(e) => e.preventDefault()}
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Scroll to current video time."
          size="small"
          onClick={() => scrollToPlayhead()}
          onMouseDown={(e) => e.preventDefault()}
        >
          <SkipNextIcon />
        </IconButton>
      </Grid>

      <Divider />

      {/* This will container the annotation controls */}
      {/* Needed to use a portal because the CSS prevents embedding it in events component elements */}
      <div
        ref={annotationControlsPortalRef}
        // style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
      ></div>

      <Divider />

      <Grid
        item
        style={{
          flexGrow: 1,
          position: 'relative',
          scrollBehavior: 'smooth',
          height: 1,
          overflow: 'auto',
          marginTop: 0,
        }}
        ref={scrollingRef}
      >
        {loading ? (
          <Loading text={'Loading data'} />
        ) : (
          <div
            ref={measureRef}
            style={{
              width: '100%',
              height: rangeMax,
              position: 'relative',
            }}
          >
            <svg
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              onClick={(event) =>
                useVODStore.getState().gotoVideo({
                  goToNearestTime: false,
                  dateTime: DateTime.fromJSDate(
                    scale.invert(event.nativeEvent.offsetY)
                  ).setZone(project.timezone, { keepLocalTime: true }),
                })
              }
            >
              <AxisLeft
                scale={scale}
                left={width}
                stroke={'transparent'}
                numTicks={(workdayLength / 1000 / 60 / 8 / zoomLevel) * 5000}
                tickStroke={theme.palette.divider}
                tickLength={width - 45}
                tickLabelProps={() => ({
                  fill: theme.palette.text.primary,
                  textAnchor: 'end',
                  alignmentBaseline: 'middle',
                  fontSize: 12,
                  transform: 'translate(-8,5)',
                })}
              />
              <animated.line
                x1="0"
                y1={playheadY}
                x2={width}
                y2={playheadY}
                stroke={theme.palette.text.primary}
              />
            </svg>
            <div
              style={{
                position: 'absolute',
                left: 50,
                right: 0,
                height: '100%',
              }}
            >
              {showAsPlanned && (
                <CranePicksAsPlanned scale={scale} showAsBuilt={showAsBuilt} />
              )}

              {showAsBuilt && (
                <CranePicksAsBuilt
                  scale={scale}
                  showAsPlanned={showAsPlanned}
                  showEvents={showEvents}
                />
              )}

              {showEvents && (
                <CranePickEvents
                  scale={scale}
                  portalRef={annotationControlsPortalRef}
                />
              )}
            </div>
          </div>
        )}
      </Grid>
    </>
  )
}
