import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import NearMeIcon from '@material-ui/icons/NearMe'
import PanToolIcon from '@material-ui/icons/PanTool'
import React from 'react'
import { useShoringStore } from './VODShoringController'

export const ShoringTools = () => {
  const shoringStore = useShoringStore()

  const overlayMouseMode = shoringStore((state) => state.overlayMouseMode)
  const linkMode = shoringStore((state) => state.linkMode)
  const createWithTimes = shoringStore((state) => state.createWithTimes)
  const setSeriesLocationWhen = shoringStore(
    (state) => state.setSeriesLocationWhen
  )

  return (
    <>
      {/* Overlay Mouse Mode */}
      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: 4 }}>
            Overlay Mouse Mode
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ButtonGroup fullWidth>
                <Button
                  variant="contained"
                  fullWidth
                  color={overlayMouseMode === 'pan' ? 'primary' : 'default'}
                  onClick={() =>
                    shoringStore.setState({ overlayMouseMode: 'pan' })
                  }
                  title="pan view"
                >
                  <PanToolIcon />
                </Button>

                <Button
                  variant="contained"
                  fullWidth
                  color={overlayMouseMode === 'select' ? 'primary' : 'default'}
                  onClick={() =>
                    shoringStore.setState({ overlayMouseMode: 'select' })
                  }
                  title="select and move"
                >
                  <NearMeIcon style={{ transform: 'scaleX(-100%)' }} />
                </Button>

                <Button
                  variant="contained"
                  fullWidth
                  color={overlayMouseMode === 'create' ? 'primary' : 'default'}
                  onClick={() =>
                    shoringStore.setState({ overlayMouseMode: 'create' })
                  }
                  title="create new"
                >
                  <AddCircleIcon />
                </Button>
              </ButtonGroup>
            </Grid>

            {/* <Grid item xs={6}>
              <ButtonGroup fullWidth>
                <Button
                  variant="contained"
                  fullWidth
                  color={linkMode === 'none' ? 'primary' : 'default'}
                  onClick={() =>
                    shoringStore.setState({
                      linkMode: 'none',
                    })
                  }
                  title="Off"
                >
                  <LinkOffIcon />
                </Button>

                <Button
                  variant="contained"
                  fullWidth
                  color={
                    linkMode === 'link predecessors' ? 'primary' : 'default'
                  }
                  onClick={() =>
                    shoringStore.setState({
                      linkMode: 'link predecessors',
                    })
                  }
                  title="Link Predecessors"
                >
                  <LinkIcon />
                  Pre
                </Button>

                <Button
                  variant="contained"
                  fullWidth
                  color={linkMode === 'link successors' ? 'primary' : 'default'}
                  onClick={() =>
                    shoringStore.setState({
                      linkMode: 'link successors',
                    })
                  }
                  title="Link Successors"
                >
                  <LinkIcon />
                  Post
                </Button>
              </ButtonGroup>
            </Grid> */}
          </Grid>
        </FormControl>
      </Grid>

      {/* Create Without location */}
      {/* <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          color={'default'}
          onClick={() => {
            shoringStore.getState().addBay({})
          }}
          title="create new without overlay coordinates"
        >
          Create new unlocated
        </Button>
      </Grid> */}

      {/* Time settings */}
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Create mode</InputLabel>
          <Select
            label="Create mode"
            native
            value={createWithTimes}
            onChange={(event) =>
              shoringStore.setState({
                createWithTimes: (event.target as HTMLInputElement)
                  .value as typeof createWithTimes,
              })
            }
          >
            {/* <option value="none">Create with no times</option> */}
            <option value="start">Create with start time</option>
            <option value="end">Create with end time</option>
            <option value="series">Create in series</option>
          </Select>
        </FormControl>
      </Grid>
      {createWithTimes === 'series' && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Set series location when</InputLabel>
            <Select
              label="Set series location when"
              native
              value={setSeriesLocationWhen}
              onChange={(event) =>
                shoringStore.setState({
                  setSeriesLocationWhen: (event.target as HTMLInputElement)
                    .value as typeof setSeriesLocationWhen,
                })
              }
            >
              <option value="start">Set location at start time</option>
              <option value="end">Set location at end time</option>
            </Select>
          </FormControl>
        </Grid>
      )}
    </>
  )
}
