import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { useAsync } from 'react-use'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  })
)

export const SecurityArmLog = (props: {}) => {
  const classes = useStyles(props)
  const api = useApi()
  const project = useProject()
  const theme = useTheme()

  const {
    value: armlogList,
    loading: armlogListLoading,
    error: armlogListError,
  } = useAsync(async () => {
    const resp = await api.securityApi.projectsSecurityArmlogList({
      projectId: project.id.toString(),
    })
    return resp.data
  }, [project.camevent_all_enabled])

  return (
    <Table>
      <TableBody>
        {armlogList?.map((armLog) => (
          <TableRow key={armLog.id}>
            <TableCell>
              {DateTime.fromISO(armLog.performed_at, {
                zone: project.timezone,
              }).toLocaleString(DateTime.DATETIME_SHORT)}
            </TableCell>
            <TableCell>
              {armLog.armed ? (
                <span
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 'bold',
                  }}
                >
                  ARMED
                </span>
              ) : (
                <span
                  style={{
                    color: theme.palette.success.main,
                    fontWeight: 'bold',
                  }}
                >
                  DISARMED
                </span>
              )}
            </TableCell>
            <TableCell>
              {armLog.performed_by ? (
                <span>
                  {armLog.performed_by.first_name}{' '}
                  {armLog.performed_by.last_name}
                </span>
              ) : (
                <span>-- Scheduled --</span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
