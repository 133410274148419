import * as Sentry from '@sentry/react'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { DateTime, Duration } from 'luxon'
import qs from 'qs'
import { QueryClient } from 'react-query'
import { checkTimeZone } from '../helpers/checkTimeZone'
import { assertId } from './assertRequired'
import {
  AIApi,
  ClipsApi,
  Configuration,
  CraneApi,
  FramesApi,
  ImageAnnotationsApi,
  PasswordReset,
  PasswordResetRequest,
  PhotospheresApi,
  ProjectMapsApi,
  ProjectsApi,
  ProjectUsersApi,
  PTZApi,
  PtzMovement,
  PublicProjectApi,
  SecurityApi,
  SegmentationReportingApi,
  ShoringApi,
  TimelapsesApi,
  UploadsApi,
  UsersApi,
  UserSummary,
  VehiclesApi,
  VideoAnnotationsApi,
  VideoProcessingApi,
  VideosApi,
} from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'
import {
  extendProjectSummary,
  ProjectSummaryExtended,
} from './ProjectSummaryExtended'
import { StreamExtended } from './StreamExtended'
import {
  extendVideoSummary,
  VideoSummaryExtended,
  videoTypeSpeeds,
} from './VideoSummaryExtended'

const storageAccessTokenKey = 'access_token'
const storageRefreshTokenKey = 'refresh_token'

function isProtectedPage() {
  return (
    window.location.pathname !== `${process.env.REACT_APP_BASE_HREF}login` &&
    window.location.pathname.indexOf(
      `${process.env.REACT_APP_BASE_HREF}public`
    ) === -1 &&
    window.location.pathname.indexOf('reset_password') === -1 &&
    window.location.pathname.indexOf('aerial-tour-share') === -1
  )
}

export type TypeVideosMap = Map<
  keyof typeof videoTypeSpeeds,
  VideoSummaryExtended[]
>

export type StreamVideosMap = Map<
  number, // stream id
  TypeVideosMap
>

export class Api {
  static stabilityEventType = 'topdeck.stable'

  client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  openapiConfig: Configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL!.slice(0, -1),
  })

  queryClient: QueryClient

  // This is a hack to roughly estimate when all the initial data fetches have loaded,
  // To be used when printing a new window/iframe via javascript, or server side rendering
  // The timeout is cleared whenever a new fetch request is initiated,
  // The timeout is reinitialized whenever a new fetch response is received
  // (See request and response interceptors below in constructor)
  // 1000ms is an approximated padding to allow the UI to render after receiving fetch response.
  stabilityTimeout: number | null = null

  usersApi: UsersApi
  projectsApi: ProjectsApi
  publicProjectApi: PublicProjectApi
  projectUsersApi: ProjectUsersApi
  ptzApi: PTZApi
  videosApi: VideosApi
  imageAnnotationsApi: ImageAnnotationsApi
  videoAnnotationsApi: VideoAnnotationsApi
  clipsApi: ClipsApi
  shoringApi: ShoringApi
  aiApi: AIApi
  photospheresApi: PhotospheresApi
  projectMapsApi: ProjectMapsApi
  framesApi: FramesApi
  vehiclesApi: VehiclesApi
  securityApi: SecurityApi
  uploadsApi: UploadsApi
  videoProcessingApi: VideoProcessingApi
  craneApi: CraneApi
  timelapsesApi: TimelapsesApi
  segmentationReportingApi: SegmentationReportingApi

  constructor(navigate: any, queryClient: QueryClient) {
    this.client.interceptors.request.use((request) => {
      if (this.stabilityTimeout) {
        clearTimeout(this.stabilityTimeout)
        this.stabilityTimeout = null
      }

      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem(
          storageAccessTokenKey
        )}`
      }
      return request
    })

    createAuthRefreshInterceptor(this.client, async (failedRequest) => {
      try {
        if (!localStorage.getItem(storageRefreshTokenKey)) {
          throw new Error('No refresh token stored')
        }
        const response = await this.client.post(
          process.env.REACT_APP_API_URL + 'oauth/token/',
          qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem(storageRefreshTokenKey),
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
          })
        )

        localStorage.setItem(storageAccessTokenKey, response.data.access_token)
        localStorage.setItem(
          storageRefreshTokenKey,
          response.data.refresh_token
        )
        failedRequest.response.config.headers['Authorization'] =
          'Bearer ' + response.data.access_token
        return Promise.resolve()
      } catch (error) {
        if (isProtectedPage()) {
          this.logout()
          const base = process.env.REACT_APP_BASE_HREF || ''
          console.log(
            (window.location.pathname + window.location.search).slice(
              base.length
            )
          )
          navigate(`login`, {
            replace: true,
            state: {
              destination:
                '/' +
                (window.location.pathname + window.location.search).slice(
                  base.length
                ),
            },
          })
        }
      }
    })

    this.client.interceptors.response.use(
      (response) => {
        if (!response.hasOwnProperty('data')) {
          throw new Error('no data in response!')
        } else {
          if (this.stabilityTimeout) {
            clearTimeout(this.stabilityTimeout)
            this.stabilityTimeout = null
          }

          this.stabilityTimeout = window.setTimeout(() => {
            window.dispatchEvent(new Event(Api.stabilityEventType))
          }, 1000)

          return response
        }
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Response Error', error.response)
          if (error.response.data) {
            throw new Error(JSON.stringify(error.response.data))
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('No Response Error', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Unkown Error', error)
        }
        throw error
      }
    )

    this.usersApi = new UsersApi(this.openapiConfig, undefined, this.client)
    this.projectsApi = new ProjectsApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.publicProjectApi = new PublicProjectApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.projectUsersApi = new ProjectUsersApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.ptzApi = new PTZApi(this.openapiConfig, undefined, this.client)
    this.videosApi = new VideosApi(this.openapiConfig, undefined, this.client)
    this.imageAnnotationsApi = new ImageAnnotationsApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.videoAnnotationsApi = new VideoAnnotationsApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.clipsApi = new ClipsApi(this.openapiConfig, undefined, this.client)
    this.shoringApi = new ShoringApi(this.openapiConfig, undefined, this.client)
    this.aiApi = new AIApi(this.openapiConfig, undefined, this.client)
    this.photospheresApi = new PhotospheresApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.projectMapsApi = new ProjectMapsApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.framesApi = new FramesApi(this.openapiConfig, undefined, this.client)
    this.vehiclesApi = new VehiclesApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.securityApi = new SecurityApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.uploadsApi = new UploadsApi(this.openapiConfig, undefined, this.client)
    this.videoProcessingApi = new VideoProcessingApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.craneApi = new CraneApi(this.openapiConfig, undefined, this.client)
    this.timelapsesApi = new TimelapsesApi(
      this.openapiConfig,
      undefined,
      this.client
    )
    this.segmentationReportingApi = new SegmentationReportingApi(
      this.openapiConfig,
      undefined,
      this.client
    )

    this.queryClient = queryClient
    queryClient.setQueryDefaults('api.getMe', {
      queryFn: this.getMe,
      staleTime: 600000,
      retry: 3,
    })
    queryClient.setQueryDefaults('api.getProjects', {
      queryFn: this.getProjects,
      staleTime: 600000,
    })

    if (localStorage.getItem(storageAccessTokenKey)) {
      this.queryClient.prefetchQuery('api.getMe')
    }

    if (isProtectedPage()) {
      this.queryClient.prefetchQuery('api.getProjects')
    }
  }

  login = async (username: string, password: string) => {
    try {
      const response = await axios.post<{
        access_token: string
        refresh_token: string
        token_type: string
        scope: string
        expires_in: number
      }>(
        process.env.REACT_APP_API_URL + 'oauth/token/',
        qs.stringify({
          grant_type: 'password',
          username: username,
          password: password,
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      try {
        localStorage.setItem(storageAccessTokenKey, response.data.access_token)
        localStorage.setItem(
          storageRefreshTokenKey,
          response.data.refresh_token
        )
        return this.getMe()
      } catch (err) {
        Sentry.captureException(new Error(JSON.stringify(err)))
        console.log('token conversion error', err)
      }
    } catch (err) {
      console.log('login request error', err)
      throw err
    }
  }

  logout = () => {
    this.queryClient.clear()
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
  }

  resetPassword = async (email: string) => {
    try {
      const payload: PasswordResetRequest = {
        email: email,
      }
      const resp = await this.client.post(`/passwords/reset/`, payload)
      return resp
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  setPassword = async (
    uidb64: string,
    token: string,
    password1: string,
    password2: string
  ) => {
    try {
      const payload: PasswordReset = {
        password1,
        password2,
      }
      const resp = await this.client.post(
        `/passwords/reset/${uidb64}/${token}/`,
        payload
      )
      return resp
    } catch (error) {
      console.log(error)
      if (axios.isAxiosError(error)) {
        if (error.response?.data.password1) {
          throw new Error(
            `There was a problem with this password: ${error.response.data.password1
              .map((e: string) => `${e}`)
              .join(' ')}`
          )
        }
      }
      throw error
    }
  }

  getMe: () => Promise<{ isAnnotator: boolean } & UserSummary> = async () => {
    const { data: me } = await this.usersApi.usersMeRead()

    if (me.external_id) {
      window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS as string, {
        send_page_view: false,
        user_id: me.external_id,
        dimension1: me.external_id,
      })
    }

    Sentry.setUser({
      external_id: me.external_id?.toString(),
    })

    return {
      ...me,
      isAnnotator:
        me.videoannotation_role === 'annotator' ||
        me.videoannotation_role === 'admin' ||
        me.videoannotation_role === 'viewer',
    }
  }

  getProjects = async (): Promise<ProjectSummaryExtended[]> => {
    const resp = await this.projectsApi.projectsList()
    return resp.data.map((project) => {
      return extendProjectSummary(project)
    })
  }

  projectIdFromKey = async (key: string) => {
    let projects = this.queryClient.getQueryData<ProjectSummaryExtended[]>(
      'api.getProjects'
    )
    if (!projects) {
      projects = await this.queryClient.fetchQuery(
        'api.getProjects',
        this.getProjects
      )
    }
    if (!projects) return null
    const project = projects.find((project) => {
      return project.slug === key
    })
    if (project) {
      return project.id
    } else {
      return null
    }
  }

  getProjectVideos = async (
    project: ProjectDetailsExtended,
    params?: {
      service?: string
      video_type?: string
      start_time?: DateTime
      end_time?: DateTime
      state?: string
    }
  ): Promise<StreamVideosMap> => {
    checkTimeZone(project, params?.start_time)
    checkTimeZone(project, params?.end_time)

    // all videos for all project streams in time range.
    // These are grouped by stream but the stream groups arent reliably sorted
    const { data: streamVideosArray } = await this.videosApi.projectsVideosList(
      {
        // streamId: string,
        projectId: project.id.toString(),
        // service?: string | undefined,
        service: params?.service,
        // videoType?: string | undefined,
        videoType: params?.video_type,
        // // startTime?: string | undefined,
        startTime: params?.start_time?.toISO(),
        // // endTime?: string | undefined,
        endTime: params?.end_time?.toISO(),
        // // state?: string | undefined,
        state: params?.state,
        // options?: any
      }
    )

    // create the structure
    const types: Array<keyof typeof videoTypeSpeeds> = [
      '1x',
      '4x-stab',
      '20x-stab',
      '20x',
      '200x-stab',
      '200x',
      'daily',
    ]

    const streamVideosMap: StreamVideosMap = new Map()

    project.streams.forEach((stream) => {
      const typeMap = new Map<
        keyof typeof videoTypeSpeeds,
        VideoSummaryExtended[]
      >()

      types.forEach((type) => typeMap.set(type, []))

      streamVideosMap.set(stream.id, typeMap)
    })

    // extend videos and sort
    streamVideosArray.forEach((streamVideos) => {
      const stream = project.streams.find(
        (s) => s.id === streamVideos.stream_id
      )
      if (!stream) {
        // shouldnt happen
        throw 'No stream found'
      }

      const streamTypeMap = streamVideosMap.get(stream.id)
      if (!streamTypeMap) {
        // shouldnt happen
        throw 'No stream typemap found'
      }

      const extendedVideos = (streamVideos.videos || []).map((video) =>
        extendVideoSummary(video, stream, project)
      )

      extendedVideos.sort(
        (video1, video2) =>
          video1.localStartDateTime.toMillis() -
          video2.localStartDateTime.toMillis()
      )

      extendedVideos.forEach((video) => {
        streamTypeMap.get(video.video_type)?.push(video)
      })
    })

    return streamVideosMap
  }

  getvideosByDate = async (
    project: ProjectDetailsExtended,
    dateTime: DateTime
  ): Promise<StreamVideosMap> => {
    checkTimeZone(project, dateTime)

    const isoDate = dateTime.toISODate()

    const staleTime =
      isoDate === DateTime.now().setZone(project.timezone).toISODate()
        ? 1000 * 60 * 5
        : 1000 * 60 * 60 * 24

    return this.queryClient.fetchQuery(
      [project.name, isoDate],
      async () => {
        return await this.getProjectVideos(project, {
          start_time: dateTime.startOf('day'),
          end_time: dateTime.startOf('day').plus({ days: 1 }),
        })
      },
      {
        staleTime,
        cacheTime: 1000 * 60 * 60 * 24,
      }
    )
  }

  getVideo = async ({
    project,
    streamId,
    dateTime,
    speed,
    goToNearestTime,
    preferStabilized,
  }: {
    project: ProjectDetailsExtended
    streamId: number
    dateTime: DateTime
    speed: number
    goToNearestTime: boolean
    preferStabilized: boolean
  }) => {
    checkTimeZone(project, dateTime)

    let typePriority: Array<keyof typeof videoTypeSpeeds>
    if (preferStabilized) {
      if (speed < 4) {
        typePriority = ['1x', '4x-stab', '20x-stab', '20x', '200x-stab', '200x']
      } else if (speed < 11) {
        typePriority = ['4x-stab', '20x-stab', '1x', '20x', '200x-stab', '200x']
      } else if (speed < 110) {
        typePriority = ['20x-stab', '20x', '200x-stab', '200x']
      } else {
        typePriority = ['200x-stab', '200x']
      }
    } else {
      if (speed < 11) {
        typePriority = ['1x', '20x', '200x']
      } else if (speed < 110) {
        typePriority = ['20x', '200x']
      } else {
        typePriority = ['200x']
      }
    }

    // All videos from all streams on chosen date
    const streamVideosMap = await this.getvideosByDate(project, dateTime)

    // All videos from chosen stream on chosen date
    const streamVideos = streamVideosMap.get(streamId)

    if (!streamVideos) {
      // shouldnt happen
      throw 'No stream videos found'
    }

    let bestVideo: VideoSummaryExtended | undefined

    typePriority.forEach((type) => {
      if (bestVideo) return
      const typeVideos = streamVideos.get(type)
      if (!typeVideos) {
        // shouldnt happen
        throw 'No type videos found'
      }
      bestVideo = typeVideos.find(
        (vid) =>
          vid.localStartDateTime < dateTime && vid.localEndDateTime > dateTime
      )
    })

    if (!bestVideo && goToNearestTime) {
      typePriority.forEach((type) => {
        if (bestVideo) return
        const typeVideos = streamVideos.get(type)
        if (!typeVideos) {
          // shouldnt happen
          throw 'No type videos found'
        }
        bestVideo = typeVideos.find((vid) => vid.localEndDateTime > dateTime)
      })
    }

    return bestVideo
  }

  ptzApiStreamsMovementCreate = ({
    stream,
    data,
  }: {
    stream: StreamExtended
    data: PtzMovement
  }) => {
    assertId(stream)

    stream.ptzResetDateTime = DateTime.now()
      .setZone(stream.timezone)
      .plus(Duration.fromObject({ seconds: 720 }))

    this.ptzApi.streamsPtzMovementCreate({
      streamId: stream.id.toString(),
      data,
    })
  }
}
