import { createStyles, makeStyles } from '@material-ui/core'
import { mat2d, vec2 } from 'gl-matrix'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { useMergeRefs } from 'use-callback-ref'
import shallow from 'zustand/shallow'
import { useSizeCanvas } from '../../../hooks/useSizeCanvas'
import { mixins } from '../../../styles/mixins'
import { UseTimelineStore } from './Timeline'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        ...mixins.absoluteFill,
        pointerEvents: 'none',
      },
    }),
  {
    name: 'TimelinePlayheads',
  }
)

const rowHeight = 15

export const TimelinePlayheads = React.forwardRef<
  HTMLCanvasElement,
  {
    useTimelineStore: UseTimelineStore
    transformRef: React.MutableRefObject<mat2d>
    mousePositionRef: React.MutableRefObject<vec2>
    setTransform: (t: mat2d) => void
    width: number
    height: number
  }
>(
  (
    {
      useTimelineStore,
      transformRef,
      mousePositionRef,
      setTransform,
      width,
      height,
    },
    ref
  ) => {
    const classes = useStyles()

    const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)

    const sizeRef = useSizeCanvas(width, height)

    const mergedRef = useMergeRefs<HTMLCanvasElement>([ref, sizeRef])

    function drawTimelinePlayheads() {
      const canvas = mergedRef.current
      const ctx = canvas?.getContext('2d')!

      if (!canvas || !ctx) {
        return
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      //Draw playhead
      const localTime = useTimelineStore.getState().videoDateTime.toJSDate()

      const tx = useTimelineStore.getState().currentScale(localTime)

      if (tx >= leftColumnWidth) {
        ctx.strokeStyle = '#ffffff'
        ctx.beginPath()
        ctx.moveTo(tx, 0)
        ctx.lineTo(tx, canvas.height)
        ctx.stroke()
      }

      if (
        useTimelineStore.getState().followPlayhead &&
        (tx < leftColumnWidth || tx > canvas.offsetWidth)
      ) {
        setTransform(
          mat2d.translate(transformRef.current, transformRef.current, [
            (tx - canvas.offsetWidth / 2) / -transformRef.current[0],
            0,
          ])
        )
      }

      //Draw mouse position
      ctx.font = `${rowHeight * 1.6}px Courier, Courier New`
      ctx.textBaseline = 'top'
      const mouseX = mousePositionRef.current[0]
      if (mouseX > leftColumnWidth) {
        ctx.strokeStyle = '#00ffff'
        ctx.beginPath()
        ctx.moveTo(mouseX, 0)
        ctx.lineTo(mouseX, canvas.height)
        ctx.stroke()
      }
    }

    useEffect(
      () =>
        useTimelineStore.subscribe<[DateTime, DateTime | undefined]>(
          drawTimelinePlayheads,
          (state) => [state.videoDateTime, state.mouseDateTime],
          shallow
        ),
      []
    )

    return <canvas ref={mergedRef} className={classes.root} />
  }
)
