import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { ProjectUser } from '../../api/codegen/typescript-axios'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { useProject } from '../ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  })
)

export const SecurityContactsList = (props: {}) => {
  const classes = useStyles(props)
  const api = useApi()
  const project = useProject()
  const theme = useTheme()
  const { confirm } = useAlertConfirmPrompt()

  const [newContact, setNewContact] = React.useState<ProjectUser>()

  const [{ value: projectUsers }, fetchProjectUsers] = useAsyncFn(async () => {
    const resp = await api.projectUsersApi.projectsUsersList({
      projectId: project.id.toString(),
      contactEligible: 'true',
    })
    return resp.data
  }, [api, project])

  const [{ value: contacts }, fetchContacts] = useAsyncFn(async () => {
    const resp = await api.securityApi.projectsSecurityContactsList({
      projectId: project.id.toString(),
    })
    return resp.data
  }, [api, project])

  React.useEffect(() => {
    fetchProjectUsers()
    fetchContacts()
  }, [])

  const [addState, add] = useAsyncFn(async () => {
    if (!newContact) return
    const resp = await api.securityApi.projectsSecurityContactsCreate({
      projectId: project.id.toString(),
      data: {
        user_id: newContact?.user!.id!,
        sequence: contacts ? contacts.length + 1 : 1,
      },
    })
    fetchContacts()
    return resp.data
  }, [api, project, contacts, fetchContacts, newContact])

  return (
    <div style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Typography variant="h6" gutterBottom>
            Security alarm contacts
          </Typography>
          <Typography variant="body1" gutterBottom>
            These are the people who are called when the security alarm is
            triggered.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <ProgressButton onClick={add} fetchState={addState}>
            Add User
          </ProgressButton>
        </Grid>
        <Grid item xs={12}>
          <Table style={{ width: 'auto' }}>
            <TableBody>
              {contacts?.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell>
                    {contact.user?.first_name} {contact.user?.last_name}
                  </TableCell>
                  <TableCell>{contact.user?.phone_number}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={async () => {
                        try {
                          await confirm({
                            title: 'Remove user from contact list?',
                          })
                          await api.securityApi.projectsSecurityContactsDelete({
                            projectId: project.id.toString(),
                            id: contact.id!.toString(),
                          })
                          fetchContacts()
                        } catch {
                          // do nothing
                        }
                      }}
                    >
                      <DeleteIcon></DeleteIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key={'new'}>
                <TableCell colSpan={2}>
                  <Autocomplete<ProjectUser>
                    options={projectUsers || []}
                    value={newContact}
                    onChange={(e, value) => {
                      if (value) {
                        setNewContact(value)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select User"
                        variant="outlined"
                      />
                    )}
                    onOpen={fetchProjectUsers}
                    getOptionLabel={(option) => option.user!.email!}
                  />
                  <Typography
                    style={{ maxWidth: 400, marginTop: 10 }}
                    variant="body2"
                  >
                    Security contacts must have a first name, last name, and
                    phone number. If a user does not appear in this
                    autocomplete, have them log in to their accounts and fill
                    out their info on the{' '}
                    <Link to={`/account`}>account management page</Link>.
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton onClick={add} disabled={!newContact}>
                    <AddIcon></AddIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}
