import { Box, Button, Divider, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz/esm'
import React, { useState } from 'react'
import { Layer } from 'react-konva'
import { Link, useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { ImageAnnotationBox } from '../../api/codegen/typescript-axios'
import { dateTimeFormat, tz } from '../../helpers/constants'
import { useApi } from '../ApiContext'
import { MultiSelect } from '../FormInputs/MultiSelect'
import { Loading } from '../Loading'
import { AnnotatorContext, LabelState } from './Annotator'
import { AnnotatorCanvasBoxes } from './AnnotatorCanvasBoxes'
import { ChangedBoxes } from './ChangedBoxes'
import { LabelStateControl } from './LabelStateControl'
import { VideoCanvas } from './VideoCanvas'

export const AnnotationReport = ({ reportId }: { reportId: string }) => {
  const api = useApi()
  const [searchParams, setSearchParams] = useSearchParams()
  const show = searchParams.get('show')
  const [selectedBox, setSelectedBox] = useState<ImageAnnotationBox>()
  const [hiddenBoxes, setHiddenBoxes] = useState<ImageAnnotationBox[]>([])
  const [labelState, setLabelState] = useState<LabelState>('all')

  const { value: report, loading } = useAsync(async () => {
    if (reportId) {
      const resp = await api.imageAnnotationsApi.imageannotationAnnotationsRead(
        { id: reportId }
      )
      return resp.data
    } else {
      return undefined
    }
  }, [reportId])

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        p={1}
        bgcolor="white"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {report ? (
          <Box flexGrow="1" display="flex">
            <Box m={1}>
              Score:{' '}
              <strong>{Math.round(report.reporting_score! * 100) + '%'}</strong>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box m={1}>
              Tagged at:{' '}
              <strong>
                {format(
                  utcToZonedTime(report.session.end_datetime!, tz),
                  dateTimeFormat
                )}{' '}
              </strong>
              by{' '}
              <strong>
                {report.session.user?.first_name || report.session.user?.email}
              </strong>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box m={1}>
              Reviewed at:{' '}
              <strong>
                {format(
                  utcToZonedTime(report.review_session.end_datetime!, tz),
                  dateTimeFormat
                )}{' '}
              </strong>
              by{' '}
              <strong>
                {report.review_session.user?.first_name ||
                  report.review_session.user?.email}
              </strong>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box m={1}>
              Category: <strong>{report.category.name}</strong>
            </Box>
          </Box>
        ) : (
          <Box m={1}> </Box>
        )}
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            searchParams.delete('report')
            setSearchParams(searchParams, { replace: true })
          }}
        />
      </Box>
      <Box position="relative" flexGrow="1">
        {loading ? (
          <Loading />
        ) : report ? (
          <VideoCanvas
            imageUrl={report.frame.image_url}
            showVideo={false}
            listening={true}
            setSelectedBox={setSelectedBox}
            mode={'select'}
            scaleExtent={[0.94, 10]}
          >
            <AnnotatorContext.Provider
              value={{
                selectedBox,
                setSelectedBox,
                hiddenBoxes,
                setHiddenBoxes,
              }}
            >
              <Layer>
                {show && show.indexOf('added') > -1 ? (
                  <AnnotatorCanvasBoxes
                    boxes={report.report.boxes_added}
                    selectedBox={selectedBox}
                    setSelectedBox={setSelectedBox}
                    labelState={labelState}
                    boxColor="#00f"
                  />
                ) : null}
                {show && show.indexOf('deleted') > -1 ? (
                  <AnnotatorCanvasBoxes
                    boxes={report.report.boxes_deleted}
                    selectedBox={selectedBox}
                    setSelectedBox={setSelectedBox}
                    labelState={labelState}
                    boxColor="#f00"
                  />
                ) : null}
                {show && show.indexOf('changed') > -1 ? (
                  <ChangedBoxes changes={report.report.changes} />
                ) : null}
              </Layer>
            </AnnotatorContext.Provider>
          </VideoCanvas>
        ) : null}
      </Box>
      <div style={{ margin: '0 10px' }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <LabelStateControl
              labelState={labelState}
              setLabelState={setLabelState}
            />
          </Grid>
          <Grid item>
            <MultiSelect
              groupLabel={'Show:'}
              options={[
                { label: 'Boxes added', value: 'added' },
                { label: 'Boxes deleted', value: 'deleted' },
                { label: 'Boxes changed', value: 'changed' },
              ]}
              queryKey={'show'}
              inputType="checkbox"
              defaultSelectAll={true}
            />
          </Grid>
          <Grid item>
            {report && (
              <Link
                to={`/annotations/frame/${report.frame!.id}`}
                target="_blank"
              >
                <Button color="primary">Go to frame</Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}
