import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { UseDashboardStore } from './DashboardController'
import { useProject } from '../ProjectWrapper'
import { useMeasure } from 'react-use'

const useStyles = makeStyles({
  root: {
    border: '1px solid #000',
  },
  iframe: {
    display: 'block',
  },
})

export const WeatherWindy = ({
  width,
  height,
  forecastOnly = false,
  singleDay = false,
  zoom = 7,
}: {
  zoom?: number
  width?: number
  height?: number
  forecastOnly?: boolean
  singleDay?: boolean
}) => {
  const classes = useStyles()
  const project = useProject()
  const [
    ref,
    { width: parentWidth, height: parentHeight },
  ] = useMeasure<HTMLDivElement>()

  const w = width ? width : singleDay ? 340 : parentWidth
  const h = height ? height : forecastOnly ? 189 : parentHeight

  return (
    <div className={classes.root} ref={ref}>
      <iframe
        className={classes.iframe}
        width={w}
        height={h}
        src={`https://embed.windy.com/embed2.html?lat=${project.latitude}&lon=${project.longitude}&detailLat=${project.latitude}&detailLon=${project.longitude}&width=${w}&height=${h}&zoom=${zoom}&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1`}
        frameBorder="0"
      ></iframe>
    </div>
  )
}
