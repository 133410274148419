import React, { FC, useMemo } from 'react'
import { useQuery } from 'react-query'
import { BrowserRouterProps, Outlet, useParams } from 'react-router-dom'
import {
  extendPublicProject,
  PublicProjectExtended,
} from '../api/ProjectDetailsExtended'
import { createCtx } from '../helpers/createCtx'
import { useApi } from './ApiContext'
import { FourOhFour } from './FourOhFourPage'
import { Loading } from './Loading'

export const [
  usePublicProject,
  PublicProjectContext,
] = createCtx<PublicProjectExtended>()

export const PublicProjectWrapper: FC<BrowserRouterProps> = (props) => {
  const api = useApi()
  const { projectSlug } = useParams()

  const { data: rawProject } = useQuery(
    ['publicProjectsRead', projectSlug],
    async () => {
      if (!projectSlug) return undefined
      const resp = await api.publicProjectApi.publicProjectsRead({
        slug: projectSlug,
      })
      return resp.data
    },
    {
      refetchInterval: 1000 * 60,
    }
  )

  // need to do this outside of useQuery because it returns non-JSON
  const project = useMemo(
    () => (rawProject ? extendPublicProject(rawProject) : undefined),
    [rawProject]
  )

  if (project === undefined) {
    return <Loading text="Loading project" />
  }

  if (project === null) {
    return <FourOhFour />
  }

  return (
    <PublicProjectContext.Provider value={project}>
      <Outlet />
    </PublicProjectContext.Provider>
  )
}
