import * as Sentry from '@sentry/react'
import log from 'loglevel'
import React, { FC, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

export const ErrorLogger: FC<{}> = ({ children }) => {
  const logDebug = useCallback(() => {
    log.setLevel(log.levels.DEBUG)
    log.debug('Debug logging activated')
  }, [])

  useHotkeys('alt+command+i', logDebug)
  useHotkeys('shift+control+i', logDebug)

  return (
    <Sentry.ErrorBoundary
      fallback={
        <span>
          There has been an error. Try reloading the page and contact support if
          the error persists.
        </span>
      }
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
