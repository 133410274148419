import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useGetSet } from 'react-use'
import { createCtx } from '../helpers/createCtx'

interface Content {
  title?: React.ReactNode
  description?: React.ReactNode
  yesText?: string
  noText?: string
}

export const [useAlertConfirmPrompt, AlertConfirmPromptCtx] = createCtx<{
  alert: (content: Content) => Promise<unknown>
  confirm: (content: Content) => Promise<unknown>
  prompt: (content: Content) => Promise<unknown>
}>()

export const AlertConfirmPromptProvider: FC<{}> = ({ children }) => {
  const resolveRejectRef = useRef({
    resolve: (value: unknown) => value,
    reject: (reason?: any) => {},
  })

  const [open, setOpen] = useState(false)
  const [type, setType] = useState<'alert' | 'confirm' | 'prompt' | undefined>(
    undefined
  )

  const [content, setContent] = useState<Content>({})

  const [getTextInputValue, setTextInputValue] = useGetSet<string>('')

  const onConfirm = useCallback(() => {
    setOpen(false)
    const value = getTextInputValue()
    resolveRejectRef.current.resolve(value || undefined)
  }, [content])

  const onDeny = useCallback(() => {
    setOpen(false)
    resolveRejectRef.current.reject()
  }, [content])

  const ctxValue = useMemo(
    () => ({
      alert: async (content: Content) => {
        setType('alert')
        setContent({
          ...content,
        })
        setTextInputValue('')
        setOpen(true)
        const _promise = new Promise((resolve, reject) => {
          resolveRejectRef.current = {
            resolve,
            reject,
          }
        })
        return _promise
      },
      confirm: async (content: Content) => {
        setType('confirm')
        setContent({
          ...content,
        })
        setTextInputValue('')
        setOpen(true)
        const _promise = new Promise((resolve, reject) => {
          resolveRejectRef.current = {
            resolve,
            reject,
          }
        })
        return _promise
      },
      prompt: async (content: Content) => {
        setType('prompt')
        setContent({
          ...content,
        })
        setTextInputValue('')
        setOpen(true)
        const _promise = new Promise((resolve, reject) => {
          resolveRejectRef.current = {
            resolve,
            reject,
          }
        })
        return _promise
      },
    }),
    [resolveRejectRef, setContent, setOpen, setTextInputValue]
  )

  return (
    <>
      <AlertConfirmPromptCtx.Provider value={ctxValue}>
        {children}
      </AlertConfirmPromptCtx.Provider>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onKeyDown={(event) => event.stopPropagation()}
      >
        {content.title && (
          <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
        )}
        {content.description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {content.description}
            </DialogContentText>
            {type === 'prompt' && (
              <TextField
                label="title"
                variant="outlined"
                fullWidth
                value={getTextInputValue()}
                onChange={(event) => setTextInputValue(event.target.value)}
                style={{ width: '100%' }}
              />
            )}
          </DialogContent>
        )}
        <DialogActions>
          {(type === 'confirm' || type === 'prompt') && (
            <Button onClick={onDeny} variant="contained">
              {content.noText || 'Cancel'}
            </Button>
          )}
          <Button onClick={onConfirm} color="primary" variant="contained">
            {content.yesText || 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
