import React from 'react'
import { useMobile } from '../../hooks/useMobile'
import { LiveVideosPageDesktopPublic } from './LiveVideosPageDesktopPublic'
import { LiveVideosPageMobilePublic } from './LiveVideosPageMobilePublic'

export const LiveVideosPagePublic = () => {
  if (useMobile()) {
    return <LiveVideosPageMobilePublic />
  } else {
    return <LiveVideosPageDesktopPublic />
  }
}
