import { Duration } from 'luxon'
import * as XLSX from 'xlsx'
import { CranePick } from '../../../api/codegen/typescript-axios'
import { CranePicksCollection } from '../../../api/CranePicksCollection'

type Columns = Array<{
  header: string
  sourceKey?: keyof CranePick
  getFormula?: (row: number) => string
  cellObject: XLSX.CellObject
}>

export const dateCellObject: XLSX.CellObject = { t: 'd', z: 'yyyy-mm-dd' }
export const timeCellObject: XLSX.CellObject = { t: 'd', z: 'hh:mm:ss' }
export const durationCellObject: XLSX.CellObject = { t: 's', z: '[h]:mm:ss' }

const getAddress = (header: string, row: number) => {
  const columnIndex = columns.findIndex((col) => col.header === header)
  if (columnIndex === -1) {
    console.error(header, row)
  }
  return `${XLSX.utils.encode_col(columnIndex)}${
    XLSX.utils.encode_row(row + 1) /* add 1 because of the header row */
  }`
}

export const columns: Columns = [
  {
    header: 'subcontractor',
    sourceKey: 'subcontractor_name',
    cellObject: {
      t: 's',
    },
  },
  {
    header: 'load_data',
    sourceKey: 'load_data',
    cellObject: { t: 's' },
  },
  {
    header: 'action',
    sourceKey: 'action',
    cellObject: { t: 's' },
  },
  {
    header: 'rework',
    sourceKey: 'rework',
    cellObject: { t: 'b' },
  },
  {
    header: 'quantity',
    sourceKey: 'quantity',
    cellObject: { t: 'n', z: '0' },
  },
  {
    header: 'date',
    sourceKey: 'in_between_start',
    cellObject: dateCellObject,
  },
  {
    header: 'pick_total',
    getFormula: (row) =>
      `${getAddress('unload_end', row)}-${getAddress('to_pick_start', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'pick_active',
    getFormula: (row) =>
      `${getAddress('to_pick_active', row)}+${getAddress(
        'load_active',
        row
      )}+${getAddress('travel_active', row)}+${getAddress(
        'unload_active',
        row
      )}`,
    cellObject: durationCellObject,
  },
  {
    header: 'pick_idle',
    getFormula: (row) =>
      `${getAddress('to_pick_idle', row)}+${getAddress(
        'load_idle',
        row
      )}+${getAddress('travel_idle', row)}+${getAddress('unload_idle', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'pre_start',
    sourceKey: 'in_between_start',
    cellObject: timeCellObject,
  },
  // {
  //   header: 'pre_idle',
  //   sourceKey: 'in_between_idle',
  //   cellObject: durationCellObject,
  // },
  {
    header: 'pre_total',
    getFormula: (row) =>
      `${getAddress('to_pick_start', row)}-${getAddress('pre_start', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'to_pick_start',
    sourceKey: 'to_pick_start',
    cellObject: timeCellObject,
  },
  {
    header: 'to_pick_total',
    getFormula: (row) =>
      `${getAddress('load_start', row)}-${getAddress('to_pick_start', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'to_pick_active',
    getFormula: (row) =>
      `${getAddress('to_pick_total', row)}-${getAddress('to_pick_idle', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'to_pick_idle',
    sourceKey: 'to_pick_idle',
    cellObject: durationCellObject,
  },
  {
    header: 'load_start',
    sourceKey: 'load_start',
    cellObject: timeCellObject,
  },
  {
    header: 'load_total',
    getFormula: (row) =>
      `${getAddress('travel_start', row)}-${getAddress('load_start', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'load_active',
    getFormula: (row) =>
      `${getAddress('load_total', row)}-${getAddress('load_idle', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'load_idle',
    sourceKey: 'load_idle',
    cellObject: durationCellObject,
  },
  {
    header: 'travel_start',
    sourceKey: 'travel_start',
    cellObject: timeCellObject,
  },
  {
    header: 'travel_total',
    getFormula: (row) =>
      `${getAddress('unload_start', row)}-${getAddress('travel_start', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'travel_active',
    getFormula: (row) =>
      `${getAddress('travel_total', row)}-${getAddress('travel_idle', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'travel_idle',
    sourceKey: 'travel_idle',
    cellObject: durationCellObject,
  },
  {
    header: 'unload_start',
    sourceKey: 'unload_start',
    cellObject: timeCellObject,
  },
  {
    header: 'unload_total',
    getFormula: (row) =>
      `${getAddress('unload_end', row)}-${getAddress('unload_start', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'unload_active',
    getFormula: (row) =>
      `${getAddress('unload_total', row)}-${getAddress('unload_idle', row)}`,
    cellObject: durationCellObject,
  },
  {
    header: 'unload_idle',
    sourceKey: 'unload_idle',
    cellObject: durationCellObject,
  },
  {
    header: 'unload_end',
    sourceKey: 'unload_end',
    cellObject: timeCellObject,
  },
  // {
  //   header: 'post_idle',
  //   sourceKey: 'post_idle',
  //   cellObject: durationCellObject,
  // },
  // {
  //   header: 'post_end',
  //   sourceKey: 'unload_end',
  //   cellObject: timeCellObject,
  // },
  {
    header: 'start_longitude',
    sourceKey: 'start_longitude',
    cellObject: { t: 'n' },
  },
  {
    header: 'start_latitude',
    sourceKey: 'start_latitude',
    cellObject: { t: 'n' },
  },
  {
    header: 'end_longitude',
    sourceKey: 'end_longitude',
    cellObject: { t: 'n' },
  },
  {
    header: 'end_latitude',
    sourceKey: 'end_latitude',
    cellObject: { t: 'n' },
  },
]

// convert Duration to Date by subtracting from 1899-12-31
export function durationToHours(duration: Duration): number {
  return duration.as('hours')
}

export function cranePicksCollectionToAOA(
  summedPicks: CranePicksCollection
): XLSX.CellObject[][] {
  const aoa: XLSX.CellObject[][] = [
    [
      { t: 's', v: 'Start DateTime' },
      {
        t: 'd',
        v:
          summedPicks.startDateTime
            ?.setZone('system', { keepLocalTime: true })
            .toJSDate() || '',
        z: 'yyyy-mm-dd hh:mm:ss',
      },
    ],
    [
      { t: 's', v: 'End DateTime' },
      {
        t: 'd',
        v:
          summedPicks.endDateTime
            ?.setZone('system', { keepLocalTime: true })
            .toJSDate() || '',
        z: 'yyyy-mm-dd hh:mm:ss',
      },
    ],
    [
      { t: 's', v: 'Total Picks' },
      { t: 'n', v: summedPicks.picks.length },
    ],
    [
      { t: 's', v: 'Total Idle Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.idleDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Total Active Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.activeDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Total Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.totalDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Shift Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.shiftDuration),
        z: '0.0',
      },
    ],
    // [
    //   { t: 's', v: 'Break Duration (hrs)' },
    //   {
    //     t: 'n',
    //     v: durationToHours(summedPicks.breakDuration),
    //     z: '0.0',
    //   },
    // ],
    [
      { t: 's', v: 'Empty Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.emptyDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Empty Idle Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.emptyIdleDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Non-Empty Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.nonEmptyDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Non-Empty Idle Duration (hrs)' },
      {
        t: 'n',
        v: durationToHours(summedPicks.nonEmptyIdleDuration),
        z: '0.0',
      },
    ],
    [
      { t: 's', v: 'Utilization' },
      { t: 's', v: (summedPicks.utilization * 100).toFixed(2) + '%' },
    ],
    // [
    //   { t: 's', v: 'Utilization Color' },
    //   { t: 's', v: summedPicks.utilizationColor },
    // ],
    [
      { t: 's', v: 'Minutes Per Pick' },
      { t: 's', v: summedPicks.minutesPerPick.toFixed(2) },
    ],
    [
      { t: 's', v: 'Picks Per Hour' },
      { t: 's', v: summedPicks.picksPerHour.toFixed(2) },
    ],
  ]

  return aoa
}
