import { FC, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { useAnimationFrame } from '../../hooks/useAnimationFrame'
import { useKRPanoStore } from './KRPanoProvider'

interface Props {
  url?: string
  enabled?: boolean
  distorted?: boolean
  zoom?: boolean
  renderer?: 'webgl' | 'css3d'
  chromakey?: string
  alpha?: number
  scale?: number
  ath?: number
  atv?: number
  torigin?: 'world' | 'image' | 'view'
  tx?: number
  ty?: number
  tz?: number
  rx?: number
  ry?: number
  rz?: number
  ox?: number
  oy?: number
}

export const KRPanoHtmlHotspot: FC<Props> = (props) => {
  const krpanoStore = useKRPanoStore()

  const pano = krpanoStore((state) => state.krPanoElement)

  const [{ x, y, opacity }, spring] = useSpring(() => ({
    x: 0,
    y: 0,
    opacity: 0,
  }))

  useAnimationFrame(() => {
    if (
      pano &&
      typeof props.ath === 'number' &&
      typeof props.atv === 'number'
    ) {
      const { x, y } = pano.spheretoscreen(props.ath, props.atv)
      if (!isNaN(x) && !isNaN(y)) {
        spring.set({ x, y, opacity: 1 })
      } else {
        spring.set({ x: 0, y: 0, opacity: 0 })
      }
    }
  })

  useEffect(() => {
    if (pano) {
    }
  }, [pano, props])

  return (
    <animated.div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        opacity,
        x,
        y,
      }}
    >
      {props.children}
    </animated.div>
  )
}
