import { useProject } from '../components/ProjectWrapper'
import { useMe } from './useMe'

// import 'mux-embed/dist/types/mux-embed.d.ts'
// @ts-ignore
import mux from 'mux-embed'

export const useMuxBaseConfig = () => {
  const { data: me } = useMe()
  const project = useProject()

  return {
    env_key: process.env.REACT_APP_MUX_DATA_ENV_KEY, // required

    // Metadata fields
    player_version: process.env.REACT_APP_VERSION, // ex: '1.0.0'
    player_init_time: mux.utils.now(),

    // Site Metadata
    viewer_user_id: me?.external_id, // ex: '12345'
    custom_1: me?.internal === true ? 'topdeck' : 'customer',
    sub_property_id: project.slug, // ex: 'cus-1'
    page_type: window.location.pathname
      .slice(process.env.REACT_APP_BASE_HREF!.length - 1)
      .replace(project.slug, 'project'),
  }
}
