import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { sortBy } from 'lodash'
import { Duration } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Subcontractor } from '../../../api/codegen/typescript-axios'
import { CranePicksCollection } from '../../../api/CranePicksCollection'
import { useCraneStore } from './CranePicksController'
import { SubcontractorStats } from './useSubcontractorStats'

interface SubcontractorExtended extends CranePicksCollection, Subcontractor {
  loadTypes: Array<{ loadType: string | null } & CranePicksCollection>
}

export const CraneReportsPanelSubcontractors = ({
  subcontractorStats,
  expanded,
}: {
  expanded?: boolean
  subcontractorStats: SubcontractorStats
}) => {
  const { t } = useTranslation()
  const craneStore = useCraneStore()

  const projectConfig = craneStore((state) => state.projectConfig)
  const supercategories = craneStore((state) => state.supercategories)

  return (
    <>
      <Typography variant="h6">{t('subcontractors')}</Typography>

      {projectConfig?.consolidate_supercategories_in_pie_chart
        ? Array.from(supercategories).map((supercategory) => (
            <div key={supercategory}>
              <Typography
                variant="h6"
                style={{
                  fontSize: '1rem',
                  position: 'relative',
                  top: '0.3em',
                }}
              >
                {supercategory}
              </Typography>
              {subcontractorStats
                .filter((s) => s.supercategory === supercategory)
                .map((s) => (
                  <SubcontractorSection
                    key={s.name}
                    subcontractor={s}
                    expanded={expanded}
                  />
                ))}
            </div>
          ))
        : subcontractorStats
            .sort(
              (a, b) => b.totalDuration.toMillis() - a.totalDuration.toMillis()
            )
            .map((s) => (
              <SubcontractorSection
                key={s.name}
                subcontractor={s}
                expanded={expanded}
              />
            ))}
    </>
  )
}

const SubcontractorSection = ({
  subcontractor,
  expanded,
}: {
  subcontractor: SubcontractorExtended
  expanded?: boolean
}) => {
  const { t } = useTranslation()
  const craneStore = useCraneStore()

  const projectConfig = craneStore((state) => state.projectConfig)

  const calendarEntries = craneStore((state) => state.calendarEntries).filter(
    (c) => c.subcontractor_link === subcontractor.id
  )
  const calendarDuration = calendarEntries.reduce(
    (acc, c) => acc.plus(c.endDateTime.diff(c.startDateTime)),
    Duration.fromMillis(0)
  )

  return (
    <Accordion
      key={subcontractor.name}
      square
      style={{
        borderLeftStyle: 'solid',
        borderLeftWidth: '8px',
        borderLeftColor: subcontractor.color,
        breakInside: 'avoid',
        position: 'relative',
        top: '1px',
      }}
      expanded={expanded}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <span style={{ flexGrow: 1 }}>
          {subcontractor.name || 'Unspecified'}
        </span>
        {!expanded && (
          <>
            {subcontractor.picks.length} {t('picks')},{' '}
            {subcontractor.totalDuration.toFormat('h:mm')}
          </>
        )}
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('task')}</TableCell>
              <TableCell align="right" style={{ width: 1 }}>
                {t('qty').toLowerCase()}
              </TableCell>
              <TableCell style={{ width: 1 }} align="right">
                {t('duration').toLowerCase()}
              </TableCell>
              <TableCell style={{ width: 1 }} align="right">
                {projectConfig?.pick_rate_format === 'picks_per_hour'
                  ? t('rate').toLowerCase()
                  : projectConfig?.pick_rate_format === 'minutes_per_pick'
                  ? t('min/pick').toLowerCase()
                  : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendarEntries.length > 0 && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {t('as_planned_total')}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    width: 1,
                    fontWeight: 'bold',
                  }}
                  align="right"
                >
                  {calendarDuration.toFormat('h:mm')}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ fontWeight: 'bold' }}
                ></TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>
                {calendarEntries.length > 0 && 'As-built '}
                {t('as_built_total')}
              </TableCell>
              <TableCell align="right" style={{ width: 1, fontWeight: 'bold' }}>
                {subcontractor.picks.length}
              </TableCell>
              <TableCell
                style={{
                  whiteSpace: 'nowrap',
                  width: 1,
                  fontWeight: 'bold',
                }}
                align="right"
              >
                {subcontractor.totalDuration.toFormat('h:mm')}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {projectConfig?.pick_rate_format === 'picks_per_hour' ? (
                  <>{subcontractor.picksPerHour.toFixed(1)}</>
                ) : projectConfig?.pick_rate_format === 'minutes_per_pick' ? (
                  <>{subcontractor.minutesPerPick.toFixed(1)}</>
                ) : null}
              </TableCell>
            </TableRow>

            {sortBy(Object.values(subcontractor.loadTypes), [
              (l) => -l.totalDuration,
            ]).map((l) => (
              <TableRow key={l.loadType}>
                <TableCell>{l.loadType}</TableCell>
                <TableCell align="right" style={{ width: 1 }}>
                  {l.picks.length}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    width: 1,
                  }}
                  align="right"
                >
                  {l.totalDuration.toFormat('h:mm')}
                </TableCell>
                <TableCell align="right">
                  {projectConfig?.pick_rate_format === 'picks_per_hour' ? (
                    <>{l.picksPerHour.toFixed(1)}</>
                  ) : projectConfig?.pick_rate_format === 'minutes_per_pick' ? (
                    <>{l.minutesPerPick.toFixed(1)}</>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
