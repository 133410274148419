import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  TableContainer,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core'
import FilterList from '@material-ui/icons/FilterList'
import React from 'react'
import { useQuery } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { useApi } from './ApiContext'
import { Loading } from './Loading'
import { PageWrapper } from './PageWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    link: {
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      color: '#fff',
    },
    muiDatatable: {
      '& td': {
        cursor: 'pointer',
      },
    },
    row: {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)

export const ProjectsListPage = (props: {}) => {
  const theme = useTheme()
  const api = useApi()
  const classes = useStyles(props)
  const navigate = useNavigate()

  const { data: projects } = useQuery('api.getProjects', api.getProjects)

  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  const [filterLive, setFilterLive] = useLocalStorage(
    'ProjectsListPage-filterLive',
    false
  )

  if (!projects) {
    return <Loading text="Loading Projects" />
  }

  if (projects.length === 1) {
    return <Navigate replace to={`/${projects[0].slug}`} />
  }

  return (
    <PageWrapper>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Typography variant="h5" style={{ padding: '10px 20px' }}>
                Your Projects{' '}
                <IconButton
                  onClick={() => {
                    setFilterLive(!filterLive)
                  }}
                  color={filterLive ? 'primary' : 'default'}
                  title="Show only live projects"
                >
                  <FilterList />
                </IconButton>
              </Typography>
              <Table className={classes.muiDatatable} title={'Your projects'}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Live</TableCell>
                    <TableCell>Name</TableCell>
                    {desktop && <TableCell>Address</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects
                    .filter((p) => {
                      if (filterLive) {
                        return p.live
                      } else {
                        return true
                      }
                    })
                    .map((project) => (
                      <TableRow
                        key={project.name}
                        onClick={() => navigate(`/${project.slug}`)}
                        className={classes.row}
                      >
                        <TableCell component="th" scope="row">
                          <div
                            style={{
                              width: 8,
                              height: 8,
                              borderRadius: 4,
                              margin: '0 auto',
                              backgroundColor: project.live
                                ? theme.palette.success.main
                                : theme.palette.background.default,
                            }}
                          />
                        </TableCell>
                        <TableCell>{project.name}</TableCell>
                        {desktop && (
                          <TableCell>
                            <>
                              {`${project.address_1 || ''} ${
                                project.city || ''
                              } ${project.state || ''} ${
                                project.zip_code || ''
                              }`}
                            </>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </PageWrapper>
  )
}
