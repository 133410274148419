import React from 'react'
import { Photosphere, ProjectDetails } from '../../api/codegen/typescript-axios'

export const PhotosphereKRPanoXML = ({
  project,
  photospheres,
  onViewChanged,
}: {
  project?: ProjectDetails
  photospheres: Photosphere[]
  onViewChanged?: () => void
}) => {
  return (
    <krpano>
      {/* <include url="%VIEWER%/skin/vtourskin.xml" /> */}
      {/* <!-- load the first scene on startup --> */}
      <action name="startup" autorun="onstart">
        {`
        loadscene(scene${photospheres[0].id}, null, MERGE);
        skin_hideskin(instant);
        `}
      </action>

      <action name="mapspot_loadscene">
        {`loadscene(%1, null, MERGE, BLEND(1));`}
      </action>

      {/* <!-- the tour scenes --> */}
      {photospheres.map((photosphere) => {
        const ShapeTag = photosphere.xml_shape as keyof JSX.IntrinsicElements
        return (
          <scene
            key={photosphere.id}
            name={'scene' + photosphere.id}
            title={photosphere.title}
          >
            {photosphere.xml_shape === 'cube' ? (
              <>
                <control zoomtocursor="true" />

                <view
                  hlookat={photosphere.template_panoattrs?.hlookat ?? 0}
                  // hlookat={0}
                  // vlookat range: 90 = ground, 0 = horizon, -90 = sky
                  vlookat={photosphere.template_panoattrs?.vlookat ?? 50}
                  vlookatmin={-20}
                  // vlookatmin={photosphere.template_panoattrs?.vlookatmin ?? -20}
                  vlookatmax={photosphere.template_panoattrs?.vlookatmax ?? 90}
                  camroll={photosphere.template_panoattrs?.camroll ?? 0}
                  fovtype="MFOV"
                  fov="110"
                  maxpixelzoom="2.0"
                  fovmin="70"
                  fovmax="140"
                  limitview="range"
                />

                <preview url={photosphere.b2_pano_previewurl} />
              </>
            ) : photosphere.xml_shape === 'flat' ? (
              <>
                <view
                  fovtype="HFOV"
                  fov="1.0"
                  fovmax="1.0"
                  maxpixelzoom="2.0"
                  limitview="lookat"
                />

                <control
                  mousetype="drag"
                  touchtype="drag"
                  zoomtocursor="true"
                  zoomoutcursor="true"
                  bouncinglimits="true"
                  keycodesin="16"
                  keycodesout="17"
                />

                <events
                  name="flatpano_events"
                  onnewpano="callwhen(area.pixelwidth GT 0 AND area.pixelheight GT 0, flatpano_startup() );"
                  onresize="flatpano_imagefit();"
                />

                <action name="flatpano_startup">
                  div(pano_aspectratio, image.hfov, image.vfov);
                  div(screen_aspectratio, area.pixelwidth, area.pixelheight);
                  if(pano_aspectratio GT screen_aspectratio, set(view.fovtype,
                  HFOV); copy(view.fov, image.hfov); copy(view.fovmax,
                  image.hfov); , set(view.fovtype, VFOV); copy(view.fov,
                  image.vfov); copy(view.fovmax, image.vfov); );
                </action>

                <action name="flatpano_imagefit">
                  if(image.vfov GT 0, div(pano_aspectratio, image.hfov,
                  image.vfov); div(screen_aspectratio, area.pixelwidth,
                  area.pixelheight); if(pano_aspectratio GT screen_aspectratio,
                  if(view.fovtype == VFOV, remapfovtype(view.fov, VFOV, HFOV,
                  lastresize_width, lastresize_height); set(view.fovtype, HFOV);
                  copy(view.fovmax, image.hfov); ); , if(view.fovtype == HFOV,
                  remapfovtype(view.fov, HFOV, VFOV, lastresize_width,
                  lastresize_height); set(view.fovtype, VFOV); copy(view.fovmax,
                  image.vfov); ); ); ); set(lastresize_width,
                  get(area.pixelwidth)); set(lastresize_height,
                  get(area.pixelheight));
                </action>
              </>
            ) : null}

            <image>
              <ShapeTag
                url={photosphere.b2_pano_cubeurl}
                multires={photosphere.xml_resolutions}
              />
            </image>
          </scene>
        )
      })}
    </krpano>
  )
}
