import { FormControl, Grid, InputLabel, NativeSelect } from '@material-ui/core'
import React from 'react'
import {
  DeckAIOverlayOptionColorBy,
  DeckAIOverlayOptionGroupBy,
  useDeckAIStore,
} from './DeckAIController'

export const DeckAIControls = ({}: {}) => {
  const deckAIStore = useDeckAIStore()

  const labels = deckAIStore((state) => state.labels)
  const labelId = deckAIStore((state) => state.labelId)
  const analyses = deckAIStore((state) => state.analyses)
  const analysisId = deckAIStore((state) => state.analysisId)
  const mouseLocation = deckAIStore((state) => state.mouseLocation)
  const segmentationDrawing = deckAIStore((state) => state.segmentationDrawing)
  const overlayOptions = deckAIStore((state) => state.overlayOptions)

  const [showInfo, setShowInfo] = React.useState(false)

  const colorByOptions: DeckAIOverlayOptionColorBy[] = [
    'Work rate',
    'Completion time',
  ]

  const groupByOptions: DeckAIOverlayOptionGroupBy[] = ['None', 'Hour', 'Day']

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          {analyses.length > 0 && (
            <FormControl>
              <InputLabel>Analysis</InputLabel>
              <NativeSelect
                value={analysisId || 0}
                onChange={(e) => {
                  deckAIStore.setState({
                    analysisId: parseInt(e.target.value),
                  })
                }}
              >
                <option key={'0'} value={0}>
                  Select analysis
                </option>
                {analyses.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        </Grid>

        <Grid item>
          {labels.length > 0 && (
            <FormControl>
              <InputLabel>Labels</InputLabel>
              <NativeSelect
                value={labelId || 0}
                onChange={(e) => {
                  deckAIStore.setState({
                    labelId: parseInt(e.target.value),
                  })
                }}
              >
                <option key={'0'} value={0}>
                  Select label
                </option>
                {labels.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        </Grid>

        <Grid item>
          {labels.length > 0 && (
            <FormControl>
              <InputLabel>Color by</InputLabel>
              <NativeSelect
                value={overlayOptions.colorBy}
                onChange={(e) => {
                  deckAIStore.setState((state) => {
                    return {
                      overlayOptions: {
                        ...state.overlayOptions,
                        colorBy: e.target.value as DeckAIOverlayOptionColorBy,
                      },
                    }
                  })
                }}
              >
                {colorByOptions.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        </Grid>

        <Grid item>
          <FormControl>
            <InputLabel>Grouping</InputLabel>
            <NativeSelect
              value={overlayOptions.groupBy}
              onChange={(e) => {
                deckAIStore.setState((state) => {
                  return {
                    overlayOptions: {
                      ...state.overlayOptions,
                      groupBy: e.target.value as DeckAIOverlayOptionGroupBy,
                    },
                  }
                })
              }}
            >
              {groupByOptions.map((opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid>

        {showInfo ? (
          <>
            <Grid>{mouseLocation}</Grid>
            {segmentationDrawing && segmentationDrawing.master_to_drawing && (
              <Grid>
                <div>Master to Drawing transformation:</div>
                <table>
                  {segmentationDrawing.master_to_drawing.map((row) => (
                    <tr>
                      {row.map((value) => (
                        <td>{value}</td>
                      ))}
                    </tr>
                  ))}
                </table>
              </Grid>
            )}
          </>
        ) : (
          <Grid></Grid>
        )}
      </Grid>
    </>
  )
}
