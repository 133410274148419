import { SomeRequired } from '../../types'

type ID = {
  id: number
}

export type HasID<T> = T & ID

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`)
  }
}

export function assertId(obj: Object): asserts obj is ID {
  if ('id' in obj && typeof (obj as ID).id === 'number') return

  throw new Error('Object has no ID')
}

export function assertRequired<T, K extends keyof T>(
  object: T,
  keys: Array<K>
): asserts object is SomeRequired<T, K> {
  keys.forEach((key) => {
    if (object[key] === undefined) {
      throw new Error('Missing required fields')
    }
  })
}
