import { createStyles, makeStyles, Paper } from '@material-ui/core'
import clsx from 'clsx'
import React, { memo } from 'react'
import { useProject } from '../ProjectWrapper'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      thumbnails: {
        display: 'flex',
        margin: '10px -5px 0',
        padding: 0,
        flexWrap: 'wrap',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
          overflow: 'auto',
        },
      },
      thumbnail: {
        listStyle: 'none',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        padding: '5px',
        transition: 'opacity 0.1s',
      },
      thumbnailInner: {
        paddingTop: '56.25%',
        backgroundSize: 'cover',
        position: 'relative',
        '&:hover': {
          outline: `1px solid ${theme.palette.primary.main}`,
        },
      },
      selectedThumbnail: {
        outline: `2px solid ${theme.palette.primary.main}`,
        '&:hover': {
          outline: `2px solid ${theme.palette.primary.main}`,
        },
      },
      streamName: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: '#fff',
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        padding: 2,
      },
    }),
  {
    name: 'VODStreamThumbnails',
  }
)

export const VODStreamThumbnails = memo(() => {
  // console.log('render VODPickers')
  const classes = useStyles()
  const project = useProject()
  const useVODStore = getUseVODStore()

  const videoStreamId = useVODStore((state) => state.videoStreamId)
  const gotoVideo = useVODStore((state) => state.gotoVideo)

  return (
    <ul className={classes.thumbnails}>
      {project.streams.map((s) => (
        <li
          key={s.id}
          className={clsx(classes.thumbnail)}
          onClick={() => {
            gotoVideo({ streamId: s.id })
          }}
        >
          <Paper elevation={2}>
            <div
              className={clsx(classes.thumbnailInner, {
                [classes.selectedThumbnail]: s.id === videoStreamId,
              })}
              style={{
                backgroundImage: `url(${s.current_thumbnail_url})`,
              }}
            >
              <div className={classes.streamName}>{s.name}</div>
            </div>
          </Paper>
        </li>
      ))}
    </ul>
  )
})

VODStreamThumbnails.displayName = 'VODStreamThumbnails'
