import {
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import ControlCameraIcon from '@material-ui/icons/ControlCamera'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SlideshowIcon from '@material-ui/icons/Slideshow'
import { DateTime, Duration } from 'luxon'
import React, { useState } from 'react'
import { useInterval, useMount } from 'react-use'
import { StreamExtended } from '../../api/StreamExtended'
import { KeyedButton } from './KeyedButton'
import { PtzControls } from './PtzControls'
import { VideoErrorMessages } from './VideoErrorMessages'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 4,
      height: 40,
      overflow: 'hidden',
    },
    infoLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    infoRight: {
      display: 'flex',
      alignItems: 'center',
    },
    feedInfo: {
      margin: '0 10px',
    },
    circle: {
      transitionDuration: 'inherit',
      transitionTimingFunction: 'linear',
    },
    alertRoot: {
      marginTop: '0px',
      marginRight: 6,
    },
  })
)

export const VideoControls: React.FC<{
  stream?: StreamExtended
  playState?: boolean
  setPlayState?: (b: boolean) => void
  enlargeState?: boolean
  setEnlargeState?: (b: boolean) => void
  fullScreenState?: boolean
  setFullScreenState?: (b: boolean) => void
  ptzState?: boolean
  setPtzState?: (b: boolean) => void
  rtcState?: boolean
  setRtcState?: (b: boolean) => void
  slideshowState?: boolean
  setSlideshowState?: (b: boolean) => void
  slideshowInterval?: number
  setSlideshowInterval?: (n: number) => void
  nextVideo?: () => void
  prevVideo?: () => void
}> = ({
  children,
  stream,
  playState,
  setPlayState,
  enlargeState,
  setEnlargeState,
  fullScreenState,
  setFullScreenState,
  ptzState,
  setPtzState,
  rtcState,
  setRtcState,
  slideshowState,
  setSlideshowState,
  slideshowInterval,
  setSlideshowInterval,
  nextVideo,
  prevVideo,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [ptzPresetDuration, setPtzPresetDuration] = React.useState<
    Duration | undefined
  >(undefined)

  useInterval(() => {
    if (stream && stream.ptzResetDateTime) {
      const now = DateTime.local()
      if (now >= stream.ptzResetDateTime) {
        setPtzPresetDuration(undefined)
      } else {
        setPtzPresetDuration(stream.ptzResetDateTime.diffNow())
      }
    }
  })

  return (
    <div className={classes.root}>
      {/* Main control bar */}
      <div className={classes.info}>
        <div className={classes.infoLeft}>
          {stream && (
            <>
              <Typography style={{ marginLeft: 6 }}>{stream.name}</Typography>
            </>
          )}
          {typeof playState === 'boolean' && setPlayState && (
            <KeyedButton
              tooltip={playState ? 'Pause' : 'Play'}
              icon={playState ? <PauseIcon /> : <PlayArrowIcon />}
              onClick={() => setPlayState(!playState)}
            />
          )}

          {/* Slideshow control */}
          {prevVideo && (
            <KeyedButton
              tooltip={'Previous stream'}
              keyCode="["
              icon={<NavigateBeforeIcon />}
              onClick={prevVideo}
            />
          )}
          {setSlideshowState && setSlideshowInterval && slideshowInterval && (
            <>
              <KeyedButton
                tooltip={slideshowState ? 'Pause slideshow' : 'Play slideshow'}
                text={slideshowState ? 'Pause slideshow' : 'Play slideshow'}
                icon={
                  slideshowState ? (
                    <Countdown
                      slideshowInterval={slideshowInterval}
                      key={slideshowInterval}
                    />
                  ) : (
                    <SlideshowIcon />
                  )
                }
                onClick={() => setSlideshowState(!slideshowState)}
              />
              <Select
                value={slideshowInterval}
                onChange={(e) => {
                  setSlideshowInterval(e.target.value as number)
                }}
                variant="standard"
                style={{ position: 'relative', top: -2, marginLeft: 6 }}
              >
                {[5, 15, 30, 60].map((seconds) => (
                  <MenuItem key={seconds} value={seconds}>
                    {seconds} sec
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {nextVideo && (
            <KeyedButton
              tooltip={'Next stream'}
              keyCode="]"
              icon={<NavigateNextIcon />}
              onClick={nextVideo}
            />
          )}
          {/* End Slideshow control */}
        </div>

        <div className={classes.infoRight}>
          {children}
          {ptzPresetDuration && (
            <VideoErrorMessages
              classes={{
                alertRoot: classes.alertRoot,
              }}
              messages={[
                {
                  text:
                    'PTZ will reset to HOME in ' +
                    ptzPresetDuration.toFormat('mm:ss'),
                  color: 'blue',
                },
              ]}
            ></VideoErrorMessages>
          )}
          {setRtcState && (
            <Select
              value={rtcState ? 1 : 0}
              onChange={(e) => {
                setRtcState(!!e.target.value)
              }}
              variant="standard"
              style={{ position: 'relative', top: -2 }}
            >
              <MenuItem value={0}>High resolution</MenuItem>
              <MenuItem value={1}>Low latency</MenuItem>
            </Select>
          )}
          {setPtzState && (
            <KeyedButton
              tooltip={ptzState ? 'Exit PTZ control' : 'Launch PTZ control'}
              icon={
                <ControlCameraIcon
                  style={{
                    color: ptzState ? '#0f0' : theme.palette.text.primary,
                  }}
                />
              }
              onClick={() => {
                setPtzState(!ptzState)
                setRtcState && setRtcState(!ptzState)
              }}
            />
          )}
          {setEnlargeState && (
            <KeyedButton
              tooltip={'Expand'}
              icon={<AspectRatioIcon />}
              onClick={() => {
                setEnlargeState(!enlargeState)
              }}
            />
          )}
          {setFullScreenState && (
            <KeyedButton
              tooltip={'Full screen'}
              icon={<FullscreenIcon />}
              onClick={() => {
                setFullScreenState(!setFullScreenState)
              }}
            />
          )}
        </div>
      </div>
      {/* End main control bar */}

      <PtzControls
        stream={stream}
        ptzState={ptzState}
        setPtzState={setPtzState}
        setRtcState={setRtcState}
      />
    </div>
  )
}

const Countdown = ({ slideshowInterval }: { slideshowInterval: number }) => {
  const classes = useStyles()
  const [slideValue, setSlideValue] = useState(100)
  useMount(() => {
    if (slideValue === 100) {
      setSlideValue(0)
    }
  })

  return (
    <CircularProgress
      variant="static"
      value={slideValue}
      size={14}
      thickness={8}
      classes={{
        svg: classes.circle,
        circle: classes.circle,
      }}
      style={{ transitionDuration: `${slideshowInterval - 0.2}s` }}
    />
  )
}
