import { createStyles, Link, makeStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { FC } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // padding: theme.spacing(2),
      cursor: 'pointer',
    },
  })
)

export const VODLink: FC<{
  to: {
    streamId?: number
    dateTime?: DateTime
    speed?: number
  }
}> = ({ to, children }) => {
  const classes = useStyles()
  const useVODStore = getUseVODStore()

  const [currentSearchParams, setSearchParams] = useSearchParams()

  return (
    <Link
      color="secondary"
      className={classes.root}
      onClick={() => {
        const searchParams = createSearchParams()
        to.dateTime &&
          searchParams.set(
            'date',
            to.dateTime.toISO({ suppressMilliseconds: true })
          )
        to.streamId && searchParams.set('stream', to.streamId.toString())
        to.speed && searchParams.set('speed', to.speed.toString())
        if (currentSearchParams.toString() === searchParams.toString()) {
          useVODStore.getState().gotoVideo({
            ...to,
          })
        } else {
          setSearchParams(searchParams)
        }
      }}
    >
      {children}
    </Link>
  )
}
