import { createStyles } from '@material-ui/core'

export const mixins = createStyles({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})
