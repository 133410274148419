import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMe } from '../hooks/useMe'

export const LocationListener: React.FC<{}> = (props) => {
  const location = useLocation()
  const { data: me } = useMe()

  React.useEffect(() => {
    if (me?.external_id) {
      gtag(
        'set',
        'page_path',
        process.env.REACT_APP_BASE_HREF!.slice(0, -1) + location.pathname
      )
      gtag('event', 'page_view')
    }
  }, [location, me])

  return <MemoizedChildren>{props.children}</MemoizedChildren>
}

const MemoizedChildren = React.memo((props) => <>{props.children}</>)
