import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Colord } from 'colord'
import { uniq } from 'lodash'
import { DateTime, Duration } from 'luxon'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'
import * as XLSX from 'xlsx'
import { extendCraneCalendarEntry } from '../../../api/CraneCalendarEntryExtended'
import { extendCranePick } from '../../../api/CranePickExtended'
import { assertIsDefined } from '../../../api/assertRequired'
import { useApi } from '../../ApiContext'
import { Loading } from '../../Loading'
import { useProject } from '../../ProjectWrapper'
import { AnalysesController } from '../AnalysesController'
import { VODController, getUseVODStore } from '../VODController'
import { CranePicksController, useCraneStore } from './CranePicksController'

export const CraneReportWeeklyPrint = () => {
  return (
    <AnalysesController>
      <VODController>
        <CranePicksController>
          <CraneReportWeeklyPrintLayout />
        </CranePicksController>
      </VODController>
    </AnalysesController>
  )
}

const CraneReportWeeklyPrintLayout = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const useVODStore = getUseVODStore()
  const api = useApi()
  const craneStore = useCraneStore()
  const project = useProject()

  const date = useVODStore((state) => state.videoDate)

  const selectedCrane = craneStore((state) => state.selectedCrane)
  const subcontractors = craneStore((state) => state.subcontractors)
  const sumPicks = craneStore((state) => state.sumPicks)

  const tableRef = React.useRef<HTMLTableElement>(null)

  React.useEffect(() => {
    document.body.style.width = '10in'
    return () => {
      document.body.style.width = ''
    }
  }, [])

  const { value: picks = [], loading: loadingPicks } = useAsync(async () => {
    if (!selectedCrane) {
      return
    }

    const { data } = await api.craneApi.cranesPicksList({
      craneId: selectedCrane.id.toString(),
      startTime: date.startOf('week').toISO(),
      endTime: date.endOf('week').toISO(),
    })

    return data.map((pick) => extendCranePick(pick, project))
  }, [selectedCrane?.id, date.toISODate()])

  const {
    value: calendarEntries = [],
    loading: loadingCalendar,
  } = useAsync(async () => {
    if (!selectedCrane) return

    const { data } = await api.craneApi.cranesCalendarList({
      craneId: selectedCrane.id.toString(),
      startTime: date.startOf('day').toISO(),
      endTime: date.plus({ days: 7 }).toISO(),
    })

    return data.map((entry) => extendCraneCalendarEntry(entry, project))
  }, [selectedCrane, date])

  const isoDates = uniq(picks.map((pick) => pick.isoDate))

  const allPicks = React.useMemo(() => {
    const isoDates = uniq(picks.map((pick) => pick.isoDate))
    return sumPicks({
      picks: picks,
      isoDates: isoDates,
      calendarEntries: calendarEntries,
      subcontractors: subcontractors,
    })
  }, [picks, calendarEntries, subcontractors])

  if (isoDates.length === 0 || loadingPicks || loadingCalendar) {
    console.log('loading')
    return <Loading />
  }

  const startTimeISO =
    allPicks.picks.at(0)?.startDateTime.toISO({
      suppressMilliseconds: true,
    }) || ''

  const url = `https://topdeck.ai/app/${
    project.slug
  }/crane-reports/?dateTime=${encodeURIComponent(startTimeISO)}&stream=${
    selectedCrane?.jib_stream_original
  }&crane=${selectedCrane?.id}`

  return (
    <div>
      <div id="loading-complete" style={{ display: 'none' }}>
        LOADING COMPLETE
      </div>
      <Helmet>
        <title>
          {project.name} -{' '}
          {project.cranes.length > 1 ? selectedCrane?.name + ' - ' : ''}{' '}
          {t('weekly_crane_report')} -{' '}
          {date.startOf('week').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </title>
      </Helmet>

      <Typography variant="h5">
        {t('weekly_crane_report')} <span>- {project.name}</span>
        {project.cranes.length > 1 && selectedCrane && (
          <span> - {selectedCrane.name}</span>
        )}
      </Typography>
      <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
        {date.startOf('week').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        {' - '}
        {date.endOf('week').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      </Typography>

      <Link color="textSecondary" href={url}>
        {'Report page'}
      </Link>

      {/* Button to export as xlsx */}
      <Box displayPrint={'none'}>
        <Button
          variant="contained"
          size="small"
          style={{
            margin: theme.spacing(1),
          }}
          onClick={() => {
            assertIsDefined(tableRef.current)

            const wb = XLSX.utils.table_to_book(tableRef.current, {
              sheet: `Week starting ${date.toISODate()}`,
              raw: true,
            })
            XLSX.writeFile(wb, `${project.name}-week-${date.toISODate()}.xlsx`)
          }}
        >
          Export XLSX
        </Button>
      </Box>

      <Table
        size="small"
        style={{
          border: '1px solid',
          borderColor: theme.palette.divider,
          marginTop: theme.spacing(2),
          width: 'auto',
        }}
        ref={tableRef}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={1} align="right">
              {t('avg')}
            </TableCell>
            <TableCell colSpan={1} align="right">
              {t('total')}
            </TableCell>
            {isoDates.map((date, i) => (
              <TableCell
                key={date}
                align="center"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {DateTime.fromISO(date).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>{t('start_time')}</TableCell>
            <TableCell
              colSpan={1}
              align="right"
              style={{ whiteSpace: 'nowrap' }}
            >
              {/* average daily startDateTime */}
              {DateTime.fromMillis(
                allPicks.dates
                  .map((date) => {
                    // return startDateTime as Duration from midnight
                    if (date.startDateTime) {
                      return date.startDateTime.diff(
                        date.startDateTime.startOf('day')
                      )
                    } else {
                      return undefined
                    }
                  })
                  .filter((d) => d !== undefined)
                  // average Duration
                  .reduce((acc, d) => acc + d.toMillis(), 0) /
                  // divide by number of days
                  isoDates.length
              )
                .setZone('UTC')
                .toLocaleString(DateTime.TIME_SIMPLE)}
            </TableCell>
            <TableCell colSpan={1} align="right">
              NA
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {date.startDateTime?.toLocaleString(DateTime.TIME_SIMPLE) || ''}
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>{t('end_time')}</TableCell>
            <TableCell
              colSpan={1}
              align="right"
              style={{ whiteSpace: 'nowrap' }}
            >
              {/* average daily end time */}
              {DateTime.fromMillis(
                allPicks.dates
                  .map((date) => {
                    // return endDateTime as Duration from midnight
                    if (date.endDateTime) {
                      return date.endDateTime.diff(
                        date.endDateTime.startOf('day')
                      )
                    } else {
                      return undefined
                    }
                  })
                  .filter((d) => d !== undefined)
                  // average Duration
                  .reduce((acc, d) => acc + d.toMillis(), 0) /
                  // divide by number of days
                  isoDates.length
              )
                .setZone('UTC')
                .toLocaleString(DateTime.TIME_SIMPLE)}
            </TableCell>

            <TableCell colSpan={1} align="right">
              NA
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {date.endDateTime?.toLocaleString(DateTime.TIME_SIMPLE) || ''}
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>{t('idle_duration')}</TableCell>
            <TableCell colSpan={1} align="right">
              {/* average idle duration */}
              {Duration.fromMillis(
                allPicks.idleDuration.toMillis() / isoDates.length
              ).toFormat('h:mm')}
            </TableCell>
            <TableCell colSpan={1} align="right">
              {allPicks.idleDuration.toFormat('h:mm')}
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {date.idleDuration.toFormat('h:mm')}
              </TableCell>
            ))}
          </TableRow>

          {/* Shift duration row */}
          <TableRow>
            <TableCell colSpan={2}>{t('shift_duration')}</TableCell>
            <TableCell colSpan={1} align="right">
              {Duration.fromMillis(
                allPicks.shiftDuration.toMillis() / isoDates.length
              ).toFormat('h:mm')}
            </TableCell>
            <TableCell colSpan={1} align="right">
              {allPicks.shiftDuration.toFormat('h:mm')}
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {date.shiftDuration.toFormat('h:mm')}
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>{t('utilization')}</TableCell>
            <TableCell colSpan={1} align="right">
              {/* average daily utilization */}
              {(
                allPicks.dates
                  .map((date) => date.utilization)
                  .reduce((acc, u) => acc + u * 100, 0) / isoDates.length
              ).toFixed(1)}
              %
            </TableCell>
            <TableCell colSpan={1} align="right">
              {(allPicks.utilization * 100).toFixed(0)}%
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {(date.utilization * 100).toFixed(0)}%
              </TableCell>
            ))}
          </TableRow>

          {/* value added utilization */}
          <TableRow>
            <TableCell colSpan={2}>{t('value_added_utilization')}</TableCell>
            <TableCell colSpan={1} align="right">
              {(
                allPicks.dates
                  .map((date) => date.utilizationValueAdded)
                  .reduce((acc, u) => acc + u * 100, 0) / isoDates.length
              ).toFixed(1)}
              %
            </TableCell>
            <TableCell colSpan={1} align="right">
              {(allPicks.utilizationValueAdded * 100).toFixed(0)}%
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {(date.utilizationValueAdded * 100).toFixed(0)}%
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>{t('qty')}</TableCell>
            <TableCell colSpan={1} align="right">
              {(allPicks.picks.length / isoDates.length).toFixed(1)}
            </TableCell>
            <TableCell colSpan={1} align="right">
              {allPicks.picks.length}
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="right"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                }}
              >
                {date.picks.length}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              colSpan={3}
              align="center"
              style={{
                borderLeft: '1px solid',
                borderLeftColor: theme.palette.divider,
                fontWeight: 500,
              }}
            >
              {t('average')}
            </TableCell>
            {allPicks.dates.map((date, i) => (
              <TableCell
                key={date.date}
                align="center"
                colSpan={3}
                style={{
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.divider,
                  fontWeight: 500,
                }}
              >
                {date.picks
                  .at(0)
                  ?.startDateTime.toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            {['', ...allPicks.dates].map((date, i) => (
              <React.Fragment key={i}>
                <TableCell
                  align="center"
                  style={{
                    borderLeft: '1px solid',
                    borderLeftColor: theme.palette.divider,
                    padding: '6px 8px 6px 8px',
                    fontWeight: 500,
                  }}
                >
                  {t('qty')}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    padding: '6px 8px 6px 8px',
                    fontWeight: 500,
                  }}
                >
                  {t('as_planned_total')}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    padding: '6px 8px 6px 8px',
                    fontWeight: 500,
                  }}
                >
                  {t('as_built_total')}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>

          {allPicks.subcontractors
            .sort((a, b) => {
              // sort by totalDuration
              return b.totalDuration.toMillis() - a.totalDuration.toMillis()
            })
            .map((subcontractor) => (
              <TableRow
                key={subcontractor.subcontractor.id}
                style={{
                  backgroundColor: new Colord(
                    subcontractor.subcontractor.color || '#ffffff'
                  )
                    .alpha(0.3)
                    .toRgbString(),
                  WebkitPrintColorAdjust: 'exact',
                }}
              >
                <TableCell
                  style={{
                    borderLeft: '8px solid',
                    borderLeftColor:
                      subcontractor.subcontractor.color || 'transparent',
                  }}
                >
                  {subcontractor.subcontractor.name || 'Unspecified'}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    borderLeft: '1px solid',
                    borderLeftColor: theme.palette.divider,
                  }}
                >
                  {(subcontractor.picks.length / isoDates.length).toFixed(1)}
                </TableCell>
                <TableCell align="right">
                  {subcontractor.calendarEntries
                    ? Duration.fromMillis(
                        subcontractor.calendarEntries
                          .reduce(
                            (acc, entry) => acc.plus(entry.duration),
                            Duration.fromMillis(0)
                          )
                          .toMillis() / isoDates.length
                      ).toFormat('h:mm')
                    : 'NA'}
                </TableCell>
                <TableCell align="right">
                  {Duration.fromMillis(
                    subcontractor.totalDuration.toMillis() / isoDates.length
                  ).toFormat('h:mm')}
                </TableCell>
                {subcontractor.dates.map((date, i) => (
                  <React.Fragment key={date.date}>
                    <TableCell
                      key={date.date}
                      align="right"
                      style={{
                        borderLeft: '1px solid',
                        borderLeftColor: theme.palette.divider,
                      }}
                    >
                      {date.picks.length}
                    </TableCell>
                    <TableCell align="right">
                      {date.calendarEntries
                        ? date.calendarEntries
                            .reduce(
                              (acc, entry) => acc.plus(entry.duration),
                              Duration.fromMillis(0)
                            )
                            .toFormat('h:mm')
                        : 'NA'}
                    </TableCell>
                    <TableCell align="right">
                      {date.totalDuration.toFormat('h:mm')}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}
