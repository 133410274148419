import {
  Checkbox,
  Grid,
  IconButton,
  Input,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import clsx from 'clsx'
import React, { memo, useRef, useState } from 'react'
// import cursor from '../../../images/cursor.png'
import { mixins } from '../../styles/mixins'
import { useProject } from '../ProjectWrapper'
import { MultiPlayerVideo } from './MultiPlayerVideo'
import { getUseVODStore } from './VODController'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      // cursor: `url(${cursor}) 22 22, auto`,
      padding: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    gridContainer: {
      height: '100%',
    },
    pointerCursor: {
      cursor: 'default',
    },
    header: {
      padding: '0 0 8px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    videos: {
      flexGrow: 1,
      // backgroundColor: '#000',
      overflowY: 'auto',
    },
    canvas: {
      ...mixins.absoluteFill,
      outline: 'none',
      pointerEvents: 'none',
    },
    alertContainer: {
      ...mixins.absoluteFill,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  {
    name: 'MultiPlayerPanel',
  }
)

export const MultiPlayerPanel = memo(
  ({ className }: { className?: string }) => {
    const classes = useStyles()
    const project = useProject()
    const useVODStore = getUseVODStore()
    const videoStreamId = useVODStore((state) => state.videoStreamId)

    const menuRef = useRef(null)
    const [selectedStreams, setSelectedStreams] = useState<Array<string>>([])
    const [menuOpen, setMenuOpen] = useState(false)
    const [autoExcludePrimary, setAutoExcludePrimary] = useState(false)

    return (
      <div className={clsx(className, classes.root)}>
        <Grid
          container
          className={classes.gridContainer}
          direction="column"
          wrap="nowrap"
        >
          <Grid className={classes.header}>
            <Select
              multiple
              value={selectedStreams}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectedStreams(event.target.value as string[])
              }}
              input={<Input />}
              displayEmpty
              renderValue={(selected) => 'Select streams'}
            >
              {project.streams.map((stream) => (
                <MenuItem key={stream.id} value={stream.name}>
                  <Checkbox
                    size="small"
                    checked={
                      selectedStreams &&
                      selectedStreams.indexOf(stream.name) > -1
                    }
                  />
                  <ListItemText primary={stream.name} />
                </MenuItem>
              ))}
            </Select>
            <IconButton
              size="small"
              onClick={() => setMenuOpen(true)}
              ref={menuRef}
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              anchorEl={menuRef.current}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <MenuItem
                onClick={() => {
                  setAutoExcludePrimary(!autoExcludePrimary)
                  setMenuOpen(false)
                }}
              >
                <Checkbox checked={autoExcludePrimary} size="small" />
                <ListItemText primary={'Auto-exclude primary stream'} />
              </MenuItem>
            </Menu>
          </Grid>

          <Grid item className={classes.videos}>
            {project.streams
              .filter((s) => {
                if (autoExcludePrimary && s.id === videoStreamId) {
                  return false
                } else {
                  return selectedStreams.indexOf(s.name) > -1
                }
              })
              .map((s) => (
                <MultiPlayerVideo key={s.id} stream={s} />
              ))}
          </Grid>
        </Grid>
      </div>
    )
  }
)

MultiPlayerPanel.displayName = 'MultiPlayerPanel'
