import { Group, Shape } from 'react-konva'
import { useProject } from '../../ProjectWrapper'
import { useUseTimelineStore } from './Timeline'

export const TimelineBackground = () => {
  const project = useProject()
  const useTimelineStore = useUseTimelineStore()

  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)
  const height = useTimelineStore((state) => state.height)
  const width = useTimelineStore((state) => state.width)

  return (
    <Group
      clip={{
        x: leftColumnWidth,
        y: 0,
        width: width,
        height: height,
      }}
      listening={false}
    >
      <Shape
        sceneFunc={function drawTimelineBackground(context, shape) {
          const {
            leftColumnWidth,
            width,
            height,
            currentScale,
          } = useTimelineStore.getState()

          context.beginPath()

          project.videoArchiveDates.forEach((day, i) => {
            const startX = currentScale(day.systemStartDateTime)
            const endX = currentScale(day.systemEndDateTime)
            if (i % 2 && endX > leftColumnWidth && startX < width) {
              context.rect(startX, 0, endX - startX, height)
            }
          })

          context.closePath()
          // (!) Konva specific method, it is very important
          context.fillShape(shape)
        }}
        fill="rgba(128, 128, 128, 0.1)"
      />
    </Group>
  )
}
