import { tz } from '../../helpers/constants'
import { zonedTimeToUtc } from 'date-fns-tz'
import { addDays } from 'date-fns'

export function transformRect(
  rect: { x: number; y: number; w: number; h: number },
  transform: {
    rotation: number
    scaleX: number
    scaleY: number
    skewX: number
    skewY: number
    x: number
    y: number
  }
) {
  return {
    x: transform.x + rect.x * transform.scaleX,
    y: transform.y + rect.y * transform.scaleY,
    w: rect.w * transform.scaleX,
    h: rect.h * transform.scaleY,
  }
}

export function getDateRangeParams(
  searchParams: URLSearchParams,
  queryKeyStart: string,
  queryKeyEnd: string,
  timezone: string = tz
): [string | undefined, string | undefined] {
  return [
    searchParams.get(queryKeyStart)
      ? zonedTimeToUtc(searchParams.get(queryKeyStart)!, timezone).toISOString()
      : undefined,

    // initialReviewEndtime?: string,
    searchParams.get(queryKeyEnd)
      ? addDays(
          zonedTimeToUtc(searchParams.get(queryKeyEnd)!, timezone),
          1
        ).toISOString()
      : undefined,
  ]
}
