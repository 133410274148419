import { useTheme } from '@material-ui/core'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Group, Rect, RegularPolygon, Text } from 'react-konva'
import { useUseTimelineStore } from './Timeline'

export const TimelineCollapsibleSection: FC<{
  expandedTitle: string
  collapsedTitle?: string
  rowGroupIndex: number
  childRowCount: number
  childRowHeight?: number
  // onCollapse?: () => void
  // onExpand?: () => void
  initialCollpased?: boolean
  children: (collapsed: boolean) => ReactNode
}> = ({
  expandedTitle,
  collapsedTitle,
  rowGroupIndex,
  childRowCount,
  childRowHeight,
  initialCollpased = false,
  children,
}) => {
  const theme = useTheme()
  const useTimelineStore = useUseTimelineStore()
  const width = useTimelineStore((state) => state.width)
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)
  const rowHeight = useTimelineStore((state) => state.rowHeight)
  const scrollY = useTimelineStore((state) => state.scrollY)

  // This is just to trigger rerenders. Actual y is calculated from getRowGroupY
  // Could put it as a useEffect dep but meh
  const sectionHeights = useTimelineStore((state) => state.sectionHeights)

  const [collapsed, setCollapsed] = useState(initialCollpased)

  const rowGroupY = useTimelineStore.getState().getRowGroupY(rowGroupIndex)

  const onCollapse = () => {
    const _sectionHeights = useTimelineStore.getState().sectionHeights.slice()
    _sectionHeights[rowGroupIndex] = rowHeight
    useTimelineStore.setState({ sectionHeights: _sectionHeights })
  }
  const onExpand = () => {
    const _sectionHeights = useTimelineStore.getState().sectionHeights.slice()
    _sectionHeights[rowGroupIndex] =
      childRowCount * (childRowHeight || rowHeight) + rowHeight
    useTimelineStore.setState({ sectionHeights: _sectionHeights })
  }

  useEffect(() => {
    onExpand()
  }, [])

  useEffect(() => {
    if (collapsed) {
      onCollapse()
    } else {
      onExpand()
    }
  }, [collapsed, childRowCount])

  return (
    <Group x={0} y={rowGroupY + scrollY}>
      {/* Full width background */}
      <Rect
        x={0}
        y={0}
        width={width}
        height={rowHeight}
        onClick={() => setCollapsed(!collapsed)}
        fill={theme.palette.background.default}
      />

      {/* Child rows */}
      <Group x={0} y={collapsed ? 0 : rowHeight}>
        {children && children(collapsed)}
      </Group>

      {/* Left column background to cover up collapsed child row */}
      <Rect
        x={0}
        y={0}
        width={leftColumnWidth}
        height={rowHeight}
        fill={theme.palette.background.default}
        onClick={() => setCollapsed(!collapsed)}
      />

      {/* Triangle to display collapsed state */}
      <RegularPolygon
        x={rowHeight / 2}
        y={rowHeight / 2}
        sides={3}
        radius={rowHeight / 3}
        fill={theme.palette.text.primary}
        rotation={collapsed ? 90 : 180}
        listening={false}
      />

      {/* Section label */}
      <Text
        x={rowHeight}
        y={rowHeight / 6}
        text={collapsed && collapsedTitle ? collapsedTitle : expandedTitle}
        fill={theme.palette.text.primary}
        fontSize={rowHeight * 0.7}
        listening={false}
      />
    </Group>
  )
}
