import Konva from 'konva'
import React from 'react'
import { Group, Rect, Shape, Text } from 'react-konva'
import { useCallbackRef } from 'use-callback-ref'
import { ShoringBayRelation } from '../../../api/codegen/typescript-axios'
import { ShoringBayExtended } from '../../../api/ShoringBayExtended'
import { darkTheme } from '../../../styles/theme'
import { useProject } from '../../ProjectWrapper'
import { useUseTimelineStore } from '../Timeline/Timeline'
import { TimelineCollapsibleSection } from '../Timeline/TimelineCollapsibleSection'
import { TimelineRow } from '../Timeline/TimelineRow'
import { getUseVODStore } from '../VODController'
import { useShoringStore } from './VODShoringController'

const rowHeight = 14
const radius = rowHeight / 4
const milestoneWidth = rowHeight

type TimelineCoordinates = Map<
  number,
  { x0: number; y0: number; x1: number; y1: number }
>

export const TimelineShoring = ({
  rowGroupIndex,
}: {
  rowGroupIndex: number
}) => {
  const shoringStore = useShoringStore()
  const useTimelineStore = useUseTimelineStore()

  const selectedBayId = shoringStore((state) => state.selectedBayId)
  const baysMap = shoringStore((state) => state.bays)
  const relationshipsMap = shoringStore((state) => state.relationships)

  const currentScale = useTimelineStore((state) => state.currentScale)
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)
  const width = useTimelineStore((state) => state.width)
  const height = useTimelineStore((state) => state.height)
  const filterByTime = useTimelineStore((state) => state.filterByTime)

  // mutable map of frequently changing timeline coordinates for each bay,
  // so we don't thrash GC by creating new objects every frame
  const [timelineCoordinates] = React.useState<TimelineCoordinates>(new Map())

  const baysArray = Array.from(baysMap.values()).filter((bay) => {
    const hasTimeRange =
      bay.inferredSystemStartDateTime && bay.inferredSystemEndDateTime

    const isMilestone = !bay.startDateTime && !bay.endDateTime

    // shouldnt happen. Every bay should have an inferred time range now,
    // because of getLatestPredecesssorEndDateTime function
    if (!hasTimeRange) return false

    // some times will be zero because they don't have their own times and neither do any of their predecessors
    const x0 = bay.inferredSystemStartDateTime?.toMillis()
      ? Math.floor(currentScale(bay.inferredSystemStartDateTime!))
      : 0
    const x1 = bay.inferredSystemEndDateTime?.toMillis()
      ? Math.floor(currentScale(bay.inferredSystemEndDateTime!))
      : 0

    if (!timelineCoordinates.get(bay.id)) {
      timelineCoordinates.set(bay.id, { x0: 0, x1: 0, y0: 0, y1: 0 })
    }
    const bayCoordinates = timelineCoordinates.get(bay.id)!
    bayCoordinates.x0 = x0
    if (isMilestone) {
      bayCoordinates.x1 = x1 + milestoneWidth
    } else {
      bayCoordinates.x1 = x1
    }

    if (filterByTime) {
      // determine if will be visible in the viewport
      const visible = x0 < width && x1 > leftColumnWidth
      return visible
    }
    return true
  })

  return (
    <TimelineCollapsibleSection
      expandedTitle={'Shoring'}
      collapsedTitle={
        selectedBayId ? 'Shoring: ' + selectedBayId.toString() : 'Shoring'
      }
      rowGroupIndex={rowGroupIndex}
      childRowCount={baysArray?.length || 0}
      childRowHeight={rowHeight}
    >
      {(collapsed) => (
        <Group>
          {baysArray
            .filter((b) => {
              // if not collapsed, show all,
              // if collapse, show only the selected bay
              return !collapsed || b.id === selectedBayId
            })
            .map((bay, i) => {
              const bayCoordinates = timelineCoordinates.get(bay.id)
              if (!bayCoordinates) {
                // shouldnt happen. should be set by now
                timelineCoordinates.set(bay.id, { x0: 0, x1: 0, y0: 0, y1: 0 })
              } else {
                bayCoordinates.y0 = i * rowHeight
                bayCoordinates.y1 = (i + 1) * rowHeight
              }
              return (
                <TimelineRow
                  key={bay.id}
                  y={i * rowHeight}
                  i={i}
                  rowHeight={rowHeight}
                >
                  <ShoringRow
                    bay={bay}
                    timelineCoordinates={timelineCoordinates}
                  />
                </TimelineRow>
              )
            })}
          <Group
            clip={{
              x: leftColumnWidth,
              y: 0,
              width: width - leftColumnWidth,
              height: height,
            }}
          >
            {Array.from(relationshipsMap.values()).map((rel) => {
              return (
                <RelationshipLine
                  key={rel.id}
                  relationship={rel}
                  timelineCoordinates={timelineCoordinates}
                  highlighted={
                    selectedBayId === rel.predecessor ||
                    selectedBayId === rel.successor
                  }
                />
              )
            })}
          </Group>
        </Group>
      )}
    </TimelineCollapsibleSection>
  )
}

const ShoringRow = ({
  bay,
  timelineCoordinates,
}: {
  bay: ShoringBayExtended
  timelineCoordinates: TimelineCoordinates
}) => {
  const project = useProject()
  const useVODStore = getUseVODStore()
  const shoringStore = useShoringStore()
  const useTimelineStore = useUseTimelineStore()

  const selectedBayId = shoringStore((state) => state.selectedBayId)
  const hoveredBayId = shoringStore((state) => state.hoveredBayId)

  const width = useTimelineStore((state) => state.width)
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)

  const textRef = useCallbackRef<Konva.Text>(null, (text) => {
    if (text) {
      text.cache()
    }
  })

  return (
    <Group
      x={0}
      y={0}
      onMouseEnter={() => shoringStore.setState({ hoveredBayId: bay.id })}
      onMouseLeave={() => shoringStore.setState({ hoveredBayId: undefined })}
    >
      <Group
        clip={{
          x: 0,
          y: 0,
          width: leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={0}
          y={0}
          width={leftColumnWidth}
          height={rowHeight}
          onClick={() => {
            const shouldContinue = shoringStore.getState().onClickNode(bay.id)

            if (shouldContinue) {
              useVODStore.getState().gotoVideo({
                streamId: bay.streamCoordinates.keys().next().value,
                dateTime: bay.startDateTime,
              })
              shoringStore.setState({ selectedBayId: bay.id })
            }
          }}
        />
        <Text
          ref={textRef}
          text={`${bay.id} - ${bay.team}${bay.teamIndex}`}
          fill="#fff"
          x={3}
          y={1}
          fontSize={rowHeight}
          listening={false}
        />
      </Group>
      <Group
        clip={{
          x: leftColumnWidth,
          y: 0,
          width: width - leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={leftColumnWidth}
          y={0}
          width={width - leftColumnWidth}
          height={rowHeight}
          onClick={(e) => {
            const shouldContinue = shoringStore.getState().onClickNode(bay.id)

            if (shouldContinue) {
              useVODStore.getState().gotoVideo({
                streamId: bay.streamCoordinates.keys().next().value,
                dateTime: useTimelineStore
                  .getState()
                  .mouseDateTime?.setZone(project.timezone, {
                    keepLocalTime: true,
                  }),
              })
              shoringStore.setState({ selectedBayId: bay.id })
            }
          }}
        />
        <TimeBlock bay={bay} timelineCoordinates={timelineCoordinates} />
      </Group>
      {(selectedBayId === bay.id || hoveredBayId === bay.id) && (
        <Rect
          x={0}
          y={0}
          width={width}
          height={rowHeight}
          fill="rgba(255, 255, 255, 0.2)"
          listening={false}
        />
      )}
    </Group>
  )
}

const TimeBlock = React.memo(
  ({
    bay,
    timelineCoordinates,
  }: {
    bay: ShoringBayExtended
    timelineCoordinates: TimelineCoordinates
  }) => {
    const useTimelineStore = useUseTimelineStore()
    const shoringStore = useShoringStore()
    const nodeTypes = shoringStore((state) => state.nodeTypes)

    const isMilestone = !bay.startDateTime && !bay.endDateTime

    return (
      <>
        <Shape
          perfectDrawEnabled={false}
          sceneFunc={function renderDayblock(context, shape) {
            // get Timeline state
            const { width, leftColumnWidth } = useTimelineStore.getState()

            // calculate day width
            const x0 = timelineCoordinates.get(bay.id)?.x0 || leftColumnWidth
            const x1 = timelineCoordinates.get(bay.id)?.x1 || leftColumnWidth

            // determine if shoud be visible in the viewport
            const visible = x0 < width && x1 > leftColumnWidth

            context.beginPath()

            // day rect
            if (
              visible &&
              !isMilestone &&
              bay.startDateTime &&
              bay.endDateTime
            ) {
              context.rect(x0, 3, Math.max(x1 - x0, 2), rowHeight - 6)
            } else if (visible && !bay.startDateTime && bay.endDateTime) {
              context.moveTo(x1 - milestoneWidth / 2, milestoneWidth / 2)
              context.lineTo(x1, 0)
              context.lineTo(x1, milestoneWidth)
            } else if (visible && bay.startDateTime && !bay.endDateTime) {
              context.moveTo(x1, 0)
              context.lineTo(x1 + milestoneWidth / 2, milestoneWidth / 2)
              context.lineTo(x1, milestoneWidth)
            } else if (isMilestone) {
              context.moveTo(x0, milestoneWidth / 2)
              context.lineTo(x0 + milestoneWidth / 2, 0)
              context.lineTo(x0 + milestoneWidth, milestoneWidth / 2)
              context.lineTo(x0 + milestoneWidth / 2, milestoneWidth)
            }

            context.closePath()
            // (!) Konva specific method, it is very important
            context.fillShape(shape)
          }}
          fill={nodeTypes.get(bay.node_type)}
          listening={false}
        />
      </>
    )
  }
)

const RelationshipLine = React.memo(
  ({
    relationship,
    timelineCoordinates,
    highlighted,
  }: {
    relationship: ShoringBayRelation
    timelineCoordinates: TimelineCoordinates
    highlighted: boolean
  }) => {
    return (
      <>
        <Shape
          perfectDrawEnabled={false}
          sceneFunc={function renderDayblock(context, shape) {
            // This is the real render function. The enclosing react component doesnt rerender on scale change
            // This is why we get these coordinates inside this scope
            // Because if we get them in the parent function, they may return undefined and then never update inside sceneFunc
            const predecessorCoordinates = timelineCoordinates.get(
              relationship.predecessor
            )
            const successorCoordinates = timelineCoordinates.get(
              relationship.successor
            )

            if (!predecessorCoordinates || !successorCoordinates) {
              return
            }

            const x0 = predecessorCoordinates.x1 + 0.5
            const y0 = predecessorCoordinates.y0 + rowHeight / 2 + 0.5

            const x1 = successorCoordinates.x0 + 0.5
            const y1 = successorCoordinates.y0 + rowHeight / 2 + 0.5

            context.beginPath()

            context.moveTo(x0, y0)
            // The first arc coming out of the end of the predecessor
            context.arcTo(x0 + radius, y0, x0 + radius, y1, radius)

            if (x1 - x0 > radius * 3) {
              // the end of the predecessor is far enough from the start of the successor to draw a simple line
              // line down
              context.lineTo(x0 + radius, y1 - radius)
              // turn toward successor
              context.arcTo(x0 + radius, y1, x1, y1, radius)
              // line to successor
              context.lineTo(x1, y1)
            } else {
              // the end of the predecessor is too close to start of the successor. We need a more complex line
              const xVertical = Math.min(x0 - radius, x1 - radius * 2)
              // the arc turns backwards
              context.arcTo(
                x0 + radius,
                y0 + radius * 2,
                x0,
                y0 + radius * 2,
                radius
              )
              // line goes back to before the sucessor
              context.lineTo(xVertical + radius, y0 + radius * 2)
              // the next arc turns down
              context.arcTo(
                xVertical,
                y0 + radius * 2,
                xVertical,
                y0 + radius * 3,
                radius
              )
              // line goes down to successor
              context.lineTo(xVertical, y1 - radius)
              // arc turns into successor
              context.arcTo(xVertical, y1, xVertical + radius, y1, radius)
              // line connects to successor
              context.lineTo(x1, y1)
            }

            // (!) Konva specific method, it is very important
            context.strokeShape(shape)
          }}
          stroke={highlighted ? '#fff' : '#777'}
          strokeWidth={highlighted ? 3 : 1}
          opacity={highlighted ? 1 : 1}
          listening={false}
        />
      </>
    )
  }
)
