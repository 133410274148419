import { TextField, Typography, useTheme } from '@material-ui/core'
import { DateRangeDelimiter, DateRangePicker } from '@material-ui/pickers'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { tz } from '../../helpers/constants'

export const DateRangeInput = ({
  label,
  timezone = tz,
  startQueryKey,
  endQueryKey,
  datesArray,
  minimal = false,
  className,
}: {
  label: string
  timezone?: string
  startQueryKey: string
  endQueryKey: string
  datesArray?: string[]
  minimal?: boolean
  className?: string
}) => {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const startDateParam = searchParams.get(startQueryKey)
  const endDateParam = searchParams.get(endQueryKey)

  return (
    <>
      {!minimal && (
        <Typography variant="body1" style={{ marginBottom: 12 }}>
          {label}
        </Typography>
      )}
      <DateRangePicker
        startText="Start"
        endText="End"
        value={[
          startDateParam ? startDateParam : null,
          endDateParam ? endDateParam : null,
        ]}
        onChange={(d) => {
          if (d[0]) {
            searchParams.set(startQueryKey, d[0])
          }
          if (d[1]) {
            searchParams.set(endQueryKey, d[1])
          }
          setSearchParams(searchParams, { replace: true })
        }}
        minDate={datesArray ? datesArray[0] : undefined}
        maxDate={datesArray ? datesArray[datesArray.length - 1] : undefined}
        renderInput={(startProps, endProps) =>
          minimal ? (
            <>
              <TextField
                {...startProps}
                variant="filled"
                helperText={null}
                style={{ backgroundColor: theme.palette.background.paper }}
              />
              {<span style={{ width: 10 }} />}
              <TextField
                {...endProps}
                variant="filled"
                helperText={null}
                style={{ backgroundColor: theme.palette.background.paper }}
              />
            </>
          ) : (
            <>
              <TextField {...startProps} />
              <DateRangeDelimiter> to </DateRangeDelimiter>
              <TextField {...endProps} />
            </>
          )
        }
        className={className}
      />
    </>
  )
}
