import { OutlinedTextFieldProps, TextField } from '@material-ui/core'
import { omit } from 'lodash'
import React from 'react'
import { useDebounce } from 'react-use'

interface Props extends OutlinedTextFieldProps {
  onDebouncedChange: (value: string) => void
}

export const DebouncedTextField = (props: Props) => {
  const propsValue = (props.value as string).toString() || ''

  const [value, setValue] = React.useState(propsValue)

  useDebounce(
    () => {
      if (propsValue !== value) {
        props.onDebouncedChange((value as string).toString() || '')
      }
    },
    1000,
    [value]
  )

  React.useEffect(() => {
    if (propsValue !== value) {
      setValue(propsValue)
    }
  }, [propsValue])

  return (
    <TextField
      {...omit(props, 'onDebouncedChange')}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}
