import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import ScheduleIcon from '@material-ui/icons/Schedule'
import React from 'react'
import { getUseVODStore } from '../VODController'
import { CranePickListView } from './CranePickListView'
import { useCraneStore } from './CranePicksController'
import { CranePicksScheduleView } from './CranePicksScheduleView'

export const CranePicksPanel = ({ autoScroll }: { autoScroll: boolean }) => {
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()

  const [viewState, setViewState] = React.useState<'List' | 'Schedule'>(
    'Schedule'
  )

  return (
    <div
      style={{
        padding: '12px 6px 0',
        height: '100%',
        overflowX: 'hidden',
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Grid
        container
        style={{ height: '100%' }}
        spacing={2}
        direction={'column'}
      >
        <Grid item container spacing={1} alignItems="center">
          <Grid item style={{ flexGrow: 1 }}>
            <Typography variant="h5">Crane Picks</Typography>
          </Grid>
          <Grid item>
            {(['List', 'Schedule'] as const).map((type) => (
              <Tooltip title={type + ' view'} key={type}>
                <IconButton
                  key={type}
                  color={viewState === type ? 'primary' : 'default'}
                  size="small"
                  onClick={() => {
                    setViewState(type)
                  }}
                >
                  {type === 'List' ? <ListIcon /> : <ScheduleIcon />}
                </IconButton>
              </Tooltip>
            ))}
          </Grid>
        </Grid>

        <Divider />

        {viewState === 'List' ? (
          <CranePickListView />
        ) : (
          <CranePicksScheduleView autoScroll={autoScroll} />
        )}
      </Grid>
    </div>
  )
}
