import Konva from 'konva'
import log from 'loglevel'
import React, { useEffect, useRef, useState } from 'react'
import { Layer } from 'react-konva'
import { useKey } from 'react-use'
import { UseStore } from 'zustand'
import { ImageSessionDetails } from '../../../api/codegen/typescript-axios'
import { Mode } from '../Annotator'
import { AnnotatorState } from '../AnnotatorFetcher'
import { useStageContext } from '../VideoCanvas'
import { MaskPolygon } from './MaskPolygon'
import { NewPolygon } from './NewPolygon'

export const MaskCanvas = ({
  session,
  mode,
  useStore,
}: {
  session: ImageSessionDetails
  mode: Mode
  useStore: UseStore<AnnotatorState>
}) => {
  const { absoluteTransform } = useStageContext()
  const layerRef = useRef<Konva.Layer>(null)

  const polygons = useStore((state) => state.maskPolygons)
  const [editIndex, setEditIndex] = useState(-1)

  useKey(
    'Backspace',
    () => {
      if (polygons && editIndex > -1) {
        log.debug(`Delete polygon${editIndex}`)
        polygons.splice(editIndex, 1)
        const newPolygons = polygons.slice()
        setEditIndex(-1)
        useStore.setState({ maskPolygons: newPolygons })
      }
    },
    undefined,
    [editIndex, polygons, useStore]
  )

  useEffect(() => {
    if (mode !== 'selectMask') {
      setEditIndex(-1)
    }
  }, [mode, setEditIndex])

  return (
    <Layer ref={layerRef} {...absoluteTransform.decompose()}>
      {mode === 'drawMask' && (
        <NewPolygon
          enabled={true}
          onCreatePolygon={(polygon) => {
            if (!polygons) return
            log.debug(`New polygon`, polygon)
            const newPolygons = polygons.slice()
            newPolygons.push(polygon)
            useStore.setState({ maskPolygons: newPolygons })
          }}
        />
      )}
      {polygons?.map((p, i) => (
        <MaskPolygon
          key={i}
          polygon={p}
          editable={mode === 'selectMask'}
          editing={editIndex === i}
          onClick={() => {
            log.debug(`setEditIndex(${i})`)
            setEditIndex(i)
          }}
          onEdit={(newPolygon) =>
            useStore.setState({ maskPolygons: polygons.slice() })
          }
        />
      ))}
    </Layer>
  )
}
