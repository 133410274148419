import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Select,
  Slider,
  Tooltip,
  useTheme,
} from '@material-ui/core'
import FastForwardIcon from '@material-ui/icons/FastForward'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import React, { memo, useEffect } from 'react'
import { MdVideoStable } from 'react-icons/md'
import { useKey, useLocalStorage } from 'react-use'
import useVideoState from '../../hooks/useVideoState'
import { useKeyboardShortcutsStore } from '../KeyboardShortcutsController'
import { useProject } from '../ProjectWrapper'
import { KeyedButton } from '../Video/KeyedButton'
import { getUseVODStore } from './VODController'
import { VODDateTimeSelect } from './VODDateTimeSelect'
import { VODSteamSelect } from './VODStreamSelect'

export const speeds = [0.5, 1, 2, 4, 10, 20, 50, 100, 200]

export const VODControls = memo(() => {
  const useVODStore = getUseVODStore()
  const project = useProject()
  const theme = useTheme()

  const videoRef = useVODStore((state) => state.videoRef)
  const video = useVODStore((state) => state.video)
  const videoStreamId = useVODStore((state) => state.videoStreamId)
  const speed = useVODStore((state) => state.speed)
  const zoomMode = useVODStore((state) => state.zoomMode)
  const gotoVideo = useVODStore((state) => state.gotoVideo)
  const preferStabilized = useVODStore((state) => state.preferStabilized)

  const [playState, play, pause] = useVideoState(videoRef.current)

  const [_seekIncrement, setSeekIncrement] = useLocalStorage(
    'VODControls-seekIncrement',
    5
  )
  const [showSeekIncrementSlider, setShowSeekIncrementSlider] = React.useState(
    false
  )
  React.useEffect(() => {
    if (!seekIncrement) {
      setSeekIncrement(5)
    }
  }, [_seekIncrement])

  const seekIncrement = _seekIncrement || 5

  useEffect(() => {
    useVODStore.setState({ videoPlaying: playState })
  }, [playState])

  useKey(
    'ArrowUp',
    (e) => {
      e.preventDefault()
      const fasterSpeedIndex = speeds.indexOf(speed) + 1
      if (fasterSpeedIndex < speeds.length) {
        gotoVideo({
          speed: speeds[fasterSpeedIndex],
        })
      }
    },
    undefined,
    [speed]
  )

  useKey(
    'ArrowDown',
    (e) => {
      e.preventDefault()
      const slowerSpeedIndex = speeds.indexOf(speed) - 1
      if (slowerSpeedIndex >= 0) {
        gotoVideo({
          speed: speeds[slowerSpeedIndex],
        })
      }
    },
    undefined,
    [speed]
  )

  const keyboardShortcutsStore = useKeyboardShortcutsStore()
  React.useEffect(() => {
    keyboardShortcutsStore.getState().set('⬆', 'Speed up')
    keyboardShortcutsStore.getState().set('⬇', 'Slow down')

    return () => {
      keyboardShortcutsStore.getState().delete('⬆')
      keyboardShortcutsStore.getState().delete('⬇')
    }
  }, [])

  return (
    <div
      style={{
        flexWrap: 'wrap',
        padding: theme.spacing(1),
      }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <VODSteamSelect />
          <KeyedButton
            tooltip={playState ? 'Pause' : 'Play'}
            keyCode=" "
            dictionaryEntry={['Space', 'Play/Pause']}
            icon={playState ? <PauseIcon /> : <PlayArrowIcon />}
            onClick={() => {
              if (playState) {
                pause()
              } else {
                play()
              }
            }}
          />

          <KeyedButton
            tooltip={`Skip back`}
            keyCode="ArrowLeft"
            dictionaryEntry={['⬅', 'Skip back']}
            icon={<FastRewindIcon />}
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.currentTime =
                  videoRef.current?.currentTime - seekIncrement
              }
            }}
          />

          {/* Seek increment control */}
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              width: 28,
              height: 30,
              paddingTop: 5,
              cursor: 'pointer',
            }}
            onMouseEnter={() => setShowSeekIncrementSlider(true)}
            onMouseLeave={() => setShowSeekIncrementSlider(false)}
            onClick={() => setShowSeekIncrementSlider(!showSeekIncrementSlider)}
          >
            <Tooltip
              title="Skip increment. (Click to adjust)"
              placement="right"
            >
              <>{seekIncrement}s</>
            </Tooltip>
            {showSeekIncrementSlider && (
              <Paper
                elevation={0}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: 100,
                  transform: 'translateY(-100%)',
                  padding: '10px 0',
                }}
              >
                <Slider
                  orientation="vertical"
                  value={seekIncrement}
                  onChange={(event: any, newValue: number | number[]) =>
                    setSeekIncrement(newValue as number)
                  }
                  getAriaValueText={(value) => value + 's'}
                  defaultValue={seekIncrement}
                  color="secondary"
                  max={60}
                  min={1}
                  aria-labelledby="Seek increment"
                />
              </Paper>
            )}
          </div>

          <KeyedButton
            tooltip={`Skip forward`}
            keyCode="ArrowRight"
            dictionaryEntry={['⮕', 'Skip forward']}
            icon={<FastForwardIcon />}
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.currentTime =
                  videoRef.current?.currentTime + seekIncrement
              }
            }}
          />
        </Grid>
        <Grid item>
          <VODDateTimeSelect />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <KeyedButton
            tooltip={'Toggle video zoom mode'}
            keyCode="z"
            dictionaryEntry={['Z', 'Toggle video zoom mode']}
            text="Zoom mode"
            onClick={() => {
              useVODStore.setState({ zoomMode: !zoomMode })
            }}
            color={zoomMode ? 'primary' : 'default'}
            style={{ whiteSpace: 'nowrap' }}
          />
        </Grid>
        <Grid item>
          <Button
            title="Play stabilized video if available"
            variant="contained"
            size="small"
            style={{ height: 32, minWidth: 0 }}
            color={preferStabilized ? 'primary' : 'default'}
            onClick={() => {
              const {
                videoDateTime,
                preferStabilized,
                gotoVideo,
              } = useVODStore.getState()
              useVODStore.setState({ preferStabilized: !preferStabilized })
              gotoVideo({
                dateTime: videoDateTime,
              })
            }}
          >
            <MdVideoStable style={{ height: 22 }} size={24} />
          </Button>
        </Grid>
        <Grid item>
          <span style={{ margin: '0 10px' }}>Speed:</span>
          <ButtonGroup>
            {speeds.map((r) => (
              <Button
                key={r}
                variant="contained"
                color={speed === r ? 'primary' : 'default'}
                size="small"
                onClick={() => gotoVideo({ speed: r })}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  minWidth: 0,
                }}
              >
                {r}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  )
})

VODControls.displayName = 'VODControls'
