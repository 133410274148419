import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import clsx from 'clsx'
import React from 'react'
import { AsyncState } from 'react-use/lib/useAsync'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { display: 'flex', alignItems: 'center' },
    vertical: { flexDirection: 'column', alignItems: 'flex-start' },
    error: { marginLeft: 20 },
    verticalError: { marginLeft: 0, marginTop: 20 },
  })
)

export const ProgressButton = (props: Props) => {
  const {
    fetchState,
    disabled,
    children,
    className,
    buttonClassname,
    vertical = false,
    ...rest
  } = props
  const classes = useStyles()

  if (fetchState?.error) {
    console.log(fetchState.error)
  }

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.vertical]: vertical },
        className
      )}
    >
      <Button
        variant="contained"
        color="primary"
        disabled={fetchState.loading || disabled}
        style={{ position: 'relative' }}
        className={buttonClassname}
        {...rest}
      >
        {children}
        {fetchState.loading && (
          <CircularProgress
            size={24}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </Button>
      {fetchState.error && (
        <Typography
          color="error"
          className={clsx(classes.error, { [classes.verticalError]: vertical })}
        >
          {fetchState.error.message}
        </Typography>
      )}
    </div>
  )
}

interface Props extends ButtonProps {
  disabled?: boolean
  fetchState: AsyncState<any>
  buttonClassname?: string
  vertical?: boolean
}
