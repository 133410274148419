import { createStyles, makeStyles } from '@material-ui/core'
import bearing from '@turf/bearing'
import distance from '@turf/distance'
import { point } from '@turf/helpers'
import { isFinite, uniqBy } from 'lodash'
import React from 'react'
import { Photosphere } from '../../api/codegen/typescript-axios'
import { mixins } from '../../styles/mixins'
import { KRPanoHtmlHotspot } from './KRPanoHtmlHotspot'

const useStyles = makeStyles(() =>
  createStyles({
    '@keyframes circle': {
      from: { opacity: 1, transform: 'scale(0)' },
      to: { opacity: 0, transform: 'scale(2)' },
    },
    hotspot: {
      pointerEvents: 'all',
      transition: 'transform 0.3s',
      transformOrigin: 'top left',
      cursor: 'pointer',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.5)',
        zIndex: 2,
      },
    },
    circles: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      position: 'relative',
      top: -10,
      left: -10,
    },
    whiteCircle: {
      ...mixins.absoluteFill,
      backgroundColor: '#fff',
      transform: 'scale(0)',
      borderRadius: '50%',
      animationName: '$circle',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
    },
    blackCircle: {
      ...mixins.absoluteFill,
      backgroundColor: '#000',
      transform: 'scale(0)',
      borderRadius: '50%',
      animationName: '$circle',
      animationDuration: '2s',
      animationDelay: '1s',
      animationIterationCount: 'infinite',
    },
    label: {
      whiteSpace: 'nowrap',
      position: 'absolute',
      top: 0,
      left: 0,
      transformOrigin: 'top left',
      transform: 'rotateZ(-45deg) translateY(-100%) ',
      height: 22,
      borderRadius: '11px 11px 11px 0',
      padding: '0 4px',
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      backgroundColor: '#000',
    },
  })
)

export const PhotosphereHotspots = ({
  spheres,
  currentSphere,
  onClickSphere,
}: {
  spheres?: Photosphere[]
  currentSphere?: Photosphere
  onClickSphere: (id: number) => void
}) => {
  const classes = useStyles()

  if (
    !currentSphere ||
    !currentSphere.latitude ||
    !currentSphere.longitude ||
    typeof currentSphere?.bearing !== 'number' ||
    !isFinite(currentSphere?.bearing) ||
    !spheres
  ) {
    return null
  }

  const currentSphereBearing = currentSphere?.bearing

  const currentSpherePoint = point([
    currentSphere.longitude,
    currentSphere.latitude,
  ])

  const otherSpheres = uniqBy(spheres, (sphere) => sphere.template)
    .filter(
      (sphere) =>
        sphere.longitude && sphere.latitude && sphere.id !== currentSphere?.id
    )
    .map((sphere) => {
      const otherSpherePoint = point([
        sphere.longitude || 0,
        sphere.latitude || 0,
      ])

      const absoluteBearing = bearing(currentSpherePoint, otherSpherePoint)
      const relativeBearing = (absoluteBearing - currentSphereBearing) % 360
      const _distance = distance(currentSpherePoint, otherSpherePoint)

      return {
        sphere: sphere,
        relativeBearing,
        distance: _distance,
      }
    })
    .sort((sphereA, sphereB) => {
      return sphereA.relativeBearing - sphereB.relativeBearing
    })
    .map((sphere, i, spheres) => {
      if (
        i > 0 &&
        sphere.relativeBearing - spheres[i - 1].relativeBearing < 6
      ) {
        const prevBearing = spheres[i - 1].relativeBearing
        spheres[i - 1].relativeBearing = prevBearing - 3
        sphere.relativeBearing = prevBearing + 3
      }
      return sphere
    })

  return (
    <>
      {otherSpheres.map((sphere) => {
        return (
          <KRPanoHtmlHotspot
            key={sphere.sphere.id}
            atv={0}
            ath={sphere.relativeBearing}
          >
            <div
              onClick={() => onClickSphere(sphere.sphere.id)}
              className={classes.hotspot}
            >
              <div
                className={classes.circles}
                style={{
                  transform: `scale(${Math.min(5, 0.1 / sphere.distance)})`,
                }}
              >
                <div className={classes.whiteCircle}></div>
                <div className={classes.blackCircle}></div>
              </div>
              <div className={classes.label}>{sphere.sphere.title}</div>
            </div>
          </KRPanoHtmlHotspot>
        )
      })}
    </>
  )
}
