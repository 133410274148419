import FileCopyIcon from '@material-ui/icons/FileCopy'
import { DateTime } from 'luxon'
import { memo, useEffect, useRef } from 'react'
import { useCopyToClipboard } from 'react-use'
import { KeyedButton } from '../Video/KeyedButton'
import { getUseVODStore, vodQueryKeys } from './VODController'

export const VODClock = memo(() => {
  const [, copyToClipboard] = useCopyToClipboard()
  const textRef = useRef<string>('')
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const useVODStore = getUseVODStore()

  useEffect(
    () =>
      useVODStore.subscribe<DateTime>(
        (videoDateTime) => {
          if (!videoDateTime) return

          const currentContent = textRef.current
          const nextContent = videoDateTime.toFormat('LLL dd yy HH:mm:ss')
          if (nextContent !== currentContent) {
            textRef.current = nextContent

            const ctx = canvasRef.current?.getContext('2d')
            if (ctx) {
              ctx.clearRect(0, 0, 444, 56)
              ctx.font = '40px Roboto Mono'
              ctx.textBaseline = 'top'
              ctx.fillStyle = '#fff'
              ctx.fillText(nextContent, 0, 9)
            }
          }
        },
        (state) => state.videoDateTime
      ),
    []
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          color: '#fff',
          height: '1.1em',
          padding: '0 4px',
          fontSize: 27,
          fontFamily: 'Courier New',
          lineHeight: 1.15,
          backgroundColor: '#000',
          borderRadius: 4,
          border: '1px solid #444',
          whiteSpace: 'nowrap',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: 222,
            height: 28,
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              display: 'inline-block',
              width: 222,
              height: 28,
              overflow: 'hidden',
              transform: 'translate3d(0, 0, 0)',
            }}
            width="444"
            height="56"
          ></canvas>
        </div>
        <KeyedButton
          style={{
            transform: 'scale(0.8)',
            color: '#fff',
          }}
          tooltip={'Copy current video time'}
          keyCode="c"
          dictionaryEntry={['C', 'Copy current video time']}
          icon={<FileCopyIcon />}
          onClick={() => {
            copyToClipboard(
              useVODStore
                .getState()
                .videoDateTime.startOf('second')
                .toISO({ suppressMilliseconds: true })
            )
          }}
        />
      </div>
      <KeyedButton
        tooltip={'Copy link at current time'}
        keyCode="u"
        dictionaryEntry={['U', 'Copy link at current time']}
        text={'Copy link'}
        icon={<FileCopyIcon />}
        margin
        onClick={() => {
          const searchParams = new URLSearchParams(window.location.search)
          searchParams.delete('date')
          searchParams.set(
            vodQueryKeys.dateTime,
            useVODStore
              .getState()
              .videoDateTime.toISO({ suppressMilliseconds: true })
          )
          const search = searchParams.toString()
          copyToClipboard(
            `${window.location.origin}${window.location.pathname}?${search}`
          )
        }}
      />
    </div>
  )
})

VODClock.displayName = 'VODClock'
