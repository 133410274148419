import { makeStyles } from '@material-ui/core/styles'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import zustand, { UseStore } from 'zustand'
import { createCtx } from '../../helpers/createCtx'

export interface StoreState {
  syncViews: boolean
  masterView?: {
    hlookat: number
    vlookat: number
    fov: number
  }
  masterPhotosphereIndex?: number
}

export type UsePhotospherePageStore = UseStore<StoreState>

export const [
  usePhotospherePageStore,
  PhotospherePageStoreContext,
] = createCtx<UsePhotospherePageStore>()

export const PhotospherePageProvider: FC = ({ children }) => {
  const [searchParams] = useSearchParams()
  const hlookat = Number(searchParams.get('hlookat'))
  const vlookat = Number(searchParams.get('vlookat'))
  const fov = Number(searchParams.get('fov'))

  // init store
  const [store] = useState<UsePhotospherePageStore>(() =>
    zustand<StoreState>((set, get) => ({
      syncViews: true,
      masterView:
        hlookat || vlookat || fov ? { hlookat, vlookat, fov } : undefined,
      masterPhotosphereIndex: undefined,
    }))
  )

  return (
    <PhotospherePageStoreContext.Provider value={store}>
      {children}
    </PhotospherePageStoreContext.Provider>
  )
}
