import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { select as d3select } from 'd3-selection'
import { zoom as d3zoom, ZoomBehavior } from 'd3-zoom'
import Konva from 'konva'
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Image, Layer, Stage } from 'react-konva'
import { useMeasure } from 'react-use'
import { useCallbackRef, useMergeRefs } from 'use-callback-ref'
import { ReactRef } from 'use-callback-ref/dist/es5/types'
import useImage from 'use-image'
import { XYWH } from '../../../types'
import { ImageAnnotationBox } from '../../api/codegen/typescript-axios'
import { createCtx } from '../../helpers/createCtx'
import { getContainTransform } from '../../helpers/getContainTransform'
import useRefCurrent from '../../hooks/useRefCurrent'
import { mixins } from '../../styles/mixins'
import { Mode } from './Annotator'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: '#000',
        ...mixins.absoluteFill,
      },
      drawMode: {
        cursor: 'crosshair',
      },
    }),
  {
    name: 'VideoCanvas',
  }
)

export const [useStageContext, StageContext] = createCtx<{
  absoluteTransform: Konva.Transform
  clamp: (
    point: Konva.Vector2d,
    dimensions?: Konva.Vector2d
  ) => {
    x: number
    y: number
  }
  width: number
  height: number
  imageNaturalWidth: number
  imageNaturalHeight: number
}>()

// This component handles image and video display,
// zoom/pan gestures,
// and other events attached to canvas root (Stage)
export const VideoCanvas: FunctionComponent<{
  imageUrl: string
  setSelectedBox: (b?: ImageAnnotationBox) => void
  videoEl?: HTMLVideoElement | null
  showVideo?: boolean
  mode: Mode
  listening: boolean
  onClick?: () => void
  scaleExtent: [number, number]
  setVideoViewport?: (box: XYWH) => void
  targetViewport?: XYWH
}> = React.memo(
  ({
    children,
    imageUrl,
    setSelectedBox,
    videoEl,
    showVideo,
    mode,
    listening,
    onClick,
    scaleExtent,
    setVideoViewport,
    targetViewport,
  }) => {
    const classes = useStyles()
    // console.log('render VideoCanvas')

    // load image and get natural dims
    const [htmlImage] = useImage(imageUrl)
    const imageNaturalWidth = htmlImage?.naturalWidth || 1
    const imageNaturalHeight = htmlImage?.naturalHeight || 1
    const [imageRef, imageRefCurrent] = useRefCurrent<Konva.Image>(null)

    // @ts-ignore
    // Store zoom transform in state (possible to update transform without rerender?)
    const [zoomTransform, setZoomTransform] = useState(new Konva.Transform())

    // create zoom behavior
    const zoomRef = useRef<ZoomBehavior<Element, unknown>>(
      d3zoom()
        .scaleExtent(scaleExtent)
        .on('zoom', (e: any) => {
          const t = e.transform
          // @ts-ignore
          setZoomTransform(new Konva.Transform([t.k, 0, 0, t.k, t.x, t.y]))
        })
    )

    // attach zoom behavior to container
    const zoomingRef = useCallbackRef<HTMLDivElement>(null, (containerEl) => {
      if (containerEl) {
        const selection = d3select(containerEl as Element)
        selection.call(zoomRef.current).on('dblclick.zoom', null)
      }
    })

    // set translate extent based on container size and on every resize
    const [measuringRef, containerDims] = useMeasure<HTMLDivElement>()
    useEffect(() => {
      const x = (1 - scaleExtent[0]) / 2
      zoomRef.current.translateExtent([
        [-containerDims.width * x, -containerDims.height * x],
        [containerDims.width * (1 + x), containerDims.height * (1 + x)],
      ])
    }, [containerDims])

    // merge measuring ref and zoom ref to attach both to same container
    const mergedRef = useMergeRefs<HTMLDivElement>([
      measuringRef as ReactRef<HTMLDivElement>,
      zoomingRef,
    ])

    // base transform
    const baseTransform = getContainTransform({
      parent: { w: containerDims.width, h: containerDims.height },
      child: { w: imageNaturalWidth, h: imageNaturalHeight },
    })

    const absoluteTransform = new Konva.Transform()
      .multiply(zoomTransform)
      .multiply(baseTransform)

    if (setVideoViewport && videoEl) {
      const videoInvertTransform = absoluteTransform.copy().invert()

      let { x: x0, y: y0 } = videoInvertTransform.point({ x: 0, y: 0 })
      let { x: x1, y: y1 } = videoInvertTransform.point({
        x: containerDims.width,
        y: containerDims.height,
      })
      const x = Math.floor(Math.max(x0, 0))
      const y = Math.floor(Math.max(y0, 0))
      const w = Math.floor(Math.min(x1, videoEl.videoWidth)) - x
      const h = Math.floor(Math.min(y1, videoEl.videoHeight)) - y
      setVideoViewport({
        x,
        y,
        w,
        h,
      })
    }

    const decomposed = absoluteTransform.decompose()

    useEffect(() => {
      let anim: Konva.Animation
      if (showVideo && imageRefCurrent) {
        const layer = imageRefCurrent.getLayer()
        anim = new Konva.Animation(() => {}, layer)
        anim.start()
      }
      return () => {
        if (anim) {
          anim.stop()
        }
      }
    }, [showVideo, imageRefCurrent])

    const clamp = useMemo(
      () => (
        point: Konva.Vector2d,
        dimensions: Konva.Vector2d = { x: 0, y: 0 }
      ) => ({
        x: Math.min(imageNaturalWidth - dimensions.x, Math.max(point.x, 0)),
        y: Math.min(imageNaturalHeight - dimensions.y, Math.max(point.y, 0)),
      }),
      [imageNaturalWidth, imageNaturalHeight]
    )

    return (
      <div
        className={clsx(classes.root, { [classes.drawMode]: mode === 'draw' })}
        ref={mergedRef}
        onClick={onClick}
      >
        <Stage
          width={containerDims.width}
          height={containerDims.height}
          strokeScaleEnabled={true}
          listening={listening}
          onMouseDown={(e: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              e.target === e.target.getStage() ||
              e.target instanceof Konva.Image
            ) {
              // console.log('onmousedown stage or image')
              setSelectedBox()
            } else {
              // If we mousedown on a rect, we must prevent the d3 zoom gesture from starting
              // console.log('onmousedown box')
              e.evt.stopPropagation()
            }
          }}
        >
          <StageContext.Provider
            value={{
              absoluteTransform,
              clamp,
              width: containerDims.width,
              height: containerDims.height,
              imageNaturalWidth,
              imageNaturalHeight,
            }}
          >
            <Layer {...decomposed}>
              {htmlImage && (
                <Image
                  image={videoEl && showVideo ? videoEl : htmlImage}
                  ref={imageRef}
                  x={0}
                  y={0}
                  width={imageNaturalWidth}
                  height={imageNaturalHeight}
                />
              )}
            </Layer>
            {children}
          </StageContext.Provider>
        </Stage>
      </div>
    )
  }
)
