import { Typography, useTheme } from '@material-ui/core'
import { Group } from '@visx/group'
import { Pie } from '@visx/shape'
import { Text } from '@visx/text'
import { useTranslation } from 'react-i18next'
import { SubcontractorStats } from './useSubcontractorStats'
import React from 'react'
import { useCraneStore } from './CranePicksController'
import { Duration } from 'luxon'

export const CraneReportsSubcontractorsPie = ({
  subcontractorStats,
}: {
  subcontractorStats: SubcontractorStats
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const craneStore = useCraneStore()
  const projectConfig = craneStore((state) => state.projectConfig)
  const supercategories = craneStore((state) => state.supercategories)

  let data = subcontractorStats.map((subcontractor) => ({
    name: subcontractor.name || 'Unspecified',
    totalDuration: subcontractor.totalDuration,
    color: subcontractor.color,
  }))

  if (projectConfig?.consolidate_supercategories_in_pie_chart) {
    data = Array.from(supercategories).map((supercategory) => {
      const supercategorySubs = subcontractorStats.filter(
        (s) => s.supercategory === supercategory
      )
      return {
        name: supercategory,
        totalDuration: Duration.fromMillis(
          supercategorySubs.reduce(
            (acc, sub) => acc + sub.totalDuration.toMillis(),
            0
          )
        ),
        color:
          supercategorySubs.length === 1
            ? supercategorySubs[0].color
            : theme.palette.secondary.main,
      }
    })
  }

  return (
    <div>
      <svg
        width={'100%'}
        height={200}
        style={{
          minWidth: 200,
        }}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid meet"
      >
        <Pie
          top={50}
          left={50}
          data={data}
          pieValue={(d) => d.totalDuration.toMillis()}
          innerRadius={10}
          outerRadius={50}
          padAngle={0.1}
          padRadius={10}
        >
          {({ arcs, path }) => (
            <Group top={50} left={50}>
              {arcs.map((arc, i) => (
                <g key={`pie-arc-${i}`}>
                  <path d={path(arc) || ''} fill={arc.data.color} />
                </g>
              ))}
              {arcs.map((arc, i) => (
                <g key={`pie-arc-text-${i}`}>
                  <Text
                    x={path.centroid(arc)[0]}
                    y={path.centroid(arc)[1]}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    fill={'#fff'}
                    stroke={'rgba(0,0,0,0.3)'}
                    strokeWidth={1}
                    fontSize={8}
                    fontWeight="bold"
                  >
                    {`${(
                      (Math.abs(arc.endAngle - arc.startAngle) /
                        (Math.PI * 2)) *
                      100
                    ).toFixed(0)}%`}
                  </Text>
                  <Text
                    x={path.centroid(arc)[0]}
                    y={path.centroid(arc)[1]}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    fill={'#fff'}
                    fontSize={8}
                    fontWeight="bold"
                  >
                    {`${(
                      (Math.abs(arc.endAngle - arc.startAngle) /
                        (Math.PI * 2)) *
                      100
                    ).toFixed(0)}%`}
                  </Text>
                </g>
              ))}
            </Group>
          )}
        </Pie>
      </svg>
      <div>
        <Typography variant="h6">{t('subcontractors')}</Typography>

        {data
          .filter((s) => s.totalDuration.toMillis() > 0)
          .map((subcontractor) => (
            <div
              key={subcontractor.name}
              style={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography
                variant="body1"
                style={{ textAlign: 'center', fontSize: 14 }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: subcontractor.color,
                    marginRight: 8,
                    WebkitPrintColorAdjust: 'exact',
                  }}
                ></span>
                {subcontractor.name || 'Unspecified'}
              </Typography>
            </div>
          ))}
      </div>
    </div>
  )
}
