import { Button, ButtonGroup, useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage, useWindowSize } from 'react-use'
import { useContextBridge } from '../../hooks/useContextBridge'
import { useMe } from '../../hooks/useMe'
import { useMobile } from '../../hooks/useMobile'
import { usePanels } from '../../hooks/usePanels'
import { useTouchDevice } from '../../hooks/useTouchDevice'
import { ApiContext } from '../ApiContext'
import { DragResizeBox } from '../DragResizeBox'
import { AnalysesController } from './AnalysesController'
import { MultiPlayerPanel } from './MultiPlayerPanel'
import { ShoringDrawingOverlay } from './Shoring/ShoringDrawingOverlay'
import { ShoringTable } from './Shoring/ShoringTable'
import { TimelineShoring } from './Shoring/TimelineShoring'
import {
  ShoringContext,
  VODShoringController,
} from './Shoring/VODShoringController'
import { VODShoringDetailPanel } from './Shoring/VODShoringDetailPanel'
import { VODShoringVideoOverlay } from './Shoring/VODShoringVideoOverlay'
import { Timeline } from './Timeline/Timeline'
import { TimelineClips } from './Timeline/TimelineClips'
import { TimelineStreams } from './Timeline/TimelineStreams'
import { UploadsContext, UploadsController } from './Uploads/UploadsController'
import { UploadsPanel } from './Uploads/UploadsPanel'
import { VODClips } from './VODClips'
import {
  ClipsContext,
  useClipsStore,
  VODClipsController,
} from './VODClipsController'
import { VODClock } from './VODClock'
import { VODContext, VODController } from './VODController'
import { VODControls } from './VODControls'
import { VODPlayer } from './VODPlayer'
import { VODSelectMobile } from './VODSelectMobile'
import { VODThumbnails } from './VODThumbnails'

export const VODPage = () => {
  return (
    <AnalysesController>
      <VODController>
        <UploadsController>
          <VODClipsController>
            <VODShoringController>
              <VODLayout />
            </VODShoringController>
          </VODClipsController>
        </UploadsController>
      </VODController>
    </AnalysesController>
  )
}

export const VODLayout = () => {
  const theme = useTheme()
  const { data: me } = useMe()
  const [searchParams] = useSearchParams()
  const clipsStore = useClipsStore()

  const { panelState, setPanelState, PanelControl } = usePanels('VODWindows', {
    Timeline: false,
    Drawings: false,
    Clips: false,
    Analytics: false,
    Thumbnails: true,
    Multiplayer: false,
  })

  useEffect(() => {
    if (panelState && !!searchParams.get('clip')) {
      setPanelState({
        ...panelState,
        Clips: true,
      })
    }
  }, [searchParams.get('clip')])

  const clipsPermission =
    me?.videoannotation_role === 'admin' ||
    me?.videoannotation_role === 'annotator'

  const clip = clipsStore((state) => state.clip)

  const touchDevice = useTouchDevice()
  const isMobile = useMobile()
  const { height } = useWindowSize()

  const ContextBridge = useContextBridge(
    ApiContext,
    VODContext,
    UploadsContext,
    ClipsContext,
    ShoringContext
  )

  if (!panelState) return null

  return (
    <div
      style={{
        height: height - theme.custom.projectNavHeight,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {isMobile && <VODSelectMobile />}
      <div
        style={{
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 6,
          height: 44,
          minHeight: 44,
          borderBottom: '2px solid',
          borderColor: theme.palette.divider,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <VODClock />
          {clip && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 18,
                width: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
                marginRight: 20,
              }}
            >
              {clip.title && <strong>{clip.title}</strong>}
              {clip.description && (
                <span style={{ margin: '0 0.2em' }}> – </span>
              )}
              {clip.description && <span>{clip.description}</span>}
            </span>
          )}
        </div>
        {!touchDevice && <PanelControl />}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {!isMobile && panelState.Thumbnails && (
          <DragResizeBox
            handleSide="left"
            defaultSize={190}
            minSize={190}
            localStorageKey="vod-thumbs-col"
          >
            <VODThumbnails />
          </DragResizeBox>
        )}

        {!touchDevice && panelState.Clips && (
          <DragResizeBox
            handleSide="left"
            defaultSize={300}
            minSize={200}
            localStorageKey="vod-clips-col"
          >
            <VODClips />
          </DragResizeBox>
        )}

        {!touchDevice && panelState.Analytics && (
          <DragResizeBox
            handleSide="left"
            defaultSize={300}
            minSize={200}
            localStorageKey="vod-shoring-col"
          >
            <VODShoringDetailPanel />
          </DragResizeBox>
        )}

        {!isMobile && panelState.Multiplayer && (
          <DragResizeBox
            handleSide="left"
            defaultSize={600}
            minSize={400}
            localStorageKey="vod-multiplayer-col"
          >
            <MultiPlayerPanel />
          </DragResizeBox>
        )}

        {!isMobile && panelState.Drawings && (
          <DragResizeBox
            handleSide="left"
            defaultSize={600}
            minSize={400}
            localStorageKey="vod-drawings-col"
            contentStyle={{ display: 'flex', flexDirection: 'column' }}
          >
            <div style={{ flexGrow: 1 }}>
              <UploadsPanel>
                <ContextBridge>
                  <ShoringDrawingOverlay />
                </ContextBridge>
              </UploadsPanel>
            </div>

            <DragResizeBox
              handleSide="top"
              defaultSize={400}
              minSize={200}
              localStorageKey="vod-analysis-table"
              contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <ShoringTable />
            </DragResizeBox>
          </DragResizeBox>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          {/* Player */}
          <VODPlayer>
            {/* <ContextBridge>
              <VODShoringVideoOverlay />
            </ContextBridge> */}
          </VODPlayer>

          {/* New Controls */}
          {!touchDevice && <VODControls />}
          {!touchDevice && panelState.Timeline && (
            <DragResizeBox
              handleSide="top"
              defaultSize={200}
              minSize={110}
              localStorageKey="vod-bot-row"
              contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Timeline>
                <ContextBridge>
                  <TimelineStreams rowGroupIndex={0} />
                  {clipsPermission && (
                    <>
                      <TimelineClips rowGroupIndex={1} />
                      <TimelineShoring rowGroupIndex={2} />
                    </>
                  )}
                </ContextBridge>
              </Timeline>
            </DragResizeBox>
          )}
        </div>
      </div>
    </div>
  )
}
