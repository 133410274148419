import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import { PageWrapper } from './PageWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '400px',
      margin: '0 auto',
      [theme.breakpoints.up('lg')]: {
        maxWidth: 'none',
      },
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    price: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      fontSize: 20,
      lineHeight: 2,
      fontWeight: 'bold',
    },
  })
)

export const PricingPage = () => {
  const classes = useStyles()

  return (
    <PageWrapper>
      <div style={{ padding: 16, paddingTop: 32, textAlign: 'center' }}>
        <Typography variant="h2" gutterBottom>
          Choose your plan
        </Typography>
        <Typography variant="body1" gutterBottom>
          We offer solutions for a wide range of project types, from 6-month
          renovations to multiyear megaprojects.
        </Typography>
        <Typography variant="body1" gutterBottom style={{ paddingBottom: 32 }}>
          We serve a variety of stakeholders: trades, GCs, owners, lenders and
          more. We’ve got you covered.
        </Typography>
        <Grid container spacing={2} className={classes.container}>
          {[
            {
              name: 'Base',
              h1: 'View',
              h2: 'HD live stream',
              list: [
                '2 Megapixels live video',
                'Overhead, unrestricted view',
                'Top-of-mast free install',
                '1-day archive, unlimited replay',
                'Up to 3 users',
              ],
              button: (
                <Button variant="contained" disabled fullWidth>
                  This is your current plan.
                </Button>
              ),
              price: 'Free',
            },
            {
              name: 'Pro',
              h1: 'Manage',
              h2: 'UHD live stream + timelapse',
              list: [
                '5+ Megapixels live video',
                'Overhead, unrestricted view',
                'Mast & jib install available',
                'Unlimited archive & replay',
                'Unlimited users',
                'Hourly & daily timelapses',
                'Remote PTZ control',
                'Download, annotate, share',
                'Month-to-month, no contract',
              ],
              button: (
                <Button variant="contained" color="primary" fullWidth>
                  Upgrade
                </Button>
              ),
              price: 'Starting at $499/month',
            },
            {
              name: 'Enterprise',
              h1: 'Anticipate',
              h2: 'Site-wide deployment',
              h3: 'Everything in Manage, plus:',
              list: [
                'Immersive 360o view',
                'Daily as-built maps',
                'Multi-crane installation',
                'Live AI-based tracking',
                'Advanced AI-based analytics',
              ],
              button: (
                <Button variant="contained" color="primary" fullWidth>
                  Upgrade
                </Button>
              ),
              price: 'Priced based on your jobsite layout',
            },
          ].map((plan) => (
            <Grid item xs={12} lg={4} key={plan.h1}>
              <Card className={classes.card}>
                <div className={classes.price}>{plan.name}</div>
                <CardContent>
                  <Typography variant="h4">{plan.h1}</Typography>
                  <Typography variant="h6">{plan.h2}</Typography>
                </CardContent>
                <List style={{ flexGrow: 1 }}>
                  {plan.list.map((li) => (
                    <ListItem key={li}>
                      <ListItemIcon style={{ minWidth: 35 }}>
                        <CheckCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={li} />
                    </ListItem>
                  ))}
                </List>
                <CardContent>
                  {plan.button}
                  <Typography style={{ marginTop: 10, textAlign: 'center' }}>
                    {plan.price}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </PageWrapper>
  )
}
