import { scaleLinear } from '@visx/scale'
import { colord } from 'colord'
import React from 'react'
import { Group, Shape } from 'react-konva'
import { darkTheme } from '../../../styles/theme'
import { useUseTimelineStore } from '../Timeline/Timeline'
import { TimelineCollapsibleSection } from '../Timeline/TimelineCollapsibleSection'
import { TimelineRow } from '../Timeline/TimelineRow'
import { TimelineRowContent } from '../Timeline/TimelineRowContent'
import { useDeckAIStore } from './DeckAIController'

const rowColors = [
  colord(darkTheme.custom.chartColorsCategorical[0]),
  colord(darkTheme.custom.chartColorsCategorical[1]),
  colord(darkTheme.custom.chartColorsCategorical[2]),
]

export const TimelineDeckAI = ({
  rowGroupIndex,
}: {
  rowGroupIndex: number
}) => {
  const deckAIStore = useDeckAIStore()
  const useTimelineStore = useUseTimelineStore()

  const segmentationData = deckAIStore((state) => state.segmentationData)
  const overlayOptions = deckAIStore((state) => state.overlayOptions)

  const rowHeight = useTimelineStore((state) => state.rowHeight)

  const data = Array.from(segmentationData.values())

  return (
    <TimelineCollapsibleSection
      expandedTitle={'Deck AI'}
      collapsedTitle={'Deck AI'}
      rowGroupIndex={rowGroupIndex}
      childRowCount={data.length}
      childRowHeight={rowHeight}
    >
      {(collapsed) => (
        <Group>
          {data.map((labelData, i) => {
            const colorScale = scaleLinear({
              domain: [0, 0],
              range: [0.1, 1],
            })

            colorScale.domain(
              labelData.groupTypes[overlayOptions.groupBy].domain
            )

            return (
              <TimelineRow
                key={i}
                y={i * rowHeight}
                i={i}
                rowHeight={rowHeight}
              >
                <TimelineRowContent
                  labelText={labelData.label.name}
                  labelColor={rowColors[i].toRgbString()}
                >
                  <Group>
                    {labelData.groupTypes[overlayOptions.groupBy].groups.map(
                      (group, j) => (
                        <Shape
                          sceneFunc={function drawTimelineDeckAI(
                            context,
                            shape
                          ) {
                            const {
                              leftColumnWidth,
                              width,
                              height,
                              currentScale,
                            } = useTimelineStore.getState()

                            context.beginPath()

                            const startX = currentScale(
                              group.systemStartDateTime
                            )
                            const endX = currentScale(group.systemEndDateTime)
                            if (endX > leftColumnWidth && startX < width) {
                              context.rect(startX, 0, endX - startX, rowHeight)
                            }

                            context.closePath()
                            // (!) Konva specific method, it is very important
                            context.fillShape(shape)
                          }}
                          fill={rowColors[i]
                            .lighten(0.1)
                            .alpha(colorScale(group.areaChanged))
                            .toRgbString()}
                        />
                      )
                    )}
                  </Group>
                </TimelineRowContent>
              </TimelineRow>
            )
          })}
        </Group>
      )}
    </TimelineCollapsibleSection>
  )
}
