import { DateTime } from 'luxon'
import { CraneEvent } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'

export interface CraneEventExtended extends CraneEvent {
  eventDateTime: DateTime
  // system times are the real world times, shifted to the browser system's timezone,
  // because the timeline D3 scale can only be in that timezone, or in UTC
  systemEventDateTime: DateTime
}

export function extendCraneEvent(
  craneEvent: CraneEvent,
  project: ProjectDetailsExtended
): CraneEventExtended {
  const eventDateTime = DateTime.fromISO(craneEvent.event_datetime, {
    zone: project.timezone,
  })

  const systemEventDateTime = eventDateTime.setZone('system', {
    keepLocalTime: true,
  })

  return {
    ...craneEvent,
    eventDateTime,
    systemEventDateTime,
  }
}
