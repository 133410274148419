import { Container } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { CSSProperties, FC } from 'react'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: 8,
      },
    }),
  {
    name: 'PageWrapper',
  }
)

export const PageWrapper: FC<{ style?: CSSProperties }> = ({
  children,
  style = {},
}) => {
  const classes = useStyles()
  return (
    <Container className={classes.root} style={style}>
      <>{children}</>
    </Container>
  )
}
