import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import React from 'react'
import { useWindowSize } from 'react-use'
import { useContextBridge } from '../../../hooks/useContextBridge'
import { useTouchDevice } from '../../../hooks/useTouchDevice'
import { ApiContext } from '../../ApiContext'
import { DragResizeBox } from '../../DragResizeBox'
import { AnalysesController } from '../AnalysesController'
import { MultiPlayerPanel } from '../MultiPlayerPanel'
import { Timeline } from '../Timeline/Timeline'
import { TimelineStreams } from '../Timeline/TimelineStreams'
import { VODClock } from '../VODClock'
import { VODContext, VODController } from '../VODController'
import { VODControls } from '../VODControls'
import { VODPlayer } from '../VODPlayer'
import { TruckWeightsPanel } from './TruckWeightsPanel'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        maxHeight: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
      topControl: {
        padding: theme.spacing(1),
        border: '1px solid',
        borderColor: theme.palette.divider,
      },
      moduleRow: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'hidden',
        overflowX: 'auto',
      },
      right: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
      bottom: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      timeline: {
        flexGrow: 1,
      },
    }),
  {
    name: 'CraneLayout',
  }
)

export const TruckWeightsPage = () => {
  return (
    <AnalysesController>
      <VODController>
        <TruckWeightsLayout />
      </VODController>
    </AnalysesController>
  )
}

const TruckWeightsLayout = () => {
  const classes = useStyles()
  const theme = useTheme()

  const touchDevice = useTouchDevice()
  const { height } = useWindowSize()

  const ContextBridge = useContextBridge(ApiContext, VODContext)

  return (
    <div
      className={classes.root}
      style={{ height: height - theme.custom.projectNavHeight }}
    >
      <div className={classes.moduleRow}>
        <div className={classes.right}>
          <div style={{ padding: theme.spacing(1) }}>
            <VODClock />
          </div>

          {/* Player */}
          <VODPlayer />

          {/* New Controls */}
          {!touchDevice && <VODControls />}

          {!touchDevice && (
            <DragResizeBox
              handleSide="top"
              defaultSize={300}
              minSize={270}
              localStorageKey="crane-picks-row"
            >
              <Timeline>
                <ContextBridge>
                  <TimelineStreams rowGroupIndex={0} />
                </ContextBridge>
              </Timeline>
            </DragResizeBox>
          )}
        </div>

        {!touchDevice && (
          <DragResizeBox
            handleSide="left"
            defaultSize={400}
            minSize={300}
            localStorageKey="truck-weights-multiplayer-col"
          >
            <MultiPlayerPanel />
          </DragResizeBox>
        )}

        <DragResizeBox
          handleSide="left"
          defaultSize={600}
          minSize={500}
          localStorageKey="truck-weights-panel-col"
        >
          <TruckWeightsPanel />
        </DragResizeBox>
      </div>
    </div>
  )
}
