import { DateTime } from 'luxon'
import { CraneCalendarEntry } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'

export interface CraneCalendarEntryExtended extends CraneCalendarEntry {
  startDateTime: DateTime
  systemStartDateTime: DateTime
  endDateTime: DateTime
  systemEndDateTime: DateTime
  isoDate: string
  duration: Duration
}

export function extendCraneCalendarEntry(
  entry: CraneCalendarEntry,
  project: ProjectDetailsExtended
): CraneCalendarEntryExtended {
  const startDateTime = DateTime.fromISO(entry.start_datetime, {
    zone: project.timezone,
  })
  const endDateTime = DateTime.fromISO(entry.end_datetime, {
    zone: project.timezone,
  })
  return {
    ...entry,
    startDateTime,
    systemStartDateTime: startDateTime.setZone('system', {
      keepLocalTime: true,
    }),
    endDateTime,
    systemEndDateTime: endDateTime.setZone('system', {
      keepLocalTime: true,
    }),
    isoDate: startDateTime.toISODate(),
    duration: endDateTime.diff(startDateTime),
  }
}
