import {
  createStyles,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { ScaleTime } from 'd3-scale'
import { CranePickExtended } from '../../../api/CranePickExtended'
import { getUseVODStore } from '../VODController'
import { Idle, useCraneStore } from './CranePicksController'
import { t } from 'i18next'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      pickTimeRange: {
        position: 'absolute',
        borderRadius: 6,
        border: '2px solid',
        padding: '2px 4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        '&:hover div:first-child': {
          opacity: 0.7,
        },
      },
      pickTimeRangeBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0.3,
      },
    }),
  {
    name: 'VODPicks',
  }
)

export const CranePicksAsBuilt = ({
  scale,
  showAsPlanned,
  showEvents,
}: {
  scale: ScaleTime<number, number, never>
  showAsPlanned: boolean
  showEvents: boolean
}) => {
  const craneStore = useCraneStore()

  const picks = craneStore((state) => state.picks)
  const idles = craneStore((state) => state.idles)

  return (
    <div
      className="CranePicksAsBuilt"
      style={{ position: 'relative', zIndex: 2 }}
    >
      {picks.map((p) => {
        return (
          <Block
            key={p.id}
            pickOrIdle={p}
            scale={scale}
            showAsPlanned={showAsPlanned}
            showEvents={showEvents}
          />
        )
      })}
      {idles.map((idle) => {
        return (
          <Block
            key={idle.startDateTime.toISO()}
            pickOrIdle={idle}
            scale={scale}
            showAsPlanned={showAsPlanned}
            showEvents={showEvents}
          />
        )
      })}
    </div>
  )
}

const Block = ({
  pickOrIdle,
  scale,
  showAsPlanned,
  showEvents,
}: {
  pickOrIdle: CranePickExtended | Idle
  scale: ScaleTime<number, number, never>
  showAsPlanned: boolean
  showEvents: boolean
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const craneStore = useCraneStore()
  const vodStore = getUseVODStore()

  const start = scale(
    pickOrIdle.startDateTime
      .setZone('local', { keepLocalTime: true })
      .toJSDate()
  )
  const end = scale(
    pickOrIdle.endDateTime.setZone('local', { keepLocalTime: true }).toJSDate()
  )
  const height = end - start

  const color =
    'id' in pickOrIdle
      ? pickOrIdle.subcontractor_color
      : theme.palette.error.dark

  return (
    <div
      // button
      key={pickOrIdle.startDateTime.toMillis()}
      onClick={() => {
        if ('id' in pickOrIdle) {
          craneStore.getState().setPick(pickOrIdle)
        } else {
          vodStore.getState().gotoVideo({
            dateTime: pickOrIdle.startDateTime,
          })
        }
        craneStore.setState({
          showVideo: true,
        })
      }}
      className={classes.pickTimeRange}
      style={{
        top: start + 1,
        right: 0,
        width: showAsPlanned || showEvents ? 'calc(60% - 1px)' : '100%',
        height: height - 2,
        borderColor: color,
        fontSize: 12,
      }}
    >
      <div
        className={classes.pickTimeRangeBackground}
        style={{
          opacity: 0.7,
          backgroundColor: color,
        }}
      />
      <div style={{ position: 'relative', zIndex: 1 }}>
        {'id' in pickOrIdle ? (
          <span>{pickOrIdle.load_data || 'Unspecified load'}</span>
        ) : (
          <span>IDLE</span>
        )}

        {'id' in pickOrIdle && pickOrIdle.action ? (
          <span>{': ' + t(`actions.${pickOrIdle.action}`)}</span>
        ) : null}

        {'id' in pickOrIdle ? (
          <span
            style={{
              fontSize: '0.8em',
              fontWeight: 'bold',
            }}
          >
            {' - '}
            {pickOrIdle.subcontractor_name}
          </span>
        ) : null}

        {/* if rework, show chip */}
        {'id' in pickOrIdle && pickOrIdle.rework ? (
          <span
            style={{
              fontSize: '0.8em',
              fontWeight: 'bold',
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              padding: '0px 4px',
              borderRadius: 4,
              marginLeft: 8,
            }}
          >
            Rework
          </span>
        ) : null}
      </div>
    </div>
  )
}
