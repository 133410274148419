import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FolderIcon from '@material-ui/icons/Folder'
import { DateTime } from 'luxon'
import React from 'react'
import { useDropArea } from 'react-use'
import { useUploadsStore } from './UploadsController'

export const UploadsLibrary = ({}: {}) => {
  const theme = useTheme()
  const uploadsStore = useUploadsStore()

  const uploads = uploadsStore((state) => state.uploads)
  const selectedUpload = uploadsStore((state) => state.selectedUpload)
  const selectedUploadPageNumber = uploadsStore(
    (state) => state.selectedUploadPageNumber
  )
  const selectedUploadPageCount = uploadsStore(
    (state) => state.selectedUploadPageCount
  )

  const [dialogOpen, setDialogOpen] = React.useState(false)

  React.useEffect(() => {
    uploadsStore.getState().fetchUploads()
  }, [])

  const [bind, state] = useDropArea({
    onFiles: async (files) => {
      uploadsStore.getState().createUpload(files[0])
    },
    // onUri: (uri) => console.log('uri', uri),
    // onText: (text) => console.log('text', text),
  })

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <FormControl>
            <InputLabel>Drawing</InputLabel>
            <Select
              native
              value={selectedUpload?.id || 'select'}
              onChange={(event) => {
                if (event.target.value !== 'select') {
                  uploadsStore.setState({
                    selectedUpload: uploads?.find(
                      (u) => u.id === parseInt(event.target.value as string)
                    ),
                  })
                }
              }}
            >
              <option key={'unselected'} value="select">
                Select drawing
              </option>
              {uploads &&
                uploads.map((upload) => (
                  <option key={upload.id} value={upload.id!.toString()}>
                    {upload.basename}
                  </option>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel>Page</InputLabel>
            <Select
              disabled={!selectedUploadPageCount}
              label="Page"
              native
              value={selectedUploadPageNumber?.toString() || '1'}
              onChange={(event) =>
                uploadsStore.setState({
                  selectedUploadPageNumber: parseInt(
                    event.target.value as string
                  ),
                })
              }
            >
              {Array.from(Array(selectedUploadPageCount).keys()).map(
                (index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setDialogOpen(true)} size={'small'}>
            <FolderIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Manage Drawings</DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ paddingBottom: 10 }}>
            Drop PDF's in area below to upload.
          </Typography>
          <div
            {...bind}
            style={{
              minHeight: 200,
              maxHeight: 400,
              border: '2px dashed',
              overflowY: 'scroll',
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <List dense>
              {uploads &&
                uploads.map((upload) => (
                  <ListItem
                    key={upload.id}
                    onClick={() =>
                      uploadsStore.setState({ selectedUpload: upload })
                    }
                    selected={selectedUpload === upload}
                  >
                    <ListItemText
                      primary={upload.basename}
                      secondary={`created at: ${DateTime.fromISO(
                        upload.created_at!
                      ).toLocaleString(DateTime.DATE_SHORT)}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          uploadsStore.getState().deleteUpload(upload)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            variant="contained"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
