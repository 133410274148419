import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import MapIcon from '@material-ui/icons/Map'
import MenuIcon from '@material-ui/icons/Menu'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import SecurityIcon from '@material-ui/icons/Security'
import UpdateIcon from '@material-ui/icons/Update'
import clsx from 'clsx'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { GiPulleyHook } from 'react-icons/gi'
import { Link, NavLink } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import aerialTour from '../../images/icon/360.svg'
import folderMovie from '../../images/icon/folder-movie.png'
import liveCam from '../../images/icon/live-cam.svg'
import photos from '../../images/icon/photos.png'
import projects from '../../images/icon/projects.svg'
import logoImgColor from '../../images/logo-color.png'
import logoImg from '../../images/logo-white.png'
import {
  ProjectDetails,
  ProjectSummary,
  UserSummary,
} from '../api/codegen/typescript-axios'
import { useMe } from '../hooks/useMe'
import { useMobile } from '../hooks/useMobile'
import { useProjects } from '../hooks/useProjects'
import { useMediaClasses } from '../styles/mediaClasses'
import { darkTheme } from '../styles/theme'
import { useAlertConfirmPrompt } from './AlertConfirmPrompt'
import { useNavData } from './AppWrapper'
import { useThemeControl } from './Context/ThemeController'
import { useAppTour } from './Global/AppTour'

const drawerWidth = 200

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      appBar: {
        [theme.breakpoints.up('sm')]: {
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
        '@media print': {
          boxShadow: 'none',
        },
      },
      appBarShift: {
        [theme.breakpoints.up('sm')]: {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
      toolbar: {
        justifyContent: 'space-between',
        '@media print': {
          justifyContent: 'start',
        },
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        [theme.breakpoints.up('sm')]: {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
          width: theme.spacing(7) + 1,
        },
      },
      drawerTopPadding: {
        height: 48,
        display: 'flex',
        justifyContent: 'flex-end',
      },
      drawerPaper: {
        width: drawerWidth,
      },
      menuButton: {
        display: 'block',
        marginLeft: 4,
      },
      menuButtonHidden: {
        visibility: 'hidden',
      },
      logoLink: {
        display: 'block',
        // marginLeft: 10,
      },
      logo: {
        display: 'block',
        width: 140,
      },
      drawerIcon: {
        minWidth: 40,
      },
      drawerIconImg: {
        width: 24,
        height: 24,
      },
      divider: {},
      link: {
        display: 'block',
        color: '#fff',
        textDecoration: 'none',
        transition: '0.2s all',
        '&:hover': {
          backgroundColor: 'rgba(256, 256, 256, 0.2)',
        },
      },
      activeLink: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
      aboutLogo: {
        width: 160,
        display: 'block',
        margin: '10px auto 20px',
      },
    }),
  {
    name: 'Nav',
  }
)

interface ILink {
  label: string
  tooltip?: string
  image?: string | React.ElementType
  apiName?: string
  path: string
  className?: string
  shouldShow: (
    me?: UserSummary,
    projects?: ProjectSummary[],
    project?: ProjectDetails
  ) => boolean
}

export const allProjectNavItems: ILink[] = [
  {
    label: 'Live Streams',
    image: liveCam,
    apiName: 'live',
    path: 'live',
    shouldShow: () => true,
  },
  {
    label: 'Daily Time Lapse',
    image: UpdateIcon,
    apiName: 'daily',
    path: 'daily',
    shouldShow: () => true,
  },
  {
    label: 'Archive Video',
    image: folderMovie,
    apiName: 'hourly',
    path: 'archive',
    shouldShow: () => true,
  },
  {
    label: 'Photos',
    image: PhotoLibraryIcon,
    apiName: 'photos',
    path: 'photos',
    shouldShow: () => true,
  },
  {
    label: 'Photosphere',
    image: photos,
    apiName: 'pano',
    path: 'pano',
    shouldShow: () => true,
  },
  {
    label: 'Aerial Tour (Beta)',
    image: aerialTour,
    apiName: 'aerialtour',
    path: 'aerialtour',
    shouldShow: () => true,
  },
  {
    label: 'Aerial Maps',
    image: MapIcon,
    apiName: 'maps',
    path: 'maps',
    shouldShow: () => true,
  },
  {
    label: 'Crane Reports',
    image: GiPulleyHook,
    apiName: 'crane',
    path: 'crane-reports',
    shouldShow: () => true,
  },
  {
    label: 'Truck Weights',
    image: LocalShippingIcon,
    apiName: 'truckweights',
    path: 'truck-weights',
    shouldShow: () => true,
  },
  {
    label: 'Security',
    image: SecurityIcon,
    apiName: 'security',
    path: 'security',
    shouldShow: () => true,
  },
  {
    label: 'Manage Users',
    image: GroupAddIcon,
    apiName: 'manageusers',
    path: 'manageusers',
    shouldShow: () => true,
  },
  {
    label: 'Contact Support',
    image: ContactSupportIcon,
    apiName: 'support',
    path: 'support',
    shouldShow: () => true,
  },
  // reports: {
  //   label: 'AI Reports',
  //   image: reports,
  //   link: 'reports',
  // },
  // documents: {
  //   label: 'Documents',
  //   image: documents,
  //   link: 'documents',
  // },
  // flightlogs: {
  //   label: 'Flight Logs',
  //   image: flightlogs,
  //   link: 'flightlogs',
  // },
]

const globalLeftNavItems: ILink[] = [
  {
    label: 'Projects',
    image: projects,
    path: `projects`,
    className: 'projects',
    shouldShow: (me, projects = []) => {
      return projects.length > 1
    },
  },
  {
    label: 'Image Annotations',
    image: ImageSearchIcon,
    path: `annotations/list-jobs`,
    shouldShow: (me) => {
      return !!me?.annotation_role
    },
  },
]

export const Nav = () => {
  const classes = useStyles()
  const mediaClasses = useMediaClasses()
  const appTour = useAppTour()

  const [{ project }, setNavData] = useNavData()

  const { data: me } = useMe()
  const { data: projects } = useProjects()

  useEffect(() => {
    if (projects) {
      setNavData((navData) => ({ ...navData, projects: projects }))
    }
  }, [projects, setNavData])

  const projectNavItems = project
    ? allProjectNavItems
        .filter((item) => {
          return item.apiName && project.pages.indexOf(item.apiName) > -1
        })
        .map((item) => ({
          ...item,
          path: `${project.slug}/${item.path}`,
        }))
    : []

  const [open, setOpen] = React.useState(false)
  useEffect(() => {
    if (appTour.index === 0 && appTour.action !== 'reset') {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [appTour])

  const mobile = useMobile()

  const drawer = (
    <div
      onClick={() => {
        if (mobile) {
          setOpen(false)
        }
      }}
    >
      <div className={classes.drawerTopPadding}>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon htmlColor="#ffffff" />
        </IconButton>
      </div>
      <Fragment>
        <List>
          {globalLeftNavItems.map((link) =>
            me && link.shouldShow(me, projects) ? (
              <LinkItem key={link.label} link={link} classes={classes} />
            ) : null
          )}
        </List>
        <Divider className={classes.divider} />
      </Fragment>
      <List>
        {project && (
          <>
            {projectNavItems.map((link) => {
              return <LinkItem key={link.label} link={link} classes={classes} />
            })}
            <Divider className={classes.divider} />
          </>
        )}
      </List>
    </div>
  )

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar variant="dense" disableGutters className={classes.toolbar}>
          <Box display="block" displayPrint={'none'}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              className={clsx(classes.menuButton, {
                [classes.menuButtonHidden]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Link to={`/`} className={classes.logoLink}>
            <Box display="block" displayPrint={'none'}>
              <img className={classes.logo} src={logoImg} alt="Topdeck logo" />
            </Box>
            <Box display="none" displayPrint={'block'} paddingLeft={1}>
              <img
                src={logoImgColor}
                alt="Topdeck logo"
                style={{ width: 160 }}
              />
            </Box>
          </Link>

          <AccountMenu />
        </Toolbar>
      </AppBar>

      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp>
        <SwipeableDrawer
          // container={container}
          variant="temporary"
          anchor={'left'}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, mediaClasses.hideForPrint, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </ThemeProvider>
  )
}

const AccountMenu = () => {
  const classes = useStyles()
  const { confirm } = useAlertConfirmPrompt()

  const { data: me } = useMe()
  const { data: projects } = useProjects()

  const { themeType, setThemeType } = useThemeControl()

  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  const [, copyToClipboard] = useCopyToClipboard()

  const accountNavItems: ILink[] = [
    {
      label: 'Image Annotations',
      path: `annotations/list-jobs`,
      shouldShow: (me) => {
        return !!me?.annotation_role
      },
    },
    {
      label: 'My Account',
      path: `account`,
      shouldShow: () => true,
    },
    {
      label: 'Log out',
      path: `logout`,
      shouldShow: () => true,
    },
    {
      label: 'Contact us',
      tooltip: 'support@topdeck.ai',
      path: `mailto:support@topdeck.ai?subject=Support&body=${encodeURIComponent(
        `\n\nTopdeck Dashboard v${process.env.REACT_APP_VERSION}\nUser Agent: ${navigator.userAgent}`
      )}`,
      shouldShow: () => true,
    },
    {
      label: 'Topdeck.ai website',
      path: `https://topdeck.ai`,
      shouldShow: () => true,
    },
  ]

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="account menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setOpen((open) => !open)}
        color="inherit"
      >
        <AccountCircle className="account-menu" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
      >
        <MenuItem
          onClick={() => {
            onClose()
            try {
              confirm({
                title: (
                  <>
                    <img
                      src={logoImgColor}
                      alt="Topdeck logo"
                      className={classes.aboutLogo}
                    />
                    {'Topdeck Dashboard'}
                  </>
                ),
                description: (
                  <>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>version:</TableCell>
                          <TableCell>{process.env.REACT_APP_VERSION}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>url:</TableCell>
                          <TableCell
                            style={{
                              maxWidth: 200,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {window.location.href}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>api:</TableCell>
                          <TableCell>{process.env.REACT_APP_API_URL}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                ),
                yesText: 'Close',
                noText: 'Copy to clipboard',
              })
            } catch (err) {
              copyToClipboard(
                `Topdeck version: ${process.env.REACT_APP_VERSION}, url: ${window.location.href}, api: ${process.env.REACT_APP_API_URL}`
              )
            }
          }}
        >
          About...
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose()
            setThemeType(themeType === 'light' ? 'dark' : 'light')
          }}
        >
          Use {themeType === 'light' ? 'dark' : 'light'} theme
        </MenuItem>
        {accountNavItems.map((link) =>
          me && link.shouldShow(me, projects) ? (
            <LinkItem
              key={link.label}
              link={link}
              classes={classes}
              onClick={onClose}
            />
          ) : null
        )}
      </Menu>
    </>
  )
}

interface LinkItemProps {
  link: ILink
  classes: any
}

const LinkItem = ({
  link,
  classes,
  onClick,
}: {
  onClick?: () => void
} & LinkItemProps) => {
  const li = (
    <ListItem
      button
      key={link.label}
      onClick={onClick}
      className={link.className}
    >
      {link.image && (
        <Tooltip title={link.label} placement="right" arrow enterDelay={500}>
          <ListItemIcon className={clsx(classes.drawerIcon)}>
            {typeof link.image === 'string' ? (
              <img src={link.image} alt="" className={classes.drawerIconImg} />
            ) : (
              <link.image
                className={classes.drawerIconImg}
                style={{ color: '#fff' }}
              />
            )}
          </ListItemIcon>
        </Tooltip>
      )}
      <ListItemText primary={link.label} />
    </ListItem>
  )

  if (link.path.indexOf('mailto') > -1 || link.path.indexOf('http') > -1) {
    return (
      <a className={classes.link} href={link.path}>
        {li}
      </a>
    )
  } else {
    return (
      <NavLink
        className={({ isActive }) =>
          isActive ? clsx(classes.link, classes.activeLink) : classes.link
        }
        to={`/${link.path}`}
        // async so previous page scroll is preserved in history
        onClick={() => window.setTimeout(() => window.scrollTo(0, 0), 0)}
      >
        {li}
      </NavLink>
    )
  }
}
