import React, { FC, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useNavigate } from 'react-router-dom'
import { Api } from '../api/Api'
import { createCtx } from '../helpers/createCtx'

export const [useApi, ApiContext] = createCtx<Api>()

export const ApiController: FC<{}> = ({ children }) => {
  const navigate = useNavigate()
  const [queryClient] = useState(() => new QueryClient())
  const [api] = useState(() => new Api(navigate, queryClient))
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
    </QueryClientProvider>
  )
}
