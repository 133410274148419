import { DateTime, Duration } from 'luxon'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'
import { CranePick } from './codegen/typescript-axios'

type SegmentName = 'in_between' | 'to_pick' | 'load' | 'travel' | 'unload'

interface Segment {
  name: SegmentName
  label: string
  start: DateTime
  systemStartDateTime: DateTime
  end: DateTime
  systemEndDateTime: DateTime
  activeDuration: Duration
  idleDuration: Duration
  totalDuration: Duration
}

export interface CranePickExtended extends Omit<CranePick, 'subcontractor'> {
  id: string
  type: 'pick'
  startDateTime: DateTime
  systemStartDateTime: DateTime
  endDateTime: DateTime
  systemEndDateTime: DateTime
  isoDate: string
  activeDuration: Duration
  idleDuration: Duration
  totalDuration: Duration
  inBetweenDuration: Duration
  emptyIdleDuration: Duration
  emptyDuration: Duration
  nonEmptyIdleDuration: Duration
  nonEmptyDuration: Duration
  isOutlier: boolean
  segments: Array<Segment>
  segmentsRecord: Record<SegmentName, Segment>
  subcontractor_color: string
  subcontractor_name: string
}

export function extendCranePick(
  p: CranePick,
  project: ProjectDetailsExtended
): CranePickExtended {
  const segments = ([
    'in_between',
    'to_pick',
    'load',
    'travel',
    'unload',
  ] as Array<SegmentName>).map((s) => {
    const start = DateTime.fromISO(
      p[(s + '_start') as keyof CranePick] as string,
      {
        zone: project.timezone,
      }
    )
    const end = DateTime.fromISO(p[(s + '_end') as keyof CranePick] as string, {
      zone: project.timezone,
    })

    const idleDuration = Duration.fromISO(
      p[(s + '_idle') as keyof CranePick] as string
    ).shiftTo('milliseconds')

    // need to shift these all to milliseconds because of a bug with Duration.diff
    // https://github.com/moment/luxon/issues/1165
    // TODO: maybe change this back when the bug is fixed
    const totalDuration = end.diff(start, 'milliseconds')

    const activeDuration = totalDuration
      .minus(idleDuration)
      .shiftTo('milliseconds')

    return {
      name: s,
      label: s.slice(0, 1).toUpperCase() + s.slice(1).replace('_', ' '),
      start,
      systemStartDateTime: start.setZone('system', { keepLocalTime: true }),
      end,
      systemEndDateTime: end.setZone('system', { keepLocalTime: true }),
      totalDuration,
      activeDuration,
      idleDuration,
    }
  })

  const segmentsRecord = <Record<SegmentName, Segment>>{}

  segments.forEach((s) => (segmentsRecord[s.name] = s))

  const totalDuration = segmentsRecord.unload.end.diff(
    segmentsRecord.to_pick.start,
    'milliseconds'
  )

  const activeDuration = segments
    .slice(1, 5)
    .reduce((acc, s) => acc.plus(s.activeDuration), Duration.fromMillis(0))

  const idleDuration = segments
    .slice(1, 5)
    .reduce((acc, s) => acc.plus(s.idleDuration), Duration.fromMillis(0))

  const inBetweenDuration = segmentsRecord.in_between.totalDuration

  const emptyDuration = segmentsRecord.in_between.totalDuration
    .plus(segmentsRecord.to_pick.totalDuration)
    .plus(segmentsRecord.load.idleDuration)

  const emptyIdleDuration = segmentsRecord.in_between.totalDuration
    .plus(segmentsRecord.to_pick.idleDuration)
    .plus(segmentsRecord.load.idleDuration)

  const nonEmptyDuration = segmentsRecord.load.activeDuration
    .plus(segmentsRecord.travel.totalDuration)
    .plus(segmentsRecord.unload.totalDuration)

  const nonEmptyIdleDuration = segmentsRecord.travel.idleDuration.plus(
    segmentsRecord.unload.idleDuration
  )

  const pick: CranePickExtended = {
    // copied props
    ...p,
    // computed props
    id: segments[1].start.toISO(),
    type: 'pick',
    startDateTime: segmentsRecord.to_pick.start,
    systemStartDateTime: segmentsRecord.to_pick.start.setZone('system', {
      keepLocalTime: true,
    }),
    endDateTime: segmentsRecord.unload.end,
    systemEndDateTime: segmentsRecord.unload.end.setZone('system', {
      keepLocalTime: true,
    }),
    isoDate: segmentsRecord.to_pick.start.toISODate(),
    activeDuration: activeDuration,
    idleDuration: idleDuration,
    totalDuration: totalDuration,
    inBetweenDuration: inBetweenDuration,
    emptyIdleDuration,
    emptyDuration,
    nonEmptyIdleDuration,
    nonEmptyDuration,
    isOutlier: idleDuration.as('minutes') > 1,
    segments,
    segmentsRecord,
    subcontractor_color: p.subcontractor_color || '#999',
    subcontractor_name: p.subcontractor_name || '',
  }

  return pick
}
