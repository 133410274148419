// from: https://css-tricks.com/using-requestanimationframe-with-react-hooks/

import { useEffect } from 'react'
import { raf } from 'rafz'

export const useAnimationFrame = (callback: (deltaTime?: number) => void) => {
  const animate = (deltaTime: number) => {
    try {
      callback(deltaTime)
    } catch (error) {
      console.error(error)
    }
    return true
  }

  useEffect(() => {
    raf(animate)
    return () => raf.cancel(animate)
    // eslint-disable-next-line
  }, [callback]) // Make sure the effect runs only once
}
