import { createStyles, makeStyles } from '@material-ui/core'

export const useMediaClasses = makeStyles(
  (theme) =>
    createStyles({
      hideForPrint: {
        '@media print': {
          display: 'none',
        },
      },
      hideForScreen: {
        '@media screen': {
          display: 'none',
        },
      },
    }),
  {
    name: 'MediaClasses',
  }
)
