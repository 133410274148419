import { CssBaseline } from '@material-ui/core'
import { LocalizationProvider } from '@material-ui/pickers'
import LuxonUtils from '@material-ui/pickers/adapter/luxon'
import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createStateContext } from 'react-use'
import { ProjectDetails, ProjectSummary } from '../api/codegen/typescript-axios'
import { AlertConfirmPromptProvider } from './AlertConfirmPrompt'
import { ApiController } from './ApiContext'
import AppRouter from './AppRouter'
import { ThemeController } from './Context/ThemeController'
import { ErrorLogger } from './ErrorLogger'
import { AppTour } from './Global/AppTour'
import { GlobalStyles } from './GlobalStyles'
import { KeyboardShortcutsController } from './KeyboardShortcutsController'
import { LanguageController } from './LanguageController'
import { PWAInstall } from './PWAInstall'
import { ServiceWorkerUpdate } from './ServiceWorkerUpdate'

export const [useNavData, NavDataProvider] = createStateContext<{
  project: ProjectDetails | undefined
  projects: ProjectSummary[]
}>({ project: undefined, projects: [] })

export const AppWrapper: FC<{}> = (props) => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_HREF}>
      <ErrorLogger>
        <ApiController>
          <NavDataProvider>
            <LanguageController>
              {/* @ts-ignore*/}
              <LocalizationProvider dateAdapter={LuxonUtils}>
                <ThemeController>
                  <GlobalStyles />
                  <CssBaseline />
                  <KeyboardShortcutsController>
                    <AlertConfirmPromptProvider>
                      <AppTour>
                        <PWAInstall />
                        <ServiceWorkerUpdate />
                        <AppRouter />
                      </AppTour>
                    </AlertConfirmPromptProvider>
                  </KeyboardShortcutsController>
                </ThemeController>
              </LocalizationProvider>
            </LanguageController>
          </NavDataProvider>
        </ApiController>
      </ErrorLogger>
    </BrowserRouter>
  )
}
