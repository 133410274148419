import React from 'react'
import { Box } from './Box'
import { Group } from 'react-konva'
import { ImageAnnotationBox } from '../../api/codegen/typescript-axios'
import { LabelState } from './Annotator'
import { useStageContext } from './VideoCanvas'

export const AnnotatorCanvasBoxes = ({
  boxes,
  setBoxes,
  selectedBox,
  setSelectedBox,
  labelState,
  boxColor,
}: {
  boxes: ImageAnnotationBox[]
  setBoxes?: (boxes: ImageAnnotationBox[]) => void
  selectedBox?: ImageAnnotationBox
  setSelectedBox: (box?: ImageAnnotationBox) => void
  labelState: LabelState
  boxColor?: string
}) => {
  const { absoluteTransform, clamp } = useStageContext()

  return (
    <Group {...absoluteTransform.decompose()}>
      {boxes.map((box, i) => {
        return (
          <Box
            key={box.id}
            box={box}
            isSelected={box === selectedBox}
            onSelect={() => {
              setSelectedBox(box)
            }}
            onChange={
              setBoxes
                ? (newDims: Partial<ImageAnnotationBox>) => {
                    const _boxes = boxes.slice()
                    const newBox = Object.assign(box, newDims)
                    _boxes[i] = newBox
                    setBoxes(_boxes)
                  }
                : undefined
            }
            absoluteTransform={absoluteTransform}
            showLabel={
              labelState === 'all' ||
              (labelState === 'selected' && box === selectedBox)
            }
            clamp={clamp}
            color={boxColor}
          />
        )
      })}
    </Group>
  )
}
