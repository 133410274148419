import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useAnalysesStore } from './AnalysesController'
import { getUseVODStore } from './VODController'

export const AnalysisSelect = () => {
  const analysesStore = useAnalysesStore()
  const { prompt } = useAlertConfirmPrompt()
  const useVODStore = getUseVODStore()

  const analyses = analysesStore((state) => state.analyses)
  const analysisId = analysesStore((state) => state.analysisId)

  return (
    <Grid style={{ display: 'flex' }} container spacing={1}>
      <Grid item style={{ flexGrow: 1 }}>
        {!analyses.length && <Typography>No analyses found.</Typography>}
        {analyses.length > 0 && (
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Analysis</InputLabel>
            <Select
              value={analysisId || 0}
              onChange={(e) => {
                analysesStore.setState({
                  analysisId: e.target.value as number,
                })
              }}
              label="Analysis"
            >
              {analyses &&
                analyses.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item>
        <Button
          title="Add analysis"
          variant="contained"
          style={{ height: '100%' }}
          onClick={async () => {
            const analysisTitle = await prompt({
              title: 'Create new analysis',
              description: 'Enter a title for this new analysis',
            })
            if (typeof analysisTitle === 'string' && analysisTitle) {
              analysesStore
                .getState()
                .createAnalysis(
                  analysisTitle,
                  useVODStore.getState().videoDateTime
                )
            }
          }}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
  )
}
