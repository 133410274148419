import mapboxgl from 'mapbox-gl'
import React from 'react'
import { MapRef } from 'react-map-gl'
import { ProjectMap } from '../../ProjectMap'
import { useDeckVerificationStore } from './DeckVerificationController'
import DrawControl from './DrawControl'

export const pickLinesSourceId = 'crane-picks-lines-source'

export const DeckVerificationMap = () => {
  const mapRef = React.useRef<MapRef>(null)

  const store = useDeckVerificationStore()
  const bounds = store((state) => state.bounds)
  const drawMode = store((state) => state.drawMode)

  const projectBounds = React.useMemo(() => {
    return new mapboxgl.LngLatBounds([
      [0.0130622, 0.0119363],
      [0.0134778, 0.0126685],
    ])
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <ProjectMap
          mapRef={mapRef}
          bounds={bounds || projectBounds}
          initialScrollZoom={true}
          cursor={drawMode === 'draw_line_string' ? 'crosshair' : 'auto'}
        >
          <DrawControl
            position="top-right"
            displayControlsDefault={false}
            defaultMode="draw_line_string"
            onCreate={store.getState().drawUpdate}
            onUpdate={store.getState().drawUpdate}
          />
        </ProjectMap>
      </div>
    </div>
  )
}
