import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'

export const CraneReportsPanelIntraPickIdles = ({}: {}) => {
  const { t } = useTranslation()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()
  const project = useProject()
  const selectedCrane = craneStore((state) => state.selectedCrane)

  const picks = craneStore((state) => state.picks)

  return (
    <>
      <Accordion square expanded={true}>
        {/* Summary header */}
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t('intra_pick_idles')}</Typography>
        </AccordionSummary>

        {/* Detail table */}
        <AccordionDetails style={{ padding: 0 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('start_time')}</TableCell>
                <TableCell style={{ width: 1 }}>
                  {t('subcontractors')}
                </TableCell>
                <TableCell style={{ width: 1 }}>{t('task')}</TableCell>
                <TableCell style={{ width: 1 }} align="right">
                  {t('duration')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* list picks with over one minute of idle duration */}
              {picks
                .filter((p) => p.idleDuration.as('seconds') > 60)
                .map((p, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {p.startDateTime.toLocaleString(DateTime.TIME_SIMPLE)}
                    </TableCell>
                    <TableCell>{p.subcontractor_name}</TableCell>
                    <TableCell>{p.load_data}</TableCell>
                    <TableCell align="right">
                      {p.idleDuration.toFormat('m:ss')}
                    </TableCell>
                    <TableCell align="right">
                      <a
                        href={`https://topdeck.ai/app/${
                          project.slug
                        }/crane-reports/?dateTime=${encodeURIComponent(
                          p.startDateTime.toISO({
                            suppressMilliseconds: true,
                          })
                        )}&stream=${selectedCrane?.jib_stream_original}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconButton size="small" color="secondary">
                          <OpenInNewIcon />
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
