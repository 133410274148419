import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import logo from '../../images/logo-color.png'
import { useApi } from './ApiContext'
import { ProgressButton } from './FormInputs/ProgressButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 8,
      margin: '0',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      width: '100%',
      maxWidth: 420,
    },
    logo: {
      width: 196,
      display: 'block',
      margin: '30px auto 20px',
    },
    loginButtonWrapper: {
      flexDirection: 'column',
    },
    loginButton: {
      marginBottom: 10,
    },
    link: {
      color: '#23527c',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    forgotPasswordButtons: {
      display: 'flex',
    },
    resetPassword: {
      marginLeft: 10,
      flexGrow: 1,
    },
    requirements: {
      padding: '0 32px',
    },
    list: {
      paddingLeft: '1em',
      marginBottom: '0',
    },
  })
)

export const ResetPasswordPage = (props: {}) => {
  const api = useApi()
  const classes = useStyles()

  const { uidb64, token } = useParams()

  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const [submitState, submitPassword] = useAsyncFn(async () => {
    if (!uidb64 || !token) {
      throw new Error('No token present')
    }
    if (password1 !== password2) {
      throw new Error("Passwords don't match!")
    }
    try {
      const me = await api.setPassword(uidb64, token, password1, password2)
      return me
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        throw new Error('Invalid email or password')
      }
      throw error
    }
  }, [uidb64, token, password1, password2])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img className={classes.logo} src={logo} alt="Topdeck logo" />
            </Grid>
            {!submitState.value && (
              <Grid item xs={12} className={classes.requirements}>
                <Alert severity="info">
                  Your password:
                  <ul className={classes.list}>
                    <li>Must be at least 8 characters.</li>
                    <li>Can't share your username or email.</li>
                    <li>Can't be all numbers.</li>
                    <li>Can't be a common word.</li>
                  </ul>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              {!submitState.value ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    submitPassword()
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        type="password"
                        label="Password"
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        type="password"
                        label="Confirm Password"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProgressButton
                        className={classes.loginButtonWrapper}
                        buttonClassname={classes.loginButton}
                        type="submit"
                        fullWidth
                        disabled={false}
                        fetchState={submitState}
                      >
                        Set Password
                      </ProgressButton>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    Your new password is set. You can log in with it now.
                  </Grid>
                  <Grid item xs={12}>
                    <Link replace to={`/login`}>
                      <Button fullWidth variant="contained">
                        Go to login
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
