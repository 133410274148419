import { Grid } from '@material-ui/core'
import without from 'lodash/without'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ProjectSummaryExtended } from '../../api/ProjectSummaryExtended'
import { queryKeys } from '../../helpers/constants'
import { parseSearchList } from '../../helpers/parseSearchList'
import { MultiSelect } from '../FormInputs/MultiSelect'

export const StreamsSelect = ({
  projects,
}: {
  projects: ProjectSummaryExtended[]
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedProjects, setSelectedProjects] = useState<
    ProjectSummaryExtended[]
  >([])

  useEffect(() => {
    const projectsParam = parseSearchList(searchParams, queryKeys.PROJECTS)
    const selectedProjects = projects.filter(
      (p) => projectsParam.indexOf(p.id.toString()) > -1
    )
    const validStreamIds = selectedProjects.reduce<string[]>((ids, project) => {
      return ids.concat(
        project.streams?.map((stream) => stream.id.toString()) || []
      )
    }, [])
    const existingStreamIds = parseSearchList(searchParams, queryKeys.STREAMS)
    const invalidStreamIds = existingStreamIds.filter(
      (id) => validStreamIds.indexOf(id) === -1
    )
    searchParams.set(
      queryKeys.STREAMS,
      without(existingStreamIds, ...invalidStreamIds).join()
    )
    setSearchParams(searchParams, { replace: true })
    setSelectedProjects(selectedProjects)
  }, [searchParams.get(queryKeys.PROJECTS)])

  return (
    <>
      {/* Projects Autocomplete */}
      <Grid item xs={12}>
        <MultiSelect
          groupLabel="Projects"
          options={projects.map((p) => ({
            label: p.name,
            value: p.id.toString(),
          }))}
          queryKey={queryKeys.PROJECTS}
          inputType="autocomplete"
        />
      </Grid>

      {/* Stream Checboxes */}
      <Grid item xs={12}>
        {selectedProjects.map((project) => (
          <MultiSelect
            key={project.id}
            groupLabel={project.name}
            options={project.streams.map((s) => ({
              label: s.name,
              value: s.id.toString(),
            }))}
            queryKey={queryKeys.STREAMS}
            inputType="checkbox"
          />
        ))}
      </Grid>
    </>
  )
}
