import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import React, { FC, useState } from 'react'
import zustand, { UseStore } from 'zustand'
import { extendFrame, FrameExtended } from '../../../api/FrameExtended'
import { createCtx } from '../../../helpers/createCtx'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'

export type FramesState = {
  streamFrames: Map<number, FrameExtended[]>
  selectedFrame: FrameExtended | undefined
  timeOptions?: {
    string: string
    dateTime: DateTime
  }[]
  searchDirection: 'forward' | 'backward'
  fetchFrames: (date: DateTime) => void
  gotoPrev: () => void
  gotoNext: () => void
}

export type UseFramesStore = UseStore<FramesState>

export const [useFramesStore, FramesContext] = createCtx<UseFramesStore>()

export const FramesController: FC<{}> = ({ children }) => {
  const api = useApi()
  const project = useProject()
  const vodStore = getUseVODStore()

  // init store
  const [useStore] = useState<UseFramesStore>(() =>
    zustand<FramesState>((set, get) => ({
      streamFrames: new Map<number, FrameExtended[]>(),
      selectedFrame: undefined,
      timeOptions: undefined,
      searchDirection: 'forward',
      fetchFrames: async (date: DateTime) => {
        const startDateTime = date.startOf('day')
        const endDateTime = startDateTime.endOf('day')
        const streams = await Promise.all(
          project.streams.map((s) =>
            api.framesApi
              .streamsFramesList({
                streamId: s.id.toString(),
                startTime: startDateTime.toISO(),
                endTime: endDateTime.toISO(),
              })
              .then((resp) => {
                const frames = resp.data.map((f) => extendFrame(f, project, s))
                const kv: [id: number, frames: FrameExtended[]] = [s.id, frames]
                return kv
              })
          )
        )

        const timeOptions = uniq(
          streams.flatMap((s) => s[1]).map((f) => f.dateTime)
        ).map((d) => ({
          string: d.toFormat('h a'),
          dateTime: d,
        }))

        set({ streamFrames: new Map(streams), timeOptions: timeOptions })
      },
      gotoPrev: () => {
        const { videoDateTime, gotoVideo } = vodStore.getState()
        // const frame = get().streamFrames.find((f) => f.dateTime.plus({ hours: 1 }) > videoDateTime)
        set({ searchDirection: 'backward' })
        gotoVideo({
          dateTime: videoDateTime
            .minus({ hours: 1 })
            .set({ minute: 0, second: 0 }),
        })
      },
      gotoNext: () => {
        const { videoDateTime, gotoVideo } = vodStore.getState()
        set({ searchDirection: 'forward' })
        gotoVideo({
          dateTime: videoDateTime
            .plus({ hours: 1 })
            .set({ minute: 0, second: 0 }),
        })
      },
    }))
  )

  const videoHour = vodStore((state) => state.videoHour)
  const videoStreamId = vodStore((state) => state.videoStreamId)
  const streamFrames = useStore((state) => state.streamFrames)
  const searchDirection = useStore((state) => state.searchDirection)

  React.useEffect(() => {
    if (!streamFrames || !videoStreamId || !videoHour) {
      return
    }
    const frames = streamFrames.get(videoStreamId)
    if (!frames) {
      return
    }
    const frame = frames.find((f) => f.roundedDateTime.hour === videoHour.hour)

    useStore.setState({ selectedFrame: frame })
  }, [streamFrames, searchDirection, videoStreamId, videoHour])

  React.useEffect(() => {
    vodStore.subscribe(
      () => {
        useStore.getState().fetchFrames(vodStore.getState().videoDate)
      },
      (state) => state.videoDate
    )
  }, [vodStore])

  return (
    <FramesContext.Provider value={useStore}>{children}</FramesContext.Provider>
  )
}
