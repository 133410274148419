import {
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import Alert from '@material-ui/lab/Alert'
import copyToClipboard from 'copy-to-clipboard'
import { uniqBy } from 'lodash'
import qs from 'qs'
import { useMemo, useState } from 'react'
import { Photosphere as PhotosphereType } from '../../api/codegen/typescript-axios'
import { DragResizeBox } from '../DragResizeBox'
import { DateRangeInput } from '../FormInputs/DateRangeInput'
import { useProject } from '../ProjectWrapper'
import { useKRPanoStore } from './KRPanoProvider'
import { PhotosphereDates } from './PhotosphereDates'
import { PhotosphereMap } from './PhotosphereMap'
import { PhotosphereOverlays } from './PhotosphereOverlays'
import { usePhotospherePageStore } from './PhotospherePageProvider'
import { PhotospherePTZ } from './PhotospherePTZ'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    closeIcon: {
      position: 'absolute',
      top: 10,
      right: -42,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    dateChip: {
      marginRight: 4,
      marginBottom: 4,
    },
    scrollDiv: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: 4,
    },
    ptzDiv: {},
  })
)

export const PhotosphereAdvancedControls = ({
  startParamKey,
  endParamKey,
  spheres,
  sphereId,
  currentSphere,
  setSphereId,
  onClose,
}: {
  startParamKey: string
  endParamKey: string
  spheres?: PhotosphereType[]
  sphereId?: number
  currentSphere?: PhotosphereType
  setSphereId: (id: number) => void
  onClose?: () => void
}) => {
  const classes = useStyles()
  const project = useProject()
  const photospherePageStoreApi = usePhotospherePageStore()
  const krpanoStore = useKRPanoStore()

  const dedupedSpheres = useMemo(
    () => uniqBy(spheres, (sphere) => sphere.template),
    [spheres]
  )

  const [showCopyConfirm, setShowCopyConfirm] = useState(false)

  return (
    <>
      <DragResizeBox
        handleSide="right"
        defaultSize={240}
        minSize={240}
        localStorageKey="photosphere-advanced-controls-width"
      >
        <div className={classes.root}>
          <div className={classes.scrollDiv}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DateRangeInput
                  label="Date range"
                  timezone={project.timezone}
                  startQueryKey={startParamKey}
                  endQueryKey={endParamKey}
                  minimal={true}
                />
              </Grid>

              {dedupedSpheres.length > 1 && (
                <Grid item xs={12}>
                  <div
                    style={{
                      width: '100%',
                      paddingTop: '100%',
                      position: 'relative',
                    }}
                  >
                    <PhotosphereMap
                      spheres={dedupedSpheres}
                      currentSphere={currentSphere}
                      onClickSphere={setSphereId}
                    />
                  </div>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="h6">
                  <span style={{ fontSize: 18 }}>{currentSphere?.title} </span>
                  <Tooltip title="Copy share URL to clipboard">
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<LinkIcon />}
                      style={{ marginRight: 4 }}
                      onClick={() => {
                        setShowCopyConfirm(true)
                        copyToClipboard(
                          `${window.location.origin}${
                            process.env.REACT_APP_BASE_HREF
                          }aerial-tour-share/${
                            currentSphere?.public_id
                          }?${qs.stringify(
                            photospherePageStoreApi.getState().masterView
                          )}`,
                          {
                            format: 'text/plain',
                          }
                        )
                      }}
                    >
                      Share
                    </Button>
                  </Tooltip>
                  <Tooltip title="Download screenshot">
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<PhotoCameraIcon />}
                      onClick={() => {
                        if (currentSphere) {
                          krpanoStore
                            .getState()
                            .downloadScreenshot(currentSphere.title)
                        }
                      }}
                    >
                      Screenshot
                    </Button>
                  </Tooltip>
                  <Snackbar
                    open={showCopyConfirm}
                    autoHideDuration={3000}
                    onClose={() => setShowCopyConfirm(false)}
                  >
                    <Alert
                      onClose={() => setShowCopyConfirm(false)}
                      severity="success"
                    >
                      Share URL copied to clipboard.
                    </Alert>
                  </Snackbar>
                </Typography>
              </Grid>

              <PhotosphereDates
                spheres={spheres}
                sphereId={currentSphere?.id}
                setSphereId={setSphereId}
                currentSphere={currentSphere}
              />
              <Grid item xs={12}>
                <PhotosphereOverlays currentSphere={currentSphere} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.ptzDiv}>
            <Divider />
            <PhotospherePTZ />
          </div>
        </div>
      </DragResizeBox>
    </>
  )
}
