import {
  Box,
  Divider,
  FormControl,
  Grid,
  NativeSelect,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useMediaClasses } from '../../../styles/mediaClasses'
import { useProject } from '../../ProjectWrapper'
import { VODDateTimeSelect } from '../VODDateTimeSelect'
import { useCraneStore } from './CranePicksController'
import { CranePicksExportButton } from './CranePicksExportButton'

export const CraneSelect: React.FC = ({ children }) => {
  const mediaClasses = useMediaClasses()
  const project = useProject()
  const craneStore = useCraneStore()

  const selectedCrane = craneStore((state) => state.selectedCrane)

  return (
    <>
      <Box padding={1} className={mediaClasses.hideForPrint}>
        <Grid container spacing={1}>
          {project.cranes.length === 0 ? (
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1">No cranes on this project</Typography>
            </Grid>
          ) : (
            <>
              {project.cranes.length > 1 && (
                <>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1">Crane: </Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl>
                      <NativeSelect
                        variant="outlined"
                        value={selectedCrane?.id || ''}
                        onChange={(e) =>
                          craneStore
                            .getState()
                            .setSelectedCraneId(parseInt(e.target.value))
                        }
                        inputProps={{
                          name: 'stream',
                          id: 'stream-select',
                        }}
                      >
                        <option>Select crane</option>
                        {project.cranes.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.name}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <VODDateTimeSelect time={false} />
              </Grid>
              <Grid item>
                <CranePicksExportButton />
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                {children}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Divider />
    </>
  )
}
