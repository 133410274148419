import { useTheme } from '@material-ui/core'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'

export const GrafanaDashboard = (props: {}) => {
  const theme = useTheme()
  const api = useApi()
  const project = useProject()
  const [searchParams] = useSearchParams()

  const { loading, error } = useAsync(async () => {
    const resp = await api.projectsApi.projectsSetupGrafana(
      {
        id: project.id.toString(),
      },
      {
        withCredentials: 'include',
      }
    )
    return resp.data
  })

  console.log(project)

  if (loading || error) return null

  return (
    <iframe
      src={`${process.env.REACT_APP_API_URL?.slice(0, -1)}${
        project.grafana_dashboard_path
      }`}
      style={{
        width: '100%',
        height: `calc(100vh - ${theme.custom.projectNavHeight}px)`,
        display: 'block',
        border: 'none',
      }}
    />
  )
}
