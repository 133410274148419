import Alert from '@material-ui/lab/Alert'
import { mat2d } from 'gl-matrix'
import Konva from 'konva'
import React from 'react'
import { Layer, Stage } from 'react-konva'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { useMeasure } from 'react-use'
import { useCallbackRef, useMergeRefs } from 'use-callback-ref'
import zustand, { UseStore } from 'zustand'
import { createCtx } from '../../../helpers/createCtx'
import { getContainTransform } from '../../../helpers/getContainTransform'
import { transformers, useZoomPan } from '../useZoomPan'
import { useUploadsStore } from './UploadsController'

export type UploadCanvasState = {
  transform: Konva.Transform
  // clamp: (
  //   point: Konva.Vector2d,
  //   dimensions?: Konva.Vector2d
  // ) => {
  //   x: number
  //   y: number
  // }
  containerWidth: number
  containerHeight: number
  drawingWidth: number
  drawingHeight: number
}

export type UseUploadCanvasStore = UseStore<UploadCanvasState>

export const [
  useUploadCanvasStore,
  UploadCanvasStoreContext,
] = createCtx<UseUploadCanvasStore>()

export const UploadDisplay: React.FC<{}> = ({ children }) => {
  const uploadsStore = useUploadsStore()

  const [useStore] = React.useState(() =>
    zustand<UploadCanvasState>((set, get) => ({
      transform: new Konva.Transform(),
      // clamp: (point: Konva.Vector2d, dimensions?: Konva.Vector2d) => {
      //   // x: number
      //   // y: number
      //   return { x: 1, y: 1 }
      // },
      containerWidth: 1,
      containerHeight: 1,
      drawingWidth: 1,
      drawingHeight: 1,
    }))
  )

  const selectedUpload = uploadsStore((state) => state.selectedUpload)
  const selectedUploadError = uploadsStore((state) => state.selectedUploadError)
  const selectedUploadPageNumber = uploadsStore(
    (state) => state.selectedUploadPageNumber
  )

  const sourceCanvasRef = useCallbackRef<HTMLCanvasElement>(null, () => {})
  const displayContainerRef = React.useRef<HTMLDivElement>(null)
  const stageRef = React.useRef<Konva.Stage>(null)

  // Keep tabs on container size
  const [measurementRef, measurements] = useMeasure<HTMLDivElement>()

  // this is a zoom/pan thing. check D3 zoom docs for explainer
  const translateExtentRef = React.useRef<[[number, number], [number, number]]>(
    [
      [0, 0],
      [measurements.width, measurements.height],
    ]
  )
  translateExtentRef.current[1][0] = measurements.width
  translateExtentRef.current[1][1] = measurements.height

  const baseTransform = getContainTransform({
    parent: { w: measurements.width, h: measurements.height },
    child: {
      w: sourceCanvasRef.current?.width || 1,
      h: sourceCanvasRef.current?.height || 1,
    },
  })

  const [zoomTransform, setZoomTransform] = React.useState(
    new Konva.Transform()
  )

  const absoluteTransform = zoomTransform.multiply(baseTransform)

  const { setTransform } = useZoomPan({
    target: displayContainerRef,
    scaleExtent: React.useRef([1, Infinity]),
    translateExtent: translateExtentRef,
    gestureConfig: {
      wheelX: transformers.zoomXY,
      wheelY: transformers.zoomXY,
      altWheelX: transformers.panX,
      altWheelY: transformers.panY,
      dragX: transformers.panX,
      dragY: transformers.panY,
    },
    handlers: {
      onTransform: (transformRef, mousePositionRef) => {
        const matrix = transformRef.current
        if (matrix) {
          setZoomTransform(new Konva.Transform(matrix as number[]))
        }
      },
    },
  })

  React.useEffect(() => {
    setTransform(mat2d.create())

    if (
      selectedUpload &&
      selectedUpload.file_url &&
      selectedUpload.content_type?.indexOf('image/') === 0
    ) {
      const image = new Image()
      image.onprogress = (data) => {
        uploadsStore.setState({
          selectedUploadLoadProgress: (data.loaded / data.total) * 100,
        })
      }
      image.onload = () => {
        const canvas = sourceCanvasRef.current
        if (!canvas) {
          console.error('canvas not present')
          return
        }
        canvas.width = image.naturalWidth
        canvas.height = image.naturalHeight
        canvas.getContext('2d')?.drawImage(image, 0, 0)

        uploadsStore.setState({
          selectedUploadLoading: false,
          selectedUploadPageCount: undefined,
        })
      }
      image.src = selectedUpload.file_url
    }
  }, [selectedUpload, selectedUploadPageNumber])

  React.useEffect(() => {
    useStore.setState({
      containerWidth: measurements.width || 1,
      containerHeight: measurements.height || 1,
      drawingWidth: sourceCanvasRef.current?.width || 1,
      drawingHeight: sourceCanvasRef.current?.height || 1,
      transform: absoluteTransform,
    })
  }, [
    measurements,
    sourceCanvasRef.current?.width,
    sourceCanvasRef.current?.height,
    absoluteTransform,
  ])

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#000',
      }}
    >
      {/* Drawing canvas container */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `matrix(${absoluteTransform.getMatrix()})`,
          transformOrigin: 'top left',
          pointerEvents: 'none',
          overflow: 'hidden',
        }}
      >
        {selectedUpload?.content_type === 'application/pdf' ? (
          <Document
            key={selectedUpload?.file_url}
            file={selectedUpload?.file_url}
            onLoadProgress={(data) => {
              uploadsStore.setState({
                selectedUploadLoadProgress: (data.loaded / data.total) * 100,
              })
            }}
            onLoadError={(data) => {
              uploadsStore.setState({
                selectedUploadError: data.name + ': ' + data.message,
              })
            }}
            onLoadSuccess={({ numPages }) => {
              uploadsStore.setState({
                selectedUploadLoading: false,
                selectedUploadPageCount: numPages as number,
              })
            }}
          >
            {selectedUploadPageNumber && (
              <Page
                canvasRef={sourceCanvasRef}
                pageNumber={selectedUploadPageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={() => {
                  console.log('onLoadSuccess')
                  setTransform(mat2d.create())
                  // stageRef.current?.batchDraw()
                }}
                onRenderSuccess={() => {
                  console.log('onRenderSuccess')
                  setTransform(mat2d.create())
                  // stageRef.current?.batchDraw()
                }}
              />
            )}
          </Document>
        ) : (
          <canvas ref={sourceCanvasRef} />
        )}
      </div>

      {/* Annotation overlay canvas container */}
      <div
        ref={useMergeRefs([
          displayContainerRef,
          measurementRef as React.Ref<HTMLDivElement>,
        ])}
        style={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          touchAction: 'none',
        }}
      >
        <Stage
          ref={stageRef}
          width={measurements.width}
          height={measurements.height}
          style={{ position: 'absolute' }}
          {...absoluteTransform.decompose()}
        >
          <UploadCanvasStoreContext.Provider value={useStore}>
            <Layer imageSmoothingEnabled={false}>{children}</Layer>
          </UploadCanvasStoreContext.Provider>
        </Stage>
      </div>

      {selectedUploadError && (
        <Alert severity="error">{selectedUploadError}</Alert>
      )}
    </div>
  )
}
