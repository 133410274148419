import { ThemeProvider as MUIThemeProvider } from '@material-ui/core'
import React, { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { createCtx } from '../../helpers/createCtx'
import { darkTheme, lightTheme } from '../../styles/theme'

type ThemeType = 'light' | 'dark'

export const [useThemeControl, ThemeControlContext] = createCtx<{
  themeType: ThemeType
  setThemeType: (type: ThemeType) => void
}>()

export const ThemeController: FC<{}> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [themeStorage, setThemeStorage] = useLocalStorage<ThemeType>(
    'theme',
    'dark'
  )

  // Enable forcing light theme via search param for print urls
  let themeParam = searchParams.get('theme')
  if (themeParam !== 'dark' && themeParam !== 'light') {
    themeParam = null
  }

  const themeType = themeParam || themeStorage || 'dark'

  const setThemeType = (theme: ThemeType) => {
    setThemeStorage(theme)
    searchParams.delete('theme')
    setSearchParams(searchParams, { replace: true })
  }

  React.useEffect(() => {
    console.log('useEffect', themeType)
    const setLight = () => {
      searchParams.set('theme', 'light')
      searchParams.set('print', '1')
      setSearchParams(searchParams, { replace: true })
    }
    const revert = () => {
      searchParams.delete('theme')
      searchParams.delete('print')
      setSearchParams(searchParams, { replace: true })
    }
    window.addEventListener('beforeprint', setLight)
    window.addEventListener('afterprint', revert)
    return () => {
      window.removeEventListener('beforeprint', setLight)
      window.removeEventListener('afterprint', revert)
    }
  }, [searchParams, setSearchParams])

  return (
    <ThemeControlContext.Provider
      value={{
        themeType: themeType,
        setThemeType: setThemeType,
      }}
    >
      <MUIThemeProvider theme={themeType === 'light' ? lightTheme : darkTheme}>
        {children}
      </MUIThemeProvider>
    </ThemeControlContext.Provider>
  )
}
