import { DateTime } from 'luxon'
import { Frame } from './codegen/typescript-axios'
import { ProjectDetailsExtended } from './ProjectDetailsExtended'
import { StreamExtended } from './StreamExtended'

export interface FrameExtended extends Frame {
  id: number
  dateTime: DateTime
  roundedDateTime: DateTime
  // system times are the real world times, shifted to the browser system's timezone,
  // because the timeline D3 scale can only be in that timezone, or in UTC
  systemDateTime: DateTime
  stream: StreamExtended
}

export function extendFrame(
  frame: Frame,
  project: ProjectDetailsExtended,
  stream: StreamExtended
): FrameExtended {
  const dateTime = DateTime.fromISO(frame.image_datetime, {
    zone: project.timezone,
  })

  const systemDateTime = dateTime.setZone('system', {
    keepLocalTime: true,
  })

  return {
    ...frame,
    dateTime,
    roundedDateTime: dateTime.set({ second: 0, millisecond: 0 }),
    systemDateTime,
    stream: stream,
  }
}
