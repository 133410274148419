import React from 'react'
import { scaleOrdinal } from '@visx/scale'
import { LegendOrdinal } from '@visx/legend'
import { UseLiveAICountStore } from './LiveAICountController'
import { useMeasure } from 'react-use'
import { format } from 'd3-format'

const zeropadder = format(' >2d')

export const LiveAICountChartLegend = ({
  useLiveAICountStore,
}: {
  useLiveAICountStore: UseLiveAICountStore
}) => {
  const liveaiClassesList = useLiveAICountStore(
    (state) => state.liveaiClassesList
  )
  const liveAiData = useLiveAICountStore((state) => state.liveAiData)

  const liveAiDatum = liveAiData[liveAiData.length - 1]

  const ordinalColorScale = scaleOrdinal({
    domain: liveaiClassesList.map(
      (klass) =>
        `${zeropadder(liveAiDatum?.box_count_avg_by_class[klass.name] || 0)} ${
          klass.name
        }s`
    ),
    range: liveaiClassesList.map((d) => d.color),
  })

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  return (
    <div ref={ref}>
      <LegendOrdinal
        scale={ordinalColorScale}
        shapeStyle={() => ({ borderRadius: '50%', width: 8, height: 8 })}
        style={{
          flexDirection: 'row',
          paddingTop: 40,
          paddingLeft: 34,
          fontSize: width / 30,
          whiteSpace: 'pre',
          fontFamily: '"Roboto Mono',
        }}
      ></LegendOrdinal>
    </div>
  )
}
