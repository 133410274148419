import { createStyles, makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    alertRoot: {
      padding: '3px 10px 3px 3px',
      marginTop: '6px',
      borderRadius: '1em',
      maxWidth: '100%',
      pointerEvents: 'auto',
    },
    icon: {
      padding: 0,
      marginRight: 4,
    },
    message: {
      padding: 0,
    },
  })
)

const colorMap = {
  red: 'error',
  yellow: 'warning',
  green: 'success',
  blue: 'info',
} as const

export interface Message {
  text: string
  color: keyof typeof colorMap
}

export const VideoErrorMessages = ({
  messages,
  classes: customClasses,
}: {
  messages: Message[]
  classes?: {
    alertRoot?: string
    icon?: string
    message?: string
  }
}) => {
  const classes = useStyles()

  return (
    <>
      {messages.map((message) => (
        <Alert
          key={message.text}
          severity="error"
          color={colorMap[message.color]}
          variant="filled"
          classes={{
            root: clsx(classes.alertRoot, customClasses?.alertRoot),
            icon: clsx(classes.icon, customClasses?.icon),
            message: clsx(classes.message, customClasses?.message),
          }}
        >
          {message.text}
        </Alert>
      ))}
    </>
  )
}
