import Konva from 'konva'
import { ReadonlyVec2, vec2, mat2d } from 'gl-matrix'

// takes the container dimensions and image natural dimensions
// and returns a base transform to fit the image inside the container
export function getContainTransform({
  parent,
  child,
  crop = {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  },
}: {
  parent: { w: number; h: number }
  child: { w: number; h: number }
  crop?: { x: number; y: number; w: number; h: number }
}) {
  crop.w = crop.w || child.w
  crop.h = crop.h || child.h

  const scale = Math.min(parent.w / crop.w, parent.h / crop.h)

  return new Konva.Transform()
    .translate(parent.w / 2, parent.h / 2)
    .scale(scale, scale)
    .translate(-(crop.x + crop.w / 2), -(crop.y + crop.h / 2))
}

export function getContainTransformMat2d({
  parent,
  child,
  crop = [
    [0, 0],
    [0, 0],
  ],
}: {
  parent: ReadonlyVec2
  child: ReadonlyVec2
  crop?: [vec2, vec2]
}) {
  crop[1][0] = crop[1][0] || child[0]
  crop[1][1] = crop[1][1] || child[1]

  const scale = Math.min(parent[0] / crop[1][0], parent[1] / crop[1][1])

  const t = mat2d.create()
  mat2d.translate(t, t, [parent[0] / 2, parent[1] / 2])
  mat2d.scale(t, t, [scale, scale])
  mat2d.translate(t, t, [
    -(crop[0][0] + crop[1][0] / 2),
    -(crop[0][1] + crop[1][1] / 2),
  ])

  return t
}
